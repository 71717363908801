import { useContext, useEffect, useState } from "react"
import { GraphTablePreview } from "./graph-and-table"
import { TrackImagePreview } from "./trackImage"
import { REPORT_TYPE, SUMMARY_PADDING, SUMMARY_WIDTH, TRACK_IMAGE_INPUT_DATA, getSettingFunction, getTrackImageTemplate, taskPropsContext } from "./utils"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import queryString from 'query-string';
import pako from 'pako';
import { Result } from "antd"
import { useSelector } from "react-redux"

const Report = () => {
  const location = useLocation()
  const [contentWidth, setContentWidth] = useState(SUMMARY_WIDTH)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState({})
  const [dataList, setDataList] = useState([])
  const [msg, setMsg] = useState()

  const {candidates} = useContext(taskPropsContext)
  const imageTypeList = useSelector(state => state.blobTypes?.list) || [];
  
  useEffect(() => {
    const search = location.search.replace(/\+/g, '%2B');
    const queryParams = queryString.parse(search)
    const reportData = queryParams.report_data
    const decodedReportData = decodeAndDecompress(reportData)
    const page = decodedReportData.page
    setPage(page)
    if (page?.type === REPORT_TYPE.TRACK_IMAGE) {
      const imageInfo = decodedReportData.image_info
      if (imageInfo.msg) {
        setMsg(imageInfo.msg)
      }
      else {
        const {rows, cols, image_url} = imageInfo
  
        const loadImageAndExtract = async spriteSheetUrl => {
          return new Promise(resolve => {
            if (!spriteSheetUrl) {
              return resolve()
            }
            const spriteSheet = new Image()
            spriteSheet.src = spriteSheetUrl;
            spriteSheet.crossOrigin = 'Anonymous';
  
            spriteSheet.onload = () => {
              const individualImageWidth = spriteSheet.width / cols;
              const individualImageHeight = spriteSheet.height / rows;
            
              // 정사각형 이미지 크기
              const individualImageSize = Math.max(individualImageWidth, individualImageHeight); // 정사각형 이미지 크기
        
              const canvas = document.createElement("canvas");
              canvas.width = individualImageSize;
              canvas.height = individualImageSize;
              const context = canvas.getContext("2d");
  
              context.drawImage(spriteSheet, 0, 0);
              // context.getImageData(x, y, width, height)
              const firstPixelData = context.getImageData(0, 0, 1, 1).data;
              const paddingColor = `rgb(${firstPixelData[0]}, ${firstPixelData[1]}, ${firstPixelData[2]})`;
              
              const imageUrls = [];
              // 스프라이트 시트에서 정사각형 이미지 추출
              for (let y = 0; y < rows; y++) {
                for (let x = 0; x < cols; x++) {
                  context.fillStyle = paddingColor;
                  context.fillRect(0, 0, canvas.width, canvas.height);
        
                  // 이미지를 패딩을 추가하여 이미지 정사각형으로 제작
                  const paddingX = (individualImageSize - individualImageWidth) / 2;
                  const paddingY = (individualImageSize - individualImageHeight) / 2;
        
                  context.drawImage(
                    spriteSheet,
                    x * individualImageWidth,
                    y * individualImageHeight,
                    individualImageWidth,
                    individualImageHeight,
                    paddingX, // x 좌표에 패딩 추가
                    paddingY, // y 좌표에 패딩 추가
                    individualImageWidth,
                    individualImageHeight
                  );
        
                  const imageDataUrl = canvas.toDataURL("image/png");
                  imageUrls.push(imageDataUrl);
                }
              }
              resolve(imageUrls)
            }
          })
        }
        const loadImagePromise = loadImageAndExtract(image_url);
        loadImagePromise.then(imageUrls => {
          const data = decodedReportData.data
          for (const caseData of data) {
            caseData.trackInfo = caseData?.track_info
            for (const trackInfo of caseData?.trackInfo || []) {
              const imageIndex = trackInfo.image_index
              trackInfo.imageUrl = imageUrls[imageIndex]
              trackInfo.locationId = trackInfo.group_id
            }
          }
          setDataList(data)
        })
      }
    }
    else {
      setDataList(decodedReportData.data)
    }
    
    let newWidth = SUMMARY_WIDTH
    const STUDY_LIMIT = 5
    const studyCount = decodedReportData?.data?.length || 1
    if (studyCount > STUDY_LIMIT && page?.type === REPORT_TYPE.GRAPH_N_TABLE ) {
      const exceedStudyCount = studyCount - STUDY_LIMIT
      newWidth = SUMMARY_WIDTH + (exceedStudyCount * 150)
    }
    else if (page?.type === REPORT_TYPE.TRACK_IMAGE) {
      const trackImageValue = page[REPORT_TYPE.TRACK_IMAGE]
      const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList)
      const getTrackImageValue = getSettingFunction(trackImageValue, trackImageTemplate)
      const maximumColumnCount = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.MAXIMUM_COLUMN_COUNT)
      newWidth = maximumColumnCount > 5 ? SUMMARY_WIDTH + 200 : SUMMARY_WIDTH
    }
    else {
      newWidth = SUMMARY_WIDTH
    }
    if (contentWidth !== newWidth) {
      setContentWidth(newWidth)
    }

    setLoading(false)
  },[])

  const getChildren = () => {
    switch (page?.type) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        return (
          <GraphTablePreview 
            page={page} 
            dataList={dataList}
            contentWidth={contentWidth}
            capture={true}
          />
        )
      case REPORT_TYPE.TRACK_IMAGE:
        return (
          <>
            {
              msg ? 
              <div 
                style={{
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Result 
                  title={msg}
                />
              </div> : 
              <TrackImagePreview 
                page={page} 
                trackDataList={dataList}
                capture={true}
              />
            }
          </>
        )
      default:
        return (
          <>
            <div>{page?.title}</div>
            <div>{page?.type}</div>
          </>
        )
    }
  }

  return (
    <div 
      style={{
        width: contentWidth, 
        minWidth: contentWidth, 
        maxWidth: contentWidth,
        backgroundColor: 'black', 
        margin: `${SUMMARY_PADDING}px auto`,
        minHeight: '600px',
      }}
      id="report-capture-box"
    >
      {!loading && 
        getChildren()
      }
    </div>
  )
}

export default Report


const decodeAndDecompress = base64String => {
  // Base64 디코딩
  const decodedData = atob(base64String);

  // 디코딩된 문자열을 Uint8Array로 변환
  const charData = decodedData.split('').map(c => c.charCodeAt(0));
  const binData = new Uint8Array(charData);

  // 데이터 압축 해제
  const decompressedData = pako.inflate(binData, { to: 'string' });

  // JSON으로 파싱하여 객체로 변환
  return JSON.parse(decompressedData);
}