import { Button, Col, Image as AntImage, Modal, Popover, Row, Space, Select, Tree, Tooltip, Switch, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks"
import { useEffect, useRef, useState } from "react"
import { MODE, clearQuickViewData, updateQuickViewController, updateQuickViewImageInformation, 
  updateQuickViewImageUrls, updateParallelView, updateDisplay
 } from "../redux/modules/analysis"
import axios from "axios"
import { TASK_DELIMITER } from "../lib/taskUtil"
import { TypographyDesc } from "./typographyDesc"
import LoadingModal from "./loadingModal"
import { TransparentBGModal } from "./styledComponent"
import { getUrl } from "../redux/modules/view"

const { Text } = Typography

const INPUT = 'Input'
const OUTPUT = 'Output'

const INPUTS = 'inputs'
const OUTPUTS = 'outputs'
const dataClasses = [INPUTS, OUTPUTS]
const SPRITE_SHEET_IMAGE_COUNTS = 24

const VERTICAL = 'vertical'
const HORIZONTAL = 'horizontal'
const IN_OUTPUT_COLUMN_WIDTH = 70
const TYPE_NAME_LOCATION = Object.freeze({FRONT: 'front', BOTTOM: 'bottom'})

const DEFAULT_ROW = 3
const DEFAULT_ALIGN = VERTICAL

const TOTAL_IMAGE_COUNT = 24
const MODAL_WIDTH = 1500
const MODAL_PADDING = 24
const SCROLL_WIDTH = 10
const MODAL_CONTENT_WIDTH = MODAL_WIDTH - (MODAL_PADDING * 2) - SCROLL_WIDTH

const AnalysisQuickViewModal = () => {
  const dispatch = useDispatch()
  const quickViewDataList = useSelector(state => state.analysis?.quickView?.list)
  const open = useSelector(state => state.analysis?.quickView?.open)
  const volumeInfo = useSelector(state => state.analysis?.quickView?.volumeInfo)
  const controllerInfo = useSelector(state => state?.analysis?.quickView?.controller)
  const parallelViewOpen = useSelector(state => state.analysis?.quickView?.parallelView?.open)
  const isLongitudinalMode = useSelector(state => state?.analysis?.quickView?.volumeInfo?.mode === MODE.MULTI)

  const align = controllerInfo?.align || DEFAULT_ALIGN

  const [loading, setLoading] = useState(true)

  const [controllerHeight, setControllerHeight] = useState(0)

  useEffect(() => {
    if (quickViewDataList?.length) {
      const columns = 6;
      const rows = TOTAL_IMAGE_COUNT / columns;
  
      const loadImageAndExtract = async (spriteSheetUrl, basePayload) => {
        return new Promise((resolve) => {
          if (!spriteSheetUrl) {
            return resolve();
          }
          const spriteSheet = new Image();
          spriteSheet.src = spriteSheetUrl;
          spriteSheet.crossOrigin = 'Anonymous';
      
          // 이미지 로딩 완료 후 실행
          spriteSheet.onload = () => {
            const individualImageWidth = spriteSheet.width / columns;
            const individualImageHeight = spriteSheet.height / rows;
            
            // 정사각형 이미지 크기
            const individualImageSize = Math.max(individualImageWidth, individualImageHeight); // 정사각형 이미지 크기
      
            const canvas = document.createElement("canvas");
            canvas.width = individualImageSize;
            canvas.height = individualImageSize;
            const context = canvas.getContext("2d");

            context.drawImage(spriteSheet, 0, 0);
            // context.getImageData(x, y, width, height)
            const firstPixelData = context.getImageData(0, 0, 1, 1).data;
            const paddingColor = `rgb(${firstPixelData[0]}, ${firstPixelData[1]}, ${firstPixelData[2]})`;
            
            const imageUrls = [];
            // 스프라이트 시트에서 정사각형 이미지 추출
            for (let y = 0; y < rows; y++) {
              for (let x = 0; x < columns; x++) {
                
                context.fillStyle = paddingColor;
                context.fillRect(0, 0, canvas.width, canvas.height);
      
                // 이미지를 패딩을 추가하여 이미지 정사각형으로 제작
                const paddingX = (individualImageSize - individualImageWidth) / 2;
                const paddingY = (individualImageSize - individualImageHeight) / 2;
      
                context.drawImage(
                  spriteSheet,
                  x * individualImageWidth,
                  y * individualImageHeight,
                  individualImageWidth,
                  individualImageHeight,
                  paddingX, // x 좌표에 패딩 추가
                  paddingY, // y 좌표에 패딩 추가
                  individualImageWidth,
                  individualImageHeight
                );
      
                const imageDataUrl = canvas.toDataURL("image/png");
                imageUrls.push(imageDataUrl);
              }
            }
            // 추출된 이미지 배열 설정
            const payload = {
              ...basePayload,
              imageUrls,
              spriteSheetUrl
            };
            resolve({ func: updateQuickViewImageUrls, payload });
          };
        });
      };
      
  
      const dispatchPromises = []

      const getPromises = ({sid, data, type}) => {
        const spriteSheetUrl = data.sprite_sheet_url;
        const informationJsonUrl = data.image_info_url;
        const basePayload = {sid, type, data}
        
        if (spriteSheetUrl) {
          const loadImagePromise = loadImageAndExtract(spriteSheetUrl, basePayload);
          dispatchPromises.push(loadImagePromise);
        }
        if (informationJsonUrl) {
          const informationJsonPromise = axios.get(informationJsonUrl).then(res => {
            const func = updateQuickViewImageInformation
            const payload = {
              ...basePayload,
              imageInformation: res.data,
              image_info_url: data.image_info_url
            }
            return {func, payload}
          });
          dispatchPromises.push(informationJsonPromise);
        }
      }
      const checkedKeys = []
  
      quickViewDataList.forEach(studyInfo => {
        const sid = studyInfo.sid;
        const date = studyInfo.date
        checkedKeys.push(date)

        dataClasses.forEach(dataClass => {
          studyInfo[dataClass]?.forEach(data => {
            checkedKeys.push(data.image_info_url)
            getPromises({sid, data, type: dataClass})  
          })
        })
      });
  
      // 모든 promise가 완료되면 한꺼번에 dispatch
      if (dispatchPromises.length) {
        Promise.all(dispatchPromises)
          .then(results => {
            results.forEach(result => {
              const func = result?.func;
              const payload = result?.payload
              if (payload) {
                dispatch(func(payload));
              }
            });
            dispatch(updateQuickViewController({checkedKeys}))
            setLoading(false)
          });
      }
      else {
        setLoading(false)
      }
    }
  }, [quickViewDataList?.length]);

  const onClose = () => dispatch(clearQuickViewData())

  const filteredQuickViewDataList = quickViewDataList?.filter(studyInfo => {
    const urls = dataClasses.map(dataClass => {
      return studyInfo[dataClass]?.map(data => data.image_info_url) || []
    }).flat()
    
    if (urls.some(url => controllerInfo?.checkedKeys?.includes(url))) {
      return true
    }
    return false
  })

  useEffect(() => {
    const controllerEl = document.getElementById('controller')
    const controllerElHeight = controllerEl?.getBoundingClientRect()?.height
    if (controllerElHeight !== controllerHeight) {
      setControllerHeight(controllerElHeight)
    }
  })

  const Title = () => {
    const display = useSelector(state => state.analysis?.display)
    
    const onLoad = () => {
      const payload = {...display}
      payload.volumeInfo = volumeInfo
      dispatch(updateDisplay(payload))
      dispatch(getUrl(volumeInfo))
      onClose()
      return
    }
    return (
      <>
        <Space 
          style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginRight: 30,
          }}
        >
          <h4 style={{margin: 0}}>Quick view</h4>
          <Button type="primary" onClick={onLoad}>Load</Button>
        </Space>
      </>
    )
  }
  
  return (
    <>
      {
        loading && open ? 
        <LoadingModal loading={loading} /> :
        <Modal
          open={open}
          width={MODAL_WIDTH}
          onCancel={onClose}
          footer={null}
          style={{ 
            top: 40,
          }}
          title={<Title />}
        >
          <div
            id={'quick-view-modal'}
            style={{
              width: '100%',
              height: '80vh',
              overflowY: 'scroll',
              position: 'relative'
            }}
          >
            <Space
              style={{
                width: '100%',
                position: 'sticky',
                zIndex: 4,
                top: 0,
                backgroundColor: 'rgb(13, 45, 53)',
                paddingBottom: 12,
                display: 'flex', 
                alignItems: 'end',
                justifyContent: 'space-between'
              }}
              id="controller"
            >
              <div>
                <Typography.Title level={5} style={{margin: 0}}>
                  {volumeInfo && volumeInfo?.taskName}
                </Typography.Title>
              </div>
              <div>
                <Controller />
              </div>
            </Space>
            <Row id={'quick-view-content'} style={{width: '100%'}}>
              {filteredQuickViewDataList?.map((studyInfo, index) => {
                const getWidth = () => {
                  if (align === VERTICAL) {
                    const imageContentWidth = MODAL_CONTENT_WIDTH - IN_OUTPUT_COLUMN_WIDTH
                    return imageContentWidth / filteredQuickViewDataList.length
                  }
                  return MODAL_CONTENT_WIDTH / filteredQuickViewDataList.length
                }
                
                const datePaddingLeft = (index === 0 && align === VERTICAL) && IN_OUTPUT_COLUMN_WIDTH

                const width = getWidth()
                return (
                  <Col
                    style={{
                      width: 
                        index === 0 && align === VERTICAL ? 
                        width + IN_OUTPUT_COLUMN_WIDTH : 
                        width
                    }}
                  >
                    {isLongitudinalMode && 
                      <TypographyDesc 
                        id={'longitudinal-date'}
                        style={{
                          textAlign: 'center',
                          position: 'sticky',
                          zIndex: 3,
                          top: controllerHeight,
                          backgroundColor: 'rgb(14, 48, 56)',
                          paddingLeft: datePaddingLeft
                        }}
                      >
                        {studyInfo.date}
                      </TypographyDesc>
                    }
                    <ImageViewer
                      studyInfoList={filteredQuickViewDataList}
                      studyInfo={studyInfo}
                      studyIndex={index}
                      width={width}
                    />
                  </Col>
                )
              })}
            </Row>
          </div>
        </Modal>
      }
      {parallelViewOpen&& <ParallelViewModal />}
    </>
  )
}

export default AnalysisQuickViewModal


const ImageViewer = ({studyInfoList, studyInfo, studyIndex, width}) => {
  const controllerInfo = useSelector(state => state?.analysis?.quickView?.controller)

  const align = controllerInfo?.align || DEFAULT_ALIGN
  const checkedKeys = controllerInfo?.checkedKeys || []
  const sid = studyInfo.sid
  const isLongitudinalMode = useSelector(state => state?.analysis?.quickView?.volumeInfo?.mode === MODE.MULTI)
  
  const [inputs, outputs] = dataClasses.map(dataClass => {
    return studyInfo?.[dataClass]?.filter((data, index) => {
      const visible = checkedKeys.includes(data.image_info_url)
      const displayNone = studyInfoList.every(studyInfo => 
        !checkedKeys.includes(studyInfo[dataClass][index].image_info_url)) || 
          (!isLongitudinalMode && !visible)
      return !displayNone
    }) || []
  })

  const longitudinalDataLength = (inputs.length + outputs.length) || 1

  return (
    <Row id={`case-${studyIndex}`}>
      {[inputs, outputs].map((dataList, listIndex) => {
        const type = listIndex === 0 ? INPUT : OUTPUT
        
        const getStickyTop = () => {
          const controllerEl = document.getElementById('controller')
          const controllerElHeight = controllerEl?.getBoundingClientRect()?.height || 0

          const dateEl = document.getElementById('longitudinal-date')
          const dateElHeight = dateEl?.getBoundingClientRect()?.height || 0
          return controllerElHeight + dateElHeight
        }

        return (
          <>
            {dataList.length > 0 && 
              <>
                {studyIndex === 0 && align === VERTICAL &&
                  <Col style={{width: IN_OUTPUT_COLUMN_WIDTH}}>
                    <div style={{height: '100%'}}>
                      <span
                        style={{
                          position: 'sticky',
                          top: getStickyTop(),
                          height: 0,
                          overflow: 'visible',
                          float: 'left',
                          width: '100%',
                          marginLeft: 4,
                        }}
                      >
                        <h3 style={{margin: 0}}>{type}</h3>
                      </span>
                      <Text 
                        style={{
                          width: `${IN_OUTPUT_COLUMN_WIDTH}px`,
                          display: 'inline-block',
                          height: '100%',
                        }}
                      >
                        <pre style={{margin: 0, padding: 0, height: '100%', backgroundColor: 'rgb(14, 48, 56)',}}/>
                      </Text>
                    </div>
                  </Col>
                }
                <Col 
                  style={{
                    width: align === HORIZONTAL ? undefined : width, 
                    display: align === HORIZONTAL && 'flex'
                  }}
                >
                  {dataList.map((data, index) => {
                    const visible = checkedKeys.includes(data.image_info_url)
                    return (
                      <>
                        <Col 
                          style={{
                            visibility: visible ? 'visible' : 'hidden',
                            position: 'relative',
                            display: 'inline-block',
                            flex: align === HORIZONTAL ? 1 : undefined,
                            width: '100%'
                          }}
                        >
                          <div
                            id={`case-${studyIndex}-${type}-${index}`}
                            style={{
                              display: 'inline-block',
                              width: (isLongitudinalMode && align === HORIZONTAL) ?
                                width / longitudinalDataLength :
                                '100%'
                            }}
                          >
                            <QuickDataViewer 
                              data={data} 
                              sid={sid} 
                              type={type}
                              index={index}
                            />
                          </div>
                        </Col>
                      </>
                    )
                  })}
                </Col>
                <br/>
              </>
            }
          </>
        )
      })}
    </Row>
  )
}

const QuickDataViewer = ({data, type, index, id}) => { 
  const dispatch = useDispatch()
  const imageInfo = data?.imageInformation
  const align = useSelector(state => state?.analysis?.quickView?.controller?.align) || DEFAULT_ALIGN
  const [stickyTop, setStickyTop] = useState(0)

  useEffect(() => {
    const controllerEl = document.getElementById('controller')
    const controllerElHeight = controllerEl?.getBoundingClientRect()?.height || 0

    const dateEl = document.getElementById('longitudinal-date')
    const dateElHeight = dateEl?.getBoundingClientRect()?.height || 0
    const top = controllerElHeight + dateElHeight

    if (top !== stickyTop) {
      setStickyTop(controllerElHeight + dateElHeight)
    }
  },[data])

  const quickViewRow = useSelector(state => state?.analysis?.quickView?.controller?.row) || DEFAULT_ROW

  const openParallelView = ({imageIndex}) => {
    const blobtypeId = data.blobtype_id
    return dispatch(updateParallelView({
      open: true,
      current: imageIndex,
      key: {type, blobtypeId, dataIndex: index}
    }))
  }

  return (
    <div>
      <TagInformation
        tagStyle={{
          position: 'sticky',
          top: stickyTop,
          float: 'left',
          height: 0,
          overflow: 'visible',
          width: '100%',
          zIndex: 1,
        }}
        run_id={data.run_id}
        type={align === HORIZONTAL && type}
        imageInfo={imageInfo}
      />
      <Row>
        {data?.imageUrls?.map((url, imageIndex) => {
          return (
            <Col 
              span={quickViewRow}
              onClick={() => openParallelView({imageIndex})}
            >
              <AntImage 
                src={url}
                width="100%"
                height="auto"
                preview={false}
                style={{cursor: 'pointer',}}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

const Controller = () => {
  const dispatch = useDispatch()
  const quickViewDataList = useSelector(state => state.analysis?.quickView?.list)
  const controllerInfo = useSelector(state => state?.analysis?.quickView?.controller)
  const [filterOpen, setFilterOpen] = useState(false)
  const [link, setLink] = useState(false) // tree에서 tag를 check할 때 동일한 tag를 한꺼번에 체크를 바꿈
  const [treeHeight, setTreeHeight] = useState('60vh')

  const isLongitudinalMode = useSelector(state => state?.analysis?.quickView?.volumeInfo?.mode === MODE.MULTI)

  const gridOptions = Array.from({ length: SPRITE_SHEET_IMAGE_COUNTS }, (_, index) => 
    index + 1).filter(num => SPRITE_SHEET_IMAGE_COUNTS % num === 0).map(num => {
      return {
        label: `${SPRITE_SHEET_IMAGE_COUNTS/num} x ${num}`,
        value: num
      }
  })

  const alginOptions = [
    {
      label: 'Vertical',
      value: VERTICAL
    },
    {
      label: 'Horizontal',
      value: HORIZONTAL
    }
  ]

  const layoutOptions = alginOptions.map(alignOpt => {
    return {
      ...alignOpt,
      options: gridOptions.map(gridOpt => {
        return {
          ...gridOpt,
          selectLabel: `${gridOpt.label} (${alignOpt.label})`,
          label: `${gridOpt.label}`,
          value: `${gridOpt.value}-${alignOpt.value}`
        }
      })
    }
  })

  const onLayoutChange = value => {
    const [row, align] = value.split('-')
    dispatch(updateQuickViewController({row, align}))
  }
  
  const dataSelectTreeData = quickViewDataList.map(studyInfo => {
    const studyInfoKey = `${studyInfo.date}-${studyInfo.sid}`
    return ({
      title: studyInfo.date,
      key: studyInfoKey,
      children: dataClasses.map(dataClass => {
        const type = dataClass === 'inputs' ? INPUT : OUTPUT
        
        const children = studyInfo[dataClass]?.map((data, index) => {
          const tag = <TagInformation 
            run_id={data.run_id}
            type={type}
            imageInfo={data.imageInformation}
            typeNameLocation={TYPE_NAME_LOCATION.FRONT}
          />
          const {image_info_url} = data
          return ({
            title: tag,
            key: image_info_url
          })
        }) || []

        const inOutputKey = `${studyInfoKey}-${type}`
        
        return ({
          title: type,
          key: inOutputKey,
          children
        })
      }).filter(data => data.children.length)
    })
  })

  const onTreeCheck = (checkedKeys, e) => {
    if (isLongitudinalMode && link) {
      const selectedPos = e.node.pos // 0-2-0-3 (root, date, in-output, tag)
      const checked = e.checked
      const splittedPos = selectedPos.split('-')
      const isInputSelected = splittedPos.length >= 3
      const inOutputLocation = parseInt(splittedPos[2])
      const isTagSelected = splittedPos.length === 4
      const tagLocation = parseInt(splittedPos[3])
      
      const newCheckedKeys = dataSelectTreeData.map(studyInfo => {
        return studyInfo.children.map((inOutput, IO_index) => {
          if (isInputSelected && IO_index !== inOutputLocation) return []
          return inOutput.children.map((data, dataIndex) => {
            if (isTagSelected && tagLocation !== dataIndex) return []
            return checked ? data.key : [data.key, inOutput.key, studyInfo.key]
          })
        })
      }).flat(Infinity).filter(data => data)
      
      if (checked) {
        const filteredKeys = newCheckedKeys.filter(data => !checkedKeys.includes(data))
        
        return dispatch(updateQuickViewController({checkedKeys: [...checkedKeys, ...filteredKeys]}))
      }
      else {
        const uniqueCheckedKeys = [...new Set(newCheckedKeys)]
        const filteredKeys = checkedKeys.filter(data => !uniqueCheckedKeys.includes(data))
        
        return dispatch(updateQuickViewController({checkedKeys: filteredKeys}))
      }
    }
    else {
      return dispatch(updateQuickViewController({checkedKeys}))
    }
  }

  const onFilterTreeToggle = open => setFilterOpen(open)

  const row = controllerInfo?.row || DEFAULT_ROW
  const align = controllerInfo?.align || DEFAULT_ALIGN
  const layoutValue = `${row}-${align}`

  const onLinkChange = check => setLink(check)
  
  useEffect(() => {
    const quickViewModalEl = document.getElementById('quick-view-modal')
    const quickViewModalElHeight = quickViewModalEl?.getBoundingClientRect()?.height
    const treeGroupSwitchEl = document.getElementById('tree-group-switch')
    const treeGroupSwitchElHeight = treeGroupSwitchEl?.getBoundingClientRect()?.height
    const popOverPadding = 24
    const newTreeHeight = quickViewModalElHeight - treeGroupSwitchElHeight - popOverPadding
    if (newTreeHeight !== treeHeight) {
      setTreeHeight(newTreeHeight)
    }
  })

  return (
    <Space 
      style={{
        display: 'flex',
        justifyContent: 'right'
      }}
    >
      <div>
        <span style={{marginRight: 5}}>Layout</span> 
        <Select 
          options={layoutOptions} 
          style={{width: 200}} 
          onChange={onLayoutChange}
          value={layoutValue}
          getPopupContainer={trigger => trigger.parentNode}
          showSearch={true}
          optionLabelProp={'selectLabel'}
          optionFilterProp="label"
        />
      </div>
      <div 
        style={{
          marginLeft: 20,
          marginRight: 20, 
        }}
      >
        <Popover 
          title={null} 
          content={
            <div 
              style={{
                width: 'max-content',
              }}
            >
              {isLongitudinalMode &&
                <div style={{display: 'flex', justifyContent: 'right'}} id="tree-group-switch">
                  <Switch 
                    checked={link} 
                    onChange={onLinkChange}
                    checkedChildren="Group" 
                    unCheckedChildren="Group"
                    style={{width: 80, marginBottom: 10}}
                  />
                </div>
              }
              <Tree
                checkable
                treeData={dataSelectTreeData}
                selectable={false}
                onCheck={onTreeCheck}
                checkedKeys={controllerInfo?.checkedKeys}
                autoExpandParent={true}
                defaultExpandAll={true}
                height={treeHeight}
              />
            </div>
          }
          open={filterOpen}
          getPopupContainer={trigger => trigger}
          destroyTooltipOnHide={true}
          placement="leftTop"
          trigger={'click'}
          onOpenChange={onFilterTreeToggle}
        >
          <Button>Filter</Button>
        </Popover>
      </div>
    </Space>
  )
}


const TagInformation = ({run_id, imageInfo, tagStyle, type, typeNameLocation=TYPE_NAME_LOCATION.BOTTOM}) => {
  // typeLocation='bottom' 'front'
  const imageTypes = useSelector(state => state.analysis?.imageTypes)
  const {getTagFromTypeId, getTypeFromTypeId} = useGetImageTypeHooks(imageTypes)
  const tasks = useSelector(state => state.analysis?.tasks)
  const targetTask = tasks.find(task => task.run_id === run_id)
  
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [childrenWidth, setChildrenWidth] = useState(0)
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const curEllipsisStatus = textRef.current.scrollWidth > textRef.current.clientWidth
      if (curEllipsisStatus !== isEllipsis) {
        setIsEllipsis(curEllipsisStatus);
      }
      if (childrenWidth !== (textRef.current.scrollWidth + 16)) {
        setChildrenWidth(textRef.current.scrollWidth + 16)
      }
    }
  })

  const blobtypeId = imageInfo?.target
  if (!blobtypeId) {
    return <></>
  }
  const outputType = getTypeFromTypeId(blobtypeId)
  let outputTagName = targetTask.order > 0 ? `${outputType.short}${TASK_DELIMITER}${targetTask.order}` : outputType.short
  if (imageInfo?.alias) {
    outputTagName = `${outputTagName} (${imageInfo?.alias})`
  }
  const outputTag = getTagFromTypeId(blobtypeId, outputTagName)
  const baseImageId = imageInfo?.base_image

  let baseImageTag
  if (baseImageId) {
    const inputs = targetTask?.input
    const selectedInput = inputs?.find(input => input.selected_id === baseImageId)?.selected
    const baseTagName = selectedInput
    baseImageTag = getTagFromTypeId(baseImageId, baseTagName)
  }

  const imagetypeTag = (
    <>
      {type && typeNameLocation === 'front' &&
        <h4 style={{display:'inline-block', marginRight: 6}}>{type}</h4>
      }
      {outputTag}
      {baseImageTag && 
        <>
          <span style={{marginRight: 6}}>/</span>
          <span>{baseImageTag}</span>
        </>
      }
      {type && typeNameLocation === 'bottom' &&
        <>
          <br />
          <h4 style={{display:'inline-block'}}>{type}</h4>
        </>
      }
    </>
  )

  return (
    <span style={{...tagStyle}}>
      <div
        ref={textRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          backgroundColor: 'transparent',
          pointerEvents: isEllipsis ? undefined : 'none'
        }} 
      >
        <Tooltip 
          title={imagetypeTag} 
          open={isEllipsis ? undefined : false}
          overlayInnerStyle={{
            width: childrenWidth + 10,
          }}
          color={'#0C2329'}
          zIndex={3005}
          placement="bottomRight"
        >
          {imagetypeTag}
        </Tooltip>
      </div>
    </span>
  )
}

const ParallelViewModal = () => {
  const dispatch = useDispatch()
  const studyList = useSelector(state => state?.analysis?.quickView?.list)
  const controllerInfo = useSelector(state => state?.analysis?.quickView?.controller)
  const open = useSelector(state => state.analysis?.quickView?.parallelView?.open)
  
  const mode = useSelector(state => state.analysis?.quickView?.volumeInfo?.mode)
  const isLongitudinalMode = mode === MODE.MULTI

  const [divisor, serDivisor] = useState(2)
  const [tagView, setTagView] = useState(true)
  
  const current = useSelector(state => state.analysis?.quickView?.parallelView?.current)
  const checkedKeys = controllerInfo.checkedKeys

  const targetStudyList = studyList.map(studyInfo => {
    const inputs = studyInfo?.inputs?.filter(data => checkedKeys.includes(data.image_info_url)) || []
    const outputs = studyInfo?.outputs?.filter(data => data?.image_info_url && checkedKeys.includes(data.image_info_url)) || []
    const dataCounts = inputs.length + outputs.length
    return ({...studyInfo, inputs, outputs, dataCounts})
  }).filter(studyInfo => studyInfo.dataCounts)

  const viewDataCount = Math.max(...targetStudyList.map(studyInfo => {
    return studyInfo.dataCounts
  }))
  
  const getRowWidthPercent = () => {
    // image 갯수에 따라 좌우 총 width를 맞춰 최대한 이미지를 크게 보게 만듬.
    // image 갯수가 많으면 문제 x
    if (isLongitudinalMode) {
      const studyCount = targetStudyList.length
      if (studyCount === 3 && viewDataCount === 2) return 0.75
      if (studyCount === 2 && viewDataCount === 2) return 0.5
    }
    else {
      if (viewDataCount === 1) return 0.5
      else if ([5,6].includes(viewDataCount)) return 0.7
      else if ([7,8].includes(viewDataCount)) return 0.8
    }
    return 1
  }
  const widthPercent = getRowWidthPercent()
  
  const closeParallelView = () => dispatch(updateParallelView({open: false}))
  const updateCurrentImageNumber = current => dispatch(updateParallelView({current}))
  
  const onChangeSlideNumber = (value, current) => {
    if (value > 0 && current + 1 <= 23) {
      updateCurrentImageNumber(current + 1)
    }
    else if (value < 0 && current - 1 >= 0) {
      updateCurrentImageNumber(current - 1)
    }
  }

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'ArrowLeft') onChangeSlideNumber(-1, current)
      else if (e.key === 'ArrowRight') onChangeSlideNumber(1, current)
    }
    
    document.addEventListener('keydown', handleKeyDown)
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  },[current])

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const margin = 24;
    const headerHeight = 22;
    const imageAspectRatio = 1;
    let bestNRows = 1; // 초기값: 1 Row
    let bestTotalRatio = 0; // 초기값: 비율의 합
    
    let usableHeightWithoutHeader = windowHeight - 2 * margin - headerHeight;
    let usableWidth = windowWidth - 2 * margin
    
    if (isLongitudinalMode) {
      const dateEl = document.getElementById('header-date')
      const dateElHeight = dateEl?.getBoundingClientRect()?.height || 0
      usableHeightWithoutHeader -= dateElHeight

      usableWidth = usableWidth/targetStudyList.length
    }

    // usableHeightWithHeader를 고정한 상태에서 반복
    for (let nRows = 1; nRows <= viewDataCount; nRows++) {
      const cardWidth = usableWidth / Math.ceil(viewDataCount/nRows) * widthPercent
      const cardHeight = cardWidth * imageAspectRatio
    
      // heightRatio 중 제일 나은 비율의 row 사용하여 render
      const heightRatio = (cardHeight * nRows / usableHeightWithoutHeader);
    
      // 조건을 만족하면 최적의 nRows 업데이트
      if (heightRatio <= 1 && heightRatio >= bestTotalRatio) {
        bestTotalRatio = heightRatio;
        bestNRows = nRows;
      }
    }
    if (bestNRows !== divisor) {
      serDivisor(bestNRows)
    }
  },[viewDataCount])

  useEffect(() => {
    const handleWheel = e => {
      e.preventDefault();
      onChangeSlideNumber(e.deltaY, current)
    }
    const element = document.getElementById('parallel-view')
    element?.addEventListener('wheel', handleWheel, { passive: false })
    return () => {
      element.removeEventListener('wheel', handleWheel);
    }
  })

  const ImageViewComponent = ({studyInfo, width}) => {
    return (
      <>
        {dataClasses.map(dataClass => {
          const targetDataList = studyInfo?.[dataClass] || []
          const type = dataClass === 'inputs' ? INPUT : OUTPUT
          return targetDataList.map(data => {
            const run_id = data.run_id
            const imageInfo = data.imageInformation
            const urls = data.imageUrls
            const targetUrl = urls[current]
            
            return (
              <div
                style={{
                  width,
                  display: isLongitudinalMode && 'inline-block'
                }}
              >
                {tagView && 
                  <TagInformation
                    type={type}
                    run_id={run_id}
                    imageInfo={imageInfo}
                    tagStyle={{
                      textAlign: 'left',
                      position: 'sticky',
                      zIndex: 1,  
                      float: 'left',
                      height: 0,
                      overflow: 'visible',
                      width: '100%'
                    }}
                  />
                }
                <AntImage 
                  src={targetUrl}
                  width={'100%'}
                  preview={false}
                />
              </div>
            )
          })
        })}
      </>
    )
  }

  return (
    <TransparentBGModal
      open={open}
      zIndex={3000}
      onCancel={closeParallelView}
      footer={null}
      title={null}
      width={'100vw'}
      centered
      bodyStyle={{height: '100vh'}}
      maskStyle={{backgroundColor: 'rgba(0,0,0,0.8)'}}
    >
      <Space 
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'}}
        onClick={e => {
          e.stopPropagation()
          closeParallelView()
        }}
      >
        <div 
          onClick={e => e.stopPropagation()}
          style={{width: 0, overflow: 'visible'}}
        >
          <Switch 
            checkedChildren="Imagetype tag" 
            unCheckedChildren="Imagetype tag"
            checked={tagView}
            onChange={checked => setTagView(checked)}
            style={{width: 130}}
          />
        </div>
        <div>{current + 1} / {SPRITE_SHEET_IMAGE_COUNTS}</div>
        <div />
      </Space>
      <div
        id="parallel-view"
        style={{
          display: "flex",
          height : '100%',
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={e => {
          e.stopPropagation()
          if (e.target.id === 'parallel-view')
            closeParallelView()
        }}
      >
        <Row 
          style={{width: `${100 * widthPercent}%`}}
          id={'parallel-view-row'}
          onClick={e => {
            e.stopPropagation()
            if (e.target.id === 'parallel-view-row')
              closeParallelView()
          }}
        >
          {isLongitudinalMode ?
            <>
              {[targetStudyList].map(studyList => {
                const getImageWidth = () => {
                  const firstLineCount = Math.ceil(viewDataCount / divisor)
                  return `${100/firstLineCount}%`
                }

                const width = getImageWidth()
                
                return studyList.map(studyInfo => {
                  return (
                    <Col style={{width: `${100 / (studyList.length)}%`}}>
                      <div>
                        <TypographyDesc
                          style={{
                            textAlign: 'center'
                          }}
                          id="header-date"
                        >
                          {studyInfo.date}
                        </TypographyDesc>
                        <div 
                          style={{
                            backgroundColor: 'rgba(128,128,128, 0.1)',
                            padding: 8
                          }}
                        >
                          <ImageViewComponent 
                            studyInfo={studyInfo}
                            width={width}
                          />
                        </div>
                      </div>
                    </Col>
                  )
                })
              })}
            </> : 
            <>
              {targetStudyList.map(studyInfo => {
                const getImageWidth = () => {
                  if (viewDataCount <= 4) {
                    return `${100/viewDataCount}%`
                  }
                  const firstLineCount = Math.ceil(viewDataCount / divisor)
                  return `${100/firstLineCount}%`
                }

                const width = getImageWidth()
                return (
                  <ImageViewComponent 
                    studyInfo={studyInfo}
                    width={width}
                  />
                )
              })}
            </>
          }
        </Row>
      </div>
    </TransparentBGModal>
  )
}