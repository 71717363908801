import { Collapse, Modal, Result, Tooltip, Typography } from "antd"

const {Title} = Typography
const { Panel } = Collapse;
export const ELLIPSIS_LIMIT = 30
export const ITEM_ELLIPSIS_LIMIT = 5

const AskModal = ({askData, handleOk, handleCancel, loading, title="Do you want to delete it?", addSubTitle}) => {
  const visible = askData?.modalVisible
  const affectedList = askData?.affectedList || []
  
  const handleCancelBlock = (e) => {if (e.target.innerText === 'Cancel') {handleCancel()}}

  return (
    <Modal
      closable={false}
      open={visible}
      onOk={handleOk}
      style={{
        minWidth: 600
      }}
      okText='Approve'
      centered
      onCancel={handleCancelBlock}
      confirmLoading={loading}
    >
      <Result
        status="warning"
        style={{padding : '0'}}
        title={(
          <>
            <Title level={3} style={{textAlign:'center'}} type="warning">
              {title}
            </Title>
          </>
        )}
        subTitle={(
          <div style={{textAlign : 'left', marginTop: 50}}>
            {addSubTitle}
            <Title level={5} style={{marginBottom : '10px'}} type="warning">This process affects below</Title>
            <Collapse>
              {affectedList.map(affectItem => {
                return (
                  <Panel header={`${affectItem.name}`}>
                    {affectItem.list.map((item, index) => {
                      const isTooLong = item.length > ELLIPSIS_LIMIT
                      const text = isTooLong ? `${item.slice(0,ELLIPSIS_LIMIT)}...` : item
                      return (
                        <div style={{marginLeft : '12px'}}>
                          <span>{`• `}</span>
                          <span>
                            <Tooltip title={item} open={isTooLong ? undefined : false}>{`${text}`}</Tooltip>
                          </span>
                        </div>
                      )})}
                    {affectItem.count > ITEM_ELLIPSIS_LIMIT
                    ? (
                      <div style={{marginLeft : '12px'}}>
                        ... more
                      </div>
                      )
                    : null}
                  </Panel>
                )
              })}
            </Collapse>
          </div>
        )}
        extra={
          <>
            <Title level={4} type="warning">
              It could take a while
            </Title>
            <Title level={4} type="danger">
              You can't undo this process
            </Title>
          </>
        }
      />
    </Modal>
  )
}

export default AskModal;