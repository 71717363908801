import { SETTING, REPORT_TYPE, getRandomKey } from "./utils"

const INITIALIZE = 'initialize'
const UPDATE_NAME = 'update name'
const UPDATE_REPORT = 'update report'
const LOAD_TEMPLATE = 'load template'
const ADD_PAGE = 'add page'
const REMOVE_PAGE = 'remove page'
const UPDATE_ACTIVE = 'update active'
const UPDATE_PAGE = 'update page'
const UPDATE_PAGE_PROPERTY = 'update page property'
const UPDATE_PAGE_SETTING_PROPERTY = 'update page setting property'
const UPDATE_PAGE_PREVIEW_PROPERTY = 'update page preview property'
const UPDATE_PAGE_ROW_CONFIG = 'update page row config'
const DELETE_PAGE_ROW_CONFIG = 'delete page row config'
const UPDATE_PAGE_TRACK_IMAGE_PROPERTY = 'update page track image property'
const UPDATE_PAGE_SETTING_TARGET_PROPERTY = 'update page track image graph setting property'
const UPDATE_TABLE_ROW = 'update table row'

const newPage = {
  key: getRandomKey(),
  title: 'new page-1',
  active: true,
  type: REPORT_TYPE.GRAPH_N_TABLE,
  [REPORT_TYPE.GRAPH_N_TABLE]: [],
}

export const initialState = {
  name: 'new report',
  pages: [JSON.parse(JSON.stringify(newPage))],
}

export const reportReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return JSON.parse(JSON.stringify(initialState))
    case UPDATE_NAME:
      return {
        ...state,
        name: action.payload
      }
    case UPDATE_REPORT:
      return {
        ...state,
        ...action.payload
      }
    case LOAD_TEMPLATE:
      return {
        ...state,
        ...action.payload,
        pages: action.payload?.pages?.map(page => {
          return {
            ...page,
            key: getRandomKey()
          }
        })
      }
    case ADD_PAGE:
      {
        const newPage = {title: `new page-${state.pages.length + 1}`, active: true};
        const existPage = [...state.pages].map(page => ({...page, active: false}))
        const addedPages = [...existPage, newPage].map((data, index) => ({...data, key: getRandomKey()}));
        return {
          ...state,
          pages: addedPages
        }
      }
    case REMOVE_PAGE:
      {
        const targetKey = action.payload
        const filteredPages = [...state.pages].filter(page => page.key !== targetKey).map(page => ({...page, active: false}))
        if (filteredPages.length) {
          filteredPages.at(-1).active = true
        }
        return {
          ...state,
          pages: filteredPages
        }
      }
    case UPDATE_ACTIVE:
      {
        const newKey = action.payload
        const newPages = [...state.pages].map(page => {
          if (page.key === newKey) {
            return {
              ...page,
              active: true
            }
          }
          return {
            ...page,
            active: false
          }
        })
        return {
          ...state,
          pages: newPages
        }
      }
    case UPDATE_PAGE:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {...action.payload}
          }
          return page
        })
      }
    case UPDATE_PAGE_PROPERTY:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              [action.payload.name]: action.payload.value
            }
          }
          return page
        })
      }
    case UPDATE_PAGE_SETTING_PROPERTY:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              setting: {
                ...page.setting,
                [action.payload.name]: action.payload.value
              }
            }
          }
          return page
        })
      }
    case UPDATE_PAGE_PREVIEW_PROPERTY:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              preview: {
                ...page.preview,
                [action.payload.name]: action.payload.value
              }
            }
          }
          return page
        })
      }
    case UPDATE_PAGE_ROW_CONFIG:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            page[REPORT_TYPE.GRAPH_N_TABLE] = page[REPORT_TYPE.GRAPH_N_TABLE].map(row => {
              if (row.index === action.payload.index) {
                return {
                  ...row,
                  config: {
                    ...row.config,
                    [action.payload.name]: action.payload.value,
                  }
                }
              }
              return row
            })
            return page
          }
          return page
        })
      }
    case UPDATE_PAGE_TRACK_IMAGE_PROPERTY:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              [REPORT_TYPE.TRACK_IMAGE]: {
                ...page[REPORT_TYPE.TRACK_IMAGE],
                [action.payload.name]: action.payload.value
              }
            }
          }
          return page
        })
      }
    case UPDATE_PAGE_SETTING_TARGET_PROPERTY:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              [SETTING]: {
                ...page[SETTING],
                [action.payload.target]: {
                  ...page[SETTING]?.[action.payload.target],
                  [action.payload.name]: action.payload.value
                }
              }
            }
          }
          return page
        })
      }
    case UPDATE_TABLE_ROW:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            return {
              ...page,
              [REPORT_TYPE.GRAPH_N_TABLE]: page[REPORT_TYPE.GRAPH_N_TABLE].map(row => {
                if (row.index === action.payload.index) {
                  return {
                    ...row,
                    ...action.payload.newValue
                  }
                }
                return row
              })
            }
          }
          return page
        })
      }
    case DELETE_PAGE_ROW_CONFIG:
      return {
        ...state,
        pages: state.pages.map(page => {
          if (page.active) {
            page[REPORT_TYPE.GRAPH_N_TABLE] = page[REPORT_TYPE.GRAPH_N_TABLE].map(row => {
              if (row.index === action.payload.index) {
                const config = {...row.config}
                delete config[action.payload.name]
                return {
                  ...row,
                  config: {
                    ...config,
                  }
                }
              }
              return row
            })
            return page
          }
          return page
        })
      }
    default: 
      return state
  }
}

export const initialize = () => {
  return {type: INITIALIZE}
}

export const updateName = payload => {
  return {type: UPDATE_NAME, payload}
}

export const updateReport = payload => {
  return {type: UPDATE_REPORT, payload}
}

export const loadTemplate = payload => {
  return {type: LOAD_TEMPLATE, payload}
}

export const addPage = () => {
  return {type: ADD_PAGE}
}

export const removePage = payload => {
  return {type: REMOVE_PAGE, payload}
}

export const updateActive = payload => {
  return {type: UPDATE_ACTIVE, payload}
}

export const updatePage = payload => {
  return {type: UPDATE_PAGE, payload}
}

export const updatePageProperty = payload => {
  return {type: UPDATE_PAGE_PROPERTY, payload}
}

export const updatePageSettingProperty = payload => {
  return {type: UPDATE_PAGE_SETTING_PROPERTY, payload}
}

export const updatePagePreviewProperty = payload => {
  return {type: UPDATE_PAGE_PREVIEW_PROPERTY, payload}
}

export const updatePageRowConfig = payload => {
  return {type: UPDATE_PAGE_ROW_CONFIG, payload}
}

export const updatePageTrackImageProperty = payload => {
  return {type: UPDATE_PAGE_TRACK_IMAGE_PROPERTY, payload}
}

export const updatePageSettingTargetProperty = payload => {
  return {type: UPDATE_PAGE_SETTING_TARGET_PROPERTY, payload}
}

export const updateTableRow = payload => {
  return {type: UPDATE_TABLE_ROW, payload}
}

export const deletePageRowConfig = payload => {
  return {type: DELETE_PAGE_ROW_CONFIG, payload}
}