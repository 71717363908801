export const UPDATE_DRAWER_OPEN = "longTask/UPDATE_DRAWER_OPEN";
export const ASSIGN_TASK_INDEX = "longTask/ASSIGN_TASK_INDEX"
export const CHANGE_RENDER_INFO = "longTask/CHANGE_RENDER_INFO"
export const UPDATE_TREE_OPEN = "longTask/UPDATE_TREE_OPEN";

export const ADD_TASK = 'longTask/ADD_TASK'
export const UPDATE_TASK = 'longTask/UPDATE_TASK'
export const DELETE_TASK = "longTask/DELETE_TASK";
export const DELETE_ENDED_TASKS = "longTask/DELETE_ENDED_TASKS";

export const TASK_SUCCESS = 'longTask/TASK_SUCCESS'
export const TASK_FAIL = 'longTask/TASK_FAIL'

export const UPLOAD = 'longTask/UPLOAD'
export const UPLOAD_SUCCESS = 'longTask/UPLOAD_SUCCESS'
export const UPLOAD_FAIL = 'longTask/UPLOAD_FAIL'
export const DOWNLOAD_PATIENTS = 'longTask/DOWNLOAD_PATIENTS'
export const DOWNLOAD_PATIENTS_SUCCESS = 'longTask/DOWNLOAD_PATIENTS_SUCCESS'
export const DOWNLOAD_PATIENTS_FAIL = 'longTask/DOWNLOAD_PATIENTS_FAIL'
export const DOWNLOAD_RESULTS = 'longTask/DOWNLOAD_RESULTS'
export const DOWNLOAD_RESULTS_SUCCESS = 'longTask/DOWNLOAD_RESULTS_SUCCESS'
export const DOWNLOAD_RESULTS_FAIL = 'longTask/DOWNLOAD_RESULTS_FAIL'

export const CANCEL_UPLOAD = 'longTask/CANCEL_UPLOAD'
export const CANCEL_UPLOAD_SUCCESS = 'longTask/CANCEL_UPLOAD_SUCCESS'
export const CANCEL_UPLOAD_SUCCESS_FAIL = 'longTask/CANCEL_UPLOAD_SUCCESS_FAIL'

const initialState = {
  upload : {loading : false},
  download : {loading : false},
  drawerOpen: false,
  tasks: [],
  renderInfo : {
    overscanStartIndex : 0,
    overscanStopIndex : 10,
    visibleStartIndex : 0,
    visibleStopIndex : 10
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {

    case UPDATE_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload,
      };

    case ASSIGN_TASK_INDEX:
      return {
        ...state,
        tasks : state.tasks.map(task=> {
          if (task.taskId === action.payload.taskId) {
            task.index = action.payload.index;
            return task;
          } else {
            return task;
          }
        })
      }

    case CHANGE_RENDER_INFO:
      return {
        ...state,
        renderInfo : action.payload
      }

    case UPDATE_TREE_OPEN:
      return {
        ...state,
        tasks: state.tasks.map(task => {
          if (task.taskId === action.payload.taskId) {
            task.treeOpen = action.payload.treeOpen;
            return task;
          } else {
            return task;
          }
        })
      };

    case ADD_TASK:
      return {
        ...state,
        tasks : [action.payload, ...state.tasks ]
      }

    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter(task => {
          return task.taskId !== action.payload.taskId;
        })
      };

    case DELETE_ENDED_TASKS:
      return {
        ...state,
        tasks : state.tasks.filter(task => {
          return !task.ended
        })
      }

    case TASK_SUCCESS:
    case TASK_FAIL:
      return {
        ...state,
        tasks : state.tasks.map(task=> {
          if (task.taskId === action.payload.taskId) {
            return {
              ...task,
              desc : action.payload.desc,
              ended : true
            }
          } else {
            return task
          }
        })
      }

    case UPDATE_TASK:
      return {
        ...state,
        tasks: state.tasks.map(task => {
          if (task.taskId === action.payload.taskId) {
            return {
              ...task,
              ...action.payload
            }
          } else {
            return task
          }
        })
      }
    case UPLOAD:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: true
        }
      }
    case UPLOAD_SUCCESS:
      return {
        ...state,
        upload : {
          ...state.upload,
          loading: false
        }
      }
    case UPLOAD_FAIL:
      return {
        ...state,
        upload : {
          ...state.upload,
          loading: false
        },
        tasks : state.tasks.map(task => {
          if (action.payload.taskIds.indexOf(task.taskId) !== -1) {
            task.ended = true
            task.desc = action.payload.desc
            return task
          } else {
            return task
          }
        })
      }
    case DOWNLOAD_PATIENTS:
      return {
        ...state,
        download : {
          ...state.download,
          loading: true
        }
      }
    case DOWNLOAD_PATIENTS_SUCCESS:
      return {
        ...state,
        download : {
          ...state.download,
          loading: false
        }
      }
    case DOWNLOAD_PATIENTS_FAIL:
      return {
        ...state,
        download : {
          ...state.download,
          loading: false
        },
        tasks : state.tasks.map(task => {
          const targetTaskId = action.payload.taskId
          if (task.taskId === targetTaskId) {
            task.ended = true
            task.desc = action.payload.desc
            return task
          }
          return task
        })
      }
    case DOWNLOAD_RESULTS:
      return {
        ...state,
        download: {
          ...state.download,
          loading: true
        }
      }
    case DOWNLOAD_RESULTS_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          loading: false
        },
      }
    case DOWNLOAD_RESULTS_FAIL:
      return {
        ...state,
        download: {
          ...state.download,
          loading: false
        },
        tasks: state.tasks.map(task => {
          const targetTaskId = action.payload.taskId
          if (task.taskId === targetTaskId) {
            task.ended = true
            task.desc = action.payload.desc
            return task
          }
          return task
        })
      }
    case CANCEL_UPLOAD:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: true
        }
      }
    case CANCEL_UPLOAD_SUCCESS:
      return initialState
    case CANCEL_UPLOAD_SUCCESS_FAIL:
      return initialState
    default:
      return state;
  }
}

export function updateDrawerOpen(payload) {
  return { type: UPDATE_DRAWER_OPEN, payload };
}

export function assignTaskIndex(payload) {
  return {type : ASSIGN_TASK_INDEX, payload}
}

export function changeRenderInfo(payload) {
  return {type : CHANGE_RENDER_INFO, payload}
}

export function updateTreeOpen(payload) {
  return {type : UPDATE_TREE_OPEN, payload }
}

export function addTask(payload) {
  return {type : ADD_TASK, payload}
}

export function deleteTask(payload) {
  return { type: DELETE_TASK, payload };
}

export function deleteEndedTasks(payload) {
  return { type: DELETE_ENDED_TASKS, payload };
}

export function taskSuccess(payload) {
  return {type : TASK_SUCCESS, payload}
}

export function taskFail(payload) {
  return {type : TASK_FAIL, payload}
}

export function updateTask(payload) {
  return {type : UPDATE_TASK, payload}
}

export function upload(payload) {
  return {type : UPLOAD, payload}
}

export function uploadSuccess(payload) {
  return {type: UPLOAD_SUCCESS, payload}
}

export function uploadFail(payload) {
  return {type: UPLOAD_FAIL, payload}
}

export function downloadPatients(payload){
  return {type : DOWNLOAD_PATIENTS, payload}
}

export function downloadPatientsSuccess(payload){
  return {type : DOWNLOAD_PATIENTS_SUCCESS, payload}
}

export function downloadPatientsFail(payload){
  return {type : DOWNLOAD_PATIENTS_FAIL, payload}
}

export function downloadResults(payload){
  return {type : DOWNLOAD_RESULTS, payload}
}

export function downloadResultsSuccess(payload){
  return {type : DOWNLOAD_RESULTS_SUCCESS, payload}
}

export function downloadResultsFail(payload){
  return {type : DOWNLOAD_RESULTS_FAIL, payload}
}

export function cancelUpload() {
  return {type: CANCEL_UPLOAD}
}

export function cancelUploadSuccess() {
  return {type: CANCEL_UPLOAD_SUCCESS}
}

export function cancelUploadFail() {
  return {type: CANCEL_UPLOAD_SUCCESS_FAIL}
}