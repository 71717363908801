export function usePreventLeave() {
  function listener(e) {
    e.preventDefault();
    e.returnValue = '';
  }

  function enablePrevent() {
    window.onbeforeunload = e => listener(e)
  }

  function disablePrevent() {
    window.onbeforeunload = null
  }

  return {enablePrevent, disablePrevent}
}

