import React, { useState, useRef } from "react";
import { Tooltip, Button } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import "./button-group.css";
// import styled from "styled-components";
// import "antd/dist/antd.css";

// const StyledButton = styled( Button )`
//   background-color: @primary-color;
// `;

const ButtonGroup = ({states, shows, viewerDispatch, buttons, icons, onChange, keepLastOne = false, keepOnlyOne = false}) => {
  // const [clickedIds, setClickedIds] = useState(defaultState)
  // const [clickedIds, setClickedIds] = useState(
  //   Array(buttons ? buttons.length : 0).fill(keepLastOne ? true : false))
  const refs = useRef([]);

  // useEffect(() => {
  //   const placeholder = []
  //   buttons.forEach(btn => placeholder.push(false))
  //   setClickedIds(placeholder)
  // }, [buttons])

  const handleClick = (event, buttonIdx) => {
    refs.current.forEach(ref => ref.blur()) // 일단 포커스 날리고

    let newStates = [...states]
    newStates[buttonIdx] = !newStates[buttonIdx]

    const cnt = newStates.reduce((prev, cur) => cur ? prev + 1 : prev, 0)
    if (keepLastOne && cnt === 0) {
      return
    }
    if (keepOnlyOne && cnt > 1) {
      newStates = newStates.map((_, vi) => buttonIdx === vi)
    }

    // setClickedIds(newStates);
    onChange(buttonIdx, newStates);
  };

  return (
    <div>
      {buttons.map((buttonLabel, i) => {
        return !shows || (shows && shows[i]) ?
          <Tooltip key={buttonLabel} title={buttonLabel} getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Button
              size="large"
              id={i}
              key={buttonLabel}
              icon={icons ? <FontAwesomeIcon icon={icons[i]} /> : null}
              ref={el => refs.current[i] = el}
              name={buttonLabel}
              onClick={(event) => handleClick(event, i)}
              className={states && states[i] ? "ant-radio-button-wrapper-checked" : ""}
            >
              {icons ? null : buttonLabel}
            </Button>
          </Tooltip> : null
      })}
    </div>
  );
};

export default ButtonGroup;
