import React, {useState, useEffect} from 'react';
import { Tag, Select, Checkbox, Divider } from 'antd';
import {useSelector} from "react-redux";
import chroma from "chroma-js";
import { TagBase } from './tagBase';

const {Option} = Select;

const ImageTypeCheck = ({options, savedList, handleCheckChange, editing}) => {
  const [checkedList, setCheckedList] = useState(savedList);
  useEffect(() => {
    setCheckedList(savedList);
    setIndeterminate(!!savedList.length && savedList.length < options.length);
    setCheckAll(savedList.length === options.length);
  }, [savedList])

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
    handleCheckChange(list);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? options : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    handleCheckChange(e.target.checked ? options : []);
  };

  const types = useSelector(state => state.blobTypes)

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <TagBase
        color={label.props?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {value}
      </TagBase>
    );
  };

  return (
    <>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        disabled={editing?true:false}
      >
        Select all
      </Checkbox>
      <Divider style={{marginTop:5, marginBottom:10}}/>
      <Select
        mode="multiple"
        value={checkedList}
        onChange={onChange}
        disabled={editing?true:false}
        tagRender={tagRender}
      >
        {types?.list && options ?
          options.map((o, i) => {
            return <Option key={i} value={o}>
              <TagBase
                color={chroma(types.list.find(t => t.short === o).tag_color.split(',')).hex()}
              >{o}</TagBase>
            </Option>
          }) : null
        }
      </Select>
    </>
  );
};

export default ImageTypeCheck;