import {useReducer, useState, useRef} from 'react';
import {
  buildTreeFromPaths,
  findBestMatch,
  findLongestCommonPath,
  getPatientInfo,
  mergePatients,
  uid
} from '../lib/UtilDicomParse'
import dicomParser from "dicom-parser";
import {useDispatch, useSelector} from "react-redux";
import {msgInfo, msgError} from '../redux/modules/message';
import {findDicomImageType, findNiftiImageType} from '../lib/findType';
import {convertBytes, convertBytesPromise} from 'dicom-character-set'
import moment from "moment";

const supported_exts = ['.nii', '.nii.gz']

const initalState = {
  loading: false,
  progress: 0,
  parseCount: 0,
  parseCountMax: 0,
  exception: false,
  dropped: false,
  anonymize: false,
  niftiOnly: false,
  resolveds: [],
  unresolveds: [],
  binds: [],
  loaded: false,
  mergeModalView: false,
  modalAskPatientID: {
    visible: false,
  }
}

const init = (args) => {
  return {
    ...initalState,
    ...args
  }
}

const rebuildBinds = source => {
  return source.map(p => {
    return ({
      label: `${p.pid}`,
      value: p.key,
      children: p.studies.map(s => {
        return ({label: `${s.sid}-${s.date}`, value: s.key})
      })
    })
  })
}

const reducer = (state, action) => {
  switch(action.type) {
    case 'UPDATE_ONLY':
      return {
        ...state,
        ...action.payload
      };
    case 'REPLACE_ONLY': 
      {
        const newState = {...state}
        for(const k of Object.keys(action.payload)) {
          newState[k] = action.payload[k]
        }
        return newState
      }
    case 'REBUILD_BINDS_ONLY':
      return {
        ...state,
        binds: rebuildBinds(action.payload?.binds || state.resolveds) // binds 직접 입력 또는 state 로부터 rebuild
      }
    case 'INIT_THEN_UPDATE':
      return {
        ...initalState,
        ...action.payload
      }
    case 'UPDATE_AND_REBUILD_BINDS':
      return {
        ...state,
        ...action.payload,
        binds: rebuildBinds(action.payload?.resolveds || state.resolveds) // payload 로부터, 없으면 state 에서 만듦
      };
    case 'REPLACE_AND_REBUILD_BINDS': 
      {
        const newState = {...state}
        for(const k of Object.keys(action.payload)) {
          newState[k] = action.payload[k]
        }
        newState.binds = rebuildBinds(action.payload?.resolveds || state.resolveds) // payload 로부터, 없으면 state 에서 만듦
        return newState
      }
    case 'CLEAR':
      return initalState
    default:
      return state;
  }
}

export const useDicomDrop = () => {
  const imageTypeList = useSelector(state => state.blobTypes.list) || [];
  const [state, dispatchDicomDrop] = useReducer(reducer, {}, init);
  const dispatch = useDispatch()

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      dispatch(msgInfo('Empty Folder'))
      return ;
    }
    let counter = 0;
    let progress = 0, prevProgress = 0;

    const dicomData = {};
    const nonDicomData = {};

    let updated = new Date().getTime()

    dispatchDicomDrop({
      type: 'UPDATE_ONLY',
      payload: {
        // loading : true,
        loaded : true,
        parseCountMax: acceptedFiles.length,
        progress: 0
    }})

    const promise = new Promise(resolve => {
      acceptedFiles.forEach(file => {

        // file.path.split('/'), file.name, file.size, file.lastModified

        const reader = new FileReader();
        reader.onload = function(_) {
          const arrayBuffer = reader.result;
          // Here we have the file data as an ArrayBuffer
          // dicomParser requires as input a Uint8Array, so we create that here
          const byteArray = new Uint8Array(arrayBuffer);
          // const kb = byteArray.length / 1024;
          // const mb = kb / 1024;
          // const byteStr = mb > 1 ? mb.toFixed(3) + " MB" : kb.toFixed(0) + " KB";

          function updateProgress() {
            counter++;

            progress = parseInt(counter / acceptedFiles.length * 100);

            const now = new Date().getTime()

            if (now - updated > 100 || counter < 50) {
              updated = new Date().getTime()
              dispatchDicomDrop({
                type: 'UPDATE_ONLY',
                payload: {
                  parseCount: counter,
                  progress: progress,
              }})
            }

            if (counter === acceptedFiles.length) {
              dispatchDicomDrop({
                type: 'UPDATE_ONLY',
                payload: {
                  parseCount: acceptedFiles.length,
                  progress: 100,
              }})
              resolve();
            }
          }

          try {
            // Parse the byte array to get a DataSet object that has the parsed contents
            const dataSet = dicomParser.parseDicom(byteArray/*, options */);

            // pass if this is a Media Storage Directory Storage SOP
            const mediaStorageDirectoryStorageSOP = '1.2.840.10008.1.3.10'
            const mediaStorageSOPClassUIDTag = 'x00020002'
            if (dataSet.string(mediaStorageSOPClassUIDTag) === mediaStorageDirectoryStorageSOP) {
              updateProgress();
              return ;
            }

            const pixelData = dataSet.elements.x7fe00010
            if (!pixelData) {
              updateProgress();
              return ;
            }

            // access a string element
            const patientID = dataSet.string('x00100020');

            // patient name 에서 dicom 표준 character set 지원
            // https://dicom.nema.org/medical/dicom/current/output/html/part05.html#sect_6.2.1
            // https://dicom.nema.org/medical/dicom/current/output/html/part05.html#sect_6.2.1.1
            // const patientName = dataSet.string('x00100010');
            const characterSet = dataSet.string('x00080005')
            const patientNameElement = dataSet.elements.x00100010;
            const patientNameBytes = new Uint8Array(dataSet.byteArray.buffer, patientNameElement.dataOffset, patientNameElement.length);
            const str = convertBytes(characterSet, patientNameBytes, {vr: 'PN'});
            const ideographicCharacterDelimiter = '='
            const personNameGroupComponentDelimiter= '^'
            const firstIdeogram = str.split(ideographicCharacterDelimiter)[0]
            const patientName = firstIdeogram.replaceAll(personNameGroupComponentDelimiter, ' ').trim()

            const patientBirth = dataSet.string('x00100030') ?? '1900-01-01';
            const patientSex = dataSet.string('x00100040') ?? 'O';
            const patientAge = dataSet.string('x00101010') ?? '30';
            // const bolusAgent = dataSet.string('x00180010');
            // const sliceThickness = dataSet.string('x00180050');
            // const pixelSpacing = dataSet.string('x00280030');
            const studyUID = dataSet.string('x0020000d');
            const seriesUID = dataSet.string('x0020000e');
            const studyID = dataSet.string('x00200010');
            const studyDate = dataSet.string('x00080020');
            const studyDesc = dataSet.string('x00081030');
            const seriesNumber = dataSet.string('x00200011');
            const seriesDate = dataSet.string('x00080021');
            const protocolName = dataSet.string('x00181030');
            const seriesDesc = dataSet.string('x0008103e'); // NOTE 마지막 e를 대문자 E로 넣으면 값 못찾아옴 ㅠ
            const instanceNum = dataSet.string('x00200013');

            if (!(patientID in dicomData)) {
              dicomData[patientID] = {
                pid: patientID,
                pname: patientName,
                sex: patientSex,
                birth: patientBirth,
                age: patientAge,
                studies: {}
              };
            }
            const studies = dicomData[patientID].studies;
            if (!(studyUID in studies)) {
              studies[studyUID] = {suid: studyUID, sid: studyID, date: studyDate, desc: studyDesc, series: {}};
            }
            const series = studies[studyUID].series;
            if (!(seriesUID in series)) {
              series[seriesUID] = {
                // type: 'dicom', dicom이란 타입은 없다
                sruid: seriesUID, sn: seriesNumber, date:seriesDate, protocol: protocolName, desc: seriesDesc, files: [],}
            }
            const srs = series[seriesUID];
            const exist = srs.files.some(el => el.instanceNum === instanceNum);
            if (!exist) {
              srs.files.push({instanceNum, file});
            }

            updateProgress()
          }
          catch(ex) {
            const key = file.path
            const exist = supported_exts.some(ext => key.slice(-ext.length) === ext)
            // TODO nifti 구조인 것을 확인해야 한다. 
            // nifti.js 로 확인
            if (exist) {
              nonDicomData[key] = {type: 'nii', file: file, pname: file.path};
            }
            else {
            }
            updateProgress()
          }
        }
        reader.readAsArrayBuffer(file);
      })
    });

    promise.then(() => {
      // if (dropzoneRef.current) {
      //   dropzoneRef.current.style.margin = '8px';
      //   dropzoneRef.current.style.padding = '8px';
      // }
      // sort files by InstanceNumber
      // patient -> study -> series -> files
      // dicomData['12237634'].studies["632553087"].series['401']
      for (const pid in dicomData) {
        const patient = dicomData[pid]
        for (const sid in patient.studies) {
          const study = patient.studies[sid]
          for (const sn in study.series) {
            const series = study.series[sn]
            series.files.sort((a, b) => {
              return parseInt(a.instanceNum) > parseInt(b.instanceNum) ? 1 : parseInt(a.instanceNum) < parseInt(b.instanceNum) ? -1 : 0;
            })
          }
        }
      }
      dispatchDicomDrop({
        type: 'UPDATE_ONLY',
        payload: {
          dropped: true,
          loading: false,
          loaded : true
        }})
      mergeDicomParseResult(dicomData, nonDicomData);
    }).catch(_ => {
      // setException(true);
      dispatchDicomDrop({type: 'UPDATE_ONLY', payload: {exception: true}})
    });
  };

  const askPatientID = (studiesWithPidOptions) => {
    studiesWithPidOptions.sort((a, b) => {
      return a.study.series[0].files[0].file.path > b.study.series[0].files[0].file.path ? 1 : -1
    })
    return new Promise((resolve) => {
      dispatchDicomDrop({
        type: 'UPDATE_ONLY',
        payload: {
          modalAskPatientID: {
            visible: true,
            source: studiesWithPidOptions,
            onOk : newMappings => {
              const unresolveds = newMappings.filter(i => !i?.selected)
              if (unresolveds.length > 0) {
                const pidsReveal = unresolveds.filter((i, idx) => idx < 5).map(i => i.study.pid)
                dispatch(msgError('Please select new patient ID: ' + pidsReveal.join(', ') + (pidsReveal.length > 5 ? '... more' : '')))
                return
              }
              dispatchDicomDrop({
                type: 'UPDATE_ONLY',
                payload: { modalAskPatientID: {visible: false} }
              })
              resolve(newMappings)
            },
            onCancel : (e) => {
              dispatchDicomDrop({
                type: 'UPDATE_ONLY',
                payload: { modalAskPatientID: {visible: false} }
              })
            }
          }
        }
      })
    })
  }

  const findNodeByLCP = (lcpSplit, tree, branches) => {
    const candidates = []
    branches.forEach(br => {
      const isParent = lcpSplit.every((dir, idx) => {
        return idx < br.paths.length ? dir === br.paths[idx] : true
      })
      if (lcpSplit.length > br.paths.length && isParent) {
        candidates.push(br.v)
      }
    })

    if (candidates.length === 1) {
      return candidates[0]
    }

    return null
  }

  const collectBranches = (root, branches) => {
    root.forEach(node => {
      if (node.children.length >= 2) {
        branches.push(node)
      }

      collectBranches(node.children, branches)
    })
  }

  const getPatients = async (dicomParsed) => {
    if (state.anonymize) {
      // parsing 된 patient 정보 무시
      const studies = []
      for (const [pid, patient] of Object.entries(dicomParsed)) {
        for (const [suid, study] of Object.entries(patient.studies)) {
          const study_row = studies[studies.push({
            key: uid(),
            pid: patient.pid,
            sid: study.sid,
            date: study.date,
            desc: study.desc,
            series: [],
            blobs: []
          }) - 1];
          for (const series of Object.values(study.series).sort((a, b) => {
            return parseInt(a.sn) > parseInt(b.sn) ? 1 : parseInt(a.sn) < parseInt(b.sn) ? -1 : 0;
          })) {
            const series_row = study_row.series[study_row.series.push({
              key: uid(),
              sid: study.sid,
              // bind: [patient_row.key, study_row.key], bind 나중에... 근데 이거 꼭 있어야하던가?
              sn: series.sn,
              date: series.date,
              sruid: series.sruid,
              protocol: series.protocol,
              desc: series.desc,
              files: series.files,
              type: findDicomImageType(series, study, imageTypeList),
            }) - 1];
            series_row.lcp = findLongestCommonPath(series.files.map(file => file.file.path))
          }
          study_row.lcp = findLongestCommonPath(study_row.series.map(sr => sr.lcp))
        }
      }

      // count how many the folder names are referenced
      const folderNameCount = new Map()
      const seriesLCPs = studies.map(s => s.series.map(sr => sr.lcp)).flat(Infinity)
      const tree = buildTreeFromPaths(seriesLCPs)

      const branches = []
      collectBranches(tree, branches)

      const folderNamesList = seriesLCPs.map(path => path.split('/').filter(i => i !== ''))
      folderNamesList.flat(Infinity).forEach(i => folderNameCount.has(i) ? folderNameCount.set(i, folderNameCount.get(i) + 1) : folderNameCount.set(i, 1))

      // build Patient ID
      let studiesWithPidOptions = []
      studies.forEach(s => {
        const lcpDirs = s.lcp.split('/').filter(i => i !== '')
        const fnCount = lcpDirs.map((dir, i) => {
          const dirs = lcpDirs.slice(0, i + 1)
          return {dir, dirs, idx: i, count: folderNameCount.get(dir),}
        })
        const fnCountSort = fnCount.sort((a, b) => a.count - b.count)
        const countMin = fnCountSort.at(0).count
        let minList = fnCountSort.filter(x => x.count === countMin)

        const pidOptions = {
          study: s,
          options: minList,
          optionsFull: lcpDirs
        }
        // 새로운 patient ID 찾기
        const pid = findNodeByLCP(lcpDirs, tree, branches)
        if (pid) {
          pidOptions.selected = pid
        }
        else {
          // if (minList.length === 1) {
          // }
          // 생각해보니 여러개여도 그냥 처음 것 선택하도록
          pidOptions.selected = minList[0].dir
        }
        studiesWithPidOptions.push(pidOptions)
      })

      studiesWithPidOptions = await askPatientID(studiesWithPidOptions)

      const newPatientMap = new Map()
      const promises = [];
      studiesWithPidOptions.forEach(i => {
        const newPatientID = i.selected
        if (newPatientMap.has(newPatientID)) {
          const newPatient = newPatientMap.get(newPatientID)
          newPatient.studies.push(i.study)
          newPatient.lcp = findLongestCommonPath(newPatient.studies.map(s => s.lcp))
        }
        else {
          const newPatient = {
            key: uid(),
            pid: newPatientID,
            pname: newPatientID,
            studies: [i.study]
            // lcp: study.lcp
          };
          const promise = getPatientInfo(newPatient, newPatient.studies[0].series[0].files[0].file)
          promises.push(promise)
          newPatientMap.set(newPatientID, newPatient)
        }
      })

      return await Promise.all(promises).then(() => {
        const patients = [...newPatientMap.values()].sort((a, b) => a.pid > b.pid ? 1 : -1)
        patients.forEach(p => p.lcp = findLongestCommonPath(p.studies.map(s => s.lcp)))
        return patients
      })
    }
    else {
      const patients = []
      Object.keys(dicomParsed).forEach(pid => {
        const patient = dicomParsed[pid];

        const patient_row = patients[patients.push({
          key: uid(),
          pid: patient.pid,
          pname: patient.pname,
          age: patient.age,
          sex: patient.sex,
          birth: patient.birth,
          studies: [],
        }) - 1];
        Object.keys(patient.studies).forEach(sid => {
          const study = patient.studies[sid];
          const study_row = patient_row.studies[patient_row.studies.push({
            key: uid(),
            sid: study.sid || "",
            date: study.date,
            desc: study.desc || "",
            series: [],
            blobs: []
          }) - 1];
          Object.values(study.series).sort((a, b) => {
            return parseInt(a.sn) > parseInt(b.sn) ? 1 : parseInt(a.sn) < parseInt(b.sn) ? -1 : 0
          }).forEach(series => {
            const series_row = study_row.series[study_row.series.push({
              key: uid(),
              sid: study.sid,
              sn: series.sn,
              date: series.date,
              sruid: series.sruid,
              protocol: series.protocol,
              desc: series.desc,
              files: series.files,
              bind: [patient_row.key, study_row.key],
              type: findDicomImageType(series, study, imageTypeList),
            }) - 1];
            series_row.lcp = findLongestCommonPath(series.files.map(file => file.file.path))
          });
          study_row.lcp = findLongestCommonPath(study_row.series.map(sr => sr.lcp))
        });
        patient_row.lcp = findLongestCommonPath(patient_row.studies.map(s => s.lcp))
      });
      return patients
    }
  }

  const createBlobFromNIfTI = (name, nifti) => {
    const blob = {
      key: uid(),
      name,
      file: nifti.file,
    }
    blob.type = findNiftiImageType(blob, imageTypeList)
    return blob
  }

  const buildPatientsAlongTree = (root, niftis, patients, unresolveds) => {
    root.sort((a,b) => a.count > b.count ? 1 : -1).forEach(node => {
      const exist = supported_exts.some(ext => node.v.slice(-ext.length) === ext)
      if (node.count === 1 && exist) {
        // file node
        const niftiName = '/' + node.paths.join('/')
        const nifti = niftis[niftiName]
        if (nifti) {
          const pid = node.paths[0]
          const sid = node.paths[1]
          const patient = patients.find(p => p.pid === pid)
          if (!patient) {
            unresolveds.push(createBlobFromNIfTI(niftiName, nifti))
          }
          else {
            let study = patient?.studies?.find(s => s.sid === sid)
            if (!study) {
              if (patient && patient?.studies?.length > 0) {
                study = patient.studies[0]
              }
              else {
                study = patient.studies[patient.studies.push({
                  key: uid(),
                  sid: "STUDY_generated",
                  date: moment().format('YYYY-MM-DD'),
                  desc: "STUDY_generated",
                  series: [],
                  blobs: []
                }) - 1]
              }
            }
            const blob = createBlobFromNIfTI(niftiName, nifti)
            blob.bind = [patient.key, study.key]
            study.blobs.push(blob) // add to blobs
          }
        }
      }
      else {
        // folder node
        if (node.paths.length === 1) {
          // create patient
          const pid = node.paths[0]
          const unixTimestamp = '1970-01-01'
          const patient = {
            key: uid(),
            pid: pid,
            pname: pid,
            sex: 'O',
            birth: unixTimestamp,
            age: moment().diff(moment(unixTimestamp), 'years'),
            studies: []
          }
          patients.push(patient)
        }
        else {
          // get patient
          const pid = node.paths[0]
          const patient = patients.find(p => p.pid === pid)
          if (patient && node.paths[1]) {
            const sid = node.paths[1]
            const study = patient.studies.find(s => s.sid === sid)
            if (!study) {
              // create study
              const study = {
                key: uid(),
                sid: sid,
                date: moment().format('YYYY-MM-DD'),
                desc: sid,
                series: [],
                blobs: []
              }
              patient.studies.push(study)
            }
          }
        }

        buildPatientsAlongTree(node.children, niftis, patients, unresolveds)
      }
    })
  }

  /**
   * parsing 결과에 id, bind 등 추후 필요한 정보를 생성해준다, 사실 merge 랄 것은 별로 없음
   * 이미 dicom 헤더 기준 환자ID, StudyID, Series SN 등으로 merge 되어 있는 상태
   * @param dicomParsed
   * @param niftiParsed
   * @returns {Promise<{destroy: () => void, update: (configUpdate: ConfigUpdate) => void}>}
   */
  const mergeDicomParseResult = async (dicomParsed, niftiParsed) => {
    if (state.niftiOnly) {
      // nifti 만으로 된 데이터셋은 폴더명으로 patient 만들고, 그안에 study 폴더도 있을 수 있고 그런 상황...
      const tree = buildTreeFromPaths(Object.keys(niftiParsed))

      const patients = []
      const unresolveds = []
      buildPatientsAlongTree(tree, niftiParsed, patients, unresolveds)

      const resolveds = state.resolveds.concat(patients)
      dispatchDicomDrop({
        type: 'UPDATE_AND_REBUILD_BINDS',
        payload: {resolveds, unresolveds, loading: false, loaded: true, parseCount: 0, parseCountMax: 0,}
      })
    }
    else {
      const patients = await getPatients(dicomParsed)

      // merge 전, dragNdrop 된 것들 안에서만 해결 시도
      const unresolvedBlobs = resolveNifti(niftiParsed, patients);

      // patient concat
      // const unresolvedsMerged = state.unresolveds.concat(unresolvedBlobs)
      const resolveds = state.resolveds.concat(patients)
      // const unresolvedsNew = resolveBlob(unresolvedsMerged, resolveds)
      const unresolvedsNew = resolveBlob(unresolvedBlobs, resolveds).sort((a, b) => a.name > b.name ? 1 : -1)

      const unresolveds = state.unresolveds.concat(unresolvedsNew)

      dispatchDicomDrop({
        type: 'UPDATE_AND_REBUILD_BINDS',
        payload: {resolveds, unresolveds, loading: false, loaded: true, parseCount: 0, parseCountMax: 0,}
      })

    }
  }

  /**
   * parsing 할 때 찾아둔 nifti 에 DB 저장을 위한 데이터를 추가하는 곳
   * bind 는 patient + study 에 연결을 위해, filename 을 이용 type 도 지정해줌
   * @param niftis nifti 파일들만 모아둔 것, 막 parsing 끝난 상태
   * @param resolveds dragNdrop 된 patient list
   * @returns {*[]}
   */
  const resolveNifti = (niftis, resolveds) => {
    let studyCount = 0
    let lastPatient = null
    let lastStudy = null
    resolveds.forEach(p => p.studies.forEach(s => {
      studyCount += 1
      lastPatient = p
      lastStudy = s
    }))

    const unresolveds = []
    Object.keys(niftis).forEach(fpath => {
      const nifti = niftis[fpath]
      const blob = {
        key: uid(),
        name: fpath,
        file: nifti.file,
      }
      blob.type = findNiftiImageType(blob, imageTypeList)
      if (studyCount === 1) {
        // study 1개 뿐이면 그냥 여기에 추가
        blob.bind = [lastPatient.key, lastStudy.key]
        lastStudy.blobs.push(blob) // add to blobs
      }
      else {
        const headPath = nifti.file.path.slice(0, -nifti.file.name.length - 1)
        const {patient, study} = findBestMatch(headPath, resolveds)
        if (patient == null || study == null) {
          unresolveds.push(blob)
        }
        else {
          blob.bind = [patient.key, study.key]
          study.blobs.push(blob) // add to blobs
        }
      }
    });

    return unresolveds
  }

  /**
   * patient, study 가 각 1개만 있을 경우 unresolveds 몰빵
   * @param unresolveds
   * @param resolveds
   * @returns {*|*[]}
   */
  const resolveBlob = (unresolveds, resolveds) => {
    const studyAll = resolveds.map(p => p.studies.map(s => s)).flat(Infinity)
    if (resolveds.length === 1 && studyAll.length === 1) {
      // nifti 만 올린다던가 하면 study 수가 여전히 1개일 수 있음
      const patient = resolveds[0]
      const study = patient.studies[0]
      unresolveds.forEach(b => {
        b.bind = [patient.key, study.key]
        study.blobs.push(b)
      })
      return []
    }
    return unresolveds
  }

  return [state, dispatchDicomDrop, onDrop]
}