import React, {memo, useState, useEffect, useRef} from "react";
import {
  Button,
  Table,
  Select,
  Tag,
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  Space,
  Typography,
  Switch,
  Spin,
  Divider
} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import { add, update, test, clear, checkName, checkShorthand, clearTestReulst } from "../redux/modules/blobTypes"
import {useHistory, useLocation} from "react-router-dom";
import * as cornerstone from "cornerstone-core";
import chroma from "chroma-js";
import {get} from "../redux/modules/blobTypes";
import {SketchPicker} from "react-color";
import {useTitle} from "../hooks/title";
import ModalityHint from "./modalityHint";
import { msgError } from "../redux/modules/message";
import { useDuplicateCheck } from "../context/duplicateCheckContext";
import { Colormap } from "../lib/colormap";
import FormColorTag from "./formColorTag";
import { TagBase } from "./tagBase";
import {getDefault} from "../redux/modules/blobTypes";
import {NamePath} from "rc-field-form/lib/interface";
// import {Colorscale} from 'react-colorscales';
// import ColorscalePicker from 'react-colorscales';
// const viridisColorscale = ['#fafa6e', '#9cdf7c', '#4abd8c', '#00968e', '#106e7c', '#2a4858'];

// TODO node v18에서 나중에 시도해보자 react-window 는 여러 문제를 야기함..
// import { ViewportList } from "react-viewport-list";
// import AutoSizer from "react-virtualized-auto-sizer";
// import { FixedSizeList, VariableSizeList, areEqual } from 'react-window'


const colormapsList = cornerstone.colors.getColormapsList();

const testResultColumn = [
  {
    title: 'Patient ID',
    dataIndex: 'pid',
    key: 'pid',
  },
  {
    title: 'Patient Name',
    dataIndex: 'pname',
    key: 'pname',
  },
  {
    title: 'Study ID',
    dataIndex: 'sid',
    key: 'sid',
  },
  {
    title: 'Study Date',
    dataIndex: 'studyDate',
    key: 'studyDate',
    render: text => <>{text.split(' ')[0]}</>,
  },
  {
    title: 'Study Desc',
    dataIndex: 'studyDesc',
    key: 'studyDesc',
  },
]
const dicomTestResultColumn = [
  ...testResultColumn,
  {
    title: 'Series Desc',
    dataIndex: 'seriesDesc',
    key: 'seriesDesc',
  },
  {
    title: 'Protocol Name',
    dataIndex: 'protocol',
    key: 'protocol',
  },

]
const imageTestResultColumn = [
  ...testResultColumn,
  {
    title: 'File name',
    dataIndex: 'fname',
    key: 'fname',
  },
]

const popover = {
  position: 'absolute',
  zIndex: '2',
}
const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}

export const removeInvalidExpressions = values => {
  if (values.dicom_hints) {
    values.dicom_hints = values.dicom_hints.filter(hint => !!hint.value)
    if (values.dicom_hints.length === 0) {
      values.dicom_hints = null
    }
  }
  if (values.image_hints) {
    values.image_hints = values.image_hints.filter(hint => !!hint.value)
    if (values.image_hints.length === 0) {
      values.image_hints = null
    }
  }
}

function Modality() {
  useTitle("Image Type");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const blobTypesLoading = useSelector(state => state.blobTypes?.loading);
  const imageType = useSelector(state => state.blobTypes?.type);
  const dicomTestResult = useSelector(state => state.blobTypes?.dicomTestResult);
  const imageTestResult = useSelector(state => state.blobTypes?.imageTestResult);
  const enumMode = Object.freeze({CREATE: 0, EDIT: 1})
  const [mode, setMode] = useState(enumMode.CREATE);
  const [form] = Form.useForm();
  const checkLoading = useSelector(state => state.blobTypes?.check?.loading)
  const checkedName = useSelector(state => state.blobTypes?.check?.name)
  const checkedNameError = useSelector(state => state.blobTypes?.check?.nameError)
  const checkedShort = useSelector(state => state.blobTypes?.check?.short)
  const checkedShortError = useSelector(state => state.blobTypes?.check?.shortError)

  // filed 최신값 load를 위한 선언
  const shorthand = Form.useWatch('short', form); // getFieldsValue 최신값이 안나와서 만듦
  const seg = Form.useWatch('seg', form);
  const segCmap = Form.useWatch('seg_cmap', form);
  const dicomHints = Form.useWatch('dicomHints', form);

  useEffect(() => {
    if (imageType !== undefined && imageType.id === location.state?.key) {
      form.setFieldsValue({
        id: imageType.id,
        name: imageType.name,
        short: imageType.short,
        cmap_id: imageType.cmap_id,
        tag_color: imageType.tag_color,
        dicom_hints: imageType.dicom_hints ? imageType.dicom_hints : [],
        image_hints: imageType.image_hints ? imageType.image_hints : [],
        seg: imageType.seg,
        seg_cmap: imageType.seg_cmap ?? [],
      });
      // const cname = cornerstone.colors.getColormap(form.getFieldValue('cmap_id')).getColorSchemeName()
      onChangeColormap(imageType.cmap_id)
      const hexrgb = chroma(imageType.tag_color.split(',')).hex()
      setTagColor(hexrgb)
    }
  }, [imageType])

  useEffect(() => {
    if (location.state !== undefined && location.state.key !== undefined) {
      setMode(enumMode.EDIT);
      dispatch(get({id: location.state.key}))
    }
    else {
      // dispatch(get({id: -1}))
      onChangeColormap('gray')
    }
    window.scrollTo(0, 0)
    return ()=>dispatch(clear())
  }, [])

  const handleCancel = () => history.goBack();

  const onFinish = values => {
    removeInvalidExpressions(values)

    // values.seg_cmap.

    if (mode === enumMode.CREATE) {
      dispatch(add({values}))
    }
    else {
      dispatch(update({values}));
    }
  };

  const [tagColor, setTagColor] = useState('#808080')
  const [cmapId, setCmapId] = useState('gray')
  const onChangeColormap = (value) => {
    setCmapId(value)
  }
  const onChangeShorthand = (e) => {
    form.setFieldsValue({short: e.target.value})
  }
  const [showPicker, setShowPicker] = useState(false);

  const handleClickTag = () => {
    setShowPicker(true)
  }
  const handleClosePicker = () => {
    setShowPicker(false);
  }
  const handleChangePicker = (color, event) => {
    setTagColor(color.hex)
  }
  const handleChangeCompletePicker = (color, event) => {
    setTagColor(color.hex)
    const rgb = chroma(color.hex).alpha(1).css().slice(4, -1)
    form.setFieldsValue({tag_color: rgb})
  }

  const [showDicomTestResult, setShowDicomTestResult] = useState(false);
  const [showImageTestResult, setShowImageTestResult] = useState(false);
  const handleTestBinding = (fieldName, setShowState, pagination={page:1, per_page:10}) => {
    let hints = form.getFieldValue(fieldName);
    if (hints.length !== 0) {
      const invalidExpression = !hints || !hints.every(hint => hint.value.length !== 0)
      if (invalidExpression) {
        dispatch(msgError('Incomplete binding condition'))
        return
      }
      setShowState(true)

      let payload = null;
      if (fieldName.includes('dicom')) {
        payload = {type: 'dicom', dicom_hints: hints, pagination}
      }
      else {
        payload = {type: 'image', image_hints: hints, pagination}
      }
      dispatch(test(payload))
    }
    else {
      let payload = null;
      if (fieldName.includes('dicom')) {
        payload = {type: 'dicom'}
      }
      else {
        payload = {type: 'image'}
      }
      dispatch(clearTestReulst(payload))
    }
  }
  const onDicomTableChange = (current, size) => {
    handleTestBinding('dicom_hints', setShowDicomTestResult, {page: current, per_page: size})
  }
  const onImageTableChange = (current, size) => {
    handleTestBinding('image_hints', setShowImageTestResult, {page: current, per_page: size})
  }

  const { onFieldsChange, lastMatchValidator } = useDuplicateCheck({
    loading : checkLoading,
    form : form,
    fields : [{
        fieldName : 'name',
        action : checkName,
        result : checkedName,
        error : checkedNameError
      },
      {
        fieldName : 'short',
        action : checkShorthand,
        result : checkedShort,
        error : checkedShortError
      }]
  })

  if (blobTypesLoading) {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: '70%',
        margin: "20px auto",
        padding: "30px auto",
        columnGap: 10,
        background: "rgba(0, 0, 0, 0.05)",
        borderRadius: 4
      }}>
        <Spin/> Loading..
      </div>
    )
  }

  return (
    // <React.Suspense fallback={}>
    <div
      style={{
        minWidth: '70%',
        margin: '20px auto',
      }}
    >
      <Form
        initialValues={{
          cmap_id: 'gray',
          tag_color: '128, 128, 128',
          dicom_hints:[],
          image_hints:[],
          seg_cmap: [] // 없으면 seg_cmap의 rule에 걸려서 on finish로 안넘어감
        }}
        form={form}
        onFinish={onFinish}
        labelCol={{span: 3}}
        wrapperCol={{span: 21}}
        onFieldsChange={onFieldsChange}
        labelWrap
        autoComplete="off"
      >
        <Form.Item noStyle>
          <Row gutter={[8, 4]} align="middle" justify="end">
            <Col span={6} >
              <h1>Image type</h1>
            </Col>
            <Col span={12}></Col>
            <Col span={6} flex={2}>
              <Space direction="horizontal" style={{width: '100%', justifyContent: 'right'}} >
                <div>
                  {imageType?.preset
                    ? <Button onClick={() => dispatch(getDefault(imageType?.id))}>Default</Button>
                    : null}
                </div>
                <Button onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  OK
                </Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
        <br/>
        <Form.Item name="id" style={{display: 'none'}}>
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              whitespace : true,
              message: 'Please input your image type name!',
            },
            {
              max: 256,
              message : 'Cannot exceed 256 letters'
            },
            {
              pattern : new RegExp(/^(?!.*[\\]).*$/gu),
              message : `The following special characters cannot be used. \\`
            },
            lastMatchValidator('name')
          ]}
          hasFeedback
        >
          <Input
            placeholder="My image type name, e.g. T1-weighted (Axial)"
            maxLength={256}
            showCount={{
              formatter: ({count, maxLength})=>
                <span style={{color:'rgba(255,255,255,0.3)'}}>
                  {`${count}/${maxLength}`}
                </span>
            }}
            />
        </Form.Item>
        <Form.Item
          label="Shorthand"
          name="short"
          rules={[
            {
              required: true,
              whitespace : true,
              message: 'Please input your image type shorthand!',
            },
            {
              max : 12,
              message : 'It cannot exceed 12 characters'
            },
            {
              pattern : new RegExp(/^(?!.*[:\\◀"<>|?/*_.])(?!.*\p{Extended_Pictographic}).*$/gu),
              message : `The following special characters cannot be used. /:\\◀"<>|?*_.`
            },
            lastMatchValidator('short')
          ]}
          hasFeedback
        >
          <Input
            onChange={onChangeShorthand}
            placeholder="Image type shorthand, e.g. T1w"
            maxLength={12}
            showCount={{
              formatter: ({count, maxLength})=>
                <span style={{color:'rgba(255,255,255,0.3)'}}>
                  {`${count}/${maxLength}`}
                </span>
            }}
          />
        </Form.Item>

        <Form.Item
          label="Tag appearance"
          name="tag_color"
        >
          <TagBase
            color={tagColor}
            onClick={handleClickTag}
            style={{cursor: "pointer"}}
          >
            {
              !!form.getFieldValue('short')
              ? form.getFieldValue('short')
              : 'Shorthand placeholder (Click to change color)'
            }
          </TagBase>
          {showPicker ?
            <div style={popover}>
              <div
                style={cover}
                onClick={handleClosePicker}
              />
              <SketchPicker
                styles={{default: {picker: {background: "#CCCCCC", color: "#222222"}}}}
                color={tagColor}
                onChange={handleChangePicker}
                onChangeComplete={handleChangeCompletePicker}
              />
            </div>
            :
            null
          }
        </Form.Item>

        <Form.Item
          label="Segmentation"
          name="seg"
          valuePropName="checked"
        >
          <Switch disabled={mode===enumMode.EDIT}/>
        </Form.Item>

        <Form.List
          name="seg_cmap"
          rules={[
            ({ getFieldValue }) => ({
              validator: async (_, labels) => {
                if (getFieldValue('seg') && labels.length === 0) {
                  return Promise.reject(new Error('At least 1 Label should be defined'))
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          {(fields, { add, remove }, {errors}) => (
            <>
              <Form.Item
                label="Labels"
                hidden={!seg}
              >
                <Button
                  type="dashed"
                  onClick={() => add({color: '#808080'})}
                  block
                  icon={<PlusOutlined />}
                  disabled={imageType?.read_only}
                >
                  Add label
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
              {fields.map((field, index) => (
                <Row key={field.key} align="middle" justify="center" hidden={!seg}>
                  <Col span={2}/>
                  <Col span={2} style={{textAlign:"center", marginBottom:16}}>
                    <Typography.Text code strong={true}>LABEL {index+1}</Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="name"
                      name={[field.name, 'name']}
                      labelCol={{span: 6}}
                      wrapperCol={{span: 18}}
                      rules={[
                        {
                          required: true,
                          message: 'Empty label name'
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const seg_cmap = getFieldValue('seg_cmap')
                            const fieldIdx = field.name
                            const indices = []
                            seg_cmap.forEach((i, idx) => {
                              if (idx !== fieldIdx && i.name === value) {
                                indices.push(idx)
                              }
                            })
                            const ids = indices.map(i => i + 1).toSorted()
                            if (ids.length > 0) {
                              return Promise.reject(new Error(`duplicated with LABEL ${ids.join(', ')}`))
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <Input style={{width: '100%'}} disabled={imageType?.read_only}/>
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label="color"
                      name={[field.name, 'color']}
                      labelCol={{span: 12}}
                      wrapperCol={{span:12}}
                      rules={[
                        {
                          required: true,
                          message: 'Empty label color'
                        },
                      ]}
                    >
                      <FormColorTag
                        form={form}
                        listName={'seg_cmap'}
                        name={[field.name, 'color']}
                        segCmap={segCmap}
                      ></FormColorTag>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="value"
                      name={[field.name, 'value']}
                      labelCol={{span: 8}}
                      wrapperCol={{span:16}}
                      rules={[
                        {
                          required: true,
                          message: 'Empty label value'
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const seg_cmap = getFieldValue('seg_cmap')
                            const fieldIdx = field.name
                            const indices = []
                            seg_cmap.forEach((i, idx) => {
                              if (idx !== fieldIdx && i.value === value) {
                                indices.push(idx)
                              }
                            })
                            const ids = indices.map(i => i + 1).toSorted()
                            if (ids.length > 0) {
                              return Promise.reject(new Error(`duplicated with LABEL ${ids.join(', ')}`))
                            }
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <InputNumber style={{width: '100%'}} precision={0} disabled={imageType?.read_only}/>
                    </Form.Item>
                  </Col>
                  <Col span={1} style={{textAlign:"center"}}>
                    {imageType?.read_only ? "" :
                      <Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Form.Item>
                    }
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item
          hidden={seg}
          shouldUpdate={(prev, cur) => {
            return prev?.id !== cur?.id
          }}
          label="Colormap name"
          initialValue={cornerstone.colors.getColormap(form.getFieldValue('cmap_id')).getColorSchemeName()}
        >
          <Form.Item
            name="cmap_id"
          >
            <Select
              options={colormapsList.map((cmap) => {
                return {label: cmap.name, value: cmap.id}
              })}
              onChange={onChangeColormap}
              style={{width:'30%'}}
              // dropdownMatchSelectWidth={false}
            />
          </Form.Item>
          <Colormap seg={seg} seg_cmap={segCmap} cmap_id={cmapId}/>
        </Form.Item>
        <Form.Item
          label="Series binding hint (DICOM header)"
          key="dicom_hints"
        >
          <Form.Item
            name="dicom_hints"
          >
            <ModalityHint
              fieldName="dicom_hints"
              value={form.getFieldValue("dicom_hints")}
              setFieldsValue={form.setFieldsValue}
            />
          </Form.Item>


          <Button onClick={() => handleTestBinding('dicom_hints', setShowDicomTestResult)}> Test binding </Button>
          {showDicomTestResult?
            <>
              <Space direction="horizontal" style={{width: '100%', justifyContent: 'right'}} >
                <Typography> First matching {dicomTestResult?.list.length} series(total:{dicomTestResult?.total})</Typography>
              </Space>
              <Table
                loading={blobTypesLoading}
                columns={dicomTestResultColumn}
                dataSource={dicomTestResult?.list}
                pagination={{
                  total: dicomTestResult?.total,
                  onChange: onDicomTableChange
                }}
                size="small"
              />
            </>
            : null
          }
        </Form.Item>
        <Form.Item
          label="Image binding hint (filename)"
          key="image_hints"
        >
          <Form.Item
            name="image_hints"
          >
            <ModalityHint
              fieldName="image_hints"
              value={form.getFieldValue("image_hints")}
              setFieldsValue={form.setFieldsValue}
            />
          </Form.Item>


          <Button onClick={() => handleTestBinding('image_hints', setShowImageTestResult)}> Test binding </Button>
          {showImageTestResult?
            <>
              <Space direction="horizontal" style={{width: '100%', justifyContent: 'right'}} >
                <Typography> First matching {imageTestResult?.list.length} series(total:{imageTestResult?.total})</Typography>
              </Space>
              <Table
                loading={blobTypesLoading}
                columns={imageTestResultColumn}
                dataSource={imageTestResult?.list}
                pagination={{
                  total: imageTestResult?.total,
                  onChange: onImageTableChange
                }}
                size="small"
              />
            </>
            : null
          }
        </Form.Item>
      </Form>
    </div>
    // </React.Suspense>
  );
}

export default Modality;