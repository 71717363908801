import React, {useEffect, useState} from "react";
import {Select, Tag} from 'antd';
import {useSelector} from "react-redux";
import chroma from "chroma-js";
import { TagBase } from "./tagBase";

const { Option, OptGroup } = Select;

export const MODE = Object.freeze({SINGLE: 0, MULTI: 1})
function TaskImageIOSelect(
  {
    options,
    onChange,
    dropdownMatchSelectWidth = false,
    placeholder,
    value,
    tabIndex,
    style,
    placement="bottomLeft",
    // imageTypesInProp,
    width = 150,
    optionGroup = false,
    mode = MODE.SINGLE,
    ...props
  }
) {
  // const blobTypes = useSelector(state => state.blobTypes);
  const selectMode = mode ?? MODE.MULTI

  const onPreventMouseDown = (event) => {
    if (selectMode === MODE.MULTI) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    return (
      <TagBase
        color={label?.props?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {value}
      </TagBase>
    );
  };

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleChange = (value, option) => {
    setSelected(value)
    if (selectMode === MODE.SINGLE) {
      if (option.length > 0) {
        setOpen(false)
      }
      // onChange?.(value[0], option[0])
    }
    // else {
    //   onChange?.(value, option)
    // }

    onChange?.(value, option)
  }

  useEffect(() => {
    // if (value) {
      if (selectMode === MODE.SINGLE) {
        if (value) {
          setSelected([value])
        }
        else {
          setSelected([])
        }
      }
      else {
        setSelected(value)
      }
    // }
  }, [value])

  const buildOptions = item => {
    const testDisabled = selectMode === MODE.SINGLE && selected.length > 0
      ? typeof(selected[0]) === 'string' ? !selected.includes(item.key) : !(selected.map(s => s.key).includes(item.key))
      : !!item.disabled
      return <Option
        key={item.key}
        label={item.key}
        value={item.key}
        disabled={testDisabled}
      >
        <TagBase color={chroma(item.tag_color.split(',')).hex()}>
          {item.key}
        </TagBase>
      </Option>
  }

  return (
    <Select
      showSearch={selectMode === MODE.MULTI || (selectMode === MODE.SINGLE && selected.length === 0)}
      // style={{width: width, height: height, paddingTop: paddingTop }}
      style={style != null ? {width:width, ...style} : {width: width}}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      mode="multiple"
      tagRender={tagRender}
      onChange={handleChange}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      placeholder={placeholder}
      tabIndex={tabIndex}
      value={selected}
      select_index={props?.select_index}
      getPopupContainer={trigger => trigger.parentNode}
      disabled={props?.disabled}
      placement={placement}
    >
      {optionGroup ?
        <>
          <OptGroup label='Default images'>
            {options.filter(o => o.disabled).map((item, oidx) => buildOptions(item, oidx))}
          </OptGroup>
          <OptGroup label='Extra images'>
            {options.filter(o => !o.disabled).map((item, oidx) => buildOptions(item, oidx))}
          </OptGroup>
        </>
        :
        options.map((item, oidx) => buildOptions(item, oidx))
      }
    </Select>
  );
}
export default TaskImageIOSelect;

