import { Button, Modal, Select, Tooltip, Space, Row, Col, Popconfirm } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { openMergeModal, closeMergeModal, mergePatient, delAskCancel, delAskOk,
   delPatients, mergeAskOk, mergeAskCancel, getDownloadTargets } from "../redux/modules/patient"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons"
import { msgError } from "../redux/modules/message"
import AskModal, {ITEM_ELLIPSIS_LIMIT, ELLIPSIS_LIMIT} from "../components/askModal"
import { ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import DownloadPatientPathSettingModal from "../components/downloadPatientPathSettingModal"

export const usePatientListHooks = ({rowSelection}) => {
  const patientSelectedRows = rowSelection.selectedRows
  const dispatch = useDispatch()
  const location = useLocation()
  const loading = useSelector(state => state.patient.loading)
  const userPerPage = useSelector(state=>state.config.per_page)
  const deleted = useSelector(state => state.patient?.deleted)
  const merged = useSelector(state => state.patient?.merged)

  const paginationInfo = location.state?.pagination
  const page = paginationInfo?.page || 1;
  const per_page = paginationInfo?.per_page || userPerPage ||10
  const category = paginationInfo?.category
  const keyword = paginationInfo?.keyword

  useEffect(() => {
    rowSelection.initSelected()
  },[deleted])

  useEffect(() => {
    if (merged) {
      const mergedPatient = rowSelection.selectedRows.find(sl => sl.key === merged)
      rowSelection.setSelectedRows([mergedPatient])
    }
  },[merged])

  const checkMergeable = () => {
    const pLength = patientSelectedRows.length
    if (pLength > 1) return true
    return false
  }
  
  const MergeBtn = () => {
    const handleMergeModalOpen = () => dispatch(openMergeModal())

    const mergeable = checkMergeable()
    const tooltipOpen = mergeable ? false : undefined

    return (
      <Tooltip title="Select at least 2 patients" open={tooltipOpen}>
        <Button
          icon={<FontAwesomeIcon style={{marginRight:8}} icon={faLayerGroup}/>}
          disabled={!mergeable}
          onClick={handleMergeModalOpen}
        >
          Merge
        </Button>
      </Tooltip>
    )
  }

  const MergePatientModal = () => {
    const dispatch = useDispatch()
    const open = useSelector(state => state.patient?.openMerge)
    const [destination, setDestination] = useState(undefined)
    
    const handleSelectChange = (value, _) => setDestination(value)
    const handleMergeCancel = () => dispatch(closeMergeModal())
    const handleMergeOk = () => {
      const params= {page, per_page, category, keyword}

      if (patientSelectedRows.length < 2) {
        return dispatch(msgError('Please select at least two'))
      }
      if (destination === undefined) {
        return dispatch(msgError('Please select a destination'))
      }
      const payload = {
        destination,
        patients: patientSelectedRows,
        ...params
      }
      return dispatch(mergePatient(payload))
    }

    const options = patientSelectedRows.map(row => ({
      key: row?.key,
      label: `Patient ID: ${row?.pid} - Name: ${row?.name}`,
      value: row?.key,
      type: 'patient'
    }))

    useEffect(() => {
      setDestination(options?.[0]?.key)
    },[])
    
    const Footer = () => {
      return (
        <Space>
          <Button onClick={handleMergeCancel}>Cancel</Button>
          <Popconfirm
            title="Are you sure?"
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleMergeOk}
          >
            <Button type={'primary'}>OK</Button>
          </Popconfirm>
        </Space>
      )
    }
  
    return (
      <Modal
        title="Please select where to merge"
        closable={false}
        open={open}
        footer={<Footer />}
      >
        <Select
          style={{width: '100%', borderColor: '#227a76', borderRightWidth: '1px'}}
          onChange={handleSelectChange}
          dropdownMatchSelectWidth={false}
          options={options}
          value={destination}
        />
      </Modal>
    )
  }

  const MergeAskModal = () => {
    const mergeAskData = useSelector(state => state.patient.mergeAsk)
    const requestData = mergeAskData?.request
    const onOk = () => {
      const params={page, per_page, category, keyword}
      
      const payload = {
        ...requestData,
        ...params,
        approve: true,
      }
      dispatch(mergeAskOk())
      return dispatch(mergePatient(payload))
    }
    const onCancel = () => dispatch(mergeAskCancel())

    const addSubTitle = () => {
      if (!requestData) {
        return <></>
      }
      const destination = requestData.destination
      const inputPatients = requestData.patients.filter(p => p.key !== destination)
      const destPatient = requestData.patients.filter(p => p.key === destination)

      const inputDataView = () => {
        const contents = inputPatients.map(p => {
          const content = `${p.pid} - ${p.name}`
          const isTooLong = content.length > ELLIPSIS_LIMIT
          if (isTooLong) {
            return (
              <Tooltip title={content}>
                <span style={{display: 'block'}}>{content.slice(0, ELLIPSIS_LIMIT)}</span>
              </Tooltip>
            )
          }
          return (
            <span style={{display: 'block'}}>{content}</span>
          )
        })
        if (contents.length > ITEM_ELLIPSIS_LIMIT) {
          return (
            <>
              {contents.slice(0, ITEM_ELLIPSIS_LIMIT)}
              <p>...more {contents.length - ITEM_ELLIPSIS_LIMIT}</p>
            </>
          )
        }
        return contents
      }
      
      return (
        <Row style={{width: '100%', color: 'white', marginBottom: 50}}>
          <Col span={11} style={{paddingLeft: 20}}>
            {inputDataView()}
          </Col>
          <Col span={2} style={{display:'flex', alignItems: 'center',  justifyContent: 'center'}}>
            <ArrowRightOutlined />
          </Col>
          <Col span={11} style={{display:'flex', alignItems: 'center', justifyContent: 'right',  paddingRight: 20}}>
            {destPatient.map(p => <span>{`${p.pid} - ${p.name}`}</span>)}
          </Col>
        </Row>
      )
    }
    
    return (
      <AskModal 
        askData={mergeAskData}
        handleOk={onOk}
        handleCancel={onCancel}
        loading={loading}
        title={'Do you want to merge it?'}
        addSubTitle={addSubTitle()}
      />
    )
  }

  const onRemove = ({approve=false}) => {
    const patients = patientSelectedRows.map(row => {
      row.selected = true
      return row
    })
    if (patients.length === 0) return dispatch(msgError('There is no selected item'))
    return dispatch(delPatients({patients, ...paginationInfo, approve}))
  }

  const removePossible = patientSelectedRows.length > 0
  const title = !removePossible && 'Select data to remove'

  const RemoveBtn = () => {
    return (
      <Tooltip title={title}>
        <Popconfirm
          disabled={!removePossible}
          title="Are you sure?"
          icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
          onConfirm={onRemove}
        >
          <Button style={{margin: '1px'}} disabled={!removePossible}>Remove</Button>
        </Popconfirm>
      </Tooltip>
    )
  }

  const DelAskModal = () => {
    const delAskData = useSelector(state => state.patient.delAsk)
    const onOk = () => {
      rowSelection.initSelected()
      dispatch(delAskOk())
      return onRemove({approve: true})
    }
    const onCancel = () => dispatch(delAskCancel())
    
    return (
      <AskModal 
        askData={delAskData}
        handleOk={onOk}
        handleCancel={onCancel}
        loading={loading}
      />
    )
  }

  const DownloadBtn = () => {
    const downloadLoading = useSelector(state => state.longTask.download.loading)
    const onDownload = () => {
      if (patientSelectedRows.length === 0) return dispatch(msgError('No selected item'))
      if (patientSelectedRows.length > 10) return dispatch(msgError(`Patient download is available for up to 10. (current: ${patientSelectedRows.length})`))
      
      return dispatch(getDownloadTargets({patients: patientSelectedRows}))
    }
    const downloadPossible = patientSelectedRows.length > 0
    const title = !downloadPossible && 'Select data to download'
    
    const downloadTargets = useSelector(state => state.patient?.downloadTargets?.patients)

    return (
      <>
        {downloadTargets && <DownloadPatientPathSettingModal />}
        <Tooltip title={title}>
          <Button
            disabled={loading || !downloadPossible}
            onClick={onDownload}
            loading={downloadLoading}
          >
            Download
          </Button>
        </Tooltip>
      </>
    )
  }

  return {
    MergeBtn,
    MergePatientModal,
    MergeAskModal,
    RemoveBtn,
    DelAskModal,
    DownloadBtn
  }
}