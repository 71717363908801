import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { changePerPage } from "../redux/modules/config";
import { msgError } from "../redux/modules/message";
import { useSearchHighlight } from "./searchHighlightContext";
import { Pagination, Space, Button, Popconfirm } from "antd";
import { QuestionCircleOutlined, ClockCircleOutlined, SyncOutlined, 
  CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import SearchBox from "../components/searchBox";

const ICON_FONT_SIZE = 16

const useListPageContext = ({load: loadAction, del : delAction, clear : clearAction, searchOptions, total, loading, isModal=false}) => {
  const dispatch = useDispatch();
  const location  = useLocation();
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState([]);
  const userPerPage = useSelector(state=>state.config.per_page)

  const [paginationInfoState, setPaginationInfoState] = useState({page: 1, per_page: userPerPage, category: undefined, keyword: undefined})

  const stateList = [
    {
      state: 'pending',
      icon: <ClockCircleOutlined style={{fontSize: ICON_FONT_SIZE}}/>
    },
    {
      state: 'running',
      icon: <SyncOutlined style={{fontSize: ICON_FONT_SIZE}}/>
    },
    {
      state: 'success',
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize: ICON_FONT_SIZE}}/>
    },
    {
      state: 'fail',
      icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize: ICON_FONT_SIZE}}/>
    },
  ]

  const [loadStates, setLoadStates] = useState(stateList.map(item => item.state))

  const paginationInfo = isModal ? 
    paginationInfoState :
    location.state?.pagination
  
  const page = paginationInfo?.page || 1;
  const per_page = paginationInfo?.per_page || userPerPage ||10
  const category = paginationInfo?.category
  const keyword = paginationInfo?.keyword

  const rowSelection = {
    selectedRowKeys: selectedRows?.map(row => row?.key), 
    selectedRows,
    setSelectedRows,
    initSelected : () => setSelectedRows([]),
    onSelect : (record, selected, _, e) => {
      let copiedSelectedRows =  [...selectedRows]
      if (selected) {
        copiedSelectedRows = [...copiedSelectedRows, record]
      }
      else {
        copiedSelectedRows = copiedSelectedRows.filter(row => row.key !== record.key)
      }
      copiedSelectedRows.sort((a,b) => b.key - a.key)
      setSelectedRows(copiedSelectedRows)
    },
    onSelectAll : (selected, _, changeRows) => {
      let copiedSelectedRows =  [...selectedRows]
      if (selected) {
        copiedSelectedRows = [...copiedSelectedRows, ...changeRows]
      }
      else {
        const deSelectedKeys = changeRows.map(row => row.key)
        copiedSelectedRows = copiedSelectedRows.filter(row => !deSelectedKeys.includes(row.key))
      }
      copiedSelectedRows.sort((a,b) => b.key - a.key)
      setSelectedRows(copiedSelectedRows)
    },
    getCheckboxProps : (record) => ({
      disabled : record.preset,
    }),
    renderCell : (checked, record, index, originNode) => {
      if (record.preset) {
        return null
      } else {
        return originNode
      }  
    }
  }

  const fetchClear = () => clearAction ? dispatch(clearAction()) : undefined

  useEffect(()=>{
    return () => fetchClear()
  },[])
  
  useEffect(()=>{
    if (per_page !== userPerPage) {
      dispatch(changePerPage(per_page))
    }
    fetch({page, per_page, category, keyword, loadStates})
  },[page, per_page, category, keyword, loadStates])

  const onChange = (page, per_page) => {
    if (isModal) {
      return setPaginationInfoState({page, per_page, category, keyword})
    }
    return history.push(location.pathname, {pagination : {page, per_page, category, keyword}})
  }

  const handleSearch = (keyword, category) => {
    if (isModal) {
      return setPaginationInfoState({page: 1, per_page, keyword, category})
    }
    return history.push(location.pathname, {pagination : {page: 1, per_page, keyword, category}})
  }
  
  const fetch = (params={page, per_page, category, keyword, loadStates}) => {
    const payload = { 
      ...params
    }
    dispatch(loadAction(payload));
  };

  const fetchDelete = (params={keys: selectedRows.map(row => row.key), approve: false}) => {
    if (params.keys.length > 0) {
      const payload = {
        keys : params.keys,
        approve : params.approve,
        loadStates,
        ...paginationInfo
      }
      dispatch(delAction(payload))
      rowSelection.initSelected()
    } else {
      dispatch(msgError('There is no selected item'))
    }
  }

  const {searchHighlight} = useSearchHighlight({paginationInfo, searchOptions})

  const SelectedCount = () => {
    const onClear = () => rowSelection.initSelected()
    return (
      <Space>
        {selectedRows.length > 0 &&
          <>
            Selected: {selectedRows.length}
            <Button onClick={onClear} size='small'>Clear</Button>
          </>
        }
      </Space>
    )
  }

  const pagination = {
    current: page,
    pageSize: per_page,
    showSizeChanger: true,
    total,
    onChange,
  }

  const Footer = () => {
    return (
      <Space style={{width: '100%', justifyContent: 'space-between'}}>
        <SelectedCount />
        <Pagination {...pagination}/>
      </Space>
    )
  }

  const RemoveBtn = () => {
    return (
      <Popconfirm
        title="Are you sure?"
        icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
        onConfirm={() => fetchDelete()}
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Button style={{margin: '1px'}}>Remove</Button>
      </Popconfirm>
    )
  }

  const ListPageSearchBox = () => {
    return (
      <SearchBox
        style={{display: "inline-block", float: 'right', width:"50%"}}
        options={searchOptions}
        onSearch={handleSearch}
        loading={loading}
      />
    )
  }

  const LoadStateButtonGroup = () => {
    return (
      <div>
        {stateList.map(item => {
          const isOn = loadStates.includes(item.state)
          const onClick = () => {
            let copiedLoadStates = [...loadStates]
            if (isOn) {
              copiedLoadStates = copiedLoadStates.filter(state => state !== item.state)
            } else {
              copiedLoadStates = [...copiedLoadStates, item.state]
            }
            setLoadStates(copiedLoadStates)
          }
          return (
            <Button
              key={item.state} 
              onClick={onClick}
              className={isOn && "ant-radio-button-wrapper-checked"}
            >
              {item.icon}
            </Button>
          )
        })}
      </div>
    )
  }
  
  return {
    page, 
    per_page, 
    category,
    keyword,
    rowSelection, 
    onChange,
    handleSearch, 
    fetchDelete,
    searchHighlight,
    SelectedCount,
    Footer,
    RemoveBtn,
    ListPageSearchBox,
    pagination,
    LoadStateButtonGroup
  }
};

export default useListPageContext;