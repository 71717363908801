import React, { useEffect, useRef, useState } from "react";
import { Space, Popconfirm, Button, Table, Tooltip, Modal, Typography, Dropdown} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector} from "react-redux";
import { load, del, clear, loadPreset, clearPreset, updateReportTemplateModalOpen } from "../redux/modules/pipeline"
import {useHistory, useLocation} from "react-router-dom";
import SearchBox from "./searchBox";
import useListPageContext from "../context/listPageContext";
import {useTitle} from "../hooks/title";
import { load as loadDataset, clear as clearDataset } from "../redux/modules/dataset";
import {AnalysisRunModal} from "./analysisRunModal";
import { load as loadImagetype  } from "../redux/modules/blobTypes"
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import {created_column} from "./createdRender";
import { TagBase } from "./tagBase";
import { EllipsisTooltipWithParagraph } from "../context/searchHighlightContext";
import EllipsisTooltipForImagetypes from "./ellipsisTooltipForImagetypes";
import ReportTemplateModal from "./report/reportTemplateModal";

const {Paragraph} = Typography

const searchOptions = [
  {value:"name", label:"Name", dataIndex : 'name'},
  {value:"desc", label:"Description", dataIndex : 'desc'},
]

export const pipeline_base_columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Task count',
    dataIndex: 'task_count',
    key: 'task_count',
    align : 'center',
    width: 130
  },
  {
    title: 'Longitudinal',
    dataIndex: 'longitudinal',
    key: 'longitudinal',
    align: 'center',
    width: 130,
    render: val => val ? 'O' : 'X',
  },
];

export const used_types_column = getTypeFromShort => ({
  title : "Image type",
  dataIndex : 'modalities',
  key : 'modalities',
  width : '270px',
  align: 'center',
  render : type_shorts => {
    const contents = (
      <div>
        {type_shorts?.map(short => {
          const type = getTypeFromShort(short)
          return type ? <TagBase color={type.tag_color} children={short} style={{margin: '4px'}}/> : null
          })
        }
      </div>)
    return (
      <div style={{display: 'flex', textAlign: 'left'}}>
        <EllipsisTooltipForImagetypes
          title={contents}
          placement="right"
          color={'#0C2329'}
        >
          {contents}
        </EllipsisTooltipForImagetypes>
      </div>
    )
}})


function PipelineList() {
  useTitle('DYNAPEX | Pipelines')
  const history = useHistory();
  const dispatch = useDispatch()
  const location = useLocation()
  const loading = useSelector(state => state.pipeline?.loading)
  const list = useSelector(state => state.pipeline.list)
  const total = useSelector(state => state.pipeline?.total)
  const dataset_id = useRef(0)
  const pipeline_id = useRef(0)
  
  const [columns, setColumns] = useState([])
  useEffect(()=>{
    dispatch(loadDataset({}))
    dispatch(loadImagetype({}))
    return () => dispatch(clearDataset())
  },[])

  const {
    rowSelection,
    handleSearch,
    fetchDelete,
    searchHighlight,
    Footer
  } = useListPageContext({load, del, clear, searchOptions, total});

  const {getTypeFromShort} = useGetImageTypeHooks()

  const handleEdit = record => history.push('/pipeline/detail', {key: record.id})
  const handleCreate = () => history.push('/pipeline/detail', {key: -1})
  const handleCreateFromPreset = () => dispatch(loadPreset())
  const handleDelete = () => fetchDelete()
  
  const action_column = (
    {
      title: 'Actions',
      key: 'action',
      width: 160,
      fixed : 'right',
      align : 'center',
      render: record => (
        <Space>
          <Tooltip title='Incomplete pipeline' open={record.valid ? false : undefined}>
            <Button onClick={() => handleAnalyze(record)} type='primary' disabled={!record.valid}>
                Run
            </Button>
          </Tooltip>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        </Space>
      )
    }
  )

  useEffect(()=>{
    const pipeline_columns = pipeline_base_columns.map(column => ({
      ...column,
      render : column.render 
              ? column.render
              : text => searchOptions.some(option => option.dataIndex === column.dataIndex) 
                ? searchHighlight(text, column.dataIndex)
                : text
    }))
    const added_columns = [used_types_column(getTypeFromShort), created_column, action_column]
    pipeline_columns.push(...added_columns)

    setColumns(pipeline_columns)
  },[list])
  
  const [showModal, setShowModal] = useState(false);
  const [selectRowIndex, setSelectRowIndex] = useState(null)

  const handleAnalyze = record => {
    pipeline_id.current = record.id
    setSelectRowIndex(record.id)
    setShowModal(true);
  };

  useEffect(()=>{
    if (!showModal) {
      setSelectRowIndex(null)
    }
  },[showModal])

  const items = [
    {
      label: 'from preset',
      key: 'create_from_preset',
    }
  ]

  const menuProps = {
    items,
    onClick: ({ item, key, keyPath, domEvent }) => {
      if (key === 'create_from_preset') {
        handleCreateFromPreset()
      }
    }
  }

  const onOpenReportTemplateModal = () => dispatch(updateReportTemplateModalOpen(true))

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <h1>Pipeline</h1>
      <div style={{display: "inline-block"}}>
        <Space>
          <Dropdown.Button
            menu={menuProps}
            onClick={handleCreate}
          >
            Create
          </Dropdown.Button>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleDelete}
            getPopupContainer={trigger => trigger}
            overlayInnerStyle={{width: 150}}
          >
            <Button>Remove</Button>
          </Popconfirm>
          <Button onClick={onOpenReportTemplateModal}>
            Report template
          </Button>
        </Space>
      </div>
      <SearchBox
        style={{display: "inline-block", float: 'right', width:"50%"}}
        options={searchOptions}
        onSearch={handleSearch}
      />
      <Table
        pagination={false}
        footer={Footer}
        loading={loading}
        columns={columns}
        rowClassName={(record, index)=> record.id === selectRowIndex ? 'ant-table-row-selected' : null}
        dataSource={list}
        rowKey={'id'}
        rowSelection={rowSelection}
      />
      <AnalysisRunModal 
        visible={showModal}
        setVisible={setShowModal}
        pathname={location.pathname}
        dataset_id={dataset_id}
        pipeline_id={pipeline_id}
      />
      <PipelineTemplateModal />
      <ReportTemplateModal />
    </div>
  );
}

export default PipelineList;

const PipelineTemplateModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pipeline_template_list = useSelector(state => state.pipeline?.pipeline_template?.list) || []
  const [selectedRowKeys, setSelectedRowKeys] = useState([0])

  const list = pipeline_template_list.map((item, index) => {
    item.task_count = item.task.length
    item.index = index
    return item
  })
  
  const {getTypeFromShort} = useGetImageTypeHooks()

  const columns = [
    ...pipeline_base_columns.map(column => ({
      ...column,
      render : column.render 
      ? column.render 
      : text => 
        <EllipsisTooltipWithParagraph
          title={<Paragraph ellipsis={{rows : 10,}} children={text}/>}
          children={text}
        />
    })), used_types_column(getTypeFromShort)]
  
  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
    onChange: (selectedRowKeys, selectedRows) => setSelectedRowKeys(selectedRowKeys)
  }
  
  const onRow = (record, index) => ({
    onClick: e => setSelectedRowKeys([record.index])
  })
  
  const onOk = () => history.push('/pipeline/detail', {key: -1, index: selectedRowKeys[0]})
  const onCancel = () => dispatch(clearPreset())

  return (
    <Modal 
      title={`Please select a preset to create`}
      closable={false}
      open={list.length}
      onOk={onOk}
      onCancel={onCancel}
      width={'75%'}
    >
      <Table 
        pagination={false}
        columns={columns}
        dataSource={list}
        rowKey={'index'}
        rowSelection={rowSelection}
        onRow={onRow}
      />
    </Modal>
  )
}