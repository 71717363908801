import React, {useState, useRef, useEffect} from "react";
import {Button, Modal, Cascader, Select, Space, Table, Popconfirm, Tooltip} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CornerstoneElementPlain from './cornerstoneElementPlain'
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import ImageTypeSelect from "./imageTypeSelect";
import * as cornerstone from "cornerstone-core";
import { msgError } from "../redux/modules/message";
import { usePatientSearchInResultContext } from "../context/patientSearchInResultContext";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import { EditableRow, EditableCell} from "./tableEditable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faLayerGroup,
  faUndo,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter
} from "@fortawesome/free-solid-svg-icons";
import { patientIdRule, patientNameRule, studyDescRule, studyIdRule } from "../lib/patientDataRule";
import {buildDicomStack} from "../lib/UtilDicomParse";
import blinkMergedElement from "../lib/blinkMergedElement";

function PatientStudyList({resolveds, unresolveds, loading, update:dispatch, binds}) {
  const {getShortFromTypeId, getTypeFromTypeId} = useGetImageTypeHooks()
  const {
    getColumnSearchProps, 
    initializeFilterSorter, 
    filterInfo, 
    sortOrder, 
    onTableChange,
    showSorterTooltip
  } = usePatientSearchInResultContext()


  const isExpandedPatients = resolveds.some(patient => patient.expanded)
  const isExpandedStudies = resolveds.some(patient => patient.studies.some(study => study.expanded))

  // useEffect(() => {
  //   console.log(isExpandedPatients, isExpandedStudies);
  // },[isExpandedPatients, isExpandedStudies])

  const onExpandPatientRowChange = (expandedRows) => {
    const patients = [...resolveds]
    patients.forEach(patient => {
      if (expandedRows.includes(patient.key)) {
        patient.expanded = true
      } else {
        patient.expanded = false
      }
    })
    dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
  }
  const onExpandStudyRowChange = (expandedStudyRows, recordPatient) => {
    const patients = [...resolveds]
    patients.forEach(patient => {
      if (patient.key === recordPatient.key) {
        patient.studies.forEach(study => {
          if (expandedStudyRows.includes(study.key)) {
            study.expanded = true
          } else {
            study.expanded = false
          }
        })
      }
    })
    dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
  }
  const rowSelectionPatient = {
    selectedRowKeys: resolveds.filter(patient => patient.selected).map(patient=> patient.key),
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys) => {
      const patients = [...resolveds]
      patients.forEach(p => 
        p.selected = selectedRowKeys.includes(p.key)
      )
      dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
    },
    getCheckboxProps : (record) => ({
      disabled : record.disabled,
    })
  };
  
  const expandablePatient = {
    expandRowByClick: true,
    expandedRowRender: (record, _) => expandPatientRowRender(record),
    expandedRowKeys: resolveds.filter(patient=>patient.expanded).map(patient => patient.key),
    onExpandedRowsChange: onExpandPatientRowChange,
  }


  const expandTogglePatients = isExpandedPatients => {
    const patients = [...resolveds]
    patients.forEach(patient => {
      patient.expanded = !isExpandedPatients
    })
    dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
  }

  const expandToggleStudies = isExpandedStudies => {
    const patients = [...resolveds]
    patients.forEach(patient => {
      patient.studies.forEach(study => {
        study.expanded = !isExpandedStudies
      })
    })
    dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
  }
  
  const handleRemoveSelected = () => {
    const patients = [...resolveds.filter(p => !p.selected)]
    patients.forEach(p => {
      p.studies = [...p.studies.filter(s => !s.selected)]
      p.studies.forEach(s => {
        s.blobs = [...s.blobs.filter(blob => !blob.selected)]
        s.series = [...s.series.filter(series => !series.selected)]
      })
    })
    dispatch({type: 'UPDATE_AND_REBUILD_BINDS', payload: {resolveds: patients}})
  }

  const [showMergeModal, setShowMergeModal] = useState(false);
  const mergeData = useRef(null)
  const [mergeOptionSelected, setMergeOptionSelected] = useState(null);
  const handleMergeCancel = e => {
    setShowMergeModal(false);
    setMergeOptionSelected(null)
  };

  const handleMergeOk = e => {
    let newData = null;
    const selectedKey = mergeOptionSelected?.val
    if (mergeData.current.hasOwnProperty('patient')) {
      // 1. MERGE PATIENT
      newData = resolveds.filter(p => !p.selected || p.key === selectedKey)

      const selectedPatient = mergeData.current.patient.find(p => p.key === selectedKey)
      const others = mergeData.current.patient.filter(p => p.key !== selectedKey)
      others.forEach(p => {
        selectedPatient.studies = selectedPatient.studies.concat(p.studies)
        selectedPatient.studies.forEach(s => {
          // update series and blob bind
          s.series.forEach(sr => sr.bind = [selectedPatient.key, s.key])
          s.blobs.forEach(b => b.bind = [selectedPatient.key, s.key])
        })
      })
      selectedPatient.selected = false

      blinkMergedElement(selectedPatient.key)
    }
    else {
      // 2. MERGE STUDY
      newData = resolveds.map(p => {
        p.studies = p.studies.filter(s => !s.selected || s.key === selectedKey)
        return p
      }).filter(p => p.studies.length !== 0)

      const selectedStudy = mergeData.current.study.find(s => s.key === selectedKey)
      const selectedStudyPatient = resolveds.find(p => p.studies.some(s => s.key === selectedKey))
      const others = mergeData.current.study.filter(s => s.key !== selectedKey)
      others.forEach(s => {
        selectedStudy.series = selectedStudy.series.concat(s.series)
        selectedStudy.blobs = selectedStudy.blobs.concat(s.blobs)
        // update series and blob bind
        selectedStudy.series.forEach(sr => sr.bind = [selectedStudyPatient.key, selectedStudy.key])
        selectedStudy.blobs.forEach(b => b.bind = [selectedStudyPatient.key, selectedStudy.key])
      })
      blinkMergedElement(selectedStudy.key)
      selectedStudy.selected = false
      selectedStudyPatient.selected = false
    }
    dispatch({type: 'REPLACE_AND_REBUILD_BINDS', payload: {resolveds: newData}})
    setShowMergeModal(false);
    setMergeOptionSelected(null)
  };

  const handleMergeSelectChange = (val, option) => {
    // console.log(val, option)
    setMergeOptionSelected({val, option})
  }

  const getSelectedFromResolveds = () => {
    return {
      patient: resolveds.filter(p => p.selected),
      study: resolveds.map(p => p.studies.filter(s => s.selected)).flat(Infinity),
      series: resolveds.map(p => p.studies.map(s => s.series.filter(sr => sr.selected))).flat(Infinity),
      images: resolveds.map(p => p.studies.map(s => s.blobs.filter(b => b.selected))).flat(Infinity)
    }
  }

  const mergeable = () => {
    const {patient, study, series, images} = getSelectedFromResolveds()
    const counts = [patient.length, study.length, series.length, images.length] // patient, study, series, blobs
    if (counts[0] > 1 && counts[1] + counts[2] + counts[3] === 0) {
      return true
    }
    if (counts[1] > 1 && counts[0] + counts[2] + counts[3] === 0) {
      return true
    }

    return false
  }

  const handleMerge = () => {
    const {patient, study} = getSelectedFromResolveds()
    // merge 대상이 patient 인가 study 인가
    if (patient.length > 0) {
      mergeData.current = {
        patient,
        options: patient.map(option => {
          return {key: option.key, label: `Patient ID: ${option.pid} - Name: ${option.pname}`, value: option.key}
        })
      }
      // patient 로 options 만들고 선택한 patient 로 다른 patient studies 옮기면 끝. 끝나고 dispatch
    }
    else if (study.length > 0) {
      mergeData.current = {
        study,
        options: study.map(option => {
          return {key: option.key, label: `Study ID: ${option.sid} - Date: ${option.date}`, value: option.key}
        })
      }

      // study 로 options 만들고 선택한 study 로 다른 study 들 series, blobs 옮기면 끝. 끝나고 dispatch
      // TODO 상위 개체, 즉 patient 가 다르면?
    }
    const defaultOption = mergeData.current?.options?.[0]
    setMergeOptionSelected({val: defaultOption.key, option: defaultOption})
    setShowMergeModal(true)
  }

  const handleRemoveNoImageType = () => {
    const patients = [...resolveds]
    patients.forEach(p => {
      p.studies.forEach(s => {
        s.series = [...s.series.filter(series => series?.type)]
        s.blobs = [...s.blobs.filter(blob => blob?.type)]
      })
    })
    dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
  }

  const handleRemoveFilter = () => {
    initializeFilterSorter()
  }

  const handleDeletePatient = key => {
    const newData = [...resolveds.filter(item => item.key !== key)];
    dispatch({type: 'REPLACE_AND_REBUILD_BINDS', payload: {resolveds: newData}})
  };
  const handleDeleteStudy = ({study, patient}) => {
    const newData = [...resolveds];
    newData.forEach(p => {
      if (patient.key === p.key) {
        p.studies = [...p.studies.filter(item => item.key !== study.key)]
      }
    })
    dispatch({type: 'UPDATE_AND_REBUILD_BINDS', payload: {resolveds: newData}})
  }
  const handleDeleteSeries = ({series, study, patient}) => {
    const newData = [...resolveds];
    newData.forEach(p => {
      if (patient.key === p.key) {
        p.studies.forEach(s => {
          if (study.key === s.key) {
            s.series = [...s.series.filter(item => item.key !== series.key)]
          }
        })
      }
    })
    dispatch({type: 'UPDATE_ONLY', payload: {resolveds: newData}})
  };

  const handleDeleteBlob = ({blob, study, patient}) => {
    const newData = [...resolveds];
    newData.forEach(p => {
      if (patient.key === p.key) {
        p.studies.forEach(s => {
          if (study.key === s.key) {
            s.blobs = [...s.blobs.filter(item => item.key !== blob.key)]
          }
        })
      }
    })
    dispatch({type: 'UPDATE_ONLY', payload: {resolveds: newData}})
  }
  function expandPatientRowRender(recordPatient) {
    const rowSelectionStudy = {
      selectedRowKeys: recordPatient.studies.filter(s => s.selected).map(s => s.key),
      preserveSelectedRowKeys: false,
      onChange: (selectedRowKeys) => {
        const patients = [...resolveds]
        patients.forEach(patient => {
          if (patient.key === recordPatient.key) {
            patient.studies.forEach(study=> {
              study.selected = selectedRowKeys.includes(study.key)
            })
          }
        })
        dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
      },
      getCheckboxProps : (record) => ({
        disabled : record.disabled,
      })
    };
    const expandStudyRowRender = (recordStudy) => {
      const rowSelectionBlob = {
        selectedRowKeys: recordStudy.blobs.filter(blob => blob.selected).map(blob => blob.key),
        preserveSelectedRowKeys: false,
        onChange: (selectedRowKeys, selectedRows) => {
          const patients = [...resolveds]
          patients.forEach(patient => {
            if (patient.key === recordPatient.key) {
              patient.studies.forEach(study => {
                if (study.key === recordStudy.key) {
                  study.blobs.forEach(blob => {
                    blob.selected = selectedRowKeys.includes(blob.key)
                  })
                }
              })
            }
          })
          dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
        },
        getCheckboxProps : record => ({
          disabled : record.disabled,
        })
      };
      const rowSelectionSeries = {
        selectedRowKeys: recordStudy.series.filter(series => series.selected === true).map(series => series.key),
        onChange: (selectedRowKeys, selectedRows) => {
          const patients = [...resolveds]
          patients.forEach(patient => {
            if (patient.key === recordPatient.key) {
              patient.studies.forEach(study => {
                if (study.key === recordStudy.key) {
                  study.series.forEach(series => {
                    series.selected = selectedRowKeys.includes(series.key)
                  })
                }
              })
            }
          })
          dispatch({type:'UPDATE_ONLY', payload:{resolveds : patients}})
        },
        getCheckboxProps : record => ({
          disabled : record.disabled,
        })
      };
      const handleSeriesTypeSelectChange = record => (val, option) => {
        const newData = [...resolveds];
        newData.forEach(p => p.studies.forEach(s => s.series.forEach(series => {
          if (!series.disabled && series.key === record.key) {
            series.type = val;
          }
        })));
        dispatch({type: 'UPDATE_ONLY', payload: {resolveds: newData}})
      }
      const handleBlobTypeSelectChange = record => (val, option) => {
        const newData = [...resolveds];
        newData.forEach(p => p.studies.forEach(s => s.blobs.forEach(blob => {
          if (!blob.disabled && blob.key === record.key) {
            blob.type = val;
          }
        })));
        dispatch({type: 'UPDATE_ONLY', payload: {resolveds: newData}})
      }

      const handleBindChange = (recordPatient, recordStudy, record) => (val, option) => {
        if (val && val[0] === recordPatient.key && val[1] === recordStudy.key) {
          return
        }

        recordStudy.blobs = recordStudy.blobs.filter(blob => blob.key !== record.key) // remove
        if (val) {
          // add this to new position
          resolveds.forEach(p => {
            if (p.key === val[0]) {
              // add it to new study
              p.studies.forEach(s => {
                if (s.key === val[1]) {
                  record.bind = val
                  s.blobs.push(record) // add
                }
              })
            }
          })
          dispatch({type:'UPDATE_ONLY', payload:{resolveds}})
        }
        else {
          // add this to unresolveds
          record.bind = undefined
          unresolveds.push(record)
          dispatch({type:'UPDATE_ONLY', payload:{resolveds, unresolveds}})
        }
      }

      const commonBlobKey = `${recordPatient.key}/${recordStudy.key}/blob`

      const blobColumnKeys = {
        name : `${commonBlobKey}/name`,
        type : `${commonBlobKey}/type`,
      }

      const blobName = record => record.name

      const blob_columns = [
        {
          title: 'Path',
          dataIndex: 'name',
          key: blobColumnKeys.name,
          width: '40%',
          sorter: (a, b) => blobName(a).toUpperCase() > blobName(b).toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(blobColumnKeys.name),
          showSorterTooltip : showSorterTooltip(blobColumnKeys.name),
          render: (text, record) => {
            const fileName = blobName(record)
            return (
              <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                {fileName}
              </div>
            )
          },
        },
        {
          title: 'Bind',
          key: 'bind',
          render: (text, record, ridx) => (
            <Cascader
              disabled={record.disabled}
              style={{width: '310px'}}
              showSearch={true}
              options={binds}
              onChange={handleBindChange(recordPatient, recordStudy, record)}
              defaultValue={record?.bind}
            />
          ),
        },
        {
          title: 'Type',
          key: blobColumnKeys.type,
          sorter: (a, b) => 
              (getShortFromTypeId(a.type)?.toUpperCase()||"") 
              > (getShortFromTypeId(b.type)?.toUpperCase()||"") ? 1 : -1,
          sortOrder : sortOrder(blobColumnKeys.type),
          showSorterTooltip : showSorterTooltip(blobColumnKeys.type),
          render: (text, record) => (
            <div>
              <ImageTypeSelect
                style={{width: '100%'}}
                onChange={handleBlobTypeSelectChange(record)}
                dropdownMatchSelectWidth={false}
                defaultValue={record.blobtype_id}
                value={record.type}
                row_index={record}
                disabled={record.disabled? true : false}
              />
            </div>
          ),
        },
        {
          title: 'Actions',
          key: 'action',
          width: '120px',
          align :'center',
          render: (record) => (
            <Space>
              <Button onClick={(e) => {
                dispatch({type:'UPDATE_ONLY', payload:{loading: true}})
                doModalNifti(e, record)
              }}>View</Button>
              <Popconfirm
                title={"Are you sure?"}
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={(e) => handleDeleteBlob({blob: record, study: recordStudy, patient: recordPatient})}
              >
                <Button>Remove</Button>
              </Popconfirm>
            </Space>
          ),
        },
      ];

      const commonSeriesKey = `${recordPatient.key}/${recordStudy.key}/series`

      const seriesColumnKeys = {
        sn : `${commonSeriesKey}/sn`,
        protocol : `${commonSeriesKey}/protocol`,
        date : `${commonSeriesKey}/date`,
        desc : `${commonSeriesKey}/desc`,
        files : `${commonSeriesKey}/files`,
        type : `${commonSeriesKey}/type`,
      }

      const series_columns = [
        {
          ...getColumnSearchProps({
            title : 'Series number',
            dataIndex : 'sn',
            key : seriesColumnKeys.sn,
            sorter: (a, b) => a.sn > b.sn ? 1 : -1,
            sortOrder : sortOrder(seriesColumnKeys.sn),
          }),
        },
        {
          ...getColumnSearchProps({
            title : 'Series desc.',
            dataIndex : 'desc',
            key : seriesColumnKeys.desc,
            sorter: (a, b) => a.desc.toUpperCase() > b.desc.toUpperCase() ? 1 : -1,
            sortOrder : sortOrder(seriesColumnKeys.desc),
          }),
        },
        {
          ...getColumnSearchProps({
            title : 'Protocol name',
            dataIndex : 'protocol',
            key : seriesColumnKeys.protocol,
            sorter: (a, b) => a.protocol?.toUpperCase() > b.protocol?.toUpperCase() ? 1 : -1,
            sortOrder : sortOrder(seriesColumnKeys.protocol),
          }),
        },
        {
          ...getColumnSearchProps({
            title : 'Date',
            dataIndex : 'date',
            key : seriesColumnKeys.date,
            sorter: (a, b) => a.date > b.date ? 1 : -1,
            sortOrder : sortOrder(seriesColumnKeys.date),
            width : '95px'
          }),
        },
        {
          title: 'Image count',
          dataIndex: 'files',
          key : seriesColumnKeys.files,
          shouldCellUpdate: (record, prevRecord) => false,
          sorter: (a, b) => (a.files?.length || a.count)  > (b.files?.length || b.count) ? 1 : -1,
          sortOrder : sortOrder(seriesColumnKeys.files),
          showSorterTooltip : showSorterTooltip(seriesColumnKeys.files),
          width : '130px',
          render: (files, record) => record.disabled ? record.image_count : files.length
        },
        {
          title: 'Type',
          key : seriesColumnKeys.type,
          sorter: (a, b) => 
            (getShortFromTypeId(a.type)?.toUpperCase()||"") 
            > (getShortFromTypeId(b.type)?.toUpperCase()||"") ? 1 : -1,
          sortOrder : sortOrder(seriesColumnKeys.type),
          showSorterTooltip : showSorterTooltip(seriesColumnKeys.type),
          render: (text, record) => (
            <div onClick={(e) => e.stopPropagation()}>
              <ImageTypeSelect
                onChange={handleSeriesTypeSelectChange(record)}
                dropdownMatchSelectWidth={false}
                defaultValue={record.blobtype_id}
                row_index={{study: recordStudy, series:record}}
                value={record.type}
                disabled={record.disabled ? true : false}
              />
            </div>
          ),
        },
        Table.EXPAND_COLUMN,
        {
          title: 'Actions',
          key: 'action',
          width: '120px',
          align :'center',
          render: (text, record, ridx) => (
            <Space>
              <Button onClick={(e) => {
                dispatch({type:'UPDATE_ONLY', payload:{loading: true}})
                doModalDicom(e, record)
              }}>View</Button>
              <Popconfirm
                title={"Are you sure?"}
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={(e) => handleDeleteSeries({series: record, study: recordStudy, patient: recordPatient})}
              >
                <Button>Remove</Button>
              </Popconfirm>
            </Space>
          ),
        },
      ];
      return (
        <>
          DICOM
          <Table
            key="key"
            locale={{emptyText:"No data"}}
            style={{marginLeft: 40}}
            columns={series_columns}
            dataSource={[...recordStudy.series]}
            pagination={false}
            rowSelection={rowSelectionSeries}
            onChange={onTableChange}
            expandable={{
              indentSize: 60,
              rowExpandable : record => record.files ? true : false,
              expandedRowRender: record => {
                record.files.sort((a, b) => {
                  const aNum = parseInt(a.instanceNum);
                  const bNum = parseInt(b.instanceNum);
                  return aNum > bNum ? 1 : aNum < bNum ? -1 : 0;
                })
                record.files.sort((a, b) => {
                  return a.file.name > b.file.name ? 1 : a.file.name < b.file.name ? -1 : 0;
                });
                return record.files.map(el => (
                  <p key={el.file.name} style={{margin: 0}}>{el.file.path}{'-#'}{el.instanceNum}</p>)
                );
              },
            }}
          />
          <br/>
          NIfTI
          <Table
            key="sid"
            locale={{emptyText:"No data"}}
            style={{marginLeft: 40}}
            columns={blob_columns}
            dataSource={[...recordStudy.blobs]}
            pagination={false}
            rowSelection={rowSelectionBlob}
            onChange={onTableChange}
          />
        </>
      );
    };

    const commonStudyKey = `${recordPatient.key}/studies`

    const studyColumnKeys = {
      sid : `${commonStudyKey}/sid`,
      date : `${commonStudyKey}/date`,
      desc : `${commonStudyKey}/desc`,
      imageCount : `${commonStudyKey}/imageCount`
    }
    const study_columns = [
      // {
      //   title : 'LCP',
      //   dataIndex : 'lcp',
      //   key : 'lcp',
      // },
      {
        ...getColumnSearchProps({
          title : 'Study ID',
          dataIndex : 'sid',
          key : studyColumnKeys.sid,
          sorter: (a, b) => a.sid > b.sid ? 1 : -1,
          sortOrder : sortOrder(studyColumnKeys.sid),
          required: false,
          addedRule: studyIdRule,
          editable: true
        }),
      },
      {
        ...getColumnSearchProps({
          title : 'Study desc',
          dataIndex : 'desc',
          key : studyColumnKeys.desc,
          width: '30%',
          sorter: (a, b) => a.desc.toUpperCase() > b.desc.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(studyColumnKeys.desc),
          required: false,
          addedRule: studyDescRule,
          editable: true
        }),
      },
      {
        ...getColumnSearchProps({
          title : 'Study Date',
          dataIndex : 'date',
          key : studyColumnKeys.date,
          sorter: (a, b) => a.date > b.date ? 1 : -1,
          sortOrder : sortOrder(studyColumnKeys.date),
          editableCellType: 'date',
          editable: true
        }),
      },
      {
        title: 'Image count',
        dataIndex: 'imageCount',
        key : studyColumnKeys.imageCount,
        sorter: (a, b) => a.imageCount > b.imageCount ? 1 : -1,
        sortOrder : sortOrder(studyColumnKeys.imageCount),
        showSorterTooltip : showSorterTooltip(studyColumnKeys.imageCount),
        width : '130px',
        render: (_, record) => {
          let count = 0
          count += record.series.reduce((acc, val) => acc + (val.disabled ? val.image_count : val?.files?.length || 0), 0)
          // count += record.series.reduce((acc, val) => acc + val?.files?.length|| 0, 0)
          count += record.blobs.length
          return count
        }
      },
      {
        title: 'Actions',
        key: 'action',
        width: '120px',
        align :'center',
        shouldCellUpdate: (record, prevRecord) => false,
        render: (text, record, ridx) => (
          <Space>
            <Popconfirm
              title={"Are you sure?"}
              icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
              onConfirm={(e) => {
                e.stopPropagation()
                handleDeleteStudy({study:record, patient:recordPatient})
              }}
              onCancel={e=>e.stopPropagation()}
              onClick={e =>e.stopPropagation()}
              disabled={record.disabled ? true : false}
            >
              <Button 
                onClick={e=>e.stopPropagation()}
                disabled={record.disabled}
              >Remove</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    const expandableStudy = {
      indentSize: 30,
      expandRowByClick: true,
      expandedRowRender: expandStudyRowRender,
      expandedRowKeys: recordPatient.studies.filter(study => study.expanded).map(study => study.key),
      onExpandedRowsChange: (expandedStudyRows) => onExpandStudyRowChange(expandedStudyRows, recordPatient),
    }
    return (
      <Table
        columns={column_editable(study_columns, recordPatient.key)}
        dataSource={[...recordPatient.studies]}
        rowSelection={rowSelectionStudy}
        expandable={expandableStudy}
        onChange={onTableChange}
        pagination={[...recordPatient.studies].length > 10}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          }
        }}
        rowClassName={() => 'editable-row'}
      />
    );
  };

  const commonPatientKey = 'patient'

  const patientColumnKeys = {
    pname : `${commonPatientKey}/pname`,
    pid : `${commonPatientKey}/pid`,
    age : `${commonPatientKey}/age`,
    birth :`${commonPatientKey}/birth`,
    sex : `${commonPatientKey}/sex`,
  }

  const patient_columns = [
    Table.EXPAND_COLUMN,
    // {
    //   title : 'LCP',
    //   dataIndex : 'lcp',
    //   key : 'lcp',
    // },
    {
      ...getColumnSearchProps({
        title : 'Patient ID',
        dataIndex : 'pid',
        key : patientColumnKeys.pid,
        sorter: (a, b) => a.pid > b.pid ? 1 : -1,
        sortOrder : sortOrder(patientColumnKeys.pid),
        addedRule: patientIdRule,
        editable: true
      }),
    },
    {
      ...getColumnSearchProps({
        title : 'Patient Name',
        dataIndex : 'pname',
        key: patientColumnKeys.pname,
        sorter: (a, b) => a.pname.toUpperCase() > b.pname.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder(patientColumnKeys.pname),
        addedRule: patientNameRule,
        editable: true
      }),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: patientColumnKeys.age,
      sorter: (a, b) => parseInt(a.age) - parseInt(b.age),
      sortOrder : sortOrder(patientColumnKeys.age),
      showSorterTooltip : showSorterTooltip(patientColumnKeys.age),
    },
    {
      ...getColumnSearchProps({
        title : 'Birthday',
        dataIndex : 'birth',
        key : patientColumnKeys.birth,
        sorter: (a, b) => a.birth > b.birth ? 1 : -1,
        sortOrder : sortOrder(patientColumnKeys.birth)
      }),
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: patientColumnKeys.sex,
      filteredValue : filterInfo[patientColumnKeys.sex] || null,
      filters: Array.from(new Set(resolveds.map(patient => patient.sex))).map(sex => {
        return {text : sex, value : sex}
      }),
      onFilter: (value, record) => record.sex.includes(value),
      sorter: (a, b) => a.sex.toUpperCase() > b.sex.toUpperCase() ? 1 : -1,
      sortOrder : sortOrder(patientColumnKeys.sex),
      showSorterTooltip : showSorterTooltip(patientColumnKeys.sex),
      shouldCellUpdate: (record, prevRecord) => false,
    },
    {
      title: 'Actions',
      key: 'action',
      width: '170px',
      align :'center',
      shouldCellUpdate: (record, prevRecord) => false,
      render: (record) => (
        <Space>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={e=>{
              e.stopPropagation()
              handleDeletePatient(record.key)
            }}
            onCancel={e =>e.stopPropagation()}
            onClick={e =>e.stopPropagation()}
            disabled={record.disabled}
          >
            <Button 
              disabled={record.disabled}
              onClick={e=>e.stopPropagation()}
            >Remove</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const saveEditData = (row, pKey) => {
    const copiedPatients = [...resolveds];
    const patientIndex = copiedPatients.findIndex(p => {
      if (pKey === undefined) {
        return p.key === row.key
      }
      return p.key === pKey
    })
    const targetPatient = copiedPatients[patientIndex];
    if (pKey !== undefined) {
      const studyIndex = targetPatient.studies.findIndex(s => {
        return s.key === row.key
      })
      const targetStudy = targetPatient.studies[studyIndex]
      targetPatient.studies.splice(studyIndex, 1, {
        ...targetStudy,
        ...row
      });
    }
    else {
      copiedPatients.splice(patientIndex, 1, {
        ...targetPatient,
        ...row
      })
    }
    dispatch({type:'UPDATE_ONLY', payload: {resolveds:copiedPatients}})
  }

  const column_editable = (column, pKey=undefined) => column.map(col => {
    if (!col.editable) return col
    return {
      ...col,
      onCell: (record, _) => ({
        record,
        ...col,
        handleSave: row => saveEditData(row, pKey),
      })
    }
  })

  const [stack, setStack] = useState({imageIds: [], currentImageIdIndex: 0});
  const [showModal, setShowModal] = useState(false);
  const [modalColormap, setModalColormap] = useState("gray");
  const doModalDicom = async (e, record) => {
    e.stopPropagation();

    const urls = record.files.map(f => URL.createObjectURL(f.file))
    const stack = await buildDicomStack(urls)
    setStack(stack);
    urls.map(url => URL.revokeObjectURL(url))

    const currImageType = getTypeFromTypeId(record.type)
    currImageType ? setModalColormap(currImageType.cmap_id) : setModalColormap("gray")
    setShowModal(true);
    dispatch({type:'UPDATE_ONLY', payload:{loading: false}})
  }

  const doModalNifti = async (e, record) => {
    
    cornerstoneNIFTIImageLoader.nifti.localMode = false;
    
    const tmpURL = URL.createObjectURL(record.file);
    const niftiPath = `nifti:${tmpURL}`;

    // const fileManager = cornerstoneNIFTIImageLoader.nifti.fileManager;
    // fileManager.add(record.file.path, record.file);

    // const niftiPath = `nifti:${record.file.path}`
    const image = await cornerstone.loadAndCacheImage(niftiPath);
    const multiFrameModule = cornerstone.metaData.get('multiFrameModule', image.imageId)
    if (multiFrameModule !== undefined) {
      const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
      const imageIdObject = ImageId.fromURL(image.imageId);
      const numberOfSlices = multiFrameModule.numberOfFrames;
      const imageIds = Array.from(Array(numberOfSlices), (_, i) => `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i}`)
      const stack = {
        imageIds: imageIds,
        currentImageIdIndex: Math.floor(numberOfSlices / 2) || 0,
      };
      setStack(stack);
      const currImageType = getTypeFromTypeId(record.type)
      currImageType ? setModalColormap(currImageType.cmap_id) : setModalColormap("gray")
      setShowModal(true);
      window.URL.revokeObjectURL(tmpURL);
    }
    else {
      dispatch(msgError('Unable to load image'))
    }
    dispatch({type:'UPDATE_ONLY', payload:{loading: false}})
  }

  const handleClose = e => setShowModal(false)

  const [isSticky, setIsSticky] = useState(false);
  const menuRef = useRef()
  const initY = useRef()
  useEffect(() => {
    const rect = menuRef.current.getBoundingClientRect();
    const initialX = rect.left + window.scrollX;
    const initialY = rect.top + window.scrollY;
    initY.current = initialY

    const handleScroll = () => {
      if (initY && window.scrollY > initY.current) {
        setIsSticky(true);
      }
      else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`sticky-menu ${isSticky? 'sticky' : ''}`} ref={menuRef}>
        <h3 style={{display: 'inline-block', marginRight: 20}}>Patients (count:{resolveds?.length})</h3>
        <Space>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleRemoveSelected}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Tooltip
              title="Remove Selected"
              mouseEnterDelay={0.5}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Button icon={<FontAwesomeIcon style={{marginRight:8}} icon={faTrash}/>}> Selected </Button>
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleRemoveNoImageType}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Tooltip
              title="Remove No image type"
              mouseEnterDelay={0.5}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Button icon={<FontAwesomeIcon style={{marginRight:8}} icon={faTrash}/>}>No image type</Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip
            title={`${isExpandedPatients ? 'Collapse' : 'Expand'} All Patient`}
            mouseEnterDelay={0.5}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Button
              icon={<FontAwesomeIcon
                style={{marginRight:8}}
                icon={isExpandedPatients ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}/>}
              onClick={() => expandTogglePatients(isExpandedPatients)}
            >
              All Patient
            </Button>
          </Tooltip>
          <Tooltip
            title={`${isExpandedStudies ? 'Collapse' : 'Expand'} All Study`}
            mouseEnterDelay={0.5}
            getPopupContainer={trigger => trigger.parentNode}
          >
            <Button
              icon={<FontAwesomeIcon
                style={{marginRight:8}}
                icon={isExpandedStudies ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}/>}
              onClick={() => expandToggleStudies(isExpandedStudies)}
            >
              All Study
            </Button>
          </Tooltip>
          <Button
            icon={<FontAwesomeIcon style={{marginRight:8}} icon={faLayerGroup}/>}
            disabled={!mergeable()}
            onClick={handleMerge}
          >
            Merge
          </Button>
          <Button
            icon={<FontAwesomeIcon style={{marginRight:8}} icon={faUndo}/>}
            onClick={handleRemoveFilter}
          >
            Clear Filter
          </Button>
        </Space>
      </div>
      <Table
        size='small'
        loading={loading}
        columns={column_editable(patient_columns)}
        dataSource={resolveds}
        rowSelection={rowSelectionPatient}
        expandable={expandablePatient}
        onChange={onTableChange}
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          }
        }}
        rowClassName={() => 'editable-row'}
      />
      <Modal
        title="Please select where to merge"
        closable={false}
        open={showMergeModal}
        onOk={handleMergeOk}
        onCancel={handleMergeCancel}
      >
        <Select
          style={{width: '100%', borderColor: '#227a76', borderRightWidth: '1px'}}
          onChange={handleMergeSelectChange}
          dropdownMatchSelectWidth={false}
          options={mergeData.current?.options}
          value={mergeOptionSelected?.val}
        />
      </Modal>
      <Modal
        closable={false}
        open={showModal}
        okText="Close"
        okType="default"
        onOk={handleClose}
        onCancel={handleClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        // centered
        width='fit-content'
      >
        <CornerstoneElementPlain
          stack={stack}
          colormap={modalColormap}
        />
      </Modal>
    </div>
  );
}

export default PatientStudyList;

