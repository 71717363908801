import { Tooltip, Typography } from "antd";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";

const {Paragraph} = Typography

export const useSearchHighlight = ({paginationInfo, searchOptions}) => {
  const category = paginationInfo?.category
  const keyword = paginationInfo?.keyword

  const searchHighlight = (text, dataIndex) => {
    const highlight = Highlight(keyword, text)
    return category === searchOptions?.find(option => option.dataIndex === dataIndex)?.value ? (
      <EllipsisTooltipWithParagraph
        title={<Paragraph ellipsis={{rows : 10,}} children={highlight}/>}
        placement="bottomLeft"
        color={'#0D6E6D'}
      >
        {highlight}
      </EllipsisTooltipWithParagraph>
    ) : (
      <EllipsisTooltipWithParagraph 
        title={<Paragraph ellipsis={{rows : 10,}} children={text}/>} 
        placement="bottomLeft" 
        color={'#0D6E6D'}
      >
        {text}
      </EllipsisTooltipWithParagraph>
    )
  }
  return {searchHighlight}
}

//https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
//https://github.com/ant-design/ant-design/issues/5753#issuecomment-451896473
export function EllipsisTooltipWithParagraph({title, placement="bottomLeft", color='#0D6E6D', children}) {
  const [open, setOpen] = useState(false)
  const tooltipRef = useRef()
  const onOpenChange = (visible) => {
    if (tooltipRef.current.clientWidth < tooltipRef.current.scrollWidth) {
      setOpen(visible)
    }
  }
  return (
    <Tooltip 
      open={open}  // 'visible' is dupreacted => use 'open'
      onOpenChange={onOpenChange} 
      title={title}
      placement={placement}
      color={color}
    >
      <Paragraph 
        ref={tooltipRef} 
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          margin : 0,
        }}
      >{children}</Paragraph>
    </Tooltip>
  )
}

export const Highlight = (keyword, text) => (
  <Highlighter
    highlightStyle={{
      backgroundColor: '#ffc069',
      padding: 0,
    }}
    searchWords={[keyword]}
    autoEscape
    textToHighlight={text ? text.toString() : ''}
    style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
  />)