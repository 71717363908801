import {all, call, debounce, fork, put, takeLeading, getContext, select} from "redux-saga/effects";
import * as actions from "../../redux/modules/pipeline";
import axios from "axios";
import {loadSuccess, loadFail, addSuccess, addFail, getSuccess, getFail, updateSuccess, updateFail, 
  delSuccess, delFail, checkNameSuccess, checkNameFail, loadMoreSuccess, loadMoreFail, 
  getPresetSuccess, getPresetFail, loadPresetSuccess, loadPresetFail,
  duplicateSuccess, duplicateFail, loadReportTemplatesSuccess, loadReportTemplatesFail,
  checkReportTemplateNameSuccess, checkReportTemplateNameFail, addReportTemplateSuccess, addReportTemplateFail,
  getReportTemplateSuccess, getReportTemplateFail, updateReportTemplateSuccess, updateReportTemplateFail, 
  delReportTemplatesSuccess, delReportTemplatesFail, duplicateReportSuccess, duplicateReportFail,
  loadReportTemplatePresetSuccess, loadReportTemplatePresetFail,
  getReportTemplatePresetSuccess, getReportTemplatePresetFail,
} from "../../redux/modules/pipeline";
import { msgError, msgSuccess } from "../../redux/modules/message";
import {getCookie} from "../../lib/cookie";
import {injectImgtypeID2TaskJSON} from "../../lib/taskUtil";

function loadApi({ page, per_page, category, keyword }) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/listPipeline', { pagination:{page, per_page}, search: {category, keyword}},
  {withCredentials: true});
}

function getApi(id) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getPipeline', {id}, {
    withCredentials: true, // For transition cookie
  });
}

function addApi({id, name, desc, longitudinal, tasks, modalities, valid}) {
  return axios.post('/api/addPipeline', {id, name, desc, longitudinal, tasks, modalities, valid}, {
    withCredentials: true, // For transition cookie
  });
}

function updateApi({id, name, desc, longitudinal, tasks, modalities, valid}) {
  return axios.post('/api/updatePipeline', {id, name, desc, longitudinal, tasks, modalities, valid}, {
    withCredentials: true, // For transition cookie
  });
}

function delApi({keys, page, per_page, category, keyword}) {
  return axios.post('/api/deletePipeline', { ids : keys, pagination : {page, per_page}, search : {category, keyword}}, {
    withCredentials: true, // For transition cookie
  });
}

function checkNameApi({value:name, id}) {
  return axios.post('/api/check-PipelineName', {name, id}, {
    withCredentials: true, // For transition cookie
  })
}

function getPresetApi(id, index) {
  return axios.post('/api/getPipelinePreset', {id, index}, {
    withCredentials: true, // For transition cookie
  })
}

function loadPresetApi() {
  return axios.post('/api/loadPipelinePreset', {}, {
    withCredentials: true, // For transition cookie
  })
}

function duplicateApi({id, name, desc, longitudinal, tasks, modalities, valid}) {
  return axios.post('/api/duplicatePipeline', {id, name, desc, longitudinal, tasks, modalities, valid}, {
    withCredentials: true, // For transition cookie
  });
}

function loadReportTemplateApi({page, per_page, category, keyword}) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/listReportTemplate', { pagination:{page, per_page}, search: {category, keyword}},
  {withCredentials: true});
}

function checkReportTemplateNameApi({value:name, id}) {
  return axios.post('/api/check-ReportTemplateName', {name, id}, {
    withCredentials: true, // For transition cookie
  })
}

function addReportTemplateApi({id, name, desc, pages, usedBlobtypeIds, valid}) {
  return axios.post('/api/addReportTemplate', {id, name, desc, pages, used_blobtype_ids: usedBlobtypeIds, valid}, {
    withCredentials: true, // For transition cookie
  });
}

function getReportTemplateApi(id) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getReportTemplate', {id}, {
    withCredentials: true, // For transition cookie
  });
}

function updateReportTemplateApi({id, name, desc, pages, usedBlobtypeIds, valid}) {
  return axios.post('/api/updateReportTemplate', {id, name, desc, pages, used_blobtype_ids: usedBlobtypeIds, valid}, {
    withCredentials: true, // For transition cookie
  });
}

function delReportTemplateApi({keys, page, per_page, category, keyword}) {
  return axios.post('/api/deleteReportTemplate', { ids : keys, pagination : {page, per_page}, search : {category, keyword}}, {
    withCredentials: true, // For transition cookie
  });
}

function duplicateReportTemplateApi({id, name, desc, pages, usedBlobtypeIds}) {
  return axios.post('/api/duplicateReportTemplate', {id, name, desc, pages, used_blobtype_ids: usedBlobtypeIds}, {
    withCredentials: true, // For transition cookie
  });
}

function loadReportTemplatePresetApi() {
  return axios.post('/api/listReportTemplatePreset', {}, {withCredentials: true})
}

function getReportTemplatePresetApi(index) {
  return axios.post('/api/getReportTemplatePreset', {index}, {withCredentials: true})
}

function* loadSaga({payload}) {
  try {
    const { page, per_page, category, keyword } = payload
    const res = yield loadApi({ page, per_page, category, keyword });
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* loadMoreSaga({payload}) {
  try {
    const { page, per_page } = payload
    const res = yield loadApi({ page, per_page });
    yield put(loadMoreSuccess(res.data));
  } catch (error) {
    yield put(loadMoreFail());
    yield put(msgError(error))
  }
}


const getBlobTypes = (state) => state.blobTypes.list
function* addSaga({payload}) {
  try {
    const {tasks, modalities, valid, values} = payload
    const {id, name, desc, longitudinal} = values

    const blobtypes = yield select(getBlobTypes);
    injectImgtypeID2TaskJSON(tasks, blobtypes)
    
    const res = yield addApi({id, name, desc, longitudinal, tasks, modalities, valid})
    yield put(addSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
  } catch (error) {
    yield put(addFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  try {
    const { id } = payload
    const res = yield call(getApi, id)
    yield put(getSuccess(res.data))
  } catch (error) {
    yield put(getFail())
    yield put(msgError(error))
  }
}

function* updateSaga({payload}) {
  try {
    const {tasks, modalities, valid, values} = payload
    const {id, name, desc, longitudinal} = values

    const blobtypes = yield select(getBlobTypes)
    injectImgtypeID2TaskJSON(tasks, blobtypes)

    const res = yield updateApi({id, name, desc, longitudinal, tasks, modalities, valid})
    yield put(updateSuccess(res.data))
    const history = yield getContext('history')
    history.goBack()
    yield put(msgSuccess(`Pipeline '${name}' updated successfully!`))
  } catch (error) {
    yield put(updateFail())
    yield put(msgError(error))
  }
}

function* delSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword } = payload;
    const res = yield delApi({keys, page, per_page, category, keyword})
    yield put(delSuccess(res.data));
  } catch (error) {
    yield put(delFail());
    yield put(msgError(error))
  }
}

function* checkNameSaga({payload}) {
  try {
    const {id, value} = payload
    const res = yield checkNameApi({id, value})
    const checkedName = res.data
    yield put(checkNameSuccess(checkedName))
  } catch (error) {
    yield put(checkNameFail(error.response.data.msg[0]))
  }
}

function* getPresetSaga({payload}) {
  try {
    const { id, index } = payload;
    const res = yield call(getPresetApi, id, index);
    yield put(getPresetSuccess(res.data));  
  } catch (error) {
    yield put(getPresetFail());
    yield put(msgError(error))
  }
}

function* loadPresetSaga() {
  try {
    const res = yield call(loadPresetApi);
    yield put(loadPresetSuccess(res.data))
  } catch (error) {
    yield put(loadPresetFail())
    yield put(msgError(error))
  }
}

function* duplicateSaga({payload}) {
  try {
    const {tasks, modalities, valid, values} = payload
    const {id, name, desc, longitudinal} = values

    const blobtypes = yield select(getBlobTypes);
    injectImgtypeID2TaskJSON(tasks, blobtypes)

    const res = yield duplicateApi({id, name, desc, longitudinal, tasks, modalities, valid})
    yield put(duplicateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess(`Duplicate success (${res.data.name})`))
  } catch (error) {
    yield put(duplicateFail());
    yield put(msgError(error))
  }
}

function* loadReportTemplateSaga({payload}) {
  try {
    const { page, per_page, category, keyword } = payload
    const res = yield loadReportTemplateApi({ page, per_page, category, keyword });
    yield put(loadReportTemplatesSuccess(res.data));
  } catch (error) {
    yield put(loadReportTemplatesFail());
    yield put(msgError(error))
  }
}

function* checkReportTemplateNameSaga({payload}) {
  try {
    const {id, value} = payload
    const res = yield checkReportTemplateNameApi({id, value})
    const checkedName = res.data
    yield put(checkReportTemplateNameSuccess(checkedName))
  } catch (error) {
    yield put(checkReportTemplateNameFail(error.response.data.msg[0]))
  }
}

function* addReportTemplateSaga({payload}) {
  try {
    const {id, name, desc, pages, usedBlobtypeIds, valid} = payload

    const res = yield addReportTemplateApi({id, name, desc, pages, usedBlobtypeIds, valid})
    yield put(addReportTemplateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
  } catch (error) {
    yield put(addReportTemplateFail());
    yield put(msgError(error))
  }
}

function* getReportTemplateSaga({payload}) {
  try {
    const { id } = payload
    const res = yield getReportTemplateApi(id)
    yield put(getReportTemplateSuccess(res.data))
  } catch (error) {
    yield put(getReportTemplateFail())
    yield put(msgError(error))
  }
}

function* updateReportTemplateSaga({payload}) {
  try {
    const {id, name, desc, pages, usedBlobtypeIds, valid} = payload

    const res = yield updateReportTemplateApi({id, name, desc, pages, usedBlobtypeIds, valid})
    yield put(updateReportTemplateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess(`Report template '${name}' updated successfully!`))
  } catch (error) {
    yield put(updateReportTemplateFail());
    yield put(msgError(error))
  }
}

function* delReportTemplateSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword } = payload;
    const res = yield delReportTemplateApi({keys, page, per_page, category, keyword})
    yield put(delReportTemplatesSuccess(res.data));
  } catch (error) {
    yield put(delReportTemplatesFail());
    yield put(msgError(error))
  }
}

function* duplicateReportTemplateSaga({payload}) {
  try {
    const {id, name, desc, pages, usedBlobtypeIds} = payload
    const res = yield duplicateReportTemplateApi({id, name, desc, pages, usedBlobtypeIds})
    yield put(duplicateReportSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess(`Duplicate success (${res.data.name})`))
  } catch (error) {
    yield put(duplicateReportFail());
    yield put(msgError(error))
  }
}

function* loadReportTemplatePresetSaga() {
  try {
    const res = yield loadReportTemplatePresetApi();
    yield put(loadReportTemplatePresetSuccess(res.data));
  } catch (error) {
    yield put(loadReportTemplatePresetFail());
    yield put(msgError(error))
  }
}

function* getReportTemplatePresetSaga({payload}) {
  try {
    const {index} = payload
    const res = yield getReportTemplatePresetApi(index)
    yield put(getReportTemplatePresetSuccess(res.data))
  } catch (error) {
    yield put(getReportTemplatePresetFail());
    yield put(msgError(error))
  }
}

function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
  yield takeLeading(actions.LOAD_MORE, loadMoreSaga)
  yield takeLeading(actions.LOAD_PRESET, loadPresetSaga)
  yield takeLeading(actions.LOAD_REPORT_TEMPLATES, loadReportTemplateSaga)
  yield takeLeading(actions.LOAD_REPORT_TEMPLATE_PRESET, loadReportTemplatePresetSaga)
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga);
  yield takeLeading(actions.ADD_REPORT_TEMPLATE, addReportTemplateSaga)
}

function* watchGet() {
  yield takeLeading(actions.GET, getSaga);
  yield takeLeading(actions.GET_PRESET, getPresetSaga);
  yield takeLeading(actions.GET_REPORT_TEMPLATE, getReportTemplateSaga)
  yield takeLeading(actions.GET_REPORT_TEMPLATE_PRESET, getReportTemplatePresetSaga)
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
  yield takeLeading(actions.UPDATE_REPORT_TEMPLATE, updateReportTemplateSaga)
}

function* watchDel() {
  yield takeLeading(actions.DEL, delSaga);
  yield takeLeading(actions.DEL_REPORT_TEMPLATES, delReportTemplateSaga)
}

function* watchCheck() {
  yield debounce(500, actions.CHECK_NAME, checkNameSaga)
  yield debounce(500, actions.CHECK_REPORT_TEMPLATE_NAME, checkReportTemplateNameSaga)
}

function* watchDuplicate() {
  yield takeLeading(actions.DUPLICATE, duplicateSaga)
  yield takeLeading(actions.DUPLICATE_REPORT_TEMPLATE, duplicateReportTemplateSaga)
}

export default function* pipelineSaga() {
  yield all([
    fork(watchLoad),
    fork(watchAdd),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
    fork(watchCheck),
    fork(watchDuplicate)
  ]);
}
