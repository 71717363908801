import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useGetImageTypeHooks } from "../../hooks/useImageTypeHooks"
import { TagBase } from "../tagBase"
import { Button, Dropdown, Modal, Space, Table } from "antd"
import { clearReportTemplates as clear, clearReportTemplatePreset, delReportTemplates as del, getReportTemplate, loadReportTemplates as load, loadReportTemplatePreset, updateReportTemplateModalOpen } from "../../redux/modules/pipeline"
import SearchBox from "../searchBox"
import EllipsisTooltipForImagetypes from "../ellipsisTooltipForImagetypes"
import useListPageContext from "../../context/listPageContext"
import { useState } from "react"

const searchOptions = [
  {value:"name", label:"Name", dataIndex : 'name'},
  {value:"desc", label:"Description", dataIndex : 'desc'},
]

const ReportTemplateModal = ({hideAction=false}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => state.pipeline?.reportTemplate?.loading)
  const list = useSelector(state => state.pipeline?.reportTemplate?.list) || []
  const total = useSelector(state => state.pipeline?.reportTemplate?.total)
  const imageTypeList = useSelector(state => state.blobTypes?.list);
  const {getTypeFromTypeId} = useGetImageTypeHooks(imageTypeList)
  const openReportTemplateModal = useSelector(state => state.pipeline?.reportTemplate?.open)

  const {
    rowSelection: checkBoxRowSelection, 
    handleSearch, 
    RemoveBtn,
    searchHighlight,
    Footer
 } = useListPageContext({load, del, clear, searchOptions, total, isModal: true});

 const dataSource = list.map(tpl => {
  const {id, name, desc, page_count, used_blobtype_ids} = tpl
  return ({id, name, desc, page_count, used_blobtype_ids, key: id})
})

 const [selectedRowKeys, setSelectedRowKeys] = useState([dataSource?.[0]?.key])

  const onEdit = record => history.push('/pipeline/manage_report', {key: record.id})
  
  const columns = [
    {
      title: 'name',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      render: name => searchHighlight(name, 'name')
    },
    {
      title: 'desc',
      align: 'center',
      dataIndex: 'desc',
      key: 'desc',
      render: desc => searchHighlight(desc, 'desc')
    },
    {
      title: 'page count',
      align: 'center',
      dataIndex: 'page_count',
      key: 'page_count',
    },
    {
      title: 'image type',
      dataIndex: 'used_blobtype_ids',
      align: 'center',
      width: '270px',
      key: 'used_blobtype_ids',
      render: used_blobtype_ids => {
        const sortedBlobTypeIds = used_blobtype_ids?.sort((a, b) => a - b)
        const contents = (
          <div>
            {sortedBlobTypeIds?.map(id => {
              const type = getTypeFromTypeId(id)
              const short = type?.short
              return type ? <TagBase color={type.tag_color} children={short} style={{margin: '4px'}}/> : null
              })
            }
          </div>)
        return (
          <div style={{display: 'flex', textAlign: 'left'}}>
            <EllipsisTooltipForImagetypes
              title={contents}
              placement="right"
              color={'#0C2329'}
            >
              {contents}
            </EllipsisTooltipForImagetypes>
          </div>
        )
      }
    },
    {
      title: 'Actions',
      key: 'action',
      width: 160,
      fixed : 'right',
      align : 'center',
      hide: hideAction,
      render: record => (
        <Button onClick={() => onEdit(record)}>Edit</Button>
      )
    }
  ].filter(col => !col.hide)
  
  const onCloseModal = () => dispatch(updateReportTemplateModalOpen(false))
  const handleCreate = () => history.push('/pipeline/manage_report', {key: -1})
  
  const onLoad = () => {
    const selectId = selectedRowKeys[0]
    dispatch(getReportTemplate({id: selectId}))
  }

  const footer = hideAction ? 
    <Button type='primary' onClick={onLoad}>Load</Button> : 
    null

  const radioRowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  const rowSelection = hideAction ? 
    radioRowSelection :
    checkBoxRowSelection

  const onRow = (record, rowIndex) => {
    return {
      onClick: e => {
        setSelectedRowKeys([record.key])
      }
    }
  }

  const items = [
    {
      label: 'from preset',
      key: 'create_from_preset',
    }
  ]

  const menuProps = {
    items,
    onClick: ({ item, key, keyPath, domEvent }) => {
      if (key === 'create_from_preset') {
        return dispatch(loadReportTemplatePreset())
      }
    }
  }

  return (
    <Modal
      title={'Report templates'}
      open={openReportTemplateModal}
      onCancel={onCloseModal}
      width={hideAction ? 1000 : 1200}
      footer={footer}
    >
      <div>
        <div style={{marginBottom: 8}}>
          <div style={{display: 'inline-block'}}>
            {!hideAction && 
              <Space>
                <Dropdown.Button
                  menu={menuProps}
                  onClick={handleCreate}
                >
                  Create
                </Dropdown.Button>
                <RemoveBtn />
              </Space>
            }
          </div>
          <SearchBox
            style={{display: "inline-block", float: 'right', width:"50%"}}
            options={searchOptions}
            onSearch={handleSearch}
          />
        </div>
        <Table 
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowSelection={rowSelection}
          pagination={false}
          footer={Footer}
          onRow={hideAction ? onRow : null}
        />
      </div>
      <ReportTemplatePresetModal />
    </Modal>
  )
}

export default ReportTemplateModal

const ReportTemplatePresetModal = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const reportTemplatePresetList = useSelector(state => state.pipeline?.reportTemplate?.preset?.list  || [])
  const imageTypeList = useSelector(state => state.blobTypes?.list);
  const {getTypeFromTypeId} = useGetImageTypeHooks(imageTypeList)
  const [selectedRowKeys, setSelectedRowKeys] = useState([0])

  const columns = [
    {
      title: 'name',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'desc',
      align: 'center',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'page count',
      align: 'center',
      dataIndex: 'page_count',
      key: 'page_count',
    },
    {
      title: 'image type',
      dataIndex: 'used_blobtype_ids',
      align: 'center',
      width: '270px',
      key: 'used_blobtype_ids',
      render: used_blobtype_ids => {
        const sortedBlobTypeIds = used_blobtype_ids?.sort((a, b) => a - b)
        const contents = (
          <div>
            {sortedBlobTypeIds?.map(id => {
              const type = getTypeFromTypeId(id)
              const short = type?.short
              return type ? <TagBase color={type.tag_color} children={short} style={{margin: '4px'}}/> : null
              })
            }
          </div>)
        return (
          <div style={{display: 'flex', textAlign: 'left'}}>
            <EllipsisTooltipForImagetypes
              title={contents}
              placement="right"
              color={'#0C2329'}
            >
              {contents}
            </EllipsisTooltipForImagetypes>
          </div>
        )
      }
    },
  ]

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  const onRow = (record, rowIndex) => {
    return {
      onClick: e => {
        setSelectedRowKeys([record.key])
      }
    }
  }

  const onCloseModal = () => dispatch(clearReportTemplatePreset())
  const handleCreate = () => history.push('/pipeline/manage_report', {key: -1, presetIndex: selectedRowKeys[0]})

  return (
    <Modal
      title={'Report template presets'}
      open={reportTemplatePresetList?.length > 0}
      width={1000}
      onCancel={onCloseModal}
      onOk={handleCreate}
    >
      <Table
        dataSource={reportTemplatePresetList.map((d,i) => ({...d, key: i}))}
        columns={columns}
        rowSelection={rowSelection}
        onRow={onRow}
        pagination={false}
      />
    </Modal>
  )
}