import React, {useEffect, useReducer, useContext} from "react";
import { Button, Space, Table, Popconfirm} from 'antd';
import {
  ClockCircleOutlined,
  SyncOutlined, CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleOutlined
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import { load, del, clear } from "../redux/modules/analysis"
import {useHistory} from "react-router-dom";
import {useTitle} from "../hooks/title";
import useListPageContext from "../context/listPageContext";
import SearchBox from "./searchBox";
import { created_column } from "./createdRender";
import { SocketContext } from "../hooks/socket";
import { EVT_ANALYSIS } from "../constants";

const dataInitialState = {
  list: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      const payload = action.payload
      const analysisId = payload.analysis_id
      if (analysisId) {
        const newData = state.list.map(item => {
          if (item.id === analysisId) {
            item.state = payload.state
          }
          return item
        })
        return { list: newData };
      }
      return state
    case "update_all":
      return { list: action.payload };
    default:
      throw new Error("Unsupported action type:", action.type);
  }
}


const searchOptions = [
  {value:"dataset", label:"Dataset name", dataIndex : 'dataset_name'},
  {value:"pipeline", label:"Pipeline name", dataIndex : 'pipeline_name'},
]

function AnalysisList() {
  useTitle('DYNAPEX | Results')
  const {addHandler, removeHandler} = useContext(SocketContext)
  const history = useHistory();
  const loading = useSelector(state => state.analysis.loading)
  const list = useSelector(state => state.analysis?.list)
  const total = useSelector(state => state.analysis?.total)
  const user = useSelector(state => state.auth.user);
  const [analysis, dispatchAnalysis] = useReducer(reducer, dataInitialState);
  const analysisSocketCB = payload => {
    dispatchAnalysis({type: 'update', payload})
  }
  useEffect(()=>{
    addHandler(EVT_ANALYSIS, analysisSocketCB)
    return () => removeHandler(EVT_ANALYSIS, analysisSocketCB)
  },[user])

  const {
    rowSelection,
    handleSearch,
    fetchDelete,
    searchHighlight,
    Footer,
    LoadStateButtonGroup
  } = useListPageContext({load, del, clear, searchOptions, total});

  useEffect(() => {
    if (list !== undefined) {
      const newData = Object.entries(list).map(([key, value], index) => {
        return {
          key: value.Analysis.id,
          id: value.Analysis.id,
          dataset_name: value.Dataset.name,
          case_count : value.Dataset.case_count,
          pipeline_name: value.Pipeline.name,
          longitudinal: value.Pipeline.longitudinal,
          task_count : value.Pipeline.task_count,
          state: value.Analysis.state,
          created: value.Analysis.created,
        };
      });
      dispatchAnalysis({type: 'update_all', payload: newData})
    }
  }, [list])


  const handleView = record => history.push('/analysis/detail', {key: record.id})

  const columns = [
    {
      title: 'Pipeline name',
      dataIndex: 'pipeline_name',
      key: 'pipeline_name',
      ellipsis: {
        showTitle: false,
      },
      render : text => searchHighlight(text, 'pipeline_name')
    },
    {
      title : 'Task count',
      dataIndex : 'task_count',
      key : 'task_count',
      align : 'center',
      width : 140
    },
    {
      title: 'Longitudinal',
      dataIndex: 'longitudinal',
      key: 'longitudinal',
      align : 'center',
      width: 140,
      render: val => <>{val ? 'O' : 'X'}</>,
    },
    {
      width: 100
    },
    {
      title: 'Dataset name',
      dataIndex: 'dataset_name',
      key: 'dataset_name',
      ellipsis: {
        showTitle: false,
      },
      render : text => searchHighlight(text, 'dataset_name')
    },
    {
      title : 'Case count',
      dataIndex : 'case_count',
      key : 'case_count',
      align : 'center',
      width : 120
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 130,
      align : 'center',
      render: record => {
        return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
          : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
          : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
          : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
      }
    },
    created_column,
    {
      title: 'Actions',
      key: 'action',
      width: 130,
      fixed : 'right',
      align : 'center',
      render: (record) => (
        <Space>
          <Button onClick={() => handleView(record)}> View </Button>
        </Space>
      ),
    },
  ];

  const handleDelete = () => fetchDelete()
  const handleCreate = () => history.push('/analysis/create')

  return (
    <div
      style={{
        width: '100%',
        // height: '100%',
        // margin: 'auto',
        // verticalAlign: 'middle'
      }}
    >
      <h1>Analysis Result</h1>
      <div style={{display: "inline-block"}}>
        <Space>
          <Button onClick={handleCreate} type={'primary'}>Run</Button>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleDelete}
          >
            <Button>Remove</Button>
          </Popconfirm>
          <LoadStateButtonGroup />
        </Space>
      </div>
      <SearchBox
        style={{display: "inline-block", float: 'right', width:"50%"}}
        options={searchOptions}
        onSearch={handleSearch}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={analysis.list}
        rowSelection={rowSelection}
        footer={Footer}
        pagination={false}
      />
    </div>
  );
}

export default AnalysisList;

