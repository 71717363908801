import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import reducer from './redux'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'
import rootSaga from './sagas'
import { createMiddleware } from 'redux-api-middleware';
import { createBrowserHistory } from 'history';
import initCornerstone from "./lib/initCornerstone";
import applyAppTokenRefreshInterceptor from './lib/axios.refresh_token';
import axios from 'axios';
import {COLLECT_DATA, COLLECT_SEG, GENERATE} from './redux/modules/scatter'
// redux-devtools-extension --> node v18 (N/A in ubuntu 18.04)
// import { composeWithDevTools } from '@redux-devtools/extension/developmentOnly';


const customHistory = createBrowserHistory();

// redux 설정
const actionsBlacklist = [COLLECT_DATA, COLLECT_SEG]
const actionSanitizer = (action) => {
  return (action.type === COLLECT_DATA || action.type === COLLECT_SEG || action.type === GENERATE) && action.payload
    ? {...action, payload: '<<LONG_BLOB>>'}
    : action;
}
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  process.env.NODE_ENV === "development" && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      actionSanitizer,
      stateSanitizer: (state) =>
        state.payload ? { ...state, payload: '<<LONG_BLOB>>' } : state,
      actionsBlacklist,
    })
    : compose;
// const composeEnhancers = composeWithDevTools({
//   actionSanitizer,
//   stateSanitizer: (state) =>
//     state.payload ? { ...state, payload: '<<LONG_BLOB>>' } : state,
//   actionsBlacklist,
// })

// create middlewares
const sagaMiddleware = createSagaMiddleware({context: {history: customHistory}});
const apiMiddleware = createMiddleware();
const middlewares = [apiMiddleware, sagaMiddleware];


const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
if(process.env.NODE_ENV !== 'production') {
  window.store = store;
  customHistory.listen((location, action) => {
    console.debug(action, location);
  }) 
}
applyAppTokenRefreshInterceptor(axios, {store, history: customHistory});

sagaMiddleware.run(rootSaga);
initCornerstone();

ReactDOM.render(
  <Router history={customHistory}>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
