import React, {useEffect, useState} from "react";
import {Button, Col, InputNumber, Popconfirm, Row, Select} from "antd";
import TaskInputSelect, {MODE} from "./taskInputSelect";
import {MinusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {LabelSelect} from "./labelControls";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import {TASK_DELIMITER} from "../lib/taskUtil";

const {Option} = Select;

const ExpressionOfCondition = ({value, inputs, disabled, onChange, inReport, imageTypeList}) => {
  const {getTypeFromShort} = useGetImageTypeHooks(imageTypeList)
  const [expressions, setExpressions] = useState(value);

  useEffect(() => {
    setExpressions(value);
  }, [value])

  const onClickAdd = () => {
    const newExpress = !expressions || expressions.length === 0
      ? [{op: "=", value: 0}] : [...expressions, {lop: "or", op: "=", value: 0}]

    setExpressions(newExpress);
    onChange(newExpress)
  }
  const onClickRemove = (exprIndex) => (e) => {
    const newExpress = [...expressions];
    newExpress.splice(exprIndex, 1);

    setExpressions(newExpress);
    onChange(newExpress)
  }
  const onLogicalOperatorChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].lop = value

    setExpressions(newExpress);
    onChange(newExpress)
  }
  const onInputChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    if (value.length === 0) {
      // 선택된 것이 없을 때
      newExpress[exprIndex].target = undefined
    }
    else {
      // 선택된 것이 있을 때
      newExpress[exprIndex].target = value[0]

      const short = value[0].includes(TASK_DELIMITER) ? value[0].split(TASK_DELIMITER)[0] : value[0]
      const imgtype = getTypeFromShort(short)
      if (imgtype?.seg && newExpress[exprIndex].value === 0) {
        newExpress[exprIndex].value = undefined
      }
    }

    setExpressions(newExpress);
    onChange(newExpress)
  }
  const onOperatorChange = (exprIndex) => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].op = value

    setExpressions(newExpress);
    onChange(newExpress)
  }
  const onValueChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].value = value

    setExpressions(newExpress);
    onChange(newExpress)
  }

  return (
    <>
      {!expressions || expressions.length === 0 ?
        <Row gutter={[8, 4]}>
          <Col span={4}>
            <Button block type="primary" onClick={onClickAdd} disabled={disabled}> + </Button>
          </Col>
        </Row>
        :
        // strExpression.slice(1).map((item, index) => {
        expressions.map((item, expr_index) => {
          const lop = item.lop === undefined ? "and" : item.lop;
          const target = item.target;
          const short = target?.includes(TASK_DELIMITER) ? target.split(TASK_DELIMITER)[0] : target
          const imgtype = getTypeFromShort(short)
          const seg = imgtype?.seg
          const op = item.op === undefined ? "=" : item.op;
          const value = item.value
          const firstColumn = expr_index === 0 ?
            <Button block type="primary" onClick={onClickAdd} disabled={disabled}> + </Button>
            :
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              onChange={onLogicalOperatorChange(expr_index)}
              defaultValue={lop}
              value={lop}
              disabled={disabled}
            >
              <Option key={1} value="and">{"and"}</Option>
              <Option key={2} value="or">{"or"}</Option>
            </Select>;

          return <Row key={expr_index} gutter={[8, 4]} style={{marginBottom: 5}} align="middle">
            <Col span={4}>
              {firstColumn}
            </Col>
            <Col span={8}>
              <TaskInputSelect
                onChange={onInputChange(expr_index)}
                dropdownMatchSelectWidth={false}
                placeholder="Select map data"
                taskInput={inputs?.[0]}
                value={target}
                mode={MODE.SINGLE}
                disabled={disabled}
                inReport={inReport}
                imageTypeList={imageTypeList}
              />
            </Col>
            <Col span={3}>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onOperatorChange(expr_index)}
                defaultValue={op}
                value={op}
                disabled={disabled}
              >
                <Option key={1} value=">">{">"}</Option>
                <Option key={2} value=">=">{">="}</Option>
                <Option key={3} value="=">{"="}</Option>
                <Option key={4} value="<=">{"<="}</Option>
                <Option key={5} value="<">{"<"}</Option>
              </Select>
            </Col>
            <Col span={8}>
              {seg ?
                <LabelSelect
                  options={imgtype.seg_cmap}
                  defaultValue={value === 0 ? null : value}
                  value={value}
                  onChange={onValueChange(expr_index)}
                  disabled={disabled}
                  inReport={inReport}
                />
                :
                <InputNumber
                  style={{width: '100%', border: '1px solid #205656'}}
                  disabled={disabled}
                  value={value}
                  onChange={onValueChange(expr_index)}
                />
              }
            </Col>
            <Col span={1}>
              <Popconfirm
                title={<div style={{width: 100}}>Are you sure?</div>}
                getPopupContainer={trigger => trigger.parentNode}
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={onClickRemove(expr_index)}
              >
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  hidden={disabled}
                />
              </Popconfirm>
            </Col>
          </Row>
        })
      }
    </>
  );
}

export default ExpressionOfCondition;