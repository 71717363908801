import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upload } from "../redux/modules/longTask";
import * as stringSim from "string-similarity";
import { msgErrorAndCb } from "../redux/modules/message";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import '../components/blink.css'

const useUploadContext = ({resolveds, unresolveds, dispatchDicomDrop, datasetCardRef, resolvedsCardRef, unresolvedsCardRef}) => {
  const dispatch = useDispatch()
  const datasetIdRef = useRef(-1)
  const imageTypes = useSelector(state => state.blobTypes.types)
  const {getTypeIdFromShort} = useGetImageTypeHooks()

  const expandUndefinedTypeSeries = (patients) => {
    const expandedResoloveds = patients.map(patient => ({
      ...patient,
      expanded : patient.studies.some(study => 
        study.series.some(series => !series.type) || study.blobs.some(blob => !blob.type)),
      studies : patient.studies.map(study => ({
        ...study,
        expanded : study.series.some(series => !series.type) || study.blobs.some(blob => !blob.type)
      })),
    }))
    dispatchDicomDrop({type: 'REPLACE_ONLY', payload: {resolveds: expandedResoloveds}})
  }

  const startUpload = (params= {uploadData : resolveds}) => {
    const {uploadData} = params
    const BLINK_TIME = 2000
    // data sanity check
    if (datasetIdRef.current < 0) {
      dispatch(msgErrorAndCb({
        content : "Error: Dataset must be selected", 
        cb : () => setTimeout(() => datasetCardRef.current.classList.remove("blink"), BLINK_TIME)
      }))
      datasetCardRef.current.classList.add("blink");
      
      const selectElement = datasetCardRef.current.querySelector('.ant-select')
      selectElement?.classList.add("blink");
      setTimeout(() => selectElement?.classList.remove("blink"), BLINK_TIME)
      
      datasetCardRef.current.scrollIntoView({block: 'center', behavior: 'smooth'})
      
      return;
    }

    if (unresolveds.length) {
      dispatch(msgErrorAndCb({
        content : "Error: Image type or case-binding is missing in some files",
        cb : () => setTimeout(() => unresolvedsCardRef.current?.classList.remove("blink"), BLINK_TIME)
      }))
      unresolvedsCardRef.current?.classList.add("blink");

      const blobKeys = unresolveds.map(blob => blob.key)
      blobKeys.forEach((key, bi) => {
        const undefinedBind = document.querySelector(`[data-row-key="${key}"]`)
        const bindElement = undefinedBind.querySelector('[data-col="bind"]')
        bindElement?.classList.add('blink')
        setTimeout(() => bindElement?.classList.remove('blink'), BLINK_TIME)
        if (bi === 0) {
          bindElement
          ? bindElement.scrollIntoView({block: 'center', behavior: 'smooth'})
          : unresolvedsCardRef.current?.scrollIntoView({block: 'center', behavior: 'smooth'})
        }
      })

      return;
    }

    const counts = uploadData
      .map((patient) =>
        patient.studies.map((s) => s.series.length + s.blobs.length)
      )
      .flat(Infinity);
    if (counts.reduce((a, b) => a + b, 0) === 0) {
      dispatch(msgErrorAndCb({
        content : "Error: Nothing to upload", 
        cb : () => setTimeout(() => resolvedsCardRef.current.classList.remove("blink"), BLINK_TIME)
      }))
      resolvedsCardRef.current.classList.add("blink");
      resolvedsCardRef.current.scrollIntoView({block: 'center', behavior: 'smooth'});
      return;
    }
    
    expandUndefinedTypeSeries(uploadData)
    const undefinedTypes = uploadData
      .map(patient =>
        patient.studies.map((s) => {
          let seriesTypes = s.series.filter(series => !series.disabled && !series.type).map(series => {return {key : series.key, type : series.type}})
          let blobTypes = s.blobs.filter(blob => !blob.disabled && !blob.type).map(blob => {return {key : blob.key, type : blob.type}})
          return seriesTypes.concat(blobTypes)
        })).flat(Infinity);
    if (undefinedTypes.some((item) => item.type === undefined)) {
      dispatch(msgErrorAndCb({
        content : `Error: Image types are not defined in ${undefinedTypes.length} cases`, 
        cb : () => setTimeout(() => resolvedsCardRef.current?.classList.remove("blink"), BLINK_TIME)
      }))
      resolvedsCardRef.current.classList.add("blink");

      undefinedTypes.forEach((item, index) => {
        const nonTypeElement = resolvedsCardRef?.current.querySelector(`[data-row-key="${item.key}"]`)
        const selectElement = nonTypeElement?.querySelector('[data-col="type"]')
        selectElement?.classList.add('blink')
        setTimeout(() => selectElement?.classList.remove('blink'), BLINK_TIME)
        if (index === 0) {
          selectElement 
          ? selectElement.scrollIntoView({block: 'center', behavior: 'smooth'})
          : resolvedsCardRef.current.scrollIntoView({block: 'center', behavior: 'smooth'})
        }
      })
      
      return;
    }
    dispatchDicomDrop({type : 'INIT_THEN_UPDATE', payload : {}})
    dispatch(upload({
      dataset_id: datasetIdRef.current,
      patients: uploadData
    }))
  };

  return {datasetIdRef, startUpload}
}

export default useUploadContext