import { Tooltip, Typography } from "antd"

const {Text} = Typography

function CreatedRender({date, full=false}) {
  const localDate = isoToLocalDate(date)
  const splitDate = dateFormat(localDate)?.split(' ') || []
  const todayDate = dateFormat(new Date()).split(' ')[0]

  const fullDate = splitDate.join(' ') 
  const onlyDate = splitDate[0]
  const onlyTime = splitDate[1] 

  const text = full 
    ? fullDate
    : onlyDate === todayDate 
      ? onlyTime
      : onlyDate

  return (
    <Tooltip title={splitDate.join(' ')} open={full ? false : undefined}>
      <Text style={{color : 'gray', fontSize : '90%'}}>{text}</Text>
    </Tooltip>
  )
}

export default CreatedRender


export const created_column = (
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    align : 'center',
    width : 110,
    render : date => <CreatedRender date={date}/>
  }
)


function isoToLocalDate(date) {
  const now = new Date()
  const diff = (- now.getTimezoneOffset() * 60 * 1000 )

  const localTimeStamp = new Date(date).getTime() + diff
  const localDate = new Date(localTimeStamp)
  return localDate
}


function dateFormat(date) {
  if (date) {
    let year = date.getFullYear()
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
  
    month = month >= 10 ? month : '0' + month;
    day = day >= 10 ? day : '0' + day;
    hour = hour >= 10 ? hour : '0' + hour;
    minute = minute >= 10 ? minute : '0' + minute;
    second = second >= 10 ? second : '0' + second;
  
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
  }
  else {
    return ;
  }
  
}