import cornerstoneTools from 'cornerstone-tools';
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import {convertToVector3} from "../cornerstoneUtils";

const BaseTool = cornerstoneTools.importInternal('base/BaseTool')
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext')
const drawTextBox = cornerstoneTools.importInternal('drawing/drawTextBox')
const textBoxWidth = cornerstoneTools.importInternal('drawing/textBoxWidth')
// const OrientationMarkersTool = cornerstoneTools.importInternal('OrientationMarkersTool')

export default class myOrientationTool extends BaseTool {
// export default class myOrientationTool extends cornerstoneTools.OrientationMarkersTool {

  // constructor(props = {}) {
  //   const defaultProps = {
  //     name: 'OrientationMarkers',
  //     configuration: {
  //       drawAllMarkers: true,
  //     },
  //     mixins: ['enabledOrDisabledBinaryTool'],
  //   };
  //
  //   super(props, defaultProps);
  // }

  constructor(props = {}) {
    const defaultProps = {
      name: 'myOrientation',
      configuration: {},
      mixins: [
        'enabledOrDisabledBinaryTool', // Mixin from cornerstoneTools source.
        // 'evenMoreHelloWorld', // Mixin from the plugin.
      ],
    }
    super(props, defaultProps);

    // Use a module from the plugin. It should be first accessed in constructor.
    this._helloWorldModule = cornerstoneTools.getModule('helloWorld');
  }


  // // activeCallback(element) {
  // enabledCallback(element) {
  //   console.log(`Hello element ${element.uuid}!`);
  //   // BaseTool.prototype.activeCallback(element);
  // }
  // disabledCallback(element) {
  //   console.log(`Goodbye element ${element.uuid}!`);
  //   // BaseTool.prototype.disabledCallback(element);
  // }

  enabledCallback(element) {
    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = cornerstone.getEnabledElement(element);
    if (enabledElement.image) {
      cornerstone.updateImage(element);
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const context = getNewContext(eventData.canvasContext.canvas);
    const element = eventData.element;
    const markers = this.getOrientationMarkers(element);

    if (!markers) {
      return;
    }
    const coords = this.getOrientationMarkerPositions(element, markers);
    // const color = toolColors.getToolColor();
    const color = 'red'

    const textWidths = {
      top: textBoxWidth(context, markers.top, 0),
      left: textBoxWidth(context, markers.left, 0),
      right: textBoxWidth(context, markers.right, 0),
      bottom: textBoxWidth(context, markers.bottom, 0),
      height: textBoxWidth(context, 'M', 0), // Trick to get an approximation of the height of the text
    };

    this.drawTopLeftText(context, markers, coords, textWidths, color);
    if (this.configuration.drawAllMarkers) {
      this.drawBottomRightText(context, markers, coords, textWidths, color);
    }
  }

  drawTopLeftText(context, markers, coords, textWidths, color) {
    drawTextBox(
      context,
      markers.top,
      coords.top.x - textWidths.top / 2,
      coords.top.y,
      color
    );
    drawTextBox(
      context,
      markers.left,
      coords.left.x - textWidths.left / 2,
      coords.left.y,
      color
    );
  };

  drawBottomRightText(context, markers, coords, textWidths, color) {
    drawTextBox(
      context,
      markers.right,
      coords.right.x - textWidths.right,
      coords.right.y,
      color
    );
    drawTextBox(
      context,
      markers.bottom,
      coords.bottom.x - textWidths.bottom / 2,
      coords.bottom.y - textWidths.height,
      color
    );
  };

  getOrientationMarkerPositions(element) {
    const enabledElement = cornerstone.getEnabledElement(element);
    // const top = cornerstone.pixelToCanvas(element, {
    //   x: enabledElement.image.width / 2,
    //   y: 5,
    // });
    // const bottom = cornerstone.pixelToCanvas(element, {
    //   x: enabledElement.image.width / 2,
    //   y: enabledElement.image.height - 15,
    // });
    // const left = cornerstone.pixelToCanvas(element, {
    //   x: 5,
    //   y: enabledElement.image.height / 2,
    // });
    // const right = cornerstone.pixelToCanvas(element, {
    //   x: enabledElement.image.width - 10,
    //   y: enabledElement.image.height / 2,
    // });

    const xHalf = enabledElement.element.clientWidth / 2
    const yHalf = enabledElement.element.clientHeight / 2
    const top = {x: xHalf, y: 5};
    const bottom = {x: xHalf, y: enabledElement.element.clientHeight - 15};
    const margin = 10
    const left = {x: margin, y: yHalf};
    const scrollBarSize = 17
    const right = {x: enabledElement.element.clientWidth - margin - scrollBarSize, y: yHalf};

    return {
      top,
      bottom,
      left,
      right,
    };
  }

  getOrientationString(vector) {
    const vec3 = convertToVector3(vector);

    // Thanks to David Clunie
    // https://sites.google.com/site/dicomnotes/

    let orientation = '';
    const orientationX = vec3.x < 0 ? 'R' : 'L';
    const orientationY = vec3.y < 0 ? 'A' : 'P';
    // const orientationZ = vec3.z < 0 ? 'F' : 'H';
    const orientationZ = vec3.z < 0 ? 'I' : 'S';

    // Should probably make this a function vector3.abs
    const abs = new cornerstoneMath.Vector3(
      Math.abs(vec3.x),
      Math.abs(vec3.y),
      Math.abs(vec3.z)
    );

    const MIN = 0.5; // direction cosine 0.7071 이상이면 45도니까 0.7까지 해도 되지만..

    for (let i = 0; i < 3; i++) {
      if (abs.x > MIN && abs.x > abs.y && abs.x > abs.z) {
        orientation += orientationX;
        abs.x = 0;
      } else if (abs.y > MIN && abs.y > abs.x && abs.y > abs.z) {
        orientation += orientationY;
        abs.y = 0;
      } else if (abs.z > MIN && abs.z > abs.x && abs.z > abs.y) {
        orientation += orientationZ;
        abs.z = 0;
      } else if (abs.x > MIN && abs.y > MIN && abs.x === abs.y) {
        orientation += orientationX + orientationY;
        abs.x = 0;
        abs.y = 0;
      } else if (abs.x > MIN && abs.z > MIN && abs.x === abs.z) {
        orientation += orientationX + orientationZ;
        abs.x = 0;
        abs.z = 0;
      } else if (abs.y > MIN && abs.z > MIN && abs.y === abs.z) {
        orientation += orientationY + orientationZ;
        abs.y = 0;
        abs.z = 0;
      } else {
        break;
      }
    }

    return orientation;
  }

  invertOrientationString(orientationString) {
    let inverted = orientationString.replace('S', 'i');
    inverted = inverted.replace('I', 's');
    inverted = inverted.replace('R', 'l');
    inverted = inverted.replace('L', 'r');
    inverted = inverted.replace('A', 'p');
    inverted = inverted.replace('P', 'a');
    inverted = inverted.toUpperCase();

    return inverted;
  }

  getOrientationMarkers(element) {
    const enabledElement = cornerstone.getEnabledElement(element);
    const image = enabledElement.image
    const report = image.color && (image.rows > 1024 || image.columns > 1024)
    const imagePlane = cornerstone.metaData.get(
      'imagePlaneModule',
      enabledElement.image.imageId
    );

    if (report || !imagePlane || !imagePlane.rowCosines || !imagePlane.columnCosines) {
      return;
    }

    const row = this.getOrientationString(imagePlane.rowCosines);
    const column = this.getOrientationString(imagePlane.columnCosines);
    const oppositeRow = this.invertOrientationString(row);
    const oppositeColumn = this.invertOrientationString(column);

    return {
      top: oppositeColumn,
      bottom: column,
      left: oppositeRow,
      right: row,
    };
  }
}
