import {all, call, fork, put, takeLeading, debounce, getContext} from "redux-saga/effects";
import * as actions from "../../redux/modules/blobTypes";
import axios from "axios";
import {loadSuccess, loadFail, addSuccess, addFail, getSuccess, getFail,
  updateSuccess, updateFail, delSuccess, delFail, testSuccess, testFail,
  checkNameSuccess, checkNameFail, checkShorthandSuccess, checkShorthandFail,
  delAsk, editHintsSuccess, editHintsFail,
  getDefaultSuccess, getDefaultFail
} from "../../redux/modules/blobTypes";
  import {getCookie} from "../../lib/cookie";
import { msgError, msgSuccess } from "../../redux/modules/message";

function loadApi({ page, per_page, category, keyword }) {
  axios.defaults.headers.post['X-CSRF-Token'] = getCookie('csrf_access_token')
  return axios.post('/api/loadImageTypes', {search: {category, keyword}, pagination:{page, per_page}},
  {withCredentials: true});
}

function addApi(payload) {
  return axios.post('/api/addImageType', payload, {
    withCredentials: true, // For transition cookie
  });
}

function getApi(payload) {
  return axios.post('/api/getImageType', payload, {
    withCredentials: true, // For transition cookie
  });
}

function updateApi(payload) {
  return axios.post('/api/updateImageType', payload, {
    withCredentials: true, // For transition cookie
  });
}

function delApi({keys, page, per_page, category, keyword, approve}) {
  return axios.post('/api/delImageType', { ids : keys, pagination : {page, per_page}, search : {category, keyword}, approve});
}

function testApi(payload) {
  return axios.post('/api/testImageType', payload, {
    withCredentials: true, // For transition cookie
  });
}

function checkNameApi({value:name,id}) {
  return axios.post('/api/check-ImageTypeName', {name, id}, {
    withCredentials: true, // For transition cookie
  })
}

function checkShortApi({value:short,id}) {
  return axios.post('/api/check-ImageTypeShort', {short,id}, {
    withCredentials: true, // For transition cookie
  })
}

function editHints({types}) {
  return axios.post('/api/editHints', {types}, {
    withCredentials: true, // For transition cookie
  })
}

function getDefault(id) {
  return axios.post('/api/getImageTypeDefault', {id}, {
    withCredentials: true, // For transition cookie
  })
}

function* loadSaga({payload}) {
  try {
    const { page, per_page, category, keyword } = payload;
    const res = yield loadApi({ page, per_page, category, keyword })
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* addSaga({payload}) {
  try {
    const res = yield call(addApi, payload.values);
    yield put(addSuccess(res.data));
    if (!payload?.stay) {
      const history = yield getContext('history');
      history.goBack();
    }
  } catch (error) {
    yield put(addFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  try {
    const res = yield call(getApi, payload);
    yield put(getSuccess(res.data));
  } catch (error) {
    yield put(getFail());
    yield put(msgError(error))
  }
}

function* updateSaga({payload}) {
  try {
    const res = yield call(updateApi, payload.values);
    yield put(updateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess(`Image type '${payload.values.name}' updated successfully!`))
  } catch (error) {
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function* delSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword, approve } = payload;
    const res = yield delApi({ keys, page, per_page, category, keyword, approve })
    const imageTypeList = res.data
    if (imageTypeList.target_data) {
      yield put(delAsk(imageTypeList))
    }
    else {
      yield put(delSuccess(imageTypeList));
    }
  } catch (error) {
    yield put(delFail());
    yield put(msgError(error))
  }
}

function* testSaga({payload}) {
  try {
    const res = yield call(testApi, payload);
    yield put(testSuccess(res.data));
  } catch (error) {
    yield put(testFail());
    yield put(msgError(error))
  }
}

function* checkNameSaga({payload}) {
  try {
    const {id, value} = payload
    const res = yield checkNameApi({value,id})
    const checkedName = res.data
    yield put(checkNameSuccess(checkedName))
  } catch (error) {
    yield put(checkNameFail(error.response.data.msg[0]))
  }
}

function* checkShorthandSaga({payload}) {
  try {
    const {id, value} = payload
    const res = yield checkShortApi({value,id})
    const checkedShorthand = res.data
    yield put(checkShorthandSuccess(checkedShorthand))
  } catch (error) {
    yield put(checkShorthandFail(error.response.data.msg[0]))
  }
}

function* editHintsSaga({payload}) {
  try {
    const types = payload
    const res = yield editHints({ types })
    yield put(editHintsSuccess(res.data))
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess('Updated successfully!'))
  } catch (error) {
    yield put(editHintsFail())
    yield put(msgError(error))
  }
}

function* getDefaultSaga({payload}) {
  try {
    const id = payload
    const res = yield getDefault(id)
    yield put(getDefaultSuccess(res.data))
    yield put(msgSuccess('Default setting loaded successfully!'))
  } catch (error) {
    yield put(getDefaultFail())
    yield put(msgError(error))
  }
}


function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga);
}

function* watchGet() {
  yield takeLeading(actions.GET, getSaga);
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}

function* watchDel() {
  yield takeLeading(actions.DEL, delSaga);
}

function* watchTest() {
  yield takeLeading(actions.TEST, testSaga);
}

function* watchCheck(){
  yield debounce(500, actions.CHECK_NAME, checkNameSaga)
  yield debounce(500, actions.CHECK_SHORTHAND, checkShorthandSaga)
}

function* watchEditHints() {
  yield takeLeading(actions.EDIT_HINTS, editHintsSaga)
}

function* watchGetDefault() {
  yield takeLeading(actions.GET_DEFAULT, getDefaultSaga)
}

export default function* blobTypesSaga() {
  yield all([
    fork(watchLoad),
    fork(watchAdd),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
    fork(watchTest),
    fork(watchCheck),
    fork(watchEditHints),
    fork(watchGetDefault)
  ]);
}
