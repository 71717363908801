import {getContext, all, call, fork, put, throttle, spawn, cancel, cancelled, select, join, takeLeading} from "redux-saga/effects";
import * as actions from "../../../redux/modules/longTask"
import {addTask, taskSuccess, taskFail, updateDrawerOpen, updateTask, 
  uploadSuccess, uploadFail, cancelUploadSuccess, cancelUploadFail,
} from "../../../redux/modules/longTask";
import { makeChannel, makeTaskData, progressWatcher } from "./lib";
import axios from "axios";
import { msgSuccess, msgError, buildErrorMessage, msgInfo, clear as clearMessage } from "../../../redux/modules/message";
import { getStudies } from "../../../redux/modules/patient";
import { uid } from "../../../lib/UtilDicomParse";
import { logout } from "../../../redux/modules/auth";


let tasks = new Set()

function createDBObjApi(patients, dataset_id, cancelToken) {
  return axios.post(
    "/api/upload/prep",
    { data: {patients : patients, dataset_id : dataset_id} },
    { cancelToken, withCredentials: true }
  );
}

function getPresignedUrlApi(uploadData, cancelToken) {
  return axios.post(
    "/api/upload/presigned",
    { data: uploadData },
    { cancelToken, withCredentials: true }
  );
}

function deleteCancelledObj({seriesIds, blobIds}) {
  return axios.post(
    "/api/delete/cancelledObj",
    { seriesIds, blobIds },
    { withCredentials: true }
  );
}

function s3UploadApi(file_or_blob, formData, data, progressCb, cancelToken) {
  const fileSize = file_or_blob.file.size
  let loadedSize = 0
  const updateSize = e => {
    const {loaded, total} = e 
    // file에 form 데이터가 붙음으로써 file 자체 사이즈보다 커져 아래와 같이 계산 진행
    // const diff = total - fileSize  // 1363 
    // uploadedFileSize = loaded - diff

    const uploadedFileSize = Math.floor(fileSize * (loaded/total))
    
    data.size -= loadedSize
    if (loaded !== total) {
      data.size += uploadedFileSize
      loadedSize = uploadedFileSize
    } 
    else {
      data.size += fileSize
    }
    progressCb(data)
  }
  
  return axios.post(file_or_blob.presigned.url, formData, {
    cancelToken,
    headers: {"Content-Type": "multipart/form-data",},
    onUploadProgress : updateSize
  });
}

function markUpoadFinishApi(payload, cancelToken) {
  return axios.post(
    "/api/upload/markFinished",
    payload,
    { cancelToken, withCredentials: true }
  );
}

function timestampToDate(timestamp, type) {
  const fileDate = new Date(timestamp * 1000);

  const year = fileDate.getUTCFullYear();
  const month = String(fileDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(fileDate.getUTCDate()).padStart(2, "0");
  const hours = String(fileDate.getUTCHours()).padStart(2, "0");
  const minutes = String(fileDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(fileDate.getUTCSeconds()).padStart(2, "0");

  if (type === 'amzData') {
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`
  }
  if (type === 'expiration') {
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`
  }
  if (type === undefined) {
    return {
      year: year,
      month: month,
      day: day,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }
}

function makePresignedForm({ patient, study, subpath_id, file_or_blob }) {
  const accKey = patient.acc_key
  const regionName = patient.region_name
  const credentialOpt = patient.credential_opt
  const bucketName = patient.bucket_name
  const initTimeStamp = patient.init_timestamp
  const diffAmzTime = file_or_blob.presigned["diff_amz_time"]
  const diffExpTime = file_or_blob.presigned["diff_exp_time"]
  const amzSignature = file_or_blob.presigned["x-amz-signature"]
  const policy_key = file_or_blob.presigned["policy_key"]

  const url = `${patient.url}/${bucketName}`;
  // s3key = f"{current_user}/{patient_id}/{study_id}/series/{series_id}/{basename}"
  const filename = file_or_blob["file"]["path"].split("/").slice(-1);
  const key = `${patient.current_user}/${patient.id}/${study.id}/${subpath_id}/${filename}`;

  const algorithm = "AWS4-HMAC-SHA256";
  const amzDate = timestampToDate(initTimeStamp + diffAmzTime, 'amzData');

  const expirationDate = timestampToDate(initTimeStamp + diffExpTime, 'expiration');

  const credential = `${accKey}/${amzDate.split("T")[0]}/${regionName}/${credentialOpt}`;

  const policyExpiration = `"expiration": "${expirationDate}"`

  const policyBucket = `{"bucket": "${bucketName}"}`
  const policykey = `{"key": "${policy_key}"}`
  const policyAlgorithm = `{"x-amz-algorithm": "${algorithm}"}`
  const policyCredential = `{"x-amz-credential": "${credential}"}`
  const policyAmzDate = `{"x-amz-date": "${amzDate}"}`
  const policyConditionArray = `[${policyBucket}, ${policykey}, ${policyAlgorithm}, ${policyCredential}, ${policyAmzDate}]`
  const policyCondition = `"conditions": ${policyConditionArray}`

  const policy = `{${policyExpiration}, ${policyCondition}}`;

  const form = {
    url: `${url}`,
    fields: {
      key: `${key}`,
      "x-amz-algorithm": `${algorithm}`,
      "x-amz-credential": `${credential}`,
      "x-amz-date": `${amzDate}`,
      policy: btoa(policy),
      "x-amz-signature": `${amzSignature}`,
    },
  };
  return form;
}

function makeFormData(file_or_blob) {
  const formData = new FormData();
  Object.keys(file_or_blob.presigned.fields).forEach(key => {
    formData.append(key, file_or_blob.presigned.fields[key]);
  });
  formData.append("file", file_or_blob.file);
  return formData
}

async function upload({patient, progressCb, treeData, taskStatus}) {
  return new Promise(async (resolve, reject) => {
    const data = {
      size: 0,
      count: 0,
      treeData,
    };
    for (const [si, study] of patient.studies.entries()) {
      for (const [sri, series] of study.series.entries()) {
        for (const file of series.files) {
          const cancelToken = axios.CancelToken.source();
          taskStatus.cancelToken.push(cancelToken)
          if (taskStatus.canceled) {
            break
          }
          try {
            file.presigned = makePresignedForm({
              patient,
              study,
              subpath_id: `series/${series.id}`,
              file_or_blob: file,
            });
            const formData = makeFormData(file)
            await s3UploadApi(file, formData, data, progressCb, cancelToken.token)
            data.count += 1;
            data.treeData[si].children[sri].count += 1

            if (series.files.length === data.treeData[si].children[sri].count) {
              data.treeData[si].children[sri].status = true
              await markUpoadFinishApi({series_id: series.id, pid: patient.id}, cancelToken.token)
              series.finished = true
            }
            progressCb(data);
          } catch (error) {
            data.treeData[si].children[sri].count += 1
            data.treeData[si].children[sri].status = false
            progressCb(data);
            reject({filePath : file.file.path, count : data.count});
          } 
        }
      }
      for (const [bi, blob] of study.blobs.entries()) {
        const cancelToken = axios.CancelToken.source();
        taskStatus.cancelToken.push(cancelToken)
        if (taskStatus.canceled) {
          break
        }
        try {
          blob.presigned = makePresignedForm({
            patient,
            study,
            subpath_id: `blobs/${blob.id}`,
            file_or_blob: blob,
          });
          const formData = makeFormData(blob)
          await s3UploadApi(blob, formData, data, progressCb, cancelToken.token)
          await markUpoadFinishApi({blob_id: blob.id, pid: patient.id}, cancelToken.token)
          blob.finished = true
          
          data.count += 1;
          data.treeData[si].children[study.series.length + bi].status = true
          data.treeData[si].children[study.series.length + bi].count = 1
          data.treeData[si].children[study.series.length + bi].totalCount = 1
          progressCb(data);
        } catch (error) {
          progressCb(data);
          reject({filePath : blob.file.path, count : data.count});
        }
      }
    }
    return resolve({count : data.count});
  });
}

function* startUpload(uploadData, spawnTaskObj) {
  const { patient, taskId } = uploadData;

  let totalSize = 0;
  let totalCount = 0;
  let treeDataRowCounts = 0

  const taskStatus = {
    canceled: false,
    cancelToken: []
  }
  
  try {
    const treeData = patient.studies.map(study => ({
      title: `${study.sid}-${study.desc}`,
      key: study.key,
      id: study.id,
      children: [
        ...study.series.map(series => {
          
          series.files.forEach(dcm => {
            totalSize += dcm.file.size;
            totalCount += 1;
          });

          return ({
            title: `${series.sn} - ${series.desc || series.protocol}`,
            key: series.key,
            id: series.id,
            status: undefined,
            count: 0,
            totalCount: series.files.length,
          })
        }),
        ...study.blobs.map(blob => {
          
          totalSize += blob.file.size;
          totalCount += 1;
          
          return ({
            title: blob.file.name,
            key: blob.key,
            id: blob.id,
            status: undefined,
            count: 0,
            totalCount: 1
          })
        })
      ]
    }))
    patient.totalSize = totalSize;
    patient.totalCount = totalCount;

    yield put(updateTask({ taskId, totalSize, totalCount, treeData, treeDataRowCounts}));

    const [uploadPromise, chan] = yield call(makeChannel, {
        func : upload,
        totalCount,
        patient,
        treeData,
        taskStatus
      });

    yield fork(progressWatcher, chan, taskId, totalSize, totalCount );

    const res = yield call(() => uploadPromise);

    yield put(taskSuccess({
      taskId: taskId,
      desc : `Done [${res.count}/${totalCount}]`
    }));
    yield put(msgSuccess(`${patient.pname}(${patient.pid}) upload complete`))

  } catch (error) {
    let desc = buildErrorMessage(error)
    if (Object.keys(error).includes('filePath','count')) {
      desc = `Failed to upload [${error.filePath}] file. [${error.count}/${totalCount}]`
    }
    yield put(taskFail({ taskId, desc }))
    yield put(msgError(desc))
  }
  finally {
    if (yield cancelled()) {
      taskStatus.canceled = true
      taskStatus.cancelToken.forEach(token => token.cancel())
    }
    const spawnTask = spawnTaskObj.spawnTask
    tasks.delete(spawnTask)
  }
}

function* getPresignedUrls(patients) {
  for (const patient of patients.values()) {
    for (const study of patient.studies.values()) {
      const cancelToken = axios.CancelToken.source()
      const spawnTaskObj = {}
      try {
        const oneStudyPatient = {
          ...patient,
          studies: [study],
        };
        // presigned 요청
        const res = yield getPresignedUrlApi(oneStudyPatient, cancelToken.token);
  
        // input presigned information to patient data
        const presignedPatient = res.data;
        study.series.forEach((series, sni) => {
          series.files.forEach((file, fi) => {
            file.presigned = presignedPatient.studies[0].series[sni].files[fi].presigned;
          });
        });
        study.blobs.forEach((blob, bi) => {
          blob.presigned = presignedPatient.studies[0].blobs[bi].presigned;
        });
  
        const uploadData = {
          patient: {
            ...patient,
            url : presignedPatient.url,
            current_user : presignedPatient.current_user,
            acc_key : presignedPatient.acc_key,
            init_timestamp : presignedPatient.init_timestamp,
            region_name : presignedPatient.region_name,
            credential_opt : presignedPatient.credential_opt,
            bucket_name : presignedPatient.bucket_name,
            studies: [study],
          },
          taskId: study.taskId,
        };
        // study 별로 upload 시작
        const spawnTask = yield spawn(startUpload, uploadData, spawnTaskObj)
        tasks.add(spawnTask)
        spawnTaskObj.spawnTask = spawnTask

      } catch (error) {
        yield put(taskFail({ taskId: study.taskId, desc: buildErrorMessage(error) }))
        yield put(msgError(error))
      }
      finally {
        if (yield cancelled()) {
          yield call(cancelToken.cancel)
        }
      }
    }
  }
}

function* startUploadSaga({ payload }) {
  const { dataset_id, patients, withPatientUpdate=false } = payload;

  const reducePatientsData = (patients, forDBInsertion=false) => {
    return patients.map(patient => ({
      ...patient,
      studies : patient.studies.map(study => ({
        ...study,
        series : study.series.filter(series => !series.disabled)
          .map(series => ({
            ...series,
            files : forDBInsertion ? [series.files[0]] : series.files,
            image_count : series.files.length
          })),
        blobs : study.blobs.filter(blob => !blob.disabled)
      })).filter(study => study.blobs.length !== 0 || study.series.length !== 0)
    }))
  }

  const reducedPatients = reducePatientsData(patients)
  const cancelToken = axios.CancelToken.source()
  
  try {
    if (patients.length === 0 ) {
      throw new Error('There is no new data')
    }
    // DB create
    if (!withPatientUpdate) {
      const {data: idmap} = yield createDBObjApi(reducePatientsData(patients, true), dataset_id, cancelToken.token);

      // db id 생성
      for (const patient of reducedPatients) {
        const pidmap = idmap[patient.key]
        patient.id = pidmap.patient_id
        for (const study of patient.studies) {
          const sidmap = pidmap.studies[study.key]
          study.id = sidmap.study_id
          study.series.forEach((sr, sridx) => sr.id = sidmap.series[sridx])
          study.blobs.forEach((b, bidx) => b.id = sidmap.blobs[bidx])
        }
      }
    }

    // Task 생성
    for (const patient of reducedPatients) {
      for (const study of patient.studies) {
        const taskId = uid()
        const title = `${patient.pid}_${patient.pname}`
        const treeData = [{
          title: `${study.id} - ${study.desc}`,
          children: []
        }]
        study.taskId = taskId
        const uploadDataIds = {
          series: study.series.map(sr => sr.id),
          blob: study.blobs.map(b => b.id)
        }
        const data = makeTaskData({ taskId, title, type : 'upload', treeData, label: 'Upload', uploadDataIds })
        yield put(addTask(data));
      }
    }
    
    if (withPatientUpdate) {
      const history = yield getContext("history");
      yield put(getStudies({id: history.location.state.key}))
    } 
    else {
      const history = yield getContext("history");
      history.push("/patient");
    }
    
    yield put(uploadSuccess())
    yield put(updateDrawerOpen(true))

  } catch (error) {
    const taskIds = reducedPatients.map(patient => patient.studies.map(study => study.taskId)).flat(Infinity);

    // error 발생시 위에서 생성한 task들 실패처리
    yield put(uploadFail({ taskIds, desc: buildErrorMessage(error) }))
    yield put(msgError(error))
    return;
  }
  finally {
    if (yield cancelled()) {
      yield call(cancelToken.cancel)
      return 
    }
  }
  // 위에서 성공시 presigned 요청 및 업로드 진행
  yield getPresignedUrls(reducedPatients)
}

function* addTaskUpload(action) {
  const task = yield fork(startUploadSaga, action)
  tasks.add(task)

  yield join(task)
  tasks.delete(task)
}

function* watchCancelUploadSaga() {
  for (const task of tasks) {
    yield cancel(task)
  }
  const tasksInStore = yield select(state => state.longTask.tasks);
  if (tasksInStore.length) {
    const canceledObjects = {
      seriesIds: [],
      blobIds: []
    }
    for (const task of tasksInStore) {
      if (task.type === 'upload') {
        const uploadDataIds = task.uploadDataIds
        const seriesIds = uploadDataIds.series
        const blobIds = uploadDataIds.blob
        
        canceledObjects.seriesIds.push(...seriesIds)
        canceledObjects.blobIds.push(...blobIds)
      }
    }
    try {
      const res = yield call(() => deleteCancelledObj(canceledObjects));
      const data = res.data
      const deletedSeriesCount = data.deleted_series_count
      const deletedBlobCount = data.deleted_blob_count
      if (deletedSeriesCount + deletedBlobCount > 0) {
        const seriesInfo = deletedSeriesCount > 0 && `${deletedSeriesCount} DICOM`
        const blobInfo = deletedBlobCount > 0 && `${deletedBlobCount} NIfTI`
        const dataInfo = `${seriesInfo ? seriesInfo : ''}${seriesInfo && blobInfo ? ' and ' : ''}${blobInfo ? blobInfo : ''}`
        const desc = `The upload of ${dataInfo} was canceled due to a logout.`

        yield put(msgInfo(desc))
      }
      tasks = new Set();

      yield put(cancelUploadSuccess())
    } catch (error) {
      yield put(cancelUploadFail())
      put(msgError(error))
    }
    yield put(logout())
    yield put(clearMessage())
  }
}

function* watchUpload() {
  yield throttle(500, actions.UPLOAD, addTaskUpload);
}

function* watchLogout() {
  yield takeLeading(actions.CANCEL_UPLOAD, watchCancelUploadSaga)
}

export default function* uploadSaga() {
  yield all([
    fork(watchUpload),
    fork(watchLogout),
  ]);
}
