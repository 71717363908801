import React, {useState, useEffect} from "react";
import {Space, Button, Table} from 'antd';
import {useSelector} from "react-redux";
import {load, clear} from "../redux/modules/patient"
import {useHistory} from "react-router-dom";
import useListPageContext from "../context/listPageContext";
import {useTitle} from "../hooks/title";
import { created_column } from "./createdRender";
import { usePatientListHooks } from "../hooks/patientRowSelectionListPage";

const searchOptions = [
  {value:"patient ID", label:"ID", dataIndex : 'pid'},
  {value:"patient name", label:"Name", dataIndex : 'name'},
]

function PatientList() {
  useTitle('DYNAPEX | Patients')
  const history = useHistory();
  const loading = useSelector(state => state.patient.loading)
  const patients = useSelector(state => state.patient.patients)
  const total = useSelector(state => state.patient?.total)
  const [data, setData] = useState([]);

  useEffect(() => {
    const newPatients = Object.entries(patients).map(([key, value], index) => {
      return {
        key: value.Patient.id,
        dbid: key,
        id: value.Patient.id,
        pid: value.Patient.pid,
        name: value.Patient.name,
        sex: value.Patient.sex,
        age: value.Patient.age,
        birth: value.Patient.birth,
        created: value.Patient.created,
        ownership: value.Ownership.explain,
      };
    });
    setData(newPatients);
  }, [patients])

  const {
    searchHighlight,
    rowSelection,
    Footer,
    ListPageSearchBox
  } = useListPageContext({load, clear, searchOptions, total, loading});

  const {
    MergeBtn,
    MergePatientModal,
    MergeAskModal,
    RemoveBtn,
    DelAskModal,
    DownloadBtn
  } = usePatientListHooks({
    rowSelection
  })

  const handleEdit = record => history.push('/patient/detail', {key: record.id})
  const handleCreate = () => history.push('/patient/detail')

  const columns = [
    {
      title: 'ID',
      dataIndex: 'pid',
      key: 'pid',
      ellipsis: {
        showTitle: false,
      },
      render : text => searchHighlight(text, 'pid')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: {
        showTitle: false,
      },
      render : text => searchHighlight(text, 'name')
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      align: 'center',
      width : 'auto'
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
      align: 'center',
      width : 'auto'
    },
    // {
    //   title: 'Ownership',
    //   dataIndex: 'ownership',
    //   key: 'ownership',
    //   align: 'center',
    //   render: record => {
    //     if (record === 'has') {
    //       return 'owned';
    //     }
    //     else {
    //       return 'shared';
    //     }
    //   }
    // },
    created_column,
    {
      title: 'Actions',
      key: 'action',
      width: 150,
      fixed : 'right',
      align : 'center',
      render: (record) => (
        <Space>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{width: '100%'}}>
      <h1>Patient</h1>
      <div style={{display: "inline-block"}}>
      <Space>
        <Button onClick={handleCreate}>Create</Button>
        <MergeBtn />
        {/* <DownloadBtn /> */}
        <RemoveBtn />
      </Space>
      </div>
      <ListPageSearchBox />
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowSelection={rowSelection}
        footer={Footer}
        pagination={false}
      />
      <MergePatientModal />
      <MergeAskModal />
      <DelAskModal />
    </div>
  );
}

export default PatientList;

