import React, { useState, useEffect, useRef, useContext } from "react";
import {Input, Row, Col, Button, Table, Space, Select, Form, Card, Progress, Switch} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {get, update, clear, updateFinishedData, add} from "../redux/modules/patient";
import moment from "moment";
import "react-block-ui/style.css"; 
import { RotatingLines } from  'react-loader-spinner'
import { usePatientDetailHooks } from "../hooks/patientRowSelectionDetailPage";
import {useTitle} from "../hooks/title";
import {load as loadImageTypes, clear as clearImageTypes} from "../redux/modules/blobTypes"
import { msgError } from "../redux/modules/message";
import { ThreeDots } from  'react-loader-spinner'
import { usePatientSearchInResultContext } from "../context/patientSearchInResultContext";
import {PrefixWhiteDatePicker, TransparentBGModal} from './styledComponent'
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import CreatedRender from "./createdRender";
import './patient.css'
import './blink.css'
import { SocketContext } from "../hooks/socket";
import { EVT_PATIENT } from "../constants";
import { togglePatientTableSplit } from "../redux/modules/config";
import { EditableRow, EditableCell} from "./tableEditable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter
} from "@fortawesome/free-solid-svg-icons";
import { patientIdRule, patientNameRule, studyDescRule, studyIdRule } from "../lib/patientDataRule";


function Patient() {
  useTitle("Patient");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()
  const enumMode = Object.freeze({CREATE: 0, EDIT: 1})
  const [mode, setMode] = useState(enumMode.CREATE);
  const {addHandler, removeHandler} = useContext(SocketContext)
  const user = useSelector(state => state.auth.user);
  const patient = useSelector(state => state.patient.patient)
  const studiesInStore = useSelector(state => state.patient.studies)
  const patientLoading = useSelector(state => state.patient.loading)
  const uploadLoading = useSelector(state => state.longTask.upload.loading)

  const TYPE_NAME = Object.freeze({DICOM: 'DICOM', NIFTI: 'NIfTI'})

  const isSplitMode = useSelector(state => state.config.patient_detail_table_split)

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const studyCardRef = useRef();
  const unResolvedCardRef = useRef()

  const {
    form,
    rowSelection,
    TYPE_INDEX,
    studies,
    column_editable,
    AddStudyBtn,
    RemoveBtn,
    DownloadBtn,
    MergeBtn,
    MoveBtn,
    CountBox,
    TypeSelectBox,
    PriorityBtn,
    FileUpload,
    stateDicomDrop,
    unResolvedList,
    UnresolvedTable,
    ViewBtn, 
    modalLoading,
    viewState,
    ImageViewModal
  } = usePatientDetailHooks({TYPE_NAME});

  const {
    getColumnSearchProps, 
    initializeFilterSorter, 
    sortOrder, 
    onTableChange,
    showSorterTooltip
  } = usePatientSearchInResultContext()

  const {getShortFromTypeId} = useGetImageTypeHooks()

  const patientSocketCB = payload => {
    dispatch(updateFinishedData(payload))
  }

  useEffect(()=>{
    addHandler(EVT_PATIENT, patientSocketCB)
    return () => removeHandler(EVT_PATIENT, patientSocketCB)
  },[user])

  // 최초 analysis db 조회
  useEffect(() => {
    if (location.state !== undefined && location.state.key !== undefined) {
      dispatch(get({id: location.state.key}))
      setMode(enumMode.EDIT)
    }
    else {
      form.setFieldsValue({
        sex: 'O',
        birth: moment(),
      });
    }
    dispatch(loadImageTypes({}))
    window.scrollTo(0, 0)

    return ()=> {
      dispatch(clear())
      dispatch(clearImageTypes())
    }
  }, [])

  useEffect(() => {
    if (patient !== undefined) {
      form.setFieldsValue({
        id: patient.id,
        pid: patient.pid,
        pname: patient.name,
        sex: patient.sex,
        birth: moment(patient.birth),
        age: patient.age,
        created: moment(patient.created),
      });
    }
  }, [patient]); 

  const handleSave = async () => {
    // mode: create or edit

    const BLINK_TIME = 2000
    const scrollMoveOptions = {block: 'center', behavior: 'smooth'}

    const payload = {
      patientInfo: form.getFieldsValue(true), 
      studies,
    }

    const editColumnElement = studyCardRef.current.querySelectorAll('.ant-form-item')
    if (editColumnElement.length) {
      editColumnElement.forEach((studyForm, studyFormIndex) => {
        studyForm?.classList.add('blink')
        setTimeout(() =>studyForm?.classList.remove('blink'), BLINK_TIME)
        
        if (studyFormIndex === 0) {
          studyForm.scrollIntoView(scrollMoveOptions)
        }
      })
      return dispatch(msgError('Incomplete study'))
    }

    if (mode === enumMode.CREATE) {
      return dispatch(add(payload))
    }

    const undefinedTypeStudies = studies.map(s => ({
      ...s,
      seriesList: s.seriesList.filter(sr => !sr.blobtype_id && !sr.deleted),
      blobs: s.blobs.filter(b => !b.blobtype_id && !b.deleted)
    })).filter(s => s.seriesList.length > 0 || s.blobs.length > 0)

    if (undefinedTypeStudies.length) {
      const copiedExpandedRowKeys = [...new Set([...expandedRowKeys, ...undefinedTypeStudies.map(s => s.key)])]
      if (expandedRowKeys.length !== copiedExpandedRowKeys.length) {
        setExpandedRowKeys(copiedExpandedRowKeys)
        await new Promise(resolve => setTimeout(resolve, 200));
      }

      undefinedTypeStudies.forEach((s, si) => {
        s.seriesList.forEach((sr, sri) => {
          let rowKey = sr.key
          if (!isSplitMode) {
            rowKey = sr.combineKey
          }
          const targetSeriesElement = studyCardRef.current.querySelector(`[data-row-key="${rowKey}"]`)
          const targetSeriesTypeElement = targetSeriesElement.querySelector('[data-col="type"]')
          targetSeriesTypeElement?.classList.add('blink')
          setTimeout(() => targetSeriesTypeElement?.classList.remove('blink'), BLINK_TIME)

          if (si === 0 && sri === 0) {
            targetSeriesTypeElement.scrollIntoView(scrollMoveOptions)
          }
        })

        s.blobs.forEach((b, bi) => {
          let rowKey = b.key
          if (!isSplitMode) {
            rowKey = b.combineKey
          }
          const targetNiftiElement = studyCardRef.current.querySelector(`[data-row-key="${rowKey}"]`)
          const targetNiftiTypeElement = targetNiftiElement.querySelector('[data-col="type"]')
          targetNiftiTypeElement?.classList.add('blink')
          setTimeout(() => targetNiftiTypeElement?.classList.remove('blink'), BLINK_TIME)
          
          if (si === 0 && bi === 0 && s.seriesList.length === 0) {
            targetNiftiTypeElement.scrollIntoView(scrollMoveOptions)
          }
        })
      })
      
      return dispatch(msgError('Incomplete type select'))
    }

    if (unResolvedList.length) {
      unResolvedList.forEach((ur, uri) => {
        const targetUnResolvedElement = unResolvedCardRef.current.querySelector(`[data-row-key="${ur.key}"]`)
        const bindColumnElement = targetUnResolvedElement.querySelector(`[data-index="bind"]`)
        bindColumnElement?.classList.add('blink')
        setTimeout(() => bindColumnElement?.classList.remove('blink'), BLINK_TIME)

        if (uri === 0) {
          bindColumnElement.scrollIntoView(scrollMoveOptions)
        }
      })
      return dispatch(msgError('unresolved data'))
    }

    // study edit 은 기존 db에만 해당, new study에는 해당사항 없음
    payload.studies.forEach(s => {
      if (s.new) s.edit = undefined
      s.seriesList.forEach(sr => {
        if (sr.new) sr.edit = undefined
      })
      s.blobs.forEach(b => {
        if (b.new) b.edit = undefined
      })
    })
    
    return dispatch(update(payload))
  }

  const handleCancel = () => history.goBack();

  const {
    loading: parsingLoading,
    progress,
    parseCount,
    parseCountMax: parseLength,
    exception,
  } = stateDicomDrop;


  const commonStudyKey = 'study'

  const studyColumnKeys = {
    sid : `${commonStudyKey}/sid`,
    desc : `${commonStudyKey}/desc`,
    date : `${commonStudyKey}/date`
  }

  const study_columns = [
    {
      ...getColumnSearchProps({
        title: "Study ID",
        dataIndex: "sid",
        key: studyColumnKeys.sid,
        sorter : (a, b) => a.sid > b.sid ? 1 : -1,
        sortOrder : sortOrder(studyColumnKeys.sid),
        editable: true,
        required: false,
        addedRule: studyIdRule
      })
    },
    {
      ...getColumnSearchProps({
        title : 'desc',
        dataIndex : 'desc',
        key : studyColumnKeys.desc,
        sorter : (a, b) => a.desc.toUpperCase() > b.desc.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder(studyColumnKeys.desc),
        editable: true,
        required: false, 
        addedRule: studyDescRule
      })
    },
    {
      title: "Study Date",
      dataIndex: "date",
      key: studyColumnKeys.date,
      sorter : (a, b) => a.date.toUpperCase() > b.date.toUpperCase() ? 1 : -1,
      sortOrder : sortOrder(studyColumnKeys.date),
      showSorterTooltip : showSorterTooltip(studyColumnKeys.date),
      width: 200,
      editable: true,
      editableCellType: 'date',
      addedRule: [
        {
          type: 'date',
          message : 'The format is not correct. '
        }
      ],
      render : date => {
        return date?.split(' ')?.[0]
      },
    },
  ];

  const expandedRowRenderSplitStudy = recordStudy => {
    const commonSeriesKey = `${recordStudy.id}/series`

    const seriesColumnKeys = {
      sn : `${commonSeriesKey}/sn`,
      desc : `${commonSeriesKey}/desc`,
      protocol : `${commonSeriesKey}/protocol`,
      blobtype_id : `${commonSeriesKey}/blobtype_id`,
      count : `${commonSeriesKey}/count`
    }

    const series_columns = [
      {
        ...getColumnSearchProps({
          title: "Series Number",
          dataIndex: "sn",
          key: seriesColumnKeys.sn,
          sorter : (a, b) => parseInt(a.sn) > parseInt(b.sn) ? 1 : -1,
          sortOrder : sortOrder(seriesColumnKeys.sn),
        }),
      },
      {
        ...getColumnSearchProps({
          title: "Series Description",
          dataIndex: "desc",
          key: seriesColumnKeys.desc,
          sorter : (a, b) => a.desc.toUpperCase() > b.desc.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(seriesColumnKeys.desc),
        }),
      },
      {
        ...getColumnSearchProps({
          title: "Protocol",
          dataIndex: "protocol",
          key: seriesColumnKeys.protocol,
          sorter : (a, b) => a.protocol.toUpperCase() > b.protocol.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(seriesColumnKeys.protocol),
        })
      },
      {
        title: 'Image Count',
        dataIndex: 'image_count',
        key: seriesColumnKeys.count,
        width : 140,
        sorter : (a, b) => a.count > b.count ? 1 : -1,
        sortOrder : sortOrder(seriesColumnKeys.count),
        showSorterTooltip : showSorterTooltip(seriesColumnKeys.count),
        render: (count, record) => (
          <CountBox count={count} record={record}/>
        )
      },
      {
        title: 'Image type',
        dataIndex: 'blobtype_id',
        key: seriesColumnKeys.blobtype_id,
        width : 140,
        sorter : (a, b) => getShortFromTypeId(a.blobtype_id)?.toUpperCase() > getShortFromTypeId(b.blobtype_id)?.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder(seriesColumnKeys.blobtype_id),
        showSorterTooltip : showSorterTooltip(seriesColumnKeys.blobtype_id),
        render: (typeId, record) => (
          <TypeSelectBox 
            typeId={typeId}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        width: 100,
        align: 'center',
        render: (priority, record) => (
          <PriorityBtn 
            priority={priority}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: "",
        key: "action",
        width: 100,
        render: record => <ViewBtn record={record}/>
      },
    ];

    const commonBlobKey = `${recordStudy.id}/blob`

    const blobColumnKeys = {
      fname : `${commonBlobKey}/fname`,
      blobtype_id : `${commonBlobKey}/blobtype_id`
    }

    const blob_columns = [
      {
        title: "File name",
        dataIndex: "fname",
        key: blobColumnKeys.fname,
        sorter : (a, b) => a > b ? 1 : -1,
        sortOrder : sortOrder(blobColumnKeys.fname),
        showSorterTooltip : showSorterTooltip(blobColumnKeys.fname),
      },
      {
        title: 'Image type',
        dataIndex: 'blobtype_id',
        key: blobColumnKeys.blobtype_id,
        sorter : (a, b) => getShortFromTypeId(a.blobtype_id)?.toUpperCase() > getShortFromTypeId(b.blobtype_id)?.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder(blobColumnKeys.blobtype_id),
        showSorterTooltip : showSorterTooltip(blobColumnKeys.blobtype_id),
        width : 140,
        render: (typeId, record) => (
          <TypeSelectBox 
            typeId={typeId}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        width: 100,
        align: 'center',
        render: (priority, record) => (
          <PriorityBtn 
            priority={priority}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: "",
        key: "action",
        width: 100,
        render: record => <ViewBtn record={record}/>
      },
    ];

    return (
      <>
        DICOM
        <Table
          rowSelection={rowSelection({TYPE: TYPE_INDEX.DICOM, studyKey: recordStudy.key})}
          locale={{ emptyText: "No data" }}
          style={{ marginLeft: 40 }}
          columns={series_columns}
          dataSource={recordStudy.seriesList.filter(d => !d.deleted)}
          pagination={false}
          onChange={onTableChange}
          rowClassName={record => record.new && 'new-data'}
        />
        <br />
        NIfTI
        <Table
          rowSelection={rowSelection({TYPE: TYPE_INDEX.NIFTI, studyKey: recordStudy.key})}
          locale={{ emptyText: "No data" }}
          style={{ marginLeft: 40 }}
          columns={blob_columns}
          dataSource={recordStudy.blobs.filter(d => !d.deleted)}
          pagination={false}
          onChange={onTableChange}
          rowClassName={record => record.new && 'new-data'}
        />
      </>
    );
  };

  const LoadingUI = () => {
    if (viewState.count) {
      return (
        <div>
          {`loading files... ${viewState.count}/${viewState.totalCount}`}
          <br />
          <br />
          <Progress
            type="circle"
            percent={parseInt(viewState.count/viewState.totalCount * 100)}
          />
        </div>
      )
    }
    else if (parsingLoading) {
      return (parseLength !== 0
        ? (
          <div>
            {`Parsing files... ${parseCount}/${parseLength}`}
            <br />
            <br />
            <Progress
              type="circle"
              percent={progress}
              status={exception ? "exception" : ""}
            />
          </div>
      ) : (
        <Space direction="vertical" align="center">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color="#0D6E6D"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
          <h4>Accepting files ...</h4>
        </Space>)
      )
    }
    return (
      <Space>
        <RotatingLines
          strokeColor="#0D6E6D"
          strokeWidth="5"
          animationDuration="0.75"
          width="30"
          visible={true}
        />
      </Space>
    )
  }

  const expandedRowRenderCombineStudy = recordStudy => {    
    
    const commonKey = `${recordStudy.id}/combine`

    const combineColumnKeys = {
      type : `${commonKey}/type`,
      sn_fn : `${commonKey}/sn_fn`,
      desc : `${commonKey}/desc`,
      protocol : `${commonKey}/protocol`,
      count : `${commonKey}/count`,
      blobtype_id : `${commonKey}/blobtype_id`,
    }

    const combine_columns = [
      {
        ...getColumnSearchProps({
          title: "Type",
          dataIndex: 'type',
          key: combineColumnKeys.type,
          sorter : (a, b) => parseInt(a.type) > parseInt(b.type) ? 1 : -1,
          sortOrder : sortOrder(combineColumnKeys.type),
        }),
      },
      {
        ...getColumnSearchProps({
          title: "Series Number / File name",
          dataIndex: 'sn_fn',
          key: combineColumnKeys.sn_fn,
          sortOrder : sortOrder(combineColumnKeys.sn_fn),
          sorter : (a, b) => parseInt(a.sn_fn) > parseInt(b.sn_fn) ? 1 : -1,
        }),
      },
      {
        ...getColumnSearchProps({
          title: "Description",
          dataIndex: "desc",
          key: combineColumnKeys.desc,
          sorter : (a, b) => a.desc.toUpperCase() > b.desc.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(combineColumnKeys.desc),
        }),
      },
      {
        ...getColumnSearchProps({
          title: "Protocol",
          dataIndex: "protocol",
          key: combineColumnKeys.protocol,
          sorter : (a, b) => a.protocol.toUpperCase() > b.protocol.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(combineColumnKeys.protocol),
        })
      },
      {
        title: 'Image Count',
        dataIndex: 'image_count',
        key: combineColumnKeys.count,
        sorter : (a, b) => a.count > b.count ? 1 : -1,
        sortOrder : sortOrder(combineColumnKeys.count),
        showSorterTooltip : showSorterTooltip(combineColumnKeys.count),
        width : 140,
        align: 'center',
        render: (count, record)=> (
          <CountBox count={count} record={record}/>
        )
      },
      {
        title: 'Image type',
        dataIndex: 'blobtype_id',
        key: combineColumnKeys.blobtype_id,
        sorter : (a, b) => getShortFromTypeId(a.blobtype_id)?.toUpperCase() > getShortFromTypeId(b.blobtype_id)?.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder(combineColumnKeys.blobtype_id),
        showSorterTooltip : showSorterTooltip(combineColumnKeys.blobtype_id),
        width : 140,
        render: (typeId, record) => (
          <TypeSelectBox 
            typeId={typeId}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        width: 100,
        align: 'center',
        render: (priority, record) => (
          <PriorityBtn 
            priority={priority}
            record={record}
            recordStudy={recordStudy}
          />
        )
      },
      {
        title: "",
        key: "action",
        align: 'center',
        width: 100,
        render: record => <ViewBtn record={record}/>
      },
    ];

    const buildDataSource = () => {
      const combinedDataSource = [
        ...recordStudy.seriesList, 
        ...recordStudy.blobs
      ].map(data => {
        if (data.type === TYPE_NAME.DICOM) {
          data.sn_fn = data.sn
        }
        if (data.type === TYPE_NAME.NIFTI) {
          data.sn_fn = data.fname
        }
        return data
      })
      return combinedDataSource.filter(d => !d.deleted)
    }

    return (
      <>
        <Table
          rowSelection={rowSelection({TYPE: TYPE_INDEX.COMBINE, studyKey: recordStudy.key, rowKey:'combineKey'})}
          locale={{ emptyText: "No data" }}
          style={{ marginLeft: 40 }}
          columns={combine_columns}
          rowKey={"combineKey"}
          dataSource={buildDataSource()}
          pagination={false}
          onChange={onTableChange}
          rowClassName={record => record.new && 'new-data'}
        />
        <br />
      </>
    );
  };

  const onChangeTableMode = () => dispatch(togglePatientTableSplit())

  const studyClassName = record => {
    let classNameList = ['editable-row']
    if (record.new) {
      classNameList.push('new-data')
    }
    return classNameList.join(' ')
  }

  const expandable = {
    expandedRowKeys,
    expandRowByClick: true,
    defaultExpandAllRows: false,
    onExpandedRowsChange: expandedRows => setExpandedRowKeys(expandedRows),
    expandedRowRender: (
      isSplitMode
      ? expandedRowRenderSplitStudy
      : expandedRowRenderCombineStudy
    )
  }

  const ExpandableBtn = () => {
    const toExpand = expandedRowKeys.length === 0
    const icon = <FontAwesomeIcon style={{marginRight:8}} icon={toExpand? faUpRightAndDownLeftFromCenter: faDownLeftAndUpRightToCenter}/>
   
    const handleExpandToggle = () => {
      if (expandedRowKeys.length > 0) setExpandedRowKeys([])
      else setExpandedRowKeys(studies.map(s => s.key))
    }
    
    const buttonTitle = toExpand ? 'Expand' : 'Collapse'

    return (
      <Button 
        onClick={handleExpandToggle}
        icon={icon}
      >{buttonTitle}</Button>)
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display :'flex'
        }}
      >
        <div
          style={{
            width: "100%",
            margin: "20px auto",
          }}
        >
          <Form 
            form={form}
            labelCol={{span: 4}}
            disabled={patientLoading}
          >
            <div style={{width: '70%', margin: '0 auto'}}>
              <Row gutter={[8, 4]} align="middle">
                <Col span={6}>
                  <h1>Patient</h1>
                </Col>
                <Col span={12}></Col>
                <Col span={6} flex="1">
                  <div>
                    <Space
                      direction="horizontal"
                      style={{ width: "100%", justifyContent: "right" }}
                    >
                      <Button onClick={handleCancel} disabled={patientLoading}>Cancel</Button>
                      <Button onClick={handleSave} type={'primary'} disabled={patientLoading}>OK</Button>
                    </Space>
                  </div>
                </Col>
              </Row>
              <br />
              {
                mode === enumMode.EDIT && 
                <Space 
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right", marginBottom: '15px' }}
                >
                  created : <CreatedRender date={patient?.created} full={true}/>
                </Space>
              }
              <Form.Item name="id" hidden={true}/>
              <Row gutter={[8,8]}>
                <Col span={12}>
                  <Form.Item 
                    name="pid" 
                    label="ID" 
                    rules={patientIdRule}
                  >
                    <Input 
                      placeholder="Please enter patient ID" 
                      maxLength={64}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="pname" 
                    label="Name"
                    rules={patientNameRule}
                  >
                    <Input 
                      placeholder="Please enter patient name" 
                      maxLength={64}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item 
                    name="sex" 
                    label="Sex"
                    rules={[
                      {
                        required: true,
                        message: 'Please select sec!',
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {label: 'Male', value: 'M'},
                        {label: 'Female', value: 'F'},
                        {label: 'Other', value: 'O'},
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="birth" label="Birthday">
                    <PrefixWhiteDatePicker format="YYYY-MM-DD" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="age" label="Age">
                    <Input maxLength={20}/>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {/*study = series + blobs*/}
            <br />
            <div style={{width: isSplitMode ? '70%' : '90%', margin: '0 auto'}}>
              <Card ref={studyCardRef}>
                <Row gutter={[8, 4]} align="middle">
                  <Col span={6}>
                    <div>
                      <h3>Study list</h3>
                    </div>
                  </Col>
                  <Col span={18} flex="1">
                    <div>
                      <Space
                        direction="horizontal"
                        style={{ width: "100%", justifyContent: "right" }}
                      >
                        <Switch 
                          unCheckedChildren="DICOM / NIfTI "
                          checkedChildren="DICOM + NIfTI "
                          onChange={onChangeTableMode}
                          checked={!isSplitMode}
                        />
                        <ExpandableBtn />
                        <Button onClick={initializeFilterSorter}>Clear filter</Button>
                        <AddStudyBtn />
                        {
                          mode === enumMode.EDIT && 
                          <>
                            {/* <DownloadBtn /> */}
                            <MergeBtn />
                            <MoveBtn />
                          </>
                        }
                        <RemoveBtn />
                      </Space>
                    </div>
                  </Col>
                </Row>
                <Table
                  loading={patientLoading}
                  columns={column_editable(study_columns)}
                  dataSource={studies.filter(s => !s.deleted)}
                  rowSelection={rowSelection({TYPE: TYPE_INDEX.STUDY})}
                  expandable={expandable}
                  pagination={studiesInStore.length > 10}
                  onChange={onTableChange}
                  components={{
                    body: {
                      row: EditableRow,
                      cell: EditableCell,
                    }
                  }}
                  rowClassName={studyClassName}
                />
              </Card>
              {
                unResolvedList.length > 0 && 
                <UnresolvedTable cardRef={unResolvedCardRef} />
              }
            </div>
            <br />
            {
              mode === enumMode.EDIT && 
              <FileUpload />
            }
          </Form>
        </div>
      </div>
      <TransparentBGModal  // Loading & Progress Modal
        open={modalLoading || patientLoading || parsingLoading || uploadLoading}
        footer={null}
        closable={false}
        zIndex={2000}
        centered
        bodyStyle={{
          backgroundColor : 'rgba(0,0,0,0)'
        }}
      >
        <div style={{
          display: "flex",
          height : '100%',
          justifyContent: "center",
          alignItems: "center",
          textAlign : 'center'
        }}>
          <LoadingUI />
        </div>
      </TransparentBGModal>
      <ImageViewModal />
    </>
  );
}

export default Patient;