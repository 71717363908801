import { combineReducers } from "redux";
import auth from './modules/auth'
import config from './modules/config'
import blobTypes from './modules/blobTypes'
import patient from './modules/patient'
import analysis from './modules/analysis'
import view from './modules/view'
import dataset from './modules/dataset'
import pipeline from './modules/pipeline'
import scatter from './modules/scatter'
import longTask  from "./modules/longTask";
import notice from './modules/notice'
import message from './modules/message'

const rootReducer = combineReducers({
  auth,
  config,
  blobTypes,
  patient,
  analysis,
  view,
  dataset,
  pipeline,
  scatter,
  longTask,
  notice,
  message
});

export default rootReducer;
