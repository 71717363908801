import React, { useState, useRef } from "react";

export const VolumeImageOverlayContext = React.createContext({})
VolumeImageOverlayContext.displayName = 'VolumeImageOverlay';

export const VolumeImageOverlayContextProvider = ({ children }) => {
  const overlays = useRef([]);
  const [action, setAction] = useState({});
  const toggleOverlay = (url, option, stackX, stackY, stackZ) => {
    const urlIndex = overlays.current.findIndex(item => item.url  === url)
    if (urlIndex >= 0) {
      setAction({type: 'remove', index: urlIndex})
      overlays.current = overlays.current.filter(item => item.url !== url)
    }
    else {
      setAction({type: 'add', index: overlays.current.length})
      overlays.current = [...overlays.current, {url, option, x: stackX, y: stackY, z: stackZ}]
      return true
    }
  }

  const cleanup = url => {
    if (overlays.current.length > 0) {
      const urlIndex = overlays.current.findIndex(item => item.url  === url)
      if (urlIndex >= 0) {
        const overlay = overlays.current[urlIndex]
        setAction({type: 'remove', index: urlIndex})
        overlays.current = overlays.current.filter(item => item.url !== url)
        overlay.x = null
        overlay.y = null
        overlay.z = null
      }


      overlays.current.map((s, si) => {
        s.x = null
        s.y = null
        s.z = null
      })
      overlays.current = []
    }
  }

  return (
    <VolumeImageOverlayContext.Provider
      value={{ overlays: overlays.current, toggleOverlay, action, setAction, cleanup }}
    >
      {children}
    </VolumeImageOverlayContext.Provider>
  );
};
export const VolumeImageOverlayContextConsumer = VolumeImageOverlayContext.Consumer;

export default VolumeImageOverlayContext;