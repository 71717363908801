import {Row, Col, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
const {Search} = Input;

// options : { label, value }[]
function SearchBox({options, loading, onSearch, style}) {
  // 검색 키워드 + 카테고리 가져올 방법

  const location = useLocation()
  const currCategory = location.state?.pagination?.category
  const currKeyword = location.state?.pagination?.keyword
  const [category, setCategory] = useState(currCategory || options[0].value)
  const [keyword, setKeyword] = useState(currKeyword || null)

  useEffect(()=>{
    setCategory(currCategory || options[0].value)
    setKeyword(currKeyword)
  },[currCategory, currKeyword])

  const onCategoryChange = (value, index) => {
    setCategory(value);
  }

  const onClickSearch = (value, event) => {
    event.preventDefault(); // 안막으면 search창에 키보드로 enter 입력할 때마다 onFinish 연결됨
    onSearch(value, category);
  }

  const onChange = (e) => setKeyword(e.target.value)

  return (
    <div style={style}>
      <Row gutter={[8, 4]}>
        <Col span={6}>
          <Select
            onChange={onCategoryChange}
            value={category}
            dropdownMatchSelectWidth={false}
            style={{width: '100%'}}
            options={options}
            disabled={loading}
          />
        </Col>
        <Col span={18}>
          <Search
            value={keyword}
            onChange={onChange}
            loading={loading}
            placeholder="input search text"
            enterButton="Search"
            allowClear
            onSearch={onClickSearch}
          />
        </Col>
      </Row>
    </div>
  );
}
export default SearchBox;
