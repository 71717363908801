import * as actions from "../../redux/modules/notice";
import { all, fork, put, takeLeading, getContext } from "redux-saga/effects";
import axios from "axios";
import {
  getListSuccess, getListFail, getDetailSuccess, getDetailFail,
  delSuccess, delFail, pubEditSuccess, pubEditFail,
} from "../../redux/modules/notice";
import { v4 } from "uuid";
import purify from "dompurify"; // dangerouslySetInnerHTML 에서 XSS 공격 방지 library
import { msgError } from "../../redux/modules/message";

function getListApi({page, per_page, category, keyword}) {
  return axios.post("/api/notice/list", { pagination : {page, per_page}, search : {category, keyword}}, 
  { withCredentials: true })
}

function getDetailApi(key) {
  return axios.get("/api/notice/detail", { params: { key: key} }, 
  { withCredentials: true })
}

function deleteApi({keys, page, per_page, category, keyword}) {
  return axios.post("/api/notice/delete", { keys : keys, pagination : {page, per_page}, search : {category, keyword}}, 
  { withCredentials: true })
}

function publishApi(articleForm) {
  return axios.post('/api/notice/publish', articleForm, 
  { withCredentials: true })
}

function editApi(articleForm) {
  return axios.post('/api/notice/edit', articleForm, 
  { withCredentials: true })
}

function imageUploadApi(filename) {
  return axios.post("/api/notice/image", { filename }, 
  { withCredentials: true });
}

function dataURItoBlob(dataURI) {
  // base64 to Blob
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  // Multipurpose Internet Mail Extensions의 약자
  return new Blob([ia], { type: mimeString });
}

async function imageCheck(content) {
  // base64 regex
  const re = /(data:image\/[^;]+;base64[^"]+)/g;

  let checkedContent = content;
  const imgList = [];

  if (re.test(content)) {
    // include base64
    let match = content.match(re);

    const base64List = [];
    while ((match = re.exec(content)) != null) {
      base64List.push(match[0]);
    }

    const blobList = [];
    base64List.forEach(function (base64) {
      blobList.push(dataURItoBlob(base64));
    });

    const presignedUrlList = [];

    for (let i = 0; i < blobList.length; i++) {
      const blob = blobList[i];
      const ext = blob.type.split("/").pop();
      const filename = `${v4()}.${ext}`;
      const response = await imageUploadApi(filename)
      const presignedURL = response.data;
      presignedUrlList.push(presignedURL);
    }

    const success = [];
    for (let i = 0; i < presignedUrlList.length; i++) {
      const formData = new FormData();
      for (const key in presignedUrlList[i].fields) {
        formData.append(key, presignedUrlList[i].fields[key]);
      }
      formData.append("Content-Type", blobList[i].type);
      formData.append("file", blobList[i]);
      const uploadStatus = await axios.post(
        `${presignedUrlList[0].url}`,
        formData
      );
      if (uploadStatus.status === 204) {
        success.push(uploadStatus.config.url);
      }
    }

    if (success.length === presignedUrlList.length) {
      base64List.forEach(function (base64, index) {
        checkedContent = checkedContent.replace(
          base64,
          `${success[index]}/${presignedUrlList[index].fields.key}`
        );
        imgList.push(presignedUrlList[index].fields.key);
      });
    }
  }
  return {checkedContent : checkedContent, imgList : imgList}
}

function* getListSaga({ payload }) {
  try {
    const { page, per_page, category, keyword } = payload
    const res = yield getListApi({category, keyword, page, per_page});
    yield put(getListSuccess(res.data))
  } catch (error) {
    yield put(getListFail())
    yield put(msgError(error))
  }
}

function* getDetailSaga({payload}) {
  try {
    const { key } = payload
    const res = yield getDetailApi(key);
    yield put(getDetailSuccess(res.data))
  } catch (error) {
    const history = yield getContext("history");
    history.replace(`/notice`)
    yield put(getDetailFail())
    yield put(msgError(error))
  }
}

function* deleteSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword } = payload;
    const res = yield deleteApi({keys, page, per_page, category, keyword});
    yield put(delSuccess(res.data))
  } catch (error) {
    yield put(delFail())
    yield put(msgError(error))
  }
}

function* buildArticleForm({ title, content, id, key, image }) {
  const { checkedContent, imgList } = yield imageCheck(content)
  const deletedImgList = [];

    image.forEach((imageURL) => {
      if (!imgList.includes(imageURL)) {
        deletedImgList.push(imageURL)
      }
    })
    const data = {
      title: purify.sanitize(title),
      content: purify.sanitize(checkedContent),
      id: id,
      key: key,
      image: imgList,
      remove: deletedImgList,
    };
  return data
}

function* pubEditSaga({payload}) {
  try {
    const { title, content, id, key, image } = payload
    const articleForm = yield buildArticleForm({ title, content, id, key, image })
    let res
    
    if (key) {
      res = yield editApi(articleForm)  
    } else {
      res = yield publishApi(articleForm)  
    }

    const article = res.data
    yield put(pubEditSuccess(article))
    const history = yield getContext("history");
    history.goBack()
  } catch (error) {
    yield put(pubEditFail())
    yield put(msgError(error))
  }
}

function* watchNotice() {
  yield takeLeading(actions.GET_LIST, getListSaga);
  yield takeLeading(actions.GET_DETAIL, getDetailSaga);
  yield takeLeading(actions.DEL, deleteSaga);
  yield takeLeading(actions.PUB_EDIT, pubEditSaga);
}

export default function* noticeSaga() {
  yield all([fork(watchNotice)]);
}