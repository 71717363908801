import {
  Space,
  Card,
  Descriptions,
  InputNumber,
  Select,
  Switch,
  Input,
  Form, Divider,
} from "antd";
import React, {Fragment} from "react";
import ExpressionOfCondition from "./expressionOfConditions";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import ROIFields from "./ROIFields";
import TrackROIFields from "./TrackROIFields";

const {Option} = Select;

export const ModelView = ({model, taskInputCandidates, setFieldsValue, longitudinal}) => {
  const {getTypeFromTypeId} = useGetImageTypeHooks()
  return (
    <>
      <Divider style={{ margin: '5px 0' }} />
      <Descriptions
        title={<div style={{fontSize: 14, fontWeight: 'normal'}}>{"Model information"}</div>}
        bordered column={1}
        size="small">
        <Descriptions.Item label="desc" style={{whiteSpace: 'pre-line'}}> {model.desc} </Descriptions.Item>
      </Descriptions>
      <Divider style={{ margin: '10px 0' }} />
      {
        Object.keys(model.config).length === 0 ? null :
        <Card type="inner" title="Model configuration" size="small">
          {
            model.config.map((item, item_index) => {
              const name = item.name;
              const options = item.options;
              // let val = item.value;
              const val = item?.value || item.default;
              //props.setFieldsValue({[name]: val})

              let children = null;
              let isSwitch = false;
              let isFormItemList = false;
              let vertical = item?.vertical
              switch (item.type) {
                case 'string':
                  children = <Input />
                  break
                case 'number':
                  children = (
                    <InputNumber
                      disabled
                      size="small"
                      value={val}
                      key={name}/>)
                  break
                case 'numbers':
                  children = (fields) => {
                    return (
                      <Form.Item
                        label={name}
                        labelWrap={true}
                        labelAlign="left"
                        labelCol={vertical ? { span: 24 }:{span: 6}}
                        wrapperCol={vertical ? { span: 24 }:{span: 18}}
                      >
                        <Space key={name + ' space'}>
                          {fields.map(field => (
                            <Form.Item
                              name={field.name}
                              key={field.key}
                              label={field.label}
                            >
                              <InputNumber/>
                            </Form.Item>
                          ))}
                        </Space>
                      </Form.Item>
                    )
                  }
                  isFormItemList = true
                  break
                case 'boolean':
                  children = (
                    <Switch defaultChecked={val} />
                  )
                  isSwitch = true
                  break
                case 'select':
                  children =
                    <Select
                      dropdownMatchSelectWidth={false}
                      placeholder="Select an option"
                      value={val}
                      item_index={item_index}
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      {options.map((option, oidx2) => (
                        <Option
                          key={oidx2} value={option} option_index={oidx2}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  break
                case 'expression':
                  children = <ExpressionOfCondition value={val} inputs={[{candidates: taskInputCandidates}]} />
                  break
                case 'locate-roi':
                {
                  const outputTypes = model.output.map(o => getTypeFromTypeId(o.blobtype))
                  const type = outputTypes.find(t => t.seg)
                  if (!type) return <></>
                  children = (
                    <ROIFields
                      imageType={type}
                      name={name}
                      value={val}
                      setFieldsValue={setFieldsValue}
                      disabled={false}
                    />
                  )
                  break
                }
                default:
                  children = null
              }
              let parent = (
                <Form.Item
                  label={name}
                  name={name}
                  key={name}
                  initialValue={val}
                  valuePropName={isSwitch ? "checked" : "value"}
                  required={item?.required}
                  labelCol={vertical ? { span: 24 }:{span: 6}}
                  wrapperCol={vertical ? { span: 24 }:{span: 18}}
                >
                  {children}
                </Form.Item>
              )
              if (isFormItemList) {
                parent = (
                  <Form.List
                    label={name}
                    name={name}
                    key={name}
                    required={item?.required}
                  >
                    {children}
                  </Form.List>
                )
              }
              return parent;
            })
          }
        </Card>
      }
    </>
  );
}
