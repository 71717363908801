export const LOAD = 'dataset/LOAD';
export const LOAD_SUCCESS = 'dataset/LOAD_SUCCESS';
export const LOAD_FAIL = 'dataset/LOAD_FAIL';
export const LOAD_PATIENTS = 'dataset/LOAD_PATIENTS';
export const LOAD_PATIENTS_SUCCESS = 'dataset/LOAD_PATIENTS_SUCCESS';
export const LOAD_PATIENTS_FAIL = 'dataset/LOAD_PATIENTS_FAIL';
export const GET = 'dataset/GET';
export const GET_SUCCESS = 'dataset/GET_SUCCESS';
export const GET_FAIL = 'dataset/GET_FAIL';
export const GET_PAGED_PATIENTS = 'dataset/GET_PAGED_PATIENTS'
export const GET_PAGED_PATIENTS_SUCCESS = 'dataset/GET_PAGED__PATIENTS_SUCCESS'
export const GET_PAGED_PATIENTS_FAIL = 'dataset/GET_PAGED_PATIENTS_FAIL'
export const ADD = 'dataset/ADD';
export const ADD_SUCCESS = 'dataset/ADD_SUCCESS';
export const ADD_FAIL = 'dataset/ADD_FAIL';
export const UPDATE = 'dataset/UPDATE';
export const UPDATE_SUCCESS = 'dataset/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'dataset/UPDATE_FAIL';
export const DEL = 'dataset/DEL';
export const DEL_SUCCESS = 'dataset/DEL_SUCCESS';
export const DEL_FAIL = 'dataset/DEL_FAIL';
export const SET_TOTAL2 = 'dataset/SET_TOTAL2'
export const INCREASE_TOTAL2 = 'dataset/INCREASE_TOTAL2 ';
export const DECREASE_TOTAL2 = 'dataset/DECREASE_TOTAL2 ';
export const CLEAR = 'dataset/CLEAR';
export const CHECK_NAME = 'dataset/CHECK_NAME';
export const CHECK_NAME_SUCCESS = 'dataset/CHECK_NAME_SUCCESS';
export const CHECK_NAME_FAIL = 'dataset/CHECK_NAME_FAIL';
export const LOAD_MORE = 'dataset/LOAD_MORE'
export const LOAD_MORE_SUCCESS = 'dataset/LOAD_MORE_SUCCESS'
export const LOAD_MORE_FAIL = 'dataset/LOAD_MORE_FAIL'

const initialState = {
  loaded: false,
  list: [],
  patients: [],
  included: []
}

function convert(datasets) {
  return Object.values(datasets).map((value, index) => {
    return {
      key: value.id,
      dbid: value.id,
      name: value.name,
      desc: value.desc,
      created: value.created,
      caseCount: value.case_count,
      // longitudinal: value.longitudinal,
    };
  })
}


export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        total: action.payload.total,
        list: convert(action.payload.list)
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case LOAD_PATIENTS:
      return {
        ...state,
        loading: true
      };
    case LOAD_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        patients: action.payload
      };
    case LOAD_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case ADD:
      return {
        ...state,
        loading: true
      };
    case ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        total: action.payload.total,
        list: convert(action.payload.list)
      };
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        dataset: action.payload.dataset,
        patients: action.payload.patients,
        total: action.payload.total,
        total2: action.payload.total2,
        included: action.payload.included,
        included_patients: action.payload.included_patients,
        check : {
          ...state.check,
          name : action.payload.dataset.name
        }
      };
    // const same_length = state.included.length === action.payload.included.length
      // if (!same_length ||
      //     (same_length && differenceBy(state.included, action.payload.included, 'key').length)) {
      //   return {
      //     ...state,
      //     loading: false,
      //     loaded: true,
      //     dataset: action.payload.dataset,
      //     patients: action.payload.patients,
      //     total: action.payload.total,
      //     total2: action.payload.total2,
      //     included: action.payload.included,
      //   };
      // } else {
      //   return {
      //     ...state,
      //     loading: false,
      //     loaded: true,
      //     dataset: action.payload.dataset,
      //     patients: action.payload.patients,
      //     total: action.payload.total,
      //   };
      // }
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET_PAGED_PATIENTS:
      return {
        ...state,
        loading : true
      }
    case GET_PAGED_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        patients: action.payload.patients,
        total: action.payload.total,
        total2: action.payload.total2,
        included: action.payload.included,
        included_patients: action.payload.included_patients,
      }
    case GET_PAGED_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DEL:
      return {
        ...state,
        loading: true
      };
    case DEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        total: action.payload.total,
        list: convert(action.payload.list)
      };
    case DEL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case SET_TOTAL2:
      return {
        ...state,
        total2: action.payload
      };
    case INCREASE_TOTAL2:
      return {
        ...state,
        total2: state.total2 + action.payload
      };
    case DECREASE_TOTAL2:
      return {
        ...state,
        total2: state.total2 - action.payload
      };
    case CLEAR:
      return initialState
    case CHECK_NAME:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          name : undefined,
          nameError : undefined
        }
      }
    case CHECK_NAME_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          name : action.payload
        }
      }
    case CHECK_NAME_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          nameError : action.payload
        }
      }
    case LOAD_MORE:
      return {
        ...state,
        loading : true
      }
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading : false,
        total : action.payload.total,
        list : [...state.list, ...convert(action.payload.list)]
      }
    case LOAD_MORE_FAIL:
      return {
        ...state,
        loading : false
      }
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function loadPatient(payload) {
  return {type: LOAD_PATIENTS, payload};
}

export function loadPatientSuccess(payload) {
  return {type: LOAD_PATIENTS_SUCCESS, payload};
}

export function loadPatientFail(payload) {
  return {type: LOAD_PATIENTS_FAIL, payload};
}

export function add(payload) {
  return {type: ADD, payload};
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload};
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload};
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function getPagedPatients(payload) {
  return {type: GET_PAGED_PATIENTS, payload}
}

export function getPagedPatientsSuccess(payload) {
  return {type : GET_PAGED_PATIENTS_SUCCESS, payload}
}

export function getPagedPatientsFail(payload) {
  return {type : GET_PAGED_PATIENTS_FAIL, payload}
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function del(payload) {
  return {type: DEL, payload};
}

export function delSuccess(payload) {
  return {type: DEL_SUCCESS, payload};
}

export function delFail(payload) {
  return {type: DEL_FAIL, payload};
}

export function setTotal2(payload) {
  return {type: SET_TOTAL2, payload};
}

export function increaseTotal2(payload) {
  return {type: INCREASE_TOTAL2, payload};
}

export function decreaseTotal2(payload) {
  return {type: DECREASE_TOTAL2, payload};
}

export function clear(payload) {
  return {type : CLEAR, payload}
}

export function checkName(payload) {
  return {type : CHECK_NAME, payload}
}

export function checkNameSuccess(payload) {
  return {type : CHECK_NAME_SUCCESS, payload}
}

export function checkNameFail(payload) {
  return {type : CHECK_NAME_FAIL, payload}
}

export function loadMore(payload) {
  return {type : LOAD_MORE, payload}
}

export function loadMoreSuccess(payload) {
  return {type : LOAD_MORE_SUCCESS, payload}
}

export function loadMoreFail(payload) {
  return {type : LOAD_MORE_FAIL, payload}
}