import React, { useEffect, useRef } from "react";
import { Network } from "vis-network";
import axios from 'axios';
import {xy} from "plotly.js/src/traces/pointcloud/attributes";
import {update} from "./taskResultViewer";

// how to use vis-network in React
// https://www.jamestharpe.com/react-visjs/

const thresholds = [4, 3, 2, 0]
const colors = ['#E6194B', '#FFE119', '#3CB44B', '#4363D8']
// const VisNetwork = ({dates, targetUrl, volThreshold, locate, setLocate}) => {
const VisNetwork = ({dates, targetUrl, volThreshold, viewerState, viewerDispatch,}) => {
  const { locate } = viewerState
  const getNodeColorBySize = size => {
    const idx = thresholds.findIndex(threshold => size > threshold)
    // TODO image type 에 정의된 color 값 가져다 쓰도록 수정
    return colors[idx]
  }
  const buildNetworkData = data => {
    // g10    = plt.scatter([], [], s=20,   marker='o', color='#4363D8', alpha=0.75)
    // g100   = plt.scatter([], [], s=320,  marker='o', color='#3CB44B', alpha=0.75)
    // g1000  = plt.scatter([], [], s=810,  marker='o', color='#FFE119', alpha=0.75)
    // g10000 = plt.scatter([], [], s=5120, marker='o', color='#E6194B', alpha=0.75)
    const xyScale = 500
    const maxX = Math.max.apply(null, data.nodes.x)
    const stepX = maxX / (dates.length - 1)
    const minY = Math.min.apply(null, data.nodes.y)
    let maxID = Math.max.apply(null, data.nodes.ids)

    const nodes = data.nodes.ids.map((id, idx) => {
      return {
        id,
        label: data.nodes.label[idx],
        volume: data.nodes.osize[idx], // original size (volume)
        margin: data.nodes.size[idx] * 3,
        x: data.nodes.x[idx] * xyScale,
        y: (1 - data.nodes.y[idx]) * xyScale,
        fixed: {x:true, y:true},
        color: getNodeColorBySize(data.nodes.size[idx]),
        com: data.nodes.center_of_mass[idx]
      }
    })
    const filteredNodes = nodes.filter(n => n.volume >= volThreshold && n.label)
    const validNodeIds = new Set(filteredNodes.map(n => n.id))

    // add date node
    dates.forEach((date, idx) => {
      filteredNodes.push({
        id: maxID + 1,
        label: date,
        // volume: data.nodes.osize[idx], // original size (volume)
        // margin: data.nodes.size[idx] * 3,
        margin: 20,
        x: idx * stepX * xyScale,
        y: -0.1 * xyScale,
        fixed: {x:true, y:true},
        chosen: false,
        shape: 'box'
        // color: ,
      })
      maxID += 1
    })


    const edges = data.links.source.map((src, idx) => {
      const tgt = data.links.target[idx]
      return {from: src, to: tgt}
    })
    const filteredEdges = edges.filter(e => validNodeIds.has(e.from) && validNodeIds.has(e.to))

    return {nodes: filteredNodes, edges: filteredEdges}
  }

  // Create a ref to provide DOM access
  const visJsRef = useRef(null);
  useEffect(() => {
    axios.get(targetUrl).then(res => {
      // Once the ref is created, we'll be able to use vis
      const options = {
        autoResize: true,
        height: "100%",
        width: "100%",
        locale: "en",
        edges: {
          arrows: { to: { enabled: true } },
          smooth: { enabled: true },
          color: '#AAAAAA',
          chosen: false,
        },
        nodes: {
          shape: "circle", // dot 도 좋은데 레이블이 밑에 달려서..
          size: 15,
        },
        interaction: { hover: true },
        physics: {
          enabled: false,
        },
      };

      const {nodes, edges} = buildNetworkData(res.data)

      const network = visJsRef.current && new Network(visJsRef.current, { nodes, edges }, options);
      // Use `network` here to configure events, etc

      // network.on("click", function (params) {
      //   console.log(params)
      // });

      network.on("selectNode", function (params) {
        if (params.nodes.length > 0) {
          const selectedNode = nodes.find(n => n.id === params.nodes[0])
          if (selectedNode && selectedNode.hasOwnProperty('com')) {
            viewerDispatch(update({
              locate: {
                ...locate,
                coordinates: selectedNode.com
              }
            }))
          }
        }
      });
    })
  }, [visJsRef, targetUrl, volThreshold]);

  // return <div ref={visJsRef} style={{height: '50vh'}}/>;
  // return <div ref={visJsRef}/>;
  return <div ref={visJsRef} style={{height: '75vh'}}/>;
};

export default VisNetwork;
