import React  from "react";
import { Slider, Space, Select } from 'antd';
import * as cornerstone from "cornerstone-core";

const {Option} = Select;

const layers_example = [
  {
    imageId: 'ct://1',
    options: {
      name: 'CT'
    }
  },
  {
    imageId: 'pet://1',
    options: {
      name: 'PET',
      opacity: 0.7,
      viewport: {
        colormap: 'hotIron',
        voi: {
          windowWidth: 30,
          windowCenter: 16
        }
      }
    }
  }
]

const option_example = {
  name: 'layer-base',
  visible: true,
  opacity: 1.0,
  viewport: {
    colormap: 'gray',
  }
}

// const colormap = cornerstone.colors.getColormap('GBM Label Colormap');
// colormap.setColorSchemeName('GBM Label Colormap')
// colormap.setNumberOfColors(4);
// colormap.insertColor(0, [0, 0, 0, 255])
// colormap.insertColor(1, [255, 0, 0, 255])
// colormap.insertColor(2, [0, 255, 0, 255])
// colormap.insertColor(3, [0, 0, 255, 255])

const colormapsList = cornerstone.colors.getColormapsList();

function OverlayOption({option, setOption, url, setAction, width, overlaid}) {
  const handleChangeSlider = (value) => {
    const newOption = {
      ...option,
      opacity: value / 100
    }
    setAction({type: 'changeOption', url:url, option: newOption})
    setOption(newOption)
  }

  function handleChangeColormap(val, SelectedOption) {
    const newOption = {
      ...option,
      viewport: {
        colormap: val,
        labelmap: val === 'GBM Label Colormap',
      }
    }
    setAction({type: 'changeOption', url:url, option: newOption})
    setOption(newOption)
  }
  // console.log(option)
  return (
    <Space direction="vertical">
      {overlaid ?
        <div style={{display: 'flex', alignItems: 'center', width: width-20}} >
          <div style={{flex: 1}}>Opacity</div>
          <div style={{marginLeft: 'auto', flex: 3}}>
            <Slider
              style={{marginLeft: 0}}
              onChange={handleChangeSlider}
              defaultValue={option.opacity * 100}
            />
          </div>
        </div>
        : null}
      <div style={{display: 'flex', alignItems: 'center', width: width-20}} >
        <div style={{flex: 1}}>Colormap</div>
          <Select
            style={{marginLeft: 'auto', flex: 3}}
            dropdownMatchSelectWidth={false}
            defaultValue={option?.viewport?.colormap ? option.viewport.colormap : 'gray'}
            onChange={handleChangeColormap}
          >
            {colormapsList.map((item) => <Option key={item.id}>{item.name}</Option>)}
          </Select>
      </div>
    </Space>
  )
}

function compareOptions(prev, next) {
  return (
    prev.option.opacity === next.option.opacity &&
    prev.option.viewport.colormap === next.option.viewport.colormap &&
    prev.width === next.width
  )
}

export const MemoizedOverlayOption = React.memo(OverlayOption, compareOptions);
export default OverlayOption;
