import { Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchHighlight } from "../context/searchHighlightContext";
import { add } from "../redux/modules/analysis";
import { load as loadDataset } from "../redux/modules/dataset";
import { msgError } from "../redux/modules/message";
import { load as loadPipleine } from "../redux/modules/pipeline";
import { dataset_base_columns } from "./datasetList";
import { pipeline_base_columns, used_types_column } from "./pipelineList";
import SearchBox from "./searchBox";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import './analysisRunModal.css'
import { created_column } from "./createdRender";

const searchOptions = [
  {value:"name", label:"Name", dataIndex : 'name'},
  {value:"desc", label:"Description", dataIndex : 'desc'},
]

export const mode = Object.freeze({PIPELINE: 'pipeline', DATASET: 'dataset'})

export function AnalysisRunModal({visible, setVisible, pathname, dataset_id, pipeline_id}) {
  const dispatch = useDispatch()
  const analysisLoading = useSelector(state => state.analysis?.loading)
  
  const fromPipelineListPage = pathname.includes('pipeline')
  const target = fromPipelineListPage ? mode.DATASET : mode.PIPELINE

  const handleOk = () => {
    if (dataset_id.current === 0) {
      return dispatch(msgError('Please select a dataset first'))
    }
    if (pipeline_id.current === 0) {
      return dispatch(msgError('Please select a pipeline first'))
    }

    dispatch(add({dataset_id : dataset_id.current, pipeline_id : pipeline_id.current}))
  }

  const handelCancel = () => {
    if (!analysisLoading) {
      dataset_id.current = 0 
      pipeline_id.current = 0
      setVisible(false)
    }
  }

  return (
    <Modal
      title={`Please select a ${target} to run`}
      closable={false}
      open={visible}
      confirmLoading={analysisLoading}
      cancelButtonProps={{disabled : analysisLoading}}
      width={'70%'}
      onOk={handleOk}
      onCancel={handelCancel}
      bodyStyle={{backgroundColor : '#152e33'}}
    >
      <ForRunAnalysisTable 
        tableTarget={target}
        dataset_id={dataset_id}
        pipeline_id={pipeline_id}
      />
    </Modal>
  )
}

export function ForRunAnalysisTable({tableTarget, dataset_id, pipeline_id}) {
  const dispatch = useDispatch()
  const analysisLoading = useSelector(state => state.analysis?.loading)
  const [paginationInfo, setPaginationInfo] = useState({page: 1, per_page: 10, category: undefined, keyword: undefined})
  const {page, per_page, category, keyword} = paginationInfo
  const {searchHighlight} = useSearchHighlight({paginationInfo, searchOptions})
  const {getTypeFromShort} = useGetImageTypeHooks()
  
  const target = tableTarget === 'pipeline' ? mode.PIPELINE : mode.DATASET
  const dataSource = useSelector(state => state[target].list)
  const total = useSelector(state => state[target].total)

  const fetch = target === mode.DATASET ? loadDataset : loadPipleine
  
  const [columns, setColumns] = useState([])

  useEffect(()=>{
    const base_columns = target === mode.DATASET ? dataset_base_columns : pipeline_base_columns
    const searched_columns = base_columns.map(column => ({
      ...column,
      render : column.render
              ? column.render
              : text => searchOptions.some(option => option.dataIndex === column.dataIndex) 
                ? searchHighlight(text, column.dataIndex)
                : text
    }))
    if (target === mode.PIPELINE) {
      searched_columns.push(used_types_column(getTypeFromShort)) 
    }
    searched_columns.push(created_column)
    setColumns(searched_columns)
  },[dataSource])

  const handleSearch = (keyword, category) => {
    dispatch(fetch({page, per_page, keyword, category}))
    const copyPaginationInfo = {...paginationInfo, keyword, category}
    setPaginationInfo(copyPaginationInfo)
  }

  const onPaginationChange = (page, per_page) => {
    dispatch(fetch({page,per_page,category,keyword}))
    const copyPaginationInfo = {...paginationInfo, page, per_page}
    setPaginationInfo(copyPaginationInfo)
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const selectRecord = selectedRows[0]
      if (target === mode.DATASET) {
        if (selectRecord.caseCount) {
          setSelectedRowKeys([selectRecord.dbid])
          dataset_id.current = selectRecord.dbid
          return ;
        }
      }
      else {
        if (selectRecord.valid) {
          setSelectedRowKeys([selectRecord.id])
          pipeline_id.current = selectRecord.id
          return ;
        }
      }
    },
    getCheckboxProps: (record) => ({
      disabled: target === mode.DATASET ? !(record.caseCount > 0) : !record.valid
    }),
  }

  const rowKey = target === mode.DATASET ? 'dbid' : 'id'

  const onRowClick = record => {
    rowSelection.onChange(null,[record])
  }

  return (
    <>
      <SearchBox 
        style={{display: "inline-block", float: 'right', width:"50%", minWidth: '400px'}}
        options={searchOptions}
        onSearch={handleSearch}
        loading={analysisLoading}
      />
      <Table 
        columns={columns}
        dataSource={dataSource}
        onRow={(record, index)=> ({
          onClick : e => onRowClick(record)
        })}
        rowClassName={record => {
          const invalid = (target === mode.DATASET && record.caseCount === 0 )
                          || (target === mode.PIPELINE && !record.valid)
          return invalid ? 'disable-select' : null
          }
        }
        rowSelection={rowSelection}
        loading={analysisLoading}
        rowKey={rowKey}
        size='small'
        pagination={{
          current : page,
          pageSize : per_page,
          total : total,
          showSizeChanger : false,
          onChange : onPaginationChange
        }}
      />
    </>
  )
}