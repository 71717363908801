import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

export const useDuplicateCheck = ({loading, form, fields=[]}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const key = location.state?.key
  const results = fields.map(field => field.result)
  const errors = fields.map(field => field.error)

  const lastMsg = {}
  fields.forEach(field => {
    const isStringFieldName = typeof field.fieldName === 'string'
    const fieldName = isStringFieldName ?
      field.fieldName :
      field.fieldName.join('.')
    
    const checkFieldName = isStringFieldName ? 
      field.fieldName :
      field.fieldName.at(-1)
    
    lastMsg[fieldName] = loading ? `Please wait for ${checkFieldName} check...` : ""
  })

  const lastResult = useRef(results)
  const lastError = useRef(errors)
  
  useEffect(()=>{
    const sameResult = results.every((r, ri) => r === lastResult.current[ri])
    const sameError = errors.every((e, ei) => e === lastError.current[ei])
    if (sameResult && sameError) {
      return
    }
    
    lastResult.current = results
    lastError.current = errors

    fields.forEach((field,index)=> {
      if (field.error) {
        const isStringFieldName = typeof field.fieldName === 'string'
        const name = isStringFieldName ? [field.fieldName] : field.fieldName
        form.setFields([{name, errors : [field.error]}])
      }
      else {
        const value = form.getFieldValue(field.fieldName)
        if (value) {
          form.validateFields([field.fieldName])
        }
      }
    })
  },[results, errors])

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields.some(f => f.validating)) {
      return
    }
    
    for (const changedField of changedFields) {
      if (typeof changedField ==='object') {
        const isStringItemName = typeof changedField.name === 'string'
        const itemName = isStringItemName ? 
          changedField.name : 
          changedField.name.join('.')
        const itemErrors = changedField.errors
        const itemValue = changedField.value
        for (const field of fields) {
          const isStringFieldName = typeof field.fieldName === 'string'
          const fieldName = isStringFieldName ? 
            field.fieldName : 
            field.fieldName.join('.')
          
          if (
            itemErrors.length === 1 && 
            itemErrors[0] === lastMsg[fieldName] && 
            itemName === fieldName
          ) {  
            return dispatch(field.action({value : itemValue, id : key}))
          }
        } 
      }
    }
  }

  const lastMatchValidator = changeFieldName => {
    const isStringChangeFieldName = typeof changeFieldName === 'string'
    const changeFieldNameString = isStringChangeFieldName ? 
      changeFieldName : 
      changeFieldName.join('.')
    const field = fields.find(field => {
      const isStringFieldName = typeof field.fieldName === 'string'
      const fieldName = isStringFieldName ? 
        field.fieldName : 
        field.fieldName.join('.')
      
        return fieldName === changeFieldNameString
    })
    
    return ({
      validator(_, value) {
        if (!value || field.result === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(lastMsg[changeFieldNameString]));
      },
    })
  }

  return { onFieldsChange, lastMatchValidator }
}