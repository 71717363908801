import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import {updateProgressByURL} from "./taskResultViewer";
import CornerstoneElementPlain from "./cornerstoneElementPlain";
import {buildDicomStack} from "../lib/UtilDicomParse";
import * as cornerstone from "cornerstone-core";
import {computeAutoVoi, formatWindowLevelString} from "../lib/cornerstoneUtils";
import * as cornerstoneTools from "cornerstone-tools";

function DicomReportViewer({name, url, width, height, parentLoading, handleLoadEnd, viewerDispatch, reset}) {
  const query = new URLSearchParams(useLocation().search);

  // const [image, setImage] = useState()
  // const [stack, setStack] = useState({imageIds: [], currentImageIdIndex: 0});
  // const [loading, setLoading] = useState(true);
  const dicomElement = useRef()
  const [viewport, setViewport] = useState(null)
  const [stack, setStack] = useState()

  const loadDicom = async () => {
    let image = null
    const dicomMultiple = Array.isArray(url)
    try {
      if (dicomMultiple ) {
        const stack = await buildDicomStack(url)
        setStack(stack)
        stack.currentImageIdIndex = 0
        cornerstoneTools.addStackStateManager(dicomElement.current, ["stack"]);
        cornerstoneTools.addToolState(dicomElement.current, "stack", stack);
        image = await cornerstone.loadAndCacheImage(stack.imageIds[stack.currentImageIdIndex])
      }
      else {
        let imageId = `wadouri:${url}`
        image = await cornerstone.loadAndCacheImage(imageId)
      }
    } catch (e) {
      if (dicomMultiple ) {
        url.forEach(item => {
          viewerDispatch(updateProgressByURL(item, {state: 'failed'}))
          handleLoadEnd(item, 'failed')
        })
      }
      else {
        viewerDispatch(updateProgressByURL(url, {state: 'failed'}))
        handleLoadEnd(url, 'failed')
      }
      return
    }

    const viewport = cornerstone.getDefaultViewportForImage(dicomElement.current, image);
    setViewport(viewport)
    cornerstone.displayImage(dicomElement.current, image, viewport);
    addCornerstoneTools(dicomMultiple )
    handleResize()

    if (dicomMultiple ) {
      url.forEach(item => {
        viewerDispatch(updateProgressByURL(item, {progress: 100}))
        viewerDispatch(updateProgressByURL(item, {state: 'success'}))
        handleLoadEnd(item, 'success')
      })
    }
    else {
      viewerDispatch(updateProgressByURL(url, {progress: 100}))
      viewerDispatch(updateProgressByURL(url, {state: 'success'}))
      handleLoadEnd(url, 'success')

    }
  }

  const addCornerstoneTools = (dicomMultiple) => {
    // add basic tools
    // 우클릭은 확대, 축소
    const zoomTool = cornerstoneTools.ZoomTool;
    cornerstoneTools.addToolForElement(dicomElement.current, zoomTool);
    cornerstoneTools.setToolActiveForElement(dicomElement.current, 'Zoom', { mouseButtonMask: 2});
    // 휠클릭은 panning
    const panTool = cornerstoneTools.PanTool;
    cornerstoneTools.addToolForElement(dicomElement.current, panTool);
    cornerstoneTools.setToolActiveForElement(dicomElement.current, 'Pan', { mouseButtonMask: 4});
    if (dicomMultiple) {
      const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
      cornerstoneTools.addTool(StackScrollMouseWheelTool)
      cornerstoneTools.setToolActive('StackScrollMouseWheel', {
        configuration: {
          // loop: true
          allowSkipping: false
        }
      })
    }
    else {
      // 휠인데 mouseButtonMask 는 뭐지?
      const zoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
      cornerstoneTools.addToolForElement(dicomElement.current, zoomMouseWheelTool);
      cornerstoneTools.setToolActiveForElement(dicomElement.current, 'ZoomMouseWheel', { mouseButtonMask: 1});
    }
  }

  const onImageRendered = () => {
    if (dicomElement.current) {
      const viewport = cornerstone.getViewport(dicomElement.current);
      setViewport(viewport)
    }
  }

  useEffect(() => {
    cornerstone.enable(dicomElement.current);
    window.addEventListener('resize', handleResize);
    dicomElement.current.addEventListener("cornerstonenewimage", handleNewImage);
    dicomElement.current.addEventListener(cornerstone.EVENTS.IMAGE_RENDERED, onImageRendered);

    if ((Array.isArray(url) && url.length > 0) || url) {
      loadDicom()
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (dicomElement.current) {
        dicomElement.current.removeEventListener("cornerstonenewimage", handleNewImage);
        dicomElement.current.removeEventListener(cornerstone.EVENTS.IMAGE_RENDERED, onImageRendered);
        cornerstone.disable(dicomElement.current);
      }
    }
  }, [url])

  useEffect(() => {
    if (reset?.doReset) {
      const enabledElement = cornerstone.getEnabledElement(dicomElement.current)
      const defaultViewport = cornerstone.getDefaultViewport(enabledElement.canvas, enabledElement.image)
      // const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(this.state.imageId)
      // const viewport = {};
      // computeAutoVoi(niftiMetaData, viewport)
      cornerstone.setViewport(dicomElement.current, {
        ...viewport,
        translation: defaultViewport.translation, // pan
        scale: defaultViewport.scale, // zoom
        voi: defaultViewport.voi // WW/WC
      })
    }
  }, [reset])

  useEffect(() => {
    handleResize()
  }, [width]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNewImage = e => {
    const enabledElement = cornerstone.getEnabledElement(dicomElement.current);
    const stackToolData = cornerstoneTools.getToolState(dicomElement.current, "stack");
    if (stackToolData) {
      const stackData = stackToolData.data[0];
      setStack(stackData)
    }
  }

  const handleResize = () => {
    if (dicomElement.current) {
      cornerstone.resize(dicomElement.current, true);
    }
  };

  return (
    <div style={{width: width, height: height}}>
      <div
          className="viewportElement"
          style={{
            width: '100%',
            height: '100%',
            position: "relative",
            color: "white"
          }}
          ref={dicomElement}
      >
        <div style={{top: "5px", left: "5px", position: "absolute", color: "white"}}>
          {name}
        </div>
        <div style={{bottom: "5px", left: "5px", position: "absolute", color: "white"}}>
          Zoom: {viewport == null ? null : viewport.scale.toFixed(2)}
        </div>
        <div style={{top: "5px", right: "5px", position: "absolute", color: "white"}}>
          {stack == null ? null : stack.currentImageIdIndex + 1} /{" "}
          {stack == null ? null : stack.imageIds.length}
        </div>
        <div style={{bottom: "5px", right: "5px", position: "absolute", color: "white"}}>
          WW/WC: {viewport == null ? null : formatWindowLevelString(viewport.voi.windowWidth)} /{" "}
          {viewport == null ? null : formatWindowLevelString(viewport.voi.windowCenter)}
        </div>
      </div>
    </div>
  );
}

export default DicomReportViewer;

