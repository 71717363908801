import { put, take, select } from "redux-saga/effects";
import {updateTask} from "../../../redux/modules/longTask";
import {END, eventChannel} from "redux-saga";

export function* progressWatcher(chan, taskId, totalSize, totalCount) {
  let updated = new Date().getTime()
  while (true) {
    const { size, count, treeData, compressionRate } = yield take(chan);

    let desc = ""

    if (count === totalCount) {
      desc = (compressionRate === undefined
        ? "Checking upload files..."
        : `Compressing files... ${compressionRate}%`)
    } else {
      desc = `${count}/${totalCount}`;
    }

    const payload = {
      progress: Math.floor((size / totalSize) * 100),
      taskId,
      desc,
      size,
      count,
      totalSize,
      totalCount
    }

    if (treeData) { // treeData ~> for upload Task
      payload.treeData = treeData
    }

    if (count === totalCount && compressionRate === undefined) {
      yield put(updateTask(payload));
      continue;
    }
    
    const now = new Date().getTime()

    // 안보고 있을 때, 20초 [20,000ms]마다 업데이트 진행
    if (now - updated > 20000 || count === 0) {
      updated = new Date().getTime()
      yield put(updateTask(payload));
      continue;
    }

    const drawerOpen = yield select(state => state.longTask.drawerOpen);

    // progress drawer 열려있고 보고 있을 때, 시야에 들어와 있는 카드들에 대해서 0.1초 [100ms]마다 업데이트 진행 
    if (drawerOpen && (now - updated > 100)) {
      const task = yield select((state) =>
        state.longTask.tasks.filter(task => task.taskId === taskId)[0]);
      const index = task.index;
      const renderInfo = yield select(state => state.longTask.renderInfo);
      
      if (
        index >= renderInfo.overscanStartIndex &&
        index <= renderInfo.overscanStopIndex
      ) {
        updated = new Date().getTime()
        yield put(updateTask(payload));
        continue;
      }
    }
  }
}

export async function makeChannel({func, totalCount, ...rest }) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const progressCb = data => {
    const {count, compressionRate} = data
    emit(data);
    if ((compressionRate === undefined || compressionRate === 100) && totalCount === count) emit(END);
  };
  const promise = func({
    progressCb,
    totalCount,
    ...rest,
  });
  return [promise, chan];
}

export function makeTaskData({taskId, title, type, treeData, label, uploadDataIds}){

  let desc
  if (type === 'upload') {
    desc = "Ready to upload..."
  } else if (type === 'download') {
    desc = 'data loading...'
  }

  return {
    taskId,
    title,
    type,
    desc,
    treeData,
    label,
    treeOpen : true,
    ended: false,
    progress: 0,
    size: 0,
    totalSize: 0,
    count: 0,
    totalCount: 0,
    index : 0,
    uploadDataIds
  }
}