import { Tooltip } from "antd"
import { useEffect, useRef, useState } from "react"


const IMAGETYPE_ELLIPSIS_CRITERION = 220

const EllipsisTooltipForImagetypes = ({children, title, placement, color}) => {
  const [open, setOpen] = useState(false)
  const [ellipsisStatus, setEllipsisStatus] = useState(false)
  const tooltipRef = useRef()
  const [width, setWidth] = useState(IMAGETYPE_ELLIPSIS_CRITERION)
  
  const onOpenChange = open => {
    const clientHeight = tooltipRef.current.clientHeight
    const scrollHeight = tooltipRef.current.scrollHeight
    if (clientHeight < scrollHeight) {
      setOpen(open)
    }
  }

  const lineHeight = 30 // px

  useEffect(()=>{
    const clientHeight = tooltipRef.current.clientHeight
    const scrollHeight = tooltipRef.current.scrollHeight
    if (clientHeight < scrollHeight) {
      setEllipsisStatus(true)
    }
    else {
      setEllipsisStatus(false)
    }
  },[children])

  useEffect(()=>{
    const tagElements = tooltipRef.current?.children?.[0]?.children || []
    const margin = 8;
    let sumWidth = 0;
    [...tagElements].forEach((el, _, array) => {
      const firstElement = array[0]
      if (firstElement.getBoundingClientRect().top === el.getBoundingClientRect().top) {
        const width = el.getBoundingClientRect().width + margin
        sumWidth += width
      }
    })
    setWidth(sumWidth)
  },[children])

  const threeDot = '\u22ef'
  const marginLeft = width - IMAGETYPE_ELLIPSIS_CRITERION
  
  return (
    <Tooltip
      open={open}
      onOpenChange={onOpenChange}
      title={
        <div style={{textAlign: 'center', width: IMAGETYPE_ELLIPSIS_CRITERION}}>
          {title}
        </div>}
      placement={placement}
      color={color}
      zIndex={10000}
      getPopupContainer={triggerNode => triggerNode}
    >
      <div
        ref={tooltipRef}
        style={{
          maxHeight: `${lineHeight}px`, 
          overflow: 'hidden', 
          textOverflow: 'ellipsis',
          display: 'inline-block',
          width: IMAGETYPE_ELLIPSIS_CRITERION
        }}
      >
        {children}
      </div>
      {ellipsisStatus ? 
        <div 
          style={{
            display: 'inline-block', 
            verticalAlign: 'top', 
            marginTop: '5px', 
            marginLeft: `${marginLeft}px`
          }}
        >
          {threeDot}
        </div> : 
        null
      }
    </Tooltip>
  )
}

export default EllipsisTooltipForImagetypes