import {all, call, debounce, fork, put, takeLeading, getContext} from "redux-saga/effects";
import * as actions from "../../redux/modules/dataset";
import axios from "axios";
import {loadSuccess, loadFail, addSuccess, addFail, getSuccess, getFail, updateSuccess, updateFail, 
  delSuccess, delFail, checkNameSuccess, checkNameFail, getPagedPatientsSuccess, getPagedPatientsFail,
  loadMoreSuccess, loadMoreFail
} from "../../redux/modules/dataset";
import { msgError, msgSuccess } from "../../redux/modules/message";
import {getCookie} from "../../lib/cookie";

function loadApi({ page, per_page, category, keyword }) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/listDataset', {search: {category, keyword}, pagination:{page, per_page}}, 
  { withCredentials: true });
}

function getApi(id, includes, search, pagination, pagination2, hints) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getDataset', {id, includes, search, pagination, pagination2, hints}, 
  { withCredentials: true });
}

function getPagedApi(id, includes, search, pagination, pagination2, hints) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getDatasetIncludes', {id, includes, search, pagination, pagination2, hints}, 
  { withCredentials: true });
}

function addApi(name, desc, patients) {
  return axios.post('/api/addDataset', {name, desc, patients},
  { withCredentials: true });
}

function updateApi(id, name, desc, patients) {
  return axios.post('/api/updateDataset', {id, name, desc, patients},
  { withCredentials: true });
}

function delApi({ keys, page, per_page, category, keyword }) {
  return axios.post('/api/deleteDataset', {ids : keys, search: {category, keyword}, pagination:{page, per_page}}, 
  { withCredentials: true });
}

function checkNameApi({value:name, id}) {
  return axios.post('/api/check-DatasetName', {name, id}, 
  { withCredentials: true })
}

function* loadSaga({payload}) {
  try {
    const { page, per_page, category, keyword } = payload;
    const res = yield loadApi({ page, per_page, category, keyword })
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* loadMoreSaga({payload}) {
  try {
    const { page, per_page } = payload;
    const res = yield loadApi({ page, per_page })
    yield put(loadMoreSuccess(res.data));
  } catch (error) {
    yield put(loadMoreFail());
    yield put(msgError(error))
  }
}

function* addSaga({payload}) {
  try {
    const patients = payload.includes;
    const { name, desc } = payload.values;
    const res = yield call(addApi, name, desc, patients);
    yield put(addSuccess(res.data));
    if (!payload.stay) {
      const history = yield getContext('history');
      history.goBack();
    }
  } catch (error) {
    yield put(addFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  try {
    const { id, includes, search, pagination, pagination2, hints } = payload;
    // const config = yield select((state) => state.auth.user.config)
    const res = yield call(getApi, id, includes, search, pagination, pagination2, hints);
    yield put(getSuccess(res.data));
  } catch (error) {
    yield put(getFail());
    yield put(msgError(error))
  }
}

function* getPagedSaga({payload}) {
  try {
    const { id, includes, search, pagination, pagination2, hints } = payload;
    const res = yield call(getPagedApi, id, includes, search, pagination, pagination2, hints);
    yield put(getPagedPatientsSuccess(res.data));
  } catch (error) {
    yield put(getPagedPatientsFail());
    yield put(msgError(error))
  }
}

function* updateSaga({payload}) {
  try {
    const patients = payload.includes;
    const { id, name, desc } = payload.values;
    const res = yield call(updateApi, id, name, desc, patients);
    yield put(updateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
    yield put(msgSuccess(`Dataset '${name}' updated successfully!`))
  } catch (error) {
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function* delSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword } = payload
    const res = yield delApi({keys, page, per_page, category, keyword})
    yield put(delSuccess(res.data));
  } catch (error) {
    yield put(delFail());
    yield put(msgError(error))
  }
}

function* checkNameSaga({payload}) {
  try {
    const {value, id} = payload
    const res = yield checkNameApi({value, id})
    const checkedName = res.data
    yield put(checkNameSuccess(checkedName))
  } catch (error) {
    yield put(checkNameFail(error.response.data.msg[0]))
  }
}

function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
  yield takeLeading(actions.LOAD_MORE, loadMoreSaga)
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga);
}

function* watchGet() {
  yield takeLeading(actions.GET, getSaga);
  yield takeLeading(actions.GET_PAGED_PATIENTS,getPagedSaga)
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}

function* watchDel() {
  yield takeLeading(actions.DEL, delSaga);
}

function* watchCheck() {
  yield debounce(500, actions.CHECK_NAME, checkNameSaga)
}

export default function* datasetSaga() {
  yield all([
    fork(watchLoad),
    fork(watchAdd),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
    fork(watchCheck)
  ]);
}
