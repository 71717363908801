import React, {useEffect, useState} from "react";
import { Select, Divider, Input, Pagination, Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {load, add, checkName} from '../redux/modules/dataset'
import { msgError } from "../redux/modules/message";

const { Option } = Select;

let index = 0;
//<DatasetSelect onChange={handleSelectChange} dropdownMatchSelectWidth={false}
//                            defaultValue={"Undefined"} value={record.type}
function DatasetSelect({onChange, dropdownMatchSelectWidth, defaultValue, row_index, loading}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const checkLoading = useSelector(state => state.dataset?.check?.loading)
  const checkedName = useSelector(state => state.dataset?.check?.name)
  const checkedNameError = useSelector(state => state.dataset?.check?.nameError)

  const onNameChange = event => {
    setName(event.target.value)
    dispatch(checkName({value : event.target.value, id : undefined}))
  };
  
  const datasets = useSelector(state => state.dataset.list);
  const total = useSelector(state => state.dataset.total);

  
  const addDataset = () => {
    if (name.length === 0){
      return dispatch(msgError('Please enter the dataset name'))
    } 
    else if (checkedNameError){
      return dispatch(msgError(checkedNameError))
    }
    else if (checkedName === name) {
      dispatch(add({values: {name, 'desc':''}, stay: true}));
      setItems([...items, name || `New item ${index++}`]);
      setName('');
    } 
    
  };

  const onChangePage = (page, pageSize) => {
    dispatch(load({page, pageSize}));
  }

  useEffect(() => {
    dispatch(load({}));
  }, [])

  return (
    <Select
      style={{ width: '100%'}}
      placeholder="Undefined"
      onChange={onChange}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      defaultValue={defaultValue}
      disabled={loading}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
            <Input 
              style={{ 
                flex: 'auto', 
                boxShadow : "0px 0px 0px 1px #0D6E6D"
              }} 
              value={name} 
              onChange={onNameChange} 
              placeholder={'Please enter the dataset name to add'}  
            />
            <Tooltip title={checkedNameError} color={"#0D6E6D"}>
              <Button
                type='text'
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={addDataset}
                disabled={checkLoading || !!checkedNameError || !(!!name)}
              >
                <PlusOutlined /> Create
              </Button>
            </Tooltip>
            <Pagination simple onChange={onChangePage} total={total}/>
          </div>
        </div>
      )}
    >
      {datasets.map((item, oidx) => (
        <Option key={item.dbid} row_index={row_index} option_index={oidx}>{item.name}</Option>
      ))}
    </Select>
  );
}
export default DatasetSelect;

