import {all, call, fork, put, getContext, takeLeading} from "redux-saga/effects";
import * as actions from "../../redux/modules/blob";
import axios from "axios";
import {loadSuccess, loadFail, addSuccess, addFail, getSuccess, getFail, updateSuccess, updateFail, delSuccess, delFail} from "../../redux/modules/blob";
import {getCookie} from "../../lib/cookie";
import { msgError } from "../../redux/modules/message";

function loadApi(page, per_page) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/listBlob', {pagination:{page, per_page}},
    {withCredentials: true});
}

function getApi(id) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getBlob', {id}, {
    withCredentials: true, // For transition cookie
  });
}

function addApi(name, desc, patients) {
  return axios.post('/api/addBlob', {name, desc, patients}, {
    withCredentials: true, // For transition cookie
  });
}

function updateApi(id, name, desc, patients) {
  return axios.post('/api/updateBlob', {id, name, desc, patients}, {
    withCredentials: true, // For transition cookie
  });
}

function delApi(id) {
  return axios.post('/api/deleteBlob', {id}, {
    withCredentials: true, // For transition cookie
  });
}

function* loadSaga({payload}) {
  try {
    let page = 1;
    let pageSize = 10;
    if (payload.hasOwnProperty('page')) {
      page = payload.page;
      pageSize = payload.pageSize;
    }
    const res = yield call(loadApi, page, pageSize);
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* addSaga({payload}) {
  try {
    const patients = payload.includes;
    const { name, desc } = payload.values;
    const res = yield call(addApi, name, desc, patients);
    yield put(addSuccess(res.data));
    if (!payload.stopRoute) {
      const history = yield getContext('history');
      history.goBack();
    }
  } catch (error) {
    yield put(addFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  try {
    const { id } = payload;
    const res = yield call(getApi, id);
    yield put(getSuccess(res.data));
  } catch (error) {
    yield put(getFail());
    yield put(msgError(error))
  }
}

function* updateSaga({payload}) {
  try {
    const patients = payload.includes;
    const { id, name, desc } = payload.values;
    const res = yield call(updateApi, id, name, desc, patients);
    yield put(updateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
  } catch (error) {
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function* delSaga({payload}) {
  try {
    const res = yield call(delApi, payload.id);
    yield put(delSuccess(res.data));
  } catch (error) {
    yield put(delFail());
    yield put(msgError(error))
  }
}

function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga);
}

function* watchGet() {
  yield takeLeading(actions.GET, getSaga);
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}

function* watchDel() {
  yield takeLeading(actions.DEL, delSaga);
}

export default function* blobSaga() {
  yield all([
    fork(watchLoad),
    fork(watchAdd),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
  ]);
}
