import cornerstoneTools from 'cornerstone-tools';
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
// import {convertToVector3} from "../cornerstoneUtils";

const BaseTool = cornerstoneTools.importInternal('base/BaseTool')
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext')
const draw = cornerstoneTools.importInternal('drawing/draw')
const drawLine = cornerstoneTools.importInternal('drawing/drawLine')
const waitForEnabledElementImageToLoad = cornerstoneTools.importInternal('util/waitForEnabledElementImageToLoad')

const projectPatientPointToImagePlane = cornerstoneTools.importInternal('util/projectPatientPointToImagePlane')
const imagePointToPatientPoint = cornerstoneTools.importInternal('util/imagePointToPatientPoint')
const planePlaneIntersection = cornerstoneTools.importInternal('util/planePlaneIntersection')
const angleBetweenPoints = cornerstoneTools.importInternal('util/angleBetweenPoints')
const convertToVector3 = cornerstoneTools.importInternal('util/convertToVector3')
const getLogger = cornerstoneTools.importInternal('util/getLogger')
const logger = getLogger('tools:ReferenceLinesTool');
// import { getToolState } from '../stateManagement/toolState.js';

export const dirColors = alpha => ({
  x: `rgba(56,183,255, ${alpha})`, // sagittal
  y: `rgba(166,255,128, ${alpha})`, // coronal
  z: `rgba(253,117,227, ${alpha})`, // axial
})

/**
 * When enabled, this tool will display references lines for each source
 * enabledElement in the provided synchronizer. This tool can also be configured
 * to use a custom renderer for alternative reference line rendering behavior
 *
 * TODO: Need to watch for configuration changes to update ToolState
 * TODO:
 *
 * @export @public @class
 * @name MyReferenceLinesTool
 * @classdesc Tool for displaying reference lines of other enabledElements
 * @extends Tools.Base.BaseTool
 */
export default class MyReferenceLinesTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'MyReferenceLines',
      mixins: ['enabledOrDisabledBinaryTool'],
      configuration: {
        renderer: renderMyActiveReferenceLines,
      },
    };

    super(props, defaultProps);

    this.renderer = null;
    this.synchronizationContext = null;
  }

  async enabledCallback(element, { synchronizationContext } = {}) {
    const renderer = this.configuration.renderer;
    const enabledElement = await waitForEnabledElementImageToLoad(element);

    if (!enabledElement || !renderer || !synchronizationContext) {
      // TODO: Unable to add tool state, image never loaded.
      // Should we `setToolDisabledForElement` here?
      logger.warn(
        `Unable to enable ${this.name}. Exiting enable callback. Tool will be enabled, but will not render.`
      );

      return;
    }
    this.renderer = renderer;
    this.synchronizationContext = synchronizationContext;

    this.forceImageUpdate(element);
  }

  disabledCallback(element) {
    this.forceImageUpdate(element);
  }

  forceImageUpdate(element) {
    const enabledElement = cornerstone.getEnabledElement(element);

    if (enabledElement.image) {
      cornerstone.updateImage(element);
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail;

    // No renderer or synch context? Adios
    if (!this.renderer || !this.synchronizationContext) {
      return;
    }

    const context = getNewContext(eventData.canvasContext.canvas);
    cornerstone.setToPixelCoordinateSystem(eventData.enabledElement, context);

    const targetStackToolState = cornerstoneTools.getToolState(evt.currentTarget, 'stack');
    const targetStackData = targetStackToolState?.data?.[0]

    // Get the enabled elements associated with this synchronization context and draw them
    const enabledElements = this.synchronizationContext.getSourceElements();
    const targetRowIdx = evt.currentTarget.className.split(' ')[2]
    const filteredElements = enabledElements.filter(element => {
      const rowIdx = element.className.split(' ')[2]
      if (rowIdx === targetRowIdx) {
        return true
      }
      else {
        return false
      }
    })
    this.renderer(
      context,
      eventData,
      evt.currentTarget,
      filteredElements,
    )
  }
}

function calculateReferenceLine(targetImagePlane, referenceImagePlane) {
  const points = planePlaneIntersection(targetImagePlane, referenceImagePlane);
  if (!points) {
    return;
  }

  return {
    start: projectPatientPointToImagePlane(points.start, targetImagePlane),
    end: projectPatientPointToImagePlane(points.end, targetImagePlane),
    // points,
  };
}
// import calculateReferenceLine from './calculateReferenceLine.js';
// import toolColors from './../../stateManagement/toolColors.js';
// import convertToVector3 from './../../util/convertToVector3.js';
// import { draw, drawLine } from './../../drawing/index.js';

/**
 * Renders the active reference line.
 *
 * @export @public @method
 * @name renderActiveReferenceLine
 * @param  {Object} context        The canvas context.
 * @param  {Object} eventData      The data associated with the event.
 * @param  {HTMLElement} targetElement    The element on which to render the reference line.
 * @param  {Array.<HTMLELement>} referenceElements The elements referenced by the line.
 * @returns {void}
 */
const renderMyActiveReferenceLines = (context, eventData, targetElement, refElements) => {
  // Make sure the images are actually loaded for the target and reference
  // Make sure the target and reference actually have image plane metadata
  const targetCSElement = cornerstone.getEnabledElement(targetElement)
  const targetImage = targetCSElement.image;
  const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', targetImage.imageId);
  const targetStackToolState = cornerstoneTools.getToolState(targetElement, 'stack');
  const targetStackData = targetStackToolState?.data?.[0]
  if (
    !targetImage ||
    !targetStackData ||
    !targetImagePlane ||
    !targetImagePlane.rowCosines ||
    !targetImagePlane.columnCosines ||
    !targetImagePlane.imagePositionPatient
  ) {
    return;
  }

  targetImagePlane.rowCosines = convertToVector3(targetImagePlane.rowCosines);
  targetImagePlane.columnCosines = convertToVector3(targetImagePlane.columnCosines);
  targetImagePlane.imagePositionPatient = convertToVector3(targetImagePlane.imagePositionPatient);
  const targetNormal = targetImagePlane.rowCosines.clone().cross(targetImagePlane.columnCosines);

  const refLines = refElements.map(refElement => {
    const refImage = cornerstone.getEnabledElement(refElement).image;
    if (!refImage) { return; }

    const refImagePlane = cornerstone.metaData.get('imagePlaneModule', refImage.imageId);
    const refStackToolState = cornerstoneTools.getToolState(refElement, 'stack');
    const refStackData = refStackToolState?.data?.[0]
    if (
      !refStackData ||
      !refImagePlane ||
      !refImagePlane.rowCosines ||
      !refImagePlane.columnCosines ||
      !refImagePlane.imagePositionPatient
    ) {
      return;
    }

    // The image planes must be in the same frame of ref
    if (targetImagePlane.frameOfReferenceUID !== refImagePlane.frameOfReferenceUID) {
      return;
    }

    refImagePlane.rowCosines = convertToVector3(refImagePlane.rowCosines);
    refImagePlane.columnCosines = convertToVector3(refImagePlane.columnCosines);
    refImagePlane.imagePositionPatient = convertToVector3(refImagePlane.imagePositionPatient);

    // The image plane normals must be > 30 degrees apart
    const refNormal = refImagePlane.rowCosines.clone().cross(refImagePlane.columnCosines);

    let angleInRadians = targetNormal.angleTo(refNormal);
    angleInRadians = Math.abs(angleInRadians);
    if (angleInRadians < 0.5) {
      // 0.5 radians = ~30 degrees
      return;
    }

    return {dir: refStackData.dir, coord: calculateReferenceLine(targetImagePlane, refImagePlane)}
  }).filter(e => e)

  if (refLines.some(refLine => !refLine.coord || !refLine.coord.start || !refLine.coord.end)) {
    return;
  }

  // TODO 나중에 사용자 옵션 연결 ㅠ
  const alpha = 0.75 // 0.1 ~ 1.0
  const lineWidth = 1.0 // 0.5 ~ ?
  // const lineDash = [5, 10, 15, 20]

  // settings shared
  const drawLineOptions = {
    lineWidth: lineWidth,
    // fillStyle:
    // lineDash: lineDash
    // shouldDrawLines = true,
  }

  if (refLines.length === 1) {
    // draw a refernce line
    const referenceLine = refLines[0].coord
    if (referenceLine) {
      // Draw the referenceLines
      drawLineOptions.color = dirColors(alpha)[refLines[0].dir];
      context.setTransform(1, 0, 0, 1, 0, 0);
      draw(context, context => {
        drawLine(
          context,
          eventData.element,
          referenceLine.start,
          referenceLine.end,
          drawLineOptions
        );
      });
    }
  }
  else {
    // draw crosshair
    const xs = []
    const ys = []
    refLines.forEach(refLine => {
      xs.push(refLine.coord.start.x)
      xs.push(refLine.coord.end.x)
      ys.push(refLine.coord.start.y)
      ys.push(refLine.coord.end.y)
    })
    xs.sort((a, b) => a-b)
    ys.sort((a, b) => a-b)
    const xmin = xs.at( 0)
    const xmax = xs.at(-1)
    const ymin = ys.at( 0)
    const ymax = ys.at(-1)
    const xdist = xmax - xmin
    const ydist = ymax - ymin
    // const dist = Math.max(xdist, ydist)
    // const gap = dist * 0.05

    refLines.forEach(refLine => {
      drawLineOptions.color = dirColors(alpha)[refLine.dir];

      const xdiff = Math.abs(refLine.coord.start.x - refLine.coord.end.x)
      const ydiff = Math.abs(refLine.coord.start.y - refLine.coord.end.y)

      const start = {x: refLine.coord.start.x, y: refLine.coord.start.y}
      const end = {x: refLine.coord.end.x, y: refLine.coord.end.y}

      const start_mid_test = {x: xmin + xdist * 0.5, y: ymin + ydist * 0.5}
      const mid_end_test = {x: xmin + xdist * 0.5, y: ymin + ydist * 0.5}
      const start_mid = {x: xs.at(1), y: ys.at(1)}
      const mid_end = {x: xs.at(1), y: ys.at(1)}
      if (xdiff > ydiff) {
        // const dist = Math.max(xdist, ydist)
        const gap = xdist * 0.05
        start_mid.x -= gap
        mid_end.x += gap
      }
      else {
        const gap = ydist * 0.05
        start_mid.y -= gap
        mid_end.y += gap
      }

      // Draw the referenceLines
      context.setTransform(1, 0, 0, 1, 0, 0);
      draw(context, context => {
        drawLine(
          context,
          eventData.element,
          start,
          start_mid,
          drawLineOptions
        );
        drawLine(
          context,
          eventData.element,
          mid_end,
          end,
          drawLineOptions
        );
      });
    })
  }
}
