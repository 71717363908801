import { all, fork, put, throttle, call, spawn } from "redux-saga/effects";
import * as actions from '../../../redux/modules/longTask'
import {addTask, updateDrawerOpen, taskSuccess, taskFail, 
  downloadPatientsSuccess, downloadPatientsFail, updateTask,
  downloadResultsSuccess, downloadResultsFail,
 } from "../../../redux/modules/longTask";
import {makeChannel, makeTaskData, progressWatcher} from "./lib";
import axios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { msgError, msgSuccess, buildErrorMessage } from "../../../redux/modules/message";
import { uid } from "../../../lib/UtilDicomParse";

function getDownloadPatientsPresignedApi(patients) {
  return axios.post("/api/getDownloadPatientsPresigned", {patients}, { withCredentials: true });
}

function getDownloadResultsPresignedApi(keys) {
  return axios.post('/api/getDownloadResultsPresigned', {keys}, { withCredentials: true })
}

async function patientsDownload({totalCount, progressCb, zipName, patients, treeData, totalSize}) {
  return new Promise(async (resolve, reject) => {
    const zip = new JSZip();
    
    const data = {
      size: 0,
      count: 0,
      treeData,
      compressionRate: 0
    }

    let filename = "" // for error message

    const DATA_TYPE = Object.freeze({DICOM: 0, NIFTI: 1})

    progressCb(data);
    
    try {
      for (const [pi, patient] of patients.entries()) {
        
        zip.folder(patient.folderName) // make folder
        
        for (const [si, study] of patient.studies.entries()) {
          
          zip.folder(`${patient.folderName}/${study.folderName}/DICOM`)
          zip.folder(`${patient.folderName}/${study.folderName}/NIfTI`)
          
          for (const [sri, series] of study.series_list.entries()) {
            const targetTreeData = treeData[pi].children[si].children[DATA_TYPE.DICOM].children[sri]
            for (const info of series.url_info) {
              const fname = info.fname
              const url = info.url
              filename = `${patient.folderName}/${study.folderName}/DICOM/${series.folderName}/${fname}`
              const blob = await JSZipUtils.getBinaryContent(url, {
                progress: function (e) {
                  if (e.percent === 100) {
                    data.size += e.loaded;
                    data.count += 1;
                    targetTreeData.count += 1
                    progressCb(data);
                  } else {
                    data.size += e.loaded;
                    progressCb(data);
                    data.size -= e.loaded;
                  }
                },
              });
              zip.file(filename, blob, { binary: true });
            }
            targetTreeData.status = true
            progressCb(data);
          }
          for (const [bi, nifti] of study.blobs.entries()) {
            const targetTreeData = treeData[pi].children[si].children[DATA_TYPE.NIFTI].children[bi]
            for (const info of nifti.url_info) {
              const fname = info.fname
              const url = info.url
              filename = `${patient.folderName}/${study.folderName}/NIfTI/${nifti.folderName}/${fname}`
              const blob = await JSZipUtils.getBinaryContent(url, {
                progress: function (e) {
                  if (e.percent === 100) {
                    data.size += e.loaded;
                    data.count += 1;
                    targetTreeData.count += 1
                    targetTreeData.status = true
                    progressCb(data);
                  } else {
                    data.size += e.loaded;
                    progressCb(data);
                    data.size -= e.loaded;
                  }
                },
              });
              zip.file(filename, blob, { binary: true });
            }
          }
        }
      }
      
      if (data.count === totalCount) {
        data.size = totalSize
        progressCb(data);
        zip.generateAsync({ 
          type: "blob",
          compression: "STORE",
          compressionOptions: {
            level: 1
          }
         }, function updateCallback (metadata) {
          data.compressionRate = metadata.percent.toFixed(2)
          progressCb(data);
         }
         ).then(async content => {
          await saveAs(content, zipName);
          resolve({count : data.count});
        });
      }
    } catch (error) {
      reject({ filename, count : data.count });
    }
  });
}

async function recursiveResultDownload({row, zip, data, parentKeys, filename, progressCb}) {
  if (row.children) {
    const parentPath = row?.parentKeys?.map(key => parentKeys.find(data => data.key === key)?.folderName).filter(folderName => !!folderName).join('/')
    const folderName = parentPath ? `${parentPath}/${row.folderName}` : row.folderName
    zip.folder(folderName)
    
    const keyData = { key: row.key, folderName: row.folderName}
    parentKeys.push(keyData)

    for (const child of row.children) {
      await recursiveResultDownload({row: child, zip, data, parentKeys, filename, progressCb})
    }
    
  } else if (!row.children && row.isLeaf) {
    const fname = row.fileName
    const url = row.url
    const parentPath = row.parentKeys.map(key => parentKeys.find(keyData => keyData.key === key)?.folderName).filter(folderName => !!folderName).join('/')
    filename = parentPath ? `${parentPath}/${fname}` : fname

    const blob = await JSZipUtils.getBinaryContent(url, {
      progress: function (e) {
        if (e.percent === 100) {
          data.size += e.loaded;
          data.count += 1;
          row.count += 1
          row.status = true
          progressCb(data);
        } else {
          data.size += e.loaded;
          progressCb(data);
          data.size -= e.loaded;
        }
      },
    });
    zip.file(filename, blob, { binary: true });

  }
  return row;
}

async function reusltDownload({totalCount, progressCb, zipName, treeData, totalSize}) {
  return new Promise(async (resolve, reject) => {
    const zip = new JSZip()

    const data = {
      size: 0,
      count: 0,
      treeData,
      compressionRate: 0
    }

    const parentKeys = []

    let filename = "" // for error message

    progressCb(data)

    try {
      for (const row of treeData) {
        await recursiveResultDownload({row, zip, data, parentKeys, filename, progressCb})
        
        if (data.count === totalCount) {
          data.size = totalSize
          progressCb(data);
          zip.generateAsync({ 
            type: "blob",
            compression: "STORE",
            compressionOptions: {
              level: 1
            }
           }, function updateCallback (metadata) {
            data.compressionRate = metadata.percent.toFixed(2)
            progressCb(data);
           }
           ).then(async content => {
            await saveAs(content, zipName);
            resolve({count : data.count});
          });
        }
      }
    } catch (error) {
      reject({ filename, count : data.count });
    }
  })
}

function* startPatientsDownload(downloadData) {
  const { taskId, zipName, totalCount, totalSize, patients, treeData } = downloadData;
  try {
    const [downloadPromise, chan] = yield makeChannel({
      func: patientsDownload,
      totalCount,
      zipName,
      patients,
      treeData,
      totalSize
    });

    yield fork(progressWatcher, chan, taskId, totalSize, totalCount);
    const res = yield call(() => downloadPromise);
    
    yield put(taskSuccess({
      taskId,
      desc : `Done [${res.count}/${totalCount}]`
    }));
    yield put(msgSuccess(`${zipName} download complete`))

  } catch (error) {
    let desc = buildErrorMessage(error)
    if (Object.keys(error).includes('filename','count')) {
      desc = `Failed to download [${error.filename}] file. [${error.count}/${totalCount}]`
    }
    yield put(taskFail({ taskId, desc }))
    yield put(msgError(desc))
  }
}

function* startResultDownload(downloadData) {
  const {taskId, zipName, totalCount, totalSize, treeData} = downloadData
  try {
    const [downloadPromise, chan] = yield makeChannel({
      func: reusltDownload,
      totalCount,
      zipName,
      treeData,
      totalSize
    })
    
    yield fork(progressWatcher, chan, taskId, totalSize, totalCount);
    const res = yield call(() => downloadPromise)

    yield put(taskSuccess({
      taskId,
      desc : `Done [${res.count}/${totalCount}]`
    }))
    yield put(msgSuccess(`${zipName} download complete`))
  } catch (error) {
    let desc = buildErrorMessage(error)
    if (Object.keys(error).includes('filename','count')) {
      desc = `Failed to download [${error.filename}] file. [${error.count}/${totalCount}]`
    }
    yield put(taskFail({ taskId, desc }))
    yield put(msgError(desc))
  }
}

function* addCard({title, type, label}) {
  const taskId = uid()
  const data = makeTaskData({ 
    taskId, 
    title,
    type,
    label
  })
  yield put(addTask(data))
  return taskId
}

const makePatientsTreeData = patients => {
  let treeDataRowCounts = 0 // for Card exactly rendering
  const treeData = patients.map(patient => {
    treeDataRowCounts++
    return {
      title: patient.folderName,
      key: patient.key,
      children: patient.studies.map(study => {
        treeDataRowCounts += 3 // study, dicom root, nifti root
        return {
          title: study.folderName,
          key: study.key,
          children: [
            {
              title: 'DICOM',
              key: `${study.key}_dicom`,
              children: study.series_list.map(series => {
                treeDataRowCounts++
                return {
                  title: series.folderName,
                  key: series.key,
                  count: 0,
                  totalCount: series.image_count,
                  status: undefined,
                }
              })
            },
            {
              title: 'NIfTI',
              key: `${study.key}_nifti`,
              children: study.blobs.map(blob => {
                treeDataRowCounts++
                return {
                  title: `${blob.folderName}`,
                  key: blob.key,
                  count: 0,
                  totalCount: 1,
                  status: undefined
                }
              })
            }
          ]
        }
      })
    }
  })
  
  return {treeData, treeDataRowCounts}
}

function* getPatientsPresignedUrls(patients, taskId, title) {
  try {
    const res = yield getDownloadPatientsPresignedApi(patients)
    const patientsWithUrls = res.data.patients
    const { treeData, treeDataRowCounts } = makePatientsTreeData(patientsWithUrls)
    yield put(updateTask({taskId, treeData, treeDataRowCounts}))
    const totalCount = res.data.total_count
    const totalSize = res.data.size

    const downloadData = {
      taskId,
      zipName: title,
      totalCount,
      totalSize,
      patients: patientsWithUrls,
      treeData
    }
    yield spawn(startPatientsDownload, downloadData)
  
  } catch (error) {
    yield put(taskFail({ taskId, desc : buildErrorMessage(error)}))
    yield put(msgError(error))
  }
}

function assignUrlToLeafNodes(row, keysWithUrls, treeDataRowCounts) {
  if (row.isLeaf) {
    treeDataRowCounts++
    row.url = keysWithUrls.find(presignedInfo => presignedInfo.s3key === row.s3key).url;
    row.status = undefined
    row.count = 0
    row.totalCount = 1
    row.fileName = row.final
    row.title = `[0 / 1] ${row.fileName}`
  }
  else if (!row.isLeaf) {
    treeDataRowCounts++
    row.folderName = row.final || row.folderName
    row.title = row.folderName
    delete row.className 
    delete row.style
    delete row.selectable
  }
  if (row.children) {
    row.children = row.children.map(childRow => assignUrlToLeafNodes(childRow, keysWithUrls, treeDataRowCounts));
  } 
  return row;
}

const makeResultsTreeData = (keysWithUrls, treeData) => {
  let treeDataRowCounts = 0
  const treeDataWithUrls = treeData.map(row => assignUrlToLeafNodes(row, keysWithUrls, treeDataRowCounts))
  return {treeDataWithUrls, treeDataRowCounts}
}

function* getResultsPresignedUrls(s3Keys, taskId, title, treeData, totalSize) {
  try {
    const res = yield getDownloadResultsPresignedApi(s3Keys)
    const keysWithUrls = res.data
    const {treeDataWithUrls, treeDataRowCounts} = makeResultsTreeData(keysWithUrls, treeData)
    yield put(updateTask({taskId, treeData: treeDataWithUrls, treeDataRowCounts}))
    const totalCount = keysWithUrls.length

    const downloadData = {
      taskId,
      zipName: title,
      totalCount,
      totalSize,
      treeData: treeDataWithUrls
    }
    
    yield spawn(startResultDownload, downloadData)

  } catch (error)  {
    yield put(taskFail({ taskId, desc : buildErrorMessage(error)}))
    yield put(msgError(error))
  }
}

function* startPatientsDownloadSaga({payload}) {
  const {patients, zipName} = payload
  if (patients.length === 0) {
    return put(msgError('No data selected'))
  }
  const title = zipName
  const type = 'downloadPatient'
  const label = 'Download patient'
  const taskId = yield addCard({title, type, label})
  
  try {
    yield put(downloadPatientsSuccess())
    yield put(updateDrawerOpen(true))

  } catch (error) {
    // error 발생시 위에서 생성한 task들 실패처리
    yield put(downloadPatientsFail({ taskId, desc: buildErrorMessage(error)}))
    yield put(msgError(error))

    return;
  }

  // start Download after get presigned URL
  yield getPatientsPresignedUrls(patients, taskId, title) 
}

function* startResultsDownloadSaga({payload}) {
  const {treeData, s3Keys, zipName, totalSize} = payload
  if (s3Keys.length === 0) {
    return put(msgError('No data'))
  }
  const title = zipName
  const type = 'downloadResult'
  const label = 'Download reulst'
  const taskId = yield addCard({title, type, label})

  try {
    yield put(downloadResultsSuccess())
    yield put(updateDrawerOpen(true))

  } catch (error) {
    yield put(downloadResultsFail({ taskId, desc: buildErrorMessage(error)}))
    yield put(msgError(error))

    return;
  }
  
  yield getResultsPresignedUrls(s3Keys, taskId, title, treeData, totalSize)
}

function* watchDownload() {
  yield throttle(500, actions.DOWNLOAD_PATIENTS, startPatientsDownloadSaga);
  yield throttle(500, actions.DOWNLOAD_RESULTS, startResultsDownloadSaga);
}

export default function* downloadSaga() {
  yield all([fork(watchDownload)])
}