import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {updateDrawerOpen,deleteEndedTasks,changeRenderInfo} from "../../redux/modules/longTask";
import AutoSizer from "react-virtualized-auto-sizer";
import ProgressCard from "./progressCard";
import { VariableSizeList as List } from 'react-window'

function LongTaskDrawer() {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(state=>state.longTask.drawerOpen);
  const endedTaskCount = useSelector(state=>state.longTask.tasks.filter(task=>task.ended).length);
  const taskCount = useSelector(state=>state.longTask.tasks.length);

  const [rowHeights, setRowHeights] = useState({})
  const listRef = useRef();

  const getRowHeight = index => rowHeights[index] || 220;

  useEffect(() => {
    setRowHeights({})
  },[drawerOpen])

  const setRowHeight = (index, height) => {
    listRef.current.resetAfterIndex(index);
    if (getRowHeight(index) !== height) {
      setRowHeights(prev => ({...prev, [index]: height}))
    }
  }

  function Row({index, style}) {
    const cardRef = useRef()
    
    const onResize = (index, height) => setRowHeight(index, height)
    
    return(
      <div style={style}>
        <ProgressCard 
          ref={cardRef} 
          index={index} 
          setRowHeight={setRowHeight} 
          onResize={onResize}
          height={getRowHeight(index)}
        />
      </div>
    )
  }

  return (
    <>
      <Drawer
        title={
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Progress [{endedTaskCount}/{taskCount}]</h3>
            <Button
              type={"primary"}
              disabled={endedTaskCount > 0 ? false : true}
              onClick={() => {
                dispatch(deleteEndedTasks());
              }}
            >
              Remove ended tasks
            </Button>
          </Space>
        }
        placement="right"
        onClose={() => {dispatch(updateDrawerOpen(false))}}
        open={drawerOpen}
        closable={false}
        size="large"
        zIndex={2000}
      >
        {taskCount > 0 && drawerOpen && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                className="List"
                height={height}
                width={width}
                itemCount={taskCount}
                itemSize={getRowHeight}
                overscanCount={2}
                ref={listRef}
                onItemsRendered={({overscanStartIndex,
                                    overscanStopIndex,
                                    visibleStartIndex,
                                    visibleStopIndex})=>{
                  dispatch(changeRenderInfo({
                    overscanStartIndex,
                    overscanStopIndex,
                    visibleStartIndex,
                    visibleStopIndex}
                  ));
                }}
              >
                {Row}
              </List>
            )}
          </AutoSizer>
        )}
      </Drawer>
    </>
  );
}

export default LongTaskDrawer;
