import { Button, Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function AlreadyLoginStatus() {
  const landingUrl = useSelector(state => state.config.landing_url);
  const history = useHistory()
  return (
    <Result 
      title={`You are already logged in.`}
      style={{
        width: '800px',
        margin: 'auto',
        verticalAlign: 'middle',
        height : '400px',
      }}
      extra={
        <Button type="primary" onClick={()=>history.push(landingUrl)}>
          Go my default page
        </Button>
      }
    />
  )
}

export default AlreadyLoginStatus