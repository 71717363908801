import React, {useEffect, useState} from "react";
import {Card, Form} from "antd";
import {useSelector} from "react-redux";
import TaskInputSelect, {MODE} from "./taskInputSelect";
import {InfoCircleOutlined} from '@ant-design/icons';
import {getMatchingOutput} from "../lib/taskUtil";

export const TaskInput = (props) => {
  const imageTypes = useSelector(state => state.blobTypes);

  // TODO task output은 어떻게 달지 흠..
  const candidatesFromBlobTypes = (btypes) => {
    if (Array.isArray(btypes)) {
      let names = []
      for (const id of btypes) {
        for (const imgType of imageTypes.list) {
          if (imgType.id === id) {
            names.push(imgType.short)
          }
        }
      }
      return names
    }
    else {
      for (const imgType of imageTypes.list) {
        if (imgType.id === btypes) {
          return [imgType.short]
        }
      }
    }

    return ""
  }

  const buildInputs = () => {
    if (props.inputs) {
      return props.inputs
    }
    else {
      // props.inputs가 undefined 이거나 null
      if (!props.model) { return undefined }

      const model = props.models.find(m => {
        return m.name === props.model[0] && m.version === props.model[1]
      })
      if (!model) { return undefined }

      let inputs = []
      for (const input of model.input) {
        inputs.push({
          name: input.name,
          type: input.type,
          candidates: candidatesFromBlobTypes(input.blobtype),
          desc: 'Descriptions need to be filled',
        })
      }
      return inputs
    }
  }

  const [inputs, setInputs] = useState(buildInputs());

  useEffect(() => {
    setInputs(buildInputs())
  }, [props.inputs, props.model])

  const onChange = (input_index) => (val, option, ...t) => {
    const newInputs = [...inputs]
    const curInput = newInputs[input_index]
    if (curInput.hasOwnProperty('length')) {
      curInput.selected = val
    }
    else {
      if (val.length > 0) {
        curInput.selected = val[0]
      }
      else {
        delete curInput.selected
      }
    }

    const matchingOutput = getMatchingOutput(props.task, curInput)
    if (val.length === 0) {
      // '추가'가 아니라 '삭제'되는 경우에도 처리해줘야 함, 특히 blobtype 을 날려줘야함
      delete curInput.selected
      if (matchingOutput) {
        delete matchingOutput.selected
        delete matchingOutput.blobtype // NOTE blobtype 까지 삭제해줘야 함
      }
    }
    else {
      if (curInput?.generate_map && matchingOutput) {
        // NOTE Array.isArray(val) 로 확인하면 안 됨, TaskInputSelect 를 multiple 로 구현했기 때문에 val 은 항상 배열로 들어옴
        matchingOutput.selected = curInput.hasOwnProperty('length') ? val : val[0]
      }
    }

    setInputs(newInputs)
    props.onChange({...props.task, inputs: newInputs, outputs: [...props.task.outputs]})
  }

  // console.count('TaskInput rendered!')
  // console.log(props.task.outputs?.[0], inputs)
  const seletedModel = props.models.find(m => m.name === props.model?.[0] && m.version === props.model?.[1])
  return (
    <>
      {inputs === undefined || inputs == null ? null :
        <Card title="Task input" size="small">
          {inputs.map((item, input_idx) => {
            let placeholder = `Select ${item.type} data`
            if (item.segmentation) {
              placeholder = `Select ${item.type} data (segmentation only)`
            }
            // const mode = item.hasOwnProperty("length") ? MODE.MULTI : MODE.SINGLE
            let desc = props.template?.input?.[input_idx]?.desc || seletedModel?.input?.[input_idx]?.desc
            if (Array.isArray(desc)) {
              desc = desc.join(' or ')
            }

            return <div key={input_idx}>
              <Form.Item
                label={item.name}
                key={input_idx}
                tooltip={{
                  title: desc,
                  icon: <InfoCircleOutlined style={{color: 'rgba(255, 255, 255, 0.5)'}}/>,
                  getPopupContainer: (trigger) => {
                    return trigger.parentNode.parentNode.parentNode
                  }
                }}
                required={item.required}
              >
                <TaskInputSelect
                  onChange={onChange(input_idx)}
                  // onSelect={onSelect}
                  // onDeselect={onDeselect}
                  dropdownMatchSelectWidth={false}
                  placeholder={placeholder}
                  value={item.selected}
                  select_index={input_idx}
                  taskInput={item}
                />
              </Form.Item>
            </div>
            })
          }
        </Card>
      }
    </>
  );
}
