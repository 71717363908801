import {all, call, fork, put, takeLeading, getContext, select} from "redux-saga/effects";
import * as actions from "../../redux/modules/analysis";
import axios from "axios";
import {loadSuccess, loadFail, addSuccess, addFail, getSuccess, getFail, 
  updateSuccess, updateFail, delSuccess, delFail,
  loadCaseSuccess, loadCaseFail, 
  getDownloadTargetsSuccess, getDownloadTargetsFail,
  getQuickViewDataSuccess, getQuickViewDataFail
} from "../../redux/modules/analysis";
import {getCookie} from "../../lib/cookie";
import { msgError } from "../../redux/modules/message";

function loadApi({page, per_page, category, keyword, loadStates}) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/listAnalysis', { pagination : {page, per_page}, search : {category, keyword}, loadStates}, {
    withCredentials: true, // For transition cookie
  });
}

// eslint-disable-next-line no-unused-vars
function loadTasksApi(name, desc, tasks) {
  return axios.post('/api/listTaskForAnalysis', {name, desc, tasks}, {
    withCredentials: true, // For transition cookie
  });
}

function getApi({id, taskId, page, per_page, category, keyword, longitudinal}) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/getAnalysis', {id, taskId, pagination : {page, per_page}, search : {category, keyword}, longitudinal}, {
    withCredentials: true, // For transition cookie
  });
}

function addApi(dataset_id, pipeline_id) {
  return axios.post('/api/addAnalysis', {dataset_id, pipeline_id}, {
    withCredentials: true, // For transition cookie
  });
}

function updateApi(id, name, desc, tasks) {
  return axios.post('/api/updateAnalysis', {id, name, desc, tasks}, {
    withCredentials: true, // For transition cookie
  });
}

function delApi({ keys, page, per_page, category, keyword, loadStates }) {
  return axios.post('/api/deleteAnalysis', { ids : keys, pagination : {page, per_page}, search : {category, keyword}, loadStates}, {
    withCredentials: true, // For transition cookie
  });
}

function loadCaseApi({id, page, per_page,category, keyword, longitudinal}) {
  return axios.post('/api/loadAnalysisCase', {id,  pagination : {page, per_page}, search : {category, keyword}, longitudinal}, {
    withCredentials: true, // For transition cookie
  })
}

function getDownloadTargetsApi(id) {
  return axios.post('/api/getDownloadTargetResult', {id}, {withCredentials: true})
}

function getQuickViewDataApi(studyInfo) {
  return axios.post('/api/getQuickViewData', {studyInfo}, {withCredentials: true})
}

function* loadSaga({payload}) {
  try {
    const {page, per_page, category, keyword, loadStates} = payload
    const res = yield loadApi({page, per_page, category, keyword, loadStates});
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* addSaga({payload}) {
  try {
    const {dataset_id, pipeline_id} = payload;
    const res = yield call(addApi, dataset_id, pipeline_id);
    yield put(addSuccess(res.data));
    const history = yield getContext('history');
    history.push('/analysis');
  } catch (error) {
    yield put(addFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  try {
    const { id, taskId, page, per_page, category, keyword, longitudinal } = payload;
    const res = yield getApi({id, taskId, page, per_page, category, keyword, longitudinal})
    yield put(getSuccess(res.data));
  } catch (error) {
    yield put(getFail());
    yield put(msgError(error))
  }
}

function* updateSaga({payload}) {
  try {
    const tasks = payload.tasks;
    const { id, name, desc } = payload.values;
    const res = yield call(updateApi, id, name, desc, tasks);
    yield put(updateSuccess(res.data));
    const history = yield getContext('history');
    history.goBack();
  } catch (error) {
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function* delSaga({payload}) {
  try {
    const { keys, page, per_page, category, keyword, loadStates } = payload;
    const res = yield delApi({ keys, page, per_page, category, keyword, loadStates })
    yield put(delSuccess(res.data));
  } catch (error) {
    yield put(delFail());
    yield put(msgError(error))
  }
}

function* loadCaseSaga({payload}) {
  try {
    const { id, page, per_page, category, keyword, longitudinal } = payload;
    const res = yield loadCaseApi({ id, page, per_page, category, keyword, longitudinal })
    yield put(loadCaseSuccess(res.data))
  } catch (error) {
    yield put(loadCaseFail())
    yield put(msgError(error))
  }
}

function* getDownloadTargetsSaga({payload}) {
  try {
    const id = payload
    const res = yield getDownloadTargetsApi(id)
    yield put(getDownloadTargetsSuccess(res.data))
  } catch (error) {
    yield put(getDownloadTargetsFail())
    yield put(msgError(error))
  }
}

function* getQuickViewDataSaga({payload}) {
  try {
    const { studyInfo, volumeInfo } = payload
    const res = yield getQuickViewDataApi(studyInfo)
    yield put(getQuickViewDataSuccess({...res.data, volumeInfo}))

  } catch (error) {
    yield put(getQuickViewDataFail())
    yield put(msgError(error))
  }
}

function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
  yield takeLeading(actions.LOAD_CASE, loadCaseSaga)
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga);
}

function* watchGet() {
  yield takeLeading(actions.GET, getSaga);
  yield takeLeading(actions.GET_QUICK_VIEW_DATA, getQuickViewDataSaga)
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}

function* watchDel() {
  yield takeLeading(actions.DEL, delSaga);
}

function* watchDownload() {
  yield takeLeading(actions.GET_DOWNLOAD_TARGETS, getDownloadTargetsSaga)
}

export default function* analysisSaga() {
  yield all([
    fork(watchLoad),
    fork(watchAdd),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
    fork(watchDownload),
  ]);
}
