import dicomParser from "dicom-parser";
import {trackVerticalStyleDefault} from "react-custom-scrollbars/lib/Scrollbars/styles";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'


export const uid = () => {
  return (Math.random().toString(16)+performance.now().toString(36)).replace(/\./g, "")
}

export const tryShortenAnonymizedPatientNames = (names) => {
  let shortestLen = Number.MAX_VALUE
  let shortestName = ''
  for (let i = 0; i < names.length; ++i) {
    const nameLen = names[i].length
    if (nameLen < shortestLen) {
      shortestName = names[i]
      shortestLen = nameLen
    }
  }

  let found = 0
  let lastSlash = 0
  for (let i = 0; i < shortestLen; ++i) {
    const checks = names.map(name => name[i] === shortestName[i])
    if (shortestName[i] === '/') {
      lastSlash = i
    }
    if(!checks.every(check => check)) {
      found = i
      break
    }
  }

  if (found === 0) {
    return names
  }
  else {
    const newNames = names.map(name => {
        if (found === name.length - 1) {
          return name
        } else {
          return name.slice(lastSlash + 1)
        }
      }
    )
    return newNames
  }
}

export const findBestMatch = (path, patients) => {
  let longest = 0;
  let longest_patients = [];
  patients.forEach(p => {
    const lidx = path.lastIndexOf(p.lcp)
    if (lidx >= 0) {
      const match = p.lcp.length - lidx
      if (match >= 0 && match <= p.lcp.length) {
        if (match > longest) {
          longest_patients = [] // 더 긴 match가 나오면 초기화
        }
        if (match >= longest) {
          longest_patients.push(p)
        }
        longest = match
      }
    }
  })

  longest = 0;
  let longest_patient = null;
  let longest_studies = [];

  let searchTarget = patients;
  if (longest_patients.length) {
    // anonymized면 환자 lcp가 empty여서 잘 못찾으므로 개별 study로 한 번 더 찾아줌
    searchTarget = longest_patients;
  }

  searchTarget.forEach(p => p.studies.forEach(s => {
    const lidx = path.lastIndexOf(s.lcp)
    if (lidx >= 0) {
      const match = s.lcp.length - lidx
      if (match >= 0 && match <= s.lcp.length) {
        if(match > longest) {
          longest_studies = [] // 더 긴 match가 나오면 초기화
          longest_patient = p
        }
        if(match >= longest) {
          longest_studies.push(s)
        }
        longest = match
      }
    }
  }))

  return {patient: longest_patient, study: longest_studies.length === 1 ? longest_studies[0] : null}
}

const compareDirs = (dirs1, dirs2) => {
  let k = 0
  for (; k < dirs1.length && k < dirs2.length; ++k) {
    if (dirs1[k] !== dirs2[k]) {
      break
    }
  }
  return k
}


/**
 * anonymized dicom 에 대해 folder structure 를 이용해서 patient ID tree 를 만듦
 * build [study LCP -> Patient ID] map
 * @param paths
 *   '/test_root/P01',
 *   '/test_root/P02',
 *   '/test_root/test2/P07',
 *   '/test_root/test2/P08',
 *   '/test_root/test2/test3/P10',
 *   '/test_root/test2/test3/P11',
 *   '/test_root/test3/P012',
 *   '/test_root/test3/P013',
 *   '/test_root/test3/P07',
 *   '/test_root/test3/P08',
 *   '/test_root2/P01'
 */

export const buildTreeFromPaths = paths => {
  const pathsSplits = paths.map(path => path.split('/').filter(i => i !== ''))
  const tree = []
  for (let i = 0; i < pathsSplits.length; ++i) {
    const splits = pathsSplits[i]
    let current_node = tree
    for (let j = 0; j < splits.length; ++j) {
      const name = splits[j]
      const node = current_node.find(n => n.v === name)
      if (node === undefined) {
        const newNode = {
          v: name,
          count: 1,
          children: [],
          paths: splits.slice(0, j + 1)
        }
        current_node.push(newNode)
        current_node = newNode.children
      }
      else {
        node.count += 1
        current_node = node.children
      }
    }
  }
  return tree
}

const traverseTree = (nodes, parents, idmap) => {
  if (nodes.length !== 0) {
    nodes.forEach(node => {
      parents.push(node.v)
      if (node.paths?.length > 0) {
        node.paths.forEach(i => idmap.set(i, node.v))
      }
      traverseTree(node.children, parents, idmap)
    })
  }
}

export const traverseTree2FindChildrenCount = (nodes, dirNames, dirNameIndex, ) => {
  if (nodes.length !== 0) {
    for (const node of nodes) {
      if (node.v === dirNames[dirNameIndex]) {
        if (dirNameIndex === dirNames.length - 1) {
          return node.children.length
        }
        else {
          return traverseTree2FindChildrenCount(node.children, dirNames, dirNameIndex + 1)
        }
      }
    }
  }
}

export const findGroup = (paths) => {
  const groups = new Map()
  let uniqPaths = [...new Set(paths)]
  const lcp1 = findLongestCommonPath(uniqPaths)
  while (!lcp1 && uniqPaths.length === 0) {
    const shortenPaths = uniqPaths.map(p => p.replace(lcp1, ""))
    shortenPaths.forEach((p, pi) => {
      if (!groups.has(p)) {
        groups.set(p, pi)
        uniqPaths.splice(pi, 1)
      }
      else {
        const pathOrg = uniqPaths[pi]
        groups.set(pathOrg, pi)
        uniqPaths.splice(pi, 1)
      }
    })
  }
  const sortedPaths = [...paths].sort()
  const splittedPaths = sortedPaths.map(path => path.split('/').filter(i => i !== ''))
  for (let i = 0; i < splittedPaths.length;) {
    const dirs_i = splittedPaths[i]

    let group = null
    if (i + 1 === splittedPaths.length) {
      group = {id: dirs_i.join('/'), dirs: dirs_i}
      groups.set(group.id, group)
      break
    }

    let matchCount = 0
    for (let j = i + 1; j < splittedPaths.length; ++j) {
      const dirs_j = splittedPaths[j]

      if (group) {
        // groupID 와 연속적으로 비교
        const pos = compareDirs(group.dirs, dirs_j)
        if (pos !== group.dirs.length) {
          groups.set(group.id, group)
          group = null
          break
        }
        else {
          matchCount++
        }
      }
      else {
        // groupID 정하기
        // 부분적으로 공통부분이 있을수도 있음 e.g. /asdf/111, /asdf/222 -> asdf 그룹 등록
        const pos = compareDirs(dirs_i, dirs_j)
        if (pos !== 0) {
          const dirs = dirs_i.filter((dir, dir_idx) => dir_idx < pos)
          group = {id: dirs.join('/'), dirs}
          matchCount++
        }
        else {
          group = {id: dirs_i.join('/'), dirs: dirs_i}
          groups.set(group.id, group)
          break
        }
      }
    }
    i += 1 + matchCount// 기본 +1 에 matchCount 만큼 더 간다
  }

  // group ID 에 common headings 존재하면 제거한 map을 리턴
  const lcp = findLongestCommonPath(Array.from(groups.keys()))
  if (lcp !== "") {
    const lcpDirs = lcp.split('/').filter(i => i !== '')
    for (const group of groups.values()) {
      const pos = compareDirs(lcpDirs, group.dirs)
      if (pos !== 0) {
        const dirs = group.dirs.filter((dir, dir_idx) => dir_idx >= pos)
        const newGroup = {...group, id2: dirs.join('/'), dirs2: dirs}
        groups.set(group.id, newGroup)
      }
    }
  }

  return groups
}

export const findLongestCommonPath = (paths) => {
  if (paths.length === 1) {
    const path = paths[0]
    const split = path.split('/').filter(i => !i.includes('.dcm'))
    return split.join('/')
  }
  else {
    let longest = 0;
    const splittedPaths = paths.map((path, i) => {
      const split = path.split('/')
      if (split.length > longest) {
        longest = i
      }
      return split;
    })

    const longest_path_split = splittedPaths[longest];
    let i = 0;
    for (i = 0; i < longest_path_split.length; ++i) {
      if (!splittedPaths.every(path_split => path_split[i] === longest_path_split[i])) {
        break
      }
    }

    return longest_path_split.slice(0, i).join('/')
  }
}

export const getPatientInfo = (patient, file) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (event) {
      const byteArray = new Uint8Array(reader.result);

      const dataSet = dicomParser.parseDicom(byteArray/*, options */);

      // access a string element
      patient.birth = dataSet.string('x00100030') ?? '1900-01-01'
      patient.sex = dataSet.string('x00100040') ?? 'O';
      patient.age = dataSet.string('x00101010') ?? '30';
      resolve();
    }
  })
}

/**
 * 일단 PID 같은지 확인 (study, series, blob 등 은 중복허용하면서 복사됨)
 * @param patients1
 * @param patients2
 * @returns {*[]}
 */
export const mergePatients = (patients1, patients2) => {
  let merged = [...patients1]
  for (let patient2 of patients2) {
    const pidx = merged.findIndex(e => patient2.pid === e.pid)
    if (pidx < 0) {
      merged.push(patient2)
    }
    else {
      const foundPatient = merged[pidx]
      patient2.studies.forEach(s => {
        foundPatient.studies.push(s)

        // update series and blob bind
        s.series.forEach(sr => sr.bind = [foundPatient.key, s.key])
        s.blobs.forEach(b => b.bind = [foundPatient.key, s.key])
      })
    }
  }
  return merged
}

async function getImageId(url) {
  const image = await cornerstone.loadAndCacheImage(`wadouri:${url}`)

  // 'x00200013' dicom 정보 => Image Number
  // TODO 나중에 다른 Dicom header 들에 대한 정보들도 확인해서 해당 dicom 정보가 항상 있는건지 확인해야 한다.
  return {
    imageId: image.imageId,
    slideNumber: image.data.string("x00200013"),
    frame: image.data.intString("x00280008"),
  }
}

function chunkArray(array, size) {
  const chunked = [];
  let index = 0;
  while (index < array.length) {
    chunked.push(array.slice(index, size + index));
    index += size;
  }
  return chunked;
}


export const buildDicomStack = async (urls, fnGetImageId=getImageId) => {
  let imageIdsList = []
  if (urls.length > 1000) {
    const chunks = chunkArray(urls, 100); // 100 requests per chunk
    for (let i = 0; i < chunks.length; i++) {
      const chunkResults = await Promise.all(chunks[i].map(url => fnGetImageId(url)))
      imageIdsList.push(...chunkResults);
    }
  }
  else {
    imageIdsList = await Promise.all(urls.map(url => fnGetImageId(url)))
  }

  if (imageIdsList.length < 1) {
    return {imageIds: [], currentImageIdIndex: 0}
  }

  // Image Number 순으로 정렬
  const sortedImageIds = imageIdsList.sort(function (a, b) {
    return a.slideNumber - b.slideNumber;
  });

  const numFrames = imageIdsList[0].frame
  if (numFrames) {
    // multi frame DICOM
    const url = urls[0]
    const imageIds = [];
    const imageIdRoot = 'wadouri:' + url;

    for(let i=0; i < numFrames; i++) {
      imageIds.push(imageIdRoot + "?frame="+i);
    }

    return {imageIds: imageIds, currentImageIdIndex: 0}
  }
  else {
    // single frame DICOMs
    const imageIds = sortedImageIds.map(i => i.imageId) // 정렬된 상태로 imageId 만 걸러냄
    return {imageIds: imageIds, currentImageIdIndex: Math.floor(imageIds.length / 2)}
  }
}