import { Typography } from "antd"
import './typographyDesc.css'

const {Text, Paragraph} = Typography

export function TypographyDesc({overflow, children, style, ...rest}) {
  return (
    <Text {...rest}>
      <pre 
        style={{margin : 0, ...style}} 
        className={overflow?'overflow':null}
      >
        {children || " "}
      </pre>
    </Text>
  )
}

export function TypographyFormItem({children}) {
  return (
    <Paragraph>
      {children || " "}
    </Paragraph>
  )
}