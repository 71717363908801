import { Button, Col, Divider, Form, Modal, Progress, Result, Row, Select, Space, Table, Tooltip, Typography } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { editHints, load } from "../redux/modules/blobTypes"
import { TagBase } from "./tagBase"
import { useTitle } from "../hooks/title"
import { EditableCell, EditableRow } from "./tableEditable"
import { uid } from "../lib/UtilDicomParse"
import ModalityHint from "./modalityHint"
import { TypographyDesc } from "./typographyDesc"
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks"
import FileUpload from "./fileUpload2"
import { useDicomDrop } from "../hooks/dicomDrop"
import { TransparentBGModal } from "./styledComponent"
import { ThreeDots } from "react-loader-spinner"
import { findDicomImageType, findNiftiImageType } from "../lib/findType"
import { removeInvalidExpressions } from "./modality"
import { msgInfo } from "../redux/modules/message"
import { ArrowRightOutlined } from '@ant-design/icons';
import EllipsisTooltipForImagetypes from "./ellipsisTooltipForImagetypes"

function ModalityTest() {
  useTitle('Image type')
  const dispatch = useDispatch()
  const history = useHistory()
  const imageTypeListInStore = useSelector(state=>state.blobTypes?.list)
  
  const [imageTypeList, setImageTypeList] = useState([])
  const [selectedTypeId, setSelectedTypeId] = useState()
  const selectedType = imageTypeList?.find(type => type.id === selectedTypeId)
  const editedTypes = imageTypeList.filter(type => type.edit)
  
  const leftColumnSpan = 14
  const spaceColumnSpan = 1
  const rightColumnSpan = 24 - leftColumnSpan - spaceColumnSpan

  useEffect(() => {
    dispatch(load({}))
  },[])
  
  useEffect(() => {
    if (imageTypeListInStore) {
      const copied = JSON.parse(JSON.stringify(imageTypeListInStore))
      setImageTypeList(copied)
      setSelectedTypeId(copied[0].id)
    }
  },[imageTypeListInStore])

  const { getTagFromTypeId, getTypeFromTypeId} = useGetImageTypeHooks(imageTypeList)
  const [stateDicomDrop, dispatchDicomDrop, onDrop] = useDicomDrop();
  const {
    loading: dropLoading,
    progress,
    parseCount,
    parseCountMax: parseLength,
    exception,
    resolveds,
    unresolveds,
  } = stateDicomDrop;

  const handleCancel = () => history.goBack()
  
  const handleOk = () => {
    const {existInvalidDicomHints, existInvalidNiftiHints} = checkInvalidHints()
    if (existInvalidDicomHints || existInvalidNiftiHints) {
      return invalidConfirmModal(existInvalidDicomHints, existInvalidNiftiHints)
    }
    return dispatch(editHints(editedTypes))
  }
  
  const invalidConfirmModal = (existInvalidDicomHints, existInvalidNiftiHints) => {
    return Modal.confirm({
      centered: true,
      icon: undefined,
      width: 800,
      onOk: () => {
        removeInvalidHInts()
        onTest()
        dispatch(msgInfo('Invalid hints have been removed. Please save again'))
      },
      content: (
        <Result
          status='warning'
          style={{padding: 0}}
          title={(
            <Typography.Title level={3} type='warning'>Invalid hints exist.</Typography.Title>
          )}
          subTitle={(
            <>
              <Typography.Title level={4} type='warning'>Do you want to remove invalid hints?</Typography.Title>
              <Row style={{textAlign : 'center', width: '100%', marginTop: 30}} gutter={2}>
                <div>
                  <Typography.Title level={5} style={{display: 'inline-block', marginRight: 20}}>
                    Invalid Type: 
                  </Typography.Title>
                  {getTagFromTypeId(selectedType?.id)}
                </div>
                {existInvalidDicomHints && 
                <Col span={24}>
                  <Typography.Title level={5}>DICOM hints</Typography.Title>
                </Col>
                }
                {existInvalidDicomHints && selectedType?.dicom_hints?.map(value => {
                  const invalid = !(!!value.value)
                  const invalidColor = 'rgba(255,0, 0, 0.5)'
                  const style = {backgroundColor: invalid && invalidColor}
                  return <>
                    <Col span={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{invalid && <ArrowRightOutlined style={{color: 'white'}}/>}</Col>
                    <Col span={2}><TypographyDesc style={style} children={value.lop || " "}/></Col>
                    <Col span={8}><TypographyDesc style={style} children={value.target}/></Col>
                    <Col span={5}><TypographyDesc style={style} children={value.op}/></Col>
                    <Col span={8}><TypographyDesc style={style} children={value.value || " "}/></Col>
                  </>})}
                {existInvalidNiftiHints && 
                  <Col span={24}>
                    <Typography.Title level={5}>NIfTI hints</Typography.Title>
                  </Col>
                }
                {existInvalidNiftiHints && selectedType?.image_hints?.map(value => {
                const style = {backgroundColor: value.value ? undefined : 'rgba(255,0, 0, 0.5)'}
                return <>
                  <Col span={3}><TypographyDesc style={style} children={value.lop || " "}/></Col>
                  <Col span={9}><TypographyDesc style={style} children={value.target}/></Col>
                  <Col span={5}><TypographyDesc style={style} children={value.op}/></Col>
                  <Col span={7}><TypographyDesc style={style} children={value.value || " "}/></Col>
                </>})}
            </Row>
            </>
          )}
        />
      )
    })
  }

  const checkInvalidHints = () => {
    let existInvalidDicomHints, existInvalidNiftiHints = false
    if (selectedType) {
      existInvalidDicomHints = selectedType.dicom_hints !== null && !selectedType.dicom_hints?.every(hint => !!hint.value)
      existInvalidNiftiHints = selectedType.image_hints !== null && !selectedType.image_hints?.every(hint => !!hint.value)
    }
    return {existInvalidDicomHints, existInvalidNiftiHints}
  }
  
  const onTest = () => {
    const copiedDicomDataSource = [...dicomDataSource]
    copiedDicomDataSource.forEach(dicom => {
      const series = {desc: dicom.series_desc, protocol: dicom.series_protocol}
      const study = {desc: dicom.study_desc}
      dicom.type = findDicomImageType(series, study, imageTypeList)
      dicom.recognized_types = findDicomImageType(series, study, imageTypeList, false)
    })
    const copiedNiftiDataSource = [...niftiDataSource]
    copiedNiftiDataSource.forEach(nifti => {
      nifti.type = findNiftiImageType(nifti, imageTypeList)
      nifti.recognized_types = findNiftiImageType(nifti, imageTypeList, false)
    })
    setDicomDataSource(copiedDicomDataSource)
    setNiftiDataSource(copiedNiftiDataSource)
  }

  const selectOptions = imageTypeList?.map((type, index) => ({
    label: (
      <TagBase
        color={type.tag_color}
        children={
          <Tooltip title={type.name} mouseEnterDelay={0.5}>
            {type.short}
          </Tooltip>
        }
      />
    ),
    value: type.short,
    key: type.id,
    type
  }))

  const removeInvalidHInts = () => {
    if (selectedType) {
      const copiedImageTypeList = [...imageTypeList]
      const targetImageType = copiedImageTypeList.find(type => type?.id === selectedType?.id)
      removeInvalidExpressions(targetImageType)
      
      setImageTypeList(copiedImageTypeList)
    }
  }

  const onChange = (_, option) => {
    removeInvalidHInts()
    setSelectedTypeId(option?.key)
  }

  const [dicomDataSource, setDicomDataSource] = useState([])
  const [niftiDataSource, setNiftiDataSource] = useState([])

  const TypeTagForTest = ({type, closable}) => {
    return (
      <TagBase 
        color={type.tag_color} 
        children={type.short}
        style={{margin: '4px', cursor: 'pointer'}}
        onClick={() => {
          removeInvalidHInts()
          setSelectedTypeId(type.id)
        }}
        closable={closable}
        onClose={() => onEdittedTypesReset(type)}
      />
    )
  }

  const dicom_columns = [
    {
      title: 'Study desc',
      dataIndex: 'study_desc',
      key: 'study_desc',
      editable: true,
      required: false,
      render: data => <div style={{wordBreak: 'break-all'}}>{data}</div>
    },
    {
      title: 'Series desc',
      dataIndex: 'series_desc',
      key: 'series_desc',
      width: 150,
      editable: true,
      required: false,
      render: data => <div style={{wordBreak: 'break-all'}}>{data}</div>
    },
    {
      title: 'Series protocol',
      dataIndex: 'series_protocol',
      key: 'series_protocol',
      width: 150,
      editable: true,
      required: false,
      render: data => <div style={{wordBreak: 'break-all'}}>{data}</div>
    },
    {
      title: 'Matching Types',
      dataIndex: 'recognized_types',
      key: 'recognized_types',
      align: 'center',
      width: 270,
      render: imageTypeIds => {
        const contents = (
          <div>
            {imageTypeIds?.map(typeId => {
              const type = getTypeFromTypeId(typeId)
              return type && <TypeTagForTest type={type} />
              })
            }
          </div>)
        return (
          <div style={{display: 'flex', textAlign: 'left'}}>
            <EllipsisTooltipForImagetypes
              title={contents}
              placement="right"
              color={'#0C2329'}
            >
              {contents}
            </EllipsisTooltipForImagetypes>
          </div>
        )
      }
    },
    {
      title: 'Final type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      render: data => {
        const typeId = data
        const type = getTypeFromTypeId(typeId)
        return type && <TypeTagForTest type={type} />
      }
    },
  ]

  const nifti_columns = [
    {
      title: 'filename',
      dataIndex: 'name',
      key: 'name',
      editable: true,
      required: false,
      render: data => <div style={{wordBreak: 'break-all'}}>{data}</div>
    },
    {
      title: 'Matching Types',
      dataIndex: 'recognized_types',
      key: 'recognized_types',
      align: 'center',
      width: 270,
      render: data => data.map(typeId => {
        const type = getTypeFromTypeId(typeId)
        return type && <TypeTagForTest type={type} />
      })
    },
    {
      title: 'Final type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      render: data => {
        const typeId = data
        const type = getTypeFromTypeId(typeId)
        return type && <TypeTagForTest type={type} />
      }
    },
  ]

  const addDicom = () => {
    const copiedDicomDataSource = [...dicomDataSource]
    const newData = {
      key: uid(),
      study_desc: undefined,
      series_desc: undefined,
      series_protocol: undefined,
      type: undefined,
      recognized_types: [],
    }
    const addedData = [...copiedDicomDataSource, newData]
    return setDicomDataSource(addedData)
  }

  const addNifti = () => {
    const copiedNiftiDataSource = [...niftiDataSource]
    const newData = {
      key: uid(),
      name: undefined,
      type: undefined,
      recognized_types: []
    }
    const addedData = [...copiedNiftiDataSource, newData]
    setNiftiDataSource(addedData)
  }

  const column_editable = (column, saveFunction) => column.map(col => {
    if (!col.editable) return col
    return {
      ...col,
      onCell: (record, _) => ({
        record,
        ...col,
        handleSave: row => saveFunction(row, col.dataIndex),
      })
    }
  })

  const saveEditCell = (dataSource, setDataSource) => (row, dataIndex) => {
    const copiedDataSource = [...dataSource]
    const target = copiedDataSource.find(item => item.key === row.key)
    if (target) {
      target[dataIndex] = row[dataIndex]
    }
    setDataSource(copiedDataSource)
    onTest()
  }

  const modalityHintSetFieldsValue = filedName => value => {
    const hints = value[filedName]
    
    const copiedList = [...imageTypeList]
    const targetType = copiedList.find(type => type.id === selectedType.id)
    if (targetType) {
      targetType[filedName] = hints
      targetType.edit = true
    }
    setImageTypeList(copiedList)
  }

  const LoadingUI = () => {
    return (parseLength !== 0
      ? (
        <div>
          {`Parsing files... ${parseCount}/${parseLength}`}
          <br />
          <br />
          <Progress
            type="circle"
            percent={progress}
            status={exception ? "exception" : ""}
          />
        </div>
    ) : (
      <Space direction="vertical" align="center">
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color="#0D6E6D"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        <h4>Accepting files ...</h4>
      </Space>)
    )
  }

  const [dicomSelectedRows, setDicomSelectedRows] = useState([])
  const [niftiSelectedRows, setNiftiSelectedRows] = useState([])

  const rowSelection = (selectedRows, setSelectedRows) => ({
    selectedKeys: selectedRows?.map(row => row?.key),
    selectedRows,
    setSelectedRows,
    initSelected : () => setSelectedRows([]),
    onSelect: (record, selected, _, e) => {
      let copiedSelectedRows = [...selectedRows]
      if (selected) {
        copiedSelectedRows = [...copiedSelectedRows, record]
      }
      else {
        copiedSelectedRows = copiedSelectedRows.filter(row => row.key !== record.key)
      }
      copiedSelectedRows.sort((a,b) => b.key - a.key)
      setSelectedRows(copiedSelectedRows)
    },
    onSelectAll : (selected, _, changeRows) => {
      let copiedSelectedRows =  [...selectedRows]
      if (selected) {
        copiedSelectedRows = [...copiedSelectedRows, ...changeRows]
      }
      else {
        const deSelectedKeys = changeRows.map(row => row.key)
        copiedSelectedRows = copiedSelectedRows.filter(row => !deSelectedKeys.includes(row.key))
      }
      copiedSelectedRows.sort((a,b) => b.key - a.key)
      setSelectedRows(copiedSelectedRows)
    },
  })

  useEffect(() => {
    const copiedResolveds = [...resolveds]
    const seriesList = []
    const niftiList = []
    copiedResolveds.forEach(patient => {
      patient.studies.forEach(study => {
        study.series.forEach(series => {
          const newData = {
            key: uid(),
            study_desc: study.desc || "",
            series_desc: series.desc || "",
            series_protocol: series.protocol || "",
            type: undefined,
            recognized_types: [],
          }
          seriesList.push(newData)
        })
        study.blobs.forEach(blob => {
          const newData = {
            key: uid(),
            name: blob.name,
            type: undefined,
            recognized_types: []
          }
          niftiList.push(newData)
        })
      })
    })
    unresolveds.forEach(blob => {
      const newData = {
        key: uid(),
        name: blob.name,
        type: undefined,
        recognized_types: []
      }
      niftiList.push(newData)
    })
    const addedDicomDataSource = [...dicomDataSource, ...seriesList]
    const addedNiftiDataSource = [...niftiDataSource, ...niftiList]
    setDicomDataSource(addedDicomDataSource)
    setNiftiDataSource(addedNiftiDataSource)
    
    dispatchDicomDrop({type: 'CLEAR'})
  },[resolveds, unresolveds])

  const removeDataRow = (rowSelection, dataSource, setDataSource) => {
    const delTargetKeys = rowSelection.selectedKeys
    const copiedDataSource = [...dataSource]
    const filteredDataSource = copiedDataSource.filter(item => !delTargetKeys.includes(item.key))
    setDataSource(filteredDataSource)
    rowSelection.initSelected()
  } 

  const dicomRowSelection = rowSelection(dicomSelectedRows, setDicomSelectedRows)
  const niftiRowSelection = rowSelection(niftiSelectedRows, setNiftiSelectedRows)

  // 입력값이 바뀔때 마다 Test 진행
  useEffect(() => {
    onTest()
  },[selectedType, imageTypeList, dicomDataSource.length, niftiDataSource.length])

  const onEdittedTypesReset = type => {
    const copiedList = [...imageTypeList]
    const targetType = copiedList.find(item => item.id === type.id)
    if (targetType) {
      const inStoreTargetType = imageTypeListInStore.find(item => item.id === type.id)
      targetType.dicom_hints = inStoreTargetType.dicom_hints
      targetType.image_hints = inStoreTargetType.image_hints
      delete targetType.edit
    }
    setImageTypeList(copiedList)
    setSelectedTypeId(type.id)
  }

  const DivideLine = () => {
    const lineColor = 'rgba(255,255,255,0.1)'

    return (
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginLeft: 'auto', borderLeft: `1px solid ${lineColor}`, marginRight: 'auto'}}></div>
      </div>
    )
  }

  return (
    <div
      style={{
        width: '95%',
        margin: '20px auto',
      }}
    >
      <Row gutter={[8, 4]} align="middle" justify="end" style={{marginBottom: 40}}>
        <Col span={6} >
          <h1>Image type binding comparison</h1>
        </Col>
        <Col span={12}>
          <FileUpload 
            styleInput={{width: '100%'}}
            msg="Drag & drop files or folders here for test"
            dropped={true}
            anonymizeVisible={false}
            onDrop={onDrop}
            dispatchDicomDrop={dispatchDicomDrop}
          />
        </Col>
        <Col span={6} flex={2}>
          <Space direction="horizontal" style={{width: '100%', justifyContent: 'right'}} >
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleOk} type='primary'>
              OK
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Row gutter={[12, 8]}>
        <Col span={leftColumnSpan} style={{display: 'flex', alignItems: 'center'}}>
          <h3 style={{display: 'inline-block', marginRight: 8}}>History: </h3>
          <TypographyDesc style={{display: 'inline-block'}}>
            {editedTypes.map(type => <TypeTagForTest type={type} closable={true}/>)}
          </TypographyDesc>
        </Col>
        <Col span={spaceColumnSpan}>
          <DivideLine />
        </Col>
        <Col span={rightColumnSpan}>
          <h3 style={{display: 'inline'}}>Target: </h3>
          <Select
            showSearch
            style={{width: '50%'}}
            options={selectOptions} 
            size={'large'}
            onChange={onChange}
            onSearch={onChange}
            value={selectedType?.short}
            placeholder="Select a imagetype"
          />
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col span={leftColumnSpan} style={{marginTop: 50}}>
          <Row>
            <Col span={12}>
              <h3>DICOM</h3>
            </Col>
            <Col span={12}>
              <Space style={{float: 'right'}}>
                <Button type='primary' onClick={addDicom}>Add</Button>
                <Button onClick={() => removeDataRow(dicomRowSelection, dicomDataSource, setDicomDataSource)}>Remove</Button>
              </Space>
            </Col>
          </Row>
          <Table 
            columns={column_editable(dicom_columns, saveEditCell(dicomDataSource, setDicomDataSource))}
            dataSource={dicomDataSource}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              }
            }}
            rowClassName={() => 'editable-row'}
            pagination={dicomDataSource.length < 10 ? false : true}
            size='small'
            rowSelection={dicomRowSelection}
          />
        </Col>
        <Col span={spaceColumnSpan}>
          <DivideLine />
        </Col>
        <Col span={rightColumnSpan} style={{marginTop: 50}}>
          {selectedTypeId && 
            <>
              <h3>DICOM Hint</h3>
              <br />
              {selectedType &&
                <Form.Item>
                  <ModalityHint
                    fieldName="dicom_hints"
                    value={selectedType?.dicom_hints || []}
                    setFieldsValue={modalityHintSetFieldsValue("dicom_hints")}
                  />
                </Form.Item>
              }  
            </>
          }
          
        </Col>
      </Row>
      <Row gutter={[12, 8]}>
        <Col span={leftColumnSpan} style={{marginTop: 50, marginBottom: 100}}>
          <Row>
            <Col span={12}>
              <h3>NIfTI</h3>  
            </Col>
            <Col span={12}>
              <Space style={{float: 'right'}}>
                <Button type='primary' onClick={addNifti}>Add</Button>
                <Button onClick={() => removeDataRow(niftiRowSelection, niftiDataSource, setNiftiDataSource)}>Remove</Button>
              </Space>
            </Col>
          </Row>
          <Table 
            columns={column_editable(nifti_columns, saveEditCell(niftiDataSource, setNiftiDataSource))}
            dataSource={niftiDataSource}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              }
            }}
            rowClassName={() => 'editable-row'}
            pagination={niftiDataSource.length < 10 && false}
            size='small'
            rowSelection={niftiRowSelection}
          />
        </Col>
        <Col span={spaceColumnSpan}>
          <DivideLine />
        </Col>
        <Col span={rightColumnSpan} style={{marginTop: 50}}>
          {selectedTypeId &&
            <>
              <h3>NIfTI Hint</h3>
              {selectedType &&
                <Form.Item>
                  <ModalityHint 
                    fieldName="image_hints"
                    value={selectedType?.image_hints || []}
                    setFieldsValue={modalityHintSetFieldsValue("image_hints")}
                  />
                </Form.Item>
              }
            </>
          }
        </Col>
      </Row>
      <TransparentBGModal  // Loading & Progress Modal
        open={dropLoading}
        footer={null}
        closable={false}
        zIndex={2000}
        centered
        bodyStyle={{
          backgroundColor : 'rgba(0,0,0,0)'
        }}
      >
        <div style={{
          display: "flex",
          height : '100%',
          justifyContent: "center",
          alignItems: "center",
          textAlign : 'center'
        }}>
          <LoadingUI />
        </div>
      </TransparentBGModal>
    </div>
  )
}

export default ModalityTest