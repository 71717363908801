import React, {useEffect, useState} from "react";
import {Modal, Button, Table, Cascader, Space, Popconfirm, Tooltip} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CornerstoneElementPlain from './cornerstoneElementPlain'
import * as cornerstoneNIFTIImageLoader from "cornerstone-nifti-image-loader";
import ImageTypeSelect from "./imageTypeSelect";
import * as cornerstone from "cornerstone-core";
import { msgError } from "../redux/modules/message";
import { usePatientSearchInResultContext } from "../context/patientSearchInResultContext";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";

function UnresolvedList({resolveds, unresolveds, binds, loading, update:dispatch}) {
  const [stack, setStack] = useState({imageIds: [], currentImageIdIndex: 0});
  const [showModal, setShowModal] = useState(false);
  const [modalColormap, setModalColormap] = useState("gray");
  const {getShortFromTypeId, getTypeFromTypeId} = useGetImageTypeHooks()

  const rowSelection = {
    selectedRowKeys: unresolveds.filter(data => data.selected).map(data => data.key),
    onChange: (selectedRowKeys, selectedRows) => {
      const newData = [...unresolveds]
      newData.forEach(data => {
        data.selected = selectedRowKeys.includes(data.key)
      })
      dispatch({type:'UPDATE_ONLY', payload:{unresolveds: newData}})
    }
  }
  
  const {
    getColumnSearchProps, 
    initializeFilterSorter, 
    sortOrder, 
    onTableChange,
    showSorterTooltip
  } = usePatientSearchInResultContext()

  const handleDelete = (key) => {
    const newData = [...unresolveds.filter((item) => item.key !== key)]
    dispatch({ type: "UPDATE_ONLY", payload: { unresolveds: newData } })
  };

  const handleBlobTypeSelectChange = record => (val, option) => {
    const newData = [...unresolveds]
    newData.forEach((d) => {
      if (d.key === record.key) {
        d.type = val
      }
    })
    dispatch({ type: 'UPDATE_ONLY', payload: { unresolveds: newData } })
  }

  const handleBindChange = record => (val, option) => {
    if (val) {
      unresolveds = unresolveds.filter(i => i.key !== record.key)
      // add this to new position
      resolveds.forEach(p => {
        if (p.key === val[0]) {
          // add it to new study
          p.studies.forEach(s => {
            if (s.key === val[1]) {
              record.bind = val
              s.blobs.push(record) // add
            }
          })
        }
      })
      dispatch({type:'REPLACE_ONLY', payload:{resolveds, unresolveds}})
    }
  }

  const columns = [
    {...getColumnSearchProps({
        title: "Path",
        dataIndex: "name",
        key: "name",
        width: "40%",
        sorter: (a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
        sortOrder : sortOrder('name'),
    })
    },
    {
      title: "Bind",
      key: "bind",
      width: "18%",
      render: (text, record, ridx) => (
        <div data-col="bind">
          <Cascader
            options={binds}
            style={{ width: "100%" }}
            onChange={handleBindChange(record)}
            dropdownMatchSelectWidth={false}
            defaultValue={record?.bind}
          />
        </div>
      ),
    },
    {
      title: "Type",
      key: "type",
      sorter: (a, b) => 
        (getShortFromTypeId(a.type)?.toUpperCase()||"")
        > (getShortFromTypeId(b.type)?.toUpperCase()||"") ? 1 : -1,
      sortOrder : sortOrder('type'),
      showSorterTooltip : showSorterTooltip('type'),
      render: (text, record, ridx) => (
        <div data-col="type">
          <ImageTypeSelect
            style={{ width: "100%" }}
            onChange={handleBlobTypeSelectChange(record)}
            dropdownMatchSelectWidth={false}
            defaultValue={undefined}
            value={record.type}
            row_index={record}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      align :'center',
      render: (record) => (
        <Space>
          <Button onClick={(e) => {
            dispatch({type:'UPDATE_ONLY', payload:{loading: true}})
            doModal(e, record)
          }}>View</Button>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={()=>handleDelete(record.key)}
          >
            <Button>Remove</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const doModal = async (e, record) => {
    // cornerstoneNIFTIImageLoader.external.cornerstone = cornerstone;
    cornerstoneNIFTIImageLoader.nifti.localMode = false;

    const tmpURL = URL.createObjectURL(record.file);
    const niftiPath = `nifti:${tmpURL}`;

    const image = await cornerstone.loadAndCacheImage(`${niftiPath}`);

    const multiFrameModule = cornerstone.metaData.get(
      "multiFrameModule",
      image.imageId
    );
    if (multiFrameModule !== undefined) {
      const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
      const imageIdObject = ImageId.fromURL(niftiPath);
      const numberOfSlices = multiFrameModule.numberOfFrames;
      const imageIds = Array.from(
        Array(numberOfSlices),
        (_, i) =>
          `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i},t-0`
      );

      const stack = {
        imageIds: imageIds,
        currentImageIdIndex: Math.floor(numberOfSlices / 2),
      };

      setStack(stack);
      const currImageType = getTypeFromTypeId(record.type)
      currImageType ? setModalColormap(currImageType.cmap_id) : setModalColormap("gray")
      setShowModal(true);
      window.URL.revokeObjectURL(tmpURL);
    } else {
      dispatch(msgError("Unable to load image"))
    }
    dispatch({type:'UPDATE_ONLY', payload:{loading: false}})
  };

  const handleClose = () => setShowModal(false)

  const handleRemoveSelected = () => {
    const newUnresolveds = [...unresolveds]
    const filteredUnresolveds = newUnresolveds.filter(p => !p.selected)

    dispatch({type: 'UPDATE_AND_REBUILD_BINDS', payload: {unresolveds: filteredUnresolveds}})
  }

  const handleRemoveNoImageType = () => {
    const newUnresolveds = [...unresolveds].filter(data => data.type)
    
    dispatch({type:'UPDATE_ONLY', payload:{unresolveds : newUnresolveds}})
  }

  return (
    <div>
      <h3 style={{display: 'inline-block', marginRight: 20}}>Unresolved files (count:{unresolveds.length})</h3>
      <Space>
        <Popconfirm
          title={"Are you sure?"}
          icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
          onConfirm={handleRemoveSelected}
        >
          <Tooltip title="Remove Selected" mouseEnterDelay={0.5}>
            <Button icon={<FontAwesomeIcon style={{marginRight:8}} icon={faTrash}/>}> Selected </Button>
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          title={"Are you sure?"}
          icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
          onConfirm={handleRemoveNoImageType}
        >
          <Tooltip title="Remove No image type" mouseEnterDelay={0.5}>
            <Button icon={<FontAwesomeIcon style={{marginRight:8}} icon={faTrash}/>}>No image type</Button>
          </Tooltip>
        </Popconfirm>
        <Button 
          icon={<FontAwesomeIcon style={{marginRight:8}} icon={faUndo}/>}
          onClick={initializeFilterSorter}
        >
          Clear Filter
        </Button>
      </Space>
      <Table
        size="small"
        loading={loading}
        columns={columns}
        dataSource={unresolveds}
        rowSelection={rowSelection}
        onChange={onTableChange}
      />
      <Modal
        // title="Basic Modal"
        open={showModal}
        closable={false}
        okText="Close"
        okType="default"
        onOk={handleClose}
        onCancel={handleClose}
        cancelButtonProps={{ style: { display: "none" } }}
        width='fit-content'
      >
        <CornerstoneElementPlain
          stack={stack}
          colormap={modalColormap}
        />
      </Modal>
    </div>
  );
}

export default UnresolvedList;
