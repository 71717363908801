import {Input, Space, Row, Col, Button, Card, Form, InputNumber, Select, Switch, Divider, Cascader} from "antd";
import React, {useState, useEffect, Fragment} from "react";
import {ModelView} from './modelView'
import TaskInputSelect, {MODE} from "./taskInputSelect";
import ExpressionOfCondition from "./expressionOfConditions";
import ExpressionOfROI from "./expressionOfROI";
import ImageTypeSelect from "./imageTypeSelect";
import "./taskConfigs.css"
import {DownOutlined, InfoCircleOutlined, UpOutlined} from "@ant-design/icons";
import {Editor as JsonEditor} from '@monaco-editor/react';
import ReportConfig from "./report/reportConfig";
import { useSelector } from "react-redux";
import TrackROIFields from "./TrackROIFields";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import { TASK_DELIMITER } from "../lib/taskUtil";

const {Option} = Select;

export const TaskConfig = (props) => {
  const {config, inputs, candidates: taskInputCandidates} = props.task

  const [jsonHeight, setJsonHeight] = useState(100)

  useEffect(() => {
    // task Config 열렸을 때, 배경에 대한 scroll 방지
    const mainLayout = document.getElementById('main-layout')
    mainLayout?.classList?.add('no-scroll')

    return () => mainLayout?.classList?.remove('no-scroll')
  },[])

  const loadedReportTemplate = useSelector((state) => state.pipeline?.reportTemplate?.load)


  useEffect(() => {
    const mainLayout = document.getElementById('main-layout')
    mainLayout?.classList?.add('no-scroll')

    return () => mainLayout?.classList?.remove('no-scroll')
  },[])


  const {getTypeFromShort} = useGetImageTypeHooks()
  // const onSelectModelChange = (name) => (val, option) => {
  //   props.onSelectModelChange(name, val, option)
  // }

  const findModel = model => {
    return model ? props.models.find(m => m.name === model[0] && m.version === model[1]) : undefined
  }

  // console.count('TaskConfig rendered!') // useWatch 때문에 2번 불리는 군..
  return (
    <>
      {(props.template_config === null || props.template_config.length === 0) ? null:
        <Card title="Task configuration" size="small">
          {props.template_config.map((item, item_index) => {
            const name = item.name;
            const options = item.options;
            // const val = config.hasOwnProperty(name) ? config[name] : options;
            let val = item.default;
            // 저장된 값이 있으면 덮어쓰기
            if (config.hasOwnProperty(name)) {
              val = config[name];
            }
            let required = item.required;
            let children = null;
            let isSwitch = false;
            let multiple = false;
            let append = null;
            let vertical = item?.vertical
            let desc = item?.desc
            const hide = item?.hide
            switch (item.type) {
              case 'string':
                children = <Input />
                break
              case 'number':
                const typeHint = item['type-hint']
                const range = item['type-hint-range']
                children = (
                  <InputNumber
                    min={range ? range[0] : Number.MIN_SAFE_INTEGER}
                    max={range ? range[1] : Number.MAX_SAFE_INTEGER}
                    step={typeHint === 'int' ? 1 : range ? range[1] / 100 : 1}
                    precision={typeHint === 'int' ? 0 : undefined}
                    size="small"
                    key={name}/>
                )
                break
              case 'numbers':
                children = (fields) => {
                  return (
                    <Form.Item
                      label={name}
                      labelWrap={true}
                      labelAlign="left"
                      labelCol={vertical ? { span: 24 }:{span: 6}}
                      wrapperCol={vertical ? { span: 24 }:{span: 18}}
                    >
                      <Space key={name + ' space'}>
                        {fields.map(field => (
                          <Form.Item
                            name={field.name}
                            key={field.key}
                            label={field.label}
                          >
                            <InputNumber/>
                          </Form.Item>
                        ))}
                      </Space>
                    </Form.Item>
                  )
                }
                multiple = true
                break
              case 'boolean':
                children = (
                  <Switch defaultChecked={val} />
                )
                isSwitch = true
                break
              case 'select':
                children =
                  <Select
                    dropdownMatchSelectWidth={false}
                    placeholder="Select an option"
                    value={val}
                    item_index={item_index}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {options.map((option, oidx2) => (
                      <Option
                        key={oidx2} value={option} option_index={oidx2}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                break
              case 'select-imagetype':
                children =
                  <ImageTypeSelect
                    style={{ width: "100%" }}
                    dropdownMatchSelectWidth={false}
                    imageTypes={props.imageTypeSelected}
                  />
                break
              case 'select-model':
                const modelMap = new Map()
                props.models.forEach(m => {
                  if (modelMap.has(m.name)) {
                    modelMap.get(m.name).push(m.version)
                  }
                  else {
                    modelMap.set(m.name, [m.version])
                  }
                })
                const cascaderOptions = [...modelMap.keys()].sort((a, b) => {
                  a = a.toLowerCase();
                  b = b.toLowerCase();
                  if( a == b) return 0;
                  return a < b ? -1 : 1;
                }).map(key => {
                  return {
                    label: key,
                    value: key,
                    children: modelMap.get(key).sort().map(version => {
                      return ({label: version, value: version})
                    })
                  }
                })
                children = (
                  <Cascader
                    // disabled={record.disabled}
                    style={{height: '100%'}}
                    popupClassName="ModelCascader"
                    showSearch={true}
                    options={cascaderOptions}
                    // onChange={onSelectModelChange(name)}
                    // 초기값은 form.item initialValue 에서?
                    // defaultValue={record?.bind}
                    getPopupContainer={trigger => trigger.parentNode}
                  />
                )
                const model = findModel(config?.model)
                append = (
                  <>
                    {val === undefined && model === undefined ? null :
                      <>
                        {model ?
                          <>
                            <ModelView
                              model={model}
                              taskInputCandidates={taskInputCandidates}
                              setFieldsValue={props.setFieldsValue}
                              longitudinal={props.longitudinal}
                            />
                          </>
                          : null}
                      </>
                    }
                  </>
                )
                break
              case 'report-configuration':
                children = null
                append = (
                  <ReportConfig
                    name={name}
                    value={val}
                    candidates={taskInputCandidates}
                    setFieldsValue={props.setFieldsValue}
                    loadedTemplate={loadedReportTemplate}
                    longitudinal={props.longitudinal}
                  />
                )
                break
              case 'expression-roi':
                children = <ExpressionOfROI
                  name={name}
                  value={val}
                  input={inputs?.[0]}
                  setFieldsValue={props.setFieldsValue}
                />
                break
              case 'expression':
                children = <ExpressionOfCondition value={val} inputs={inputs}/>
                break
              case 'json':
                if (val) {
                  const lineCount = val.split('\n').length - 1
                  const elemHeight = 20 * lineCount
                  let height = jsonHeight
                  if (height < elemHeight) {
                    while (height < elemHeight) {
                      if (height >= 800) {
                        break
                      }
                      height *= 2
                    }
                    setJsonHeight(height)
                  }
                }
                children = <div>
                  <JsonEditor
                    height={jsonHeight}
                    language="json"
                    defaultValue={val}
                    theme="vs-dark"
                    onChange={(value, evt) => props.setFieldsValue({[name]: value})}
                  />
                  <div style={{position: "absolute", bottom: 0}}>
                    <Button key="shrink" size="small" icon={<UpOutlined/>} disabled={jsonHeight === 100} onClick={() => setJsonHeight(jsonHeight * 0.5 < 100 ? 100 : jsonHeight * 0.5)} />
                    <Button key="grow" size="small" icon={<DownOutlined/>} disabled={jsonHeight === 800} onClick={() => setJsonHeight(jsonHeight * 2 > 800 ? 800 : jsonHeight * 2)} />
                  </div>
                </div>
                break
              case 'track-roi':
              {
                const target = inputs?.[0]?.selected
                const short = target?.includes(TASK_DELIMITER) ? target.split(TASK_DELIMITER)[0] : target
                const type = getTypeFromShort(short)
                children = <TrackROIFields imageType={type} value={val}/>
                break
              }
              default:
                children = null
            }
            let parent = (
                <Fragment key={name}>
                  <Form.Item
                    hidden={hide}
                    label={name}
                    name={name}
                    key={name}
                    initialValue={val}
                    valuePropName={isSwitch ? "checked" : "value"}
                    required={required}
                    labelWrap={true}
                    labelAlign="left"
                    labelCol={vertical ? { span: 24 }:{span: 6}}
                    wrapperCol={vertical ? { span: 24 }:{span: 18}}
                    tooltip={desc ? {
                      title: desc,
                      icon: <InfoCircleOutlined style={{color: 'rgba(255, 255, 255, 0.5)'}}/>,
                      getPopupContainer: trigger => trigger.parentNode
                    }:null}
                  >
                    {children}
                  </Form.Item>
                  {append}
                </Fragment>
            )
            if (multiple) {
              parent = (
                <Form.List
                  label={name}
                  name={name}
                  key={name}
                  required={required}
                  // initialValue={val}
                >
                  {children}
                </Form.List>
              )
            }
            return parent;
          })}
        </Card>
      }
    </>
  );
}
