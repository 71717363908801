import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import axios from "axios";
import * as actions from "../../redux/modules/view";
import {
  getUrlSuccess,
  getUrlFail,
  getSeriesUrlSuccess,
  getSeriesUrlFail,
  getNiftiUrlSuccess,
  getNiftiUrlFail,
  clearUrlCacheSuccess,
  clearUrlCacheFail
} from "../../redux/modules/view";
import {getCookie} from "../../lib/cookie";
import {getContext} from "@redux-saga/core/effects";
import {msgError} from "../../redux/modules/message";

function clearPresignedUrlCacheApi(url) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/view/clearCache',
    {url},
    {withCredentials: true});
}


function* clearPresignedUrlCacheSaga({ payload }) {
  try {
    const { url } = payload;
    const { data } = yield call(clearPresignedUrlCacheApi, url);
    yield put(clearUrlCacheSuccess(data));
  } catch (error) {
    yield put(clearUrlCacheFail());
    yield put(msgError(error))
  }
}


function getPresignedUrlApi(run_id, pid, sids, extra) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/view/resultPresigned',
    {pid, sids, run_id, extra},
    {withCredentials: true});
}

function* getPresignedUrlSaga({ payload }) {
  try {
    const { run_id, pid, sids, extra, action='push'} = payload;
    const { data } = yield call(getPresignedUrlApi, run_id, pid, sids, extra);
    yield put(getUrlSuccess(data));
    
    if (!payload?.stay) {
      const history = yield getContext('history');
      let locationState = {}
      if (action === 'push' || payload?.stay) {
        const display = yield select(state => state.analysis.display);
        locationState = {...display, volumeInfo: payload}
      }
      else {
        const historyState = {...history.location.state}
        locationState = {...historyState, volumeInfo: payload}
      }
      
      if (sids.length === 1) {
        history[action]('/view-volume', locationState)
      }
      else {
        history[action]('/view-volume2', locationState)
      }
    }
  } catch (error) {
    yield put(getUrlFail());
    yield put(msgError(error))
  }
}

function getSeriesPresignedUrlApi(pid, sid, sn) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/view/presigned',
    {type : 'dcm','pid': pid, 'sid': sid, 'sn': sn},
    {withCredentials: true});
}

function* getSeriesPresignedUrlSaga({ payload }) {
  try {
    const { pid, sid, sn } = payload;
    const { data } = yield call(getSeriesPresignedUrlApi, pid, sid, sn);
    yield put(getSeriesUrlSuccess(data));
  } catch (error) {
    yield put(getSeriesUrlFail());
    yield put(msgError(error))
  }
}

function getNiftiPresignedUrlApi(path) {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  return axios.post('/api/view/presigned',
    { type : 'nifti', 'path' : path},
    {withCredentials: true});
}

function* getNiftiPresignedUrlSaga({ payload }) {
  try {
    const { path } = payload;
    const { data } = yield call(getNiftiPresignedUrlApi, path);
    yield put(getNiftiUrlSuccess(data));
  } catch (error) {
    yield put(getNiftiUrlFail());
    yield put(msgError(error))
  }
}

function* watchGetPresignedUrl() {
  yield takeEvery(actions.GET_URL, getPresignedUrlSaga);
  yield takeEvery(actions.GET_SERIES_URL, getSeriesPresignedUrlSaga);
  yield takeEvery(actions.GET_NIFTI_URL, getNiftiPresignedUrlSaga);
  yield takeEvery(actions.CLEAR_URL_CACHE, clearPresignedUrlCacheSaga);
}

export default function* verifySaga() {
  yield all([
    fork(watchGetPresignedUrl),
  ]);
}