import React, {useState, useEffect, useRef} from "react";
import {Button, Form, Input, Row, Col, Space } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import { get, add, update, setTotal2, clear, checkName, getPagedPatients } from "../redux/modules/dataset"
import {useHistory, useLocation} from "react-router-dom";
import CaseSearchBox from "./caseSearchBox";
import TableTransfer from "./tableTransfer2"
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import {useTitle} from "../hooks/title";
import { useDuplicateCheck } from "../context/duplicateCheckContext";
import { TextAreaWithCount } from "./styledComponent";
import { getTextByte } from "../lib/getTextByte";

function Dataset() {
  useTitle("Dataset");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const descValue = Form.useWatch('desc', form);

  const config = useSelector(state => state.auth?.user?.config);

  const patients = useSelector(state => state.dataset?.patients)
  const included_patients = useSelector(state => state.dataset?.included_patients) || []
  const included = useSelector(state=>state.dataset?.included)
  const loading = useSelector(state=>state.dataset.loading)
  const total = useSelector(state=>state.dataset?.total)
  const total2 = useSelector(state=>state.dataset?.total2)
  const dataset = useSelector(state=>state.dataset?.dataset)

  const enumMode = Object.freeze({CREATE: 0, EDIT: 1})
  const [mode, setMode] = useState(enumMode.CREATE);
  const [searched, setSearched] = useState(false);
  // const [searchParam, setSearchParam] = useState({});
  const searchParams = useRef()
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(config ? config.per_page : 10);
  const [targetKeys, setTargetKeys] = useState([]);
  const [dataSourceAccumulated, setDataSourceAccumulated] = useState([]);
  const clean = useRef(true)
  const transferRef = useRef(null)

  const checkLoading = useSelector(state => state.dataset?.check?.loading)
  const checkedName = useSelector(state => state.dataset?.check?.name)
  const checkedNameError = useSelector(state => state.dataset?.check?.nameError)

  useEffect(() => {
    if (patients?.length + included_patients?.length > 0) {
      setDataSourceAccumulated(orderBy(
        uniqBy(
          dataSourceAccumulated.concat(patients, included_patients),
          "key"
        ),
        'key', 'desc'
      ));
    }
    if (clean.current) {
      setTargetKeys(included.map(item => item.key))
    }
    else {
      dispatch(setTotal2(targetKeys.length))
    }
  },[patients, included_patients])


  useEffect(() => {
    if (dataset !== undefined) {
      form.setFieldsValue({
        id: dataset.id,
        name: dataset.name,
        desc: dataset.desc,
      });
    }
  }, [dataset])

  // 최초 analysis db 조회
  useEffect(() => {
    if (location.state?.key !== undefined) {
      setMode(enumMode.EDIT);
    }
    dispatch(get({
      id: location.state?.key,
      pagination: {
        current,
        pageSize
      }
    }))
    window.scrollTo(0, 0)
    return () => dispatch(clear())
  }, [])

  const handleSearch = (keyword, category) => {
    setSearched(keyword ? true : false);
    searchParams.current = {keyword, category}
    // setSearchParam({keyword, category})
    fetch({
      // id: location.state?.key,
      search: {keyword, category},
      includes: targetKeys,
      pagination: {
        current,
        pageSize
      }
    })
  }

  const onFinish = values => {
    if (mode === enumMode.CREATE) {
      dispatch(add({values, includes: targetKeys}))
    }
    else {
      dispatch(update({values, includes: targetKeys}));
    }
  };

  const handleCancel = () => history.goBack();

  const fetch = ({
    pagination = {current, pageSize},
    pagination2 = {current, pageSize},
    search= undefined,
    includes = undefined,
    hints = undefined
  }) => {
    let payload = {
      id: location.state?.key,
      pagination: {
        page: pagination.current,
        per_page: pagination.pageSize
      },
      pagination2: {
        page: pagination2.current,
        per_page: pagination2.pageSize
      },
      includes,
      hints,
    }
    if (search) { // handle search 에서 searched 가 state 변경 전에 이곳까지 오게되어 제대로 반영이 안되어 search로 변경 진행
      payload.search = search;
    }
    /*
      get 함수가 initial, changePage, search 에서 사용되는 중.
      이로 인해 dataset form 값이 form.setFieldsValue가 실행되어 form 입력 값이 초기화됨.
      이에 따라 초기 값 불러올 떄엔 get, 검색 및 pageChange 시엔 getPagedPatients 로 dispatch 시행.
    */
    dispatch(getPagedPatients(payload)); 
  };

  const sortTargetKeys = (a, b) => {
    const ia = parseInt(a.split('-')[0])
    const ib = parseInt(b.split('-')[0])
    if (ia > ib) {
      return -1;
    }
    if (ia < ib) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  const onChangeTransfer = (targetKeys, direction, moveKeys) => {
    clean.current = false
    const paginationR = transferRef.current?.getPaginationR()
    targetKeys.sort(sortTargetKeys)
    setTargetKeys(targetKeys);

    // check if need fetch more
    const hints = []
    let current = paginationR.current
    // current 2 total 15 -> 20 > 15
    // curretn 2 total 10 -> 20 > 10
    const maxPage = Math.ceil(paginationR.total/paginationR.pageSize)
    if (current > maxPage) {
      current = current -1
    }
    const sliceStart = (current - 1) * paginationR.pageSize;
    const sliceEnd = current * paginationR.pageSize;
    const targetKeysPaged = targetKeys.slice(sliceStart, sliceEnd)
    targetKeysPaged.forEach((k, i) => {
      const exist = dataSourceAccumulated.find(item => item?.key === k)
      if (exist === undefined) {
        hints.push(k)
      }
    })
    if (hints.length > 0) {
      fetch({hints})
    }

    dispatch(setTotal2(targetKeys.length))
  }

  const { onFieldsChange, lastMatchValidator } = useDuplicateCheck({
    loading : checkLoading,
    form : form,
    fields : [{
      fieldName : 'name',
      action : checkName,
      result : checkedName,
      error : checkedNameError
    }],
  })

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Form
        form={form}
        name="dataset"
        className="login-form"
        labelCol={{span: 2}}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
      >
        <Row gutter={[8, 4]} align="middle">
          <Col span={6}>
            <h1>Dataset</h1>  
          </Col>
          <Col span={12}></Col>
          <Col span={6} flex="1">
            <div>
              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right" }}
                >
                  <Button onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    OK
                  </Button>
              </Space>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <br/>
        <Form.Item name="id" style={{display: 'none'}}>
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              whitespace : true,
              message: 'Please input your dataset name!',
            },
            {
              max: 256,
              message : 'Cannot exceed 256 letters'
            },
            {
              pattern : new RegExp(/^(?!.*[\\]).*$/gu),
              message : `The following special characters cannot be used. \\`
              // \asd 를 작성한 경우, \\asd 로 요청하고 있는지 확인 후 \\asd를 돌려받음, 
              // 이후, \asd가 입력되어 있기 때문에 다시 \\asd로 요청이가고 \\asd를 돌려받는 것이 반복됨.
              // 중복체크하는 부분들에는 \값을 못쓰도록 막아야 할 것으로 보임
            },
            lastMatchValidator('name')
          ]}
          hasFeedback
        >
          <Input 
            placeholder="My Dataset" 
            maxLength={256}
            showCount={{
              formatter: ({count, maxLength}) => 
                <span style={{color:'rgba(255,255,255,0.3)'}}>
                  {`${count}/${maxLength}`}
                </span>
            }}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="desc"
          rules={[
            {
              message: 'Please describe your dataset!',
            },
            () => ({
              validator(_, value) {
                if (!value || getTextByte(value) <= 65535) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Cannot exceed 65535 letters'));
              },
            }),
          ]}
        >
          <TextAreaWithCount 
            placeholder="Dataset description" 
            maxLength={65535}
            showCount={{
              formatter: ({ maxLength}) => `${getTextByte(descValue || "")}/${maxLength}`
            }}
          />
        </Form.Item>

        <CaseSearchBox
          style={{width:'45%'}}
          loading={loading}
          onSearch={handleSearch}
        />
        <br/>

        <TableTransfer
          ref={transferRef}
          dataSource={patients}
          dataSource2={included_patients}
          totalDataSource={dataSourceAccumulated}
          total={total}
          total2={total2}
          loading={loading}
          fetch={fetch}
          searchParams={searchParams.current}
          showSelectAll={false}
          selectAllLabels={[
            ({ selectedCount, totalCount}) => (
              <span style={{height: '100%'}}>
                {selectedCount > 0 ?
                  selectedCount > 1 ?
                    `${selectedCount}/${total} cases selected`:
                    selectedCount +' case selected' : <label style={{visibility:'hidden'}}>0</label>
                }
              </span>
            ),
            ({ selectedCount, totalCount }) => (
              <span>
                {selectedCount > 0 ?
                  selectedCount > 1 ?
                    `${selectedCount}/${total2} cases selected` :
                    selectedCount +' case selected' : <label style={{visibility:'hidden'}}>0</label>
                }
              </span>
            )
          ]}
          locale={{
            titles: [`all (total: ${total})`, `included (total: ${total2})`],
            itemUnit: "case",
            itemsUnit: "cases",
            notFoundContent: "no data"
          }}
          targetKeys={targetKeys}
          // onChange={setTargetKeys}
          onChange={onChangeTransfer}
        />
      </Form>
    </div>
  );
}

export default Dataset;

