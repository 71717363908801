import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Input, Row, Select, Tooltip} from "antd";
import {MenuOutlined, MinusCircleOutlined} from '@ant-design/icons';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));
const SortableItem = SortableElement((props) => <div {...props} />);
const SortableBody = SortableContainer((props) => <div {...props} />);

const {Option} = Select;

const ModalityHint = (props) => {
  const [expressions, setExpressions] = useState(props.value);
  useEffect(() => {
    setExpressions(props.value);
  }, [props.value])

  const dicom = props.fieldName.includes('dicom')
  const onClickAdd = () => {
    const newExpress = !expressions || expressions.length === 0 ? [{
      op: "include",
      target: dicom ? "Series description" : "File name",
      value: "",
      case_sensitive: false
    }] : [...expressions, {
      lop: "and",
      op: "include",
      target: dicom ? "Series description" : "File name",
      value: "",
      case_sensitive: false
    }];

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }
  const onClickRemove = (exprIndex) => (e) => {
    const newExpress = [...expressions];
    newExpress.splice(exprIndex, 1);
    if (exprIndex === 0 && newExpress.length > 0) {
      delete newExpress[0].lop
    }

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }
  const onLogicalOperatorChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].lop = value

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }
  const onTargetChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].target = value

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }
  const onOperatorChange = (exprIndex) => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].op = value

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }
  const onValueChange = exprIndex => e => {
    const newExpress = [...expressions];
    newExpress[exprIndex].value = e.target.value

    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }

  const onCaseSensitiveChante = exprIndex => e => {
    const newExpress = [...expressions];
    newExpress[exprIndex].case_sensitive = e.target.checked
    
    setExpressions(newExpress);
    props.setFieldsValue({[props.fieldName]: newExpress})
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = 'default';
    if (oldIndex === newIndex) { return }

    const newExpressions = [...expressions];
    const expr2move = newExpressions[oldIndex]
    newExpressions.splice(oldIndex, 1)
    // newIndex 가 falsy 이면 지우는 것임
    if (newIndex != null) {
      newExpressions.splice(newIndex, 0, expr2move)
    }

    newExpressions.forEach((expr, expr_index) => {
      if (expr_index === 0) {
        delete expr.lop
      }
      else {
        if (!expr.hasOwnProperty('lop')) {
          expr.lop = 'or'
        }
      }
    })

    setExpressions(newExpressions);
    props.setFieldsValue({[props.fieldName]: newExpressions})
  };

  let orGroupCount = 0

  return (
    <>
      {!expressions || expressions.length === 0 ?
        <Row gutter={[8, 4]} >
          <Col span={3}>
            <Button block type="primary" onClick={onClickAdd}> + </Button>
          </Col>
        </Row>
        :
        <SortableBody
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortStart={() => (document.body.style.cursor = 'grabbing')}
          onSortEnd={onSortEnd}
        >
          {
            expressions.map((item, expr_index) => {
              const lop = item.lop ?? "or"
              if (lop === 'or') orGroupCount += 1
              
              const target = item.target ?? (dicom ? "Series description" : "File name")
              const op = item.op ?? "include"
              const value = item.value ?? ""
              const caseSensitive = item.case_sensitive ?? false
              const firstColumn = expr_index === 0 ?
                <Button block type="primary" onClick={onClickAdd} className='drag-invisible'> + </Button>
                :
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  onChange={onLogicalOperatorChange(expr_index)}
                  defaultValue={lop}
                  value={lop}
                >
                  <Option key={1} value="and">{"and"}</Option>
                  <Option key={2} value="or">{"or"}</Option>
                </Select>;

              const lopSpan = 3
              const targetSpan = 6
              const opSpan = 4
              const inputSpan = 8
              const removeBtnSpan = 1
              const dragHandleSpan = 1
              const caseSensitiveSpan = 24 - (lopSpan + targetSpan + opSpan + inputSpan + removeBtnSpan + dragHandleSpan)

              return <SortableItem
                // collection={longitudinalWatched && index === 0 ? 1 : 0}
                // disabled={longitudinalWatched && index === 0 ? true : false}
                index={expr_index}
                // className={applyClass}
              >
                {/* {lop === 'or' && <TypographyDesc>{`Group ${orGroupCount}`}</TypographyDesc>} */}
                <Row key={expr_index} gutter={[8, 4]} style={{marginBottom: 5, marginTop: (lop === 'or' && expr_index !== 0) && 14}} align="middle">
                  <Col span={lopSpan}>
                    {firstColumn}
                  </Col>
                  <Col span={targetSpan}>
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      onChange={onTargetChange(expr_index)}
                      placeholder="Select DICOM header attribute"
                      defaultValue={target}
                      value={target}
                    >
                      {dicom ?
                        <>
                          <Option key={1} value="Series description">{"Series description"}</Option>
                          <Option key={2} value="Protocol name">{"Protocol name"}</Option>
                          <Option key={3} value="Study description">{"Study description"}</Option>
                        </>
                        :
                        <Option key={1} value="File Name">{"File Name"}</Option>}
                    </Select>
                  </Col>
                  <Col span={opSpan}>
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      onChange={onOperatorChange(expr_index)}
                      defaultValue={op}
                      value={op}
                    >
                      <Option key={1} value="include">{"include"}</Option>
                      <Option key={2} value="exclude">{"exclude"}</Option>
                      <Option key={3} value="exact match">{"exact match"}</Option>
                    </Select>
                  </Col>
                  <Col span={inputSpan}>
                    <Input
                      style={{width: '100%'}}
                      value={value}
                      onChange={onValueChange(expr_index)}/>
                  </Col>
                  <Col span={caseSensitiveSpan}>
                    <Tooltip title="Case sensitive">
                      <Checkbox
                        onChange={onCaseSensitiveChante(expr_index)}
                        defaultChecked={caseSensitive}
                        checked={caseSensitive}
                      />
                    </Tooltip>
                  </Col>
                  <Col span={removeBtnSpan}>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={onClickRemove(expr_index)}
                    />
                  </Col>
                  <Col span={removeBtnSpan}>
                    <DragHandle />
                  </Col>
                </Row>
              </SortableItem>
            })
          }
        </SortableBody>
      }
    </>
  );
}

export default ModalityHint;