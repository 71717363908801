import React, { useEffect } from 'react';
import {Form,Input,Checkbox,Button} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {register, checkUserName, checkEmail, checkClear} from '../redux/modules/auth'
import { useDuplicateCheck } from '../context/duplicateCheckContext';
import { formItemLayout, tailFormItemLayout } from '../constants';

const RegistrationForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const checkLoading = useSelector(state => state.auth?.check?.loading)
  const checkedUserName = useSelector(state => state.auth?.check?.userName)
  const checkedUserNameError = useSelector(state => state.auth?.check?.userNameError)
  const checkedEmail = useSelector(state => state.auth?.check?.email)
  const checkedEmailError = useSelector(state => state.auth?.check?.emailError)

  const onFinish = values => dispatch(register(JSON.stringify(values)))

  useEffect(() => {
    return () => dispatch(checkClear())
  }, [])
  

  const { onFieldsChange, lastMatchValidator } = useDuplicateCheck({
    loading : checkLoading,
    form : form,
    fields : [{
        fieldName : 'username',
        action : checkUserName,
        result : checkedUserName,
        error : checkedUserNameError
      },
      {
        fieldName : 'email',
        action : checkEmail,
        result : checkedEmail,
        error : checkedEmailError
      }],
  })

  return (
    <Form
    style={{
        width: '50%',
        margin: 'auto',
        marginLeft: '15%',
        verticalAlign: 'middle',
      }}
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      scrollToFirstError
      autoComplete="off"
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[
          {
            required: true,
            type : 'string',
            message: 'Please input your Username!',
            whitespace: true,
          },
          {
            min : 5,
            max : 20,
            message : 'Username must be between 5 and 20 characters!'
          },
          {
            pattern: new RegExp(/^[\w]*$/),
            message : 'Only the alphabets & numbers can be used'
          },
          lastMatchValidator('username')
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            required: true,
            message: 'Please input your E-mail!',
          },
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            pattern : new RegExp(/^(?!.*[\\]).*$/gu),
            message : `The following special characters cannot be used. \\`
          },
          lastMatchValidator('email')
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
            pattern: /\d.*[A-Z]|[A-Z].*\d/,
            message : 'Password must contain 1 capital letter and 1 number!'
          },
          {
            min : 8,
            max : 50,
            message : 'Password must be between 8 and 50 characters!'
          }
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item 
        name="agreement" 
        valuePropName="checked" 
        {...tailFormItemLayout}
        type='boolean'
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('please accept the terms and conditions')),
          },
        ]}
      >
        <Checkbox>
          I have read the <a href="">agreement</a>
        </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;
