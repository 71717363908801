export const MODE = Object.freeze({SINGLE: 0, MULTI: 1})

export const LOAD = 'analysis/LOAD';
export const LOAD_SUCCESS = 'analysis/LOAD_SUCCESS';
export const LOAD_FAIL = 'analysis/LOAD_FAIL';
export const LOAD_TASKS = 'analysis/LOAD_TASKS';
export const LOAD_TASKS_SUCCESS = 'analysis/LOAD_TASKS_SUCCESS';
export const LOAD_TASKS_FAIL = 'analysis/LOAD_TASKS_FAIL';
export const GET = 'analysis/GET';
export const GET_SUCCESS = 'analysis/GET_SUCCESS';
export const GET_FAIL = 'analysis/GET_FAIL';
export const ADD = 'analysis/ADD';
export const ADD_SUCCESS = 'analysis/ADD_SUCCESS';
export const ADD_FAIL = 'analysis/ADD_FAIL';
export const UPDATE = 'analysis/UPDATE';
export const UPDATE_SUCCESS = 'analysis/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'analysis/UPDATE_FAIL';
export const DEL = 'analysis/DEL';
export const DEL_SUCCESS = 'analysis/DEL_SUCCESS';
export const DEL_FAIL = 'analysis/DEL_FAIL';
export const CLEAR = 'analysis/CLEAR';
export const LOAD_CASE = 'analysis/LOAD_CASE'
export const LOAD_CASE_SUCCESS = 'analysis/LOAD_CASE_SUCCESS'
export const LOAD_CASE_FAIL = 'analysis/LOAD_CASE_FAIL'
export const UPDATE_ANALYSIS_STATE = 'analysis/UPDATE_ANALYSIS_STATE'
export const UPDATE_RUN_STATE = 'analysis/UPDATE_RUN_STATE'
export const UPDATE_CASE_STATE = 'analysis/UPDATE_CASE_STATE'
export const OPEN_NAVIGATE_MODAL = 'analysis/OPEN_NAVIGATE_MODAL'
export const CLOSE_NAVIGATE_MODAL = 'analysis/CLOSE_NAVIGATE_MODAL'
export const UPDATE_DISPLAY = 'analysis/UPDATE_DISPLAY'
export const GET_DOWNLOAD_TARGETS = 'analysis/GET_DOWNLOAD_TARGETS'
export const GET_DOWNLOAD_TARGETS_SUCCESS = 'analysis/GET_DOWNLOAD_TARGETS_SUCCESS'
export const GET_DOWNLOAD_TARGETS_FAIL = 'analysis/GET_DOWNLOAD_TARGETS_FAIL'
export const CLEAR_DOWNLOAD_TARGETS = 'analysis/CLEAR_DOWNLOAD_TARGETS'
export const GET_QUICK_VIEW_DATA = 'analysis/GET_QUICK_VIEW_DATA'
export const GET_QUICK_VIEW_DATA_SUCCESS = 'analysis/GET_QUICK_VIEW_DATA_SUCCESS'
export const GET_QUICK_VIEW_DATA_FAIL = 'analysis/GET_QUICK_VIEW_DATA_FAIL'
export const CLEAR_QUICK_VIEW_DATA = 'analysis/CLEAR_QUICK_VIEW_DATA'
export const UPDATE_QUICK_VIEW_IMAGE_URLS = 'analysis/UPDATE_QUICK_VIEW_IMAGE_URLS'
export const UPDATE_QUICK_VIEW_IMAGE_INFORMATION = 'analysis/UPDATE_QUICK_VIEW_IMAGE_INFORMATION'
export const UPDATE_QUICK_VIEW_CONTROLLER = 'analysis/UPDATE_QUICK_VIEW_CONTROLLER'
export const UPDATE_PARALLEL_VIEW = 'analysis/UPDATE_PARALLEL_VIEW'

const displayState = {
  key: undefined,
  mode: undefined,
  task: {
    expandedRowKeys: [],
    selectedId: undefined,
  },
  case: {
    pagination: {
      page: undefined,
      per_page: undefined,
    },
    search: {
      category: undefined,
      keyword: undefined,
    }
  },
  volumeInfo: {
    pid: undefined,
    run_id: undefined,
    sids: undefined,
    task_index: undefined,
    case_index: undefined,
  }
}

const initialState = {
  loaded: false,
  display: JSON.parse(JSON.stringify(displayState))
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list,
        total: action.payload.total,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case LOAD_TASKS:
      return {
        ...state,
        loading: true
      };
    case LOAD_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tasks: action.payload
      };
    case LOAD_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case ADD:
      return {
        ...state,
        loading: true
      };
    case ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DEL:
      return {
        ...state,
        loading: true
      };
    case DEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list,
        total: action.payload.total,
      };
    case DEL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case CLEAR:
      return {
        ...initialState,
        display: JSON.parse(JSON.stringify(displayState))
      }
    case GET:
      return {
        ...state,
        loading : true
      }
    case GET_SUCCESS:
      return {
        ...state,
        loading : false,
        loaded : true,
        analysis : action.payload.analysis,
        dataset : action.payload.dataset,
        pipeline : action.payload.pipeline,
        taskTemplates : action.payload.task_templates,
        models : action.payload.models,
        imageTypes : action.payload.image_types,
        tasks : action.payload.tasks.sort((a, b) => (a.order > b.order) ? 1 : -1),
        case : {
          list : action.payload.cases,
          total : action.payload.total
        },
        display: {
          ...state.display,
          mode: action.payload.longitudinal ? MODE.MULTI : MODE.SINGLE,
          task: {
            ...state.display.task,
            selectedId: action.payload.load_task_id
          }
        }
      }
    case GET_FAIL:
      return {
        ...state,
        loading : false,
        loaded : true
      }
    case LOAD_CASE:
      return {
        ...state,
        loading : true
      }
    case LOAD_CASE_SUCCESS:
      return {
        ...state,
        loading : false,
        case : {
          list : action.payload.cases,
          total : action.payload.total
        }
      }
    case LOAD_CASE_FAIL:
      return {
        ...state,
        loading : false
      }
    case UPDATE_ANALYSIS_STATE:
      if (state?.analysis?.id !== action.payload.analysis_id) {
        return state
      }
      else {
        return {
          ...state,
          analysis : {
            ...state.analysis,
            state : action.payload.state
          }
        }
      }
    case UPDATE_RUN_STATE:
      if (!state?.tasks) {
        return state
      }
      return {
        ...state,
        tasks : state.tasks.map(i => {
          if (i.run_id === action.payload.run_id) {
            i.state = action.payload.state
          }
          return i
        })
      }
    case UPDATE_CASE_STATE:
      if (!state?.case) {
        return state
      }
      return {
        ...state,
        case : {
          ...state.case,
          list: state.case.list.map(i => {
            const found = action.payload.cases.find(j => i.run_id === j.run_id && i.patient_id === j.pid && i.study_id === j.sid)
            if (found) {
              i.message = found.msg
              i.state = found.state
            }
            return i
          })
        }
      }
    case OPEN_NAVIGATE_MODAL:
      return {
        ...state,
        open: true
      }
    case CLOSE_NAVIGATE_MODAL:
      return {
        ...state,
        open: undefined
      }
    case UPDATE_DISPLAY:
      return {
        ...state,
        display: {
          ...state.display,
          ...action.payload
        }
      }
    case GET_DOWNLOAD_TARGETS:
      return {
        ...state,
        loading: true
      }
    case GET_DOWNLOAD_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadTargets: action.payload
      }
    case GET_DOWNLOAD_TARGETS_FAIL:
      return {
        ...state,
        loading: false
      }
    case CLEAR_DOWNLOAD_TARGETS:
      return {
        ...state,
        downloadTargets: undefined
      }
    case GET_QUICK_VIEW_DATA:
      return {
        ...state,
        loading: true
      }
    case GET_QUICK_VIEW_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        quickView: {
          controller: {
            ...state?.quickView?.controller,
          },
          ...action.payload,
          open: true
        }
      }
    case GET_QUICK_VIEW_DATA_FAIL:
      return {
        ...state,
        loading: false,
      }
    case CLEAR_QUICK_VIEW_DATA:
      return {
        ...state,
        loading: false,
        quickView: {
          controller: {
            ...state?.quickView?.controller,
          },
          lastVolumeInfo: {
            ...state?.quickView?.volumeInfo,
          }
        }
      }
    case UPDATE_QUICK_VIEW_IMAGE_URLS:
      {
        const payload = action.payload
        const sid = payload.sid
        const type = payload.type
        const run_id = payload.data.run_id
        const imageUrls = payload.imageUrls
        const spriteSheetUrl = payload.spriteSheetUrl

        const list = state.quickView.list
        const newList = list.map(studyInfo => {
          if (studyInfo.sid === sid) {
            return {
              ...studyInfo,
              [type]: studyInfo[type].map(data => {
                if (data.run_id === run_id && data.sprite_sheet_url === spriteSheetUrl) {
                  return {
                    ...data,
                    imageUrls
                  }
                }
                return data
              })
            }
          }
          return studyInfo
        })
        return {
          ...state,
          quickView: {
            ...state.quickView,
            list: newList
          }
        }
      }
    case UPDATE_QUICK_VIEW_IMAGE_INFORMATION:
      {
        const payload = action.payload
        const sid = payload.sid
        const type = payload.type
        const run_id = payload.data.run_id
        const imageInformation = payload.imageInformation
        const imageInformationUrl = payload.image_info_url

        const list = state.quickView.list
        const newList = list.map(studyInfo => {
          if (studyInfo.sid === sid) {
            return {
              ...studyInfo,
              [type]: studyInfo[type].map(data => {
                if (data.run_id === run_id && data.image_info_url === imageInformationUrl) {
                  return {
                    ...data,
                    imageInformation
                  }
                }
                return data
              })
            }
          }
          return studyInfo
        })

        return {
          ...state,
          quickView: {
            ...state.quickView,
            list: newList
          }
        }
      }
    case UPDATE_QUICK_VIEW_CONTROLLER:
      return {
        ...state,
        quickView: {
          ...state.quickView,
          controller : {
            ...state.quickView.controller,
            ...action.payload
          }
        }
      }
    case UPDATE_PARALLEL_VIEW:
      return {
        ...state,
        quickView: {
          ...state.quickView,
          parallelView: {
            ...state.quickView.parallelView,
            ...action.payload
          }
        }
      }
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function loadPatient(payload) {
  return {type: LOAD_TASKS, payload};
}

export function loadPatientSuccess(payload) {
  return {type: LOAD_TASKS_SUCCESS, payload};
}

export function loadPatientFail(payload) {
  return {type: LOAD_TASKS_FAIL, payload};
}

export function add(payload) {
  return {type: ADD, payload};
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload};
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload};
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function del(payload) {
  return {type: DEL, payload};
}

export function delSuccess(payload) {
  return {type: DEL_SUCCESS, payload};
}

export function delFail(payload) {
  return {type: DEL_FAIL, payload};
}

export function clear(payload) {
  return {type: CLEAR, payload}
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function loadCase(payload) {
  return {type: LOAD_CASE, payload}
}

export function loadCaseSuccess(payload) {
  return {type: LOAD_CASE_SUCCESS, payload}
}

export function loadCaseFail(payload) {
  return {type : LOAD_CASE_FAIL, payload}
}

export function updateAnalysisState(payload) {
  return {type: UPDATE_ANALYSIS_STATE, payload}
}

export function updateRunState(payload) {
  return {type : UPDATE_RUN_STATE, payload}
}

export function updateCaseState(payload) {
  return {type : UPDATE_CASE_STATE, payload}
}

export function openNavigateModal() {
  return {type : OPEN_NAVIGATE_MODAL}
}

export function closeNavigateModal() {
  return {type : CLOSE_NAVIGATE_MODAL}
}

export function updateDisplay(payload) {
  return {type : UPDATE_DISPLAY, payload}
}

export function getDownloadTargets(payload) {
  return {type : GET_DOWNLOAD_TARGETS, payload}
}

export function getDownloadTargetsSuccess(payload) {
  return {type : GET_DOWNLOAD_TARGETS_SUCCESS, payload}
}

export function getDownloadTargetsFail(payload) {
  return {type : GET_DOWNLOAD_TARGETS_FAIL, payload}
}

export function clearDownloadTargets() {
  return {type : CLEAR_DOWNLOAD_TARGETS}
}

export function getQuickViewData(payload) {
  return {type: GET_QUICK_VIEW_DATA, payload}
}

export function getQuickViewDataSuccess(payload) {
  return {type: GET_QUICK_VIEW_DATA_SUCCESS, payload}
}

export function getQuickViewDataFail(payload) {
  return {type: GET_QUICK_VIEW_DATA_FAIL, payload}
}

export function clearQuickViewData() {
  return {type: CLEAR_QUICK_VIEW_DATA}
}

export function updateQuickViewImageUrls(payload) {
  return {type: UPDATE_QUICK_VIEW_IMAGE_URLS, payload}
}

export function updateQuickViewImageInformation(payload) {
  return {type: UPDATE_QUICK_VIEW_IMAGE_INFORMATION, payload}
}

export function updateQuickViewController(payload) {
  return {type: UPDATE_QUICK_VIEW_CONTROLLER, payload}
}

export function updateParallelView(payload) {
  return {type: UPDATE_PARALLEL_VIEW, payload}
}