import {TransparentBGModal} from './styledComponent'
import { Space } from 'antd'
import { RotatingLines } from 'react-loader-spinner'

const LoadingModal = ({loading, LoadingComponent=LoadingUI}) => {
  return (
    <TransparentBGModal
      open={loading}
      footer={null}
      closable={false}
      zIndex={2000}
      centered
      bodyStyle={{backgroundColor : 'rgba(0,0,0,0)'}}
    >
      <div style={{
        display: "flex",
        height : '100%',
        justifyContent: "center",
        alignItems: "center",
        textAlign : 'center'
      }}>
        <LoadingComponent />
      </div>
    </TransparentBGModal>
  )
}

export const LoadingUI = () => {
  return (
    <Space>
      <RotatingLines 
        strokeColor="#0D6E6D"
        strokeWidth="5"
        animationDuration="0.75"
        width="30"
        visible={true}
      />
    </Space>
  )
}

export default LoadingModal