import {Row, Col, Input, Select} from "antd";
import React, {useState, useEffect} from "react";

const {Search} = Input;

export const caseSearchOptions = [
  {value : 'patient ID', label : 'patient ID', dataIndex : 'pid'},
  {value : 'patient name', label : 'patient name', dataIndex : 'name'},
  {value : 'study ID', label : 'study ID', dataIndex : 'study_id'},
  // {value : 'study description', label : 'study description'}
]

function CaseSearchBox({loading, onSearch, style, defaultValue}) {
  // 검색 키워드 + 카테고리 가져올 방법
  const [category, setCategory] = useState(caseSearchOptions[0].value)

  const onCategoryChange = (value, index) => {
    setCategory(value);
  }

  const onClickSearch = (value, event) => {
    event.preventDefault(); // 안막으면 search창에 키보드로 enter 입력할 때마다 onFinish 연결됨
    onSearch(value, category);
  }

  const [value, setValue] = useState(defaultValue)
  
  useEffect(() => {
    setValue(defaultValue)
  },[defaultValue])
  
  const onChange = e => setValue(e.target.value)

  return (
    <div style={style}>
      <Row gutter={[8, 4]}>
        <Col span={6}>
          <Select
            onChange={onCategoryChange}
            defaultValue={caseSearchOptions[0].value}
            dropdownMatchSelectWidth={false}
            style={{width: '100%'}}
            options={caseSearchOptions}
          />
            {/* <Option key={1} value="patient name">patient name</Option>
            <Option key={2} value="patient ID">patient ID</Option>
            <Option key={3} value="study ID">study ID</Option> */}
            {/* <Option key={4} value="study description">study description</Option> */}
          {/* </Select> */}
        </Col>
        <Col span={18}>
          <Search
            value={value}
            onChange={onChange}
            loading={loading}
            placeholder="input search text"
            enterButton="Search"
            allowClear
            onSearch={onClickSearch}
          />
        </Col>
      </Row>
    </div>
  );
}
export default CaseSearchBox;
