import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, DatePicker } from 'antd';
import './tableEditable.css'
import moment from 'moment';

const EditableContext = React.createContext(null);
export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} scrollToFirstError autoComplete="off">
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell = ({
                        title,
                        editable,
                        children,
                        dataIndex,
                        record,
                        handleSave,
                        editableCellType,
                        addedRule=[],
                        required=true,
                        ...restProps
                      }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (record?.[dataIndex] === undefined) {
      setEditing(true);
    }
  },[])

  const toggleEdit = () => {
    setEditing(!editing);
    const filedValues = {[dataIndex]: record[dataIndex]}
    if (editableCellType === 'date') {
      filedValues[dataIndex] = moment(record[dataIndex])
    }
    form.setFieldsValue(filedValues);
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.error('Save failed:', errInfo);
    }
  };

  const cancel = () => {
    toggleEdit()
  }

  let childNode = children;

  const inputNode = ({editableCellType, inputRef}) => {
    if (editableCellType === 'date') {
      const onChange = (_, dataString) => {
        toggleEdit();
        handleSave({
          ...record,
          [dataIndex]: dataString,
        });
      }
      return (
        <DatePicker 
          data-index={dataIndex}
          ref={inputRef} 
          style={{width: '100%'}}
          format={'YYYY-MM-DD'} 
          onChange={onChange} 
          onBlur={cancel} 
          onClick={e => e.stopPropagation()} 
          allowClear={false}
        />)
    }
    return (
      <Input 
        data-index={dataIndex}
        ref={inputRef} 
        onPressEnter={save} 
        onBlur={save}
      />
    )
  }

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          required && 
          {
            required: true,
            message: `${title} is required.`,
            whitespace: true,
          },
          ...addedRule
        ]}
        onClick={(e) => e.stopPropagation()}
      >
        {inputNode({editableCellType, inputRef})}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={(e) => {
          e.stopPropagation()
          toggleEdit()
        }}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
