
export function setCookie(cookieName, cookieValue, cookieExpire, cookiePath, cookieDomain, cookieSecure){
  let cookieText=escape(cookieName)+'='+escape(cookieValue);
  cookieText+=(cookieExpire ? '; EXPIRES='+cookieExpire.toGMTString() : '');
  cookieText+=(cookiePath ? '; PATH='+cookiePath : '');
  cookieText+=(cookieDomain ? '; DOMAIN='+cookieDomain : '');
  cookieText+=(cookieSecure ? '; SECURE' : '');
  document.cookie=cookieText;
}

export function getCookie(cookieName){
  let cookieValue=null;
  if(document.cookie){
    let array=document.cookie.split((escape(cookieName)+'='));
    if(array.length >= 2){
      let arraySub=array[1].split(';');
      cookieValue=unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

export function deleteCookie(cookieName){
  let temp=getCookie(cookieName);
  if(temp){
    setCookie(cookieName,temp,(new Date(1)));
  }
}
