import * as cornerstone from "cornerstone-core";
import chroma from "chroma-js";

export const Colormap = ({seg, seg_cmap, cmap_id}) => {
  const colors = []
  if (seg && seg_cmap?.every(c => c?.color !== undefined)) {
    seg_cmap.forEach(seg_info => {
      const color = chroma(seg_info.color).rgba()
      colors.push(color)
    })
  }
  else {
    const cmap = cornerstone.colors.getColormap(cmap_id)
    const lut = cmap.createLookupTable()
    const numColors = cmap.getNumberOfColors()
    for (let i = 0; i < numColors; i++) {
      const color = cmap.getColor(i);
      colors.push(color)
    }
    lut.NumberOfColors = 256; // TODO 이거 뭐였지?
  }
  return (
    <div style={{width: "100%"}}>
      {colors?.map((c, i) => {
        return <span
          key={i}
          style={{
            display: 'inline-block',
            backgroundColor: chroma(c.slice(0, -1)),
            height: 10,
            width: `${100/colors.length}%`
        }}/>
      })}
    </div>
  )
}