// comparing array of objects in js
// https://stackoverflow.com/questions/27030/comparing-arrays-of-objects-in-javascript
export const objectsEqualExactly = (o1, o2) =>
  typeof o1 === 'object' && o1 !== null && Object.keys(o1).length > 0 ?
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])) :
    o1 === o2;

const eps = 1.5e-4

export const objectsEqual = (o1, o2) =>
  typeof o1 === 'object' && o1 !== null && Object.keys(o1).length > 0 ?
    Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])) :
    typeof o1 === 'number' && typeof o2 === 'number' ?
      Math.abs(o1 - o2) < eps :
      o1 === o2;

export const arraysEqual = (a1, a2) =>
  a1?.length === a2?.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
