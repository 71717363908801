import { Button, Popconfirm, Tooltip, Modal, Select, Result, Typography, Table, Form, Cascader, Space, Card, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { update, delAskCancel, delAskOk, getDownloadTargets } from "../redux/modules/patient";
import { QuestionCircleOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import AskModal from "../components/askModal";
import { uid } from "../lib/UtilDicomParse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLayerGroup, faTrash } from "@fortawesome/free-solid-svg-icons"
import ImageTypeSelect from "../components/imageTypeSelect";
import { useDicomDrop } from "./dicomDrop";
import UploadComponent from "../components/fileUpload2";
import { usePatientSearchInResultContext } from "../context/patientSearchInResultContext";
import { useGetImageTypeHooks } from "./useImageTypeHooks";
import { useImageViewHooks } from "./patientImageView";
import DownloadPathSettingModal from "../components/downloadPatientPathSettingModal";
import blinkMergedElement from "../lib/blinkMergedElement";

const {Title, Paragraph} = Typography

export const usePatientDetailHooks = ({TYPE_NAME}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const loading = useSelector(state => state.patient.loading)
  const deleteAsk = useSelector(state =>  state.patient?.delAsk)
  const patientInStore = useSelector(state => state.patient.patient)
  const studiesInStore = useSelector(state => state.patient.studies)
  const [studies, setStudies] = useState([])
  const [unResolvedList, setUnResolvedList] = useState([])
  const [selectedRows, setSelectedRows] = useState([[],[],[],[]])
  const TYPE_INDEX = Object.freeze({STUDY:0, DICOM:1, NIFTI:2, COMBINE:3})
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [showMoveModal, setShowMoveModal] = useState(false)
  const [form] = Form.useForm();

  const {getShortFromTypeId} = useGetImageTypeHooks()

  const {
    ViewBtn, 
    modalLoading,
    viewState,
    ImageViewModal
  } = useImageViewHooks({TYPE_NAME})
  
  useEffect(() => {
    const copiedStudies = [...studiesInStore]
    copiedStudies.forEach(s => {
      s.key = `${s.id}-study`
      s.seriesList.forEach(sr => {
        sr.type = TYPE_NAME.DICOM
        sr.key = `${TYPE_NAME.DICOM}-${sr.id}`
        sr.combineKey = `combined-${sr.key}`
        sr.originalTypeId = sr.blobtype_id
        sr.originalPriority = sr.priority
      })
      s.blobs.forEach(b => {
        b.type = TYPE_NAME.NIFTI
        b.key = `${TYPE_NAME.NIFTI}-${b.id}`
        b.combineKey = `combined-${b.key}`
        b.originalTypeId = b.blobtype_id
        b.originalPriority = b.priority
      })
    })
    setStudies(copiedStudies)
  },[studiesInStore])

  useEffect(() => {
    const selectedStudyRows = studies.filter(s => s.selected && !s.deleted)
    const selectedDICOMRows = studies.map(s => s.seriesList.filter(sr => sr.selected  && !s.deleted)).flat(1)
    const selectedNIFTIRows = studies.map(s => s.blobs.filter(b => b.selected  && !s.deleted)).flat(1)
    const selectedCombinedRows = [...selectedDICOMRows, ...selectedNIFTIRows]
    setSelectedRows([selectedStudyRows, selectedDICOMRows, selectedNIFTIRows,selectedCombinedRows])
  },[studies])

  const rowSelection = ({TYPE, studyKey, rowKey='key'}) => ({
    selectedRowKeys: selectedRows[TYPE].map(row => row[rowKey]),
    selectedRows: selectedRows[TYPE],
    onSelect: (record, selected) => {
      const copiedStudies = [...studies]
      if (TYPE === TYPE_INDEX.STUDY) {
        copiedStudies.forEach(s => {
          if (s.key === record.key) {
            s.selected = selected
          }
        })
      }
      else if (TYPE === TYPE_INDEX.DICOM || TYPE === TYPE_INDEX.NIFTI) {
        const targetStudy = copiedStudies.find(s => s.key === studyKey)
        const target = TYPE === TYPE_INDEX.DICOM ? 'seriesList' : 'blobs'
        targetStudy[target].forEach(data => {
          if (data.key === record.key) {
            data.selected = selected
          }
        })
      }
      else if (TYPE === TYPE_INDEX.COMBINE) {
        const targetStudy = copiedStudies.find(s => s.key === studyKey)
        const targets = ['seriesList', 'blobs']
        targets.forEach(target => {
          targetStudy[target].forEach(data => {
            if (data.combineKey === record.combineKey) {
              data.selected = selected
            }
          })
        })
      }
      setStudies(copiedStudies)
    },
    onSelectAll: (selected, _, changeRows) => {
      const copiedStudies = [...studies]
      const changedKeys = changeRows.map(r => r[rowKey])
      if (TYPE === TYPE_INDEX.STUDY) {
        copiedStudies.forEach(s => {
          if (changedKeys.includes(s.key)) {
            s.selected = selected
          }
        })
      }
      else if (TYPE === TYPE_INDEX.DICOM || TYPE === TYPE_INDEX.NIFTI) {
        const target = TYPE === TYPE_INDEX.DICOM ? 'seriesList' : 'blobs'
        const targetStudy = copiedStudies.find(s => s.key === studyKey)
        targetStudy[target].forEach(data => {
          if (changedKeys.includes(data.key)) {
            data.selected = selected
          }
        })
      }
      else if (TYPE === TYPE_INDEX.COMBINE) {
        const targetStudy = copiedStudies.find(s => s.key === studyKey)
        const targets = ['seriesList', 'blobs']
        targets.forEach(target => {
          targetStudy[target].forEach(data => {
            if (changedKeys.includes(data.combineKey)) {
              data.selected = selected
            }
          })
        })
      }
      setStudies(copiedStudies)
    }
  })

  const saveEditData = (row, dataIndex) => {
    const copiedStudies = [...studies]
    const studyIndex = copiedStudies.findIndex(s => s.key === row.key)
    const targetStudy = copiedStudies[studyIndex]
    if (targetStudy[dataIndex] !== row[dataIndex]) {
      targetStudy[dataIndex] = row[dataIndex]
      targetStudy.edit = true
    }
    setStudies(copiedStudies)
  }

  const column_editable = column => column.map(col => {
    if (!col.editable) return col
    return {
      ...col,
      onCell: (record, _) => ({
        record,
        ...col,
        handleSave: row => saveEditData(row, col.dataIndex),
      })
    }
  })

  const AddStudyBtn = () => {
    const getToday = () => { // YYYY-MM-DD 형식
      let today = new Date();
  
      let year = today.getFullYear();
      let month = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
      let date = today.getDate();
  
      // 월과 일이 한 자리 숫자인 경우 앞에 0을 붙여 두 자리로 만듦
      if (month < 10) {
        month = '0' + month;
      }
  
      if (date < 10) {
        date = '0' + date;
      }
  
      let result = year + '-' + month + '-' + date;
      return result
    }

    const handleAddStudy = () => {
      const newStudy = {
        new: true,
        edit: true,
        key: uid(),
        sid: undefined,
        desc: undefined,
        date: getToday(),
        seriesList: [],
        blobs: [],
      }
  
      const copiedStudies = [...studies, newStudy]
      setStudies(copiedStudies)
    }
    
    return <Button onClick={handleAddStudy}>Add study</Button>
  }

  const RemoveBtn = () => {
    const handleSelecetdRemove = () => {
      const copiedStudies = [...studies];
      const studySelectedKeys = selectedRows[TYPE_INDEX.STUDY].map(s => s.key)
      copiedStudies.forEach(s => {
        if (studySelectedKeys.includes(s.key)) {
          s.edit = true
          s.deleted = true
          s.selected = false

          s.seriesList.forEach(sr => {
            sr.deleted = true
            sr.selected = false
            sr.blobtype_id = sr.originalTypeId
          })

          s.blobs.forEach(b => {
            b.deleted = true
            b.selected = false
            b.blobtype_id = b.originalTypeId
          })
        }

        const dicomSelectedKeys = selectedRows[TYPE_INDEX.DICOM].map(sr => sr.key)
        s.seriesList.forEach(sr => {
          if (dicomSelectedKeys.includes(sr.key)) {
            s.edit = true
            sr.edit = true
            sr.deleted = true
            sr.selected = false
            sr.blobtype_id = sr.originalTypeId
          }
        })

        const niftiSelectedKeys = selectedRows[TYPE_INDEX.NIFTI].map(b => b.key)
        s.blobs.forEach(b => {
          if (niftiSelectedKeys.includes(b.key)) {
            s.edit = true
            b.edit = true
            b.deleted = true
            b.selected = false
            b.blobtype_id = b.originalTypeId
          }
        })

        const filteredSeriesList = s.seriesList.filter(sr => !(sr.new && sr.deleted))
        const filteredBlobs = s.blobs.filter(b => !(b.new && b.deleted))

        s.seriesList = filteredSeriesList
        s.blobs = filteredBlobs
        checkPriority(s.seriesList, s.blobs)
      })
      const filteredStudies = copiedStudies.filter(s => {
        if ((s.deleted && s.new && s.seriesList.length === 0 && s.blobs.length === 0)) {
            return false
          }
        return true
      })

      setStudies(filteredStudies)
    }
    
    return (
      <>
        <PatientDelAskModal />
        <Popconfirm
          title={"Are you sure?"}
          icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
          onConfirm={handleSelecetdRemove}
        >
          <Button>
            Remove
          </Button>
        </Popconfirm>
      </>
    )
  }

  const MergeBtn = () => {
    const sLength = selectedRows[TYPE_INDEX.STUDY].length
    const srLength = selectedRows[TYPE_INDEX.DICOM].length
    const bLength = selectedRows[TYPE_INDEX.NIFTI].length
    const checkMergeable = () => {
      if (sLength > 1 && (srLength + bLength) === 0) return true
      return false
    }
    const mergeable = checkMergeable()
    const tooltipOpen = mergeable ? false : undefined
    const handleMergeModalOpen = () => setShowMergeModal(true)
    
    const tooltipTitle = () => {
      if (sLength < 2) {
        return "Please select at least 2 studies"
      }
      if ((srLength + bLength) !== 0) {
        return "Please select only studies"
      }
    }

    return (
      <>
        <MergeStudyModal />
        <Tooltip title={tooltipTitle()} open={tooltipOpen}>
          <Button
            icon={<FontAwesomeIcon style={{marginRight:8}} icon={faLayerGroup}/>}
            disabled={!mergeable}
            onClick={handleMergeModalOpen}
          >
            Merge
          </Button>
        </Tooltip>
      </>
    )
  }

  const MergeStudyModal = () => {
    const [destinationKey, setDestinationKey] = useState(undefined)
    const handleSelectChange = (value, _) => setDestinationKey(value)
    const handleMergeCancel = () => setShowMergeModal(false)
    const handleMergeOk = () => {
      const copiedStudies = [...studies]
      const selectedStudyKeys = selectedRows[TYPE_INDEX.STUDY].map(row => row.key)
      const selectedStudies = copiedStudies.filter(s => selectedStudyKeys.includes(s.key))
      const destinationStudy = selectedStudies.find(s => s.key === destinationKey)
      const absorbedStudies = selectedStudies.filter(s => s.key !== destinationKey)
      absorbedStudies.forEach(s => {
        const moveSeriesList = [...s.seriesList]
        const moveBlobs = [...s.blobs]
        
        moveSeriesList.forEach(sr => {
          sr.priority = false
          sr.edit = true
        })
        moveBlobs.forEach(b => {
          b.priority = false
          b.edit = true
        })
        
        destinationStudy.seriesList.push(...moveSeriesList)
        destinationStudy.blobs.push(...moveBlobs)
        destinationStudy.edit = true
        
        s.deleted = true
        s.selected = false
        s.edit = true
        s.seriesList = []
        s.blobs = []
      })
      
      destinationStudy.selected = false
      
      const targets = ['seriesList', 'blobs']
      targets.forEach(target => {
        destinationStudy[target].forEach(data => {
          data.sid = destinationStudy.key
        })
      })

      blinkMergedElement(destinationStudy.key)

      checkPriority(destinationStudy.seriesList, destinationStudy.blobs)
      
      setStudies(copiedStudies)
      setShowMergeModal(false)
    }

    const options = selectedRows[TYPE_INDEX.STUDY].map(row => ({
      key: row.key,
      label: `Study ID: ${row.sid} - Desc: ${row.desc}`,
      value: row.key,
      type: 'study'
    }))

    useEffect(() => {
      setDestinationKey(options?.[0]?.key)
    },[])
  
    return (
      <Modal
        title="Please select where to merge"
        closable={false}
        open={showMergeModal}
        onOk={handleMergeOk}
        onCancel={handleMergeCancel}
      >
        <Select
          style={{width: '100%', borderColor: '#227a76', borderRightWidth: '1px'}}
          onChange={handleSelectChange}
          dropdownMatchSelectWidth={false}
          options={options}
          value={destinationKey}
        />
      </Modal>
    )
  }

  const MoveBtn = () => {
    const sLength = selectedRows[TYPE_INDEX.STUDY].length
    const srLength = selectedRows[TYPE_INDEX.DICOM].length
    const bLength = selectedRows[TYPE_INDEX.NIFTI].length
    
    const moveable = studies.length > 1 && (srLength + bLength) > 0 && sLength === 0
    
    const tooltipOpen = moveable ? false : undefined
    const handleMoveModalOpen = () => setShowMoveModal(true)
    
    const tooltipTitle = () => {
      if (sLength > 0) {
        return "Please select only DICOM & NIfTI"
      }
      if ((srLength + bLength) === 0) {
        return "Please select DICOM & NIfTI"
      }
    }
    return (
      <>
        <MoveModal />
        <Tooltip title={tooltipTitle()} open={tooltipOpen}>
          <Button
            disabled={!moveable}
            onClick={handleMoveModalOpen}
          >
            Move
          </Button>
        </Tooltip>
      </>
    )
  }

  const checkPriority = (seriesList, blobs) => {
    const all = [...seriesList, ...blobs].filter(d => !d.deleted)
    const imageTypeIds = [...new Set(all.map(item => item.blobtype_id))]
    imageTypeIds.forEach(id => {
      const typeImages = all.filter(img => img.blobtype_id === id)
      const priorityExist = typeImages.find(img => img.priority)
      if (!priorityExist) {
        typeImages[0].priority = true
        typeImages[0].edit = true
      }
    })
  }

  const MoveModal = () => {
    const [destinationKey, setDestinationKey] = useState(undefined)
    const handleSelectChange = (value, _) => setDestinationKey(value)
    const handleMoveCancel = () => setShowMoveModal(false)
    const handleMoveOk = () => {
      const copiedStudies = [...studies]
      const destinationStudy = copiedStudies.find(s => s.key === destinationKey)
      destinationStudy.edit = true
      
      const destinationSeriesList = destinationStudy.seriesList
      const destinationBlobs = destinationStudy.blobs
      const moveDicomKeys = selectedRows[TYPE_INDEX.DICOM].map(sr => sr.key)
      const moveNiftiKeys = selectedRows[TYPE_INDEX.NIFTI].map(b => b.key)

      const absorbedStudies = copiedStudies.filter(s => s.key !== destinationKey)
      absorbedStudies.forEach(s => {
        const moveSeriesList = s.seriesList.filter(sr => moveDicomKeys.includes(sr.key))
        const moveBlobs = s.blobs.filter(b => moveNiftiKeys.includes(b.key))
        
        const remainSeriesList = s.seriesList.filter(sr => !moveDicomKeys.includes(sr.key))
        const remainBlobs = s.blobs.filter(b => !moveNiftiKeys.includes(b.key))
        
        s.edit = true
        // 전체 이동 후 처리
        moveSeriesList.forEach(sr => {
          sr.sid = destinationKey
          sr.priority = false
          sr.edit = true
        })
        moveBlobs.forEach(b => {
          b.sid = destinationKey
          b.priority = false
          b.edit = true
        })

        destinationSeriesList.push(...moveSeriesList)
        destinationBlobs.push(...moveBlobs)
        
        s.seriesList = remainSeriesList
        s.blobs = remainBlobs
        // remain data에 차선 데이터에 priority 주기
        checkPriority(remainSeriesList, remainBlobs)
      })

      // 이동 마무리 되어 destination의 priority 점검
      checkPriority(destinationSeriesList, destinationBlobs)
      
      setStudies(copiedStudies)
      setShowMoveModal(false)
    }

    const options = studies.filter(s => !s.deleted).map(row => ({
      key: row.key,
      label: `Study ID: ${row.sid} - Desc: ${row.desc}`,
      value: row.key,
      type: 'study'
    }))

    return (
      <Modal
        title="Please select where to merge"
        closable={false}
        open={showMoveModal}
        onOk={handleMoveOk}
        onCancel={handleMoveCancel}
      >
        <Select
          style={{width: '100%', borderColor: '#227a76', borderRightWidth: '1px'}}
          onChange={handleSelectChange}
          dropdownMatchSelectWidth={false}
          options={options}
          value={destinationKey}
        />
      </Modal>
    )
  }

  const DownloadBtn = () => {
    const [downloadAvailable, setDownloadAvailable] = useState(false)

    useEffect(() => {
      // 업로드 안된 데이터, 지운데이터, upload_finished가 안된 데이터 선택시 download button 비활성화
      setDownloadAvailable(checkDownloadAvailable())
    },[selectedRows])

    const disableDownloadDicom = selectedRows[TYPE_INDEX.DICOM].some(sr => sr.selected && (sr.new || !sr.upload_finished))
    const disableDownloadNifti = selectedRows[TYPE_INDEX.NIFTI].some(b => b.selected && (b.new || !b.upload_finished))
    
    const sLength = selectedRows[TYPE_INDEX.STUDY].length
    const srLength = selectedRows[TYPE_INDEX.DICOM].length
    const bLength = selectedRows[TYPE_INDEX.NIFTI].length
    
    const checkDownloadAvailable = () => {
      // new, deleted, upload_finished 가 선택되면 안됨.
      if ((sLength + srLength + bLength) === 0) return false
      if (disableDownloadDicom || disableDownloadNifti) return false
      
      return true
    }
    
    const tooltipTitle = () => {
      if ((sLength + srLength + bLength) === 0) return 'Select data to download'
      if (disableDownloadDicom || disableDownloadNifti) return 'Deselect new data'
      return undefined
    }

    const onDownloadHandle = () => {
      // study select의 경우, 다운 가능한 series와 blob만 select하여 back으로 전달
      // upload_finished가 false 인 경우 제외하여 다운로드 진행
      const copiedStudies = JSON.parse(JSON.stringify(studies))
      
      copiedStudies.forEach(s => {
        if (s.selected) {
          s.selected = false
          
          s.seriesList.forEach(sr => {
            if (!sr.new && !sr.deleted && sr.upload_finished) {
              sr.selected = true
            }
            else {
              sr.selected = false
            }
          })
          s.blobs.forEach(b => {
            if (!b.new && !b.deleted && b.upload_finished) {
              b.selected = true
            }
            else {
              b.selected = false
            }
          })
        } 
        s.seriesList = s.seriesList.filter(sr => sr.selected)
        s.blobs = s.blobs.filter(b => b.selected)
      })

      const filteredStudies = copiedStudies.filter(s => {
        if (s.seriesList.length === 0 && s.blobs.length === 0) return false
        return true
      })

      const patient = {
        ...patientInStore,
        studies: filteredStudies
      }
      
      dispatch(getDownloadTargets({patients: [patient], inDetail: true}))
    }

    const downloadTargets = useSelector(state => state.patient?.downloadTargets?.patients)

    return (
      <>
        {downloadTargets && <DownloadPathSettingModal />}
        <Tooltip title={tooltipTitle()}>
          <Button
            disabled={!downloadAvailable}
            onClick={onDownloadHandle}
          >
            Download
          </Button>
        </Tooltip>
      </>
    )
  }

  const changePriority = (record, recordStudy, copiedStudies=[...studies]) => {
    const targetStudy = copiedStudies.find(s => s.key === recordStudy.key)
    const all = [...targetStudy.seriesList, ...targetStudy.blobs].filter(data => !data.deleted)
    const imgTypeIds = [...new Set(all.map(item => item.blobtype_id))]

    imgTypeIds.forEach(id => {
      // id 에 해당하는 모든 series 또는 blob 추출
      const typeImages = all.filter(img => img.blobtype_id === id)
      const recordExist = typeImages.find(img => record?.combineKey === img?.combineKey)
      if (recordExist) {
        typeImages.forEach(img => {
          // img === record // 
          if (record?.combineKey === img?.combineKey) {
            img.priority = true
            img.edit = true
          }
          else {
            img.priority = false
            img.edit = true
          }
        })
      }
      else {
        // record가 없다? --> priority 부여된 존재가 있는지? 없을 때 아무나 준다
        const priorityImage = typeImages.find(img => img.priority)
        if (!priorityImage) {
          typeImages[0].priority = true
          typeImages.edit = true
        }
      }
    })
    setStudies(copiedStudies)
  }

  const CountBox = ({count="", record}) => {
    const uploadFinishedDicom = useSelector(state => state.patient?.uploadFinished?.dicom?.filter(sr => sr.pid === patientInStore.id))?.map(sr => sr.id) || []
    const [view, setView] = useState(false)

    useEffect(() => {
      setView(checkPossible())
    },[uploadFinishedDicom])
    
    const checkPossible = () => {
      if (record.upload_finished) return true
      if (record.new) return true
      if (record.type === TYPE_NAME.NIFTI) return true
      const possible = uploadFinishedDicom.includes(record.key)
      if (possible) {
        record.upload_finished = true
        return true
      }
      return false
    }
    
    return !view ? <LoadingOutlined /> : count
  }

  const TypeSelectBox = ({typeId, record, recordStudy, changeUnresolvedType}) => {
    const handleTypeSelectChange = record => (val, option) => {
      if (changeUnresolvedType) {
        return changeUnresolvedType(record, val)
      }
      return changeType(record, val)
    }
    
    const changeType = (record, blobtype_id) => {
      const copiedStudies = [...studies]
      const target = record.type === TYPE_NAME.DICOM ? 'seriesList' : 'blobs'
      const targetStudy = copiedStudies.find(s => s.key === recordStudy.key)
      targetStudy[target].forEach(data => {
        if (data.key === record.key) {
          data.blobtype_id = blobtype_id
          data.edit = true
        }
      })
      changePriority(record, recordStudy, copiedStudies)
    }

    const className = record.originalTypeId !== typeId ? 'changed-type' : null
    return (
      <div onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}} data-col="type-select" className={className}>
        <ImageTypeSelect
          onChange={handleTypeSelectChange(record)}
          dropdownMatchSelectWidth={false}
          defaultValue={record.originalTypeId}
          row_index={{study : recordStudy, record}}
          value={typeId}
        />
      </div>
    )
  }

  const PriorityBtn = ({priority, record, recordStudy}) => {
    return (
      <Button 
        onClick={() => changePriority(record, recordStudy)}
      >{priority 
        ? <CheckOutlined className={record.originalPriority !== priority ? 'new-priority' : null}/> 
        : 'Set'
        }
      </Button>
    )
  }

  const [stateDicomDrop, dispatchDicomDrop, onDrop] = useDicomDrop();
  
  const {
    anonymize,
    resolveds,
    unresolveds,
  } = stateDicomDrop;

  useEffect(() => {
    if (resolveds.length > 0) {
      checkDiffPatient(resolveds)
    }
  },[resolveds])

  const checkDiffPatient = resolveds => {
    if (resolveds.length === 1) {
      const uploadPatientInfo = resolveds[0]
      const checkInfo = ['pid', 'pname', 'sex']
      const patientInfo = form.getFieldsValue(true)
      let same = true
      checkInfo.forEach(info => {
        if (patientInfo[info] !== uploadPatientInfo[info]) same = false
      })
      if (same) return addUploadedStudy(resolveds)
    }
    return askAddStudyConfirm(resolveds)
  }

  const addUploadedStudy = resolveds => {
    const copiedStudies = [...studies]
    const copiedResolveds = [...resolveds]
    const resolvedStudies = copiedResolveds.map(p => p.studies).flat(1)
    
    for (const study of resolvedStudies) {
      study.seriesList = study.series
      study.series = undefined
      study.new = true
      for (const sr of study.seriesList) {
        sr.blobtype_id = sr.type
        sr.type = TYPE_NAME.DICOM
        sr.combineKey = `${sr.type}/${sr.key}`
        sr.priority = false
        sr.new = true
        sr.image_count = sr.files.length
        sr.sid = study.key
      }
      for (const b of study.blobs) {
        b.blobtype_id = b.type
        b.type = TYPE_NAME.NIFTI
        b.combineKey = `${b.type}/${b.key}`
        b.priority = false
        b.new = true
        b.sid = study.key
        b.fname = b.name
      }
      checkPriority(study.seriesList, study.blobs)
    }
    
    dispatchDicomDrop({type: 'CLEAR'})
    setStudies([...copiedStudies, ...resolvedStudies])
  }
  
  const FileUpload = () => {
    return (
      <UploadComponent
        styleInput={{ height: "300px" }}
        msg="Drag & drop files or folders here for additional data"
        onDrop={onDrop}
        dropped={false}
        anonymize={anonymize}
        dispatchDicomDrop={dispatchDicomDrop}
      />
    )
  }

  const askAddStudyConfirm = resolveds => {
    let count = 1
    const newStudies = resolveds.map(patient => patient.studies.map(study => ({
        index : count++,
        key : count,
        pname : patient.pname,
        pid : patient.pid,
        sid : study.sid,
        desc : study.desc,
      }))).flat(Infinity)

    const columns = [
      {
        dataIndex : 'index',
        title : '',
      },
      {
        dataIndex: 'pname',
        title: 'Patient Name',
      },
      {
        dataIndex: 'pid',
        title: 'Patient ID',
      },
      {
        dataIndex: 'sid',
        title: 'Study ID',
      },
      {
        dataIndex: 'desc',
        title: 'Study desc',
      },
    ];

    const onOk = () => addUploadedStudy(resolveds)

    const onCancel = () => dispatchDicomDrop({type: 'CLEAR'})

    const patientInfo = form.getFieldsValue(true)

    return Modal.confirm({
      icon : undefined,
      width : 'fit-content',
      content : (
        <Result
          status="warning"
          style={{padding : '0'}}
          title={(
            <>
              <Title level={4} style={{textAlign:'center'}} type="warning">
                New data uploaded as shown below.
              </Title>
              <Title level={5} style={{textAlign:'center'}} type="warning">

              </Title>
            </>
          )}
          subTitle={(
            <>
              <Paragraph style={{marginBottom : '10px'}} type="warning">
                Following data will be added
              </Paragraph>
              <Table
                columns={columns}
                dataSource={newStudies}
                pagination={newStudies.length > 10}
                size='small'
              />
              <Paragraph style={{marginBottom : '10px'}} type="warning">
                to
                <Title level={5} type="warning">
                  {`Patient [id: ${patientInfo.pid}, name: ${patientInfo.pname}]`}
                </Title>
                Do you want to proceed anyway?
              </Paragraph>
            </>
          )}
        />
      ),
      onOk,
      onCancel,
    })
  }

  useEffect(() => {
    unresolveds.forEach(ur => {
      ur.blobtype_id = ur.type
      ur.fname = ur.name
      ur.new = true
      ur.priority = false
      ur.type = TYPE_NAME.NIFTI
      ur.combineKey = `${ur.type}/${ur.key}`
    })
    const addedUnResolvedList = [...unResolvedList, ...unresolveds]

    setUnResolvedList(addedUnResolvedList)
    
    dispatchDicomDrop({type: 'CLEAR'})
  },[unresolveds])

  const UnresolvedTable = ({cardRef}) => {
    const [unResolvedRowKeys, setUnResolvedRowKeys] = useState([])
    
    const rowSelection = {
      selectedRowKeys: unResolvedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => setUnResolvedRowKeys(selectedRowKeys)
    }

    const {
      getColumnSearchProps, 
      sortOrder, 
      onTableChange,
      showSorterTooltip
    } = usePatientSearchInResultContext()
    
    const binds = studies.filter(s => !s.deleted).map(s => ({
      label: `${s.sid} | ${s.desc} | ${s.date.split(' ')[0]}`,
      value: s.key,
    }))

    const handleBindChange = record => (value, selectedOptions) => {
      const studyKey = value[0]
      const copiedStudies = [...studies]
      const targetStudies = copiedStudies.find(s => s.key === studyKey)
      record.sid = targetStudies.key
      targetStudies.blobs.push(record)

      checkPriority(targetStudies.seriesList, targetStudies.blobs)

      setStudies(copiedStudies)

      const copiedUnResolvedList = [...unResolvedList]
      const filteredUnResolvedList = copiedUnResolvedList.filter(ur => ur.key !== record.key)
      setUnResolvedList(filteredUnResolvedList)
    }

    const unresolvedCoumnKey = {
      name: 'unresolved/name',
      blobtype_id: 'unresolved/blobtype_id'
    }

    const changeUnresolvedType = (record, blobtype_id) => {
      const copiedUnResolvedList = [...unResolvedList]
      const targetData = copiedUnResolvedList.find(ur => ur.key === record.key)
      targetData.blobtype_id = blobtype_id
      targetData.edit = true
      setUnResolvedList(copiedUnResolvedList)
    }

    const handleRemove = () => {
      const copiedUnResolvedList = [...unResolvedList]
      const filteredUnResolvedList = copiedUnResolvedList.filter(ur => !unResolvedRowKeys.includes(ur.key))
      setUnResolvedList(filteredUnResolvedList)
    }

    const handleRemoveNoImageType = () => {
      const copiedUnResolvedList = [...unResolvedList]
      const filteredUnResolvedList = copiedUnResolvedList.filter(ur => ur.blobtype_id)
      setUnResolvedList(filteredUnResolvedList)
    }

    const columns = [
      {
        ...getColumnSearchProps({
          title: "Path",
          dataIndex: "name",
          key: unresolvedCoumnKey.name,
          sorter: (a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1,
          sortOrder : sortOrder(unresolvedCoumnKey.name),
        })
      },
      {
        title: "Bind",
        key: "bind",
        width: 200,
        render: (text, record, ridx) => {
          return (
            <Cascader
              data-index="bind"
              options={binds}
              style={{ width: "100%" }}
              onChange={handleBindChange(record)}
              dropdownMatchSelectWidth={false}
              defaultValue={record?.bind}
            />
          )
        }
      },
      {
        title: "Image type",
        dataIndex: 'blobtype_id',
        width: 140,
        key: unresolvedCoumnKey.blobtype_id,
        sorter: (a, b) => 
          (getShortFromTypeId(a.blobtype_id)?.toUpperCase()||"")
          > (getShortFromTypeId(b.blobtype_id)?.toUpperCase()||"") ? 1 : -1,
        sortOrder : sortOrder(unresolvedCoumnKey.blobtype_id),
        showSorterTooltip : showSorterTooltip(unresolvedCoumnKey.blobtype_id),
        render: (typeId, record) => (
          <TypeSelectBox
            typeId={typeId}
            record={record}
            changeUnresolvedType={changeUnresolvedType}
          />
        )
      },
      {
        title: '',
        key: "action",
        width: 100,
        align :'center',
        render: record => <ViewBtn record={record}/>
      },
    ];

    return unResolvedList.length > 0 && (
      <>
        <br />
        <Card ref={cardRef}>
          <Row gutter={[8, 4]} align="middle">
            <Col span={6}>
              <div>
                <h3>Unresolved list</h3>
              </div>
            </Col>
            <Col span={18} flex="1">
              <div>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right" }}
                >
                  <Popconfirm
                    title={"Are you sure?"}
                    icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                    onConfirm={handleRemoveNoImageType}
                  >
                    <Button
                      icon={<FontAwesomeIcon style={{marginRight:8}} icon={faTrash}/>}
                    >
                      No image type
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title={"Are you sure?"}
                    icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                    onConfirm={handleRemove}
                  >
                    <Button>
                      Remove
                    </Button>
                  </Popconfirm>
                </Space>
              </div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={unResolvedList}
            rowClassName={record => record.new && 'new-data'}
            onChange={onTableChange}
            pagination={false}
            rowSelection={rowSelection}
          />
        </Card>
      </>
    )
  }

  const PatientDelAskModal = () => {
    const handleOk = () => {
      const payload = {
        patientInfo: form.getFieldsValue(true), 
        studies,
        approve: true
      }
      dispatch(delAskOk())
      dispatch(update(payload))
    }
    const handleCancel = () => dispatch(delAskCancel())

    return (
      <AskModal
        askData={deleteAsk} 
        handleOk={handleOk} 
        handleCancel={handleCancel} 
        loading={loading} 
        title={"Do you want to update it?"}
      />
    )
  }

  return {
    form,
    rowSelection,
    TYPE_INDEX,
    studies,
    column_editable,
    AddStudyBtn,
    RemoveBtn,
    MergeBtn,
    MergeStudyModal,
    MoveBtn,
    DownloadBtn,
    MoveModal,
    CountBox,
    TypeSelectBox,
    PriorityBtn,
    FileUpload,
    stateDicomDrop,
    unResolvedList,
    UnresolvedTable,
    PatientDelAskModal,
    ViewBtn, 
    modalLoading,
    viewState,
    ImageViewModal
  }
}