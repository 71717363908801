import { cloneElement, useContext, useEffect, useState } from "react"
import { INPUT_TYPE, FormDataList, reportContext, DEFAULT_COLOR, PREVIEW, SETTING, TABLE_ROW_TYPE, 
  TRACK_IMAGE_TYPE, getConfigValue, NONE, capitalizeFirstLetter, VALUE_OPERATION, OPERATION_COMPARE, 
  OPERATION, COMPARE, IMAGE_INFO, taskPropsContext } from "./utils"
import { Button, Popover, Tabs, Tooltip } from "antd"
import { deletePageRowConfig, updatePagePreviewProperty, updatePageRowConfig, 
  updatePageSettingTargetProperty } from "./reportReducer"
import { SettingOutlined, AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined, 
  FontColorsOutlined, BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faTable, faEye, faFont, faImage, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

const ROW = 'row'
const POPOVER_ROW_STYLE_WIDTH = 600
const POPOVER_GLOBAL_SETTING_WIDTH = POPOVER_ROW_STYLE_WIDTH + 50

const MODE = Object.freeze({
  ROW: ROW,
  SETTING: SETTING,
  PREVIEW: PREVIEW,
})

export const tableTypeItems = [
  {icon: <FontAwesomeIcon icon={faTable}/> , value: TABLE_ROW_TYPE.TABLE},
  {icon: <FontAwesomeIcon icon={faChartLine}/>, value: TABLE_ROW_TYPE.GRAPH},
  {icon: <FontAwesomeIcon icon={faFont}/> , value: TABLE_ROW_TYPE.TEXT},
  {icon: <FontAwesomeIcon icon={faEye}/>, value: PREVIEW}
]

export const PREVIEW_OPTION = Object.freeze({
  STUDY_COUNT: 'study count',
})

export const TEXT_TYPE_SETTING = Object.freeze({
  TEXT_STYLE: 'text style',
  ALIGN: 'align',
  FONT_SIZE: 'font size',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  FONT_COLOR: 'font color',
})

export const TEXT_STYLE_DEFAULT_VALUES = Object.freeze({
  [TEXT_TYPE_SETTING.ALIGN]: 'center',
  [TEXT_TYPE_SETTING.FONT_SIZE]: 20,
  [TEXT_TYPE_SETTING.BOLD]: false,
  [TEXT_TYPE_SETTING.ITALIC]: false,
  [TEXT_TYPE_SETTING.UNDERLINE]: false,
  [TEXT_TYPE_SETTING.FONT_COLOR]: DEFAULT_COLOR,
})

const textStyleSetting = ({name=TEXT_TYPE_SETTING.TEXT_STYLE, vertical=false, defaultValues=TEXT_STYLE_DEFAULT_VALUES, ...rest}) => ({
  name,
  type: INPUT_TYPE.TEXT_STYLE,
  vertical,
  defaultValues,
  alignProps: {
    name: TEXT_TYPE_SETTING.ALIGN,
    type: INPUT_TYPE.RADIO_GROUP,
    default: defaultValues[TEXT_TYPE_SETTING.ALIGN],
    options: [
      {
        label: <AlignLeftOutlined />,
        value: 'left'
      },
      {
        label: <AlignCenterOutlined />,
        value: 'center'
      },
      {
        label: <AlignRightOutlined />,
        value: 'right'
      }
    ],
  },
  fontSizeProps: {
    name: TEXT_TYPE_SETTING.FONT_SIZE,
    type: INPUT_TYPE.INPUT_NUMBER,
    default: defaultValues[TEXT_TYPE_SETTING.FONT_SIZE],
    min: 10,
    max: 50,
    addonAfter: 'px',
    width: 100,
    size: 'small'
  },
  fontColorProps: {
    name: TEXT_TYPE_SETTING.FONT_COLOR,
    type: INPUT_TYPE.COLOR_PICKER,
    default: defaultValues[TEXT_TYPE_SETTING.FONT_COLOR],
    icon: <FontColorsOutlined />
  },
  boldProps: {
    name: TEXT_TYPE_SETTING.BOLD,
    type: INPUT_TYPE.BUTTON,
    default: defaultValues[TEXT_TYPE_SETTING.BOLD],
    icon: <BoldOutlined />
  },
  italicProps: {
    name: TEXT_TYPE_SETTING.ITALIC,
    type: INPUT_TYPE.BUTTON,
    default: defaultValues[TEXT_TYPE_SETTING.ITALIC],
    icon: <ItalicOutlined />
  },
  underlineProps: {
    name: TEXT_TYPE_SETTING.UNDERLINE,
    type: INPUT_TYPE.BUTTON,
    default: defaultValues[TEXT_TYPE_SETTING.UNDERLINE],
    icon: <UnderlineOutlined />
  },
  ...rest,
})

export const TEXT_TYPE_SETTING_NAMES = Object.freeze({
  TEXT_STYLE: 'text style',
})

export const textTypeSettings = [
  textStyleSetting({
    name: TEXT_TYPE_SETTING_NAMES.TEXT_STYLE,
  }),
]


const commonPreviewSetting = [
  {
    name: PREVIEW_OPTION.STUDY_COUNT,
    type: INPUT_TYPE.INPUT_NUMBER,
    default: 2,
    min: 1
  }
]

export const graphTablePreviewSettings = [
  ...commonPreviewSetting
]

export const trackImagePreviewSettings = [
  ...commonPreviewSetting
]

export const TRACK_TITLE_SETTING = Object.freeze({
  VIEW_TITLE: 'view title',
  TITLE: 'title',
  TITLE_STYLE: 'title style',
})

export const trackTitleSettings = [
  {
    name: TRACK_TITLE_SETTING.VIEW_TITLE,
    type: INPUT_TYPE.SWITCH,
    default: false,
    extra: [
      {
        name: TRACK_TITLE_SETTING.TITLE,
        type: INPUT_TYPE.STRING,
        default: '',
        triggerValue: true,
        maxLength: 30,
        placeholder: 'please input title'
      },
      {
        ...textStyleSetting({name: TRACK_TITLE_SETTING.TITLE_STYLE}),
        triggerValue: true,
      }
    ]
  }
]

export const TRACK_GRAPH_SETTING = Object.freeze({
  SHOW: 'show',
  LINE_COLOR: 'line color',
  Y_AXIS_START_AT_ZERO: 'y axis start at zero',
  Y_AXIS_LOG_SCALE: 'y axis log scale',
  FONT_STYLE: 'font style',
  DISPLAY_DATE_INDIVIDUALLY: 'display dates individually'
})

export const trackGraphSettings = [
  {
    name: TRACK_GRAPH_SETTING.SHOW,
    type: INPUT_TYPE.SWITCH,
    default: true,
    extra: [
      {
        name: TRACK_GRAPH_SETTING.LINE_COLOR,
        type: INPUT_TYPE.COLOR_PICKER,
        default: DEFAULT_COLOR,
        triggerValue: true,
      },
      {
        name: TRACK_GRAPH_SETTING.Y_AXIS_START_AT_ZERO,
        type: INPUT_TYPE.SWITCH,
        default: true,
        triggerValue: true,
      },
      {
        name: TRACK_GRAPH_SETTING.Y_AXIS_LOG_SCALE,
        type: INPUT_TYPE.SWITCH,
        default: false,
        triggerValue: true,
      },
      {
        name: TRACK_GRAPH_SETTING.DISPLAY_DATE_INDIVIDUALLY,
        type: INPUT_TYPE.SWITCH,
        default: false,
        triggerValue: false,
      }
    ]
  },
  {
    ...textStyleSetting({
      name: TRACK_GRAPH_SETTING.FONT_STYLE,
      alignProps: undefined,
      boldProps: undefined,
      italicProps: undefined,
      underlineProps: undefined,
      triggerValue: true,
      vertical: false,
      defaultValues: {
        ...TEXT_STYLE_DEFAULT_VALUES,
        [TEXT_TYPE_SETTING.FONT_SIZE]: 14,
        [TEXT_TYPE_SETTING.ALIGN]: undefined,
        [TEXT_TYPE_SETTING.BOLD]: undefined, 
        [TEXT_TYPE_SETTING.ITALIC]: undefined,
        [TEXT_TYPE_SETTING.UNDERLINE]: undefined,
      }
    }),
  }
]

export const TRACK_IMAGE_SETTING = Object.freeze({
  DISPLAY_INFORMATION: 'display information',
  FONT_STYLE: 'font style',
  COLORIZE_STATE: 'colorize state',
  NEW: 'new',
  INCREASE: 'increase',
  STABLE: 'stable',
  DECREASE: 'decrease',
  NUMBERING: 'numbering',
})

export const trackImageSettings = [
  {
    name: TRACK_IMAGE_SETTING.DISPLAY_INFORMATION,
    type: INPUT_TYPE.SELECT,
    multiple: true,
    default: Object.values(IMAGE_INFO),
    options: Object.values(IMAGE_INFO).map(v => {
      return ({
        label: v,
        value: v
      })
    })
  },
  {
    ...textStyleSetting({
      name: TRACK_IMAGE_SETTING.FONT_STYLE,
      alignProps: undefined,
      boldProps: undefined,
      italicProps: undefined,
      underlineProps: undefined,
      vertical: false,
      defaultValues: {
        ...TEXT_STYLE_DEFAULT_VALUES,
        [TEXT_TYPE_SETTING.FONT_SIZE]: 14,
        [TEXT_TYPE_SETTING.ALIGN]: undefined,
        [TEXT_TYPE_SETTING.BOLD]: undefined, 
        [TEXT_TYPE_SETTING.ITALIC]: undefined,
        [TEXT_TYPE_SETTING.UNDERLINE]: undefined,
      }
    }),
  },
  {
    name: TRACK_IMAGE_SETTING.COLORIZE_STATE,
    type: INPUT_TYPE.SWITCH,
    default: false,
    extra: [
      {
        name: TRACK_IMAGE_SETTING.NEW,
        type: INPUT_TYPE.COLOR_PICKER,
        default: '#ff0000',
        triggerValue: true,
      },
      {
        name: TRACK_IMAGE_SETTING.INCREASE,
        type: INPUT_TYPE.COLOR_PICKER,
        default: '#ffff00',
        triggerValue: true,
      },
      {
        name: TRACK_IMAGE_SETTING.STABLE,
        type: INPUT_TYPE.COLOR_PICKER,
        default: '#00ff00',
        triggerValue: true,
      },
      {
        name: TRACK_IMAGE_SETTING.DECREASE,
        type: INPUT_TYPE.COLOR_PICKER,
        default: '#5555EE',
        triggerValue: true,
      },
    ]
  },
  {
    name: TRACK_IMAGE_SETTING.NUMBERING,
    type: INPUT_TYPE.SWITCH,
    default: true,
  }
]

export const TABLE_TYPE_SETTING_NAMES = Object.freeze({
  LINE_HEIGHT: 'line height',
  FONT: 'font',
  DATE: 'date',
  LABEL: 'label',
  VALUE: 'value',
  COLORIZE_CHANGE_VALUE: 'colorize change value',
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  DATA_PRESENTATION: 'data presentation',
  PRECISION: 'precision',
  SCIENTIFIC_NOTATION: 'scientific notation',
  DECIMAL_ROUNDING: 'decimal rounding',
  HISTOGRAM_N_SPARKLINE: 'histogram and sparkline',
  HEIGHT: 'height',
  LINE_COLOR: 'line color',
  TICK_MARKS: 'tick marks',
  Y_AXIS_RANGE_TO_ZERO: 'y axis range to zero',
  TICK_FONT: 'tick font',
  Y_AXIS_LOG_SCALE: 'y axis log scale',
  NUMBER_OF_BINS: 'number of bins',
})

const checkHistogramAndSparkline = record => {
  const operation = record.value?.[OPERATION]
  const compare = record.value?.[COMPARE]
  return (operation === VALUE_OPERATION.HISTOGRAM || 
    operation === VALUE_OPERATION.SPARKLINE) &&
    compare === OPERATION_COMPARE.CURRENT_VALUE
}

const checkCompare = record => {
  const operation = record.value?.[OPERATION]
  const compare = record.value?.[COMPARE]
  return (
    compare === OPERATION_COMPARE.CHANGE_FROM_START || 
    compare === OPERATION_COMPARE.CHANGE_FROM_LAST
    ) && (operation !== undefined)
}

const tableTypeSettings = [
  {
    name: TABLE_TYPE_SETTING_NAMES.LINE_HEIGHT,
    type: INPUT_TYPE.SLIDER,
    default: 2,
    formatter: value => {
      switch (value) {
        case 0:
          return 'small'
        case 1:
          return 'middle'
        case 2:
          return 'large'
        default:
          return 'large'
      }
    },
    min: 0,
    max: 2,
    hideRow: true
  },
  {
    name: TABLE_TYPE_SETTING_NAMES.FONT,
    type: INPUT_TYPE.DIVIDER,
    children: 'Text',
    hideLabel: true,
    vertical: true,
    hideTrigger: record => checkHistogramAndSparkline(record),
    extra: [
      textStyleSetting({
        name: TABLE_TYPE_SETTING_NAMES.DATE,
        defaultValues: {
          ...TEXT_STYLE_DEFAULT_VALUES,
          [TEXT_TYPE_SETTING.FONT_SIZE]: 18,
        },
        hideRow: true
      }),
      textStyleSetting({
        name: TABLE_TYPE_SETTING_NAMES.LABEL,
        defaultValues: {
          ...TEXT_STYLE_DEFAULT_VALUES,
          [TEXT_TYPE_SETTING.ALIGN]: 'left',
        },
      }),
      textStyleSetting({
        name: TABLE_TYPE_SETTING_NAMES.VALUE,
        hideTrigger: record => {
          return record?.target?.selected === NONE
        },
        extra: [
          {
            name: TABLE_TYPE_SETTING_NAMES.COLORIZE_CHANGE_VALUE,
            type: INPUT_TYPE.SWITCH,
            default: false,
            hideTrigger: record => {
              if (checkHistogramAndSparkline(record)) {
                return true
              }
              if (!checkCompare(record)) {
                return true
              }
              return record?.target?.selected === NONE
            },
            extra: [
              {
                name: TABLE_TYPE_SETTING_NAMES.ASCENDING,
                type: INPUT_TYPE.COLOR_PICKER,
                default: '#ff0000',
                triggerValue: true
              },
              {
                name: TABLE_TYPE_SETTING_NAMES.DESCENDING,
                type: INPUT_TYPE.COLOR_PICKER,
                default: '#7d80ba',
                triggerValue: true
              }
            ]
          },
          {
            name: TABLE_TYPE_SETTING_NAMES.DATA_PRESENTATION,
            type: INPUT_TYPE.SELECT,
            default: TABLE_TYPE_SETTING_NAMES.PRECISION,
            options: [
              {
                label: 'precision',
                value: TABLE_TYPE_SETTING_NAMES.PRECISION
              },
              {
                label: 'scientific notation',
                value: TABLE_TYPE_SETTING_NAMES.SCIENTIFIC_NOTATION
              }
            ],
            hideTrigger: record => {
              if (checkHistogramAndSparkline(record)) {
                return true
              }
              return record?.target?.selected === NONE
            },
            extra: [
              {
                name: TABLE_TYPE_SETTING_NAMES.DECIMAL_ROUNDING,
                type: INPUT_TYPE.INPUT_NUMBER,
                default: 2,
                min: 0,
                max: 10
              }
            ]
          },
        ]
      }),
    ]
  },
  {
    name: TABLE_TYPE_SETTING_NAMES.HISTOGRAM_N_SPARKLINE,
    type: INPUT_TYPE.DIVIDER,
    children: 'Histogram and Sparkline',
    hideLabel: true,
    vertical: true,
    hideTrigger: record => !checkHistogramAndSparkline(record),
    extra: [
      {
        name: TABLE_TYPE_SETTING_NAMES.HEIGHT,
        type: INPUT_TYPE.SLIDER,
        default: 200,
        formatter: value => `${value} px`,
        min: 100,
        max: 400,
      },
      {
        name: TABLE_TYPE_SETTING_NAMES.LINE_COLOR,
        type: INPUT_TYPE.COLOR_PICKER,
        default: DEFAULT_COLOR,
      },
      {
        name: TABLE_TYPE_SETTING_NAMES.TICK_MARKS,
        type: INPUT_TYPE.SWITCH,
        default: true,
        extra: [
          textStyleSetting({
            name: TABLE_TYPE_SETTING_NAMES.TICK_FONT,
            alignProps: undefined,
            boldProps: undefined,
            italicProps: undefined,
            underlineProps: undefined,
            triggerValue: true,
            vertical: false,
            defaultValues: {
              ...TEXT_STYLE_DEFAULT_VALUES,
              [TEXT_TYPE_SETTING.FONT_SIZE]: 14,
              [TEXT_TYPE_SETTING.ALIGN]: undefined,
              [TEXT_TYPE_SETTING.BOLD]: undefined, 
              [TEXT_TYPE_SETTING.ITALIC]: undefined,
              [TEXT_TYPE_SETTING.UNDERLINE]: undefined,
            }
          }),
        ]
      },
      {
        name: TABLE_TYPE_SETTING_NAMES.Y_AXIS_RANGE_TO_ZERO,
        type: INPUT_TYPE.SWITCH,
        default: true,
      },
      {
        name: TABLE_TYPE_SETTING_NAMES.Y_AXIS_LOG_SCALE,
        type: INPUT_TYPE.SWITCH,
        default: false,
      },
      {
        name: TABLE_TYPE_SETTING_NAMES.NUMBER_OF_BINS,
        type: INPUT_TYPE.SLIDER,
        default: 50,
        formatter: value => `${value}`,
        min: 1,
        max: 100,
        hideTrigger: record => {
          const operation = record.value?.[OPERATION]
          const compare = record.value?.[COMPARE]
          if (operation === VALUE_OPERATION.SPARKLINE &&
            compare === OPERATION_COMPARE.CURRENT_VALUE) {
            return true
          }
          return false
        },
      }
    ]
  },
]

export const GRAPH_TYPE_SETTING_NAMES = Object.freeze({
  LAYOUT_MASTER: 'layout master',
  FIRST_ROW_IN_GROUP: 'first row in group',
  VIEW_TITLE: 'view title',
  TITLE: 'title',
  TITLE_STYLE: 'title style',
  HEIGHT: 'height',
  Y_AXIS_START_AT_ZERO: 'y axis start at zero',
  X_AXIS_LABEL_DATE: 'x axis label (date)',
  UNIT: 'unit',
  Y_AXIS_LOG_SCALE: 'y axis log scale',
  LINE_COLOR: 'line color',
  FONT_STYLE: 'font style',
  Y_AXIS_SCALE_STRETCH: 'y axis scale stretch',
  BOTTOM_MARGIN: 'bottom margin',
})

const graphTypeSettings = [
  {
    name: GRAPH_TYPE_SETTING_NAMES.LAYOUT_MASTER,
    type: INPUT_TYPE.SWITCH,
    default: false,
    disabledTrigger: record => {
      const compare = record.value?.[COMPARE]
      if (compare && compare !== OPERATION_COMPARE.CURRENT_VALUE) {
        return {
          disabled: true,
          childrenTooltip: 'The comparison mode is always layout master',
          replaceValue: true
        }
      }
      if (compare && record.config?.[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP]) {
        return {
          disabled: true,
          childrenTooltip: 'First row in graph group is always layout master',
          replaceValue: true
        }
      }
    },
    hideGlobal: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP,
    type: INPUT_TYPE.SWITCH,
    default: false,
    hideGlobal: true,
    hideRow: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.VIEW_TITLE,
    type: INPUT_TYPE.SWITCH,
    default: false,
    hideFromSecond: true,
    hideGlobal: true,
    extra: [
      {
        name: GRAPH_TYPE_SETTING_NAMES.TITLE,
        type: INPUT_TYPE.STRING,
        triggerValue: true,
        placeholder: 'e.g. ADC map'
      },
      {
        ...textStyleSetting({name: GRAPH_TYPE_SETTING_NAMES.TITLE_STYLE}),
        triggerValue: true,
        alignProps: undefined,
        boldProps: undefined,
        italicProps: undefined,
        underlineProps: undefined,
        vertical: false,
        defaultValues: {
          ...TEXT_STYLE_DEFAULT_VALUES,
          [TEXT_TYPE_SETTING.FONT_SIZE]: 14,
          [TEXT_TYPE_SETTING.ALIGN]: undefined,
          [TEXT_TYPE_SETTING.BOLD]: undefined, 
          [TEXT_TYPE_SETTING.ITALIC]: undefined,
          [TEXT_TYPE_SETTING.UNDERLINE]: undefined,
        }
      }
    ]
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.HEIGHT,
    type: INPUT_TYPE.SLIDER,
    default: 400,
    formatter: value => `${value} px`,
    min: 200,
    max: 800,
    hideFromSecond: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.Y_AXIS_START_AT_ZERO,
    type: INPUT_TYPE.SWITCH,
    default: true,
    hideFromSecond: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.X_AXIS_LABEL_DATE,
    type: INPUT_TYPE.SWITCH,
    default: true,
    hideFromSecond: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.UNIT,
    type: INPUT_TYPE.STRING,
    default: undefined,
    hideFromSecond: true,
    hideGlobal: true,
    placeholder: 'e.g. volume [ml] or ADC [mm^2/s] or mL/100g'
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.Y_AXIS_LOG_SCALE,
    type: INPUT_TYPE.SWITCH,
    default: false,
    hideFromSecond: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.LINE_COLOR,
    type: INPUT_TYPE.COLOR_PICKER,
    default: DEFAULT_COLOR,
  },
  textStyleSetting({
    name: GRAPH_TYPE_SETTING_NAMES.FONT_STYLE,
    alignProps: undefined,
    boldProps: undefined,
    italicProps: undefined,
    underlineProps: undefined,
    vertical: false,
    defaultValues: {
      ...TEXT_STYLE_DEFAULT_VALUES,
      [TEXT_TYPE_SETTING.FONT_SIZE]: 14,
      [TEXT_TYPE_SETTING.ALIGN]: undefined,
      [TEXT_TYPE_SETTING.BOLD]: undefined, 
      [TEXT_TYPE_SETTING.ITALIC]: undefined,
      [TEXT_TYPE_SETTING.UNDERLINE]: undefined,
    },
    hideFromSecond: true
  }),
  {
    name: GRAPH_TYPE_SETTING_NAMES.Y_AXIS_SCALE_STRETCH,
    type: INPUT_TYPE.SLIDER,
    default: 1.2,
    formatter: value => `${value}`,
    step: 0.05,
    min: 1.2,
    max: 1.6,
    hideFromSecond: true
  },
  {
    name: GRAPH_TYPE_SETTING_NAMES.BOTTOM_MARGIN,
    type: INPUT_TYPE.SLIDER,
    default: 10,
    formatter: value => `${value} px`,
    min: 0,
    max: 100,
    hideFromSecond: true
  }
]



const getTextStyleValue = (textStyle, defaultValues) => key => {
  if (textStyle?.[key] === undefined) {
    return defaultValues?.[key]
  }
  return textStyle?.[key]
}

const findFontSetting = (template, key, fontSetting=undefined) => {
  for (const item of template) {
    if (item.name === key) {
      fontSetting = item
      break
    }
    if (item.extra?.length) {
      fontSetting = findFontSetting(item.extra, key, fontSetting)
    }
  }
  return fontSetting
}

export const getFontStyle = (styleValues, key, template) => {
  const fontSetting = findFontSetting(template, key) || {}
  const defaultValues = fontSetting.defaultValues
  const getTextStyleProperty = getTextStyleValue(styleValues, defaultValues)
  const align = getTextStyleProperty(TEXT_TYPE_SETTING.ALIGN)
  const bold = getTextStyleProperty(TEXT_TYPE_SETTING.BOLD)
  const italic = getTextStyleProperty(TEXT_TYPE_SETTING.ITALIC)
  const fontSize = getTextStyleProperty(TEXT_TYPE_SETTING.FONT_SIZE)
  const underline = getTextStyleProperty(TEXT_TYPE_SETTING.UNDERLINE)
  const color = getTextStyleProperty(TEXT_TYPE_SETTING.FONT_COLOR)
  return {
    textDecoration: underline && 'underline',
    fontSize,
    fontStyle: italic && 'italic',
    fontWeight: bold && 'bold',
    textAlign: align,
    color
  }
}


export const SettingContent = ({value, mode=MODE.ROW, index, template=[], target, page, record}) => {
  const {dispatchReport} = useContext(reportContext)

  const defaultValue = mode === MODE.ROW ? 
    {...page?.[SETTING]?.[record.type], ...value} : 
    {...value}

  const [config, setConfig] = useState(defaultValue)
  
  useEffect(() => {
    setConfig(defaultValue)
  },[value])

  const onUpdate = payload => {
    setConfig({...config, [payload.name]: payload.value})
    
    switch (mode) {
      case MODE.SETTING:
        {
          const newPayload = {...payload, target}
          return dispatchReport(updatePageSettingTargetProperty(newPayload))
        }
      case MODE.PREVIEW:
        return dispatchReport(updatePagePreviewProperty(payload))
      case MODE.ROW:
        {
          const newPayload = {...payload, index}
          return dispatchReport(updatePageRowConfig(newPayload))
        }
      default:
        return console.info('no mode')
    }
  }

  const addLabelWrap = item => {
    return {
      ...item,
      labelWrap: (item) => {
        const name = item.name
        const useGlobalValue = !value?.hasOwnProperty(name)
        
        const onClick = () => {
          return dispatchReport(deletePageRowConfig({index, name}))
        }

        return (
          <>
            <span>{item.name}</span>
            {!useGlobalValue && 
              <Tooltip
                getPopupContainer={triggerNode => triggerNode.parentNode}
                mouseEnterDelay={0.5}
                title="reset to"
              >
                <FontAwesomeIcon 
                  icon={faRotateLeft} 
                  style={{cursor: 'pointer', marginLeft: 8}}
                  onClick={onClick}
                />
              </Tooltip>
            }
          </>
        )
      }
    }
  }

  const newTemplate = mode === MODE.ROW ?
    appliedFuncTemplate(template, addLabelWrap) :
    template

  return (
    <>
      {template.length > 0 && 
        <div style={{padding: '0 10px', }}>
          <FormDataList
            template={newTemplate}
            value={config}
            onUpdate={onUpdate}
            labelSpan={8}
          />
        </div>
      }
    </>
  )
}

export const getGroups = rows => {
  const newRows = []
  let groupRows = []
  for (const [index, row] of rows.entries()) {
    if (groupRows.length === 0) {
      groupRows.push(row)
    }
    else if (groupRows.length) {
      const InGroupFirstRow = groupRows[0]
      if (InGroupFirstRow.type === row.type) {
        groupRows.push(row)
      }
      else {
        newRows.push({type: InGroupFirstRow.type, rows: groupRows})
        groupRows = [row]
      }
    }

    if ((index === rows.length - 1) && groupRows.length) {
      const InGroupFirstRow = groupRows[0]
      newRows.push({type: InGroupFirstRow.type, rows: groupRows})
    }
  }
  return newRows
}

const applyLongitudinalChildrenTooltip = longitudinal => item => {
  if (item.name === PREVIEW_OPTION.STUDY_COUNT) {
    if (!longitudinal) {
      return {
        ...item,
        childrenTooltip: 'Available for longitudinal mode (More than one)',
        childrenTooltipForceOpen: true,
        childrenTooltipPlacement: 'top',
        disabled: true,
        replaceValue: 1,
      }
    }
  }
  return item
}

export const applyDisabledFunc = (item, row) => {
  const disabledInfo = item.disabledTrigger && item.disabledTrigger(row)
  if (disabledInfo) {
    return {
      ...item,
      ...disabledInfo
    }
  }
  return item
}

export const filterFunc = (item, row) => {
  const hideRow = item.hideRow
  const hide = item?.hideTrigger && item.hideTrigger(row)
  return (!hideRow && !hide)
}

export const appliedFuncTemplate = (items, func, row) => {
  return items.map(item => {
    if (item.extra?.length) {
      return {
        ...func(item, row),
        extra: appliedFuncTemplate(item.extra, func, row)
      }
    }
    return func(item, row)
  })
}

export const filterTemplate = (items, func, row) => {
  return items.filter(item => {
    return func(item, row)
  }).map(item => {
    if (item.extra?.length) {
      return {
        ...item,
        extra: filterTemplate(item.extra, func, row)
      }
    }
    return item
  })
}

export const getGraphTableTemplate = (type, row, longitudinal=false) => {
  let template = []
  switch (type) {
    case TABLE_ROW_TYPE.TEXT:
      template = textTypeSettings
      break
    case TABLE_ROW_TYPE.GRAPH:
      template = graphTypeSettings
      break
    case TABLE_ROW_TYPE.TABLE:
      template = tableTypeSettings
      break
    case PREVIEW:
      template = appliedFuncTemplate(graphTablePreviewSettings, applyLongitudinalChildrenTooltip(longitudinal))
      break
    default:
      return []
  }
  if (!row) {
    return template.filter(item => !item.hideGlobal)
  }
  
  const filteredTemplate = filterTemplate(template, filterFunc, row)

  const appliedDisabledTemplate = appliedFuncTemplate(filteredTemplate, applyDisabledFunc, row)
  return appliedDisabledTemplate
}

export const GraphNTableSettingBtn = ({page, updateCopiedPage}) => { 
  const {longitudinal} = useContext(taskPropsContext)
  const commonLabelStyle = {fontSize: 16}
  const [activeKey, setActiveKey] = useState(TABLE_ROW_TYPE.TABLE)
  const [open, setOpen] = useState(false)

  useEffect(()=> {
    updateCopiedPage()
  },[open, activeKey])

  const newTabItems = tableTypeItems.map(item => {
    const target = item.value
    const type = page.type
    
    let value
    let mode
    switch (target) {
      case TABLE_ROW_TYPE.TABLE:
      case TABLE_ROW_TYPE.GRAPH:
      case TABLE_ROW_TYPE.TEXT:
        value = page[SETTING]?.[target]
        mode = MODE.SETTING
        break
      case PREVIEW:
        value = page[PREVIEW]
        mode = MODE.PREVIEW
        break
      default:
        value = {}
    }
    
    const template = getGraphTableTemplate(target, undefined, longitudinal)

    const filterFunc = item => !item.hideGlobal

    const children = (
      <div>
        {activeKey === target &&
          <SettingContent 
            type={type} 
            value={value}
            mode={mode} 
            template={filterTemplate(template, filterFunc)}
            target={target}
          />
        }
      </div>
    )

    const label = (
      <div>
        {cloneElement(item.icon, {style: commonLabelStyle})}
        <span style={{marginLeft: 4}}>
          {capitalizeFirstLetter(target)}
        </span>
      </div>
    )

    return {...item, key: target, children, label}
  })

  const popoverContent = (
    <div style={{width: POPOVER_GLOBAL_SETTING_WIDTH}}>
      {open && 
        <Tabs 
          type="card"
          tabPosition="left"
          items={newTabItems}
          style={{minHeight: 200}}
          activeKey={activeKey}
          onChange={setActiveKey}
        />
      }
    </div>
  )
  return (
    <>
      <Popover
        content={popoverContent} 
        trigger="click" 
        arrowPointAtCenter={true}
        autoAdjustOverflow={false}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        placement="bottomRight"
        open={open}
        onOpenChange={setOpen}
      >
        <Tooltip
          mouseEnterDelay={0.5}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          title={`Global setting`}
        >
          <Button icon={<SettingOutlined />}/>
        </Tooltip>
      </Popover>
    </>
  )
}

export const getGraphGroupRows = (page, rows) => {
  const globalSetting = page[SETTING]
  const globalGraphSettingValues = globalSetting?.[TABLE_ROW_TYPE.GRAPH] || []
  const graphTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.GRAPH)

  const newRows = []
  let groupRows = []
  
  for (const row of rows) {
    const graphRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.GRAPH, row)
    const getRowConfigValue = getConfigValue(globalGraphSettingValues, graphTemplate, row.config, graphRowTemplate)
    const isLayoutMaster = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.LAYOUT_MASTER)
    const isFirstRow = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP)
    
    if (groupRows.length === 0) {
      const value = row.value
      const operation = value?.[OPERATION]
      const compare = value?.[COMPARE]
      if (!value || !operation || !compare) {
        continue 
        // value 가 없으면 graph를 그릴 수 없음
      }
      groupRows.push(row)
    }
    else if (groupRows.length) {
      const value = row.value
      const operation = value?.[OPERATION]
      const compare = value?.[COMPARE]
      const InGroupFirstRow = groupRows[0]
      const firstRowCompare = InGroupFirstRow.value?.[COMPARE]
      if (!value || !operation || !compare) {
        continue
        // value 가 없으면 graph를 그릴 수 없음
      }
      if (!isFirstRow && !isLayoutMaster) {
        groupRows.push(row)
      }
      else {
        newRows.push({[COMPARE]: firstRowCompare, rows: groupRows})
        groupRows = [row]
      }
    }
  }
  if (groupRows.length) {
    const InGroupFirstRow = groupRows[0]
    const firstRowCompare = InGroupFirstRow.value?.[COMPARE]
    newRows.push({[COMPARE]: firstRowCompare, rows: groupRows})
  }
  return newRows
}


export const GraphNTableRowSettingBtn = ({config, index, record, page}) => {
  const type = record.type
  
  const template = getGraphTableTemplate(type, record).filter(item => {
    switch (type) {
      case TABLE_ROW_TYPE.GRAPH:
        { 
          const isFirstRow = config?.[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP] || 
            config?.[GRAPH_TYPE_SETTING_NAMES.LAYOUT_MASTER]
          if (!isFirstRow) {
            return !item.hideFromSecond
          }
          return item
        }
      default:
        return item
    }
  }).map(item => {
    switch (type) {
      case TABLE_ROW_TYPE.TABLE:
        {
          if (checkHistogramAndSparkline(record)) {
            return {
              ...item,
              hide: true,
            }
          }
          return item
        }
      default:
        return item
    }
  })

  const popoverContent = (
    <div style={{width: POPOVER_ROW_STYLE_WIDTH}}>
      <SettingContent 
        page={page}
        value={config}
        mode={MODE.ROW}
        index={index}
        template={template}
        extraMargin={0}
        record={record}
      />
    </div>
  )

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      placement="left"
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      <SettingOutlined style={{cursor: 'pointer'}} />
    </Popover>
  )
}


export const TrackImageSettingBtn = ({page, updateValue}) => { 
  const {longitudinal} = useContext(taskPropsContext)
  const commonLabelStyle = {fontSize: 16}
  const setting = page[SETTING]
  const previewValue = page[PREVIEW]
  const [activeKey, setActiveKey] = useState(TRACK_IMAGE_TYPE.TITLE)
  const [open, setOpen] = useState(false)

  useEffect(()=> {
    updateValue()
  },[open, activeKey])

  const tabItems = [
    {icon: <FontAwesomeIcon icon={faFont}/> , value: TRACK_IMAGE_TYPE.TITLE},
    {icon: <FontAwesomeIcon icon={faChartLine}/>, value: TRACK_IMAGE_TYPE.GRAPH},
    {icon: <FontAwesomeIcon icon={faImage}/> , value: TRACK_IMAGE_TYPE.IMAGE},
    {icon: <FontAwesomeIcon icon={faEye}/>, value: PREVIEW}
  ].map(item => {
    const target = item.value
    let mode = MODE.SETTING
    let template
    let value = setting?.[target]
    switch (target) {
      case TRACK_IMAGE_TYPE.TITLE:
        template = trackTitleSettings
        break
      case TRACK_IMAGE_TYPE.GRAPH:
        template = trackGraphSettings
        break
      case TRACK_IMAGE_TYPE.IMAGE:
        template = trackImageSettings
        break
      case PREVIEW:
        mode = MODE.PREVIEW

        template = appliedFuncTemplate(trackImagePreviewSettings, applyLongitudinalChildrenTooltip(longitudinal))
        value = previewValue
        break
      default:
        break
    }

    const children = (
      activeKey === target ? 
        <SettingContent 
          type={page.type}
          page={page}
          value={value}
          mode={mode}
          target={target}
          template={template}
        />: 
        <></>
    )

    const label = (
      <div>
        {cloneElement(item.icon, {style: commonLabelStyle})}
        <span style={{marginLeft: 4}}>
          {capitalizeFirstLetter(item.value)}
        </span>
      </div>
    )

    return {...item, key: target, children, label}
  })

  const popoverContent = (
    <div style={{width: POPOVER_GLOBAL_SETTING_WIDTH}}>
      {open && 
        <Tabs 
          type="card"
          tabPosition="left"
          style={{minHeight: 200}}
          items={tabItems}
          activeKey={activeKey}
          onChange={setActiveKey}
        />
      }
    </div>
  )
  return (
    <>
      <Popover
        content={popoverContent} 
        trigger="click" 
        getPopupContainer={triggerNode => triggerNode.parentNode}
        placement="bottomRight"
        open={open}
        onOpenChange={setOpen}
      >
        <Tooltip
          mouseEnterDelay={0.5}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          title={`global setting`}
        >
          <Button icon={<SettingOutlined />}/>
        </Tooltip>
      </Popover>
    </>
  )
}