export const REGISTER = 'auth/REGISTER';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'auth/REGISTER_FAIL';
export const LOAD = 'auth/LOAD';
export const LOAD_IN_MEMORY = 'auth/LOAD_IN_MEMORY';
export const LOAD_SUCCESS = 'auth/LOAD_SUCCESS';
export const LOAD_FAIL = 'auth/LOAD_FAIL';
export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';
export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';
export const UPDATE = 'auth/UPDATE';
export const UPDATE_SUCCESS = 'auth/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'auth/UPDATE_FAIL';
export const REFRESH = 'auth/REFRESH';
export const REFRESH_SUCCESS = 'auth/REFRESH_SUCCESS';
export const REFRESH_FAIL = 'auth/REFRESH_FAIL';
export const LOGOUT_EVENT = 'auth/LOGOUT_EVENT';
export const CHECK_USERNAME = 'auth/CHECK_USERNAME'
export const CHECK_USERNAME_SUCCESS = 'auth/CHECK_USERNAME_SUCCESS'
export const CHECK_USERNAME_FAIL = 'auth/CHECK_USERNAME_FAIL'
export const CHECK_EMAIL = 'auth/CHECK_EMAIL'
export const CHECK_EMAIL_SUCCESS = 'auth/CHECK_EMAIL_SUCCESS'
export const CHECK_EMAIL_FAIL = 'auth/CHECK_EMAIL_FAIL'
export const CHECK_CLEAR = 'auth/CHECK_CLEAR'

const initialState = {
  loaded: false,
  user: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REFRESH:
      return {
        ...state,
        loading: true
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.payload
      };
    case REFRESH_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null,
        error: action.error
      };
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.payload
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case REGISTER:
      return {
        ...state,
        loading: true
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.payload
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_IN_MEMORY:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        user: action.payload
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        user: null,
        error: action.error
      };
    case LOGIN:
      return {
        ...state,
        loggingIn: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action.payload
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loggingIn: false,
        user: null,
        loginError: action.error
      };
    case LOGOUT:
      return {
        ...state,
        loggingOut: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null
      };
    case LOGOUT_FAIL:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error
      };
    case LOGOUT_EVENT:
      return {
        ...state,
        loggingOut: false,
        user: null
      };
    case CHECK_USERNAME:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          userName : undefined,
          userNameError : undefined
        }
      }
    case CHECK_USERNAME_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          userName : action.payload,
        }
      }
    case CHECK_USERNAME_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          userNameError : action.payload
        }
      }
    case CHECK_EMAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          email : undefined,
          emailError : undefined
        }
      }
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          email : action.payload
        }
      }
    case CHECK_EMAIL_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          emailError : action.payload
        }
      }
    case CHECK_CLEAR:
      return {
        ...state,
        check : undefined
      }
    default:
      return state;
  }
}

export function isLoaded(globalState) {
  return globalState.auth && globalState.auth.loaded;
}

export function register(payload) {
  return {type: REGISTER, payload};
}

export function registerSuccess(payload) {
  return {type: REGISTER_SUCCESS, payload};
}

export function registerFail() {
  return {type: REGISTER_FAIL};
}

export function refresh() {
  return { type: REFRESH } 
}

export function refreshSuccess(payload) {
  return { type: REFRESH_SUCCESS, payload };
}

export function refreshFail() {
  return { type: REFRESH_FAIL };
}

export function update(payload) {
  return { type: UPDATE , payload};
}

export function updateSuccess(payload) {
  return { type: UPDATE_SUCCESS, payload };
}

export function updateFail() {
  return { type: UPDATE_FAIL };
}

export function load(payload) {
  return { type: LOAD, payload };
}

export function loadSuccess(payload) {
  return { type: LOAD_SUCCESS, payload };
}

export function loadFail() {
  return { type: LOAD_FAIL };
}

export function login(payload) {
  return { type: LOGIN, payload };
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload };
}

export function loginFail(payload) {
  return { type: LOGIN_FAIL, payload };
}

export function logout() {
  return { type: LOGOUT };
}

export function logoutSuccess() {
  return { type: LOGOUT_SUCCESS };
}

export function logoutFail() {
  return { type: LOGOUT_FAIL };
}

export function logoutEvent() {
  return { type: LOGOUT_EVENT };
}

export function checkUserName(payload) {
  return { type: CHECK_USERNAME, payload}
}

export function checkUserNameSuccess(payload) {
  return { type: CHECK_USERNAME_SUCCESS, payload}
}

export function checkUserNameFail(payload) {
  return { type: CHECK_USERNAME_FAIL, payload}
}

export function checkEmail(payload) {
  return { type: CHECK_EMAIL, payload}
}

export function checkEmailSuccess(payload) {
  return { type: CHECK_EMAIL_SUCCESS, payload}
}

export function checkEmailFail(payload) {
  return { type: CHECK_EMAIL_FAIL, payload}
}

export function checkClear() {
  return {type : CHECK_CLEAR}
}