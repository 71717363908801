export const LOAD = 'blob-types/LOAD';
export const LOAD_SUCCESS = 'blob-types/LOAD_SUCCESS';
export const LOAD_FAIL = 'blob-types/LOAD_FAIL';
export const GET = 'blob-types/GET';
export const GET_SUCCESS = 'blob-types/GET_SUCCESS';
export const GET_FAIL = 'blob-types/GET_FAIL';
export const ADD = 'blob-types/ADD';
export const ADD_SUCCESS = 'blob-types/ADD_SUCCESS';
export const ADD_FAIL = 'blob-types/ADD_FAIL';
export const UPDATE = 'blob-types/UPDATE';
export const UPDATE_SUCCESS = 'blob-types/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'blob-types/UPDATE_FAIL';
export const DEL = 'blob-types/DEL';
export const DEL_SUCCESS = 'blob-types/DEL_SUCCESS';
export const DEL_FAIL = 'blob-types/DEL_FAIL';
export const TEST = 'blob-types/TEST';
export const TEST_SUCCESS = 'blob-types/TEST_SUCCESS';
export const TEST_FAIL = 'blob-types/TEST_FAIL';
export const CLEAR = 'blob-types/CLEAR';
export const CHECK_NAME = 'blob-types/CHECK_NAME';
export const CHECK_NAME_SUCCESS = 'blob-types/CHECK_NAME_SUCCESS';
export const CHECK_NAME_FAIL = 'blob-types/CHECK_NAME_FAIL';
export const CHECK_SHORTHAND = 'blob-types/CHECK_SHORTHAND';
export const CHECK_SHORTHAND_SUCCESS = 'blob-types/CHECK_SHORTHAND_SUCCESS';
export const CHECK_SHORTHAND_FAIL = 'blob-types/CHECK_SHORTHAND_FAIL';
export const CLEAR_TEST_RESULT = 'blob-types/CLEAR_TEST_RESULT';
export const DEL_ASK = 'blob-types/DEL_ASK';
export const DEL_ASK_OK = 'blob-types/DEL_ASK_OK';
export const DEL_ASK_CANCEL = 'blob-types/DEL_ASK_CANCEL';
export const EDIT_HINTS = 'blob-types/EDIT_HINTS';
export const EDIT_HINTS_SUCCESS = 'blob-types/EDIT_HINTS_SUCCESS';
export const EDIT_HINTS_FAIL = 'blob-types/EDIT_HINTS_FAIL';
export const GET_DEFAULT = 'blob-types/GET_DEFAULT';
export const GET_DEFAULT_SUCCESS = 'blob-types/GET_DEFAULT_SUCCESS';
export const GET_DEFAULT_FAIL = 'blob-types/GET_DEFAULT_FAIL';

const initialState = {
  loaded: false,
  types: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        types: action.payload.types,
        total: action.payload.total,
        list: action.payload.list.map(data => ({...data, key: data.id}))
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ADD:
      return {
        ...state,
        loading: true
      };
    case ADD_SUCCESS: // ADD 는 upload page 에서도 가능하기 때문에 list와 type 등을 받아야 한다. 
      return {
        ...state,
        loading: false,
        types: action.payload.types,
        total: action.payload.total,
        list: action.payload.list.map(data => ({...data, key: data.id}))
      };
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
      }
    case GET:
      return {
        ...state,
        loading: true,
        type: undefined,
      };
    case GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: action.payload.type,
        check : { // for name rules
          name : action.payload.type.name,
          short : action.payload.type.short,
        }
      };
    }
    case GET_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DEL:
      return {
        ...state,
        loading: true
      };
    case DEL_SUCCESS:
      return {
        ...state,
        loading: false,
        types: action.payload.types,
        total: action.payload.total,
        list: action.payload.list.map(data => ({...data, key: data.id}))
      };
    case DEL_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TEST:
      return {
        ...state,
        loading: true
      };
    case TEST_SUCCESS:
      {
        const result = {
          ...state,
          loading: false
        }
        if (action.payload.type === 'dicom') {
          result.dicomTestResult = {
            list: action.payload.result,
            total: action.payload.total,
          }
        } else {
          result.imageTestResult = {
            list: action.payload.result,
            total: action.payload.total,
          }
        }

        return result
      }
    case TEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CLEAR:
      return initialState
    case CHECK_NAME:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          name : undefined,
          nameError : undefined
        }
      }
    case CHECK_NAME_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          name : action.payload
        }
      }
    case CHECK_NAME_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          nameError : action.payload
        }
      }
    case CHECK_SHORTHAND:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          short : undefined,
          shortError : undefined
        }
      }
    case CHECK_SHORTHAND_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          short : action.payload,
        }
      }
    case CHECK_SHORTHAND_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          shortError : action.payload
        }
      }
    case CLEAR_TEST_RESULT:
      {
        const result = {
          ...state,
          loading: false
        }
        if (action.payload.type === 'dicom') {
          result.dicomTestResult = {
            list: [],
            total: 0,
          } 
        } else {
          result.imageTestResult = {
            list: [],
            total: 0,
          }
        }
        return result
      }
    case DEL_ASK:
      return {
        ...state,
        loading : false,
        delAsk: {
          affectedList : action.payload.affected_list,
          targetData : action.payload.target_data,
          modalVisible : true,
        }
      }
    case DEL_ASK_OK:
      return {
        ...state,
        delAsk: undefined
      }
    case DEL_ASK_CANCEL:
      return {
        ...state,
        loading : false,
        delAsk : undefined,
      }
    case EDIT_HINTS:
      return {
        ...state,
        loading: true
      }
    case EDIT_HINTS_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case EDIT_HINTS_FAIL:
      return {
        ...state,
        loading: false
      }
    case GET_DEFAULT:
      return {
        ...state,
        loading: true
      }
    case GET_DEFAULT_SUCCESS:
      return {
        ...state,
        type: action.payload,
        loading: false
      }
    case GET_DEFAULT_FAIL:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function add(payload) {
  return {type: ADD, payload};
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload};
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload};
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function del(payload) {
  return {type: DEL, payload};
}

export function delSuccess(payload) {
  return {type: DEL_SUCCESS, payload};
}

export function delFail(payload) {
  return {type: DEL_FAIL, payload};
}

export function test(payload) {
  return {type: TEST, payload};
}

export function testSuccess(payload) {
  return {type: TEST_SUCCESS, payload};
}

export function testFail(payload) {
  return {type: TEST_FAIL, payload};
}

export function clear(payload) {
  return {type: CLEAR, payload}
}

export function checkName(payload) {
  return {type : CHECK_NAME, payload}
}

export function checkNameSuccess(payload) {
  return {type : CHECK_NAME_SUCCESS, payload}
}

export function checkNameFail(payload) {
  return {type : CHECK_NAME_FAIL, payload}
}

export function checkShorthand(payload) {
  return {type : CHECK_SHORTHAND, payload}
}

export function checkShorthandSuccess(payload) {
  return {type : CHECK_SHORTHAND_SUCCESS, payload}
}

export function checkShorthandFail(payload) {
  return {type : CHECK_SHORTHAND_FAIL, payload}
}

export function clearTestReulst(payload) {
  return {type : CLEAR_TEST_RESULT, payload}
}
  
export function delAsk(payload) {
  return {type:DEL_ASK, payload}
}

export function delAskOk(payload) {
  return {type:DEL_ASK_OK, payload}
}

export function delAskCancel(payload) {
  return {type:DEL_ASK_CANCEL, payload}
}

export function editHints(payload) {
  return {type:EDIT_HINTS, payload}
}

export function editHintsSuccess(payload) {
  return {type:EDIT_HINTS_SUCCESS, payload}
}

export function editHintsFail(payload) {
  return {type:EDIT_HINTS_FAIL, payload}
}
export function getDefault(payload) {
  return {type:GET_DEFAULT, payload}
}

export function getDefaultSuccess(payload) {
  return {type:GET_DEFAULT_SUCCESS, payload}
}

export function getDefaultFail(payload) {
  return {type:GET_DEFAULT_FAIL, payload}
}
