import { InputNumber, Switch, Slider, Select, Form, Input, Button, Radio, Checkbox, Cascader, 
  Tooltip, Divider, Popover } from "antd"
import { TASK_DELIMITER } from "../../lib/taskUtil"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { TagBase } from "../tagBase"
import { SketchPicker } from "react-color"
import { QuestionCircleOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import ExpressionOfCondition from "../expressionOfConditions"

export const reportContext = createContext({})
export const previewUpdateContext = createContext({})
export const taskPropsContext = createContext({})

export const PREVIEW = 'preview'
export const SETTING = 'setting'
export const ALL = 'all'
export const NONE = 'none'
export const DEFAULT_COLOR = '#ffffff'
export const NONE_COLOR = '#444444'
export const PAGES = 'pages'
export const CONFIG = 'config'
export const TRACK = 'track'
export const OPERATION = 'operation'
export const COMPARE = 'compare'

export const ALL_SEG_CMAP = Object.freeze({name: 'All', value: ALL, color: DEFAULT_COLOR})
export const NONE_TYPE_MAP = Object.freeze({name: 'None', value: NONE, color: NONE_COLOR})

export const SUMMARY_WIDTH = 1200
export const SUMMARY_PADDING = 15
export const REPORT_TYPE = Object.freeze({GRAPH_N_TABLE: 'graph-and-table', TRACK_IMAGE: 'track-image'})

export const TABLE_ROW_TYPE = Object.freeze({TABLE:'table', GRAPH: 'graph', TEXT: 'text'})
export const TRACK_IMAGE_TYPE = Object.freeze({TITLE:'title', GRAPH: 'graph', IMAGE: 'image'})

export const ROW_INFO = Object.freeze({
  NAME: 'name',
  TYPE: 'type',
  TARGET: 'target',
  VALUE: 'value',
  NEGLECT_SMALL_VALUES: 'neglect small values',
  SMALL_VALUES_THRESHOLD: 'small values threshold',
  FILTER_CONDITION: 'filter-condition',
})

export const VALUE_OPERATION = Object.freeze({
  VOLUME: 'volume',
  COUNT: 'count',
  MEAN: 'mean',
  MIN: 'min', 
  MAX: 'max', 
  MEDIAN: 'median',
  STD: 'std', 
  HISTOGRAM: 'histogram', 
  SPARKLINE: 'sparkline',
})

export const OPERATION_COMPARE = Object.freeze({
  CURRENT_VALUE: 'current value',
  CHANGE_FROM_START: 'change from start',
  CHANGE_FROM_LAST: 'change from last',
})


export const INPUT_TYPE = Object.freeze({
  GRAPH_N_TABLE: REPORT_TYPE.GRAPH_N_TABLE,
  TRACK_IMAGE: REPORT_TYPE.TRACK_IMAGE, 
  STRING: 'string', 
  IMAGE_SELECT: 'image-select', 
  INFORMATION_SELECT: 'information select', 
  CASCADER: 'cascader', 
  INPUT_NUMBER: 'input number', 
  SWITCH: 'switch', 
  SLIDER: 'slider', 
  COLOR_PICKER: 'color picker',
  SELECT: 'select',
  RADIO_GROUP: 'radio group',
  CHECK_BOX: 'check box',
  TEXT_STYLE: 'text style',
  BUTTON: 'button',
  MAP_CASCADER: 'map cascader',
  DIVIDER: 'divider',
  REPORT_VALUE_CASCADER: 'report value cascader',
  EXPRESSION: 'expression',
})

export const IMAGETYPE_INPUT_DATA = Object.freeze({
  BASE_IMAGE: 'base-image',
  TRACK: 'track', 
})

export const TRACK_IMAGE_INPUT_DATA = Object.freeze({
  BASE_IMAGE: IMAGETYPE_INPUT_DATA.BASE_IMAGE,
  TRACK: IMAGETYPE_INPUT_DATA.TRACK,
  SORT: 'sort',
  IMAGE_INFORMATION: 'image information',
  VOLUME_THRESHOLD: 'volume threshold',
  MAXIMUM_ROW_COUNT: 'maximum row count',
  MAXIMUM_COLUMN_COUNT: 'maximum column count (study)',
})

export const SORT = Object.freeze({
  VOLUME: 'volume',
  ASC: 'ascending',
  DESC: 'descending'
})

export const IMAGE_INFO = Object.freeze({
  VOLUME: 'volume', 
  LOCATION: 'location', 
  STATE: 'state'
})

export const getTrackImageTemplate = (candidates, imageTypeList, readOnly, defaultValue) => {
  const imageMaps = imageTypeList.filter(type => !type.seg)
  const imageShortList = imageMaps.map(type => type.short)
  const imageMapsSelectOptions = candidates?.filter(candidate => {
    if (candidate?.includes(TASK_DELIMITER)) {
      const short = candidate.split(TASK_DELIMITER)[0]
      return imageShortList.includes(short)
    }
    else {
      return imageShortList.includes(candidate)
    }
  }).map(mapCandidate => {
    const short = mapCandidate.includes(TASK_DELIMITER) ? 
      mapCandidate.split(TASK_DELIMITER)[0] :
      mapCandidate
    const map = imageMaps.find(map => map.short === short)
    return {
      label: <TagBase 
        color={map?.tag_color} 
        children={mapCandidate}
      />,
      value: mapCandidate
    }
  })

  const trackType = imageTypeList.find(type => type.display === TRACK)
  const trackImageCascaderOptions = candidates?.filter(candidate => {
    if (candidate?.includes(TASK_DELIMITER)) {
      const short = candidate.split(TASK_DELIMITER)[0]
      return short === trackType?.short
    } 
    else {
      return candidate === trackType?.short
    }
  }).map(trackCandidate => {
    return {
      label: <TagBase 
        color={trackType.tag_color} 
        children={trackCandidate}
      />,
      value: trackCandidate,
    }
  })

  const sortCascaderOptions = [
    {
      label: SORT.VOLUME,
      value: SORT.VOLUME,
      children: [
        {
          label: SORT.ASC,
          value: SORT.ASC,
        },
        {
          label: SORT.DESC,
          value: SORT.DESC,
        }
      ]
    },
  ]

  const inputNumberWidth = 100
  const addonAfterWidth = 38

  const getReadOnlyOptions = target => {
    const targetSelected = target?.selected
          
    const short = targetSelected?.includes(TASK_DELIMITER) ?
      targetSelected.split(TASK_DELIMITER)[0] : 
      targetSelected

    const imagetype = imageTypeList?.find(type => type.short === short)

    const option = {
      key: targetSelected,
      label: (
        <TagBase 
          color={imagetype?.tag_color}
          children={targetSelected}
        />
      ),
      value: targetSelected,
      imagetype,
    }
    return option
  }

  return [
    {
      name: TRACK_IMAGE_INPUT_DATA.BASE_IMAGE,
      type: INPUT_TYPE.MAP_CASCADER,
      required: true,
      options: readOnly ? 
        [getReadOnlyOptions(defaultValue[TRACK_IMAGE_INPUT_DATA.BASE_IMAGE])] : 
        imageMapsSelectOptions,
    },
    {
      name: TRACK_IMAGE_INPUT_DATA.TRACK,
      type: INPUT_TYPE.MAP_CASCADER,
      required: true,
      options: readOnly ? 
        [getReadOnlyOptions(defaultValue[TRACK_IMAGE_INPUT_DATA.TRACK])] : 
        trackImageCascaderOptions,
    },
    {
      name: TRACK_IMAGE_INPUT_DATA.SORT,
      type: INPUT_TYPE.CASCADER,
      default: [SORT.VOLUME, SORT.DESC],
      options: sortCascaderOptions
    },
    {
      name: TRACK_IMAGE_INPUT_DATA.VOLUME_THRESHOLD,
      type: INPUT_TYPE.INPUT_NUMBER,
      default: 0,
      min: 0,
      addonAfter: <>mm<sup>3</sup></>,
      width: inputNumberWidth + addonAfterWidth
    },
    {
      name: TRACK_IMAGE_INPUT_DATA.MAXIMUM_ROW_COUNT,
      type: INPUT_TYPE.INPUT_NUMBER,
      default: 4,
      min: 1,
      max: 20,
      width: inputNumberWidth
    },
    {
      name: TRACK_IMAGE_INPUT_DATA.MAXIMUM_COLUMN_COUNT,
      type: INPUT_TYPE.INPUT_NUMBER,
      default: 4,
      min: 2,
      max: 6,
      width: inputNumberWidth
    }
  ]
}


export const searchFilter = (inputValue, path) => {
  const value = String(inputValue)
  return path.some(option => {
    let resultValue
    let resultLabel
    if (typeof option.value === 'string') {
      resultValue = option.value?.toLowerCase().includes(value.toLowerCase())
    }
    if (typeof option.label === 'string') {
      resultLabel = option.label?.toLowerCase().includes(value.toLowerCase())
    }
    return resultValue || resultLabel
  });
}

export const capitalizeFirstLetter = inputString => inputString.charAt(0).toUpperCase() + inputString.slice(1);

export const tooltipProps = (msg, icon=<QuestionCircleOutlined style={{color: DEFAULT_COLOR}} />) => {
  return ({
    title: msg,
    icon,
    getPopupContainer: triggerNode => triggerNode.parentNode
  })
}

const DefaultComponent = () => <div>null</div>


export const FormDataList = ({
  template, value, onUpdate, extraMargin=10, readOnly=false, 
  GraphAndTable=DefaultComponent, TrackImage=DefaultComponent,
  labelSpan=6
}) => {
  const {candidates, imageTypeList} = useContext(taskPropsContext)
  if (!template?.length) return null
  
  return template.map(item => {
    const vertical = item?.vertical
    const name = item.name
    const options = item.options
    const min = item.min
    const max = item.max
    const formatter = item.formatter
    const showCount = item.showCount
    const disabled = item.disabled
    const hideLabel = item.hideLabel
    const hide = item.hide
    // const label = item.label
    const labelWrap = item.labelWrap
    const replaceValue = item.replaceValue
    const childrenTooltip = item.childrenTooltip
    const childrenTooltipForceOpen = item.childrenTooltipForceOpen
    const childrenTooltipPlacement = item.childrenTooltipPlacement
    const required = item.required
    
    let val = item.default;
    if (value?.hasOwnProperty(name)) {
      val = value[name];
    }
    
    if (disabled && replaceValue !== undefined) {
      val = replaceValue;
    }

    let children = null;
    let isSwitch = false;

    let extraChildren = null

    const onChange = value => {
      const payload = {name, value}
      onUpdate(payload)
    }
    
    switch (item.type) {
      case INPUT_TYPE.TEXT_STYLE:
        {
          const alignProps = item.alignProps
          const fontSizeProps = item.fontSizeProps
          const fontColorProps = item.fontColorProps
          const boldProps = item.boldProps
          const italicProps = item.italicProps
          const underlineProps = item.underlineProps
          children = (
            <div style={{display: 'flex', alignItems: 'center'}}>
              {fontColorProps &&
                <ColorPicker 
                  color={val?.[fontColorProps.name] || fontColorProps.default}
                  onChange={color => {
                    onChange({
                      ...val,
                      [fontColorProps.name]: color
                    })
                  }}
                  icon={fontColorProps.icon}
                />
              }
              {boldProps && 
                <Button 
                  icon={boldProps.icon} 
                  type={val?.[boldProps.name] ? "primary" : "default"}
                  onClick={() => {
                    onChange({
                      ...val,
                      [boldProps.name]: !val?.[boldProps.name]
                    })
                  }}
                />
              }
              {italicProps &&
                <Button 
                  icon={italicProps.icon} 
                  type={val?.[italicProps.name] ? "primary" : "default"}
                  onClick={() => {
                    onChange({
                      ...val,
                      [italicProps.name]: !val?.[italicProps.name]
                    })
                  }}
                />
              }
              {underlineProps &&
                <Button 
                  icon={underlineProps.icon} 
                  type={val?.[underlineProps.name] ? "primary" : "default"}
                  onClick={() => {
                    onChange({
                      ...val,
                      [underlineProps.name]: !val?.[underlineProps.name]
                    })
                  }}
                />
              }
              {alignProps && 
                <Radio.Group 
                  options={alignProps.options}
                  value={val?.[alignProps.name] || alignProps.default}
                  onChange={e => onChange({
                    ...val,
                    [alignProps.name]: e.target.value
                  })}
                  optionType="button"
                  size="small"
                  style={{width: 90}}
                />
              }
              {fontSizeProps && 
                <InputNumber 
                  min={fontSizeProps.min} 
                  max={fontSizeProps.max} 
                  value={val?.[fontSizeProps.name] || fontSizeProps.default}
                  addonAfter={fontSizeProps.addonAfter}
                  onChange={num => {
                    onChange({
                      ...val,
                      [fontSizeProps.name]: num
                    })
                  }}
                  style={{
                    width: fontSizeProps.width, 
                    marginLeft: alignProps && 10,
                    border: '1px solid #205656'
                  }}
                  size={fontSizeProps.size}
                />
              }
            </div>
          )
          break
        }
      case INPUT_TYPE.CHECK_BOX:
        children = (
          <Checkbox 
            checked={val} 
            onChange={e => onChange(e.target.checked)}
            indeterminate={
              val === undefined ? 
              item?.indeterminate : 
              undefined
            }
          />
        )
        break
      case INPUT_TYPE.RADIO_GROUP:
        children = (
          <Radio.Group 
            options={item.options}
            value={val}
            onChange={e => onChange(e.target.value)}
            optionType="button"
            size="small"
          />
        )
        break
      case INPUT_TYPE.STRING:
        children = (
          <Input 
            disabled={readOnly || disabled} 
            value={val} 
            onChange={e => {
              const value = e.target.value
              onChange(value)
            }}
            maxLength={item?.maxLength}
            showCount={showCount && formatter}
            placeholder={item.placeholder}
            style={{
              border: '1px solid #205656'
            }}
          />
        )
        break
      case INPUT_TYPE.SWITCH:
        children = (
          <Switch 
            disabled={readOnly || disabled} 
            checked={val} 
            checkedChildren={item.checkedChildren}
            unCheckedChildren={item.unCheckedChildren}
            onChange={onChange}
          />
        )
        isSwitch = true
        break
      case INPUT_TYPE.INPUT_NUMBER:
        children = (
          <InputNumber 
            disabled={readOnly || disabled} 
            min={min} 
            max={max} 
            value={val}
            onChange={onChange}
            addonAfter={item.addonAfter}
            style={{
              width: item.width,
              border: '1px solid #205656'
            }}
          />
        )
        break
      case INPUT_TYPE.SLIDER:
        children = (
          <Slider
            disabled={readOnly || disabled} 
            min={min}
            max={max}
            tooltip={{
              formatter,
              getPopupContainer: triggerNode => triggerNode.parentNode
            }}
            step={item.step}
            value={val}
            onChange={onChange}
            style={{maxWidth: 200}}
          />
        )
        break
      case INPUT_TYPE.COLOR_PICKER:
        children = (
          <ColorPicker 
            disabled={readOnly || disabled} 
            color={val}
            onChange={onChange}
          />
        )
        break
      case INPUT_TYPE.SELECT:
        children = (
          <Select 
            disabled={readOnly || disabled} 
            options={options}
            value={val}
            mode={item.multiple && "multiple"}
            allowClear
            onChange={onChange}
            placeholder="Select an option"
            dropdownMatchSelectWidth={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            showSearch
          />
        )
        break
      case INPUT_TYPE.GRAPH_N_TABLE:
        children = (
          <GraphAndTable 
            page={value}
            defaultValue={val}
            readOnly={readOnly}
          />
        )
        break
      case INPUT_TYPE.TRACK_IMAGE:
        children = (
          <TrackImage
            page={value}
            defaultValue={val}
            readOnly={readOnly}
          />
        )
        break
      case INPUT_TYPE.CASCADER:
        const displayRender = (labels, selectedOptions) => {
          return <LabelWithTooltip labels={labels.join(' / ')}/>
        }
        children = (
          <Cascader 
            disabled={readOnly || disabled} 
            options={options}
            value={val}
            onChange={onChange}
            displayRender={displayRender}
            placeholder="Select an option"
            dropdownMatchSelectWidth={false}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          />
        )
        break
      case INPUT_TYPE.MAP_CASCADER:
        // map 선택시 map id까지 같이 저장 시킴
        // {
        //    selected: mapName, 
        //    selected_id: map_id
        //    seg_value: segValue
        // }
        children = (
          <MapCascader 
            disabled={readOnly || disabled}
            options={options}
            defaultValue={val}
            onChange={onChange}
            readOnly={readOnly}
          />
        )
        break
      case INPUT_TYPE.DIVIDER:
        children = (
          <Divider 
            children={item.children}
            style={{marginBottom: item?.extra?.length && 0}}
          />
        )
        break
      case INPUT_TYPE.REPORT_VALUE_CASCADER:
        children = (
          <ValueSelect
            value={val}
            record={value}
            disabled={readOnly || disabled}
            onChange={onChange}
            options={options}
          />
        )
        break
      case INPUT_TYPE.EXPRESSION:
        children = (
          <ExpressionOfCondition 
            value={val} 
            onChange={onChange}
            inputs={[{candidates}]}
            disabled={readOnly || disabled}
            inReport={true}
            imageTypeList={imageTypeList}
          />
        )
        break
      default:
        children = null
    }
    let parent = (
      <Form.Item
        label={
          hideLabel ? 
          undefined : 
          labelWrap ? 
          labelWrap(item, value) : 
          name
        }
        initialValue={val}
        valuePropName={isSwitch ? "checked" : "value"}
        required={item?.required}
        labelCol={vertical ? { span: 24 }:{span: labelSpan}}
        wrapperCol={vertical ? { span: 24 }:{span: 24 - labelSpan}}
        tooltip={item?.tooltip && tooltipProps(item?.tooltip)}
      >
        {childrenTooltip ? 
          <PopupTooltip
            title={childrenTooltip}
            forceOpen={childrenTooltipForceOpen}
            placement={childrenTooltipPlacement}
          >
            {children}
          </PopupTooltip> :
          children
        }
      </Form.Item>
    )

    const checkConditionExtraItem = extraItem => {
      if (extraItem.triggerValue === undefined) {
        return true
      }
      if (item.type === INPUT_TYPE.SELECT && item.multiple) {
        const triggerValue = extraItem.triggerValue
        const existValue = val?.includes(extraItem.name)
        return triggerValue === existValue
      }
      return val === extraItem.triggerValue
    }

    if (item?.extra?.some(checkConditionExtraItem)) {
      const filteredExtra = item.extra.filter(checkConditionExtraItem)
      extraChildren = (
        <FormDataList 
          template={filteredExtra}
          value={value}
          required={required}
          onUpdate={onUpdate}
          extraMargin={extraMargin}
          readOnly={readOnly}
          GraphAndTable={GraphAndTable}
          TrackImage={TrackImage}
          labelSpan={labelSpan}
        />)
    }

    return (
      <>
        {!hide && parent}
        {extraChildren && (
          <div style={{marginLeft: extraMargin}}>
            {extraChildren}
          </div>
        )}
      </>
    )
  })
}


const ColorPicker = ({color, onChange, icon}) => {
  const [tagColor, setTagColor] = useState(color || DEFAULT_COLOR)

  useEffect(() => {
    if (color) {
      setTagColor(color)
    }
  },[color])

  const handleChangePicker = (color, e) => {
    const hexColor = color.hex
    setTagColor(hexColor)
    onChange(hexColor)
  }
  
  return (
    <>
      <Popover
        trigger={"click"}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        placement="left"
        content={
          <SketchPicker
            styles={{default: {picker: {background: "#CCCCCC", color: "#222222"}}}}
            color={tagColor}
            onChange={handleChangePicker}
          />
        }
      >
        {icon ? 
          <Button icon={icon} style={{color: tagColor}} /> : 
          <TagBase
            color={tagColor}
            style={{cursor: "pointer"}}
          >
            {tagColor}
          </TagBase>
        }
      </Popover>
    </>
  )
}

export const LabelWithTooltip = ({ labels }) => {
  const labelRef = useRef(null);
  const [isEllipsis, setIsEllipsis] = useState(false);

  useEffect(() => {
    const node = labelRef.current;
    if (node) {
      setIsEllipsis(node.offsetWidth < node.scrollWidth);
    }
  }, [labels]);

  return (
    <div ref={labelRef}>
      {isEllipsis ? (
        <Tooltip 
          title={labels}
          getPopupContainer={triggerNode => triggerNode.parentNode.parentNode.parentNode}
          mouseEnterDelay={0.5}
        >
          {labels}
        </Tooltip>
      ) : (
        labels
      )}
    </div>
  );
}

export const MapCascader = ({onChange, disabled, options, defaultValue, style, readOnly}) => {
  const {candidates, imageTypeList} = useContext(taskPropsContext)
  
  const selectedMap = defaultValue?.selected
  const noCandidate = (selectedMap === NONE || !selectedMap) ? 
    false : 
    !candidates?.includes(selectedMap)

  const displayRender = (labels, selectedOptions) => {
    let viewLabels = labels
    if (noCandidate && !readOnly) {
      const mapId = defaultValue.selected_id
      const imagetype = imageTypeList.find(type => type.id === mapId)
      if (imagetype) {
        viewLabels = (
          <PopupTooltip
            title="imagetype not found"
            placement={undefined}
          >
            <TagBase color={imagetype?.tag_color} >
              <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize: 12, marginRight: 8}}/>
              {imagetype?.short}
            </TagBase>
          </PopupTooltip>
        )
      }
      else {
        // 지워진 imagetype
        viewLabels = <TagBase color={'#FF0000'} children={'Not found'}/>
      }
    }
    return (
      <LabelWithTooltip labels={viewLabels}/>
    )
  }

  const getMapCascaderValue = mapArray => {
    if (!mapArray) {
      return undefined
    }
    const target = mapArray?.[0]
    const segValue = mapArray?.[1]
    
    const short = target?.includes(TASK_DELIMITER) ?
      target.split(TASK_DELIMITER)[0] : target
    const imagetype = imageTypeList.find(type => type.short === short)

    const newValue = {
      selected: target,
      selected_id: imagetype?.id
    }
    if (segValue) {
      newValue.seg_value = segValue
    }
    
    return newValue
  }

  const optionsWithKey = options.map((opt, index) => {
    const optWithKey = {
      ...opt, 
      key: index
    }
    if (opt?.children) {
      optWithKey.children = opt.children.map((child, childIndex) => ({
        ...child,
        key: `${index}-${childIndex}`
      }))
    }
    return optWithKey
  })

  const getDefaultValue = () => {
    let value = []
    if (defaultValue?.selected) {
      value.push(defaultValue.selected)
    }
    if (defaultValue?.seg_value) {
      value.push(defaultValue.seg_value)
    }
    return value
  }

  return (
    <Cascader 
      disabled={disabled || readOnly} 
      options={optionsWithKey}
      value={getDefaultValue()}
      onChange={mapArray => onChange(getMapCascaderValue(mapArray))}
      placeholder={!disabled && "Select an option"}
      dropdownMatchSelectWidth={false}
      displayRender={displayRender}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      showSearch={{filter: searchFilter}}
      style={style}
    />
  )
}

const findItemByName = (items, name) => {
  for (let item of items) {
    if (item.name === name) {
      return item;
    }
    if (item.extra) {
      const found = findItemByName(item.extra, name);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export const getSettingFunction = (settingValues, settingTemplate) => (key, getDefault=true) => {
  let value = settingValues?.[key]
  if (value === undefined && getDefault) {
    const template = findItemByName(settingTemplate, key)
    if (!template) {
      return undefined
    }
    return template.default
  }
  return value
}

export const getConfigValue = (globalConfig, globalTemplate, rowConfig, rowTemplate) => key => {
  const getGlobalSettingValue = getSettingFunction(globalConfig, globalTemplate)
  const getRowSettingValue = getSettingFunction(rowConfig, rowTemplate)
  if (getRowSettingValue(key, false) !== undefined) {
    return getRowSettingValue(key)
  }
  return getGlobalSettingValue(key)
}

export const getRandomKey = () => Math.random().toString(36).substring(2, 6);

export const getCurrentTemplate = report => {
  const copiedReport = JSON.parse(JSON.stringify(report))
  const usedBlobtypeIds = []
  const extra = [
    {propName: ROW_INFO.FILTER_CONDITION, key: 'selected_id'},
  ]
  for (const page of copiedReport[PAGES]) {
    const pageType = page.type
    switch (pageType) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        const dataSource = page[pageType] || []
        for (const row of dataSource) {
          const target = row?.target
          const mapId = target?.selected_id
          if (mapId) {
            usedBlobtypeIds.push(mapId)
          }
        }
        for (const item of extra) {
          const {propName} = item
          for (const row of dataSource) {
            const expressions = row?.[propName]
            if (!expressions || expressions?.length === 0) {
              continue
            }
            for (const exp of expressions) {
              const mapId = exp?.[item.key]
              if (mapId) {
                usedBlobtypeIds.push(mapId)
              }
            }
          }
        }
        continue
      case REPORT_TYPE.TRACK_IMAGE:
        const trackImageConfig = page[pageType]
        for (const configName of Object.values(IMAGETYPE_INPUT_DATA)) {
          const mapInfo = trackImageConfig[configName]
          const mapId = mapInfo?.selected_id
          if (mapId) {
            usedBlobtypeIds.push(mapId)
          }
        }
        continue
      default:
        continue
    }
  }
  const reportData = {...report, usedBlobtypeIds: [...new Set(usedBlobtypeIds)]}
  return reportData
}

export const getConvertedReportTemplate = (loadedTemplate, candidates, imageTypeList) => {
  const getChangedTarget = targetId => {
    const blobtypeId = targetId
    if (blobtypeId) {
      const blobType = imageTypeList.find(type => type.id === blobtypeId)
      if (!blobType) {
        return undefined
      }
      const lastTargetCandidate = [...candidates].reverse().find(candidate => {
        const short = candidate.includes(TASK_DELIMITER) ? 
          candidate.split(TASK_DELIMITER)[0] : candidate
        return blobType?.short === short
      })

      if (lastTargetCandidate) {
        return lastTargetCandidate
      }
    }
    return undefined
  }

  const loadedPages = JSON.parse(JSON.stringify(loadedTemplate[PAGES])).map(page => {
    switch (page.type) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        const dataSource = page[page.type] || []
        for (const row of dataSource) {
          if (row.target && row.target?.selected !== NONE) {
            const target = row?.target
            const newTargetSelected = getChangedTarget(target?.selected_id)
            if (newTargetSelected) {
              target.selected = newTargetSelected
            }
          }
          if (row[ROW_INFO.FILTER_CONDITION]) {
            const expressions = row[ROW_INFO.FILTER_CONDITION]
            if (!expressions || expressions?.length === 0) {
              continue
            }
            for (const expression of expressions) {
              const target = expression?.target
              if (target) {
                const newTargetSelected = getChangedTarget(expression?.selected_id)
                if (newTargetSelected) {
                  expression.target = newTargetSelected
                }
              }
            }
          }
        }
        return {
          ...page,
          key: getRandomKey()
        }
      case REPORT_TYPE.TRACK_IMAGE:
        const trackImageConfig = page[page.type]
        for (const configName of Object.values(IMAGETYPE_INPUT_DATA)) {
          const target = trackImageConfig?.[configName]
          if (target?.selected) {
            const newSelected = getChangedTarget(target?.selected_id)
            if (newSelected) {
              trackImageConfig[configName].selected = newSelected
            }
          }
        }
        return {
          ...page,
          key: getRandomKey()
        }
      default:
        return {
          ...page,
          key: getRandomKey()
        }
    }
  })

  const payload = {
    name: loadedTemplate.name,
    pages: loadedPages,
  }

  return payload
}


export const PopupTooltip = ({children, title, placement='left', forceOpen=undefined}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleWheel = () => setOpen(false);

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
      setOpen(false)
    }
  }, []);

  return (
    <Tooltip
      title={title}
      open={forceOpen ?? open}
      placement={placement}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      destroyTooltipOnHide={true}
    >
      <span>{children}</span>
    </Tooltip>
  )
}


const ValueSelect = ({value, record, disabled, onChange, options}) => {
  const [open, setOpen] = useState(false)

  const target = record?.target

  const onCascaderChange = value => {
    let newValue
    if (!value) {
      newValue = undefined
    }
    else {
      const operation = value?.[0]
      const compare = value?.[1]
      
      newValue = {
        operation,
        compare
      }
    }

    onChange(newValue)
  }

  const displayRender = (labels, selectedOptions) => {
    const operation = labels?.[0]
    const compare = labels?.[1]
    let label
    if (compare === OPERATION_COMPARE.CURRENT_VALUE) {
      label = operation
    }
    else {
      label = <>{operation}{` - ${compare}`}</>
    }
    
    return <LabelWithTooltip labels={label}/>
  }
  
  return (
    <Cascader 
      open={open}
      style={{textAlign: 'left'}}
      options={options}
      getPopupContainer={triggerNode => triggerNode}
      showSearch
      disabled={disabled}
      value={
        (target && value && value?.[OPERATION] && value?.[COMPARE]) ? 
        [value[OPERATION], value[COMPARE]] : 
        undefined
      }
      placeholder={!disabled && "Select an option"}
      onChange={onCascaderChange}
      displayRender={displayRender}
      onDropdownVisibleChange={setOpen}
    />
  )
}


export const formatToLocalString = number => {
  const isNumber = typeof number === 'number'
  if (!isNumber) return null
  return number.toLocaleString('en-US')
}


export const roundToTwoDecimalPlaces = number => {
  const isNumber = typeof number === 'number'
  if (!isNumber) return null
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0, // 최소 소수점 이하 자릿수
    maximumFractionDigits: 2, // 최대 소수점 이하 자릿수
  });
}


export const formatToDecimalPlaces = decimalRounding => number => {
  const isNumber = typeof number === 'number'
  if (!isNumber) return null
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0, // 최소 소수점 이하 자릿수
    maximumFractionDigits: decimalRounding, // 최대 소수점 이하 자릿수
  });
}

export const formatToScientificNotation = decimalRounding => number => {
  const isNumber = typeof number === 'number'
  if (!isNumber) return null
  return number.toExponential(decimalRounding)
}