import React, {useContext, useEffect, useState} from "react";
import MultiSegContext from "./multiSegmentationContext";
import {LabelView} from "../components/labelControls";
import {Typography} from "antd";

export const IJKContext = React.createContext();
IJKContext.displayName = 'IJKContext';

export const IJKContextProvider = ({ children }) => {
  const [ijk, setIJK] = useState([0, 0, 0])
  return (
    <IJKContext.Provider
      value={{ijk, setIJK}}
    >
      {children}
    </IJKContext.Provider>
  );
}
export const IJKContextConsumer = IJKContext.Consumer;

export const IJKValue = ({volumeZ, seg, cmap}) => {
  const {ijk, setIJK} = useContext(IJKContext);
  const [cursorValue, setCursorValue] = useState(0)
  useEffect(() => {
    let active = true
    if (active && volumeZ) {
      const voxelValue = volumeZ.imageDataNDarray.get(ijk[0], ijk[1], ijk[2], 0)
      const realValue = voxelValue * volumeZ.metaData.slope + volumeZ.metaData.intercept
      setCursorValue(realValue)
    }
    return () => {
      active = false
    }
  }, [ijk])

  const label = seg ? cmap.find(l => l.value === Math.round(cursorValue)) : null
  return (
    <>
      {/* Statistics 에 이것까지 추가하니까 정사각형 레이아웃 깨져서 임시로 끔*/}
      {/*<Typography.Text copyable={{text:`${[...ijk].join(', ')}`}}>*/}
      {/*  Coordinate (IJK): {`${[...ijk].join(', ')}`}*/}
      {/*</Typography.Text>*/}
      {seg && label != null ?
        <span>
          Value: <LabelView name={label.name} value={label.value} color={label.color}/>
        </span>
        : <>Value: {cursorValue?.toFixed(2)}</>
      }
    </>
  )
}

export default IJKContext;
