// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
// 
// HOW TO USE:
// import applyAppTokenRefreshInterceptor from 'axios.refresh_token.js';
// import axios from 'axios';
// ...
// applyAppTokenRefreshInterceptor(axios); // register the interceptor with all axios instance
// ...
// - Alternatively:
// const apiClient = axios.create({baseUrl: 'example.com/api'});
// applyAppTokenRefreshInterceptor(apiClient); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// ); // register the interceptor with one specific axios instance
//
// PS: You may need to figure out some minor things yourself as this is just a proof of concept and not a tutorial.
// Forgive me in advance
import axios from 'axios';
import {getCookie} from "./cookie";
import {refreshFail} from "../redux/modules/auth";
import { msgError } from '../redux/modules/message';

const shouldIntercept = (error) => {
  try {
    return error.config.url !== '/token/refresh' && error.response.status === 401
  } catch (e) {
    return false;
  }
};

const setTokenData = (userData = {}, axiosClient) => {
  const csrf_access_token = getCookie('csrf_access_token')
  axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
  // If necessary: save to storage
  //   tokenData's content includes data from handleTokenRefresh(): {
  //     idToken: data.auth_token,
  //     refreshToken: data.refresh_token,
  //     expiresAt: data.expires_in,
  // };
};

const handleTokenRefresh = () => {
  // const refreshToken = window.localStorage.getItem('refreshToken');
  const csrf_refresh_token = getCookie('csrf_refresh_token')
  return new Promise((resolve, reject) => {
    axios.post('/token/refresh', {}, {
      headers: {
        'X-CSRF-Token': csrf_refresh_token
      },
      withCredentials: true, // For transition cookie
    }).then(({data}) => {
      resolve({user: data});
    }).catch((err) => {
      reject(err);
    })
  });
};

const attachTokenToRequest = (request, userData) => {
  // request.headers['Authorization'] = 'Bearer ' + token;
  const csrf_access_token = getCookie('csrf_access_token')
  request.headers['X-CSRF-Token'] = csrf_access_token;

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  // if (/\/orders/.test(request.url)) {
  //     request.params.token = token;
  // }
};

const applyAppTokenRefreshInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const onRejected = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(token => {
        originalRequest._queued = true;
        options.attachTokenToRequest(originalRequest, token);
        return axiosClient.request(originalRequest);
      }).catch(err => {
        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh.call(options.handleTokenRefresh)
      .then((userData) => {
        options.setTokenData(userData, axiosClient);
        options.attachTokenToRequest(originalRequest, userData);
        processQueue(null, userData);
        resolve(axiosClient.request(originalRequest));
      })
      .catch((err) => {
        processQueue(err, null);
        reject(err);
        // refresh failed....
        options.store.dispatch(msgError(err))
        options.store.dispatch(refreshFail())
        options.history.push('/login');
        // TODO 뭔가 좀 가혹한데 id/passwd 한 번 더 물어보기?
      })
      .finally(() => {
        isRefreshing = false;
      })
    });
  };

  axiosClient.interceptors.response.use(undefined, onRejected);
};

export default applyAppTokenRefreshInterceptor;