import { memo, useCallback, useContext, useEffect, useRef, useState } from "react"
import { ALL_SEG_CMAP, CONFIG, DEFAULT_COLOR, NONE_TYPE_MAP, PREVIEW, SETTING, SUMMARY_PADDING, 
  SUMMARY_WIDTH, TABLE_ROW_TYPE, OPERATION_COMPARE, VALUE_OPERATION, TRACK, REPORT_TYPE, 
  capitalizeFirstLetter, getConfigValue, getSettingFunction, reportContext, taskPropsContext, 
  OPERATION, COMPARE, INPUT_TYPE, FormDataList, PopupTooltip, ROW_INFO, roundToTwoDecimalPlaces, formatToDecimalPlaces, formatToScientificNotation } from "./utils"
import { useDispatch, useSelector } from "react-redux";
import { TASK_DELIMITER } from "../../lib/taskUtil";
import { updatePageProperty } from "./reportReducer";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, MinusCircleOutlined, QuestionCircleOutlined, CloseCircleOutlined, 
  CloseOutlined, FilterTwoTone, CloseCircleFilled } from '@ant-design/icons';
import { Input, Select, Space, Button, Table, Popconfirm, Popover } from "antd";
import { LabelView } from "../labelControls";
import { TagBase } from "../tagBase";
import { updatePreviewOpen } from "../../redux/modules/pipeline";
import { GraphNTableRowSettingBtn, GraphNTableSettingBtn, getFontStyle, getGraphTableTemplate, 
  TABLE_TYPE_SETTING_NAMES, GRAPH_TYPE_SETTING_NAMES, getGroups, getGraphGroupRows, 
  TEXT_TYPE_SETTING_NAMES, tableTypeItems, appliedFuncTemplate, applyDisabledFunc } from "./settings";
import Plot from "react-plotly.js";
import { SummaryColorTable } from "../styledComponent";
import { useGetImageTypeHooks } from "../../hooks/useImageTypeHooks";

const SortableItem = memo(SortableElement((props) => <tr {...props} />));
const SortableBody = memo(SortableContainer((props) => <tbody {...props} />));
const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999', padding : '10px'}} />);

const LABEL_WIDTH = 220
const MARGIN_RIGHT = 30
const MARGIN_LEFT = 30

const COLUMN_PADDING = 16

const MemoizedTable = memo(Table)

const getChangedTypeRow = (record, type) => {
  const newRecord = {
    type,
    target: undefined,
    value: undefined,
    config: {},
  }
  if (!record.target) {
    return newRecord
  }
  switch (type) {
    case TABLE_ROW_TYPE.TABLE:
      {
        if (!record.value) {
          return {
            ...newRecord,
            target: record.target
          }
        }
        if (record.value) {
          return {
            ...newRecord,
            target: record.target,
            value: record.value
          }
        }
        return newRecord
      }
    case TABLE_ROW_TYPE.GRAPH:
      {
        if (record.target.selected === NONE_TYPE_MAP.value) {
          return newRecord
        }
        if (!record.value) {
          return {
            ...newRecord,
            target: record.target
          }
        }
        if (record.value) {
          const value = record.value
          const operation = value[OPERATION]
          if (operation === VALUE_OPERATION.HISTOGRAM || operation === VALUE_OPERATION.SPARKLINE) {
            return {
              ...newRecord,
              target: record.target,
            }
          }
          else {
            return {
              ...newRecord,
              target: record.target,
              value: record.value
            }
          }
        }
        return newRecord
      }
    default:
      return newRecord
  }
}

const DataColumnRender = ({record, onRowUpdate, readOnly}) => {
  const [hover, setHover] = useState(false)
  const {candidates, imageTypeList} = useContext(taskPropsContext)
  const [open, setOpen] = useState(false)
  const [row, setRow] = useState(record)
  useEffect(() => {
    setRow(record)
  },[record])

  const {getTagFromTypeId, getTypeFromTypeId, getTypeFromShort, getTypeFromDisplay} = useGetImageTypeHooks(imageTypeList)
  
  const rowType = row?.type
  const isTextType = rowType === TABLE_ROW_TYPE.TEXT
  
  const getRenderData = () => {
    let selectedMap = <></>
    let selectedLabel = <></>
    let value
    let noCandidate = false
    
    const errorIcon = <CloseCircleFilled style={{fontSize: 14, marginRight: 8, color: "#ff4d4f"}}/>
    const ErrorMessage = ({message}) => {
      return (
        <span style={{color: '#ff4d4f'}}>{message}</span>
      )
    }
    const FilterIcon = ({length=0}) => {
      return (
        <>
          {length > 0 && (
            <span style={{marginLeft: 8, marginRight: 8, fontSize: 14}}>
              <FilterTwoTone twoToneColor='#0D6E6D' />
              <span>{length}</span>
            </span>
          )}
        </>
      )
    }
    
    if (isTextType) {
      return 'N/A'
    }
    const target = row.target
    if (!target) {
      return (
        <>
          {errorIcon}
          <ErrorMessage message={'Empty target'}/>
        </>
      )
    }
    const selected = target.selected
    if (selected === NONE_TYPE_MAP.value) {
      return <TagBase {...NONE_TYPE_MAP} children={NONE_TYPE_MAP.name}/>
    }
    const selectedId = target.selected_id
    const display = target.display
    const imagetype = getTypeFromTypeId(selectedId) || getTypeFromDisplay(display)
    
    noCandidate = !candidates?.includes(selected)
    selectedMap = getTagFromTypeId(imagetype?.id, selected)
    
    const segValue = target?.seg_value
    if (segValue !== undefined) {
      if (imagetype?.seg) {
        if (segValue === ALL_SEG_CMAP.value) {
          selectedLabel = <TagBase {...ALL_SEG_CMAP} children={'All'}/>
        }
        const selectedCmap = imagetype.seg_cmap?.find(cmap => cmap.value === segValue) 
        if (selectedCmap) {
          selectedLabel = <LabelView {...selectedCmap}/>
        }
      }
    }

    if (noCandidate & !readOnly) {
      return (
        <>
          {errorIcon}
          {selectedMap}
          {selectedLabel}
          <ErrorMessage message={'Imagetype not found'}/>
        </>
      )
    }

    if (!row.value) {
      return (
        <>
          {errorIcon}
          {selectedMap}
          {selectedLabel}
          <ErrorMessage message={'Empty value'}/>
        </>
      )
    }
    const operation = row?.value?.operation === VALUE_OPERATION.VOLUME ? 
      <>{VALUE_OPERATION.VOLUME} [<>mm<sup>3</sup></>]</> : 
      row?.value?.operation
    if (row?.value?.compare === OPERATION_COMPARE.CURRENT_VALUE) {
      value = <span style={{marginRight: 8}}>{operation}</span>
    }
    else {
      value = <span style={{marginRight: 8}}>{operation}{` - ${row?.value?.compare}`}</span>
    }

    if (row?.[ROW_INFO.FILTER_CONDITION]) {
      const expressions = row?.[ROW_INFO.FILTER_CONDITION]
      if (
        expressions?.length && 
        (expressions.some(exp => exp?.target === undefined) ||
        expressions.some(exp => exp?.value === undefined))
      ) {
        return (
          <>
            {errorIcon}
            {selectedMap}
            {selectedLabel}
            {value}
            <ErrorMessage message={'Invalid filter condition'}/>
          </>
        )
      }
      for (const exp of expressions) {
        const target = exp.target
        if (!candidates?.includes(target) && !readOnly) {
          return (
            <>
              {errorIcon}
              {selectedMap}
              {selectedLabel}
              {value}
              <ErrorMessage message={'Invalid filter condition'}/>
            </>
          )
        }
      }
    }

    return (
      <div>
        {selectedMap}
        {selectedLabel}
        {value}
        <FilterIcon length={row?.[ROW_INFO.FILTER_CONDITION]?.length}/>
      </div>
    )
  }
  
  const template = targetInfoSettings({row, candidates, imageTypeList, readOnly}).filter(setting => ![ROW_INFO.NAME, ROW_INFO.TYPE].includes(setting.name))
  const appliedDisabledTemplate = appliedFuncTemplate(template, applyDisabledFunc, row)

  const onUpdate = payload => {
    let newRow = {
      ...row
    }
    const {name, value} = payload
    switch (name) {
      case ROW_INFO.TARGET:
        if (value?.selected === NONE_TYPE_MAP.value) {
          newRow = {
            ...newRow,
            value: undefined,
          }
          delete newRow[ROW_INFO.FILTER_CONDITION]
        }
        const newTargetValue = getNewTargetValue(value, imageTypeList, newRow)
        newRow = {
          ...newRow,
          target: value,
          ...newTargetValue
        }
        break
      case ROW_INFO.VALUE:
        newRow = {
          ...newRow,
          [name]: value
        }
        break
      case ROW_INFO.FILTER_CONDITION:
        newRow = {
          ...newRow,
          [name]: value.map(exp => {
            const {target} = exp
            const short = target?.includes(TASK_DELIMITER) ?  
              target?.split(TASK_DELIMITER)[0] : target
            const imageType = getTypeFromShort(short)
            return {
              ...exp,
              selected_id: imageType?.id
            }
          })
        }
        break
      default:
        newRow = {
          ...newRow,
          [name]: value
        }
    }
    setRow(newRow)
    onRowUpdate(newRow, false)
  }
  
  const popoverXMargin = 16
  const popoverYMargin = 12
  return (
    <Popover
      trigger={'click'}
      open={isTextType ? false : open}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onOpenChange={open => {
        setOpen(open)
        if (!open) {
          let newRow = {
            ...row
          }
          if (!newRow?.name && newRow?.value) {
            newRow = {
              ...newRow,
              name: newRow?.value?.operation
            }
          }
          onRowUpdate(newRow)
        }
      }}
      content={
        <div style={{width: 500}}>
          {open && 
            <>
              <div style={{ 
                position: 'relative', 
                textAlign: 'center', 
                marginBottom: 16,
                backgroundColor: '#1b3e46',
                marginRight: -popoverXMargin,
                marginLeft: -popoverXMargin,
                marginTop: -popoverYMargin,
                border: '1px solid #0C2329',
              }}>
                <span>Data definition</span>
                <CloseOutlined
                  style={{ 
                    position: 'absolute', 
                    right: '16px', // 오른쪽 여백을 원하는 만큼 조정해주세요
                    top: '50%', // 상단에서 50% 위치에 있도록 설정
                    transform: 'translateY(-50%)', // Y축 기준으로 50%만큼 이동하여 중앙에 위치하도록 조정
                    cursor: 'pointer' 
                  }}
                  onClick={()=> setOpen(false)}
                />
              </div>
              <FormDataList
                template={appliedDisabledTemplate}
                value={record}
                onUpdate={onUpdate}
                readOnly={readOnly}
                labelSpan={8}
              />
            </>
          }
        </div>
      }
    >
      <div 
        style={{
          cursor: isTextType ? undefined : 'pointer',
          textDecoration: (hover && !isTextType) && 'underline',
          fontWeight: (open && !isTextType) && 'bold',
          width: '100%',
          textAlign: 'left'
        }}
        onClick={() => setOpen(true)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {getRenderData()}
      </div>
    </Popover>
  )
}

const checkGraphTypeLayoutLeader = dataSource => {
  const groups = getGroups(dataSource)
  const graphGroups = groups.filter(group => group.type === TABLE_ROW_TYPE.GRAPH)

  const changeFirstRowInGroup = group => {
    for (const [index, rowInGroup] of group.entries()) {
      if (index === 0) {
        rowInGroup.config[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP] = true
      }
      else {
        delete rowInGroup.config[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP]
      }
    }
  }
  for (const graphGroup of graphGroups) {
    let group = []
    for (const row of graphGroup.rows) {
      const compare = row?.value?.[COMPARE]
      if (group.length === 0) {
        if (compare !== OPERATION_COMPARE.CURRENT_VALUE) {
          row.config[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP] = true
        }
        else {
          group.push(row)
        }
      }
      else {
        if (compare !== OPERATION_COMPARE.CURRENT_VALUE) {
          row.config[GRAPH_TYPE_SETTING_NAMES.FIRST_ROW_IN_GROUP] = true
          changeFirstRowInGroup(group)
          group = []
        }
        else {
          group.push(row)
        }
      }
    }
    if (group.length > 0) {
      changeFirstRowInGroup(group)
    }
  }
}

const GraphAndTable = ({defaultValue: dataSource, page, readOnly}) => {
  const dispatch = useDispatch()
  const {dispatchReport} = useContext(reportContext)
  const open = useSelector(state => state?.pipeline?.preview_open)
  const [updateSortableComponents, setUpdateSortableComponents] = useState(false)

  const [copiedPage, setCopiedPage] = useState(page)
  
  const copiedDataSource = useRef(dataSource)

  useEffect(() => {
    setCopiedPage(page)
    setUpdateSortableComponents(true)
  },[page.key])
  
  useEffect(() => {
    copiedDataSource.current = dataSource
    setUpdateSortableComponents(false)
  },[dataSource])
  
  const updateDataSource = useCallback((newDataSource=dataSource, update=true) => {
    checkGraphTypeLayoutLeader(newDataSource)
    
    const payload = {name: REPORT_TYPE.GRAPH_N_TABLE, value: newDataSource}
    dispatchReport(updatePageProperty(payload))
    if (update) {
      return setUpdateSortableComponents(true)
    }
  },[])

  const updateCopiedPage = useCallback((newPage=page) => {
    setCopiedPage(newPage)
  },[page])

  const columns = [
    {
      title: "",
      width: 0,
      align: 'center',
      hide: readOnly,
      render: () => <DragHandle />
    },
    {
      title: 'name',
      align: 'center',
      width: 150,
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => { 
        const onChange = e => {
          const value = e.target.value

          const newRecord = {
            ...record, 
            name: value
          }
          const newDataSource = dataSource.map((data, index) => {
            if (index === record.index) {
              return {...data, ...newRecord}
            }
            return data
          })
          return updateDataSource(newDataSource, false)
        }
        return <Input defaultValue={name} onChange={onChange} style={{minWidth: 100}} disabled={readOnly}/>
      }
    },
    {
      title: 'type',
      align: 'center',
      width: 100,
      dataIndex: 'type',
      key: 'type',
      render: (type, record) => {
        const options = tableTypeItems.filter(item => {
          return item.value !== PREVIEW
        }).map(item => {
          const label = (
            <div>
              {item.icon}
              <span style={{marginLeft: 4}}>
                {capitalizeFirstLetter(item.value)}
              </span>
            </div>
          )
          return {...item, label}
        })

        const onChange = (value, option) => {
          const newRecord = getChangedTypeRow(record, value)
          const newDataSource = dataSource.map((data, index) => {
            if (index === record.index) {
              const newValue = {
                ...data, 
                ...newRecord
              }
              if (value === TABLE_ROW_TYPE.TEXT) {
                delete newValue[ROW_INFO.FILTER_CONDITION]
              }
              return newValue
            }
            return data
          })
          return updateDataSource(newDataSource)
        }

        return (
          <Select 
            options={options}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            defaultValue={type}
            onChange={onChange}
            showSearch
            style={{textAlign: 'left'}}
            disabled={readOnly}
          />
        )
      }
    },
    {
      title: 'data',
      align: 'center',
      key: 'data',
      render: record => {
        return (
          <DataColumnRender 
            record={record}
            onRowUpdate={onRowUpdate}
            readOnly={readOnly}
          />
        )
      }
    },
    {
      title: 'config',
      align: 'center',
      width: 60,
      dataIndex: 'config',
      hide: readOnly,
      key: 'config',
      render: (val, record, index) => (
        <GraphNTableRowSettingBtn 
          config={val} 
          index={index}
          type={record.type}
          record={record}
          page={page}
        />
      )
    },
    {
      title: '',
      align: 'center',
      hide: readOnly,
      width: 40,
      render: record => {
        const onConfirm = () => {
          const filteredDataSource = dataSource.filter(data => data !== record).map((data, index) => ({...data, index}))
          return updateDataSource(filteredDataSource)
        }
        return (
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={onConfirm}
            placement="left"
            getPopupContainer={triggerNode => triggerNode.parentNode}
            overlayInnerStyle={{width: 150}}
          >
            <MinusCircleOutlined style={{cursor: 'pointer'}}/>
          </Popconfirm>
        )  
      }
    },
  ].filter(col => !col.hide)

  const addRow = () => {
    const newRow = {
      type: TABLE_ROW_TYPE.TABLE,
      name: undefined,
      target: undefined,
      value: undefined,
      [CONFIG]: {}
    }
    const newDataSource = [...dataSource, newRow].map((data, index) => ({...data, index}))
    return updateDataSource(newDataSource)
  }

  const getNewDataSourceOnMove = (dataSource, oldIndex, newIndex) => {
    const dataSourceCopy = dataSource.map(ds => {
      ds.oldIndex = ds.index
      return ds
    })
  
    const ds2move = dataSourceCopy[oldIndex]
    dataSourceCopy.splice(oldIndex, 1)
    if (newIndex != null) {
      dataSourceCopy.splice(newIndex, 0, ds2move)
    }
  
    const newDataSource = dataSourceCopy.map((data, index) => {
      const newData = {
        ...data,
        index,
      }
  
      delete newData.oldIndex
  
      return newData
    })
    
    return newDataSource
  }

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setUpdateSortableComponents(true)
    document.body.style.cursor = 'default';
    if (oldIndex === newIndex) return
    const newDataSource = getNewDataSourceOnMove(copiedDataSource.current, oldIndex, newIndex) || []
    return updateDataSource(newDataSource)
  },[copiedDataSource])


  const DraggableContainer = useCallback(props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortStart={() => {(
        document.body.style.cursor = 'grabbing'
        )}}
      onSortEnd={onSortEnd}
      {...props}
    />
  ),[onSortEnd]);

  const DraggableBodyRow = useCallback(({ className, style, ...restProps }) => {
    const index = copiedDataSource.current.findIndex((x) => x.index === restProps['data-row-key']);
    if (index !== -1) {
      return <SortableItem index={index} {...restProps} />;
    }
    return <tr className={className} style={style} {...restProps} />
  },[copiedDataSource, updateSortableComponents])

  const togglePreview = () => dispatch(updatePreviewOpen(!open))
  
  const onRowUpdate = (newRecord, update=true) => {
    const newDataSource = dataSource.map((data, index) => {
      if (index === newRecord.index) {
        return {
          ...newRecord,
          index
        }
      }
      return {
        ...data,
        index
      }
    })
    return updateDataSource(newDataSource, update)
  }

  return (
    <div style={{position: 'relative'}}>
      {!readOnly && 
        <Space style={{height: 35, float: 'right'}}>
          <Button onClick={addRow}>
            Add row
          </Button>
          <div>
            <Button onClick={togglePreview}>
              Preview
            </Button>
            <GraphNTableSettingBtn 
              page={copiedPage}
              updateCopiedPage={updateCopiedPage}
            /> 
          </div>
        </Space>
      }
      <MemoizedTable
        columns={columns}
        dataSource={dataSource}
        size='small'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        rowKey="index"
        pagination={false}
      />
    </div>
  )
}

export default GraphAndTable

export const GraphTablePreview = ({page, dataList=[], contentWidth=SUMMARY_WIDTH, candidates, capture=false}) => {
  const graphTableRows = page[REPORT_TYPE.GRAPH_N_TABLE] || []

  const groupRows = getGroups(graphTableRows)

  const previewContentWidth = contentWidth - MARGIN_LEFT - (SUMMARY_PADDING * 2)

  return (
    <div style={{marginLeft: MARGIN_LEFT}}>
      {groupRows.map(group => {
        const {type, rows} = group
        switch (type) {
          case TABLE_ROW_TYPE.TABLE:
            return (
              <TableTypePreview 
                rows={rows}
                viewDataList={dataList}
                page={page}
                contentWidth={previewContentWidth}
                candidates={candidates}
                capture={capture}
              />
            )
          case TABLE_ROW_TYPE.GRAPH:
            return (
              <GraphTypePreview 
                rows={rows}
                viewDataList={dataList}
                page={page}
                contentWidth={previewContentWidth}
                candidates={candidates}
                capture={capture}
              />
            )
          case TABLE_ROW_TYPE.TEXT:
            return (
              <TextTypePreview 
                rows={rows}
                page={page}
              />
            )
          default:
            return null
        }
      })}
    </div>
  )
}


const TextTypePreview = ({rows, page}) => {
  const globalSetting = page?.[SETTING]
  const globalTextSettingValues = globalSetting?.[TABLE_ROW_TYPE.TEXT] || []

  const textTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TEXT)
  
  return (
    <>
      {rows.map(row => {
        const textRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TEXT, row)
        const getRowConfigValue = getConfigValue(globalTextSettingValues, textTemplate, row.config, textRowTemplate)
        const textStyleValues = getRowConfigValue(TEXT_TYPE_SETTING_NAMES.TEXT_STYLE)
        const fontStyle = getFontStyle(textStyleValues, TEXT_TYPE_SETTING_NAMES.TEXT_STYLE, textRowTemplate)
        
        return (
          <div
            style={{
              ...fontStyle,
              marginRight: MARGIN_RIGHT
            }}
          >{row.name}</div>
        )
      })}
    </>
  )
}

const TableInGraphPreview = ({dateValue, row, columnWidth, page}) => {
  const globalSetting = page?.[SETTING]
  const globalTableSettingValues = globalSetting?.[TABLE_ROW_TYPE.TABLE] || []

  const tableTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE)
  
  const tableRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE, row)

  const getRowConfigValue = getConfigValue(globalTableSettingValues, tableTemplate, row.config, tableRowTemplate)

  const rowValue = row?.value
  const operation = rowValue?.[OPERATION]

  const graphHeight = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.HEIGHT)
  const lineColor = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.LINE_COLOR)
  const labelView = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.TICK_MARKS)
  const valueStyle = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.TICK_FONT)
  const fontStyle = getFontStyle(valueStyle, TABLE_TYPE_SETTING_NAMES.TICK_FONT, tableRowTemplate)
  
  const startZero = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.Y_AXIS_RANGE_TO_ZERO)
  const logScale = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.Y_AXIS_LOG_SCALE)
  
  const value = dateValue?.[row.index]

  const getData = () => {
    const baseGraphData = {
      type : operation === VALUE_OPERATION.HISTOGRAM ? 'bar' : 'scatter',
      marker: {
        color : lineColor
      },
    }
    
    return [{
      x: value.bin_centers,
      y: value.bins,
      ...baseGraphData
    }]
  }
  const data = getData()

  const labelConfig = labelView ? 
  {
    automargin: true,
    tickfont: {
      color: fontStyle.color,
      size: fontStyle.fontSize
    },
  } :
  {
    zeroline: false
  }

  const axisStyle = {
    xaxis: {
      ...labelConfig
    },
    yaxis: {
      ...labelConfig,
      rangemode: startZero && 'tozero',
      type: logScale && 'log'
    }
  }

  const layout = {
    autosize: true,
    plot_bgcolor: 'black',
    paper_bgcolor: 'black',
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
      pad: 0,
    },
    ...axisStyle
  };
  return (
    <div style={{width: '100%'}}>
      <Plot
        data={data}
        layout={layout}
        style={{ 
          width: columnWidth - (COLUMN_PADDING * 2), 
          maxWidth: 400,
          margin: 'auto',
          height: graphHeight
        }}
        config={{
          staticPlot: true,
        }}
      />
    </div>
  )
}

const TableTypePreview = ({rows, viewDataList, page, contentWidth, candidates, capture}) => {
  const globalSetting = page?.[SETTING]
  const globalTableSettingValues = globalSetting?.[TABLE_ROW_TYPE.TABLE] || []

  const tableTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE)
  const getGlobalSettingValue = getSettingFunction(globalTableSettingValues, tableTemplate)


  const getColumnWidth = () => {
    const newColumnWidth = viewDataList.length ? 
      (contentWidth - LABEL_WIDTH * 2 - MARGIN_RIGHT) / viewDataList.length : 
      0
    return newColumnWidth
  }
  
  const columnWidth = getColumnWidth()
  
  const getTableSize = () => {
    const tableSizeValue = getGlobalSettingValue(TABLE_TYPE_SETTING_NAMES.LINE_HEIGHT)
    const tableSizeSetting = tableTemplate.find(setting => setting.name === TABLE_TYPE_SETTING_NAMES.LINE_HEIGHT)
    const formatter = tableSizeSetting?.formatter
    if (!formatter) {
      return 'large'
    }
    return formatter(tableSizeValue)
  }
  const tableSize = getTableSize()

  const getDataSource = dateValueList => {
    let dataSource = rows.map(row => {
      const name = row.name
      
      const value = {name, row}
      const rowValue = row?.value 
      const tableRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE, row)

      const getRowConfigValue = getConfigValue(globalTableSettingValues, tableTemplate, row.config, tableRowTemplate)
      const valueStyle = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.VALUE)
      const fontStyle = getFontStyle(valueStyle, TABLE_TYPE_SETTING_NAMES.VALUE, tableRowTemplate)
      const dataPresentation = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.DATA_PRESENTATION, tableRowTemplate)
      const decimalRounding = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.DECIMAL_ROUNDING, tableRowTemplate)
      const valueWrapper = dataPresentation === TABLE_TYPE_SETTING_NAMES.PRECISION ? 
      formatToDecimalPlaces(decimalRounding) :
      formatToScientificNotation(decimalRounding)

      if (dateValueList && rowValue) {
        for (const [index, dateValue] of dateValueList.entries()) {
          const date = dateValue.date
          const compare = rowValue?.[COMPARE]
          const operation = rowValue?.[OPERATION]
          if (dateValue?.[row.index] === undefined) {
            break
          }
          let children = undefined

          let style = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: columnWidth - (COLUMN_PADDING * 2),
            ...fontStyle
          }
          
          switch (compare) {
            case OPERATION_COMPARE.CURRENT_VALUE:
              if (
                operation === VALUE_OPERATION.HISTOGRAM || 
                operation === VALUE_OPERATION.SPARKLINE
              ) {
                children = (
                  <TableInGraphPreview 
                    row={row}
                    page={page}
                    dateValue={dateValue}
                    columnWidth={columnWidth}
                  />
                )
              }
              break
            case OPERATION_COMPARE.CHANGE_FROM_START:
            case OPERATION_COMPARE.CHANGE_FROM_LAST:
              if (!operation || index === 0) {
                children = null
                break
              }
              const curValue = dateValue?.[row.index]
              const previousValue = dateValueList?.[index - 1]?.[row.index]
              const firstValue = dateValueList?.[0]?.[row.index]
              const standardValue = compare === OPERATION_COMPARE.CHANGE_FROM_START ? 
                firstValue :
                previousValue

              const changeValue = curValue - standardValue
              const percentage = changeValue / standardValue * 100
              const colorizeChangeValue = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.COLORIZE_CHANGE_VALUE)
              let customStyle = {...style}
              if (colorizeChangeValue) {
                const ascendingColor = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.ASCENDING)
                const descendingColor = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.DESCENDING)
                const color = changeValue > 0 ? 
                  ascendingColor : 
                  changeValue < 0 ? 
                  descendingColor :
                  DEFAULT_COLOR
                customStyle = {...customStyle, color}
              }

              children = (
                <div>
                  <div style={customStyle}>{valueWrapper(changeValue)}</div>
                  <div style={customStyle}>{`(${roundToTwoDecimalPlaces(percentage)}%)`}</div>
                </div>
              )
              break
            default:
              break
          }
          if (children === undefined) {
            const viewValue = valueWrapper(dateValue?.[row.index])
            children = <div style={style}>{viewValue}</div>
          }
          
          if (candidates && !candidates.includes(row?.target?.selected) && !capture) {
            children = (
              <Space style={{color: 'red', fontSize: 16}}>
                <CloseCircleOutlined />N/A
              </Space>
            )
          }

          value[date] = children
        }
      }
      return value
    })
    return dataSource
  }

  const baseColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: LABEL_WIDTH,
      render: (name, record) => {
        const tableRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE, record)
        const getRowConfigValue = getConfigValue(globalTableSettingValues, tableTemplate, record?.row?.config, tableRowTemplate)
        const valueStyle = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.LABEL)
        const fontStyle = getFontStyle(valueStyle, TABLE_TYPE_SETTING_NAMES.LABEL, tableRowTemplate)
        return (
          <div
            style={{
              ...fontStyle, 
              margin: 0, 
              width: '100%',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              overflowWrap: 'break-word',
            }}
          >
            {name}
          </div>
        )
      }
    }
  ]

  const dataSource = getDataSource(viewDataList)

  const dateStyleValues = getGlobalSettingValue(TABLE_TYPE_SETTING_NAMES.DATE)
  const dateFontStyle = getFontStyle(dateStyleValues, TABLE_TYPE_SETTING_NAMES.DATE, tableTemplate)

  const LAST_TABLE_COLUMN_RATIO = 0.6
  
  const columns = [
    ...baseColumns, 
    ...viewDataList.map(data => {
      const date = data.date
      return ({
        title: (
          <p 
            style={{
              margin: 0,
              wordWrap: 'break-word',
              wordBreak: 'break-all',
              overflowWrap: 'break-word',
              ...dateFontStyle
            }}
          >
            {date}
          </p>
        ),
        dataIndex: date, 
        key: date,
        align: 'center',
        width: columnWidth,
      })
    }),
    {
      title: '',
      key: 'index',
      width: LABEL_WIDTH * LAST_TABLE_COLUMN_RATIO,
      render: () => ''
    }
  ]
  
  return (
    <div style={{marginRight: MARGIN_RIGHT}}>
      <SummaryColorTable
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="index"
        size={tableSize}
        style={{marginRight: LABEL_WIDTH * (1 - LAST_TABLE_COLUMN_RATIO)}}
      />
    </div>
  )
}

const GraphTypePreview = ({rows, viewDataList, page, contentWidth=SUMMARY_WIDTH, candidates, capture}) => {
  const globalSetting = page[SETTING]
  const globalGraphSettingValues = globalSetting?.[TABLE_ROW_TYPE.GRAPH] || {}
  
  const graphTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.GRAPH)

  const groupRows = getGraphGroupRows(page, rows)

  const LineGraph = ({rows, compare, width}) => {
    const xLabels = viewDataList.map(d => d.date)
    const xValues = xLabels.map((d, i) => i)
    const firstRow = rows[0] 
    const graphRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.GRAPH, firstRow)

    const getRowConfigValue = getConfigValue(globalGraphSettingValues, graphTemplate, firstRow.config, graphRowTemplate)
  
    const graphHeight = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.HEIGHT)
    const yAxisStartAtZero = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.Y_AXIS_START_AT_ZERO)
    const viewXAxisLabel = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.X_AXIS_LABEL_DATE)
    const unit = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.UNIT)
    const yAxisScaleStretch = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.Y_AXIS_SCALE_STRETCH)
    const marginBottom = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.BOTTOM_MARGIN)
    const fontStyleValue = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.FONT_STYLE)
    const fontStyle = getFontStyle(fontStyleValue, GRAPH_TYPE_SETTING_NAMES.FONT_STYLE, graphRowTemplate)
    const fontColor = fontStyle.color
    const fontSize = fontStyle.fontSize
    const yLogScale = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.Y_AXIS_LOG_SCALE)

    const viewTitle = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.VIEW_TITLE)
    const title = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.TITLE)
    const titleStyleValue = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.TITLE_STYLE)
    const titleStyle = getFontStyle(titleStyleValue, GRAPH_TYPE_SETTING_NAMES.TITLE_STYLE, graphRowTemplate)
    
    const getData = () => {
      const data = rows?.map(row => {
        const getRowConfigValue = getConfigValue(globalGraphSettingValues, graphTemplate, row.config, graphRowTemplate)
        const lineColor = getRowConfigValue(GRAPH_TYPE_SETTING_NAMES.LINE_COLOR)
        const operation = row?.value?.[OPERATION]
        if (!operation) {
          return null
        }
        const name = row.name
        if (compare !== OPERATION_COMPARE.CURRENT_VALUE) {
          const yValues = viewDataList.map(d => d?.[row.index])
          const changeValueInformation = yValues.map((yValue, index) => {
            if (index === 0) {
              return ({
                changeValue: 0,
                changePercentage: 0
              })
            }
            const standardValue = compare === OPERATION_COMPARE.CHANGE_FROM_START ?
              yValues[0] :
              yValues[index - 1]
            const changeValue = yValue - standardValue
            const changePercentage = (changeValue / standardValue)
            return ({
              changeValue,
              changePercentage
            })
          })
          const changeValues = changeValueInformation.map(info => info?.changeValue)
          const changePercentage = changeValueInformation.map(info => info?.changePercentage)
          const changeValueTrace = {
            x: xValues,
            y: yLogScale ? changeValues.map(value => value > 0 ? value : null) : changeValues,
            name,
            type: 'scatter',
            yaxis: 'y1',
            marker: {
              color: lineColor
            },
          }
          const changePercentageTrace = {
            x: xValues,
            y: changePercentage,
            name: 'percentage',
            type: 'bar',
            yaxis: 'y2',
            marker: {
              color: 'rgba(255,255,255,0.4)'
            },
          }
          return [
            changeValueTrace, 
            changePercentageTrace
          ]
        }
        
        const yValues = viewDataList.map(d => d[row.index])
        
        const graphType = 'scatter'
        return {
          x: xValues,
          y: yValues,
          type: graphType,
          marker: {
            color: lineColor
          },
          name,
          showlegend: true,
        }
      })
      
      if (compare !== OPERATION_COMPARE.CURRENT_VALUE) {
        return data[0]
      }
      return data
    }

    const data = getData()

    const usedShortNames = rows.map(row => row?.target?.selected)
    const usedShortNotInCandidates = usedShortNames.some(shortName => !candidates?.includes(shortName))

    const layout = {
      title: viewTitle && {
        text: title,
        font: {
          color: titleStyle.color,
          size: titleStyle.fontSize,
        },
      },
      autosize: true,
      plot_bgcolor: 'black',
      paper_bgcolor: 'black',
      margin: {
        l: LABEL_WIDTH,
        r: LABEL_WIDTH,
        t: viewTitle ? titleStyle.fontSize * 2 : 50,
        b: marginBottom,
        pad: 10,
      },
      xaxis: {
        automargin: true,
        title: null,
        tickvals: xValues,
        ticktext: xLabels,
        showticklabels: viewXAxisLabel,
        zeroline: false,
        tickfont: {
          color: fontColor,
          size: fontSize
        },
        range: [-0.5, xLabels.length - 0.5],
      },
      yaxis: {
        automargin: true,
        title: {
          text: unit,
          side: 'left',
          font: {
            size: fontSize,
            color: fontColor
          },
          standoff: 10,
        },
        showticklabels: true,
        zeroline: false,
        tickfont: {
          color: fontColor,
          size: fontSize
        },
        type: yLogScale && 'log'
      },
      shapes: [
        {
          type: 'rect',
          xref: 'paper',
          yref: 'paper',
          x0: 0,
          y0: 0,
          x1: 1,
          y1: 1,
          line: {
            color: 'white',
            width: 1,
          },
          fillcolor: 'rgba(0,0,0,0)'
        }
      ],
      legend: {
        x: 0, // 범례의 x 위치를 0 (왼쪽)으로 설정
        y: 0.9, // 범례의 y 위치를 조정
        orientation: 'h', // 범례의 방향을 수직('v')으로 설정
        xanchor: 'auto', // x 축 기준으로 왼쪽 정렬
        yanchor: 'middle', // y 축 기준으로 중간 정렬
        font: {
          color: fontColor,
          size: fontSize
        }
      },
      annotations: (usedShortNotInCandidates && !capture) && [
        {
          x: 0.5,
          y: 0.5,
          xref: 'paper',
          yref: 'paper',
          text: 'Unavailable target used.',
          showarrow: false,
          font: {
            size: 25,
            color: 'red'
          }
        }
      ]
    }

    if (compare !== OPERATION_COMPARE.CURRENT_VALUE) {
      const y1Data = data.find(trace => trace.yaxis === 'y1')
      const y1Values = y1Data.y
      const absY1Values = y1Values.map(value => Math.abs(value))
      const maxAbsY1Value = Math.max(...absY1Values)
      layout.yaxis = {
        ...layout.yaxis,
        range: yLogScale ? 
          [-Math.log10(maxAbsY1Value) * yAxisScaleStretch, Math.log10(maxAbsY1Value) * yAxisScaleStretch]:
          [-maxAbsY1Value * yAxisScaleStretch, maxAbsY1Value * yAxisScaleStretch],
      }

      const y2Data = data.find(trace => trace.yaxis === 'y2')
      const y2Values = y2Data.y
      const absY2Values = y2Values.map(value => Math.abs(value))
      const maxAbsY2Value = Math.max(...absY2Values)
      
      const yaxis2 = {
        title: {
          text: 'Change (%)',
          font: {
            size: fontSize,
            color: fontColor
          },
        },
        overlaying: 'y',
        side: 'right',
        tickformat: ',.0%',
        tickfont: {
          color: fontColor,
          size: fontSize
        },
        range: [-maxAbsY2Value * yAxisScaleStretch, maxAbsY2Value * yAxisScaleStretch],
      }
      layout.yaxis2 = yaxis2
    }
    else {
      const yValues = data?.map(d => d.y).flat(Infinity)
      const max = Math.max(...yValues)
      const min = yAxisStartAtZero ? 0 : Math.min(...yValues)
      const minOffset = 0.8

      const range = yLogScale ? 
        [Math.log10(min === 0 ? 1 : min * minOffset), Math.log10(max * yAxisScaleStretch)] :
        [min * minOffset, max * yAxisScaleStretch]

      layout.yaxis.range = range
    }
    
    return (
      <div style={{width: '100%'}}>
        <Space style={{display: 'flex', justifyContent: 'center'}}>
          <Plot
            data={data}
            layout={layout}
            style={{width, height: graphHeight}}
            config={{staticPlot: true,}}
          />
        </Space>
      </div>
    )
  }

  return (
    <div style={{marginRight: MARGIN_RIGHT}}>
      {groupRows.map(group => {
        const rows = group.rows
        const compare = group[COMPARE]
        return (
          <LineGraph 
            rows={rows} 
            compare={compare} 
            width={contentWidth - MARGIN_RIGHT}
          />
        )
      })}
    </div>
  )
}

const getMapCascaderOptions = (candidates, imageTypeList, record, readOnly) => {
  if (readOnly) {
    const targetSelected = record?.target?.selected
    const short = targetSelected?.includes(TASK_DELIMITER) ?
      targetSelected.split(TASK_DELIMITER)[0] : targetSelected
    const imagetype = imageTypeList.find(type => type.short === short)
    
    const option = {
      label: (
        <TagBase 
          color={imagetype?.tag_color}
          children={record?.target?.selected}
        />
      ),
      value: record?.target?.selected,
    }
    if (imagetype?.seg) {
      const children = imagetype.seg_cmap.map(data => {
        return ({
          label: <LabelView {...data} />,
          value: data.value
        })
      })
      option.children = children
    }
    return [option]
  }
  const getSegColorMapChildren = imagetype => {
    if (!imagetype?.seg) return undefined
    const segMapOptions = imagetype.seg_cmap.map((data, index) => {
      return ({
        label: <LabelView {...data} />,
        value: data.value
      })
    })
    const allTypeOption = {
      label: <TagBase {...ALL_SEG_CMAP} children={'All'}/>,
      value: ALL_SEG_CMAP.value
    }

    if (segMapOptions.length === 1) {
      return [...segMapOptions]
    }

    return [allTypeOption, ...segMapOptions]
  }
  
  const baseOptions = candidates?.map(candidate => {          
    const short = candidate.includes(TASK_DELIMITER) ?
      candidate.split(TASK_DELIMITER)[0] : candidate
    
    const imagetype = imageTypeList.find(imagetype => imagetype.short === short)

    const option = {
      key: candidate,
      label: (
        <TagBase 
          color={imagetype?.tag_color}
          children={candidate}
        />
      ),
      value: candidate,
      imagetype,
    }

    const children = getSegColorMapChildren(imagetype)?.map((data, index) => ({...data, key: `${candidate}-${data.value}`}))
    
    return imagetype.seg ? {...option, children} : option
  })

  const noneTypeOption = {
    label: <TagBase {...NONE_TYPE_MAP} children={NONE_TYPE_MAP.name}/>,
    value: NONE_TYPE_MAP.value
  }

  const options = record?.type === TABLE_ROW_TYPE.TABLE ? 
    [noneTypeOption, ...baseOptions] : 
    baseOptions
  
  return options
}

const getValueOptions = (imageTypeList, record) => {
  const type = record?.type
  const target = record?.target
  const targetSelected = target?.selected
  const targetSelectedId = target?.selected_id
  const targetSegValue = target?.seg_value

  const trackType = imageTypeList?.find(type => type.display === TRACK)
  const isTargetTrack = targetSelected && targetSelected?.includes(trackType?.short)
  const selectedImageType = imageTypeList?.find(type => type.id === targetSelectedId)
  
  const isSeg = selectedImageType?.seg
  const isSegValueAll = targetSegValue === ALL_SEG_CMAP.value
  const isTableType = type === TABLE_ROW_TYPE.TABLE

  const valueOptions = Object.values(VALUE_OPERATION).map(value => {
    const label = value === VALUE_OPERATION.VOLUME ? <>{VALUE_OPERATION.VOLUME} [<>mm<sup>3</sup></>]</> : value
    return {
      label,
      value
    }
  })

  const compareOptions = Object.values(OPERATION_COMPARE).map(value => {
    return {
      label: value,
      value
    }
  })
  
  const options = valueOptions.map(item => {
    let disabled = false
    let title = undefined
    switch (item.value) {
      case VALUE_OPERATION.VOLUME:
        return item
      case VALUE_OPERATION.COUNT:
        {
          disabled = !isSeg || (!isTargetTrack && (isSeg && isSegValueAll))
          title = 'Available for track or not all value segmentation only'
          return {
            ...item,
            label: disabled ?
              <PopupTooltip title={title}>
                {item.label}
              </PopupTooltip> :
              item.label
            ,
            disabled,
          }
        }
      case VALUE_OPERATION.HISTOGRAM:
      case VALUE_OPERATION.SPARKLINE:
        {
          if (!isTableType) {
            disabled = true
            title = 'Available for table only'
          }
          if (isSeg && !isSegValueAll) {
            disabled = true
            title = 'Available for all segmentation or image map only'
          }
          
          return {
            ...item,
            label: disabled ? 
              <PopupTooltip title={title}>
                {item.label}
              </PopupTooltip> :
              item.label
            ,
            disabled
          }
        }
      default:
        if (isSeg && !isSegValueAll) {
          disabled = true
          title = 'Available for all segmentation or image map only'
        }
        return {
          ...item,
          label: disabled ? 
            <PopupTooltip title={title}>
              {item.label}
            </PopupTooltip> :
            item.label
          ,
          disabled
        }
    }
  }).map(item => {
    const children = compareOptions
    
    if (item.disabled) {
      return item
    }

    switch (item.value) {
      case VALUE_OPERATION.HISTOGRAM:
      case VALUE_OPERATION.SPARKLINE:
        return {
          ...item,
          children: children.filter(child => child.value === OPERATION_COMPARE.CURRENT_VALUE)
        }
      default:
        return {
          ...item,
          children
        }
    }
  })
  
  return options
}

const getNewTargetValue = (mapCascaderValue, imageTypeList, record) => {
  const trackType = imageTypeList.find(type => type.display === TRACK)
  const selectedId = mapCascaderValue?.selected_id
  const targetSegValue = mapCascaderValue?.seg_value
  
  const imageType = imageTypeList.find(type => type.id === selectedId)
  
  const isTrack = trackType === imageType
  const isSeg = imageType?.seg
  const isSegValueAll = targetSegValue === ALL_SEG_CMAP.value
  
  const newValue = {
    target: undefined,
    value: undefined
  }
  if (!mapCascaderValue) {
    return newValue
  }
  if (!record.value) {
    return {
      ...newValue,
      target: mapCascaderValue
    }
  }
  if (record.value) {
    const operation = record.value[OPERATION]
    let inherit = true
    switch (operation) {
      case VALUE_OPERATION.COUNT:
        inherit = isTrack
        break
      case VALUE_OPERATION.VOLUME:
        break
      default:
        inherit = !isSeg || (isSeg && isSegValueAll)
    }
    return {
      ...newValue,
      target: mapCascaderValue,
      value: inherit ? record.value : undefined
    }
  }
  return newValue
}

const targetInfoSettings = ({row, candidates, imageTypeList, readOnly}) => {
  const typeOptions = tableTypeItems.filter(item => {
    return item.value !== PREVIEW
  }).map(item => {
    const label = (
      <div>
        {item.icon}
        <span style={{marginLeft: 4}}>
          {capitalizeFirstLetter(item.value)}
        </span>
      </div>
    )
    return {...item, label}
  })

  const targetSelectOptions = getMapCascaderOptions(candidates, imageTypeList, row, readOnly)

  const isRowTypeText = row.type === TABLE_ROW_TYPE.TEXT
  const isNotSelectedMap = !row.target
  const isNoneTarget = row?.target?.selected === NONE_TYPE_MAP.value
  const isSegType = row.target?.seg_value

  return [
    {
      name: ROW_INFO.NAME,
      type: INPUT_TYPE.STRING,
      placeholder: 'Enter data name'
    },
    {
      name: ROW_INFO.TYPE,
      type: INPUT_TYPE.SELECT,
      options: typeOptions,
    },
    {
      name: ROW_INFO.TARGET,
      type: INPUT_TYPE.MAP_CASCADER,
      options: targetSelectOptions,
      disabledTrigger: record => {
        if (isRowTypeText) {
          return {
            disabled: true,
            replaceValue: undefined,
            childrenTooltip: 'Unavailable for text type',
          }
        }
      },
    },
    {
      name: ROW_INFO.VALUE,
      type: INPUT_TYPE.REPORT_VALUE_CASCADER,
      options: getValueOptions(imageTypeList, row),
      disabledTrigger: record => {
        if (isRowTypeText) {
          return {
            disabled: true,
            replaceValue: undefined,
            childrenTooltip: 'Unavailable for text type',
          }
        }
        if (isNoneTarget || isNotSelectedMap) {
          return {
            disabled: true,
            replaceValue: undefined,
            childrenTooltip: 'Unavailable for None target',
          }
        }
      },
    },
    {
      name: ROW_INFO.NEGLECT_SMALL_VALUES,
      type: INPUT_TYPE.SWITCH,
      default: false,
      disabledTrigger: record => {
        if (isRowTypeText) {
          return {
            disabled: true,
            replaceValue: false,
            childrenTooltip: 'Unavailable for text type',
          }
        }
        if (isSegType) {
          return {
            disabled: true,
            replaceValue: false,
            childrenTooltip: 'Unavailable for segmentation',
          }
        }
        if (isNoneTarget || isNotSelectedMap) {
          return {
            disabled: true,
            replaceValue: false,
            childrenTooltip: 'Unavailable for None target',
          }
        }
      },
      extra: [
        {
          name: ROW_INFO.SMALL_VALUES_THRESHOLD,
          type: INPUT_TYPE.SLIDER,
          default: 0.5,
          formatter: value => `${value}%`,
          min: 0.01,
          max: 2,
          triggerValue: true,
          step: 0.01,
        }
      ]
    },
    {
      name: ROW_INFO.FILTER_CONDITION,
      type: INPUT_TYPE.EXPRESSION,
      vertical: true,
      disabledTrigger: record => {
        if (isRowTypeText) {
          return {
            disabled: true,
            replaceValue: undefined,
            childrenTooltip: 'Unavailable for text type',
          }
        }
        if (isNoneTarget || isNotSelectedMap) {
          return {
            disabled: true,
            replaceValue: undefined,
            childrenTooltip: 'Unavailable for None target',
          }
        }
      },
    }
  ]  
}