import React, {useRef} from "react";
import { render } from "react-dom";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import dicomParser from 'dicom-parser'
import {Input, Button, Row, Col, Tag, Switch} from "antd";
import ImageScrollbar from "./ImageScrollbar/ImageScrollbar";
import {IJKContext} from "../context/ijkContext";
import {
  computeAutoVoi,
  convertToVector3,
  extractVolumeMetaData,
  compareVolumeMetaData} from "../lib/cornerstoneUtils";
import {cloneDeep} from "lodash"
import {deepClone} from "craco-less/lib/utils";
import Plot from 'react-plotly.js'
import {dirColors} from "../lib/customTools/myReferenceLinesTool";

const {TextArea} = Input;
const scrollToIndex = cornerstoneTools.importInternal('util/scrollToIndex');
const segmentationModule = cornerstoneTools.getModule('segmentation');

function areStringArraysEqual(arr1, arr2) {
  if (arr1 === arr2) return true; // Identity
  if (!arr1 || !arr2) return false; // One is undef/null
  if (arr1.length !== arr2.length) return false; // Diff length

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

const divStyle = {
  // width: "512px",
  // height: "512px",
  // width: "400px",
  // width: "350px",
  // height: "256px",
  position: "relative",
  color: "white",
  // border: "1px solid #6c757d",
};

const topLeftStyle = {
  top: "5px",
  left: "10px",
  position: "absolute",
  color: "white"
}

const topRightStyle = {
  top: "5px",
  right: "22px", // margin (5) + imageScrollBar (17)
  position: "absolute",
  color: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}

const bottomLeftStyle = {
  bottom: "5px",
  left: "5px",
  position: "absolute",
  color: "white"
};

const bottomRightStyle = {
  bottom: "5px",
  right: "22px",
  position: "absolute",
  color: "white"
};

// "position:absolute;right:-5px;bottom:-5px;width:20px;height:20px;background:red;cursor:se-resize"
const knobStyle = {
  position:"absolute",
  right:"-20px",
  bottom:"-20px",
  width:"20px",
  height:"20px",
  // background:"red",
  cursor:"se-resize"
}

// JSON with all layers to be loaded
// The `name` option is used only by this example and
// cornerstone doesn't even know that it exists.
// You can add any option you want to `options` object.
const layers = [{
  imageId: 'ct://1',
  options: {
    name: 'CT'
  }
}, {
  imageId: 'pet://1',
  options: {
    name: 'PET',
    opacity: 0.7,
    viewport: {
      colormap: 'hotIron',
      voi: {
        windowWidth: 30,
        windowCenter: 16
      }
    }
  }
}];


class CornerstoneElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stack: props.stack,
      viewport: cornerstone.getDefaultViewport(null, undefined),
      imageId: props.stack.imageIds?.[0],
      strImageInfo: "",
      tag: props.tag,
      dirColor: dirColors(1.0)[props.direction]
    };

    // Select a renderer and apply the specified options
    this.renderer = new cornerstoneTools.stackRenderers.FusionRenderer();
    this.renderer.findImageFn = this.findImageFn

    // this.renderer.findImageFn = function(imageIds, targetImageId) {
    //   // const idx = parseInt(targetImageId.split('#')[1].split('-')[1]);
    //   // return imageIds[idx];
    //
    //   let minDistance = 1;
    //   const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', targetImageId);
    //   const imagePositionZ = targetImagePlane.imagePositionPatient[2];
    //
    //   let closest;
    //   imageIds.forEach(function(imageId) {
    //     const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
    //     const imgPosZ = imagePlane.imagePositionPatient[2];
    //     const distance = Math.abs(imgPosZ - imagePositionZ);
    //     if (distance < minDistance) {
    //       minDistance = distance;
    //       closest = imageId;
    //     }
    //   });
    //
    //   return closest;
    // };
    // pixel interpolation
    // const viewport = cornerstone.getViewport(element);
    // viewport.pixelReplication = !viewport.pixelReplication;
    // cornerstone.setViewport(element, viewport);

    // const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);

    this.onImageRendered = this.onImageRendered.bind(this);
    this.onNewImage = this.onNewImage.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    // this.handleImageRendered = this.handleImageRendered.bind(this);
    this.imageSliderOnInputCallback = this.imageSliderOnInputCallback.bind(this);
    this.handleLayerAdded = this.handleLayerAdded.bind(this);
    this.handleLayerRemoved = this.handleLayerRemoved.bind(this);
    this.handleActiveLayerChanged = this.handleActiveLayerChanged.bind(this);
    this.handleNewAction = this.handleNewAction.bind(this);
    this.handleSegmentation = this.handleSegmentation.bind(this);
    this.handleNewStackWithSegmentation = this.handleNewStackWithSegmentation.bind(this);
    this.buildSegmentsFromLabel = this.buildSegmentsFromLabel.bind(this);
    this.handleStackChanged = this.handleStackChanged.bind(this);
    this.doResize = this.doResize.bind(this);
    this.handleKnobMouseDown = this.handleKnobMouseDown.bind(this)
    this.handleAddLayer = this.handleAddLayer.bind(this)
  }

  findImageFn(imageIds, targetImageId) {
    // const idx = parseInt(targetImageId.split('#')[1].split('-')[1]);
    // return imageIds[idx];
    const dir = targetImageId.split('#')[1][0]
    const imageSliceIndex = dir === 'x' ? 0 : dir === 'y' ? 1 : 2

    let minDistance = 2;
    const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', targetImageId);
    const targetImgSlicePos = targetImagePlane.imagePositionPatient[imageSliceIndex];

    let closest;
    imageIds.forEach(function(imageId) {
      const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
      const imgSlicePos = imagePlane.imagePositionPatient[imageSliceIndex];
      const distance = Math.abs(imgSlicePos - targetImgSlicePos);
      if (distance < minDistance) {
        minDistance = distance;
        closest = imageId;
      }
    });

    return closest;
  }

  doResize(width, height) {
    if (width < 100)
      width = 100
    if (height < 100)
      height = 100

    this.element.style.width = width + 'px';
    this.element.style.height = height + 'px';
    cornerstone.resize(this.element);

    // element.style.width = width + 'px';
    // element.style.height = height + 'px';
    // cornerstone.resize(element);
  }

  handleToggle() {
    // divStyle.width = "512px"
    // divStyle.height = "512px"

    const colormap = cornerstone.colors.getColormap('myCustomColorMap');
    colormap.setNumberOfColors(4);
    colormap.insertColor(0, [0, 0, 0, 255])
    colormap.insertColor(1, [255, 0, 0, 255])
    colormap.insertColor(2, [0, 255, 0, 255])
    colormap.insertColor(3, [0, 0, 255, 255])

    // const testLUT = new cornerstone.colors.LookupTable();
    // testLUT.setNumberOfTableValues(3)
    // testLUT.insertColor(0, [255, 0, 0, 1])
    // testLUT.insertColor(1, [0, 255, 0, 1])
    // testLUT.insertColor(2, [0, 0, 255, 1])

    const layer = cornerstone.getLayer(this.element, this.renderer.layerIds[0])
    layer.viewport.labelmap = true
    layer.viewport.colormap = colormap
    // const stackToolState = cornerstoneTools.getToolState(this.element, 'stack');
    // stackToolState.data[0].options.viewport.labelmap = true
    // stackToolState.data[0].options.viewport.colormap = testLUT
    this.renderer.render(this.element)
  }

  test () {
    const segmentationModule = cornerstoneTools.getModule('segmentation');
    const visible1 = segmentationModule.getters.isSegmentVisible(this.element, 1);
    const visible2 = segmentationModule.getters.isSegmentVisible(this.element, 2);
    const visible3 = segmentationModule.getters.isSegmentVisible(this.element, 3);
    segmentationModule.setters.toggleSegmentVisibility(this.element, 1);
    segmentationModule.setters.toggleSegmentVisibility(this.element, 2);
    segmentationModule.setters.toggleSegmentVisibility(this.element, 3);
    const visible1re = segmentationModule.getters.isSegmentVisible(this.element, 1);
    const visible2re = segmentationModule.getters.isSegmentVisible(this.element, 2);
    const visible3re = segmentationModule.getters.isSegmentVisible(this.element, 3);
    cornerstone.updateImage(this.element);
  }

  imageSliderOnInputCallback(value) {
    // this.setViewportActive();
    scrollToIndex(this.element, value);
  };


  render() {
    // const scrollbarMax = this.props.imageIds.length - 1;
    const margin = 5
    const scrollbarMax = this.state.stack.imageIds?.length - 1 || 1;
    const scrollbarHeight = this.element ? `${this.element.clientHeight - 20}px` : '100px';
    const scrollbarWidth = 17;
    // const test = this.element && cornerstoneTools.getToolState(this.element, "stack")?.data.length > 1
    // transform: translate(0px, -256px)  rotate(90deg);
    const imageScrollbarStyle = {
      transformOrigin: "top left",
      transform: `translate(${this.element ? this.element.clientWidth - scrollbarWidth - margin : 256}px, 
        ${this.element ? -this.element.clientHeight : -256}px) rotate(90deg)`
    };
    return (
      <div
        style={this.props?.hidden ? {display: 'none'} : {}}
      >
        <div style={{display:'block', border: `1px solid ${this.state.dirColor}`}} >
          <div
            className={`viewportElement coord ${this.props.volumeIndex} - ${this.props.direction}`}
            style={{...divStyle, width: this.props.width, height: this.props.width}}
            ref={input => {
              this.element = input;
            }}
          >
            {/*<div*/}
            {/*  onMouseDown={this.handleKnobMouseDown}*/}
            {/*  style={knobStyle}>*/}
            {/*</div>*/}
            <canvas className="cornerstone-canvas" />
            {/*<div style={topRightStyle}>translation x:{this.state.viewport.translation.x.toFixed(2)}y:{this.state.viewport.translation.y.toFixed(2)}</div>*/}
            <div style={topLeftStyle}>
              {this.state.tag}
              {/*{`${this.state.activeLabelmapIndex}/[${this.props.segmentation.segmentations?.map(s => s.labelmapIndex).join(', ')}]`}*/}
            </div>
            <div style={topRightStyle}>
              {this.props.seg ?
                <Switch
                  style={{width: 'fit-content', marginTop: 5, marginBottom: 4}}
                  disabled={this.props.viewerState.processing}
                  checked={this.props.segOverlaid}
                  onChange={this.props.handleChangeSegOverlay}
                  checkedChildren="overlaid"
                  unCheckedChildren="overlay"
                />
                : null}
              <div style={this.props.seg ? {marginRight: 8} : {marginRight: 0}}>
                {this.props.overlay.overlays.length > 0
                  ? this.renderer.currentImageIdIndex + 1
                  : this.state.stack.currentImageIdIndex + 1 || 0}
                / {this.state.stack?.imageIds?.length}
              </div>
              {/*<div style={{position: 'absolute', right: 0}}>*/}
              {/*  {this.state.tag}*/}
              {/*</div>*/}
            </div>
            <div style={bottomLeftStyle}>Zoom: {this.state.viewport.scale.toFixed(2)}</div>
            <div style={bottomRightStyle}>
              WW/WC: {this.state.viewport.voi.windowWidth?.toFixed(2)} /{" "}
              {this.state.viewport.voi.windowCenter?.toFixed(2)}
            </div>
          </div>
          <ImageScrollbar
            onInputCallback={this.imageSliderOnInputCallback}
            max={scrollbarMax}
            height={scrollbarHeight}
            style={imageScrollbarStyle}
            // value={this.state.stack.currentImageIdIndex}
            value={this.props.overlay.overlays.length > 0
              ? this.renderer.currentImageIdIndex
              : this.state.stack.currentImageIdIndex || 0}
          />
        </div>
        {/*<div>*/}
        {/*  {this.state.stack?.currentImageIdIndex}/{this.state.stack?.imageIds?.length}*/}
        {/*</div>*/}
      </div>
    );
  }

  onWindowResize() {
    cornerstone.resize(this.element);
    // cornerstone.render(this.element);
    // this.renderer.render(this.element) // re-render
    // cornerstone.reset(this.element);
  }

  onImageRendered() {
    const viewport = cornerstone.getViewport(this.element);
    this.setState({
      viewport
    });
  }

  onNewImage() {
    const enabledElement = cornerstone.getEnabledElement(this.element);
    const split1 = enabledElement.image.imageId.split('?')
    const fname = split1[0]
    const split2 = split1[1].split('#')
    const dimInfo = split2[1]
    // console.log("CornerstoneElement", fname, dimInfo )

    const multiFrameMeta = cornerstone.metaData.get('multiFrame', enabledElement.image.imageId)
    const imagePixelInfo = cornerstone.metaData.get('imagePixel', enabledElement.image.imageId)
    if (multiFrameMeta && imagePixelInfo) {
      // const stackToolState = cornerstoneTools.getToolState(this.element, 'stack')
      this.setState({
        ...this.state,
        imageId: enabledElement.image.imageId,
        strImageInfo: `slice: ${dimInfo}/${multiFrameMeta.numberOfFrames}, filename:${fname} ` +
          JSON.stringify(multiFrameMeta) + JSON.stringify(imagePixelInfo)
      });
    }

    const stackData = cornerstoneTools.getToolState(this.element, "stack");
    this.renderer.currentImageIdIndex = stackData.data[0].currentImageIdIndex
    // this.renderer.render(this.element) //무한루프..

    let {ijk, setIJK} = this.context
    const newIJK = [...ijk]
    const dirIdx = ['x', 'y', 'z'].findIndex(dr => dr === this.props.direction)
    newIJK[dirIdx] = this.renderer.currentImageIdIndex
    setIJK(newIJK)
  }

  // handleImageRendered(event) {
  //   // console.log('cornerstoneImageRendred', event.detail.image.imageId);
  //   event.detail.element.removeEventListener(cornerstone.EVENTS.IMAGE_RENDERED, this.handleImageRendered);
  //   // this.props.addTools(event.detail.element);
  // }

  handleLayerAdded(event) {
    const layers = cornerstone.getLayers(event.detail.element)
    // console.log(this.element, layers, event.detail.layerId)
  }
  handleLayerRemoved(event) {
  }
  handleActiveLayerChanged(event) {
  }

  handleKnobMouseDown(e) {
    e.stopPropagation()
    const initWidth = this.element.clientWidth;
    const initHeight = this.element.clientHeight;
    const startX = e.clientX;
    const startY = e.clientY;
    const dragResize = (e) => {
      e.stopPropagation()
      const x = e.clientX - startX;
      const y = e.clientY - startY;
      const w = initWidth + x;
      const h = initHeight + y;
      this.doResize(w, h);
    }
    document.addEventListener('mousemove', dragResize);
    document.addEventListener('mouseup', function handleKnowMouseUp(e) {
      document.removeEventListener('mouseup', handleKnowMouseUp);
      document.removeEventListener('mousemove', dragResize);
    })
  }

  componentDidMount() {
    const element = this.element;

    // element.addEventListener(cornerstone.EVENTS.IMAGE_RENDERED, this.handleImageRendered)
    element.addEventListener(cornerstone.EVENTS.IMAGE_RENDERED, this.onImageRendered);
    element.addEventListener(cornerstone.EVENTS.LAYER_ADDED, this.handleLayerAdded)
    element.addEventListener(cornerstone.EVENTS.LAYER_REMOVED, this.handleLayerRemoved)
    element.addEventListener(cornerstone.EVENTS.ACTIVE_LAYER_CHANGED, this.handleActiveLayerChanged)
    element.addEventListener(cornerstone.EVENTS.NEW_IMAGE, this.onNewImage);
    // cornerstone.events.addEventListener('cornerstoneimageloadstart', function(event) {
    //   const eventData = event.detail;
    //   // console.log('cornerstone-image-load-started! - 2')
    // });
    // cornerstone.events.addEventListener('cornerstoneimageloadend', function(event) {
    //   const eventData = event.detail;
    //   // console.log('cornerstone-image-load-ended! - 2')
    // });
    // cornerstone.events.addEventListener('cornerstoneimageloadprogress', function(event) {
    //   const eventData = event.detail;
    //   // const loadProgress = document.getElementById('load-progress');
    //   // loadProgress.textContent = `Image Load Progress: ${eventData.percentComplete}%`;
    //   // console.log(`Image Load Progress: ${eventData.percentComplete}% - 2`);
    // });
    window.addEventListener("resize", this.onWindowResize);

    cornerstone.enable(element);
    cornerstoneTools.addStackStateManager(this.element);

    const stackData = cornerstoneTools.getToolState(this.element, "stack");
    if (stackData === undefined && this.state.stack?.imageIds?.length !== 0) {
      this.handleStackChanged().then(() => {
        if (this.props.overlay.overlays.length > 0) {
          this.handleNewAction()
        }
        if (this.props.segmentation.segmentations?.length > 0) {
          this.handleSegmentation()
        }
      })
    }
  }
  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    // cornerstone.events.removeEventListener('cornerstoneimageloadstart')
    // cornerstone.events.removeEventListener('cornerstoneimageloadend')
    // cornerstone.events.removeEventListener('cornerstoneimageloadprogress')
    const element = this.element;
    // element.removeEventListener(cornerstone.EVENTS.IMAGE_RENDERED, this.handleImageRendered);
    element.removeEventListener(cornerstone.EVENTS.IMAGE_RENDERED, this.onImageRendered);
    element.removeEventListener(cornerstone.EVENTS.LAYER_ADDED, this.handleLayerAdded)
    element.removeEventListener(cornerstone.EVENTS.LAYER_REMOVED, this.handleLayerRemoved)
    element.removeEventListener(cornerstone.EVENTS.ACTIVE_LAYER_CHANGED, this.handleActiveLayerChanged)
    element.removeEventListener(cornerstone.EVENTS.NEW_IMAGE, this.onNewImage);
    window.removeEventListener("resize", this.onWindowResize);

    // NOTE stackToolState 제거하기전에 수행
    if (this.props.segmentation.segmentations) {
      this.props.segmentation.segmentations.forEach((seg, segIndex) => {
        const stackState = cornerstoneTools.getToolState(element, 'stack');
        if (stackState?.data?.[0]) {
          segmentationModule.setters.labelmap3DForElement(element, [], segIndex);
        }
      })
    }
    cornerstoneTools.clearToolState(element, 'stack');
    cornerstoneTools.clearToolState(element, 'stackRenderer');
    cornerstone.disable(element);
  }

  componentDidUpdate(prevProps, prevState) {
    // const stackData = cornerstoneTools.getToolState(this.element, "stack");
    // const stack = stackData.data[0];

    if (this.props.noImage) {
      return
    }

    if (prevProps.hidden !== this.props.hidden) {
      this.onWindowResize()
    }

    if (prevProps.width !== this.props.width) {
      this.onWindowResize()
    }

    if (prevProps.tag.props.children !== this.props.tag.props.children) {
      this.setState({
        ...this.state,
        tag: this.props.tag,
      })
    }

    // reset
    if (this.props?.reset?.doReset && prevProps?.reset !== this.props?.reset) {
      const enabledElement = cornerstone.getEnabledElement(this.element)
      const defaultViewport = cornerstone.getDefaultViewport(enabledElement.canvas, enabledElement.image)
      const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(this.state.imageId)
      const viewport = {};
      computeAutoVoi(niftiMetaData, viewport)
      cornerstone.setViewport(this.element, {
        ...this.state.viewport,
        translation: defaultViewport.translation, // pan
        scale: defaultViewport.scale, // zoom
        voi: viewport.voi // WW/WC
      })
      // 초기 위치로 scroll
      scrollToIndex(this.element, parseInt(this.state.stack?.imageIds?.length / 2))
      // TODO overlay, seg 는 건드리지 말자
      // overlay - overlaied or not + colormap + opacity
      // seg - overlaied or not
      return
    }

    // locate
    if (this.props?.locate?.doLocate && prevProps?.locate !== this.props.locate) {
      // 이미지는 cornerstoneNIFTIImageLoader 에서 RAS 로 강제 변환되어 있고 좌표, locate.coordinate 는 원래 좌표계여서 변환필요
      const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(this.state.imageId)
      const orientationString = niftiMetaData.header.convertNiftiSFormToNEMA(niftiMetaData.header.affine)
      const xyzOrder = orientationString.slice(0, 3) // eg. 'XYZ'
      let directions = ['x', 'y', 'z']
      if (xyzOrder === 'XZY') {
        directions = ['x', 'z', 'y']
      }
      else if (xyzOrder === 'YZX') {
        directions = ['y', 'z', 'x']
      }
      let dirIdx = directions.findIndex(dr => dr === this.props.direction)
      const senses = orientationString.slice(3, 6) // eg, '-++'

      const orgImageIndex = this.props.locate.coordinates[dirIdx]
      const newImageIndex = senses[dirIdx] === '+' ? this.props.stack.imageIds.length - orgImageIndex - 1 : orgImageIndex
      const finalIndex = parseInt(newImageIndex)
      // console.log(this.element, orgImageIndex, newImageIndex, test)

      scrollToIndex(this.element, finalIndex)
    }

    // props.stack.imageIds에 변화가 있는지 확인
    let {imageIds: stack, currentImageIdIndex: imageIndex} = this.props.stack;
    const {imageIds: prevStack, currentImageIdIndex: prevImageIndex} = prevProps.stack;
    const hasStackChanged = !areStringArraysEqual(prevStack, stack);

    // image stack 변경시
    if (hasStackChanged || prevProps?.urls !== this.props.urls) {
      // if (this.element.offsetWidth > 0 && this.element.offsetHeight > 0) {
      //   console.log(this.element, hasStackChanged, prevProps?.urls !== this.props.urls)
      // }
      this.handleStackChanged().then(() => {
        if (this.props.overlay.overlays.length > 0) {
          this.handleNewAction()
        }
        this.handleNewStackWithSegmentation()
      })
    }

    // overlay action 관련처리
    const overlayChanged = this.props.overlay.action !== prevProps.overlay.action
    if (overlayChanged) {
      this.handleNewAction(prevProps)
    }

    // segmentation action 관련처리
    const segmentationChanged = this.props.segmentation.action !== prevProps.segmentation.action
    if (segmentationChanged) {
      this.handleSegmentation(prevProps)
    }
  }

  async handleStackChanged() {
    // update stack toolstate
    // TODO 필요하면 쓰고 아님 지우자
    // TODO 각종 Tool설정 및 currentImageIdIndex에 대해서 render 하면 끝
    let {
      imageIds: stackImageIds,
      currentImageIdIndex,
    } = this.props.stack;

    if (this.props.overlay.overlays.length > 0) {
      const stackData = cornerstoneTools.getToolState(this.element, 'stack')
      this.renderer.layerIds.forEach((layerId, layerIdx) => {
        cornerstone.removeLayer(this.element, layerId)
        cornerstoneTools.removeToolState(this.element, 'stack', layerIdx)
      })
      this.renderer.layerIds = []
    }

    cornerstoneTools.clearToolState(this.element, 'stack');
    cornerstoneTools.clearToolState(this.element, 'stackRenderer');
    // cornerstoneTools.stopClip(this.element);

    // cornerstoneTools.addStackStateManager(this.element, ['stack', 'Crosshairs']);
    // cornerstoneTools.addStackStateManager(this.element, ['stack']);
    // NOTE addStackStateManager를 여기서 하면 계속 manager를 새로 만들게 됨 ㅠ

    // cornerstoneTools.addStackStateManager(this.element);
    cornerstoneTools.addToolState(this.element, 'stack', this.props.stack);
    cornerstoneTools.addToolState(this.element, 'stackRenderer', this.renderer);
    const crossHairToolState = cornerstoneTools.getToolState(this.element, 'Crosshairs')

    try {
      const currentStackImageId = stackImageIds[currentImageIdIndex || 0];
      const loadedImage = await cornerstone.loadAndCacheImage(currentStackImageId);

      const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(loadedImage.imageId)
      const viewport = {};
      computeAutoVoi(niftiMetaData, viewport, loadedImage)
      this.props.stack.options = {...this.props.overlay.layerOption}
      this.props.stack.options.viewport = {
        ...this.props.stack.options.viewport,
        ...viewport
      }
      this.renderer.currentImageIdIndex = currentImageIdIndex;
      this.renderer.render(this.element, [this.props.stack])
      this.setState({
        ...this.state,
        stack: this.props.stack,
        volumeMetaData: extractVolumeMetaData(niftiMetaData)
      });
    } catch (e) {
      // console.log("error: ", e);
    }
  }

  /**
   * 레이블 추출하는 부분인데 느려서 타이머 이용해서 chunk 단위로 처리를 쪼갬
   * chunk 도 느려서 결국 WebWorker 이용
   * https://stackoverflow.com/questions/10344498/best-way-to-iterate-over-an-array-without-blocking-the-ui
   * @param label
   * @returns {Promise<void>}
   */
  buildSegmentsFromLabel(labelmapIndex=undefined) {
    const propsSeg = this.props.segmentation
    const targetLabelmapIndex = labelmapIndex || propsSeg.action.index
    const seg = propsSeg.segmentations.find(s => s.labelmapIndex === targetLabelmapIndex)
    if (seg) {
      const needActive = propsSeg.action.manual || (!propsSeg.action.manual && targetLabelmapIndex == this.props.viewerState.segMaxIdx)
      // console.log(`segMaxIdx ${this.props.viewerState.segMaxIdx}`, this.props.viewerState, this.props.direction, needActive, propsSeg, propsSeg.action)
      const label = seg[this.props.direction]
      const labelMetaData = seg.metaData
      if (compareVolumeMetaData(this.state.volumeMetaData, labelMetaData)) {
        const {configuration:config} = cornerstoneTools.getModule('segmentation');
        const UINT_16_ARRAY = 0
        const FLOAT_32_ARRAY = 1

        const stackState = cornerstoneTools.getToolState(this.element, 'stack');
        const numberOfFrames = stackState.data[0]?.imageIds?.length;
        if (numberOfFrames) {
          const slicelengthInBytes = label.byteLength / numberOfFrames;

          const element = this.element;
          const renderer = this.renderer
          const worker = new Worker(new URL("../workers/buildSegments2D.worker.js", import.meta.url));
          worker.postMessage([label, config.arrayType, slicelengthInBytes, numberOfFrames]);
          worker.onmessage = function (e) {
            const {segments} = e.data
            segmentationModule.setters.labelmap3DForElement(element, label, targetLabelmapIndex, [], segments, targetLabelmapIndex);
            if (needActive) {
              segmentationModule.setters.activeLabelmapIndex(element, targetLabelmapIndex)
              this.setState({
                ...this.state,
                activeLabelmapIndex: targetLabelmapIndex
              });
            }
            if (element) {
              renderer.render(element)
            }
            worker.terminate()
          }.bind(this)
          worker.onerror = function(e) {
            console.debug(e.error);
            worker.terminate()
          };
        }
      }
    }
  }

  handleNewStackWithSegmentation() {
    if (this.props.segmentation.segmentations?.length > 0) {
      const stackState = cornerstoneTools.getToolState(this.element, 'stack');
      const numberOfFrames = stackState.data[0]?.imageIds?.length;
      if (numberOfFrames) {
        const {labelmaps3D, activeLabelmapIndex, currentImageIdIndex} = segmentationModule.getters.labelmaps3D(this.element)
        this.props.segmentation.segmentations.forEach(seg => {
          const {labelmapIndex} = seg
          if (labelmaps3D == undefined || !(labelmapIndex in labelmaps3D)) {
            this.buildSegmentsFromLabel(labelmapIndex)
          }
        })
      }
    }
  }


  handleSegmentation(prevProps = undefined) {
    const propsSeg = this.props.segmentation
    if (propsSeg.action.type === 'changeOption') {
      this.renderer.render(this.element)
    }
    else {
      // change option 은 무조건 반영하고 추가 삭제만 따져서 반영
      if (this.props?.studyId && propsSeg.action.studyId !== this.props.studyId) {
        return // longitudinal 에서는 studyId 까지 비교해줘야 함
      }
      const stackState = cornerstoneTools.getToolState(this.element, 'stack');
      const numberOfFrames = stackState.data[0]?.imageIds?.length;
      if (numberOfFrames) {
        if (propsSeg.action.type === 'add') {
          if (propsSeg.segmentations) {
            this.buildSegmentsFromLabel()
          }
        }
        else {
          // segmentation.action.type === remove
          segmentationModule.setters.labelmap3DForElement(this.element, [], propsSeg.action.index);
          // 삭제 후 어떤 segmentation 을 활성화 할 지
          if (propsSeg.segmentations.length > 0) {
            let maxIndex = 0
            propsSeg.segmentations.forEach(s => {
              if (s.labelmapIndex > maxIndex) {
                maxIndex = s.labelmapIndex
              }
            })
            segmentationModule.setters.activeLabelmapIndex(this.element, maxIndex)
            this.setState({
              ...this.state,
              activeLabelmapIndex: maxIndex
            });
          }
          this.renderer.render(this.element)
        }
      }
    }
  }

  handleNewAction(prevProps = undefined) {
    if (this.props.overlay.action.type === 'changeOption') {
      const layers = cornerstone.getLayers(this.element)
      layers.forEach(layer => {
        if (layer.image) {
          if (layer.image.imageId.indexOf(this.props.overlay.action.url) >= 0) {
            layer.options.opacity = this.props.overlay.action.option.opacity;
            layer.viewport.colormap = this.props.overlay.action.option.viewport.colormap;
            layer.viewport.labelmap = this.props.overlay.action.option.viewport.labelmap;
            this.renderer.render(this.element)
          }
        }
      })
    }
    else {
      // overlays에 변경사항이 있을 때
      if (prevProps === undefined || this.props.overlay.overlays !== prevProps.overlay.overlays) {
        if (this.props.overlay.action.type === 'add') {
          this.handleAddLayer()
        }
        else if (this.props.overlay.action.type === 'remove') {
          // removeToolState(stackData), removeLayer, renderer.layerIds - splice
          const index2Del = this.props.overlay.action.index + 1
          const stackData = cornerstoneTools.getToolState(this.element, 'stack')
          const stackData2Del = stackData.data[index2Del]
          cornerstoneTools.removeToolState(this.element, 'stack', stackData2Del)
          const layerId2Del = this.renderer.layerIds[index2Del] // layer는 baseLayer 때문에 +1
          cornerstone.removeLayer(this.element, layerId2Del)
          this.renderer.layerIds.splice(index2Del, 1)
          this.renderer.render(this.element) // re-render
        }
        else {
          console.error('unhandled type of action')
        }
      }
    }
  }

  handleAddLayer() {
    const newOverlay = this.props.overlay.overlays[this.props.overlay.action.index]
    const newOverlayStack = {...newOverlay[this.props.direction]}
    cornerstoneTools.addToolState(this.element, 'stack', newOverlayStack)
    const viewOption = {...newOverlay.option}
    if (newOverlay.url === this.props.url) {
      viewOption.visible = false
    }
    const stackData = cornerstoneTools.getToolState(this.element, 'stack')
    const targetImageId = stackData.data[0].imageIds[this.renderer.currentImageIdIndex]
    const layerStartImageId = this.findImageFn(newOverlayStack.imageIds, targetImageId)
    if (layerStartImageId) {
      cornerstone.loadAndCacheImage(layerStartImageId).then(layerStartImage => {
        const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(layerStartImage.imageId)
        computeAutoVoi(niftiMetaData, viewOption.viewport)
        const layerId = cornerstone.addLayer(this.element, layerStartImage, viewOption)
        const layers = cornerstone.getLayers(this.element)
        const layer = cornerstone.getLayer(this.element, layerId)
        layers[0].syncProps = {
          originalScale: layers[0].image.columnPixelSpacing,
          originalColumnSpacing : layers[0].image.columnPixelSpacing,
          originalRowSpacing : layers[0].image.rowPixelSpacing,

        };
        layer.syncProps = {
          originalScale: layer.image.columnPixelSpacing,
          originalColumnSpacing : layer.image.columnPixelSpacing,
          originalRowSpacing : layer.image.rowPixelSpacing,
        };
        this.renderer.layerIds.push(layerId)
        this.renderer.render(this.element) // re-render
      })
    }
  }
}

CornerstoneElement.contextType = IJKContext;

export default CornerstoneElement;
