import React, {useEffect, useState} from "react";
import {Button, Col, InputNumber, Popconfirm, Row, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {LabelSelect} from "./labelControls";
import {useSelector} from "react-redux";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import {TASK_DELIMITER} from "../lib/taskUtil";

const {Option} = Select;

const ExpressionOfROI = ({name, value, input, setFieldsValue, disabled}) => {
  const {getTypeFromShort} = useGetImageTypeHooks()
  const [expressions, setExpressions] = useState(value);

  useEffect(() => {
    setExpressions(value);
  }, [value])

  useEffect(() => {
    if (!input.selected && expressions.length > 0) {
      setExpressions([]);
    }
  }, [input.selected])

  const onClickAdd = () => {
    const newExpress = !expressions || expressions.length === 0
      ? [{value: 0}] : [...expressions, {value: 0}]

    setExpressions(newExpress);
    setFieldsValue({[name]: newExpress})
  }
  const onClickRemove = (exprIndex) => (e) => {
    const newExpress = [...expressions];
    newExpress.splice(exprIndex, 1);

    setExpressions(newExpress);
    setFieldsValue({[name]: newExpress})
  }
  const onValueChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].value = value

    setExpressions(newExpress);
    setFieldsValue({[name]: newExpress})
  }

  // console.count('expressionOfROI')
  return (
    <>
      {!expressions || expressions.length === 0 ?
        <Row gutter={[8, 4]}>
          <Col span={2}>
            <Button
              block
              type="primary"
              shape="round"
              onClick={onClickAdd}
              icon={<PlusOutlined/>}
            />
          </Col>
        </Row>
        :
        // strExpression.slice(1).map((item, index) => {
        expressions.map((item, expr_index) => {
          const target = input.selected;
          const short = target?.includes(TASK_DELIMITER) ? target.split(TASK_DELIMITER)[0] : target
          const imgtype = getTypeFromShort(short)
          const value = item.value
          const firstColumn = expr_index === 0 ?
            <Button
              block
              type="primary"
              shape="round"
              onClick={onClickAdd}
              icon={<PlusOutlined/>}
            />
            : null

          return <Row key={expr_index} gutter={[8, 4]} style={{marginBottom: 5}} align="middle">
            <Col span={2}>
              {firstColumn}
            </Col>
            <Col span={9}>
              <LabelSelect
                options={imgtype?.seg_cmap ?? []}
                defaultValue={value}
                value={value}
                onChange={onValueChange(expr_index)}
              />
            </Col>
            <Col span={13}>
              <Popconfirm
                title={"Are you sure?"}
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={onClickRemove(expr_index)}
              >
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  hidden={disabled}
                />
              </Popconfirm>
            </Col>
          </Row>
        })
      }
    </>
  );
}

export default ExpressionOfROI;