export const REQUEST = 'scatter/REQUEST';
export const COLLECT_DATA = 'scatter/COLLECT_DATA';
export const COLLECT_SEG = 'scatter/COLLECT_SEG';
export const GENERATE = 'scatter/GENERATE';
export const CLEAR = 'scatter/CLEAR';

const initialState = {
  request: false,
  data1: null,
  data2: null,
  segmentation: null,
  plotData: null
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return {
        ...state,
        request: true,
      };
    case COLLECT_DATA:
      if (state.data1 === null) {
        return {
          ...state,
          data1: action.payload
        };
      }
      else {
        return {
          ...state,
          data2: action.payload
        };
      }
    case COLLECT_SEG:
      return {
        ...state,
        segmentation: action.payload
      };
    case GENERATE:
      return {
        ...state,
        request: false,
        data1: null,
        data2: null,
        segmentation: null,
        plotData: action.payload
      };
    case CLEAR:
      return {
        ...state,
        plotData: null
      }
    default:
      return state;
  }
}

export function request(payload) {
  return {type: REQUEST, payload};
}

export function collectData(payload) {
  return {type: COLLECT_DATA, payload};
}

export function collectSeg(payload) {
  return {type: COLLECT_SEG, payload};
}

export function generate(payload) {
  return {type: GENERATE, payload};
}

export function clear(payload) {
  return {type: CLEAR, payload};
}
