import {Avatar, Badge} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import React from "react";

function MyAvatar() {
  return (
      <Badge dot className="avatar">
        <Avatar shape="square" icon={<UserOutlined/>}/>
      </Badge>
  );
}

export default MyAvatar;
