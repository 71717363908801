import { FontSizeFullText } from "./styledComponent"

function ModelName({name}) {
  return (
    name ?
      <FontSizeFullText
        code
        style={{fontSize: 12, color : 'rgb(27, 180, 178)'}}
      >
        {`${name[0]} - ${name[1]}`}
      </FontSizeFullText>
      :
      <></>
  )
}

export default ModelName