import React, {useEffect, useState, forwardRef, useImperativeHandle} from "react";
import {Button, Col, InputNumber, Popconfirm, Row, Select} from "antd";
// import TaskInputSelect, {MODE} from "./taskInputSelect";
import {MinusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import TaskImageIOSelect, {MODE} from "./taskImageIOSelect";
import {TASK_DELIMITER} from "../lib/taskUtil";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import {LabelSelect} from "./labelControls";

const {Option} = Select;

const ImageFilterExpression = forwardRef((props, ref) => {
  const {getTypeFromShort} = useGetImageTypeHooks(props.imageTypeList)
  const [expressions, setExpressions] = useState(props.value);
  // useEffect(() => {
  //   setExpressions(props.value);
  // }, [props.value])

  useEffect(() => {
    // TODO 삭제된 map이 사용된 표현들 제거
    if (props.inputs?.[0].selected && expressions) {
      const newExpr = expressions.filter((item, index) => {
        return !item.hasOwnProperty('target') || item.target === undefined || props.inputs[0].candidates.includes(item.target)
      })
      if (newExpr.length > 0 && newExpr[0].hasOwnProperty('lop')) {
        delete newExpr[0].lop
      }
      setExpressions(newExpr);
    }
  }, [props.inputs?.[0].selected])

  const onClickAdd = () => {
    const newExpress = !expressions || expressions.length === 0 ? [{
      op: "=",
      value: 0
    }] : [...expressions, {
      lop: "or",
      op: "=",
      value: 0
    }];

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }
  const onClickRemove = (exprIndex) => (e) => {
    const newExpress = [...expressions];
    newExpress.splice(exprIndex, 1);

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }
  const onLogicalOperatorChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].lop = value

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }
  const onInputChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    if (value.length === 0) {
      newExpress[exprIndex].target = undefined
    }
    else {
      newExpress[exprIndex].target = value[0]
    }

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }
  const onOperatorChange = (exprIndex) => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].op = value

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }
  const onValueChange = exprIndex => (value, index) => {
    const newExpress = [...expressions];
    newExpress[exprIndex].value = value

    setExpressions(newExpress);
    // props.setFieldsValue({[props.name]: newExpress})
  }

  useImperativeHandle(ref, () => ({
    getExpression: () => expressions,
    setExpression: expressions => setExpressions(expressions)
  }))

  return (
    <>
      {!expressions || expressions.length === 0 ?
        <Row gutter={[8, 4]}>
          <Col span={4}>
            <Button block type="primary" onClick={onClickAdd}> + </Button>
          </Col>
        </Row>
        :
        // strExpression.slice(1).map((item, index) => {
        expressions.map((item, expr_index) => {
          const lop = item.lop === undefined ? "and" : item.lop;
          const target = item.target;
          const short = target?.includes(TASK_DELIMITER) ? target.split(TASK_DELIMITER)[0] : target
          const imgtype = getTypeFromShort(short)
          const seg = imgtype?.seg
          const op = item.op === undefined ? "=" : item.op;
          const value = item.value === undefined ? 0 : item.value;
          const firstColumn = expr_index === 0 ?
            <Button block type="primary" onClick={onClickAdd} disabled={props.disabled}> + </Button>
            :
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              onChange={onLogicalOperatorChange(expr_index)}
              defaultValue={lop}
              value={lop}
              disabled={props.disabled}
            >
              <Option key={1} value="and">{"and"}</Option>
              <Option key={2} value="or">{"or"}</Option>
            </Select>;

          return <Row key={expr_index} gutter={[8, 4]} style={{marginBottom: 5}} align="middle">
            <Col span={4}>
              {firstColumn}
            </Col>
            <Col span={6}>
              <TaskImageIOSelect
                options={[...props.imageTypeSelected.filter(t => t.selected)].map(t => {
                  return {
                    ...t,
                    selected: false,
                    disabled: false,
                  }
                })}
                onChange={onInputChange(expr_index)}
                dropdownMatchSelectWidth={false}
                value={target}
                width={'100%'}
              />
            </Col>
            <Col span={3}>
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                onChange={onOperatorChange(expr_index)}
                defaultValue={op}
                value={op}
                disabled={props.disabled}
              >
                <Option key={1} value=">">{">"}</Option>
                <Option key={2} value=">=">{">="}</Option>
                <Option key={3} value="=">{"="}</Option>
                <Option key={4} value="<=">{"<="}</Option>
                <Option key={5} value="<">{"<"}</Option>
              </Select>
            </Col>
            <Col span={10}>
              {seg ?
                <LabelSelect
                  options={imgtype.seg_cmap}
                  defaultValue={value}
                  value={value}
                  onChange={onValueChange(expr_index)}
                />
                :
                <InputNumber
                  style={{width: '100%'}}
                  disabled={props.disabled}
                  value={value}
                  onChange={onValueChange(expr_index)}/>
              }
            </Col>
            <Col span={1}>
              <Popconfirm
                title={"Are you sure?"}
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={onClickRemove(expr_index)}
              >
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  hidden={props.disabled}
                />
              </Popconfirm>
            </Col>
          </Row>
        })
      }
    </>
  );
})

export default ImageFilterExpression;