import React  from 'react';
import {useHistory} from 'react-router-dom'
import Avatar from './avatar'
import { Button, Menu, Dropdown, Space, Modal } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import { logout } from '../redux/modules/auth'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { clear as clearMessage } from '../redux/modules/message';
import { cancelUpload } from '../redux/modules/longTask';

function Profile(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const collapsed = !useSelector(state => state.config.sidebar_open);
  const userId = useSelector(state=>state.auth?.user?.id)

  const uploadTaskLength = useSelector(state=>state.longTask.tasks.filter(task => task.type === 'upload').length);
  const uploadFinishLength = useSelector(state =>state.longTask.tasks.filter(task => task.ended && task.type === 'upload').length);

  const onLogout = () => dispatch(logout());

  const logoutConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "If you logout now, uploading data currently in progress will be canceled.",
      onOk: close => {
        dispatch(cancelUpload())
        close()
      }
    })
  }
  
  function toLoginPage() {
    history.push('/login')
  }

  function menuOnclick({ item, key, keyPath, domEvent }) {
    if (key === 'settings') {
      history.push('/setting',{key : userId})
    }
    else if (key === "logout") {
      if (uploadFinishLength === uploadTaskLength) {
        onLogout()
        dispatch(clearMessage())
        return 
      }
      return logoutConfirm()
    }
  }

  const menu = (
    <Menu onClick={menuOnclick}>
      <Menu.Item key="settings">
        <span>Settings</span>
      </Menu.Item>
      <Menu.Item key="logout">
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  if (props.loggedIn) {
    return (
      <div style={props.style}>
        <Dropdown overlay={menu} trigger={['click']} placement="topLeft" arrow={{pointAtCenter: true,}}>
          {collapsed
            ?
            <Space>
              <Avatar/>
            </Space>
            :
            <Space>
              <Avatar />
              {props.name}
            </Space>
          }
        </Dropdown>
      </div>
    );
  }
  else {
    return (
      <div style={props.style}>
        <Button onClick={toLoginPage}> Login </Button>
      </div>
    );
  }
}

export default Profile;
