import React, { useEffect, useState, useMemo, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "./noticeEditor.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import ImageResize from "quill-image-resize";
import {clear, getDetail, pubEdit} from "../../redux/modules/notice";
import {useTitle} from '../../hooks/title'
import { Button, Col, Input, Row, Space, Form, Typography } from "antd";
import purify from "dompurify";

Quill.register("modules/ImageResize", ImageResize);

function NoticeEditor() {
  useTitle('Notice')
  const location = useLocation();
  const dispatch = useDispatch()
  const history = useHistory();
  const QuillRef = useRef();
  const contentRef = useRef();
  const [form] = Form.useForm();

  const user = useSelector((state) => state.auth.user);
  const article = useSelector((state)=>state.notice?.article)
  
  const key = location.state?.key;

  const [content, setContent] = useState("");

  useEffect(()=>{
    if (key !== undefined && article === undefined) {
      dispatch(getDetail({key}))
    }
    return () => dispatch(clear())
  },[])
  
  
  useEffect(()=>{
    if (location.state !== undefined && article !== undefined) {
      setContent(article.content)
      form.setFields([{ name : ['Title'], value : article.title}])
      contentRef.current = article.content
    }
  },[article])
  
  const onChange = value =>  {
    contentRef.current = value
  }

  const handleList = () => history.goBack()

  function handleSubmit() {
    const payload = {
      title : form.getFieldValue('Title'),
      content : purify.sanitize(contentRef.current),
      id : user.id,
      key : key,
      image : key === undefined ? [] : article.image,
    }
    dispatch(pubEdit(payload))
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ font: [] }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ script: "sub" }, { script: "super" }],
          [{ align: [] }, { color: [] }, { background: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    }),[]
  );
    

  return (
    <div
      style={{
        maxWidth: '70%',
        minWidth: "70%",
        margin: "20px auto",
      }}
    >
      <Form
        form={form}
        labelCol={{span : 2}}
        onFinish={handleSubmit}
      >
        <Row gutter={[8, 4]} align="middle">
          <Col span={6}>
            <h1>Notice</h1>
          </Col>
          <Col span={12}></Col>
          <Col span={6} flex='1'>
            <div>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "right" }}
              >
                <Button
                  onClick={handleList}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  {key === undefined ? "Publish" : "OK"}
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
        <br />
        <Form.Item 
          name='Title' 
          label='Title'
          rules={[
            {
              required: true,
              whitespace : true,
              type : 'string',
              message: 'Please input title!',
            },
            {
              max: 256,
              message : 'Cannot exceed 256 letters'
            },
          ]}  
        >
          <Input
            size={'large'} 
            maxLength={256} 
            showCount={{
              formatter: ({count, maxLength})=> 
                <span style={{color:'rgba(255,255,255,0.3)'}}>
                  {`${count}/${maxLength}`}
                </span>
            }}
          />
        </Form.Item>
        <Form.Item name='Content' label='Content'>
          <Typography>
            <ReactQuill
              className={'app'}
              ref={QuillRef}
              theme="snow"
              value={purify.sanitize(content)}
              onChange={onChange}
              placeholder={"Please enter your contents."}
              modules={modules}
            />
          </Typography>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NoticeEditor;
