import React, {useState, useEffect, useRef} from "react";
import { Popconfirm, Button, Table, Space, Tooltip} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {load, del, clear} from "../redux/modules/dataset"
import { load as loadPipeline, clear as clearPipeline, } from "../redux/modules/pipeline"
import SearchBox from "./searchBox";
import {useHistory, useLocation} from "react-router-dom";
import useListPageContext from "../context/listPageContext";
import {useTitle} from "../hooks/title";
import {QuestionCircleOutlined} from '@ant-design/icons';
import {AnalysisRunModal} from "./analysisRunModal";
import { load as loadImagetype  } from "../redux/modules/blobTypes"
import { created_column } from "./createdRender";

const searchOptions = [
  {value:"name", label:"Name", dataIndex : 'name'},
  {value:"desc", label:"Description", dataIndex : 'desc'},
]

export const dataset_base_columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Description',
    dataIndex: 'desc',
    key: 'desc',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Case count',
    dataIndex: 'caseCount',
    key: 'caseCount',
    align: 'center',
    width: 150
  },
];

function DatasetList() {
  useTitle('DYNAPEX | Datasets')
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation()
  const loading = useSelector(state => state.dataset.loading)
  const list = useSelector(state => state.dataset.list)
  const total = useSelector(state => state.dataset?.total)
  const dataset_id = useRef(0)
  const pipeline_id = useRef(0)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    dispatch(loadPipeline({}));
    dispatch(loadImagetype({}));
    return () => dispatch(clearPipeline())
  }, [])

  const {
    rowSelection,
    handleSearch,
    fetchDelete,
    searchHighlight,
    Footer
  } = useListPageContext({load, del, clear, searchOptions, total});

  const handleCreate = () => history.push('/dataset/detail')
  const handleEdit = record => history.push('/dataset/detail', {key: record.dbid})
  const handleDelete = () => fetchDelete()

  const handleAnalyze = record => {
    dataset_id.current = record.dbid
    setSelectRowIndex(record.dbid)
    setShowModal(true);
  };

  const action_column = (
    {
      title: 'Actions',
      key: 'action',
      width: 160,
      align : 'center',
      render: record => (
        <Space>
          <Tooltip title="Zero case dataset" open={record.caseCount ? false : undefined}>
            <Button type='primary' onClick={() => handleAnalyze(record)} disabled={!record.caseCount}> Run </Button>
          </Tooltip>
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        </Space>
      ),
    }
  )

  useEffect(()=>{
    const dataset_columns = dataset_base_columns.map(column => ({
      ...column,
      render : column.render 
                ? column.render
                : text => searchOptions.some(option => option.dataIndex === column.dataIndex) 
                  ? searchHighlight(text, column.dataIndex)
                  : text
    }))
    const added_columns = [created_column, action_column]
    dataset_columns.push(...added_columns)
    
    setColumns(dataset_columns)
  },[list])

  const [showModal, setShowModal] = useState(false);
  const [selectRowIndex, setSelectRowIndex] = useState(null)

  useEffect(()=>{
    if (!showModal) {
      setSelectRowIndex(null)
      dataset_id.current = 0
    }
  },[showModal])

  return (
      <div
        style={{
          width: '100%',
        }}
      >
        <h1>Dataset</h1>
        <div style={{display: "inline-block"}}>
          <Space>
            <Button onClick={handleCreate}> Create </Button>
            <Popconfirm
              title="Are you sure?"
              icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
              onConfirm={() => handleDelete()}>
              <Button style={{margin: '1px'}} >Remove</Button>
            </Popconfirm>
          </Space>
        </div>
        <SearchBox
          style={{display: "inline-block", float: 'right', width:"50%"}}
          options={searchOptions}
          onSearch={handleSearch}
        />

        <Table
          footer={Footer}
          pagination={false}
          rowClassName={(record, index)=> record.dbid === selectRowIndex ? 'ant-table-row-selected' : null}
          loading={loading}
          columns={columns}
          dataSource={list}
          rowKey={'dbid'}
          rowSelection={rowSelection}
        />
        <AnalysisRunModal 
          visible={showModal}
          setVisible={setShowModal}
          pathname={location.pathname}
          dataset_id={dataset_id}
          pipeline_id={pipeline_id}
        />
      </div>
  );
}

export default DatasetList;

