import React, {useState, useRef, useEffect} from "react";
import * as cornerstoneTools from "cornerstone-tools";
import chroma from "chroma-js";
const segmentationModule = cornerstoneTools.getModule('segmentation');

export const SegmentationContext = React.createContext({})
SegmentationContext.displayName = 'SegmentationOverlay';

export const SegmentationContextProvider = ({ children }) => {
  // const [segmentations, setSegmentations] = useState([]);
  const segmentations = useRef([]);
  const [action, setAction] = useState({});
  const toggleSegmentation = (manual, volumeIndex, url, option, stackX, stackY, stackZ, metaData=undefined) => {
    const segmentation = segmentations.current.find(item => item.url === url)
    if (segmentation) {
      // setSegmentations(segmentations.filter(item => item.url !== url))
      segmentations.current = segmentations.current.filter(item => item.url !== url)
      setAction({type: 'remove', index: segmentation.labelmapIndex, manual})
      return false
    }
    else {
      let newLabelmapIndex = volumeIndex

      // new colormap
      const labelmap3DOrColorLUTIndex = newLabelmapIndex // NOTE ColorLUT 도 동일한 index 를 줌
      segmentationModule.setters.colorLUT(labelmap3DOrColorLUTIndex) // colorLUT 생성
      option.colorLUT.forEach(item => {
        const segmentIndex = item.value
        const colorArray = chroma(item.color).rgb().concat(255)
        segmentationModule.setters.colorForSegmentIndexOfColorLUT(labelmap3DOrColorLUTIndex, segmentIndex, colorArray)
      })

      segmentations.current = [
        ...segmentations.current,
        {
          labelmapIndex: newLabelmapIndex,
          url,
          option,
          x: stackX,
          y: stackY,
          z: stackZ,
          metaData
        }
      ]
      setAction({type: 'add', index: newLabelmapIndex, manual})
      return true
    }
  }

  const cleanup = url => {
    if (segmentations.current.length > 0) {
      const seg = segmentations.current.find(seg => seg.url === url)
      if (seg) {
        segmentations.current = segmentations.current.filter(item => item.url !== url)
        setAction({type: 'remove', index: seg.labelmapIndex, manual: false})
        seg.x = null
        seg.y = null
        seg.z = null
      }
    }
    if (segmentations.current.every(s => s.x === null && s.y === null && s.z === null)) {
      segmentations.current = []
      const segModuleState = segmentationModule.state
      segModuleState.series = {}
      segModuleState.colorLutTables = []
    }
  }

  return (
    <SegmentationContext.Provider
      value={{ segmentations: segmentations.current, toggleSegmentation, action, setAction, cleanup }}
    >
      {children}
    </SegmentationContext.Provider>
  );
};
export const SegmentationContextConsumer = SegmentationContext.Consumer;

export default SegmentationContext;