import React from 'react';
import { Typography } from 'antd';

const { Title, Text, Paragraph, Link } = Typography

export const Privacy = () => {
  const titleLevel = 3
  const subTitleLevel = 4

  const titleMarginBottom = 50

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '60%'}}>
        <section id="en">
          <Title level={titleLevel} style={{textAlign: 'center', marginBottom: titleMarginBottom}}>Privacy Policy</Title>
          <Paragraph>
            Dynapex LLC. (the “Company”, “Dynapex”, “we”, “us”, “our”) has this Privacy Policy (hereinafter referred to as the "Policy”) in place to protect user privacy, rights, and interests; and handle user complaints relevant to personal information by the Personal Information Protection Act.
          </Paragraph>
          <Paragraph>
            ※ This Policy, as partially modified and updated, goes into effect on March 2, 2023.
          </Paragraph>
          <Title level={subTitleLevel}>1.  Purpose of Processing Personal Information</Title>
          <Paragraph>
            We use the information, including personal information, alone or in combination with other information that we may collect about you, for the following purposes :
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                To process your order, manage your account.
              </li>
              <li>
                We collect the following data: email address, full name, company, occupation, subscription/Maintenance status, and any other information that you choose to provide. To communicate with you (via email), such as to respond to your requests/inquiries.
              </li>
              <li>
                We collect the following data: email address, full name, and your subscription/Maintenance status. To better understand how users access and use our Site, we collect data through Google Analytics which is only maintained and processed in aggregate to preserve individual visitors’ privacy. Please refer to Chapter 5 of “Google Analytics” for further information.
              </li>
              <li>
                Where permitted, we would use your data for marketing and promotional purposes. For example, by applicable law and with your consent, we will use your email address to send you news and newsletters and to contact you about products or information (offered by us) we think may interest you. You have the right to withdraw your consent at any time as noted below.
              </li>
              <li>
                We collect the following data: email address, full name, research of interest, company, occupation, and location. We may share your information in any case of dispute, or legal proceeding of any kind between you and us concerning the Site or your use thereof.
              </li>
            </ul>
          </Paragraph>
          <Title level={subTitleLevel}>2.  How to Collect Personal Information</Title>
          <Paragraph>
            We collect information that you enter on our Site or provide to us in any other way. We collect and process your data when you :
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                Register online or place an order for any of our products or services.
              </li>
              <li>
                Request information or otherwise contact us.
              </li>
              <li>
                Use or view our website via your browser’s cookies.
              </li>
              <li>
                Request for free trials of our products.
              </li>
            </ul>
          </Paragraph>
          <Title level={subTitleLevel}>3. Provision of Personal Information to Third Parties</Title>
          <Paragraph>
            We may share the information that we collect about you, with third parties worldwide, as follows :
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                Service Providers/Agents. We may disclose your information to third parties, including service providers, independent contractors, and affiliates that perform functions on our behalf. Examples include : analyzing data, analytics companies who collect browsing information and profiling information, recruitment, and our own marketing and promotional purposes. We will only provide these entities with the information necessary for them to perform these services and functions on our behalf. These entities are contractually required to protect your personal information from unauthorized access, use, or disclosure.
              </li>
              <li>
                We do not share your personal information with third parties for their own marketing purposes. We do not sell your information to third parties in any circumstances.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            We also may share your information in the following situations :
            <br />
            Business Transfers. If we are acquired by or merged with another company, if substantially all our assets are transferred to another company, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other company. You will have the opportunity to opt out of any such transfer if, at our discretion, it will result in the handling of your information in a way that differs materially from this Policy.
          </Paragraph>
          <Title level={subTitleLevel}>4. Cookies, Third Party Analytics We Use</Title>
          <Paragraph>
            Cookies : This website uses cookies. A cookie is a small text document with information a website stores on a visitor’s computer which the visitor’s browser provides to the website each time the visitor returns. This information may be used to determine the location of visitors and the way they move on this website, allowing us to improve the quality of the website and enabling us to provide information based on the whereabouts of the visitors as well as create reports about the general use of the website. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
          </Paragraph>
          <Paragraph>
            Google Analytics: This website uses Google Analytics for these purposes. Google stores this information on its servers. Dynapex has a data processing agreement with Google which is incorporated in their terms and conditions and privacy policy. Please read their privacy policy for more information. Most used browsers give you the option of rejecting cookies. Blocking cookies may have a negative impact on the usability of websites. Please look into the privacy settings of your browser to adjust them in case you desire to. Below information is gathered by Google Analytics and is stored for 60 days. Data will be automatically deleted after this period. To Dynapex, this information is anonymous.
          </Paragraph>
          <Title level={subTitleLevel}>5. Your Data Protection Rights</Title>
          <Paragraph>
            <ul>
              <li>
                The right to access - You have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.
              </li>
              <li>
                The right to rectification - You have the right to request that Our Company correct any information you believe is inaccurate. You also have the right to request Our Company to complete the information you believe is incomplete.
              </li>
              <li>
                The right to erasure - You have the right to request that Our Company erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing - You have the right to request that Our Company restrict the processing of your personal data, under certain conditions.
              </li>
              <li>
                The right to object to processing - You have the right to object to Our Company’s processing of your personal data, under certain conditions.
              </li>
              <li>
                The right to data portability - You have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.
              </li>
            </ul>
          </Paragraph>
          <Title level={subTitleLevel}>6. Your Choices; Use of Your Personal Information</Title>
          <Paragraph>
            You may choose to opt out of our mailing list and our blog updates. To opt out, please contact : contact@dynapex.co or choose to unsubscribe by clicking the ‘Unsubscribe’ link from the email we may send to you.
          </Paragraph>
          <Title level={subTitleLevel}>7. Contact Us</Title>
          <Paragraph>
            If you have any questions about Our Company’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                Email: <Link href="mailto:contact@dynapex.co">contact@dynapex.co</Link>
              </li>
              <li>
                Call us : +82 (0)2-861-9158
              </li>
              <li>
                Or fill out an inquiry form at <Link href="https://dynapex.co/">https://dynapex.co/</Link>
              </li>
              <li>
                Or write to us at A-2406, 606, Seobusaet-Gil, Geumcheon-gu, Seoul, Republic of Korea
              </li>
            </ul>
          </Paragraph>
          <Title level={subTitleLevel}>8. Third-Party Links</Title>
          <Paragraph>
            Our Site may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy but instead is governed by the privacy policies of those third-party websites. We do not exercise control over such third-party websites and are not responsible for the information practices of such third-party websites. You access such third-party websites or content at your own risk. You should always read the privacy policy of a third-party website before providing any information to the website.
          </Paragraph>
          <Title level={subTitleLevel}>9. How We Store Your Data</Title>
          <Paragraph>
            We securely store your data at on-premise server within the Company building.
            <br/>
            Our Company will keep your data until the time necessary for the purpos	e for which it was collected or until you request to delete them.
          </Paragraph>
          <Title level={subTitleLevel}>10. Data Security</Title>
          <Paragraph>
            We have implemented commercially reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction, in accordance with industry practice and applicable laws. Please be aware that despite our best efforts, no data security measures can guarantee 100% security. While we strive to protect information transmitted on or through our Site, we cannot and do not guarantee the security of any information you transmit on or through the Site, and you do so at your own risk.
          </Paragraph>
          <Title level={subTitleLevel}>11. Retention of Personal Information</Title>
          <Paragraph>
            The data is only kept for the time necessary for the purpose for which it was collected. You have the right to request to delete the personal information that we hold about you at any time.
          </Paragraph>
          <Title level={subTitleLevel}>12. Modification of Privacy Policy</Title>
          <Paragraph>
            This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy on our Site. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the change on our Site or by contacting you at the email address on file.
          </Paragraph>
        </section>
      </div>
    </div>
  )
}

// TODO 출시


// TODO 출시
export const Terms = () => {
  const titleLevel = 3
  const subTitleLevel = 4

  const titleMarginBottom = 50
  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '60%'}}>
        <Title level={titleLevel} style={{textAlign: 'center', marginBottom: titleMarginBottom}}>Terms of Service </Title>
        <Title level={subTitleLevel}>1. Acceptance of Terms </Title>
        <Paragraph>
          Welcome to Dynapex. These Terms of Service ("Terms") govern your access and use of our cloud-based medical imaging analysis software (the "Service") provided by Dynapex LLC. ("Dynapex", "us", "we", "our"). Please read these Terms carefully. By using our Service, you agree to comply with and be bound by these Terms.
        </Paragraph>
        <Title level={subTitleLevel}>2. Description of Services </Title>
        <Paragraph>
          Dynapex provides an innovative and sophisticated cloud-based medical imaging analysis software. Leveraging state-of-the-art deep learning methodologies, our Services facilitate the automatic annotation of brain Magnetic Resonance Imaging (MRI) scans. Further, we conduct multi-parametric analysis of advanced MRI data, providing nuanced and comprehensive insight within demarcated regions of interest. The intended use of our Services is primarily aimed at healthcare professionals and researchers in the medical field. 
        </Paragraph>
        <Title level={subTitleLevel}>3. User Obligations </Title>
        <Paragraph>
          As a user of our Services, you acknowledge and agree to utilize our platform in full compliance with all relevant laws, regulations, and professional guidelines, both domestically and internationally. Furthermore, you commit to using our Services in a manner that aligns with these Terms and any additional guidelines or instructions we may provide. The user must not misuse our Services for any harmful or fraudulent purposes or in any way that could interrupt, negatively affect, or inhibit other users from fully enjoying our Services. 
        </Paragraph>
        <Title level={subTitleLevel}>4. Data Utilization and Privacy </Title>
        <Paragraph>
          By engaging with our Services, you grant us permission to use the data imported for the enhancement and optimization of our software's performance and capabilities. Please note that your data will be kept until the time necessary for the purpose for which it was collected or until you request to remove it. Our commitment to your privacy and the safeguarding of your data is paramount. For comprehensive information about how we handle and protect your data, please refer to our Privacy Policy. 
        </Paragraph>
        <Title level={subTitleLevel}>5. Intellectual Property Rights </Title>
        <Paragraph>
          The Service, along with its integral features and functionalities, original content, and associated intellectual property, are owned exclusively by Dynapex. They are safeguarded by international copyright, patent, trade secret, trademark, and other intellectual property or proprietary rights laws. Any unauthorized use, reproduction, or distribution of our Services or any associated intellectual property is strictly prohibited. 
        </Paragraph>
        <Title level={subTitleLevel}>6. Disclaimers </Title>
        <Paragraph>
          Dynapex provides the Service on an "as is" and "as available" basis without any representations, warranties, or conditions of any kind, either express or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. 
        </Paragraph>
        <Title level={subTitleLevel}>7. Limitation of Liability </Title>
        <Paragraph>
          Under no circumstances and under no legal theory (including, but not limited to, negligence) shall Dynapex, its affiliates, agents, directors, employees, suppliers, or licensors be held liable for any direct, indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of, or inability to use, our Service. 
        </Paragraph>
        <Title level={subTitleLevel}>8. Amendments to Terms </Title>
        <Paragraph>
          Dynapex reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. Continued use of our Service following the posting of changes to these Terms will constitute acceptance of those changes. 
        </Paragraph>
        <Title level={subTitleLevel}>9. Governing Law and Jurisdiction </Title>
        <Paragraph>
          These Terms, their subject matter and their formation, are governed by the laws of the Republic of Korea. You agree that the courts of the Republic of Korea will have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with these Terms or their subject matter or formation. 
        </Paragraph>
        <Title level={subTitleLevel}>10. Contact Information </Title>
        <Paragraph>
          Should you have any inquiries or concerns relating to these Terms or our Services, we encourage you to communicate with us directly. You may reach us via email at contact@dynapex.co. 
        </Paragraph>
      </div>
    </div>
  );
};
