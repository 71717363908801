
import React, {useState, useEffect, useImperativeHandle} from "react";
import {Transfer, Table, } from 'antd';
import difference from "lodash/difference";
import { useSearchHighlight } from "../context/searchHighlightContext";
import { caseSearchOptions } from "./caseSearchBox";

const TableTransfer = React.forwardRef((props, ref) => {
  const { dataSource, dataSource2, total, total2, totalDataSource, loading, targetKeys, searchParams } = props;
  const [paginationL, setPaginationL] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  })
  const [paginationR, setPaginationR] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false
  })
  
  const {searchHighlight} = useSearchHighlight({paginationInfo : searchParams, searchOptions : caseSearchOptions})

  useImperativeHandle(ref, () => ({
    getPaginationR: () => paginationR
  }))

  useEffect(() => {
    setPaginationL({
      ...paginationL,
      total: total
    })
  }, [props.total])
  useEffect(() => {
    setPaginationR({
      ...paginationR,
      total: total2
    })
  }, [props.total2])

  const leftColumns = [
    {
      title: 'Patient ID',
      dataIndex: ['patient', 'pid'],
      key: 'pid',
      render : text => searchHighlight(text,'pid')
    },
    {
      title: 'Patient name',
      dataIndex: ['patient', 'name'],
      key: 'pname',
      render : text => searchHighlight(text, 'name')
    },
    {
      title: 'Birth',
      dataIndex: ['patient', 'birth'],
      key: 'birth',
      render: text => {
        return text?.split(' ')[0]
      }
    },
    {
      title: 'Age',
      dataIndex: ['patient', 'age'],
      key: 'age',
      width: 'auto',
    },
    {
      title: 'Sex',
      dataIndex: ['patient', 'sex'],
      key: 'sex',
      width: 'auto',
    },
    {
      title: 'Study ID',
      dataIndex: ['study', 'sid'],
      key: 'sid',
      render : text => searchHighlight(text, 'study_id')
    },
    {
      title: 'Study date',
      dataIndex: ['study', 'date'],
      key: 'date',
      render: text => {
        return text?.split(' ')[0]
      },
    },
  ];

  const rightColumns = [
    {
      title: 'Patient ID',
      dataIndex: ['patient', 'pid'],
      key: 'pid',
    },
    {
      title: 'Patient name',
      dataIndex: ['patient', 'name'],
      key: 'pname',
      render : text => searchHighlight(text, '')
    },
    {
      title: 'Birth',
      dataIndex: ['patient', 'birth'],
      key: 'birth',
      width: 'auto',
      render: text => {
        return text?.split(' ')[0]
      }
    },
    {
      title: 'Age',
      dataIndex: ['patient', 'age'],
      key: 'age',
      width: 'auto',
    },
    {
      title: 'Sex',
      dataIndex: ['patient', 'sex'],
      key: 'sex',
      width: 'auto',
    },
    {
      title: 'Study ID',
      dataIndex: ['study', 'sid'],
      key: 'sid',
    },
    {
      title: 'Study date',
      dataIndex: ['study', 'date'],
      key: 'date',
      render: text => {
        return text?.split(' ')[0]
      },
    },
  ];

  return (
    <Transfer
      {...props}
      dataSource={dataSource}
      rowKey={(record) => record.key}
      ref={ref}
    >
      {(listProps) => {
        const {
          direction,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled
        } = listProps;
        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item?.disabled
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
                                    .filter(item => item !== undefined)
                                    .map(({key}) => key)

            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys
        };

        const handleTableChange = (paginationObj) => {
          const newPaginationL = {...paginationL}
          const newPaginationR = {...paginationR}
          if (direction === "left") {
            newPaginationL.current = paginationObj.current
            setPaginationL(newPaginationL)
          }
          else {
            newPaginationR.current = paginationObj.current
            setPaginationR(newPaginationR)
          }
          props.fetch({pagination: newPaginationL, pagination2: newPaginationR, search : {...searchParams}})
        };

        const rightDataSource = targetKeys.map(k => totalDataSource.find(item => item?.key === k) ?? {key: k}) 
        
        const leftDataSource = dataSource.map((item) => ({...item,disabled: targetKeys.includes(item.key)}));
        
        const columns = direction === 'left' ? leftColumns : rightColumns;

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            loading={loading}
            dataSource={
              direction === "left" ? leftDataSource : rightDataSource
            }
            size="small"
            rowKey="key"
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              }
            })}
            onChange={handleTableChange}
            pagination={direction === "left" ? paginationL : paginationR}
          />
        );
      }}
    </Transfer>
  );
});

export default TableTransfer;
