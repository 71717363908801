import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {assignTaskIndex, deleteTask, updateTask, updateTreeOpen} from "../../redux/modules/longTask";
import {CloseOutlined,DownloadOutlined,UploadOutlined,LoadingOutlined,
  CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { Button, Card, Progress, Space, Tree } from "antd";
import { TagBase } from "../tagBase";

const { DirectoryTree } = Tree;

const ProgressCard = React.forwardRef(({ index, onResize, height}, ref)=>  {
  const dispatch = useDispatch()
  const task = useSelector(state => state.longTask.tasks.find((task, order) => order === index));
  const treeData = useSelector(state => state.longTask.tasks.find((task, order) => order === index).treeData)
  const taskIndex = useSelector(state => state.longTask.tasks.find((task, order) => order === index).index)
  const ended = useSelector(state => state.longTask.tasks.find((task, order) => order === index).ended)
  const progress = useSelector(state => state.longTask.tasks.find((task, order) => order === index).progress)
  const count = useSelector(state => state.longTask.tasks.find((task, order) => order === index).count)
  const treeOpen = useSelector(state => state.longTask.tasks.find((task, order) => order === index).treeOpen)
  
  const taskId = task.taskId
  const progressDesc = task.progressDesc
  const treeDataRowCounts = task.treeDataRowCounts

  const treeDataLeafHeight = 28
  const treeDataHeight = treeDataRowCounts * treeDataLeafHeight

  const childKeys = []
  
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === ref.current) {
            setElementHeight(entry.contentRect.height + 10);
            break
          }
        }
      });
      resizeObserver.observe(ref.current);
      
      return () => resizeObserver.disconnect();
    }
  },[ref, treeOpen])

  useEffect(()=>{
    if (height !== elementHeight && elementHeight !== 0) {
      onResize(index, elementHeight)
    }
  },[taskIndex, elementHeight])

  useEffect(() => {
    if (taskIndex !== index) {
      changeIndex(index)
    }
  }, [taskIndex, index]);

  const changeIndex = index => dispatch(assignTaskIndex({ taskId: task.taskId, index }))

  function fileSizeUnit(size, totalSize) {
    if (totalSize / (1024 ** 3) > 1) {
      return [size / (1024 ** 3), totalSize / (1024 ** 3), "GB"];
    }
    else if (totalSize / (1024 ** 2) > 1) {
      return [size / (1024 ** 2), totalSize / (1024 ** 2), "MB"];
    } 
    else if (totalSize / 1024 > 1) {
      return [size / 1024, totalSize / 1024, "KB"];
    } 
    else {
      return [size, totalSize, "Byte"];
    }
  }

  const [downloadSize, fileSize, Unit] = fileSizeUnit(task.size, task.totalSize);

  const toFixed = 2; // 소수점 자릿수

  useEffect(()=>{
    let progressDesc = ""
    switch (progress) {
      case 0:
        progressDesc = ended ? `Fail` : "check size..."
        break;
      case 100:
        progressDesc = `${fileSize.toFixed(toFixed)} ${Unit}`
        break;
      default:
        progressDesc = `${downloadSize.toFixed(toFixed)}/${fileSize.toFixed(toFixed)} ${Unit}`
        break;
    }
    
    dispatch(updateTask({taskId, progressDesc}))
  },[ended, progress, count, task.size])
  
  const resultDownloadLeafNodeInformation = row => {
    if (row.children) {
      row.children = row.children.map(childRow => resultDownloadLeafNodeInformation(childRow))
    }
    else if (row.isLeaf) {
      childKeys.push(row.key)
      row.title = `[${row.count} / ${row.totalCount}] ${row.fileName}`
      row.selectable = false
      row.checkable =  false
      row.icon = row.status ? (
        <CheckCircleOutlined />
      ) : row.status === undefined ? (
        <LoadingOutlined />
      ) : (
        <CloseCircleOutlined />
      )
      return row
    }
    return row
  }

  function makeTreeData(task) {
    if (task.type === 'upload') { // study - data
      return treeData.map(study => ({
        ...study,
        children: study.children.map(data => {
          childKeys.push(data.key)
          return {
            ...data,
            title: `[${data.count} / ${data.totalCount}] ${data.title}`,
            icon: data.status ? (
              <CheckCircleOutlined />
            ) : data.status === undefined ? (
              <LoadingOutlined />
            ) : (
              <CloseCircleOutlined />
            )
          }
        })
      }))
    }
    else if (task.type === 'downloadPatient') {
      return treeData.map(patient => ({ // patients -> patient -> studies -> study -> data -> (dicom, nifti)
        ...patient,
        children: [
          ...patient.children.map(study => ({
            ...study,
            children: [
              ...study.children.map(data => ({
                ...data,
                children: [
                  ...data.children.map(file => {
                    childKeys.push(file.key)
                    return {
                      ...file,
                      title: `[${file.count} / ${file.totalCount}] ${file.title}`,
                      selectable: false,
                      isLeaf: true,
                      checkable: false,
                      icon: file.status ? (
                        <CheckCircleOutlined />
                      ) : file.status === undefined ? (
                        <LoadingOutlined />
                      ) : (
                        <CloseCircleOutlined />
                      )
                    }
                  })
                ]
              }))
            ]
          }))
        ]
      }))
    }
    return treeData.map(row => resultDownloadLeafNodeInformation(row))
  }

  const customTreeData = task.treeData && makeTreeData(task)

  return (
    <Card
      ref={ref}
      id={task.taskId}
      style={{ borderRadius: "10px"}}
      title={
        <>
          <TagBase
            color={task.type === "upload" ? '192,16,0' : '0,128,64'}
            icon={
              task.type === "upload" ? (
                <UploadOutlined />
              ) : (
                <DownloadOutlined />
              )
            }
          >
            {task.label}
          </TagBase>
          {`${task.title}`}
        </>
      }
      extra={
        <Button
          icon={<CloseOutlined />}
          disabled={ended ? false : true}
          style={{ margin: "0px 0px 0px 10px" }}
          type={"text"}
          onClick={() => dispatch(deleteTask({ taskId: task.taskId }))}
        />
      }
    >
      <div style={{height: 66, marginBottom: 10}}>
        <Progress
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
          status={ended ? (progress === 100 ? null : "exception") : "active"}
          percent={progress === 100 ? (ended ? 100 : 99) : progress}
        />
        <Space>
          {!ended && count ===0 && <LoadingOutlined />}
          {task.desc}
        </Space>
        <p style={{magin:0, padding: 0}}>
          {progressDesc}
        </p>
      </div>
      <DirectoryTree
        showLine={true}
        defaultExpandAll={task.treeOpen}
        autoExpandParent={true}
        onExpand={(_, status) => {
          if (count !==0) {
            dispatch(updateTreeOpen({ taskId: task.taskId, treeOpen: status.expanded }))
          }
        }}
        blockNode={true}
        treeData={customTreeData}
        showIcon={true}
        selectable={false}
        height={treeDataHeight}
        expandedKeys={task.treeOpen ? childKeys : []}
      />
    </Card>
  );
})

export default ProgressCard;
