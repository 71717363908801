import React, {useEffect, useState} from "react";
import {Select, Tag, Divider, Button} from 'antd';
import chroma from "chroma-js";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import { TagBase } from "./tagBase";

const { Option } = Select;

function ImageTypeSelect(
  {
    onChange,
    dropdownMatchSelectWidth,
    defaultValue,
    value,
    row_index,
    disabled,
    imageTypes= undefined,
    placement="bottomLeft",
  }
) {
  const {imageTypeList, getTypeFromTypeId, getTagColorFromType} = useGetImageTypeHooks(imageTypes)

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    // const onPreventMouseDown = (event) => {
    //   // event.preventDefault();
    //   // event.stopPropagation();
    // };
    const type = getTypeFromTypeId(value)
    const tagColor = getTagColorFromType(type)
    return (
      <TagBase
        color={tagColor}
        closable={!disabled}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {type?.short}
      </TagBase>
    );
  };

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleChange = (value, option) => {
    setSelected(value)
    if (option.length > 0) {
      setOpen(false)
    }
    onChange(value[0], option[0]) 
  }

  useEffect(() => {
    if (value == null || Array.isArray(value) && value.length === 0) {
      setSelected([])
    }
    else {
      setSelected([value])
    }
  }, [value])

  const onHandleReset = () => {
    setSelected([defaultValue])
    onChange(defaultValue) 
  }

  return (
    <Select
      data-col="type"
      showSearch={selected.length === 0}
      style={{ minWidth: 130 }}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      mode="multiple"
      disabled={disabled}
      tagRender={tagRender}
      onChange={handleChange}
      defaultValue={[defaultValue]}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      value={selected}
      dropdownStyle={{minWidth : 130}}
      dropdownRender={menu => ( // menu = children = Option
        <div>
          {menu} 
          {defaultValue  // in patient detail page
          ? <>
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, justifyContent :'center' }}>
                <Button onClick={onHandleReset} type='primary' size='small'>Reset to default</Button>
              </div>
            </>
          : null}
        </div>
      )}
      filterOption={(input, option) =>
        (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
      }
      placement={placement}
      getPopupContainer={trigger => trigger.parentNode}
    >
      {imageTypeList ? imageTypeList.map(item => (
        <Option
          key={item.id}
          title={item.short}
          value={item.id}
          row_index={row_index}
          option_index={item.id}
          disabled={
            selected.length > 0
              ? !selected.includes(item.id)
              : false
          }
        >
          <TagBase color={chroma(item.tag_color.split(',')).hex()}>
            {item.short}
          </TagBase>
        </Option>
      )): null}
    </Select>
  );
}
export default ImageTypeSelect;

