import { all, fork } from 'redux-saga/effects'
import auth from './modules/auth'
import blobTypes from './modules/blobTypes'
import patient from './modules/patient'
import analysis from './modules/analysis'
import view from './modules/view'
import dataset from './modules/dataset'
import pipeline from './modules/pipeline'
import study from './modules/study'
import blob from './modules/blob'
import series from './modules/series'
import scatter from './modules/scatter'
import notice from './modules/notice'
import message from './modules/message'
import config from './modules/config'
// long Task
import upload from './modules/longTask/upload'
import download from './modules/longTask/download'

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(blobTypes),
    fork(patient),
    fork(analysis),
    fork(view),
    fork(dataset),
    fork(pipeline),
    fork(study),
    fork(blob),
    fork(series),
    fork(scatter),
    fork(notice),
    fork(message),
    fork(config),
    fork(upload),
    fork(download),
  ])
}

