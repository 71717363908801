export const LOAD = 'pipeline/LOAD';
export const LOAD_SUCCESS = 'pipeline/LOAD_SUCCESS';
export const LOAD_FAIL = 'pipeline/LOAD_FAIL';
export const LOAD_TASKS = 'pipeline/LOAD_TASKS';
export const LOAD_TASKS_SUCCESS = 'pipeline/LOAD_TASKS_SUCCESS';
export const LOAD_TASKS_FAIL = 'pipeline/LOAD_TASKS_FAIL';
export const GET = 'pipeline/GET';
export const GET_SUCCESS = 'pipeline/GET_SUCCESS';
export const GET_FAIL = 'pipeline/GET_FAIL';
export const ADD = 'pipeline/ADD';
export const ADD_SUCCESS = 'pipeline/ADD_SUCCESS';
export const ADD_FAIL = 'pipeline/ADD_FAIL';
export const UPDATE = 'pipeline/UPDATE';
export const UPDATE_SUCCESS = 'pipeline/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'pipeline/UPDATE_FAIL';
export const DEL = 'pipeline/DEL';
export const DEL_SUCCESS = 'pipeline/DEL_SUCCESS';
export const DEL_FAIL = 'pipeline/DEL_FAIL';
export const CLEAR = 'pipeline/CLEAR'
export const CHECK_NAME = 'pipeline/CHECK_NAME'
export const CHECK_NAME_SUCCESS = 'pipeline/CHECK_NAME_SUCCESS'
export const CHECK_NAME_FAIL = 'pipeline/CHECK_NAME_FAIL'
export const LOAD_MORE = 'pipeline/LOAD_MORE'
export const LOAD_MORE_SUCCESS = 'pipeline/LOAD_MORE_SUCCESS'
export const LOAD_MORE_FAIL = 'pipeline/LOAD_MORE_FAIL'
export const GET_PRESET = 'pipeline/GET_PRESET'
export const GET_PRESET_SUCCESS = 'pipeline/GET_PRESET_SUCCESS'
export const GET_PRESET_FAIL = 'pipeline/GET_PRESET_FAIL'
export const LOAD_PRESET = 'pipeline/LOAD_PRESET'
export const LOAD_PRESET_SUCCESS = 'pipeline/LOAD_PRESET_SUCCESS'
export const LOAD_PRESET_FAIL = 'pipeline/LOAD_PRESET_FAIL'
export const CLEAR_PRESET = 'pipeline/CLEAR_PRESET'
export const DUPLICATE = 'pipeline/DUPLICATE'
export const DUPLICATE_SUCCESS = 'pipeline/DUPLICATE_SUCCESS'
export const DUPLICATE_FAIL = 'pipeline/DUPLICATE_FAIL'
export const LOAD_REPORT_TEMPLATES = 'pipeline/LOAD_REPORT_TEMPLATES'
export const LOAD_REPORT_TEMPLATES_SUCCESS = 'pipeline/LOAD_REPORT_TEMPLATES_SUCCESS'
export const LOAD_REPORT_TEMPLATES_FAIL = 'pipeline/LOAD_REPORT_TEMPLATES_FAIL'
export const CLEAR_LOADED_REPORT_TEMPLATE = 'pipeline/CLEAR_LOADED_REPORT_TEMPLATE'
export const CLEAR_REPORT_TEMPLATES = 'pipeline/CLEAR_REPORT_TEMPLATES'
export const CHECK_REPORT_TEMPLATE_NAME = 'pipeline/CHECK_REPORT_TEMPLATE_NAME'
export const CHECK_REPORT_TEMPLATE_NAME_SUCCESS = 'pipeline/CHECK_REPORT_TEMPLATE_NAME_SUCCESS'
export const CHECK_REPORT_TEMPLATE_NAME_FAIL = 'pipeline/CHECK_REPORT_TEMPLATE_NAME_FAIL'
export const ADD_REPORT_TEMPLATE = 'pipeline/ADD_REPORT_TEMPLATE'
export const ADD_REPORT_TEMPLATE_SUCCESS = 'pipeline/ADD_REPORT_TEMPLATE_SUCCESS'
export const ADD_REPORT_TEMPLATE_FAIL = 'pipeline/ADD_REPORT_TEMPLATE_FAIL'
export const GET_REPORT_TEMPLATE = 'pipeline/GET_REPORT_TEMPLATE'
export const GET_REPORT_TEMPLATE_SUCCESS = 'pipeline/GET_REPORT_TEMPLATE_SUCCESS'
export const GET_REPORT_TEMPLATE_FAIL = 'pipeline/GET_REPORT_TEMPLATE_FAIL'
export const UPDATE_REPORT_TEMPLATE = 'pipeline/UPDATE_REPORT_TEMPLATE'
export const UPDATE_REPORT_TEMPLATE_SUCCESS = 'pipeline/UPDATE_REPORT_TEMPLATE_SUCCESS'
export const UPDATE_REPORT_TEMPLATE_FAIL = 'pipeline/UPDATE_REPORT_TEMPLATE_FAIL'
export const DEL_REPORT_TEMPLATES = 'pipeline/DEL_REPORT_TEMPLATES'
export const DEL_REPORT_TEMPLATES_SUCCESS = 'pipeline/DEL_REPORT_TEMPLATES_SUCCESS'
export const DEL_REPORT_TEMPLATES_FAIL = 'pipeline/DEL_REPORT_TEMPLATES_FAIL'
export const DUPLICATE_REPORT_TEMPLATE = 'pipeline/DUPLICATE_REPORT_TEMPLATE'
export const DUPLICATE_REPORT_TEMPLATE_SUCCESS = 'pipeline/DUPLICATE_REPORT_TEMPLATE_SUCCESS'
export const DUPLICATE_REPORT_TEMPLATE_FAIL = 'pipeline/DUPLICATE_REPORT_TEMPLATE_FAIL'
export const UPDATE_REPORT_PREVIEW_OPEN = 'pipeline/UPDATE_REPORT_PREVIEW_OPEN'
export const UPDATE_REPORT_PREVIEW = 'pipeline/UPDATE_REPORT_PREVIEW'
export const UPDATE_REPORT_TEMPLATE_MODAL_OPEN = 'pipeline/UPDATE_REPORT_TEMPLATE_MODAL_OPEN'
export const LOAD_REPORT_TEMPLATE_PRESET = 'pipeline/LOAD_REPORT_TEMPLATE_PRESET'
export const LOAD_REPORT_TEMPLATE_PRESET_SUCCESS = 'pipeline/LOAD_REPORT_TEMPLATE_PRESET_SUCCESS'
export const LOAD_REPORT_TEMPLATE_PRESET_FAIL = 'pipeline/LOAD_REPORT_TEMPLATE_PRESET_FAIL'
export const GET_REPORT_TEMPLATE_PRESET = 'pipeline/GET_REPORT_TEMPLATE_PRESET'
export const GET_REPORT_TEMPLATE_PRESET_SUCCESS = 'pipeline/GET_REPORT_TEMPLATE_PRESET_SUCCESS'
export const GET_REPORT_TEMPLATE_PRESET_FAIL = 'pipeline/GET_REPORT_TEMPLATE_PRESET_FAIL'
export const CLEAR_REPORT_TEMPLATE_PRESET = 'pipeline/CLEAR_REPORT_TEMPLATE_PRESET'

const initialState = {
  loaded: false,
  list : []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list.map(data => ({...data, key: data.id})),
        total: action.payload.total,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case LOAD_TASKS:
      return {
        ...state,
        loading: true
      };
    case LOAD_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        tasks: action.payload
      };
    case LOAD_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case ADD:
      return {
        ...state,
        loading: true
      };
    case ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        pipeline: action.payload.pipeline,
        tasks: action.payload.tasks,
        task_templates: action.payload.task_templates,
        models: action.payload.models,
        modes: action.payload.modes,
        modalities: action.payload.modalities,
        check : { // for name duplicate rule
          name : action.payload.pipeline?.name
        }
      };
    }
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DEL:
      return {
        ...state,
        loading: true
      };
    case DEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list,
        total: action.payload.total,
      };
    case DEL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case CLEAR:
      return initialState
    case CHECK_NAME:
      return {
        ...state,
        check : {
          ...state.check,
          loading : true,
          name : undefined,
          nameError : undefined
        }
      }
    case CHECK_NAME_SUCCESS:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          name : action.payload
        }
      }
    case CHECK_NAME_FAIL:
      return {
        ...state,
        check : {
          ...state.check,
          loading : false,
          nameError : action.payload
        }
      }
    case LOAD_MORE:
      return {
        ...state,
        loading : true
      }
    case LOAD_MORE_SUCCESS:
      return {
        ...state,
        loading : false,
        total : action.payload.total,
        list : [...state.list, ...action.payload.list]
      }
    case LOAD_MORE_FAIL:
      return {
        ...state,
        loading : false
      }
    case GET_PRESET:
      return {
        ...state,
        loading: true
      }
    case GET_PRESET_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        pipeline: action.payload.pipeline,
        tasks: action.payload.tasks,
        task_templates: action.payload.task_templates,
        models: action.payload.models,
        modes: action.payload.modes,
        modalities: action.payload.modalities,
        check : { 
          name : null
        }
      }
    case GET_PRESET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case LOAD_PRESET:
      return {
        ...state,
        loading: true,
      }
    case LOAD_PRESET_SUCCESS:
      return {
        ...state,
        loading: false,
        pipeline_template : action.payload
      }
    case LOAD_PRESET_FAIL:
      return {
        ...state,
        loading: false
      }
    case CLEAR_PRESET:
      return {
        ...state,
        pipeline_template: undefined
      }
    case DUPLICATE:
      return {
        ...state,
        loading: true
      }
    case DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case DUPLICATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case LOAD_REPORT_TEMPLATES:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: true
        }
      }
    case LOAD_REPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false,
          ...action.payload
        }
      }
    case LOAD_REPORT_TEMPLATES_FAIL:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false
        }
      }
    case CLEAR_LOADED_REPORT_TEMPLATE:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          load: undefined
        }
      }
    case CLEAR_REPORT_TEMPLATES:
      return {
        ...state,
        reportTemplate: undefined
      }
    case CHECK_REPORT_TEMPLATE_NAME:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          check : {
            ...state?.reportTemplate?.check,
            loading : true,
            name : undefined,
            nameError : undefined
          }
        }
      }
    case CHECK_REPORT_TEMPLATE_NAME_SUCCESS:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          check : {
            ...state?.reportTemplate?.check,
            loading : false,
            name : action.payload
          }
        }
      }
    case CHECK_REPORT_TEMPLATE_NAME_FAIL:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          check : {
            ...state?.reportTemplate?.check,
            loading : false,
            nameError : action.payload
          }
        }
      }
    case ADD_REPORT_TEMPLATE:
      return {
        ...state,
        loading: true
      };
    case ADD_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_REPORT_TEMPLATE:
      return {
        ...state,
        reportTemplate: {
          loading: true
        }
      };
    case GET_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        reportTemplate: {
          loading: false,
          load: action.payload,
          check : { // for name duplicate rule
            name : action.payload?.name
          }
        },
      };
    case GET_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        reportTemplate: {
          loading: false,
        }
      }
    case UPDATE_REPORT_TEMPLATE:
      return {
        ...state,
        loading: true
      }
    case UPDATE_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DEL_REPORT_TEMPLATES:
      return {
        ...state,
        loading: true
      }
    case DEL_REPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        reportTemplate: {
          ...state?.reportTemplate,
          list: action.payload.list,
          total: action.payload.total,
        },
      }
    case DEL_REPORT_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
      }
    case DUPLICATE_REPORT_TEMPLATE:
      return {
        ...state,
        loading: true
      }
    case DUPLICATE_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case DUPLICATE_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_REPORT_PREVIEW_OPEN:
      return {
        ...state,
        preview_open: action.payload
      }
    case UPDATE_REPORT_PREVIEW:
      return {
        ...state,
        preview_update: action.payload
      }
    case UPDATE_REPORT_TEMPLATE_MODAL_OPEN:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          open: action.payload
        }
      }
    case LOAD_REPORT_TEMPLATE_PRESET:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: true
        }
      }
    case LOAD_REPORT_TEMPLATE_PRESET_SUCCESS:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false,
          preset: {
            list: action.payload.list,
            total: action.payload.total,
          }
        }
      }
    case LOAD_REPORT_TEMPLATE_PRESET_FAIL:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false
        }
      }
    case GET_REPORT_TEMPLATE_PRESET:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: true
        }
      }
    case GET_REPORT_TEMPLATE_PRESET_SUCCESS:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false,
          load: action.payload,
          check : { 
            name : null
          }
        }
      }
    case GET_REPORT_TEMPLATE_PRESET_FAIL:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          loading: false
        }
      }
    case CLEAR_REPORT_TEMPLATE_PRESET:
      return {
        ...state,
        reportTemplate: {
          ...state?.reportTemplate,
          preset: undefined
        }
      }
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function loadPatient(payload) {
  return {type: LOAD_TASKS, payload};
}

export function loadPatientSuccess(payload) {
  return {type: LOAD_TASKS_SUCCESS, payload};
}

export function loadPatientFail(payload) {
  return {type: LOAD_TASKS_FAIL, payload};
}

export function add(payload) {
  return {type: ADD, payload};
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload};
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload};
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function del(payload) {
  return {type: DEL, payload};
}

export function delSuccess(payload) {
  return {type: DEL_SUCCESS, payload};
}

export function delFail(payload) {
  return {type: DEL_FAIL, payload};
}

export function clear() {
  return {type : CLEAR}
}

export function checkName(payload) {
  return {type : CHECK_NAME, payload}
}

export function checkNameSuccess(payload) {
  return {type : CHECK_NAME_SUCCESS, payload}
}

export function checkNameFail(payload) {
  return {type : CHECK_NAME_FAIL, payload}
}

export function loadMore(payload) {
  return {type : LOAD_MORE, payload}
}

export function loadMoreSuccess(payload) {
  return {type : LOAD_MORE_SUCCESS, payload}
}

export function loadMoreFail(payload) {
  return {type : LOAD_MORE_FAIL, payload}
}

export function getPreset(payload) {
  return {type: GET_PRESET, payload}
}

export function getPresetSuccess(payload) {
  return {type: GET_PRESET_SUCCESS, payload}
}

export function getPresetFail(payload) {
  return {type: GET_PRESET_FAIL, payload}
}

export function loadPreset() {
  return {type: LOAD_PRESET}
}

export function loadPresetSuccess(payload) {
  return {type: LOAD_PRESET_SUCCESS, payload}
}

export function loadPresetFail() {
  return {type: LOAD_PRESET_FAIL}
}

export function clearPreset() {
  return {type: CLEAR_PRESET}
}

export function duplicate(payload) {
  return {type: DUPLICATE, payload}
}

export function duplicateSuccess(payload) {
  return {type: DUPLICATE_SUCCESS, payload}
}

export function duplicateFail(payload) {
  return {type: DUPLICATE_FAIL, payload}
}

export function loadReportTemplates(payload) {
  return {type: LOAD_REPORT_TEMPLATES, payload}
}

export function loadReportTemplatesSuccess(payload) {
  return {type: LOAD_REPORT_TEMPLATES_SUCCESS, payload}
}

export function loadReportTemplatesFail(payload) {
  return {type: LOAD_REPORT_TEMPLATES_FAIL, payload}
}

export function clearLoadedReportTemplate() {
  return {type: CLEAR_LOADED_REPORT_TEMPLATE}
}

export function clearReportTemplates() {
  return {type: CLEAR_REPORT_TEMPLATES}
}

export function delReportTemplates(payload) {
  return {type: DEL_REPORT_TEMPLATES, payload}
}

export function delReportTemplatesSuccess(payload) {
  return {type: DEL_REPORT_TEMPLATES_SUCCESS, payload}
}

export function delReportTemplatesFail(payload) {
  return {type: DEL_REPORT_TEMPLATES_FAIL, payload}
}

export function checkReportTemplateName(payload) {
  return {type: CHECK_REPORT_TEMPLATE_NAME, payload}
}

export function checkReportTemplateNameSuccess(payload) {
  return {type: CHECK_REPORT_TEMPLATE_NAME_SUCCESS, payload}
}

export function checkReportTemplateNameFail(payload) {
  return {type: CHECK_REPORT_TEMPLATE_NAME_FAIL, payload}
}

export function addReportTemplate(payload) {
  return {type: ADD_REPORT_TEMPLATE, payload}
}

export function addReportTemplateSuccess(payload) {
  return {type: ADD_REPORT_TEMPLATE_SUCCESS, payload}
}

export function addReportTemplateFail(payload) {
  return {type: ADD_REPORT_TEMPLATE_FAIL, payload}
}

export function getReportTemplate(payload) {
  return {type: GET_REPORT_TEMPLATE, payload}
}

export function getReportTemplateSuccess(payload) {
  return {type: GET_REPORT_TEMPLATE_SUCCESS, payload}
}

export function getReportTemplateFail(payload) {
  return {type: GET_REPORT_TEMPLATE_FAIL, payload}
}

export function updateReportTemplate(payload) {
  return {type: UPDATE_REPORT_TEMPLATE, payload}
}

export function updateReportTemplateSuccess(payload) {
  return {type: UPDATE_REPORT_TEMPLATE_SUCCESS, payload}
}

export function updateReportTemplateFail(payload) {
  return {type: UPDATE_REPORT_TEMPLATE_FAIL, payload}
}

export function duplicateReport(payload) {
  return {type: DUPLICATE_REPORT_TEMPLATE, payload}
}

export function duplicateReportSuccess(payload) {
  return {type: DUPLICATE_REPORT_TEMPLATE_SUCCESS, payload}
}

export function duplicateReportFail(payload) {
  return {type: DUPLICATE_REPORT_TEMPLATE_FAIL, payload}
}

export function updatePreviewOpen(payload) {
  return {type: UPDATE_REPORT_PREVIEW_OPEN, payload}
}

export function updateReportPreview(payload) {
  return {type: UPDATE_REPORT_PREVIEW, payload}
}

export function updateReportTemplateModalOpen(payload) {
  return {type: UPDATE_REPORT_TEMPLATE_MODAL_OPEN, payload}
}

export function loadReportTemplatePreset() {
  return {type: LOAD_REPORT_TEMPLATE_PRESET}
}

export function loadReportTemplatePresetSuccess(payload) {
  return {type: LOAD_REPORT_TEMPLATE_PRESET_SUCCESS, payload}
}

export function loadReportTemplatePresetFail(payload) {
  return {type: LOAD_REPORT_TEMPLATE_PRESET_FAIL, payload}
}

export function getReportTemplatePreset(payload) {
  return {type: GET_REPORT_TEMPLATE_PRESET, payload}
}

export function getReportTemplatePresetSuccess(payload) {
  return {type: GET_REPORT_TEMPLATE_PRESET_SUCCESS, payload}
}

export function getReportTemplatePresetFail(payload) {
  return {type: GET_REPORT_TEMPLATE_PRESET_FAIL, payload}
}

export function clearReportTemplatePreset() {
  return {type: CLEAR_REPORT_TEMPLATE_PRESET}
}
