import { Button, Col, Form, Input, Row, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useDuplicateCheck } from "../../context/duplicateCheckContext";
import { inputFormatter } from "../../lib/inputFormatter";
import { getTextByte } from "../../lib/getTextByte";
import { useEffect } from "react";
import { TextAreaWithCount } from "../styledComponent";
import ReportConfig from "./reportConfig";
import { getCurrentTemplate } from "./utils";
import { addReportTemplate, checkReportTemplateName, clearReportTemplates, duplicateReport, getReportTemplate, getReportTemplatePreset, updateReportTemplate } from "../../redux/modules/pipeline";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { load as loadImageTypes } from "../../redux/modules/blobTypes"
import LoadingModal from "../loadingModal";
import { validChangeReportConfig, TASK_CONFIG_NAME_PAGES_IN_REPORT } from "../../lib/taskUtil";

const ManageReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  
  const imageTypeList = useSelector((state) => state.blobTypes?.list) || [];
  const loadedTemplate = useSelector((state) => state.pipeline?.reportTemplate?.load)
  const sidebarOpen = useSelector(state => state.config.sidebar_open);
  const loading = useSelector(state => state.pipeline?.loading)

  const checkLoading = useSelector(state => state.pipeline?.reportTemplate?.check?.loading)
  const checkedName = useSelector(state => state.pipeline?.reportTemplate?.check?.name)
  const checkedNameError = useSelector(state => state.pipeline?.reportTemplate?.check?.nameError)

  const [form] = Form.useForm();
  const reportValue = Form.useWatch('report', form)
  const descValue = reportValue?.desc

  const id = location.state.key
  
  const onFinish = () => {
    const newTemplate = getCurrentTemplate(reportValue)
    const valid = validChangeReportConfig(newTemplate?.[TASK_CONFIG_NAME_PAGES_IN_REPORT], imageTypeList.map(t => t.short))
    if (id < 0) {
      return dispatch(addReportTemplate({...newTemplate, id, valid}))
    }
    return dispatch(updateReportTemplate({...newTemplate, id, valid}))
  }

  const onCancel = () => history.goBack()
  const onDuplicate = () => {
    const newTemplate = getCurrentTemplate(reportValue)
    return dispatch(duplicateReport(newTemplate))
  }

  useEffect(() => {
    dispatch(loadImageTypes({}));
    
    if (id > 0) {
      dispatch(getReportTemplate({id}))
    }
    else {
      const index = location?.state?.presetIndex
      if (index >=0) {
        dispatch(getReportTemplatePreset({index}))
      }
    }

    return () => dispatch(clearReportTemplates())
  },[])

  const { onFieldsChange, lastMatchValidator} = useDuplicateCheck({
    loading : checkLoading,
    form : form,
    fields : [{
      fieldName : ['report','name'],
      action : checkReportTemplateName,
      result : checkedName,
      error : checkedNameError
    }]
  })

  return (
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        // minWidth: '50%',
        width: '100%',
      }}
    >
      <LoadingModal 
        loading={loading}
      />
      <Form
        form={form}
        name="report"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        style={{width: sidebarOpen ? '48%':'49%', padding: 10}}
        labelCol={{span: 3}}
        labelWrap
      >
        <Row gutter={[8, 4]} align="middle">
          <Col span={12}>
            <h1>Report template</h1>
          </Col>
          <Col span={12} flex='1'>
            <div>
              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right" }}
                >
                  {id > 0 && 
                    <Button onClick={onDuplicate}>
                      Duplicate
                    </Button>
                  }
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    OK
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <br />
        <Form.Item
          label="Name"
          name={["report","name"]}
          rules={[
            {
              required: true,
              whitespace : true,
              type : 'string',
              message: 'Please input your pipeline name!',
            },
            {
              max: 256,
              message : 'Cannot exceed 256 letters'
            },
            {
              pattern : new RegExp(/^(?!.*[\\]).*$/gu),
              message : `The following special characters cannot be used. \\`
            },
            lastMatchValidator(['report','name'])
          ]}
          hasFeedback
        >
          <Input
            placeholder="My Pipeline"
            maxLength={256}
            showCount={inputFormatter}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name={['report',"desc"]}
          rules={[
            {
              message: 'Please describe your pipeline!',
            },
            () => ({
              validator(_, value) {
                if (!value || getTextByte(value) <= 65535) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Cannot exceed 65535 letters'));
              },
            }),
          ]}
        >
          <TextAreaWithCount 
            placeholder="Pipeline description" 
            rows={4}
            maxLength={65535}
            style={{marginBottom: 10}}
            showCount={{
              formatter: ({ count, maxLength})=> `${getTextByte(descValue || "")}/${maxLength}`
            }}
          />
        </Form.Item>
        <Form.Item 
          hidden={true}
          name="report"
        />
        <ReportConfig 
          name={'report'}
          value={reportValue}
          candidates={imageTypeList.map(t => t.short)}
          setFieldsValue={form.setFieldsValue}
          inManageReportPage={true}
          loadedTemplate={loadedTemplate}
        />
      </Form>
    </div>
  )
}

export default ManageReport