
import React, {useState, useEffect, useRef} from "react";
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import {Button, Divider, Select, Tag} from "antd";
import chroma from "chroma-js";
import {MODE} from "./taskInputSelect";
import { getTagTextColor } from "./tagBase";

const rBgColor = '#989898'
const xBtnColor = '#666666'
const xBtnColorOnHover = '#000000'

const LRCommonStyle = {
  borderWidth: 0,
  // borderStyle: 'solid',
  // borderColor: 'gray',
  boxSizing: 'border-box',
  paddingInline: 7,
  textAlign: 'center',
  fontSize: 12,
  color: 'black',
  display: 'inline-block',
  lineHeight: '22px'
}

const lStyle = {
  ...LRCommonStyle,
  marginRight: 0,
  borderTopLeftRadius: 5,
  borderBottomLeftRadius: 5,
  textAlign: 'center',
  // borderLeftWidth: 1, // 그냥 common 에 border 1주고 right만 0주면 안됨?
  borderRightWidth: 0,
}
const rStyle = {
  ...LRCommonStyle,
  marginRight: 8,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  borderLeftWidth: 0,
  // borderRightWidth: 1,
  backgroundColor: rBgColor,
}
const bStyle = {
  padding: 0,
  paddingLeft: 3,
  border: 0,
  cursor: 'pointer',
  lineHeight: 'px',
  backgroundColor: rBgColor
}

export const LabelView = ({name, color, value, onClose}) => {
  const [closeButtonColor, setCloseButtonColor] = useState(xBtnColor)
  return (
    <>
      <span style={{...lStyle, backgroundColor: color, color: getTagTextColor(color)}}> {name} </span>
      <span style={rStyle}>
        {value}
        {!onClose ? null :
          <button
            type="button"
            onClick={onClose}
            onMouseEnter={() => setCloseButtonColor(xBtnColorOnHover)}
            onMouseLeave={() => setCloseButtonColor(xBtnColor)}
            style={{...bStyle, color: closeButtonColor}}>
            <span style={{fontSize:16, lineHeight: 0}}> x </span>
          </button>
        }
      </span>
    </>
  )
}

export const LabelListView = ({labels}) => {
  return (
    <div>
      {labels.map(l => <LabelView name={l.name} color={l.color} value={l.value}/>)}
    </div>
  )
}

export const LabelSelect = (
  {
    options,
    onChange,
    defaultValue,
    value: valueSpecified,
    disabled,
    inReport=false
  }
) => {
  // const {imageTypeList, getTypeFromTypeId, getTagColorFromType} = useGetImageTypeHooks()
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <LabelView
        name={label?.props?.name ?? `INVALID LABEL: ${label}`}
        value={label?.props?.value ?? undefined}
        onClose={disabled ? null : onClose}
        color={label?.props?.color ?? '#777777'}
      />
    );
  };

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleChange = (value, option) => {
    setSelected(value)
    if (option.length > 0) {
      setOpen(false)
    }
    onChange(value[0], option[0])
  }

  useEffect(() => {
    if (!options) {
      setSelected([])
    }
  }, [options])

  useEffect(() => {
      const valid = options?.some(opt => opt.value === valueSpecified)
      if (valid || (!valid && valueSpecified === 0)) {
        setSelected([valueSpecified])
      } else {
        setSelected([])
      }
  }, [valueSpecified])

  if (!options) {
    return <Select
      placeholder="Select ROI"
      showSearch={selected.length === 0}
      style={{ minWidth: 250 }}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      mode="multiple"
      value={selected}
      dropdownStyle={{minWidth : 250}}
      getPopupContainer={trigger => trigger.parentNode}
    />
  }

  return (
    <Select
      data-col="type"
      placeholder="Select ROI"
      showSearch={selected.length === 0}
      style={inReport ? {width: '100%'} : {minWidth: 250}}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      mode="multiple"
      disabled={disabled}
      tagRender={tagRender}
      onChange={handleChange}
      defaultValue={[defaultValue]}
      // dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      value={selected}
      dropdownStyle={{minWidth : 250}}
      getPopupContainer={trigger => trigger.parentNode}
      filterOption={(input, option) =>
        option.title.toLowerCase().includes(input.toLowerCase()) || option.value.toString().includes(input)
      }
    >
      {
        [...options, {name: 'background', color: '#000000', value:0}].map((opt, optIdx) => {
          const testDisabled = selected.length > 0 && !selected.includes(opt.value)
          return <Select.Option
            key={opt.name}
            title={opt.name}
            value={opt.value}
            disabled={testDisabled}
          >
            <LabelView name={opt.name} color={opt.color} value={opt.value}/>
          </Select.Option>
        })
      }
    </Select>
  );
}
