import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';
import myOrientationTool from "./customTools/myOrientationTool";

export default function initCornerstone() {
  // Cornertone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

  // Register Custom tool moudules
  // cornerstoneTools.register('module', 'helloWorldModule', myModule);


  // cornerstoneTools.init();
  cornerstoneTools.init({
    mouseEnabled: true,
    touchEnabled: false, // 이거 leak 겁나 생김 ㅠ
    // globalToolSyncEnabled: true, // 흠 이걸 왜 tracking 하고 있지....? in setToolMode.js in cornerstoneTools
    // 허허 이거 날리니 다 없어지네 흠..
    // globalToolSyncEnabled: true,
    showSVGCursors: true,
  });

  const segmentationModule = cornerstoneTools.getModule('segmentation');
  segmentationModule.setters.colorForSegmentIndexOfColorLUT(0, 3, [12, 12, 255, 255])

  // Preferences
  const fontFamily =
    'Work Sans, Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif';
  cornerstoneTools.textStyle.setFont(`16px ${fontFamily}`);
  cornerstoneTools.toolStyle.setToolWidth(2);
  cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');
  cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');

  cornerstoneTools.store.state.touchProximity = 40;

  // IMAGE LOADER
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        strict: false,
      },
    },
  });
  cornerstoneNIFTIImageLoader.external.cornerstone = cornerstone;

  // Debug
  window.cornerstone = cornerstone;
  window.cornerstoneTools = cornerstoneTools

  const wwwcTool = cornerstoneTools.WwwcTool;
  cornerstoneTools.addTool(wwwcTool);
  cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: 1});
  const dragProbeTool = cornerstoneTools.DragProbeTool;
  cornerstoneTools.addTool(dragProbeTool); // 추가만 해놓고 활성화(active)는 안함
  const zoomTool = cornerstoneTools.ZoomTool;
  cornerstoneTools.addTool(zoomTool);
  cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2}); // 우클릭은 확대, 축소
  const panTool = cornerstoneTools.PanTool;
  cornerstoneTools.addTool(panTool);
  cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4}); // 휠클릭은 panning
  const zoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
  cornerstoneTools.addTool(zoomMouseWheelTool);
  cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1}); // 휠인데 mouseButtonMask 는 뭐지?
  cornerstoneTools.addTool(myOrientationTool, {configuration: { drawAllMarkers: true }});
  cornerstoneTools.setToolActive('myOrientation')
  const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
  cornerstoneTools.addTool(StackScrollMouseWheelTool)
  cornerstoneTools.setToolActive('StackScrollMouseWheel', {
    configuration: {
      // loop: true
      allowSkipping: false
    }
  })


  // Enable logging
  // cornerstoneTools.enableLogger();
  // // defaults to "cornerstoneTools:*" which will print all logs from the cornerstoneTools package
  // // This is just some sugar ontop of the debug library. You can enable all
  // // debug logging – including things outside of cornerstoneTools
  // cornerstoneTools.enableLogger('*');
  // // Only show logs from setToolMode.js
  // cornerstoneTools.enableLogger('cornerstoneTools:store:setToolMode');
  // // Show all logs, but exclude the eventDispatchers (which can be a bit noisy)
  // cornerstoneTools.enableLogger(
  //   'cornerstoneTools:*,-cornerstoneTools:eventDispatchers:*'
  // );

  localStorage.setItem('debug', 'cornerstoneTools:*');
}
