import { Col, Modal, Row, Table, Tree, Card, Typography, Radio, Select, Switch, InputNumber, Input, Space, Button, Tooltip, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { clearDownloadTargets } from "../redux/modules/analysis";
import { useCallback, useEffect, useRef, useState } from "react";
import ModelName from "./modelName";
import { uid } from "../lib/UtilDicomParse";
import { downloadResults } from "../redux/modules/longTask";
import { RollbackOutlined, CaretLeftOutlined, CaretRightOutlined, CloseCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import './downloadResultPathSettingModal.css'
import { inputFormatter } from "../lib/inputFormatter";
const { DirectoryTree } =Tree

const STRUCTURE_KEYS = Object.freeze({DEPTH: 'Depth', PATIENT: 'Patient', STUDY: 'Study', FILE: 'File'})
const PATIENT_INFO = Object.freeze({ID: 'pid', NAME: 'name', ALL: 'all', CUSTOM: 'custom'})
const STUDY_INFO = Object.freeze({ID: 'sid', DESC: 'desc', DATE: 'date', ALL: 'all'})
const NIFTI_INFO = Object.freeze({
  PATIENT_ID: 'pid',
  PATIENT_NAME: 'pname',
  STUDY_ID: 'sid',
  STUDY_DESC: 'desc',
  STUDY_DATE: 'studyDate',
  TASK_ORDER: 'order',
  TASK_NAME_SHORT: 'taskNameShort',
  TASK_NAME: 'taskName',
  IMAGETYPE_SHORT: 'short',
  IMAGETYPE_NAME: 'typeName',
  CUSTOM: 'custom',
  ALIAS: 'alias'
})

const CONTROLLER_NAME = Object.freeze({
  TYPE: 'Type',
  START_INDEX: 'Start index', 
  ZERO_PAD: 'Zero pad', 
  CUSTOM_INPUT: 'Custom input', 
  SERIAL_NUMBER: 'Serial number', 
  INDEX_LOCATION: 'Index location', 
  OPTION: 'Option',
  LEVEL: 'Level'
})

const CONTROLLER_TYPE = Object.freeze({
  SWITCH: 'switch', 
  RADIO:'radio', 
  INPUT:'input', 
  INPUT_NUMBER: 'input number', 
  SELECT: 'select'
})

const ERROR_TYPE = Object.freeze({
  EMPTY: 'empty', 
  UNACCEPTABLE: 'unacceptable', 
  DUPLICATE: 'duplicate', 
  TOO_LONG:'too_long'
})

const DATA_TYPE = Object.freeze({PATIENT:'patient', STUDY: 'study', FILE: 'file'})
const INDEX_LOCATION = Object.freeze({PREFIX: 0, SUFFIX: 1})
const DEPTH_VALUE = Object.freeze({FLAT: 'Flat', PATIENT: 'Patient', STUDY: 'Study', FILE: "File", MANUAL: 'Manual', HIERARCHY: 'Hierarchy'})
const DIRECTION = Object.freeze({RIGHT: 'right', LEFT: 'left'})

// extra에 label이 있으면 새로운 라인으로 label과 함께 옵션 생성
// extra에 label이 없으면 extra를 불러오는 옵션 오른편에 배치
const addIndex = {
  label: CONTROLLER_NAME.SERIAL_NUMBER,
  type: CONTROLLER_TYPE.SWITCH,
  selected: false,
  extra: [
    {
      trigger: true,
      type: CONTROLLER_TYPE.RADIO,
      name: CONTROLLER_NAME.INDEX_LOCATION,
      style: {marginLeft: 20},
      options: [
        {
          label: 'Prefix',
          value: INDEX_LOCATION.PREFIX
        },
        {
          label: 'Suffix',
          value: INDEX_LOCATION.SUFFIX
        }
      ],
      selected: INDEX_LOCATION.PREFIX
    }
  ]
}

const namingOptionStructures = {
  [STRUCTURE_KEYS.DEPTH]: {
    label: 'Folder structure',
    depth: {
      label: CONTROLLER_NAME.TYPE,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'Flat',
          value: DEPTH_VALUE.FLAT
        },
        {
          label: 'Hierarchical',
          value: DEPTH_VALUE.HIERARCHY
        },
      ],
      selected: DEPTH_VALUE.FLAT,
      extra: [
        {
          trigger: DEPTH_VALUE.HIERARCHY,
          label: CONTROLLER_NAME.LEVEL,
          name: CONTROLLER_NAME.LEVEL,
          type: CONTROLLER_TYPE.RADIO,
          options: [
            {
              label: 'Patient',
              value: DEPTH_VALUE.PATIENT
            },
            {
              label: 'Patient + Study',
              value: DEPTH_VALUE.STUDY
            }
          ],
          selected: DEPTH_VALUE.STUDY
        }
      ]
    }
  },
  [STRUCTURE_KEYS.PATIENT]: {
    label: 'Folder level - Patient',
    addIndex: {
      ...addIndex,
      extra: [
        ...addIndex.extra,
        {
          trigger: true,
          label: CONTROLLER_NAME.START_INDEX,
          name: CONTROLLER_NAME.START_INDEX,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 100000,
          selected: 1,
        },
        {
          trigger: true,
          label: CONTROLLER_NAME.ZERO_PAD,
          name: CONTROLLER_NAME.ZERO_PAD,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 10,
          selected: 1
        },
      ]
    },
    folderName: {
      label: CONTROLLER_NAME.OPTION,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'ID',
          value: PATIENT_INFO.ID
        },
        {
          label: 'Name',
          value: PATIENT_INFO.NAME
        },
        {
          label: 'All',
          value: PATIENT_INFO.ALL
        },
        {
          label: 'Custom',
          value: PATIENT_INFO.CUSTOM,
        }
      ],
      selected: PATIENT_INFO.ID,
      extra: [
        {
          trigger: PATIENT_INFO.CUSTOM,
          label: CONTROLLER_NAME.CUSTOM_INPUT,
          name: CONTROLLER_NAME.CUSTOM_INPUT,
          type: CONTROLLER_TYPE.INPUT,
          style : {width: 250},
          selected: '',
        }
      ]
    },
  },
  [STRUCTURE_KEYS.STUDY]: {
    label: 'Folder level - Study',
    addIndex,
    folderName: {
      label: CONTROLLER_NAME.OPTION,
      name: CONTROLLER_NAME.OPTION,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'ID',
          value: STUDY_INFO.ID
        },
        {
          label: 'Desc',
          value: STUDY_INFO.DESC
        },
        {
          label: 'Date',
          value: STUDY_INFO.DATE
        },
        {
          label: 'All',
          value: STUDY_INFO.ALL
        }
      ],
      selected: STUDY_INFO.ID
    },
  },
  [STRUCTURE_KEYS.FILE]: {
    label: 'File level',
    addIndex: {
      ...addIndex,
      extra: [
        ...addIndex.extra,
        {
          trigger: true,
          label: CONTROLLER_NAME.START_INDEX,
          name: CONTROLLER_NAME.START_INDEX,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 100000,
          selected: 1,
        },
        {
          trigger: true,
          label: CONTROLLER_NAME.ZERO_PAD,
          name: CONTROLLER_NAME.ZERO_PAD,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 10,
          selected: 1
        },
      ]
    },
    fileName: {
      label: CONTROLLER_NAME.OPTION,
      type: CONTROLLER_TYPE.SELECT,
      style: {width: 250},
      options: [
        {
          label: 'Patient ID',
          value: NIFTI_INFO.PATIENT_ID
        },
        {
          label: 'Patient Name',
          value: NIFTI_INFO.PATIENT_NAME
        },
        {
          label: 'Study ID',
          value: NIFTI_INFO.STUDY_ID
        },
        {
          label: 'Study Desc',
          value: NIFTI_INFO.STUDY_DESC
        },
        {
          label: 'Study Date',
          value: NIFTI_INFO.STUDY_DATE
        },
        {
          label: 'Task order',
          value: NIFTI_INFO.TASK_ORDER
        },
        {
          label: 'Task name short',
          value: NIFTI_INFO.TASK_NAME_SHORT
        },
        {
          label: 'Task name',
          value: NIFTI_INFO.TASK_NAME
        },
        {
          label: 'Imagetype short',
          value: NIFTI_INFO.IMAGETYPE_SHORT
        },
        {
          label: 'Imagetype name',
          value: NIFTI_INFO.IMAGETYPE_NAME
        },
        {
          label: 'Custom',
          value: NIFTI_INFO.CUSTOM
        }
      ],
      selected: [NIFTI_INFO.PATIENT_ID, NIFTI_INFO.STUDY_DATE, NIFTI_INFO.TASK_NAME_SHORT, NIFTI_INFO.IMAGETYPE_SHORT],
      extra: [
        {
          trigger: {
            condition: 'in',
            value: 'custom'
          },
          label: CONTROLLER_NAME.CUSTOM_INPUT,
          name: CONTROLLER_NAME.CUSTOM_INPUT,
          type: CONTROLLER_TYPE.INPUT,
          style : {width: 250},
          max: 255,
          selected: '',
        }
      ]
    }
  }
}

const FIRST_DOT_ERROR_MSG = 'First . is not allowed'

const getTaskNameShort = taskName => {
  // short이 undefined인 경우는 줄이지 않아도 되는 것들
  const taskNames = [
    {
      "name": "preparation",
      "short": "prep"
    },
    {
      "name": "co-registration (longitudinal)",
      "short": "coreg-long"
    },
    {
      "name": "flip orientation",
      "short": "flip"
    },
    {
      "name": "intensity normalization",
      "short": "norm"
    },
    {
      "name": "intensity normalization (NAWM)",
      "short": "norm-NAWM"
    },
    {
      "name": "bias field correction (N4)",
      "short": "correct"
    },
    {
      "name": "voxel data collection",
      "short": "collect"
    },
    {
      "name": "resample",
      "short": "rs"
    },
    {
      "name": "nifti orientation",
      "short": "reorient"
    },
    {
      "name": "brain extraction",
      "short": "bet"
    },
    {
      "name": "co-registration & resample",
      "short": "coreg"
    },
    {
      "name": "model training (U-Net based segmentation)",
      "short": "train(unet)"
    },
    {
      "name": "model training (clustering)",
      "short": "train(cluter)"
    },
    {
      "name": "model prediction",
      "short": "modelpred"
    },
    {
      "name": "morphological operations",
      "short": "morph"
    },
    {
      "name": "generate mask",
      "short": "mask"
    },
    {
      "name": "voxel-wise arithmetic",
      "short": "arith"
    },
    {
      "name": "brain tumor segmentation-v1",
      "short": "tumor-seg"
    },
    {
      "name": "brain tumor voxel clustering-v1",
      "short": "habitat"
    },
    {
      "name": "DL-DiReCT-v0",
      "short": undefined,
    },
    {
      "name": "DL-DiReCT-v6",
      "short": undefined
    },
    {
      "name": "DL-DiReCT-v7",
      "short": undefined
    },
    {
      "name": "brain metastasis-v1",
      "short": "meta-seg"
    },
    {
      "name": "brain metastasis (t1ce only)-v1",
      "short": "meta-t1ce-seg"
    },
    {
      "name": "Diffusion",
      "short": "cmn-dwi"
    },
    {
      "name": "DSC Perfusion",
      "short": "cmn-dsc"
    },
    {
      "name": "T1 Perfusion",
      "short": "cmn-dce"
    },
    {
      "name": "CT Perfusion",
      "short": "cmn-ctp"
    },
    {
      "name": "MR Virtual Expert",
      "short": "cmn-stroke-mr"
    },
    {
      "name": "MR Virtual Expert Basic",
      "short": "cmn-stroke-mr-basic"
    },
    {
      "name": "CT Virtual Expert",
      "short": "cmn-stroke-ct"
    },
    {
      "name": "CT Virtual Expert Basic",
      "short": "cmn-stroke-ct-basic"
    }
  ]

  const targetTaskNameInfo = taskNames.find(nameInfo => nameInfo.name === taskName)

  return targetTaskNameInfo === undefined ? taskName : (targetTaskNameInfo.short || targetTaskNameInfo.name)
}

const DownloadResultPathSettingModal = () => {
  const dispatch = useDispatch()
  const fileList = useSelector(state => state.analysis?.downloadTargets.file_list)
  const patientsInStore = useSelector(state => state.analysis?.downloadTargets.patients)
  const taskTemplates = useSelector(state => state.analysis?.downloadTargets.templates)
  const tasksInStore = useSelector(state => state.analysis?.downloadTargets.tasks)
  const imagetypeInStore = useSelector(state => state.analysis?.downloadTargets.blobtypes)
  const [patients, setPatients] = useState([])
  const [tasks, setTasks] = useState([])
  const [options, setOptions] = useState(JSON.parse(JSON.stringify(namingOptionStructures)))
  const initialScrollToInfo = {key: undefined, index: 0, structureKey: undefined, type: undefined}
  const [scrollToInfo, setScrollToInfo] = useState(initialScrollToInfo)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [taskSelectedRowKeys, setTaskSelectedRowKeys] = useState([])
  const [caseSelectedRowKeys, setCaseSelectedRowKeys] = useState([])
  const [zipFileInfo, setZipFileInfo] = useState({value: ' ', errorMsg: [], initValue: ''})
  const [viewMode, setViewMode] = useState({value: DEPTH_VALUE.FILE})
  const [expandedKeys, setExpandedKeys] = useState([])
  const [replaceChars, setReplaceChars] = useState({})
  const ROW_SELECTABLE = false
  const ERROR_TEXT_COLOR = 'rgb(180,180,180)'
  const [checkHoverTaskTable, setCheckHoverTaskTable] = useState()

  const rowSelection = (selectedRowKeys, setSelectedRowKeys) => ({
    selectedRowKeys,
    onSelect : (record, selected, _, e) => {
      if (record.disabled) return
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.key])
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
      }
    },
    onSelectAll : (selected, _, changeRows) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, ...changeRows.map(row => row.key)])
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => !changeRows.map(row => row.key).includes(key)))
      }
    },
    getCheckboxProps : (record) => ({
      disabled : record.disabled,
    }),
    renderCell : (checked, record, index, originNode) => {
      if (record.disabled) {
        return null
      } else {
        return originNode
      }  
    }
  })

  useEffect(() => {
    let tasks = []
    if (tasksInStore) {
      const preparationTemplate =  taskTemplates.find(template => template.name === 'preparation')
      const modelPredTemplate =  taskTemplates.find(template => template.name === 'model prediction')
      tasks = [
        {
          order: '-',
          task_template_id: preparationTemplate.id,
          disabled: true,
        },
        ...tasksInStore.map(task => {
          return {
            ...task,
            key: task.id
          }
        })
      ].map(task => {
        const templateId = task.task_template_id
        const targetTemplate = taskTemplates.find(template => template.id === templateId)
        const taskNameShort = getTaskNameShort(targetTemplate === modelPredTemplate ? getTemplateName(task) : targetTemplate.name)
        return {
          ...task,
          short: taskNameShort,
          shortOriginal: taskNameShort
        }
      })
      setTasks(tasks)
      setTaskSelectedRowKeys(tasks.filter(task => task.key).map(task => task.key))
    }
    
    const getTaskInfo = parsedTaskId => {
      const task = tasks?.find(task => task.id === parseInt(parsedTaskId))
      const taskId = task?.id
      const order = task?.order 
      const taskName = getTemplateName(task)
      const short = task?.short
      return {taskId, order, taskName, short}
    }

    const getImageTypeInfo = parsedTypeId => {
      const imagetype = imagetypeInStore.find(type => type.id === parseInt(parsedTypeId))
      const typeName = imagetype.name
      const short = imagetype.short
      return {typeName, short}
    }

    if (patientsInStore) {
      const copiedPatient = JSON.parse(JSON.stringify(patientsInStore))
      const copiedFileList = JSON.parse(JSON.stringify(fileList))
      let studyKeys = []
      const initPatients = copiedPatient.map(patient => {
        const patientKey = uid()
        return {
          ...patient,
          key: patientKey,
          folderName: `${patient.pid}`,
          all: `${patient.pid}-${patient.name}`,
          custom: '',
          type: DATA_TYPE.PATIENT,
          studies: patient.studies.map(study => {
            const studyKey = uid()
            studyKeys.push(studyKey)
            const studyDate = study.date.split(' ')[0].split('-').join('')
            const studyDateWithHyphen = study.date.split(' ')[0]
            return {
              ...study,
              key: studyKey,
              parentKeys: [patientKey],
              all: `${study.sid}-${studyDate}-${study.desc}`,
              type: DATA_TYPE.STUDY,
              date: studyDate,
              hyphenDate: studyDateWithHyphen,
              fileNames: copiedFileList.filter(fileInfo => {
                const {pid, sid, taskId} = parseFilename(fileInfo.fname)
                if (pid === `${patient.id}` && sid === `${study.id}` && taskId) {
                  return true
                }
                return false
              }).map(fileInfo => {
                const {blobtypeId: parsedBlobtypeId, taskId: parsedTaskId, alias} = parseFilename(fileInfo.fname)
                
                const fileKey = uid()
                const currFilename = fileInfo.fname.split('.')[0]
                const extension = fileInfo.fname.split(currFilename)[1]
                const parentKeys = [patientKey, studyKey]
                const pid = patient.pid
                const pname = patient.name
                const sid = study.sid
                const desc = study.desc
                const {taskId, order, taskName, short: taskNameShort} = getTaskInfo(parsedTaskId)

                const {typeName, short} = parsedBlobtypeId === 'vox' ? {typeName: 'voxel', short: 'vox'} : getImageTypeInfo(parsedBlobtypeId)

                const dataInfo = {
                  [NIFTI_INFO.PATIENT_ID]: pid,
                  [NIFTI_INFO.PATIENT_NAME]: pname,
                  [NIFTI_INFO.STUDY_ID]: sid,
                  [NIFTI_INFO.STUDY_DESC]: desc,
                  [NIFTI_INFO.STUDY_DATE]: studyDate,
                  [NIFTI_INFO.TASK_NAME_SHORT]: taskNameShort,
                  [NIFTI_INFO.TASK_ORDER]: order,
                  [NIFTI_INFO.TASK_NAME]: taskName,
                  [NIFTI_INFO.IMAGETYPE_SHORT]: short,
                  [NIFTI_INFO.IMAGETYPE_NAME]: typeName,
                  [NIFTI_INFO.ALIAS]: alias,
                  ext: extension,
                  taskId
                }

                return {
                  ...fileInfo,
                  key: fileKey,
                  parentKeys,
                  type: DATA_TYPE.FILE,
                  ...dataInfo
                }
              }).sort((a, b) => a.order - b.order)
            }
          })
        }
      })
      setPatients(initPatients)
      setCaseSelectedRowKeys(studyKeys)
    }
  },[patientsInStore, tasksInStore])

  useEffect(() => {
    setScrollToInfo(initialScrollToInfo)
  },[options])

  const getTemplateName = task => {
    const taskTemplateId = task.task_template_id
    const targetTemplate = taskTemplates.find(template => template.id === taskTemplateId)
    if (targetTemplate.name === "model prediction") {
      const modelName = task.config?.model.join('-')
      return modelName
    
    }
    return targetTemplate?.name
  }

  const treeRef = useRef()
  
  const spaceSpan = 0
  const taskSpan = 10
  const caseSpan = 24 - taskSpan - spaceSpan

  const optionSelectSpan = 10
  const previewSpan = 24 - optionSelectSpan - spaceSpan

  const checkPossiblePath = (title, extension=undefined, fistDotCheck=true) => {
    const checkRegex = /[\\/:*?"<>|]/g;
    const invalidChars = title.match(checkRegex) || []

    if (title[0] === '.' && fistDotCheck) {
      if (extension) {
        const fileName = title.split(extension)[0]
        if (fileName[0] === '.') {
          invalidChars.unshift(FIRST_DOT_ERROR_MSG)
        }
      }
      else {
        invalidChars.unshift(FIRST_DOT_ERROR_MSG)
      }
    }
    return invalidChars
  }

  const checkEmptyString = title => {
    const regex = /^\s*$/
    return regex.test(title)
  }

  const checkDuplicatePath = (target, dataList, titleKey) => {
    const targetFolderName = target[titleKey]
    const othersFolderName = dataList.filter(data => data.key !== target.key).map(data => data[titleKey])
    if (othersFolderName.includes(targetFolderName)) {
      return true
    }
    return false
  }

  const taskColumns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 60,
      align : 'center',
      render: (value, record) => {
        const renderData = value > 0 ? value : '-'
        return renderData
      }
    },
    {
      title: 'Task name',
      dataIndex: 'task_template_id',
      key: 'name',
      render : (template_id, record) => {
        const selectTemplate = taskTemplates.find(template => template.id === parseInt(template_id))
        if (selectTemplate?.name === 'model prediction') {
          const modelName = record.config?.model
          return (
            <>
              <p style={{margin : 0}}>{selectTemplate?.name}</p>
              <ModelName name={modelName}/>
            </>
          )}
        return selectTemplate?.name
      }
    },
    {
      title: 'Shorthand',
      dataIndex: 'short',
      key: 'short',
      render: (short, record) => {
        const onChange = e => {
          e.stopPropagation()
          record.short = e.target.value
          setTasks([...tasks])
        }
        const onBlur = e => {
          setTimeout(() => {
            e.stopPropagation()
            record.edit = false
            setTasks([...tasks])
          },100)
        }

        const onEditMode = e => {
          e.stopPropagation()
          record.edit = true
          setTasks([...tasks])
          setTimeout(() => {
            const inputElement = document.getElementById(`input-${record.key}`);
            if (inputElement) {
              inputElement.focus();
            }
          }, 0)
        }

        const onResetShortName = e => {
          e.stopPropagation()
          const originalValue = record.shortOriginal
          record.short = originalValue
          setTasks([...tasks])
        }

        const onMouseHover = (record, status) => e => {
          e.stopPropagation()
          if (status === 'enter') {
            setCheckHoverTaskTable(record.key)
          }
          else {
            setCheckHoverTaskTable()
          }
        }

        const onHover = checkHoverTaskTable === record.key && !record.edit
        
        return record.key === undefined ?
          short : 
          <div
            onClick={onEditMode}
            style={{
              cursor: 'pointer',
              border: onHover && '1px solid rgba(255,255,255,0.4)',
              borderRadius: onHover && '2px',
              padding: '2px',
            }}
            onMouseEnter={onMouseHover(record, 'enter')}
            onMouseLeave={onMouseHover(record, 'leave')}
          >
            {
              record.edit ? 
                <Input
                  id={`input-${record.key}`}
                  value={short}
                  onChange={onChange}
                  onClick={e => e.stopPropagation()}
                  onBlur={onBlur}
                  onPressEnter={onBlur}
                  suffix={
                    <Tooltip title={'Reset'}>
                      <RollbackOutlined onClick={onResetShortName} />
                    </Tooltip>
                  }
                /> :
                short
            }
          </div>
      }
    },
  ]

  const caseColumns = [
    {
      title: 'Patient',
      children: [
        {
          title : 'ID',
          align: 'center',
          dataIndex : 'pid',
          key : 'pid',
        },
        {
          title: 'Name',
          align: 'center',
          dataIndex: 'name',
          key: 'name',
        },
      ]
    },
    {
      title: 'Study',
      children: [
        {
          title: 'ID',
          align: 'center',
          dataIndex: 'sid',
          key: 'sid',
        },
        {
          title: 'Desc',
          align: 'center',
          dataIndex: 'desc',
          key: 'desc',
        },
        {
          title: 'Date',
          align: 'center',
          dataIndex: 'date',
          key: 'date',
        },
      ]
    }
  ] // longitudinal mode 에서는 study 관련 컬럼 제거
  // .filter(column => mode === MODE.MULTI ? !column.title.includes('Study') : true)
  const caseList = patients.map(patient => {
    return patient.studies.map(study => {
      return {
        key: study.key,
        pid: patient.pid,
        name: patient.name,
        sid: study.sid,
        desc: study.desc,
        date: study.hyphenDate
      }
    })
  }).flat()
  const getDepth = () => {
    if (options[STRUCTURE_KEYS.DEPTH].depth.selected === DEPTH_VALUE.HIERARCHY) {
      return options[STRUCTURE_KEYS.DEPTH].depth.extra.find(extra => extra.name === CONTROLLER_NAME.LEVEL).selected
    }
    return options[STRUCTURE_KEYS.DEPTH].depth.selected
  }
  const depth = getDepth()
  const maxLength = 255

  const makeTreeData = useCallback(() => {
    // tree data 용 함수
    const getTitle = (title, addIndex, indexLocation, index) => {
      if (!addIndex) return title
      if (indexLocation === INDEX_LOCATION.PREFIX) return `${index}_${title}`
      return `${title}_${index}`
    }
  
    const getData = (structureKey, data, index, key=undefined) => {
      const addIndex = options[structureKey].addIndex.selected
      const indexLocation = options[structureKey].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.INDEX_LOCATION)?.selected
      const title = getTitle(data.selectedValue, addIndex, indexLocation, index)
      const newKey = key ? `${key}_${data.id}` : `${data.id}`
      return {title, key: newKey}
    }
  
    const TitleElement = ({title, invalid=false, errorMsg, target, structureKey, style}) => {
      const inputRef = useRef(null)
      
      useEffect(() => {
        if (selectedKeys.includes(target?.key)) {
          setTimeout(() => inputRef.current?.focus({cursor: 'end'}), 10)
          if (target.key === scrollToInfo.key) {
            setScrollToInfo(initialScrollToInfo)
          }
        }
      },[selectedKeys])
  
      if (selectedKeys.includes(target?.key)) { // edit mode
        const selectedOptionValue = target.selectedOptionValue
        
        if (target[`original_${selectedOptionValue}`] === undefined) { // save original value
          target[`original_${selectedOptionValue}`] = target[selectedOptionValue]
        }
        
        const onChange = e => target[selectedOptionValue] = e.target.value
        
        const onPressEnter = () => setSelectedKeys([])
  
        const onReset = () => {
          const originalValue = target[`original_${selectedOptionValue}`]
          target[selectedOptionValue] = originalValue
          setSelectedKeys([])
        }
  
        return (
          <Input 
            showCount={inputFormatter}
            // index 및 확장자에 의해 최대 길이가 줄어듦
            // title: 현재 보이는 값 (index + 입력되어있는 title + 확장자)
            // target[selectedOptionValue] 입력되어있는 값
            maxLength={maxLength - (title.length - target[selectedOptionValue].length)}
            ref={inputRef} 
            size="small" 
            onClick={e => e.stopPropagation()}
            onChange={onChange} 
            onPressEnter={onPressEnter} 
            defaultValue={target[selectedOptionValue]} 
            style={{width: 300}}
            suffix={<RollbackOutlined onClick={onReset} />}
          />
        )
      }
      const searchedKey = scrollToInfo?.key === target?.key && scrollToInfo?.key
      const alpha = 0.2
      const errorBackgroundColor = ERROR_TEXT_COLOR?.replace('rgb', 'rgba').replace(')', `,${alpha})`);
  
  
      const titleStyle = {display: 'inline', margin:0, padding: 0, color: invalid && '#faad14', ...style, backgroundColor: searchedKey && errorBackgroundColor}
  
      const errorMsgRender = errorMsg.filter(msg => scrollToInfo.type === msg.type).map(data => <span style={{color: ERROR_TEXT_COLOR, marginRight: 4}}>{data.msg}</span>) || ""
      return ( // view mode
        <Typography.Title level={5} style={titleStyle}>
          {invalid ?
            <>{title}ㅤㅤ{searchedKey && errorMsgRender}</>:
            title
          }
        </Typography.Title>
      )
    }
  
    const getTitleElement = (structureKey, target, dataList, style, titleKey='folderName') => {
      const title = target[titleKey]
      const invalidChars = checkPossiblePath(title, target.ext)
      const duplicate = checkDuplicatePath(target, dataList, titleKey)
      const isEmptyString = checkEmptyString(title)
      
      let replacedTitle = title
      Object.entries(replaceChars).forEach(([invalidValue, replaceInfo]) => {
        const pattern = new RegExp(`\\${invalidValue}`, "g");
        replacedTitle = replacedTitle.replace(pattern, replaceInfo.value);
      })
      target.final = replacedTitle
      const tooLong = target.final.length > maxLength
      const notReplaceCharsLength = invalidChars.filter(char => replaceChars[char] === undefined).length
      
      const errorMsg = getErrorMsg({invalidChars, duplicate, isEmptyString, target, structureKey, tooLong})
      const invalid = notReplaceCharsLength || duplicate || isEmptyString || tooLong
      
      return (
        <TitleElement 
          title={replacedTitle} 
          invalid={invalid} 
          errorMsg={errorMsg} 
          target={target} 
          structureKey={structureKey} 
          style={style} 
          key={target.key}
        />
      )
    }
  
    const createObject = () => {
      const types = [ERROR_TYPE.EMPTY, ERROR_TYPE.UNACCEPTABLE, ERROR_TYPE.DUPLICATE, ERROR_TYPE.TOO_LONG]
      const item = {count: 0, keys: []}
      
      return types.map(type => ({
        type,
        color: ERROR_TEXT_COLOR,
        ...JSON.parse(JSON.stringify(item))
      }))
    }
  
    const errorObject = Object.fromEntries(
      Object.entries(STRUCTURE_KEYS).map(([key, value]) => 
        [value, {label: namingOptionStructures?.[value]?.label, list: createObject()}]) 
      )
    const notAllowedCharsArray = []
  
    const getErrorMsg = ({invalidChars, duplicate, isEmptyString, target, structureKey, tooLong}) => {
      let errorMsg = []
      if (isEmptyString) {
        const targetErrorObj = errorObject[structureKey].list.find(data => data.type === ERROR_TYPE.EMPTY)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg:`Empty string`, color: targetErrorObj.color, type:ERROR_TYPE.EMPTY})
      }
      if (invalidChars.length > 0) {
        const filteredInvalidChars = [...new Set(invalidChars)]
        const targetErrorObj = errorObject[structureKey].list.find(data => data.type === ERROR_TYPE.UNACCEPTABLE)
        notAllowedCharsArray.push(...invalidChars)
        const unChangedChars = filteredInvalidChars.filter(char => replaceChars?.[char] === undefined)
        if (unChangedChars.length > 0) {
          targetErrorObj.count++
          targetErrorObj.keys.push(target.key)
          errorMsg.push({msg: `Unacceptable character [ ${unChangedChars.join(', ')} ]`, color: targetErrorObj.color, type: ERROR_TYPE.UNACCEPTABLE})
        }
      }
      if (duplicate) {
        const targetErrorObj = errorObject[structureKey].list.find(data => data.type === ERROR_TYPE.DUPLICATE)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg: `Duplicate`, color: targetErrorObj.color, type: ERROR_TYPE.DUPLICATE})
      }
      if (tooLong) {
        const targetErrorObj = errorObject[structureKey].list.find(data => data.type === ERROR_TYPE.TOO_LONG)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg: `Too long`, color: targetErrorObj.color, type: ERROR_TYPE.TOO_LONG})
      }
  
      return errorMsg
    }

    // tree 제작 순서: 
    // 1. Structure depth 에 따른 구조 변경
      // - data filtering: task 및 case 선택에 따른 데이터 filtering
      // - data extracting: filtering 된 데이터에 대해서 depth 에 따른 데이터 구조 변경
    // 2. 데이터 조작
    // 3. tree 제작
    
    // 구조 추출
    // check visible
    patients.forEach(patient => {
      const patientVisible = patient.studies.some(study => caseSelectedRowKeys.includes(study.key))  
      patient.visible = patientVisible
      patient.studies.forEach(study => {
        const studyVisible = caseSelectedRowKeys.includes(study.key)
        study.visible = studyVisible
        study.fileNames.forEach(fileInfo => {
          const taskId = fileInfo.taskId
          const fileVisible = studyVisible && taskSelectedRowKeys.includes(parseInt(taskId))
          fileInfo.visible = fileVisible
        })
      })
    })
    
    
    // data filtering
    const filteredData = patients.filter(patient => {
      patient.children = patient.studies.filter(study => {
        study.children = study.fileNames.filter(fileInfo => fileInfo.visible)
        return study.children.length === 0 ? false : study.visible
      })
      return patient.children.length === 0? false : patient.visible 
    })
    
    // data extracting
    const extractedData = []
    filteredData.forEach(patient => {
      if (depth === DEPTH_VALUE.PATIENT) {
        const children = patient.children.map(study => {
          return study.children
        }).flat()
        patient.children = children
        
        extractedData.push(patient)
      } 
      if (depth === DEPTH_VALUE.STUDY) {
        extractedData.push(patient)
      }
      if (depth === DEPTH_VALUE.FLAT) {
        patient.children.forEach(study => {
          study.children.forEach(fileInfo => {
            extractedData.push(fileInfo)
          })
        })
      }
    })

    // 옵션에 해당하는 값들 부여
    const recursiveChangeData = (row, rowIndex) => {
      if (row.type === DATA_TYPE.PATIENT) {
        const customValue = options[STRUCTURE_KEYS.PATIENT].folderName.extra.find(extra => extra.name === CONTROLLER_NAME.CUSTOM_INPUT).selected
        if (row.custom !== customValue) {
          row.custom = customValue
        }
        const startIndex = options[STRUCTURE_KEYS.PATIENT].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.START_INDEX).selected
        const zeroPad = options[STRUCTURE_KEYS.PATIENT].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.ZERO_PAD).selected
        const index = `${rowIndex + startIndex}`.toString().padStart(zeroPad, '0')

        const selectedOptionValue = options[STRUCTURE_KEYS.PATIENT].folderName.selected

        row.selectedOptionValue = selectedOptionValue
        row.selectedValue = `${row[selectedOptionValue]}`

        const {title} = getData(STRUCTURE_KEYS.PATIENT, row, index)
        
        row.folderName = title
      }

      if (row.type === DATA_TYPE.STUDY) {
        const selectedOptionValue = options[STRUCTURE_KEYS.STUDY].folderName.selected
        row.selectedOptionValue = selectedOptionValue
        row.selectedValue = `${row[selectedOptionValue]}`

        const {title} = getData(STRUCTURE_KEYS.STUDY, row, rowIndex + 1)
        row.folderName = title
      }

      if (row.type === DATA_TYPE.FILE) {
        const startIndex = options[STRUCTURE_KEYS.FILE].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.START_INDEX).selected
        const zeroPad = options[STRUCTURE_KEYS.FILE].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.ZERO_PAD).selected
        const index = `${rowIndex + startIndex}`.toString().padStart(zeroPad, '0')

        const selectedOptionValue = options[STRUCTURE_KEYS.FILE].fileName.selected
        const combinationKey = selectedOptionValue.join('-')
        if (selectedOptionValue.includes(NIFTI_INFO.TASK_NAME_SHORT)) {
          const taskShortName = tasks.find(task => task.id === row.taskId).short
          if (taskShortName !== row[NIFTI_INFO.TASK_NAME_SHORT]) {
            row[NIFTI_INFO.TASK_NAME_SHORT] = taskShortName
          }
        }
        if (selectedOptionValue.includes(NIFTI_INFO.CUSTOM)) {
          const customValue = options[STRUCTURE_KEYS.FILE].fileName.extra.find(extra => extra.name === CONTROLLER_NAME.CUSTOM_INPUT).selected
          if (row.custom !== customValue) {
            row.custom = customValue
          }
          const filename = selectedOptionValue.map(key => row[key]).join('-')
          row[combinationKey] = filename
        }
        const filename = selectedOptionValue.map(key => row[key]).join('-')
        row[combinationKey] = filename
        
        row.selectedOptionValue = combinationKey
        const alias = row.alias ? `__(${row.alias})` : ''
        row.selectedValue = `${row[combinationKey]}${alias}`
        
        const {title} = getData(STRUCTURE_KEYS.FILE, row, index)
        row.fileName = `${title}${row.ext}`
      }
      if (row.children) {
        row.children = row.children.map((childRow, index) => recursiveChangeData(childRow, index))
      }
      return row
    }
    // 데이터에 옵션에 따른 값 부여
    const changedData = extractedData.map((row, index) => recursiveChangeData(row, index))
    
    const s3Keys = []

    const dataKeys = {
      [DEPTH_VALUE.PATIENT]: [],
      [DEPTH_VALUE.STUDY]: [],
      [DEPTH_VALUE.FILE]: []
    }
    let totalSize = 0

    // tree data 제작
    const recursiveGetTreeNodes = (row, rows) => {
      const notSelectableStyle = {backgroundColor: 'rgba(0,0,0,0)', cursor: 'default'}
      const notSelectableClassName = 'not-selectable'
      
      row.style = notSelectableStyle
      row.className = notSelectableClassName
      row.selectable = ROW_SELECTABLE
      
      if (row.type === DATA_TYPE.PATIENT) {
        dataKeys[DEPTH_VALUE.PATIENT].push(row.key)
        const titleElement = getTitleElement(STRUCTURE_KEYS.PATIENT, row, rows)
        row.title = titleElement
        row.isLeaf = false
      }
      if (row.type === DATA_TYPE.STUDY) {
        dataKeys[DEPTH_VALUE.STUDY].push(row.key)
        const titleElement = getTitleElement(STRUCTURE_KEYS.STUDY, row, rows)
        row.title = titleElement
        row.isLeaf = false
      }
      if (row.type === DATA_TYPE.FILE) {
        dataKeys[DEPTH_VALUE.FILE].push(row.key)
        s3Keys.push(row.s3key)
        totalSize += row.size
        const titleElement = getTitleElement(STRUCTURE_KEYS.FILE, row, rows, {}, 'fileName')
        row.title = titleElement
        row.isLeaf = true
      }
      if (row.children) {
        row.children = row.children.map(child => recursiveGetTreeNodes(child, row.children))
      }
      return row
    }

    const treeData = changedData.map(row => recursiveGetTreeNodes(row, changedData))
    
    return {treeData, s3Keys, totalSize, dataKeys, errorObject, notAllowedChars: [...new Set(notAllowedCharsArray)].filter(msg => msg !== FIRST_DOT_ERROR_MSG)}
  },[options, patients, selectedKeys, taskSelectedRowKeys, caseSelectedRowKeys, scrollToInfo, replaceChars, tasks])

  const { treeData, s3Keys, totalSize, dataKeys, errorObject, notAllowedChars } = makeTreeData()
  
  const selectedDepth = getDepth()
  
  const expandOptions = [
     {
      label: 'Patient',
      value: DEPTH_VALUE.PATIENT,
      visible: selectedDepth !== DEPTH_VALUE.FLAT,
      array: [],
    },
    {
      label: 'Patient + Study',
      value: DEPTH_VALUE.STUDY,
      visible: selectedDepth === DEPTH_VALUE.STUDY,
      array: dataKeys[DEPTH_VALUE.PATIENT]
    },
    {
      label: selectedDepth === DEPTH_VALUE.STUDY ? 'Patient + Study + File' : 'Patient + File',
      value: DEPTH_VALUE.FILE,
      visible: true,
      array: dataKeys[DEPTH_VALUE.FILE]
    },
    {
      label: 'Manual',
      value: DEPTH_VALUE.MANUAL,
      visible: selectedDepth !== DEPTH_VALUE.FLAT,
      array: expandedKeys
    }
  ]

  useEffect(() => {
    if (dataKeys[DEPTH_VALUE.STUDY].length > 0) {
      setViewPoint(DEPTH_VALUE.FILE)
    }
  },[patients])


  const onSelectExpand = e => {
    const value = e.target.value
    setViewPoint(value, true)
  }

  const zipFileNameOnChange = e => {
    const value = e.target.value
    zipFileInfo.value = value
    
    const errorMsg = []
    const invalidChars = checkPossiblePath(value)
    if (invalidChars.length > 0) {
      errorMsg.push(`Not allowed Characters[ ${[...new Set(invalidChars)].join(', ')} ]`)
    }
    const isEmptyString = checkEmptyString(value)
    if (isEmptyString) {
      errorMsg.push(`Not allowed empty string`)
    }
    zipFileInfo.errorMsg = errorMsg

    setZipFileInfo({...zipFileInfo})
  }

  const getDateNow = (full=true) => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    if (full) {
      return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const initialValue = `${getDateNow(false)}`
    zipFileInfo.value = initialValue
    zipFileInfo.initValue = initialValue
    setZipFileInfo({...zipFileInfo})
  },[])

  const onTreeItemSelect = (currSelectedKeys, _) => {
    const copiedSelectedKeys = [...selectedKeys]
    if (copiedSelectedKeys.includes(currSelectedKeys[0])) {
      setSelectedKeys([])
    }
    else {
      setSelectedKeys(currSelectedKeys)
    }
  }

  const onDirectClick = (direction, data, structureKey) => () => {
    let currIndex = scrollToInfo.index
    
    if (scrollToInfo.type === data.type && scrollToInfo.structureKey === structureKey) {
      if (direction === DIRECTION.RIGHT) {
        currIndex++
      }
      else {
        currIndex--
      }
      if (currIndex < 0) {
        currIndex = data.keys.length - 1
      }
      else if (currIndex >= data.keys.length) {
        currIndex = 0
      }
      scrollToInfo.index = currIndex
    }
    else {
      currIndex = 0
      scrollToInfo.type = data.type
      scrollToInfo.index = currIndex
    }
    scrollToInfo.structureKey = structureKey
    scrollToInfo.key = data.keys[currIndex]
    setScrollToInfo({...scrollToInfo})
    
    setViewPoint(structureKey)
    
    setTimeout(() => treeRef.current.scrollTo({ key: scrollToInfo.key, align: 'top', offset: 100}), 100)
  }

  const setViewPoint = (mode, force=false) => {
    const values = expandOptions.map(option => option.value)
    if (!values.includes(mode)) {
      return setViewMode({value: DEPTH_VALUE.FILE})
    }
    if (force) {
      return setViewMode({value: mode})
    }
    if (viewMode.value !== DEPTH_VALUE.MANUAL && depth !== DEPTH_VALUE.FLAT) {
      return setViewMode({value: mode})
    }
  }
  
  useEffect(() => {
    const viewPoint = expandOptions.find(option => option.value === viewMode.value) || 
      expandOptions.find(option => option.value === DATA_TYPE.FILE)
    setExpandedKeys(viewPoint.array)
  },[viewMode])

  const blankTreeData = [
    {
      title: <Typography.Title level={4} style={{color: 'rgb(220,100,100)', display: 'inline', marginLeft: 10}}>No Data</Typography.Title>,
      icon: <CloseCircleOutlined style={{color: 'rgb(220,100,100)', fontSize: '24px'}} />,
      key: uid()
    }
  ]

  const footer = () => {
    const onCancel = () => dispatch(clearDownloadTargets())
    const onOk = () => {
      const payload = {treeData, s3Keys, zipName: zipFileInfo.value, totalSize}
      
      // flat 상태에서는 tree data collapse 불가능 하므로 progress card에서 collapse가 가능하도록 folder명으로 한번 감싸줌
      if (selectedDepth === DEPTH_VALUE.FLAT) { 
        const folderKey = uid()
        const changedTreeData = [
          {
            folderName: zipFileInfo.value,
            key: folderKey,
            children: treeData.map(data => ({
              ...data,
              parentKeys: [folderKey]
            })),
          }
        ]
        payload.treeData = changedTreeData
      }
      
      dispatch(downloadResults(payload))
      dispatch(clearDownloadTargets())
    }
    const existInvalidFolderName = Object.values(errorObject).some(errorData => {
      return errorData.list.some(data => data.count > 0)
    })
    const notAllowedCharsReplacements = Object.entries(replaceChars).filter(([key, value]) => {
      return value.errorMsg?.length > 0
    })
    const notExistDownloadData = s3Keys.length === 0
    const okDisabled = existInvalidFolderName || notExistDownloadData || zipFileInfo.errorMsg.length > 0 || notAllowedCharsReplacements?.length > 0
    const okTooltipTitle = 
      (notExistDownloadData && 'No data to download') || 
      ((existInvalidFolderName || notAllowedCharsReplacements) && 'Fix errors first') || 
      (zipFileInfo.errorMsg.length > 0 && 'Change zip file name')
    
    return (
      <div>
        <div style={{marginBottom: 14}}>
          <Input
            value={zipFileInfo.value} 
            onChange={zipFileNameOnChange} 
            status={zipFileInfo.errorMsg.length > 0 && 'error'}
            style={{width: 250, textAlign: 'right'}}
            suffix=".zip"
            addonAfter={<Tooltip title={'Reset'}><RollbackOutlined onClick={onZipNameReset} /></Tooltip>}
            maxLength={255-".zip".length}
          />
          {zipFileInfo.errorMsg.map(msg => {
            return <div style={{color: 'rgba(255,64,64)'}}>{msg}</div>
          })}
        </div>
        <Space>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Tooltip title={okTooltipTitle} open={okDisabled ? undefined : false}>
            <Button type="primary" disabled={okDisabled} onClick={onOk}>
              OK
            </Button>
          </Tooltip>
        </Space>
      </div>
    )
  }
  
  const onZipNameReset = () => {
    const data = {
      ...zipFileInfo,
      value: zipFileInfo.initValue,
      errorMsg: []
    }

    setZipFileInfo(data)
  }

  const STEP_OPTION = Object.freeze({SELECT_DATA: 0, SELECT_OPTIONS: 1})
  const [currStep, setCurrStep] = useState(STEP_OPTION.SELECT_DATA)
  const onChangeStep = value => setCurrStep(value)

  const onTreeExpand = (currExpandedKeys, {expanded, node}) => {
    console.log(currExpandedKeys, {expanded, node});
    const keys = []

    const getChildrenKeys = (node, keys)  => {
      keys.push(node.key)
      if (node.children) {
        node.children.forEach(child => {
          getChildrenKeys(child, keys)
        })
      }
      return keys
    }

    if (expanded) {
      setExpandedKeys(currExpandedKeys)
    } 
    else {
      const relativeKeys = [node.key, ...getChildrenKeys(node, keys)]
      const filteredExpandedKeys = currExpandedKeys.filter(key => !relativeKeys.includes(key))
      setExpandedKeys(filteredExpandedKeys)
    }
  }

  const onTableRow = (selectedRowKeys, setSelectedRowKeys) => (record, index) => {
    return {
      onClick: () => {
        const targetRowSelection = rowSelection(selectedRowKeys, setSelectedRowKeys)
        const targetKey = record.key
        const selected = !selectedRowKeys.includes(targetKey)
        targetRowSelection.onSelect(record, selected)
      }
    }
  }

  return (
    <Modal
      open={fileList.length > 0}
      footer={footer()}
      width={1400}
      closable={false}
      title={'Download'}
      style={{padding: 0, top: 30, paddingBottom: 20}}
    >
      <div style={{minHeight: '60vh'}}>
        <Steps
          type="navigation"
          onChange={onChangeStep}
          current={currStep}
          style={{marginBottom: 20}}
          items={[
            {
              title: 'Select target',
              status: 'process'
            },
            {
              title: 'Naming rule customization',
              status: 'process'
            }
          ]}
        />
        <>
          {currStep === STEP_OPTION.SELECT_DATA &&
          <Row gutter={[8, 8]}>
            <Col span={taskSpan} style={{paddingRight: 24}}>
              <Space style={{marginBottom: 10}}>
                <Typography.Title level={5} style={{display: 'inline'}}>
                  Task
                </Typography.Title>
                <span style={{color: taskSelectedRowKeys.length === 0 && 'rgb(220,100,100)', marginLeft: 14}}>
                  {`[ ${taskSelectedRowKeys.length} / ${tasks.length - 1} ]`}
                </span>
              </Space>
              <div style={{maxHeight: 600, overflowY: 'auto'}}>
                <Table
                  bordered
                  columns={taskColumns}
                  dataSource={tasks}
                  size='small'
                  pagination={false}
                  rowSelection={rowSelection(taskSelectedRowKeys, setTaskSelectedRowKeys)}
                  onRow={onTableRow(taskSelectedRowKeys, setTaskSelectedRowKeys)}
                />
              </div>
            </Col>
            <Col span={caseSpan}>
              <Space style={{marginBottom: 10}}>
                <Typography.Title level={5} style={{display: 'inline'}}>
                  Case
                </Typography.Title>
                <span style={{color: caseSelectedRowKeys.length === 0 && 'rgb(220,100,100)', marginLeft: 14}}>
                  {`[ ${caseSelectedRowKeys.length} / ${caseList.length} ]`}
                </span>
              </Space>
              <Table
                bordered
                columns={caseColumns}
                dataSource={caseList}
                size='small'
                pagination={caseList.length > 10}
                rowSelection={rowSelection(caseSelectedRowKeys, setCaseSelectedRowKeys)}
                onRow={onTableRow(caseSelectedRowKeys, setCaseSelectedRowKeys)}
              />
            </Col>
          </Row>
          }
          {currStep === STEP_OPTION.SELECT_OPTIONS &&
          <Row>
            <Col span={optionSelectSpan} style={{paddingRight: 24}}>
              {
                Object.entries(options).map(([structureKey, detailOptions]) => {
                  let disabled = false
                  if (selectedDepth === DEPTH_VALUE.PATIENT) {
                    if (structureKey === STRUCTURE_KEYS.STUDY) {
                      disabled = true
                      // return <></> // 안보이게 처리
                    } 
                  }
                  if (selectedDepth === DEPTH_VALUE.FLAT) {
                    if (structureKey === STRUCTURE_KEYS.PATIENT || structureKey === STRUCTURE_KEYS.STUDY) {
                      disabled = true
                      // return <></> // 안보이게 처리
                    }
                  }
                  const existError = errorObject[structureKey].list.some(data => data.count > 0)
                  const backgroundColor = (disabled && 'rgba(20,20,20, 0.4)') || (existError && 'rgba(255, 64, 64, 0.2)')
                  return ( 
                    <Col span={24} style={{marginBottom: 4}}>
                      <Card bodyStyle={{padding: 12, backgroundColor}}>
                        <Typography.Title level={5}>{detailOptions.label}</Typography.Title>
                        <Row gutter={[8,8]}>
                          {
                            Object.entries(detailOptions).map(([optionKey, detailOption]) => {
                              if (optionKey === 'label') return <></>
                              
                              const labelColSpan = 5
                              const controllerColSpan = 24 - labelColSpan

                              const commonOnChange = () => { // option 변경 시 view 모드 해당 옵션에 맞게 변경
                                setViewPoint(structureKey)
                              }

                              const optionRender = detailOption => {
                                switch (detailOption.type) {
                                  case CONTROLLER_TYPE.RADIO:
                                    {
                                      const radioOptions = detailOption.options
                                      const onChange = e => {
                                        const value = e.target.value
                                        if (detailOptions.label === STRUCTURE_KEYS.PATIENT && 
                                            detailOption.label === CONTROLLER_NAME.OPTION && 
                                            value === PATIENT_INFO.CUSTOM 
                                        ) {
                                          detailOptions.addIndex.selected = true
                                        }
                                        detailOption.selected = value
                                        setOptions({...options})
                                        commonOnChange()
                                      }
                                      const value = detailOption.selected
                                      return (
                                        <Radio.Group
                                          disabled={disabled}
                                          className={!disabled && 'custom-radio-group'}
                                          options={radioOptions}
                                          optionType='button'
                                          value={value}
                                          onChange={onChange}
                                          style={detailOption.style}
                                        />
                                      )
                                    }
                                  case CONTROLLER_TYPE.SELECT:
                                    {
                                      const selectOptions = detailOption.options
                                      const onChange = e => {
                                        detailOption.selected = e
                                        setOptions({...options})
                                        commonOnChange()
                                      }
                                      return (
                                        <Select
                                          disabled={disabled}
                                          mode='multiple'
                                          allowClear
                                          style={detailOption.style}
                                          options={selectOptions}
                                          value={detailOption.selected}
                                          onChange={onChange}
                                          placeholder="Please select"
                                        />
                                      )
                                    }
                                  case CONTROLLER_TYPE.SWITCH:
                                    {
                                      const onChange = e => {
                                        detailOption.selected = e
                                        setOptions({...options})
                                        commonOnChange()
                                      }
                                      const checked = detailOption.selected
                                      return (
                                        <Switch 
                                          disabled={disabled}
                                          checked={checked} 
                                          onChange={onChange}
                                          style={detailOption.style}
                                        />
                                      )
                                    }
                                  case CONTROLLER_TYPE.INPUT_NUMBER:
                                    {
                                      const onChange = e => {
                                        detailOption.selected = e
                                        setOptions({...options})
                                        commonOnChange()
                                      }
                                      return (
                                        <InputNumber 
                                          disabled={disabled}
                                          onChange={onChange} 
                                          min={detailOption.min} 
                                          max={detailOption.max} 
                                          defaultValue={detailOption.selected}
                                          style={detailOption.style}
                                        />)
                                    }
                                  case CONTROLLER_TYPE.INPUT:
                                    {
                                      const onChange = e => {
                                        detailOption.selected = e.target.value
                                        setOptions({...options})
                                        commonOnChange()
                                      }
                                      return (
                                        <Input 
                                          disabled={disabled}
                                          showCount={inputFormatter}
                                          maxLength={detailOption.max || maxLength}
                                          onChange={onChange} 
                                          value={detailOption.selected} 
                                          style={detailOption.style}
                                        />
                                      )
                                    }
                                  default:
                                    return <></>
                                }
                              }
                              const extraRender = ({newLine=false}) => {
                                return detailOption.extra?.map(extra => {
                                  let triggerMatched = false
                                  if (extra.trigger === detailOption.selected) {
                                    triggerMatched = true
                                  }
                                  if (typeof extra.trigger === 'object') {
                                    const condition = extra.trigger.condition
                                    if (condition === 'in' && detailOption.selected.includes(extra.trigger.value)) {
                                      triggerMatched = true
                                    }
                                  }
                                  if (triggerMatched) {
                                    if (extra.label) {
                                      if (newLine) {
                                        return (
                                          <>
                                            <Col span={labelColSpan} style={{textAlign: 'right', height: 32}}>
                                              <div 
                                                style={{
                                                  display: 'inline-flex', 
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  verticalAlign: 'middle', 
                                                  height: '100%'
                                                }}
                                              >
                                                {extra.label} : 
                                              </div>
                                            </Col>
                                            <Col span={controllerColSpan}>
                                              {optionRender(extra)}
                                            </Col>
                                          </>
                                        )
                                      }
                                    }
                                    else {
                                      if (!newLine) return optionRender(extra)
                                    }
                                  }
                                  return <></>
                                })
                              }

                              return (
                                <>
                                  <Col span={labelColSpan} style={{textAlign: 'right', height: 32}}>
                                    <div 
                                      style={{
                                        display: 'inline-flex', 
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        verticalAlign: 'middle', 
                                        height: '100%'
                                      }}
                                    >
                                      {detailOption.label} : 
                                    </div>
                                  </Col>
                                  <Col span={controllerColSpan} style={{minHeight: 32}}>
                                    <div
                                      style={{
                                        display: 'flex', 
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                        height: '100%'
                                      }}
                                    >
                                      {optionRender(detailOption)}
                                      {extraRender({})}
                                    </div>
                                  </Col>
                                  {extraRender({newLine: true})}
                                </>
                              )
                            })
                          }
                        </Row>
                      </Card>
                    </Col>
                  )
                })
              }
              {notAllowedChars.length > 0 && 
                <>
                  <Card bodyStyle={{padding: 12}}>
                    <Typography.Title level={5} style={{marginTop: 16}}>Replacement of unacceptable characters</Typography.Title>
                    <Row style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                      {notAllowedChars.map((char, index) => {
                        const indexSpan = 2
                        const invalidCharSpan = 4
                        const spaceSpan = 2
                        const inputSpan = 12 - (indexSpan + invalidCharSpan + spaceSpan)
      
                        const onChange = e => {
                          const value = e.target.value
                          const invalidChars = checkPossiblePath(value, undefined, false)
                          const copiedReplaceChars = {...replaceChars}
                          if (value) {
                            copiedReplaceChars[char] = {value}
                            if (invalidChars.length > 0) {
                              const errorMsg = `[ ${[...new Set(invalidChars)].join(', ')} ]`
                              copiedReplaceChars[char].errorMsg = errorMsg
                            }
                          }
                          else {
                            delete copiedReplaceChars[char]
                          }
                          setReplaceChars(copiedReplaceChars)
                        }
      
                        return (
                          <>
                            <Col span={indexSpan}>
                              {`[ ${index + 1} ]`}
                            </Col>
                            <Col span={invalidCharSpan}>
                              <Typography.Text>
                                <pre>{char}</pre>
                              </Typography.Text>
                            </Col>
                            <Col span={spaceSpan}>
                              <ArrowRightOutlined />
                            </Col>
                            <Col span={inputSpan} onChange={onChange}>
                              <Input value={replaceChars?.[char]?.value} style={{border: replaceChars?.[char]?.errorMsg && '1px solid rgba(255,64,64)'}}/>
                              {replaceChars?.[char]?.errorMsg &&
                                <div style={{color: 'rgba(255,64,64)'}}>{replaceChars?.[char]?.errorMsg}</div>
                              }
                            </Col>
                          </>
                        )
                      })}
                    </Row>
                  </Card>
                </>
              }
            </Col>
            <Col span={previewSpan}>
              <Row style={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: 8}}>
                <Typography.Title level={5} style={{marginRight: 8, display: 'inline'}}>
                  Display:
                </Typography.Title>
                <Radio.Group
                  optionType="button"
                  options={expandOptions.filter(option => option.visible)}
                  onChange={onSelectExpand}
                  value={viewMode.value}
                  className={'custom-radio-group'}
                />
              </Row>
              <div style={{minHeight: 500, backgroundColor: '#0C2329', padding: 18}}>
                <DirectoryTree
                  ref={treeRef}
                  showLine
                  treeData={(treeData.length > 0 && treeData) || blankTreeData}
                  height={500}
                  defaultExpandAll={true}
                  blockNode={true}
                  showIcon={true}
                  expandAction={true}
                  autoExpandParent={true}
                  expandedKeys={expandedKeys}
                  selectedKeys={selectedKeys}
                  onSelect={onTreeItemSelect}
                  onExpand={onTreeExpand}
                />
              </div>
              {Object.entries(errorObject).some(([key, info]) => info.list.find(data => data.count > 0)) && 
                <Typography.Title level={5} style={{marginTop: 10}}>Error</Typography.Title>
              }
              <Typography.Text>
                {
                  Object.entries(errorObject).map(([key, info]) => {
                    const existError = info.list.find(data => data.count > 0)
                    return (
                      <>
                        {existError && 
                          <Card bodyStyle={{padding: 12}}>
                            <Typography.Title level={5}>{info.label}</Typography.Title>
                            {
                              info.list.map(data => {
                                const getErrorMessage = (type, count) => {
                                  switch (type) {
                                    case ERROR_TYPE.EMPTY:
                                      return `Empty: ${count}`
                                    case ERROR_TYPE.DUPLICATE:
                                      return `Duplicate: ${count}`
                                    case ERROR_TYPE.UNACCEPTABLE:
                                      return `Unacceptable character: ${count}`
                                    case ERROR_TYPE.TOO_LONG:
                                      return `Too long: ${count}`
                                    default:
                                      return ''
                                  }
                                }

                                const currIndex = (scrollToInfo.structureKey === key) &&
                                (scrollToInfo.type === data.type) ? scrollToInfo.index : undefined

                                const initializeScrollToInfo = () => setScrollToInfo(initialScrollToInfo)

                                return (
                                  <>
                                    {data.count > 0 && 
                                      <pre 
                                        style={{
                                          display: 'flex', 
                                          justifyContent: 'space-between', 
                                          alignItems: 'center',
                                          color: data.color
                                        }}
                                      >
                                        {getErrorMessage(data.type, data.count)}
                                        <Space>
                                          <CaretLeftOutlined 
                                            style={{cursor: 'pointer'}} 
                                            onClick={onDirectClick(DIRECTION.LEFT, data, key)}
                                          />
                                          {currIndex !== undefined && 
                                            <span 
                                              onClick={initializeScrollToInfo}
                                              style={{cursor: 'pointer'}}
                                            >
                                              {`${currIndex + 1}/${data.count}`}
                                            </span>
                                          }
                                          <CaretRightOutlined 
                                            style={{cursor: 'pointer'}} 
                                            onClick={onDirectClick(DIRECTION.RIGHT, data, key)}
                                          />
                                        </Space>
                                      </pre>
                                    }
                                  </>
                                )
                              })
                            }
                          </Card>
                        }
                      </>
                    )
                  })
                }
              </Typography.Text>
            </Col>
          </Row>
          }
        </>
      </div>
    </Modal>
  )
}

export default DownloadResultPathSettingModal;


function parseFilename(filename) {
  const strippedName = filename.split('.nii')[0];

  let alias = null;
  if (strippedName.includes('__')) {
      const aliasIndex = strippedName.indexOf('__');
      alias = strippedName.substring(aliasIndex + 2).replace(/\(|\)/g, '');
  }

  const parts = strippedName.split('_');

  const result = {
    pid: parts.length > 0 ? parts[0] : null,
    sid: parts.length > 1 ? parts[1] : null,
    blobtypeId: parts.length > 2 ? parts[2] : null,
    taskId: parts.length > 3 ? parts[3] : null,
    alias: alias
  };

  return result;
}
