import '../components/patient.css'

const blinkMergedElement = dataRowKey => {
  const element = document.querySelector(`[data-row-key="${dataRowKey}"]`)
  
  const blinkClassName = 'merged'
  
  setTimeout(() => {
    element?.classList.add(blinkClassName)
    setTimeout(() => {
      element?.classList.remove(blinkClassName)
    }, 3000)
  })
}

export default blinkMergedElement