import '@h5web/app/dist/styles.css';

import React, { useState, useEffect } from 'react';
import { App } from '@h5web/app';
import { H5WasmProvider } from '@h5web/h5wasm';
import axios from 'axios'
import { useLocation } from 'react-router-dom';
import {updateProgressByURL} from "./taskResultViewer";

function H5Viewer({url, width, parentLoading, handleLoadEnd, viewerDispatch}) {
  const query = new URLSearchParams(useLocation().search);
  const [h5File, setH5File] = useState();
  useEffect(() => {
    let active = true
    if (url) {
      const fname = url.split('?')[0].split('/').pop()
      axios({
        url: url,
        method: 'GET',
        responseType: 'arraybuffer',
        onDownloadProgress: (evt) => {
          const percentage = Math.round(
            (evt.loaded * 100) / evt.total
          );
          viewerDispatch(updateProgressByURL(url, {progress: percentage}))
        }
      }).then((response) => {
        viewerDispatch(updateProgressByURL(url, {state: 'success'}))
        if (active) {
          setH5File({
            filename: fname,
            buffer: response.data // as ArrayBuffer,
          });
        }
        handleLoadEnd(url, 'success')
      }).catch((e) => {
        viewerDispatch(updateProgressByURL(url, {state: 'failed'}))
        handleLoadEnd(url, 'failed')
      })
    }
    return () => {
      active = false
    }
  }, [url])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Client-side CSV export
  const getExportURL = (format, dataset, selection, value) => {
    if (format === 'csv') {
      // Async function that will be called when the user clicks on a `CSV` export menu entry
      return async () => {
        // Generate CSV string from `value` array
        // let csv = '';
        // value.forEach((val) => {
        // })
        // console.log(format, dataset, selection)
        const csv = value.join('\n')

        // const filename = `data ${dataset.name}.csv`
        // TODO filename은 ExportEntry.tsx 에서 data.csv 로 강제 ㅠ

        // Return CSV string as Blob so it can be downloaded
        return new Blob([csv]);
      };
    }
  }

  if (!h5File || parentLoading)  {
    return <span>Loading...</span>
  }

  return (
    <div style={{width: width,  height: '80vh'}}>
      <H5WasmProvider
        getExportURL={getExportURL}
        {...h5File}
      >
        <App
          explorerOpen={!query.has('wide')}
          // getFeedbackURL={getFeedbackURL}
        />
      </H5WasmProvider>
    </div>
  );
}

export default H5Viewer;

