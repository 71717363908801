import {useEffect, useRef, createContext} from 'react';
import {io} from 'socket.io-client';
import {useSelector} from "react-redux";

export const SocketContext = createContext({});

const NAMESPACE = '/socket'

const useSocket = () => {
  const user = useSelector(state => state.auth.user);
  const socketRef = useRef();
  const connected = useRef(false);

  const add = (event, callback) => {
    socketRef.current?.on(event, callback)
  }

  const addHandler = (event, callback) => {
    if (user) {
      if (connected.current) {
        add(event, callback)
      }
      else {
        const interval = setInterval(() => {
          if (connected.current) {
            clearInterval(interval)
            add(event, callback)
          }
        }, 1000);
      }
    }
  }

  const removeHandler = (event, callback) => {
    socketRef.current?.off(event, callback)
  }

  useEffect(() => {
    socketRef.current = new io(NAMESPACE, {
      reconnection: true, 
      reconnectionAttempts: 10, 
      reconnectionDelay: 5000
    });
    socketRef.current.on('connect', () => {connected.current = true});
    // socketRef.current.on('room', () => {});
    // socketRef.current.io.on("ping", (test) => {
    //   console.log('ping', test)
    // });
    
    if (user) {
      socketRef.current.emit('join', {id: user.id});
    }

    return () => {
      socketRef.current.disconnect()
      connected.current = false
    }
  }, [user]);
  
  return {addHandler, removeHandler}
}

export default useSocket;