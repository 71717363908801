import React, {useEffect, useState, forwardRef, useRef, useContext} from "react";
import {Switch, Space, message} from 'antd';
import CornerstoneElement from './cornerstoneElement'
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import {useDispatch, useSelector} from "react-redux";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstone from "cornerstone-core";
import VolumeImageOverlayContext from "../context/volumeImageOverlayContext";
import MultiSegContext from "../context/multiSegmentationContext";
import OverlayOption from "./overlayOption";
import {buildNiftiStack, calcMinMaxSlice, computeAutoVoi, concatTypedArrays} from "../lib/cornerstoneUtils";
import Plot from "react-plotly.js";
import {MemoizedHistogramPlot, MemoizedScatterPlot} from "./Plots";
import myOrientationTool from "../lib/customTools/myOrientationTool";
import MyReferenceLinesTool from "../lib/customTools/myReferenceLinesTool";
import {update, updateProgressByURL} from "./taskResultViewerLong";
// import useIsMounted from "../hooks/isMounted";
import {msgError} from "../redux/modules/message";
import IJKContext, {IJKContextProvider, IJKValue} from "../context/ijkContext";
import {clearUrlCache} from "../redux/modules/view";
import {updateProcessingByURL} from "./taskResultViewer";

function VolumeImageViewerLong(props) {
  const {
    acs,
    cmds,
    colormap,
    handleLoadEnd,
    locate,
    newImageSynchronizer,
    scrollSynchronizers,
    seg,
    seg_cmap,
    tag,
    tools,
    url,
    urls,
    width,
    reset,
    autowwwc,
    viewerDispatch,
    allImageLoaded,
    studyId,
    hidden,
    viewerState,
    volumeIndex
  } = props;
  let synchronizerWWWC = useRef(null);
  useEffect(() => {
    return () => {
      // memory leak 방지
      cleanup(studyId, url)
      if (synchronizerWWWC.current) {
        synchronizerWWWC.current.destroy()
      }
      if (refX.current?.element) {
        scrollSynchronizers.current[0].remove(refX.current.element)
        newImageSynchronizer.current.remove(refX.current.element)
      }
      if (refY.current?.element) {
        scrollSynchronizers.current[1].remove(refY.current.element)
        newImageSynchronizer.current.remove(refY.current.element)
      }
      if (refZ.current?.element) {
        scrollSynchronizers.current[2].remove(refZ.current.element)
        newImageSynchronizer.current.remove(refZ.current.element)
      }
      if (scrollSynchronizers.current.length > 0) {
        scrollSynchronizers.current[0].destroy()
        scrollSynchronizers.current[1].destroy()
        scrollSynchronizers.current[2].destroy()
      }
      if (newImageSynchronizer.current) {
        newImageSynchronizer.current.destroy()
      }
      cornerstone.imageCache.purgeCache();
      cornerstoneNIFTIImageLoader.nifti.purge();

      flattened3DLabelCache.current = null
      // filteredPixelData3D.current = null;
      filteredPixelData3DIndex.current = null;
      volumeZ.current = null

      if (requestPromise.current.state() === 'pending' && requestObj.current) {
        requestObj.current.abort()
        cancelled.current = true
        dispatch(clearUrlCache({url}))
      }
      requestObj.current = null
      requestPromise.current = null
    }
  }, []);

  const [filteredPixelData3D, setFilteredPixelData3D] = useState(null);
  // const filteredPixelData3D = useRef();
  // const [filteredPixelData3DIndex, setFilteredPixelData3DIndex] = useState(null);
  const filteredPixelData3DIndex = useRef();
  const [noImage, setNoImage] = useState(false);
  useEffect(() => {
    if (noImage) {
      removeCornerstoneTools()
    }
  }, [noImage])

  const volumeZ = useRef() // volume Z 맞나?

  const cancelled = useRef(false)
  const requestObj = useRef(null)
  const requestPromise = useRef(null)

  const dispatch = useDispatch()
  useEffect(() => {
    let alive = true
    const imageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
    cornerstoneNIFTIImageLoader.nifti.localMode = false;
    cornerstoneNIFTIImageLoader.nifti.configure({
      beforeSend: (request, url) => {
        requestObj.current = request
      }
    })

    cancelled.current = false
    const niftiUrl = imageId.fromURL(`nifti:${url}`)
    requestPromise.current = cornerstoneNIFTIImageLoader.nifti.loadVolume(niftiUrl.url)
    requestPromise.current.then(async (volume)  => {
      requestObj.current = null
      cornerstoneNIFTIImageLoader.nifti.configure({
        beforeSend: null
      })
      volumeZ.current = volume

      const directions = ['x', 'y', 'z']
      const newStacks = {x: {}, y: {}, z: {}}
      let promises = []
      for (const dir of directions) {
        const fileName = url.split('?')[0].split('/').at(-1)
        const niftiImageId = imageId.fromURL(`nifti:${url}#${dir}`)
        await cornerstone.loadAndCacheImage(niftiImageId.url).then(imageLoaded => {
          const newStack = buildNiftiStack(niftiImageId, imageLoaded, layerOption)
          if (newStack) {
            newStack.fileName = fileName
            newStack.dir = dir
            promises = promises.concat(newStack.imageIds.map(stackImageId => cornerstone.loadAndCacheImage(stackImageId)))
            newStacks[dir] = newStack
          }
        })
      }
      if (alive) {
        Promise.all(promises).then(values => {
          viewerDispatch(updateProgressByURL(url, {state: 'success'}))
        })
        setStacks(newStacks);
      }
    }).catch(e => {
      if (cancelled.current) { return }

      // cancel 된 게 아니라면 로딩에 실패한 것이니 이미지 경로가 잘못되었거나.. 이미지가 존재하지 않는 경우임
      viewerDispatch(updateProgressByURL(url, {state: 'failed'}))
      dispatch(msgError(`"Failed to load image" ${e.message}`))
      setNoImage(true)
    })
    return () => {
      alive = false
      cleanup(studyId, url)
    }
  }, [url])

  const [stacks, setStacks] = useState({x: {}, y: {}, z: {}});
  const flattened3DLabelCache = useRef(null)
  const refX = useRef();
  const refY = useRef();
  const refZ = useRef();
  useEffect(() => {
    const x = acs[0] ? 1 : 0
    const y = acs[1] ? 1 : 0
    const z = acs[2] ? 1 : 0
    const needSync = x + y + z >= 1
    if (noImage === false && needSync) {
      synchronizerWWWC.current = new cornerstoneTools.Synchronizer(
        cornerstone.EVENTS.IMAGE_RENDERED, cornerstoneTools.wwwcSynchronizer);
      if (refX.current?.element) {
        synchronizerWWWC.current.add(refX.current.element);
        scrollSynchronizers.current[0].add(refX.current.element)
        newImageSynchronizer.current.add(refX.current.element)
      }
      if (refY.current?.element) {
        synchronizerWWWC.current.add(refY.current.element)
        scrollSynchronizers.current[1].add(refY.current.element)
        newImageSynchronizer.current.add(refY.current.element)
      }
      if (refZ.current?.element) {
        synchronizerWWWC.current.add(refZ.current.element)
        scrollSynchronizers.current[2].add(refZ.current.element)
        newImageSynchronizer.current.add(refZ.current.element)
      }
    }
    addCornerstoneTools()

    // overlays
    if (overlays.length > 0) {

    }

    return () => {
      if (synchronizerWWWC.current) {
        synchronizerWWWC.current.destroy()
      }
      if (refX.current?.element) {
        scrollSynchronizers.current[0].remove(refX.current.element)
        newImageSynchronizer.current.remove(refX.current.element)
      }
      if (refY.current?.element) {
        scrollSynchronizers.current[1].remove(refY.current.element)
        newImageSynchronizer.current.remove(refY.current.element)
      }
      if (refZ.current?.element) {
        scrollSynchronizers.current[2].remove(refZ.current.element)
        newImageSynchronizer.current.remove(refZ.current.element)
      }
    }
  }, [acs, urls])

  useEffect(() => {
    addCornerstoneTools()
  }, [tools])

  useEffect(() => {
    if (autowwwc) {
      // 3장
      const eElements = cornerstone.getEnabledElements();
      const myElements = eElements.filter(e => e.image.imageId.includes(url))
      if (myElements.length > 0) {
        const pixels = myElements.map(e => e.image.getPixelData())
        const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(myElements[0].image.imageId)
        const {min, max, skewness} = calcMinMaxSlice(concatTypedArrays(pixels), niftiMetaData)
        myElements.forEach(e => {
          const viewport = cornerstone.getViewport(e.element);
          computeAutoVoi(niftiMetaData, viewport, e.image, min, max, skewness)
          cornerstone.displayImage(e.element, e.image, viewport);
        })
      }
      // stacks
      viewerDispatch(updateProcessingByURL(url, {processing: false}))
    }
  }, [autowwwc]);

  const refPlot = useRef();

  useEffect(() => {
    if (allImageLoaded) {
      if (seg && stacks.x?.imageIds) {
        handleChangeSegOverlay(false)(true)
      }
    }
  }, [allImageLoaded, stacks])
  // 예제에서는 display_image가 완료된 이후에 ReferenceLines나 Crosshair를 붙이더라, 아래는 예제 주석
  // Doing some dark magic here to make sure we don't add our
  // synchronizer/tool until all canvases have rendered an image.
  // const renderCount = useRef(0);
  // const addTools = (element) => {
  //   synchronizer.add(element);
  //   renderCount.current = renderCount.current + 1
  //   cornerstoneTools.orientationMarkers.enable(element);
  // }
  // useEffect(() => {
  //   const testZ = refZ.current.element;
  //   const ee = cornerstone.getEnabledElement(testZ);
  //   if (renderCount >= 3) {
  //     // cornerstoneTools.addTool(cornerstoneTools.ReferenceLinesTool);
  //     // cornerstoneTools.setToolEnabled('ReferenceLines', {
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // const tool1 = cornerstoneTools.ReferenceLinesTool;
  //     // cornerstoneTools.addToolForElement(tool1);
  //     // cornerstoneTools.setToolEnabledForElement('ReferenceLines', {
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // cornerstoneTools.setToolActiveForElement
  //
  //     // cornerstoneTools.addTool(cornerstoneTools.CrosshairsTool);
  //     // cornerstoneTools.setToolActive('Crosshairs', {
  //     //   mouseButtonMask: 1,
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // const tool2 = cornerstoneTools.CrosshairsTool;
  //     // cornerstoneTools.addToolForElement(tool2);
  //     // cornerstoneTools.setToolEnabledForElement('ReferenceLines', {
  //     //   mouseButtonMask: 1,
  //     //   synchronizationContext: synchronizer,
  //     // })
  //   }
  // }, [renderCount])

  const layer_option_base = {
    name: 'layer-base',
    visible: true,
    opacity: 1.0,
    viewport: {
      colormap: 'gray',
    }
  }
  const layer_option_overlay = {
    name: 'layer-overlay',
    visible: true,
    opacity: 0.2,
    viewport: {
      colormap: 'jet',
    }
  }
  // const handleButton4 = () => {
  //   // console.log(plotSelected)
  //   // build new 3D maps
  //   const new3DMapZ = new Uint16Array(pixelData3D.current.length)
  //   const new3DMapX = new Uint16Array(pixelData3D.current.length)
  //   const new3DMapY = new Uint16Array(pixelData3D.current.length)
  //   const xlen = stacks.x.imageIds.length
  //   const ylen = stacks.y.imageIds.length
  //   const zlen = stacks.z.imageIds.length
  //   for (let i = 0; i < refPlot.current.props.data[0].selectedpoints.length; ++i) {
  //     const indexSel = refPlot.current.props.data[0].selectedpoints[i];
  //     const indexZ = filteredPixelData3DIndex.current[indexSel]
  //     // new3DMap[indexZ] = filteredPixelData3D.current[indexSel]
  //     new3DMapZ[indexZ] = 1;
  //     const z = Math.floor(indexZ / (xlen * ylen))
  //     const remainderZ = parseInt(indexZ % (xlen * ylen))
  //     const y = Math.floor(remainderZ / xlen)
  //     const x = parseInt(remainderZ % xlen)
  //     const indexY = y * zlen * xlen + z * xlen + x;
  //     new3DMapY[indexY] = 1;
  //     const indexX = x * ylen * zlen + z * ylen + y;
  //     new3DMapX[indexX] = 1;
  //   }
  //
  //   const segmentationModule = cornerstoneTools.getModule('segmentation');
  //   segmentationModule.setters.labelmap3DForElement(refZ.current.element, new3DMapZ.buffer, 0);
  //   segmentationModule.setters.labelmap3DForElement(refY.current.element, new3DMapY.buffer, 0);
  //   segmentationModule.setters.labelmap3DForElement(refX.current.element, new3DMapX.buffer, 0);
  // }

  const [segOverlaid, setSegOverlaid] = useState(false);
  const {segmentations, toggleSegmentation, action:actionSeg, cleanup} = useContext(MultiSegContext);
  // // Segmentation module configuration.
  // const defaultConfiguration = {
  //   renderOutline: true,
  //   renderFill: true,
  //   shouldRenderInactiveLabelmaps: true,
  //   radius: 10,
  //   minRadius: 1,
  //   maxRadius: 50,
  //   fillAlpha: 0.2,
  //   fillAlphaInactive: 0.1,
  //   outlineAlpha: 0.7,
  //   outlineAlphaInactive: 0.35,
  //   outlineWidth: 3,
  //   storeHistory: true,
  //   segmentsPerLabelmap: 65535, // Max is 65535 due to using 16-bit Unsigned ints.
  //   arrayType: UINT_16_ARRAY,
  // };
  const [segOption, setSegOption] = useState({
    name: 'optionBase',
    visible: true,
    opacity: 1.0,
    renderOutline: true,
    renderFill: true,
    shouldRenderInactiveLabelmaps: true,
  });

  const createWorkerFlatten = (volume, ndarray) => {
    return new Promise((resolve, reject) => {
      const worker = new Worker(new URL("../workers/flattenNDarray.worker.js", import.meta.url));
      worker.postMessage([ndarray, volume.metaData.slope, volume.metaData.intercept,]);
      worker.onmessage = function (e) {
        const {flattened} = e.data
        resolve(flattened)
        worker.terminate()
      }
      worker.onerror = function(e) {
        // Rejects the promise using the error associated with the ErrorEvent
        reject(worker, e.error);
      };
    })
  }

  const handleChangeSegOverlay = manual => async checked => {
    // url 변경 여부(== cache 유무), toggle 여부를 따져서 toggle 해줘야 함
    const studySegmentations = segmentations[studyId]
    const segmentation = studySegmentations?.find(item => item.url === url)
    if (!segmentation && flattened3DLabelCache.current === null) {
      // 레이블맵 캐시가 없는 경우
      viewerDispatch(update({processing: true}))
      // 왜 volumeZ 인지는 볼륨 XYZ 를 봐야할 듯?
      const promiseX = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray.transpose(1, 2, 0))
      const promiseY = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray.transpose(0, 2, 1))
      const promiseZ = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray)
      Promise.all([promiseX, promiseY, promiseZ]).then(results => {
        const [resultX, resultY, resultZ] = results
        flattened3DLabelCache.current = {x: resultX, y: resultY, z: resultZ, metaData: volumeZ.current.metaData}

        // 새로운 url 인 경우(첫 로딩이후 강제로 들어오거나, 이후 변경됨) 무조건 toggle (켜준다)
        segOption.colorLUT = seg_cmap
        toggleSegmentation(manual, studyId, volumeIndex, url, segOption, resultX.buffer, resultY.buffer, resultZ.buffer, volumeZ.current.metaData)
        viewerDispatch(update({processing: false}))
      }).catch((e) => console.debug(e))
    }
    else {
      // url도 있고 캐시도 존재하는 경우 (사용자가 클릭한 경우) 이미 켜져있으면 아무일도 하지 않음
      if (!(segOverlaid === checked)) {
        toggleSegmentation(manual, studyId, volumeIndex, url, segOption, flattened3DLabelCache.current.x.buffer, flattened3DLabelCache.current.y.buffer, flattened3DLabelCache.current.z.buffer, flattened3DLabelCache.current.metaData)
      }
    }
    setSegOverlaid(checked)
  }


  const [overlaid, setOverlaid] = useState(false);
  const {overlays, toggleOverlay, action, setAction} = useContext(VolumeImageOverlayContext);
  const [layerOption, setLayerOption] = useState({
    name: 'optionBase',
    visible: true,
    opacity: 1.0,
    viewport: {
      colormap: colormap,
      //   voi: { windowWidth: 30, windowCenter: 16 }
    }
  });

  const handleChangeOverlay = (checked) => {
    setOverlaid(checked)
    toggleOverlay(url, layerOption, stacks.x, stacks.y, stacks.z)
  }

  const removeCornerstoneTools = () => {
    if (synchronizerWWWC.current) {
      synchronizerWWWC.current.destroy()
    }
    if (refX.current?.element) {
      scrollSynchronizers.current[0].remove(refX.current.element)
      newImageSynchronizer.current.remove(refX.current.element)
    }
    if (refY.current?.element) {
      scrollSynchronizers.current[1].remove(refY.current.element)
      newImageSynchronizer.current.remove(refY.current.element)
    }
    if (refZ.current?.element) {
      scrollSynchronizers.current[2].remove(refZ.current.element)
      newImageSynchronizer.current.remove(refZ.current.element)
    }
    // const elems = [refX.current?.element, refY.current?.element, refZ.current?.element]
    // elems.forEach(elem => {
    //   if(elem && cornerstoneTools.isToolActiveForElement(elem, 'Crosshairs')) {
    //     cornerstoneTools.removeToolForElement(elem, 'Crosshairs')
    //   }
    // })
  }
  const addCornerstoneTools = () => {
    // add basic tools
    // 우클릭은 확대, 축소
    const zoomTool = cornerstoneTools.ZoomTool;
    cornerstoneTools.addTool(zoomTool);
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2});
    // 휠클릭은 panning
    const panTool = cornerstoneTools.PanTool;
    cornerstoneTools.addTool(panTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4});
    // 휠인데 mouseButtonMask 는 뭐지?
    const zoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
    cornerstoneTools.addTool(zoomMouseWheelTool);
    cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1});
    // 스택 - 마우스스크롤
    const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
    cornerstoneTools.addTool(StackScrollMouseWheelTool)
    cornerstoneTools.setToolActive('StackScrollMouseWheel', {
      configuration: {
        // loop: true
        allowSkipping: false
      }
    })
    // orientation 레이블
    cornerstoneTools.addTool(myOrientationTool, {configuration: { drawAllMarkers: true }});
    cornerstoneTools.setToolActive('myOrientation')
    // referencelines
    cornerstoneTools.addTool(MyReferenceLinesTool);

    // add tools depending on settings
    if (tools.wwwc) {
      const wwwcTool = cornerstoneTools.WwwcTool;
      cornerstoneTools.addTool(wwwcTool);
      cornerstoneTools.setToolActive('Wwwc', {mouseButtonMask: 1});
    }
    else if (tools.dragprobe) {
      const dragProbeTool = cornerstoneTools.DragProbeTool;
      cornerstoneTools.addTool(dragProbeTool);
      cornerstoneTools.setToolActive('DragProbe', {mouseButtonMask: 1});
    }
    else if (tools.crosshair) {
      cornerstoneTools.addTool(cornerstoneTools.CrosshairsTool);
      cornerstoneTools.setToolActive('Crosshairs', {
        mouseButtonMask: 1,
        synchronizationContext: newImageSynchronizer.current,
      });
    }

    if (tools.referencelines) {
      cornerstoneTools.setToolEnabled('MyReferenceLines', {
        synchronizationContext: newImageSynchronizer.current,
      });
    }
    else {
      cornerstoneTools.setToolDisabled('MyReferenceLines', {
        synchronizationContext: newImageSynchronizer.current,
      });
    }
  }

  return (
    <IJKContextProvider>
      <div
        style={hidden ? {display: 'none'} : {display: "flex", flexDirection: "row"}}
        className='loading-overlay-target'
        onContextMenu={(e) => e.preventDefault()}
      >
        <CornerstoneElement
          hidden={!acs[0]}
          volumeIndex={volumeIndex}
          direction="z"
          stack={stacks.z}
          ref={refZ}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations: segmentations[studyId], action: actionSeg, studyId}}
          tag={tag}
          width={width}
          noImage={noImage}
          locate={locate}
          reset={reset}
          studyId={studyId}
          viewerState={viewerState}
          seg={seg}
          segOverlaid={segOverlaid}
          handleChangeSegOverlay={handleChangeSegOverlay(true)}
          // height={props.height}
        />

        <CornerstoneElement
          hidden={!acs[1]}
          volumeIndex={volumeIndex}
          direction="y"
          stack={stacks.y}
          ref={refY}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations: segmentations[studyId], action: actionSeg, studyId}}
          tag={tag}
          width={width}
          noImage={noImage}
          locate={locate}
          reset={reset}
          studyId={studyId}
          viewerState={viewerState}
          seg={seg}
          segOverlaid={segOverlaid}
          handleChangeSegOverlay={handleChangeSegOverlay(true)}
        />
        <CornerstoneElement
          hidden={!acs[2]}
          volumeIndex={volumeIndex}
          direction="x"
          stack={stacks.x}
          ref={refX}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations: segmentations[studyId], action: actionSeg, studyId}}
          tag={tag}
          width={width}
          noImage={noImage}
          locate={locate}
          reset={reset}
          studyId={studyId}
          viewerState={viewerState}
          seg={seg}
          segOverlaid={segOverlaid}
          handleChangeSegOverlay={handleChangeSegOverlay(true)}
        />

        {cmds.states[1] && filteredPixelData3D ?
          <MemoizedHistogramPlot
            ref={refPlot}
            data={filteredPixelData3D}
            size={width}
            // onAfterPlot={handlePlotEnd}
          /> : null}
      </div>
    </IJKContextProvider>
  );
}


export default VolumeImageViewerLong;

