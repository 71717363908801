import { Button, Card, Col, Input, InputNumber, Modal, Radio, Row, Select, Space, Switch, Tooltip, Tree, Typography } from "antd"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { clearDownloadTargets } from "../redux/modules/patient"
import { downloadPatients as downloadAction } from "../redux/modules/longTask"
import { RollbackOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

const { DirectoryTree } =Tree

const STRUCTURE_KEYS = Object.freeze({PATIENT: 'Patient', STUDY: 'Study', DICOM: 'DICOM', NIFTI: 'NIfTI'})
const PATIENT_INFO = Object.freeze({ID: 'pid', NAME: 'name', ALL: 'all', CUSTOM: 'custom'})
const STUDY_INFO = Object.freeze({ID: 'sid', DESC: 'desc', ALL: 'all'})
const DICOM_INFO = Object.freeze({SERIES_NUMBER: 'sn', DESC: 'desc', PROTOCOL: 'protocol', COMBINATION: 'combination', COMBINATION_LABEL: 'Select data'})
const NIFTI_INFO = Object.freeze({FILE_NAME: 'fnameWithoutExt'})

const CONTROLLER_TYPE = Object.freeze({
  SWITCH: 'switch', 
  RADIO: 'radio', 
  INPUT: 'input', 
  INPUT_NUMBER: 'input number', 
  SELECT: 'select'
})

const CONTROLLER_NAME = Object.freeze({
  ADD_INDEX: 'Add index', 
  INDEX_LOCATION: 'Index location', 
  DIRECTORY_NAME: 'Directory name',
  CUSTOM_INPUT: 'Custom input', 
  START_INDEX: 'Start index', 
  ALL: 'all', 
  ZERO_PAD: 'Zero pad'
})

const ERROR_TYPE = Object.freeze({
  EMPTY: 'empty', 
  NOT_ALLOWED: 'not_allowed', 
  DUPLICATE: 'duplicate', 
  TOO_LONG:'too_long'
})

const INDEX_LOCATION = Object.freeze({PREFIX: 0, SUFFIX: 1})
const DIRECTION = Object.freeze({RIGHT: 'right', LEFT: 'left'})

// extra에 label이 있으면 새로운 라인으로 label과 함께 옵션 생성
// extra에 label이 없으면 extra를 불러오는 옵션 오른편에 배치
const addIndex = {
  label: CONTROLLER_NAME.ADD_INDEX,
  type: CONTROLLER_TYPE.SWITCH,
  selected: false,
  extra: [
    {
      trigger: true,
      name: CONTROLLER_NAME.INDEX_LOCATION,
      style: {marginLeft: 20},
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'Prefix',
          value: INDEX_LOCATION.PREFIX
        },
        {
          label: 'Suffix',
          value: INDEX_LOCATION.SUFFIX
        }
      ],
      selected: INDEX_LOCATION.PREFIX
    },
  ]
}

const namingOptionStructures = {
  [STRUCTURE_KEYS.PATIENT]: {
    label: STRUCTURE_KEYS.PATIENT,
    addIndex: {
      ...addIndex,
      extra: [
        ...addIndex.extra,
        {
          trigger: true,
          label: CONTROLLER_NAME.START_INDEX,
          name: CONTROLLER_NAME.START_INDEX,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 100000,
          selected: 1,
        },
        {
          trigger: true,
          label: CONTROLLER_NAME.ZERO_PAD,
          name: CONTROLLER_NAME.ZERO_PAD,
          type: CONTROLLER_TYPE.INPUT_NUMBER,
          min: 1,
          max: 10,
          selected: 1
        },
      ]
    },
    folderName: {
      label: CONTROLLER_NAME.DIRECTORY_NAME,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'ID',
          value: PATIENT_INFO.ID
        },
        {
          label: 'Name',
          value: PATIENT_INFO.NAME
        },
        {
          label: 'All',
          value: PATIENT_INFO.ALL
        },
        {
          label: 'Custom',
          value: PATIENT_INFO.CUSTOM,
        }
      ],
      selected: PATIENT_INFO.ID,
      extra: [
        {
          trigger: PATIENT_INFO.CUSTOM,
          label: CONTROLLER_NAME.CUSTOM_INPUT,
          name: CONTROLLER_NAME.CUSTOM_INPUT,
          type: CONTROLLER_TYPE.INPUT,
          style : {width: 300},
          selected: '',
        }
      ]
    },
  },
  [STRUCTURE_KEYS.STUDY]: {
    label: STRUCTURE_KEYS.STUDY,
    addIndex,
    folderName: {
      label: CONTROLLER_NAME.DIRECTORY_NAME,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'ID',
          value: STUDY_INFO.ID
        },
        {
          label: 'Desc',
          value: STUDY_INFO.DESC
        },
        {
          label: 'All',
          value: STUDY_INFO.ALL
        }
      ],
      selected: STUDY_INFO.ID
    }
  },
  [STRUCTURE_KEYS.DICOM]: {
    label: STRUCTURE_KEYS.DICOM,
    addIndex,
    folderName: {
      label: CONTROLLER_NAME.DIRECTORY_NAME,
      name: CONTROLLER_NAME.DIRECTORY_NAME,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'Series number',
          value: DICOM_INFO.SERIES_NUMBER
        },
        {
          label: 'Desc',
          value: DICOM_INFO.DESC
        },
        {
          label: 'Protocol',
          value: DICOM_INFO.PROTOCOL
        },
        {
          label: 'Combination',
          value: DICOM_INFO.COMBINATION
        }
      ],
      selected: DICOM_INFO.SERIES_NUMBER,
      extra: [
        {
          trigger: DICOM_INFO.COMBINATION,
          label: DICOM_INFO.COMBINATION_LABEL,
          name: DICOM_INFO.COMBINATION_LABEL,
          type: CONTROLLER_TYPE.SELECT,
          style : {width: 300},
          options: [
            {
              label: 'Series number',
              value: DICOM_INFO.SERIES_NUMBER
            },
            {
              label: 'Desc',
              value: DICOM_INFO.DESC
            },
            {
              label: 'Protocol',
              value: DICOM_INFO.PROTOCOL
            },
          ],
          selected: [DICOM_INFO.SERIES_NUMBER, DICOM_INFO.DESC]
        }
      ]
    }
  },
  [STRUCTURE_KEYS.NIFTI]: {
    label: STRUCTURE_KEYS.NIFTI,
    addIndex,
    folderName: {
      label: CONTROLLER_NAME.DIRECTORY_NAME,
      type: CONTROLLER_TYPE.RADIO,
      options: [
        {
          label: 'File name',
          value: NIFTI_INFO.FILE_NAME
        }
      ],
      selected: NIFTI_INFO.FILE_NAME
    }
  }
}

const DownloadPatientPathSettingModal = () => {
  const dispatch = useDispatch()

  const downloadTargets = useSelector(state => state.patient?.downloadTargets?.patients)

  const [options, setOptions] = useState(JSON.parse(JSON.stringify(namingOptionStructures)))
  const [selectedKeys, setSelecteedKeys] = useState([])
  const [zipFileInfo, setZipFileInfo] = useState({value: ' ', errorMsg: []})
  const [expandedKeys, setExpandedKeys] = useState([])
  const initialScrollToInfo = {key: undefined, index: 0, structureKey: undefined, type: undefined}
  const [scrollToInfo, setScrollToInfo] = useState(initialScrollToInfo)
  
  const optionColSpan = 8
  const treeColSpan = 10
  const errorColSpan = 24 - optionColSpan - treeColSpan
  
  const treeRef = useRef()

  useEffect(() => {
    setSelecteedKeys([])
  },[options, expandedKeys])

  useEffect(() => {
    setScrollToInfo(initialScrollToInfo)
  },[options])

  const inputFormatter = {
    formatter: ({count, maxLength}) => (
      <span style={{color: 'rgba(255,255,255,0.2)'}}>
        {`${count}/${maxLength}`}
      </span>
    )
  }

  const checkPossiblePath = title => {
    const checkRegex = /[\\/:*?"<>|]/g;
    const invalidChars = title.match(checkRegex) || []
    if (title[0] === '.') {
      invalidChars.unshift('First . is not allowed')
    }
    return invalidChars
  }

  const checkEmptyString = title => {
    const regex = /^\s*$/
    return regex.test(title)
  }

  const makeTreeData = () => {
    const patients = downloadTargets
    
    const TitleElement = ({title, invalid=false, errorMsg=[], target, structurekey, style}) => {
      const inputRef = useRef(null)
      useEffect(() => {
        if (selectedKeys.includes(target?.key)) {
          setTimeout(() => inputRef.current?.focus({cursor: 'end'}), 10)
          setScrollToInfo(initialScrollToInfo)
        }
      },[selectedKeys])

      if (selectedKeys.includes(target?.key)) { // edit mode
        const selectedOptionValue = target.selectedOptionValue
        
        if (target[`original_${selectedOptionValue}`] === undefined) { // save original value
          target[`original_${selectedOptionValue}`] = target[selectedOptionValue]
        }
        
        const onChange = e => target[selectedOptionValue] = e.target.value
        
        const onPressEnter = () => setSelecteedKeys([])

        const onReset = () => {
          const originalValue = target[`original_${selectedOptionValue}`]
          target[selectedOptionValue] = originalValue
          setSelecteedKeys([])
        }
        // patient ~ dicom filename까지 250글자 내로 만들어야 함
        // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html 
        // instance uid = max 64 bytes + .dcm -> 최대 70글자로 계산하여 250 - 70 = 이전 모든 경로에 대해 180글자로 제한하기
        // 이를 위하여 patient, study, series, blob 각각 최대 60글자까지 허용

        return (
          <Input 
            showCount={inputFormatter}
            maxLength={60}
            ref={inputRef} 
            size="small" 
            onClick={e => e.stopPropagation()}
            onChange={onChange} 
            onPressEnter={onPressEnter} 
            defaultValue={target[selectedOptionValue]} 
            style={{width: 300}}
            suffix={<RollbackOutlined onClick={onReset} />}
          />
        )
      }
      const searchedKey = scrollToInfo?.key === target?.key && scrollToInfo?.key
      const alpha = 0.2
      const errorBackgroundColor = getErrorColor(scrollToInfo.type)
      const rgbaValue = errorBackgroundColor?.replace('rgb', 'rgba').replace(')', `,${alpha})`);


      const titleStyle = {display: 'inline-block', margin:0, padding: 0, color: invalid && 'rgb(250,50,50)', ...style, backgroundColor: searchedKey && rgbaValue}

      const errorMsgRender = errorMsg.map(data => <span style={{color: data.color, marginRight: 4}}>{data.msg}</span>)
      return ( // view mode
        <Typography.Title level={5} style={titleStyle}>
          {invalid ?
            <>{title}ㅤㅤㅤㅤㅤ{errorMsgRender}</>:
            title
          }
        </Typography.Title>
      )
    }

    const getTitle = (title, addIndex, indexLocation, index) => {
      if (!addIndex) return title
      if (indexLocation === INDEX_LOCATION.PREFIX) return `${index}_${title}`
      return `${title}_${index}`
    }

    const getData = (structureKey, data, index, key=undefined) => {
      const addIndex = options[structureKey].addIndex.selected
      const indexLocation = options[structureKey].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.INDEX_LOCATION)?.selected
      const title = getTitle(data.selectedValue, addIndex, indexLocation, index)
      const newKey = key ? `${key}_${data.id}` : `${data.id}`
      return {title, key: newKey}
    }

    const checkDuplicatePath = (target, dataList) => {
      const targetFolderName = target.folderName
      const othersFolderName = dataList.filter(data => data.id !== target.id).map(data => data.folderName)
      if (othersFolderName.includes(targetFolderName)) {
        return true
      }
      return false
    }

    const getErrorColor = type => {
      switch (type) {
        case ERROR_TYPE.EMPTY:
          return 'rgb(50,200,200)'
        case ERROR_TYPE.NOT_ALLOWED:
          return 'rgb(100,255,100)'
        case ERROR_TYPE.DUPLICATE:
          return 'rgb(200,150,255)'
        case ERROR_TYPE.TOO_LONG:
          return 'rgb(150,150,50)'
        default:
          return 'rgb(100,100,100)'
      }
    }

    const createObject = () => {
      const types = [ERROR_TYPE.EMPTY, ERROR_TYPE.NOT_ALLOWED, ERROR_TYPE.DUPLICATE, ERROR_TYPE.TOO_LONG]
      const item = {count: 0, keys: []}
      
      return types.map(type => ({
        type,
        color: getErrorColor(type),
        ...JSON.parse(JSON.stringify(item))
      }))
    }

    const errorObject = Object.fromEntries(
      Object.entries(STRUCTURE_KEYS).map(([key, value]) => 
        [value, {label: value, list: createObject()}]) 
      )
    
    let totalDownloadCounts = 0

    const getErrorMsg = ({invalidChars, duplicate, isEmptyString, target, structurekey, tooLong}) => {
      let errorMsg = []
      if (duplicate) {
        const targetErrorObj = errorObject[structurekey].list.find(data => data.type === ERROR_TYPE.DUPLICATE)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg: `Duplicate`, color: targetErrorObj.color})
      }
      if (isEmptyString) {
        const targetErrorObj = errorObject[structurekey].list.find(data => data.type === ERROR_TYPE.EMPTY)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg:`Not allowed empty string`, color: targetErrorObj.color})
      }
      if (invalidChars.length > 0) {
        const targetErrorObj = errorObject[structurekey].list.find(data => data.type === ERROR_TYPE.NOT_ALLOWED)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg: `Not allowed Characters[ ${invalidChars.join(', ')} ]`, color: targetErrorObj.color})
      }
      if (tooLong) {
        const targetErrorObj = errorObject[structurekey].list.find(data => data.type === ERROR_TYPE.TOO_LONG)
        targetErrorObj.count++
        targetErrorObj.keys.push(target.key)
        errorMsg.push({msg: `Too Long`, color: targetErrorObj.color})
      }

      return errorMsg
    }

    const getTitleElemnt = (structurekey, target, dataList, style) => {
      const folderName = target.folderName
      const invalidChars = checkPossiblePath(folderName)
      const duplicate = checkDuplicatePath(target, dataList)
      const isEmptyString = checkEmptyString(folderName)
      const tooLong = folderName.length > 60
      const errorMsg = getErrorMsg({invalidChars, duplicate, isEmptyString, target, structurekey, tooLong})
      const invalid = invalidChars.length > 0 || duplicate || isEmptyString || tooLong

      const titleElement = TitleElement({title: folderName, invalid, errorMsg, target, structurekey, style})
      
      return titleElement
    }

    const dataKeys = {
      [STRUCTURE_KEYS.PATIENT]: [], 
      [STRUCTURE_KEYS.DICOM]: [], 
      [STRUCTURE_KEYS.NIFTI]: [],
      detail: []
    }

    // option값에 따른 값 할당 진행
    for (const [pi, patient] of patients.entries()) {
      if (patient.folderName === undefined) {
        patient.folderName = `${patient[PATIENT_INFO.ID]}`
      }
      if (patient.all === undefined) {
        patient.all = `${patient.pid}-${patient.name}`
      }

      const customValue = options[STRUCTURE_KEYS.PATIENT].folderName.extra.find(extra => extra.name === CONTROLLER_NAME.CUSTOM_INPUT).selected
      if (patient.custom !== customValue) {
        patient.custom = customValue
      }

      const startIndex = options[STRUCTURE_KEYS.PATIENT].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.START_INDEX).selected
      const zeroPad = options[STRUCTURE_KEYS.PATIENT].addIndex.extra.find(extra => extra.name === CONTROLLER_NAME.ZERO_PAD).selected
      const index = `${pi + startIndex}`.toString().padStart(zeroPad, '0')

      const selectedOptionValue = options[STRUCTURE_KEYS.PATIENT].folderName.selected
      
      patient.selectedOptionValue = selectedOptionValue
      patient.selectedValue = `${patient[selectedOptionValue]}`
      
      const {title, key} = getData(STRUCTURE_KEYS.PATIENT, patient, index)

      patient.folderName = title
      patient.key = key
      dataKeys[STRUCTURE_KEYS.PATIENT].push(patient.key)

      for (const [si, study] of patient.studies.entries()) {
        if (study.folderName === undefined) {
          study.folderName = `${study[STUDY_INFO.ID]}`
        }

        if (study.all === undefined) {
          study.all = `${study.sid}-${study.desc}`
        }

        const selectedOptionValue = options[STRUCTURE_KEYS.STUDY].folderName.selected
        study.selectedOptionValue = selectedOptionValue
        study.selectedValue = `${study[selectedOptionValue]}`

        const {title, key} = getData(STRUCTURE_KEYS.STUDY, study, si + 1, patient.key)
        study.folderName = title
        study.key = key
        const dicomRootKey = `${study.key}_dicom`
        const niftiRootKey = `${study.key}_nifti`
        study.dicomRootKey = dicomRootKey
        study.niftiRootKey = niftiRootKey
        
        dataKeys[STRUCTURE_KEYS.DICOM].push(dicomRootKey)
        dataKeys[STRUCTURE_KEYS.NIFTI].push(niftiRootKey)


        for (const [sri, series] of study.series_list.entries()) {
          if (series.folderName === undefined) {
            series.folderName = `${series[DICOM_INFO.SERIES_NUMBER]}`
          }
          const selectedOptionValue = options[STRUCTURE_KEYS.DICOM].folderName.selected
          if (selectedOptionValue === DICOM_INFO.COMBINATION) {
            const combinationOptionValue = options[STRUCTURE_KEYS.DICOM].folderName.extra.find(extra => extra.name === DICOM_INFO.COMBINATION_LABEL).selected
            const combinationKey = combinationOptionValue.join('_')
            if (series[combinationKey] === undefined) {
              const folderName = combinationOptionValue.map(key => series[key]).join('-') || ""
              series[combinationKey] = folderName
              series.folderName = folderName
            }
            series.selectedOptionValue = combinationKey
            series.selectedValue = `${series[combinationKey]}`
          }
          else {
            series.selectedOptionValue = selectedOptionValue
            series.selectedValue = `${series[selectedOptionValue]}`
          }
          const {title, key} = getData(STRUCTURE_KEYS.DICOM, series, sri + 1, dicomRootKey)
          series.folderName = title
          series.key = key
          dataKeys.detail.push(series.key)
        }
        
        for (const [bi, blob] of study.blobs.entries()) {
          if (!blob.fnameWithoutExt) { // 폴더명 안에 nifti 파일이 있기 때문에 extention 없는 것을 초기 폴더명으로 설정
            const folderName = blob.fname.split('.nii')[0]
            blob.fnameWithoutExt = folderName
            if (blob.folderName === undefined) {
              blob.selectedOptionValue = NIFTI_INFO.FILE_NAME
              blob.selectedValue = `${folderName}`
            }
          }
          const {title, key} = getData(STRUCTURE_KEYS.NIFTI, blob, bi + 1, study.niftiRootKey)
          blob.folderName = title
          blob.key = key
          dataKeys.detail.push(blob.key)
        }
      }
    }

    const notSelectableStyle = {color: 'rgba(100,100,100, 0.8)', backgroundColor: 'rgba(0,0,0,0)', cursor: 'default'}
    const notSelectableClassName = 'not-selectable'
    
    // 할당된 값 가준으로 error check 및 treeData 제작
    const treeData = patients.map(patient => {
      const selectedValue = options[STRUCTURE_KEYS.PATIENT].folderName.selected
      let className
      let style
      let selectable = true
      if (selectedValue === PATIENT_INFO.CUSTOM) {
        className = notSelectableClassName
        style = notSelectableStyle
        selectable = false
      }
      
      const titleElement = getTitleElemnt(STRUCTURE_KEYS.PATIENT, patient, patients, style)
      
      return {
        ...patient,
        title: titleElement,
        isLeaf: false,
        style,
        className,
        selectable,
        children: patient.studies.map(study => {
          const titleElement = getTitleElemnt(STRUCTURE_KEYS.STUDY, study, patient.studies)
          
          return {
            ...study,
            title: titleElement,
            isLeaf: false,
            children: [
              {
                key: study.dicomRootKey,
                title: TitleElement({title: 'DICOM', style: notSelectableStyle}),
                style: notSelectableStyle,
                className: notSelectableClassName,
                isLeaf: false,
                dataType: 'dicom',
                selectable: false,
                children: study.series_list.map(series => {
                  const titleElement = getTitleElemnt(STRUCTURE_KEYS.DICOM, series, study.series_list)

                  const children = series => {
                    // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html 
                    // instance uid = max 64 bytes + .dcm -> 최대 70글자로 계산하여 250 - 70 = 이전 모든 경로에 대해 180글자로 제한하기
                    let result = [
                      {
                      key: `${series.key}_file_1`,
                      title: TitleElement({title: series.fname, style: notSelectableStyle}),
                      style: notSelectableStyle,
                      isLeaf: true,
                      selectable: false,
                      className: notSelectableClassName,
                      }
                    ]
                    if (series.image_count > 1) {
                      result.push({
                        key: `${series.key}_file_more`,
                        selectable: false,
                        className: notSelectableClassName,
                        style: notSelectableStyle,
                        title: TitleElement({title: `... ${series.image_count - 1} Files`, style: notSelectableStyle}),
                        isLeaf: true
                      })
                    }
                    return result
                  }

                  totalDownloadCounts += series.image_count
                  return {
                    ...series,
                    title: titleElement,
                    isLeaf: false,
                    children: children(series)
                  }
                })
              },
              {
                key: study.niftiRootKey,
                title: TitleElement({title: 'NIfTI', style: notSelectableStyle}),
                style: notSelectableStyle,
                className: notSelectableClassName,
                isLeaf: false,
                dataType: 'nifti',
                selectable: false,
                children: study.blobs.map(blob => {
                  const titleElement = getTitleElemnt(STRUCTURE_KEYS.NIFTI, blob, study.blobs)
                  totalDownloadCounts += 1
                  
                  return {
                    ...blob,
                    title: titleElement,
                    isLeaf: false,
                    children: [
                      {
                        key: `${blob.key}_file`,
                        title: TitleElement({title: blob.fname, style: notSelectableStyle}),
                        style: notSelectableStyle,
                        className: notSelectableClassName,
                        isLeaf: true,
                        selectable: false,
                      }
                    ]
                  }
                })
              }
            ]
          }
        }),
      }
    })    

    return {treeData, errorObject, totalDownloadCounts, patients, dataKeys}
  }

  const {treeData, errorObject, totalDownloadCounts, patients, dataKeys} = makeTreeData()

  const Footer = () => {
    const onCancel = () => dispatch(clearDownloadTargets())
    const onOk = () => {
      dispatch(downloadAction({patients, zipName: zipFileInfo.value}))
      dispatch(clearDownloadTargets())
    }
    
    const existInvalidFolderName = Object.values(errorObject).some(errorData => {
      return errorData.list.some(data => data.count > 0)
    })
    const notExistDownloadData = totalDownloadCounts === 0
    const okDisabled = existInvalidFolderName || notExistDownloadData || zipFileInfo.errorMsg.length > 0
    const okTooltipTitle = 
      (existInvalidFolderName && 'Resolve duplicate or disallowed folder names.') || 
      (notExistDownloadData && 'No data to download') || 
      (zipFileInfo.errorMsg.length > 0 && 'Change zip file name')
    
    return (
      <Space>
        <Button onClick={onCancel}>Cancel</Button>
        <Tooltip title={okTooltipTitle} open={okDisabled ? undefined : false}>
          <Button type='primary' disabled={okDisabled} onClick={onOk}>Ok</Button>
        </Tooltip>
      </Space>
    )
  }

  const onTreeItemSelect = (currSelectedKeys, _) => {
    const copiedSelectedKeys = [...selectedKeys]
    if (copiedSelectedKeys.includes(currSelectedKeys[0])) {
      setSelecteedKeys([])
    }
    else {
      setSelecteedKeys(currSelectedKeys)
    }
  }

  const onDirectClick = (direction, data, structureKey) => () => {
    let currIndex = scrollToInfo.index
    
    if (scrollToInfo.type === data.type && scrollToInfo.structureKey === structureKey) {
      if (direction === DIRECTION.RIGHT) {
        currIndex++
      }
      else {
        currIndex--
      }
      if (currIndex < 0) {
        currIndex = data.keys.length - 1
      }
      else if (currIndex >= data.keys.length) {
        currIndex = 0
      }
      scrollToInfo.index = currIndex
    }
    else {
      currIndex = 0
      scrollToInfo.type = data.type
      scrollToInfo.index = currIndex
    }
    scrollToInfo.structureKey = structureKey
    scrollToInfo.key = data.keys[currIndex]
    setScrollToInfo({...scrollToInfo})
    
    const viewPoint = expandOptions.find(option => option.label === structureKey)
    setExpandedKeys(viewPoint.value)
    
    setTimeout(() => treeRef.current.scrollTo({ key: scrollToInfo.key, align: 'top', offset: 100}), 100)
  }

  const getDateNow = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const dateTimeString = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    return dateTimeString
  }

  useEffect(() => {
    const initailValue = `Download_${patients.length}_patients_${getDateNow()}`
    zipFileInfo.value = initailValue
    setZipFileInfo({...zipFileInfo})
  },[])

  const zipFileNameOnChange = e => {
    const value = e.target.value
    zipFileInfo.value = value
    
    let errorMsg = []
    const invalidChars = checkPossiblePath(value)
    if (invalidChars.length > 0) {
      errorMsg.push(`Not allowed Characters[ ${invalidChars.join(', ')} ]`)
    }
    const isEmptyString = checkEmptyString(value)
    if (isEmptyString) {
      errorMsg.push(`Not allowed empty string`)
    }
    zipFileInfo.errorMsg = errorMsg

    setZipFileInfo({...zipFileInfo})
  }

  const onExpand = (currExpandedKeys, {expanded, node}) => {
    setExpandedKeys(currExpandedKeys)
  }

  const onSelectExpand = e => {
    setScrollToInfo(initialScrollToInfo)
    setExpandedKeys(e.target.value)
  }

  const expandOptions = [
    {
      label: 'Patient',
      value: []
    },
    {
      label: 'Study',
      value: dataKeys[STRUCTURE_KEYS.PATIENT]
    },
    {
      label: 'DICOM',
      value: dataKeys[STRUCTURE_KEYS.DICOM]
    },
    {
      label: 'NIfTI',
      value: dataKeys[STRUCTURE_KEYS.NIFTI]
    },
    {
      label: 'DICOM + NIfTI',
      value: [...dataKeys[STRUCTURE_KEYS.DICOM], ...dataKeys[STRUCTURE_KEYS.NIFTI]]
    },
    {
      label: 'Detail',
      value: [...dataKeys.detail]
    }
  ]

  useEffect(() => {
    setExpandedKeys(dataKeys.detail)
  },[downloadTargets])

  return (
    <Modal
      open={downloadTargets?.length > 0}
      width={1600}
      closable={false}
      title={'Please select download file structure settings'}
      footer={<Footer />}
      style={{top: 30, padding: 0}}
    >
      <Row gutter={[8,8]}>
        <Col span={optionColSpan}>
          <Row gutter={[8,8]}>
            <Col span={6}>
              <Typography.Text style={{float: 'right'}}>
                Zip file name: 
              </Typography.Text>
            </Col>
            <Col span={18}>
              <Input 
                value={zipFileInfo.value} 
                onChange={zipFileNameOnChange} 
                status={zipFileInfo.errorMsg.length > 0 && 'error'}
              />
              {zipFileInfo.errorMsg.map(msg => {
                return <div style={{color: 'rgba(255,64,64)'}}>{msg}</div>
              })}
            </Col>
          </Row>
          {
            Object.entries(options).map(([key, detailOptions]) => {
              const existError = errorObject[key].list.some(data => data.count > 0)
              return (
                <Col span={24}>
                  <Card bodyStyle={{padding: 12, backgroundColor: existError && 'rgba(255, 64, 64, 0.2)'}}>
                    <Typography.Title level={5}>{detailOptions.label}</Typography.Title>
                    <Row gutter={[8,8]}>
                      {
                        Object.entries(detailOptions).map(([detailKey, detailOption]) => {
                          if (detailKey === 'label') return <></>
                          
                          const labelColSpan = 7
                          const controllerColSpan = 24 - labelColSpan

                          const commonOnChange = () => { // option 변경 시 view 모드 해당 옵션에 맞게 변경
                            const viewPoint = expandOptions.find(option => option.label === key)
                            setExpandedKeys(viewPoint.value)
                          }

                          const optionRender = detailOption => {
                            switch (detailOption.type) {
                              case CONTROLLER_TYPE.RADIO:
                                {
                                  const radioOptions = detailOption.options
                                  const onChange = e => {
                                    const value = e.target.value
                                    detailOption.selected = value
                                    setOptions({...options})
                                    commonOnChange()
                                  }
                                  const value = detailOption.selected
                                  return (
                                    <Radio.Group 
                                      options={radioOptions}
                                      optionType='button'
                                      value={value}
                                      onChange={onChange}
                                    />
                                  )
                                }
                              case CONTROLLER_TYPE.SWITCH:
                                {
                                  const onChange = e => {
                                    detailOption.selected = e
                                    setOptions({...options})
                                    commonOnChange()
                                  }
                                  const checked = detailOption.selected
                                  return <Switch checked={checked} onChange={onChange}/>
                                }
                              case CONTROLLER_TYPE.INPUT:
                                {
                                  const onChange = e => {
                                    detailOption.selected = e.target.value
                                    setOptions({...options})
                                    commonOnChange()
                                  }
                                  return (
                                    <Input 
                                      showCount={inputFormatter}
                                      maxLength={40}
                                      onChange={onChange} 
                                      value={detailOption.selected} 
                                      style={detailOption.style}
                                    />
                                  )
                                }
                              case CONTROLLER_TYPE.INPUT_NUMBER:
                                {
                                  const onChange = e => {
                                    detailOption.selected = e
                                    setOptions({...options})
                                    commonOnChange()
                                  }
                                  return (
                                    <InputNumber 
                                      onChange={onChange} 
                                      min={detailOption.min} 
                                      max={detailOption.max} 
                                      defaultValue={detailOption.selected}
                                    />)
                                }
                              case CONTROLLER_TYPE.SELECT:
                                {
                                  const selectOptions = detailOption.options
                                  const onChange = e => {
                                    detailOption.selected = e
                                    setOptions({...options})
                                    commonOnChange()
                                  }
                                  return (
                                    <Select
                                      mode='multiple'
                                      allowClear
                                      style={detailOption.style}
                                      options={selectOptions}
                                      value={detailOption.selected}
                                      onChange={onChange}
                                      placeholder="Please select"
                                    />
                                  )
                                }
                              default:
                                return <></>
                            }
                          }

                          const extraRender = ({newLine=false}) => {
                            return detailOption.extra?.map(extra => {
                              if (extra.trigger === detailOption.selected) {
                                if (extra.label) {
                                  if (newLine) {
                                    return (
                                      <>
                                        <Col span={labelColSpan} style={{textAlign: 'right'}}>
                                          {extra.label} : 
                                        </Col>
                                        <Col span={controllerColSpan}>
                                          {optionRender(extra)}
                                        </Col>
                                      </>
                                    )
                                  }
                                }
                                else {
                                  if (!newLine) return optionRender(extra)
                                }
                              }
                              return <></>
                            })
                          }

                          return (
                            <>
                              <Col span={labelColSpan} style={{textAlign: 'right'}}>
                                {detailOption.label} : 
                              </Col>
                              <Col span={controllerColSpan}>
                                {optionRender(detailOption)}
                                {extraRender({})}
                              </Col>
                              {extraRender({newLine: true})}
                            </>
                          )
                        })
                      }
                    </Row>
                  </Card>
                </Col>
              )
            })
          }
        </Col>
        <Col span={treeColSpan}>
          <Row style={{marginBottom: 5, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <Col span={4}>
              <Typography.Text style={{float: 'right', marginRight: 5}}>
                View mode:
              </Typography.Text>
            </Col>
            <Col span={20}>
              <Radio.Group 
                options={expandOptions}
                optionType="button"
                onChange={onSelectExpand}
              />
            </Col>
          </Row>
          <DirectoryTree
            ref={treeRef}
            treeData={treeData}
            showLine
            defaultExpandAll={true}
            blockNode={true}
            showIcon={true}
            height={800}
            selectedKeys={selectedKeys}
            onSelect={onTreeItemSelect}
            expandAction={false}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            autoExpandParent={true}
          />
        </Col>
        <Col span={errorColSpan}>
          <Typography.Title level={5}>Error Information</Typography.Title>
          <Typography.Text>
            {
              Object.entries(errorObject).map(([key, info]) => {
                const existError = info.list.find(data => data.count > 0)
                return (
                  <>
                    {existError && 
                      <Card bodyStyle={{padding: 12}}>
                        <Typography.Title level={5}>{info.label}</Typography.Title>
                        {
                          info.list.map(data => {
                            const getErrorMessage = (type, count) => {
                              switch (type) {
                                case ERROR_TYPE.EMPTY:
                                  return `Empty folder name: ${count}`
                                case ERROR_TYPE.DUPLICATE:
                                  return `Duplicate folder name: ${count}`
                                case ERROR_TYPE.NOT_ALLOWED:
                                  return `Not allowed folder name: ${count}`
                                case ERROR_TYPE.TOO_LONG:
                                  return `Too long folder name: ${count}`
                                default:
                                  return ''
                              }
                            }

                            const currIndex = (scrollToInfo.structureKey === key) &&
                            (scrollToInfo.type === data.type) ? scrollToInfo.index : undefined

                            const initializeScrollToInfo = () => setScrollToInfo(initialScrollToInfo)

                            return (
                              <>
                                {data.count > 0 && 
                                  <pre 
                                    style={{
                                      display: 'flex', 
                                      justifyContent: 'space-between', 
                                      alignItems: 'center',
                                      color: data.color
                                    }}
                                  >
                                    {getErrorMessage(data.type, data.count)}
                                    <Space>
                                      <CaretLeftOutlined 
                                        style={{cursor: 'pointer'}} 
                                        onClick={onDirectClick(DIRECTION.LEFT, data, key)}
                                      />
                                      {currIndex !== undefined && 
                                        <span 
                                          onClick={initializeScrollToInfo}
                                          style={{cursor: 'pointer'}}
                                        >
                                          {`${currIndex + 1}/${data.count}`}
                                        </span>
                                      }
                                      <CaretRightOutlined 
                                        style={{cursor: 'pointer'}} 
                                        onClick={onDirectClick(DIRECTION.RIGHT, data, key)}
                                      />
                                    </Space>
                                  </pre>
                                }
                              </>
                            )
                          })
                        }
                      </Card>
                    }
                  </>
                )
              })
            }
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  )
}

export default DownloadPatientPathSettingModal