export const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

// socket 
export const NAMESPACE = '/socket'
export const EVT_ANALYSIS = 'analysis'
export const EVT_PATIENT = 'patient'
export const EVT_CLINIC_PROGRESS = 'clinic-progress'