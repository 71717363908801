import {useHistory, useLocation} from "react-router-dom";
import {Menu} from "antd";
import React, { useEffect } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faListCheck, faUpload, faUserGroup, faTimeline, faXRay, 
  faCircleExclamation, faDatabase, faInbox, faBarsProgress} from "@fortawesome/free-solid-svg-icons";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateDrawerOpen } from "../redux/modules/longTask";
import { usePreventLeave } from "../context/preventLeave";
import { toggleOpen as messageToggleOpen } from "../redux/modules/message";

function SiderMenu() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory()

  const finishLength = useSelector(state =>state.longTask.tasks.filter(task => task.ended).length);
  const taskLength = useSelector(state=>state.longTask.tasks.length);
  const sidebar_open = useSelector(state => state.config.sidebar_open);
  const landingPage = useSelector(state => state.config.landing_url);

  const handleMenuClick = e => history.push(e.key)

  const {enablePrevent, disablePrevent} = usePreventLeave();

  useEffect(()=>{
    if (finishLength === taskLength) {
      disablePrevent()
    }
    else {
      enablePrevent()
    }
  },[finishLength, taskLength])

  const logoMargin = 4
  const logoWidth = (sidebar_open ? 140 : 30) - (2 * logoMargin)
  const logoHeight = 40
  const paddingSize = 12

  const onLogoClick = () => history.push(landingPage)

  return (
    <div style={{backgroundColor: '#0C2329'}}>
      <div 
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: (logoHeight + 2 * paddingSize),
          cursor: 'pointer',
          paddingTop: paddingSize, 
          paddingBottom: paddingSize,
        }}
        onClick={onLogoClick}
      >
        {
          sidebar_open
          ? <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" width={logoWidth}/>
          : <img src={process.env.PUBLIC_URL + '/logo_icon.png'} alt="Logo" width={logoWidth}/>
        }
      </div>
      <Menu
        style={{borderRight: 'none'}}
        mode="inline"
        defaultSelectedKeys={["/"]}
        selectedKeys={[location.pathname.split('/').slice(0, 2).join('/')]}
      >
        <Menu.Item key="/notice" icon={<FontAwesomeIcon icon={faCircleExclamation} />} onClick={handleMenuClick}>
          Notice
        </Menu.Item>
        <Menu.Item key="/upload" icon={<FontAwesomeIcon icon={faUpload}/>} onClick={handleMenuClick}>
          Upload
        </Menu.Item>
        <Menu.Item key="/patient" icon={<FontAwesomeIcon icon={faUserGroup} />} onClick={handleMenuClick}>
          Patient
        </Menu.Item>
        <Menu.Item key="/dataset" icon={<FontAwesomeIcon icon={faDatabase} />} onClick={handleMenuClick}>
          Dataset
        </Menu.Item>
        <Menu.Item key="/pipeline" icon={<FontAwesomeIcon icon={faTimeline}/>} onClick={handleMenuClick}>
          Pipeline
        </Menu.Item>
        <Menu.Item key="/analysis" icon={<FontAwesomeIcon icon={faListCheck}/>} onClick={handleMenuClick}>
          Result
        </Menu.Item>
        <Menu.Item key="/modality" icon={<FontAwesomeIcon icon={faXRay}/>} onClick={handleMenuClick}>
          Image type
        </Menu.Item>
        <Menu.Item key='/message' icon={<FontAwesomeIcon icon={faInbox}/>} onClick={() => dispatch(messageToggleOpen())}>
          Message
        </Menu.Item>
        <Menu.Item
          key="/longTask"
          icon={finishLength < taskLength 
            ? <LoadingOutlined /> 
            : <FontAwesomeIcon icon={faBarsProgress} />
          }
          onClick={() => dispatch(updateDrawerOpen(true))}
        >
          Progress
        </Menu.Item>
      </Menu>
    </div>
  );
}

export default SiderMenu;