import { Button, Table, Space, Popconfirm } from "antd";
import React from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {clear, getList as load, del} from "../../redux/modules/notice";
import useListPageContext from '../../context/listPageContext'
import SearchBox from "../searchBox";
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useTitle} from '../../hooks/title'
import { created_column } from "../createdRender";

const searchOptions = [
  {value : "title", label : "Title", dataIndex : 'title'},
  {value : 'content', label : 'Content', dataIndex : 'content'}
]

function NoticeList() {
  useTitle('DYNAPEX | Notice')
  const history = useHistory();
  const admin = useSelector(state=> state.auth.user?.admin);
  const list = useSelector(state=>state.notice?.list);
  const loading = useSelector(state=>state.notice.loading);
  const total = useSelector(state=> state.notice?.total)

  const {
    rowSelection, 
    handleSearch, 
    fetchDelete,
    searchHighlight,
    pagination,
    Footer
 } = useListPageContext({load, del, clear, searchOptions, total});

  const handleWrite = () => history.push("/notice/edit")
  const handleView = record => history.push(`/notice/detail`, { key : record.key })
  const handleEdit = record => history.push('/notice/edit', { key : record.key })
  const handleRemove = () => fetchDelete()

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "50%",
      ellipsis: {
        showTitle: false,
      },
      render : text => searchHighlight(text, 'title')
    },
    {
      title: "Author",
      dataIndex: "user",
      key: "user",
      align: "center",
    },
    created_column,
    {
      title : 'Actions',
      key : 'action',
      width: '15%',
      fixed : 'right',
      align : 'center',
      render : (record) => {
        return <Space>
          <Button onClick={() => handleView(record)}>View</Button>
          {admin ? <Button onClick={() => handleEdit(record)}>Edit</Button> : null} 
        </Space>
      }
    }
  ]

  const adminOption = admin ? {
    rowSelection,
    footer: Footer,
    pagination: false
  } : null

  return (
    <div style={{ width: "100%" }}>
      <h1>Notice</h1>
      {admin ? 
      <div style={{display: "inline-block"}}>
        <Space>
          <Button onClick={handleWrite}>
            Write
          </Button>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}} />}
            onConfirm={handleRemove}
          >
            <Button>Remove</Button>
          </Popconfirm>
        </Space>
      </div>
      : null}
      <SearchBox
        style={{display: "inline-block", float: 'right', width:"50%"}}
        options={searchOptions}
        onSearch={handleSearch}
      />
      <Table
        columns={columns}
        dataSource={list}
        loading={loading}
        pagination={pagination}
        rowKey="key"
        {...adminOption}
      />
    </div>
  );
}

export default NoticeList;
