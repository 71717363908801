export const SET_USER_CONFIG = 'config/SET_USER_CONFIG'
export const TOGGLE_SIDER = 'config/TOGGLE_SIDER'
export const TOGGLE_ANALYSIS_TITLE = 'config/TOGGLE_COLLAPSE'
export const CHANGE_PER_PAGE = 'config/CHANGE_PER_PAGE'
export const CHANGE_PER_PAGE_FOR_DETAIL = 'config/CHANGE_PER_PAGE_FOR_DETAIL'
export const TOGGLE_PATIENT_TABLE_SPLIT = 'config/TOGGLE_PATIENT_TABLE_SPLIT'
export const UPDATE = 'config/UPDATE'
export const UPDATE_SUCCESS = 'config/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'config/UPDATE_FAIL'

const initialState = {
  changed : {
    status : false
  },
  locale: 'ko_KR',
  theme: 'light',
  sidebar_open: true,
  per_page : 10,
  landing_url : '/upload',
  per_page_for_detail : 10,
  analysis_detail_title_open : false,
  patient_detail_table_split: true
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_USER_CONFIG:
      return {
        ...state,
        ...action.payload
      }
    case TOGGLE_SIDER:
      return {
        ...state,
        sidebar_open: !state.sidebar_open,
        changed : {
          ...state.changed,
          status : true,
          sidebar_open: !state.sidebar_open,
        }
      }
    case TOGGLE_ANALYSIS_TITLE:
      return {
        ...state,
        analysis_detail_title_open : !state.analysis_detail_title_open,
        changed : {
          ...state.changed,
          status : true,
          analysis_detail_title_open : !state.analysis_detail_title_open,
        }
      }
    case CHANGE_PER_PAGE:
      return {
        ...state,
        per_page : action.payload,
        changed : {
          ...state.changed,
          status : true,
          per_page : action.payload,
        }
      }
    case CHANGE_PER_PAGE_FOR_DETAIL:
      return {
        ...state,
        per_page_for_detail : action.payload,
        changed : {
          ...state.changed,
          status : true,
          per_page_for_detail : action.payload,
        }
      }
    case TOGGLE_PATIENT_TABLE_SPLIT:
      return {
        ...state,
        patient_detail_table_split: !state.patient_detail_table_split,
        changed: {
          ...state.changed,
          status: true,
          patient_detail_table_split: !state.patient_detail_table_split,
        }
      }
    case UPDATE:
      return {
        ...state,
        loading : true
      }
    case UPDATE_SUCCESS:
      return {
        ...initialState,
        ...action.payload,
        loading : false,
      }
    case UPDATE_FAIL:
      return {
        ...state,
        loading : false
      }
    default:
      return state;
  }
}

export function setUserConfig(payload) {
  return {type: SET_USER_CONFIG, payload}
}

export function toggleSider() {
  return {type: TOGGLE_SIDER}
}

export function toggleAnalysisTitle() {
  return {type: TOGGLE_ANALYSIS_TITLE}
}

export function changePerPage(payload) {
  return {type: CHANGE_PER_PAGE, payload}
}

export function changePerPageForDetail(payload) {
  return {type: CHANGE_PER_PAGE_FOR_DETAIL, payload}
}

export function togglePatientTableSplit() {
  return {type: TOGGLE_PATIENT_TABLE_SPLIT}
}

export function update(payload) {
  return {type: UPDATE, payload}
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload}
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload}
}

