import React, {useState, useEffect, useContext, useRef } from "react";
import {Button, Radio, Space, Tooltip, Slider, Select, Popover, Divider, InputNumber} from "antd";
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {MenuUnfoldOutlined, MenuFoldOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faA, faC, faChartArea, faChartColumn, faCircleDot, faCircleHalfStroke, faCrosshairs, faFileLines,
  faHouse, faS, faWandMagic,
} from "@fortawesome/free-solid-svg-icons";
import ButtonGroup from "./buttonGroups";
import SegmentationContext from "../context/segmentationContext";
import MultiSegContext from "../context/multiSegmentationContext";
import * as cornerstoneTools from "cornerstone-tools";
import {getUrl} from "../redux/modules/view";
import {useDispatch, useSelector} from "react-redux";
import Toolbar from "@uiw/react-md-editor/lib/components/Toolbar";
import {prepareProcessingPerUrl, update} from "./taskResultViewer";
import { NavigateModal } from "./analysisComponents";
import { openNavigateModal } from "../redux/modules/analysis";
import ModelName from "./modelName";
import { TASK_NAME_MODEL_PREDICTION } from "../lib/taskUtil";
import AnalysisQuickViewModal from "./analysisQuickView";

const segmentationModule = cornerstoneTools.getModule('segmentation');

const cmdNames = ['Statistics', 'Histogram', 'Scatter']

export const MODE = Object.freeze({SINGLE: 0, MULTI: 1}) // cross-sectional or longitudinal
function ViewerToolbar(
  {
    viewerState, viewerDispatch,
    mode, loadTarget,
    // handleReset,
    // handleMouseLeftToolChange,
    // acsState, handleStats, dateState, handleDate,
    lesionTrack, lesionNav, rightSider,
  }
) {
  const {
    // loading,
    // progress,
    // processing,
    acs,
    dates,
    datesState,
    cmds,
    // inputs,
    // outputs,
    urls,
    // mapsOnly,
    // mapCount,
    // windowSize,
    // viewWidth,
    // allViewWidth,
    // showRightSider,
    // openPopOver,
    openModal,
    // imageTypeSelected,
    // reset,
    // locate,
    // navData,
    tools
  } = viewerState

  const refs = useRef([]);
  const buttonBlur = () => {
    refs.current.forEach(ref => ref.blur())
  }
  const {segmentations:segmentations1, setAction:setActionSeg1} = useContext(SegmentationContext);
  const {segmentations:segmentations2, setAction:setActionSeg2} = useContext(MultiSegContext);
  const segExist = mode === MODE.SINGLE ? segmentations1?.length > 0 : Object.keys(segmentations2).length > 0
  const setActionSeg = mode === MODE.SINGLE ? setActionSeg1 : setActionSeg2

  const handleChangeSlider = (value) => {
    segmentationModule.configuration.fillAlpha = value / 100
    // setActionSeg({type: 'changeOption', url:url, option: newOption})
    setActionSeg({type: 'changeOption'})  // 나중에 url 을 기준으로 다른 옵션 적용가능하도록 변경
  }
  const handleChangeSlider2 = (value) => {
    segmentationModule.configuration.outlineAlpha = value / 100
    // setActionSeg({type: 'changeOption', url:url, option: newOption})
    setActionSeg({type: 'changeOption'})  // 나중에 url 을 기준으로 다른 옵션 적용가능하도록 변경
  }

  const dispatch = useDispatch();
  const analysis = useSelector(state => state.analysis);
  const quickViewOpen = useSelector(state => state.analysis?.quickView?.open)

  const handleReset = () => {
    viewerDispatch(update({reset: {doReset: true}}))
  }

  const handleAutoWWWC = () => {
    viewerDispatch(prepareProcessingPerUrl({mapsOnly: true}))
    viewerDispatch(update({autowwwc: true}))
  }

  const handleMouseLeftToolChange = event => {
    const newTools = {...tools}
    switch (event.target.value) {
      case 'WWWC':
        newTools.clear()
        newTools.wwwc = true
        break
      case 'Probe':
        newTools.clear()
        newTools.dragprobe = true
        break
      case 'Crosshair':
        newTools.clear()
        newTools.referencelines = true
        newTools.crosshair = true
        break
      default:
        break
    }
    viewerDispatch(update({tools: newTools}))
  }
  const handleACS = (buttonIdx, values) => {
    viewerDispatch(update({acs: values}))
  }
  const handleDate = (buttonIdx, values) => {
    // setDate(values)
    viewerDispatch(update({datesState: values}))
  }
  const handleCmds = (buttonIdx, values) => {
    viewerDispatch(update({
      ...(values[buttonIdx] && {openModal: true}),
      ...(values[buttonIdx] && {modalType: cmdNames[buttonIdx]}),
      ...(buttonIdx === 0 && !values[buttonIdx] && {stats: {...viewerState.stats, execute: false}}),
      ...(buttonIdx === 1 && !values[buttonIdx] && {histogram: {...viewerState.histogram, execute: false}}),
      ...(buttonIdx === 2 && !values[buttonIdx] && {scatter: {...viewerState.scatter, execute: false}}),
      cmds: {
        ...cmds,
        states: [...values]
      },
    }))
  }

  const [lesionIndex, setLesionIndex] = useState(0)
  useEffect(() => {
    setLesionIndex(0)
  }, [urls])
  const handleLesionNavSelect = (value, option) => {
    setLesionIndex(value)
  }

  const onNavigateOpen = () => dispatch(openNavigateModal())

  const popoverContent = () => {
    const volumeInfo = analysis.display.volumeInfo
    const {patientId, patientName, order, task_index, caseInfo} = volumeInfo

    if (caseInfo == null) {
      return null
    }
    
    const patientInfo = {
      ID: patientId,
      Name: patientName,
      Birth: caseInfo[0].birth.split(' ')?.[0],
      Sex: caseInfo[0].sex
    }
    const studyInfoList = caseInfoList => caseInfoList.map(caseInfo => ({
      ID: caseInfo.sid,
      Desc: caseInfo.desc,
      Date: caseInfo.date.split(' ')?.[0],
    }))
    
    const task = analysis.tasks[task_index]
    const getTaskNameElement = task => {
      const taskTemplates = analysis.taskTemplates
      const template_id = task.task_template_id
      const selectTemplate = taskTemplates.find(template => template.id === parseInt(template_id))
      if (selectTemplate?.name === TASK_NAME_MODEL_PREDICTION) {
        return (
          <div style={{display: 'inline-block'}}>
            <p style={{margin : 0}}>{selectTemplate?.name}</p>
            <ModelName name={task.config?.model}/>
          </div>
        )
      }
      return selectTemplate?.name
    }
    const taskInfo = {
      Order: order,
      Name: getTaskNameElement(task)
    }
    
    const information = [
      {
        title: 'Patient',
        info: patientInfo
      },
      {
        title: 'Study',
        info: studyInfoList(caseInfo),
        list: true
      },
      {
        title: 'Task',
        info: taskInfo
      
      },
    ]

    const content = dataInfo => {
      return Object.entries(dataInfo).map(([key, value]) => {
        return (
          <p key={key} style={{margin : 0}}>
            <div style={{display: 'inline-block', marginRight: 10, verticalAlign: 'top'}}>{key}: </div>
            <div style={{display: 'inline-block'}}>{value}</div>
          </p>
        )
      })
    }

    return (
      <div>
        {information.map((data, index) => {
          return (
            <>
              <h4>{data.title}</h4>
              {data.list ? 
                data.info.map((dataInfo, dataInfoIndex) => {
                  return (
                    <>
                      {content(dataInfo)}
                      {dataInfoIndex !== data.info.length-1 && <br/>}
                    </>
                  )
                }):
                content(data.info)
              }
              {index !== information.length - 1 && 
                <Divider 
                  style={{
                    marginTop: 10, 
                    marginBottom: 10, 
                    borderColor: 'rgba(128,128,128,0.5)'
                  }}/>
              }
            </>
          )})
        }
      </div>
    )
  }

  return (
    <div style={{display: 'flex', backdropFilter: 'blur(10px)'}}>
    {/*<div style={{display: 'flex', width: actualInnerWidth, backdropFilter: 'blur(10px)'}}>*/}
      <Space>
        <Tooltip title="Reset" getPopupContainer={triggerNode => triggerNode.parentNode}>
          <Button size="large" icon={<FontAwesomeIcon icon={faHouse}/>} onClick={handleReset}/>
        </Tooltip>
        <Tooltip title="Auto-contrast" getPopupContainer={triggerNode => triggerNode.parentNode}>
          <Button icon={<FontAwesomeIcon icon={faWandMagic}/>} size="large" onClick={handleAutoWWWC}/>
        </Tooltip>
        <Radio.Group onChange={handleMouseLeftToolChange} value={tools.wwwc ? "WWWC" : tools.dragprobe ? "Probe" : "Crosshair"} size="large">
          <Tooltip title="WW/WC" getPopupContainer={triggerNode => triggerNode.parentNode} >
            <Radio.Button value="WWWC" ref={el => refs.current[0] = el} onClick={buttonBlur}>
              <FontAwesomeIcon icon={faCircleHalfStroke}/>
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Probe" getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Radio.Button value="Probe" ref={el => refs.current[1] = el} onClick={buttonBlur}>
              <FontAwesomeIcon icon={faCircleDot}/>
            </Radio.Button>
          </Tooltip>
          <Tooltip title="Crosshair" getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Radio.Button value="Crosshair" ref={el => refs.current[2] = el} onClick={buttonBlur}>
              <FontAwesomeIcon icon={faCrosshairs}/>
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
        <ButtonGroup
          states={acs}
          buttons={['Axial', 'Coronal', 'Sagittal']}
          icons={[faA, faC, faS]}
          onChange={handleACS}
          keepLastOne={true}
          keepOnlyOne={mode === MODE.MULTI}
        />
        {mode === MODE.MULTI ?
          <ButtonGroup
            states={datesState}
            buttons={dates}
            onChange={handleDate}
            keepLastOne={true}
          /> : null
        }
        <ButtonGroup
          states={cmds.states}
          shows={cmds.shows}
          viewerDispatch={viewerDispatch}
          buttons={cmdNames}
          icons={[faFileLines, faChartColumn, faChartArea]}
          onChange={handleCmds}
        />
        {lesionTrack?.visible ? <Button onClick={lesionTrack.handler}>Track ROI</Button> : null}
        {lesionNav?.visible ?
          <Tooltip
            title='Locate lesions'
            placement="bottomRight"
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <Button
              style={{padding: '4px, 12px'}}
              disabled={lesionNav?.coordinates?.length === 0}
              onClick={() => {
                const newIndex = lesionIndex - 1 === -1 ? lesionIndex : lesionIndex - 1
                setLesionIndex(newIndex)
                lesionNav?.handler(lesionNav?.coordinates?.[newIndex])
              }}>
              {"<"}
            </Button>
            <Select
              disabled={lesionNav?.coordinates?.length === 0}
              value={lesionIndex}
              options={
                lesionNav?.coordinates?.map((coord, i) => ({value: i, label: i + 1}))
              }
              onChange={handleLesionNavSelect}
              style={{width: 66}}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            />
            <Button
              style={{padding: '4px, 12px'}}
              disabled={lesionNav?.coordinates?.length === 0}
              onClick={() => {
                const newIndex = lesionIndex + 1 === lesionNav?.coordinates?.length ? lesionIndex : lesionIndex + 1
                setLesionIndex(newIndex)
                lesionNav?.handler(lesionNav?.coordinates?.[newIndex])
              }}
            >
              {">"}
            </Button>
            <Button
              type="primary"
              disabled={lesionNav?.coordinates?.length === 0}
              onClick={() => lesionNav?.handler(lesionNav?.coordinates?.[lesionIndex])}
            >Go</Button>
          </Tooltip>
          : null}
        {segExist ?
          <Tooltip>
            Segmentation fill opacity
            <Slider
              style={{width: 150, marginTop: 0, marginBottom: 0}}
              onChange={handleChangeSlider}
              // defaultValue={}
              value={segmentationModule.configuration.fillAlpha * 100}
              tooltip={{getPopupContainer: triggerNode => triggerNode.parentNode}}
            />
          </Tooltip> : null
        }
        {segExist ?
          <Tooltip>
            Segmentation outline opacity
            <Slider
              style={{width: 150, marginTop: 0, marginBottom: 0}}
              onChange={handleChangeSlider2}
              // defaultValue={segmentationModule.configuration.outlineAlpha * 100}
              value={segmentationModule.configuration.outlineAlpha * 100}
              tooltip={{getPopupContainer: triggerNode => triggerNode.parentNode}}
            />
          </Tooltip> : null
        }
      </Space>
      <Space style={{marginLeft: 'auto'}}>
        <Popover 
          content={popoverContent()} 
          placement="bottomRight"
          mouseEnterDelay={0.7}
        >
          <Button onClick={onNavigateOpen}>Navigate</Button>
        </Popover>
        {rightSider ?
          <Tooltip
            title={rightSider?.visible ? "Hide options" : "Show options"}
            getPopupContainer={triggerNode => triggerNode.parentNode}>
            <Button
              icon={rightSider?.visible ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
              onClick={() => rightSider?.handler()}
            />
          </Tooltip>
          : null
        }
      </Space>
      <NavigateModal />
      {quickViewOpen &&<AnalysisQuickViewModal />}
    </div>
  )
}

ViewerToolbar.defaultProps = {
  mode: MODE.SINGLE
}

export default ViewerToolbar;
