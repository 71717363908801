import React from "react";
import { Button, Table, Space, Popconfirm} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector} from "react-redux";
import { load, del, clear, delAskCancel, delAskOk } from "../redux/modules/blobTypes"
import {useHistory, useLocation} from "react-router-dom";
import SearchBox from "./searchBox";
import useListPageContext from "../context/listPageContext";
import {useTitle} from "../hooks/title";
import AskModal from "./askModal";
import { Colormap } from "../lib/colormap";
import { Highlight } from "../context/searchHighlightContext";
import { TagBase } from "./tagBase";

const searchOptions = [
  {value:"name", label:"Name", dataIndex : 'name'},
  {value:"short", label:"Shorthand", dataIndex : 'short'},
]

function ModalityList() {
  useTitle('DYNAPEX | Image types')
  const history = useHistory();
  const dispatch = useDispatch()
  const loading = useSelector(state => state.blobTypes?.loading)
  const list = useSelector(state=>state.blobTypes?.list)
  const total = useSelector(state => state.blobTypes?.total)
  const deleteAsk = useSelector(state => state.blobTypes?.delAsk)
  const targetData = deleteAsk?.targetData
  const location = useLocation()

  const {
    pagination, 
    rowSelection, 
    handleSearch, 
    fetchDelete,
    searchHighlight,
    Footer
  } = useListPageContext({load, del,clear, searchOptions, total});

  const handleCreate = () => history.push('/modality/detail')
  const handleEdit = record => history.push('/modality/detail', {key: record.id})
  const handleDelete = () => fetchDelete()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render : text => searchHighlight(text, 'name')
    },
    {
      title: 'Tag',
      dataIndex : 'short',
      key: 'short',
      render: (text, record) => {
        // for (text color:  white)
        const category = location.state?.pagination?.category
        const keyword = location.state?.pagination?.keyword
        const value = category === 'short' ? Highlight(keyword, record.short) : record.short
        return <TagBase
          color={record.tag_color}
          children={value}
        />},
    },
    {
      title: 'Segmentation',
      dataIndex: 'seg',
      render: val => val ? 'O' : 'X',
      align: 'center',
    },
    {
      title : 'Colormap',
      dataIndex : 'cmap_id',
      align: 'center',
      render : (cmap_id, record) => {
        return Colormap({seg: record.seg, seg_cmap: record.seg_cmap, cmap_id})
      }
    },
    {
      title: 'Actions',
      key: 'action',
      width: 140,
      fixed : 'right',
      align : 'center',
      render: (record) => (
        <Space>
          <Button style={{margin: '1px'}} onClick={() => handleEdit(record)}> Edit </Button>
        </Space>
      ),
    },
  ];

  const handleAskOk = () => {
    dispatch(delAskOk())
    fetchDelete({keys:targetData, approve:true})
  }
  const hadleAskCancel = () => dispatch(delAskCancel())

  const handleTest = () => history.push('/modality/compare_binding')

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <h1>Image type</h1>
      <div style={{display: "inline-block"}}>
        <Space>
          <Button onClick={handleCreate}> Create </Button>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={handleDelete}
          >
            <Button>Remove</Button>
          </Popconfirm>
          <Button onClick={handleTest}>Compare bindings</Button>
        </Space>
      </div>
      <SearchBox
        style={{display: "inline-block", float: 'right', width:"50%"}}
        options={searchOptions}
        onSearch={handleSearch}
      />
      <Table
        pagination={{...pagination, position: []}}
        footer={Footer}
        loading={loading}
        columns={columns}
        dataSource={list}
        rowSelection={rowSelection}
      />
      <AskModal 
        askData={deleteAsk} 
        handleOk={handleAskOk} 
        handleCancel={hadleAskCancel} 
        loading={loading} 
      />
    </div>
  );
}

export default ModalityList;

