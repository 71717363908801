import React, { useState, useEffect, useRef } from "react";
import * as cornerstoneTools from "cornerstone-tools";
import chroma from "chroma-js";
const segmentationModule = cornerstoneTools.getModule('segmentation');

export const MultiSegContext = React.createContext({})
MultiSegContext.displayName = 'MultiSegmentationOverlay';

export const MultiSegContextProvider = ({ children }) => {
  // const [segmentations, setSegmentations] = useState([]);
  const segmentations = useRef({});
  const [action, setAction] = useState({});
  const toggleSegmentation = (manual, studyId, volumeIndex, url, option, stackX, stackY, stackZ, metaData=undefined) => {
    // 새로 만들거나 없애거나
    const studyFound = studyId in segmentations.current
    const studySegmentations = segmentations.current[studyId]
    const segFound = studySegmentations?.find(i => i.url === url)
    if (studyFound && segFound) {
      segmentations.current[studyId] = studySegmentations.filter(item => item.url !== url)
      setAction({type: 'remove', index: segFound.labelmapIndex, manual, studyId})
      return false
    }
    else {
      const newLabelmapIndex = volumeIndex

      // new colormap
      const csToolLUT = option.colorLUT.map(i => chroma(i.color).rgb().concat(255))
      const labelmap3DOrColorLUTIndex = newLabelmapIndex // NOTE ColorLUT 도 동일한 index 를 줌
      segmentationModule.setters.colorLUT(labelmap3DOrColorLUTIndex) // colorLUT 생성
      option.colorLUT.forEach(item => {
        const segmentIndex = item.value
        const colorArray = chroma(item.color).rgb().concat(255)
        segmentationModule.setters.colorForSegmentIndexOfColorLUT(labelmap3DOrColorLUTIndex, segmentIndex, colorArray)
      })

      const newSeg = {
        labelmapIndex: newLabelmapIndex,
        url,
        option,
        x: stackX,
        y: stackY,
        z: stackZ,
        metaData
      }
      segmentations.current = {...segmentations.current}
      if (!studyFound) {
        segmentations.current[studyId] = [newSeg]
      }
      else {
        segmentations.current[studyId] = [...segmentations.current[studyId], newSeg]
      }
      setAction({type: 'add', index: newLabelmapIndex, manual, studyId})
      return true
    }
  }

  const cleanup = (studyId, url) => {
    let studySegmentations = segmentations.current[studyId]
    if (studySegmentations) {
      const seg = studySegmentations.find(seg => seg.url === url)
      if (seg) {
        segmentations.current[studyId] = studySegmentations.filter(item => item.url !== url)
        setAction({type: 'remove', index: seg.labelmapIndex, manual: false, studyId})
        seg.x = null
        seg.y = null
        seg.z = null
      }
      if (studySegmentations.every(s => s.x === null && s.y === null && s.z === null)) {
        studySegmentations = []
      }
    }

    if (Object.values(segmentations.current).every(studySegmentations => studySegmentations.length === 0)) {
      segmentations.current = {}
      const segModuleState = segmentationModule.state
      segModuleState.series = {}
      segModuleState.colorLutTables = []
    }
  }

  return (
    <MultiSegContext.Provider
      value={{ segmentations: segmentations.current, toggleSegmentation, action, setAction, cleanup }}
    >
      {children}
    </MultiSegContext.Provider>
  );
};
export const MultiSegContextConsumer = MultiSegContext.Consumer;

export default MultiSegContext;