import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader';
import {objectsEqual, arraysEqual} from "../lib/compare";

export function formatWindowLevelString(value) {
  if (value) {
    const longValue = value.toFixed(2).length > 7
    var r = value.toFixed(20).match(/^-?\d*\.?0*\d{0,2}/)[0];
    if (longValue && r.length > 4) {
      return Number.parseFloat(value).toExponential(2)
    }
    else {
      return value.toFixed(2)
    }
  }
}

export function buildNiftiStack(niftiImageIdObj, cornerstoneImageObj, options) {
  const multiFrameMeta = cornerstone.metaData.get('multiFrame', cornerstoneImageObj.imageId)
  if (multiFrameMeta !== undefined) {
    const numberOfSlices = multiFrameMeta.numberOfFrames;
    return {
      currentImageIdIndex: Math.ceil(numberOfSlices / 2),
      imageIds: Array.from({length: numberOfSlices},
        (_, i) => {
          return `nifti:${niftiImageIdObj.filePath}#${niftiImageIdObj.slice.dimension}-${i},t-0`
        }),
      options: {
        ...options,
        viewport: {
          ...options.viewport,
          // voi: {
          //   ...options.viewport.voi
          // }
        }
      },
    };
  }
  else {
    return undefined
  }
}

export function convertToVector3(arrayOrVector3) {
  if (arrayOrVector3 instanceof cornerstoneMath.Vector3) {
    return arrayOrVector3;
  }

  const keys = Object.keys(arrayOrVector3);

  if (keys.includes('x') && keys.includes('y') && keys.includes('z')) {
    return new cornerstoneMath.Vector3(
      arrayOrVector3.x,
      arrayOrVector3.y,
      arrayOrVector3.z
    );
  }

  return new cornerstoneMath.Vector3(
    arrayOrVector3[0],
    arrayOrVector3[1],
    arrayOrVector3[2]
  );
}

export function concatTypedArrays(typedArrays) {
  const totalLength = typedArrays.reduce((acc, value) => acc + value.length, 0)
  const result = new typedArrays[0].constructor(totalLength)
  let offset = 0
  for (let arr of typedArrays) {
    result.set(arr, offset)
    offset += arr.length
  }
  return result
}

export function calcMinMaxSlice(arr, niftiMetaData) {
  arr.sort()

  // find zero
  const hashmap = arr.reduce((acc, val) => {
    acc[val] = (acc[val] || 0 ) + 1
    return acc
  }, {})
  const mode = parseInt(Object.keys(hashmap).reduce((a, b) => hashmap[a] > hashmap[b] ? a : b))
  const filtered = arr.filter(i => i > mode)
  // const nonZeroArr = new Float32Array(filtered.buffer, filtered.byteOffset, filtered.byteLength / Float32Array.BYTES_PER_ELEMENT);
  let nonZeroArr = new Float32Array(filtered.length);
  for(let i=0; i < filtered.length; i++) {
    nonZeroArr[i] = filtered[i];
  }

  const n = nonZeroArr.length
  const mean = nonZeroArr.reduce((a, b) => a + b, 0) / n
  const std = Math.sqrt(nonZeroArr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)

  // 6 sigma outlier (= {max - mean}/stdev > 6 stdev)
  // const maxSigma = (niftiMetaData.maxPixelValue - mean) / std
  const sigmaTests = nonZeroArr.map(i => (i - mean) / std)
  const newMaxIndex = sigmaTests.findIndex(i => i >= 2.58)  // 99% == 2.58 sigma
  const newMax = newMaxIndex >= 0 ? nonZeroArr[newMaxIndex] : nonZeroArr.at(-1)

  let skewness = arr.reduce((acc, val) => acc + (val - mean) ** 3, 0) / n;
  skewness /= std ** 3;
  return {min:nonZeroArr[0], max:newMax, skewness}
}

export function computeAutoVoi (niftiMetaData, viewport, image = undefined, min= undefined, max= undefined, skewness = undefined) {
  // TODO ktrans는 정규분포가 아니어서 중간값을 window center로 하면 안됨
  if (image && image.color) {
    return // color 이미지는 auto-contrast 불가
  }

  // dicom 인 경우 niftiMetaData 자체가 없음
  if (!niftiMetaData) {
    const minVoi = image.minPixelValue * image.slope + image.intercept;
    let maxVoi = image.maxPixelValue * image.slope + image.intercept;
    if (skewness && skewness > 1 && skewness < Infinity) {
      maxVoi /= skewness
    }
    const ww = maxVoi - minVoi;
    const wc = (maxVoi + minVoi) / 2;
    if (viewport.voi === undefined) {
      viewport.voi = {
        windowWidth: ww,
        windowCenter: wc
      };
    } else {
      viewport.voi.windowWidth = ww;
      viewport.voi.windowCenter = wc;
    }
  }
  else {
    let ww = undefined
    let wc = undefined
    if (min && max) {
      const minValue = min * niftiMetaData.slope + niftiMetaData.intercept;
      let maxValue = max * niftiMetaData.slope + niftiMetaData.intercept;
      if (skewness && skewness > 1 && skewness < Infinity) {
        maxValue /= skewness
      }
      ww = maxValue - minValue;
      wc = (maxValue + minValue) / 2;
    }

    if (viewport.voi === undefined) {
      viewport.voi = {
        // windowWidth: ww,
        // windowCenter: wc
        windowWidth: ww ? ww : niftiMetaData.windowWidth,
        windowCenter: wc ? wc : niftiMetaData.windowCenter
      };
    } else {
      // viewport.voi.windowWidth = ww
      // viewport.voi.windowCenter = wc
      viewport.voi.windowWidth = ww ? ww : niftiMetaData.windowWidth
      viewport.voi.windowCenter = wc ? wc : niftiMetaData.windowCenter
    }
  }
}


export function concatPixelData(arrays, slope, intercept) {
  // sum of individual array lengths
  let totalLength = arrays.reduce((acc, value) => acc + value.length, 0);
  if (!arrays.length) return null;

  let result = new Uint16Array(totalLength);
  // for each array - copy it over result
  // next array is copied right after the previous one
  let length = 0;
  for(const array of arrays) {
    result.set(array.map(i => {
      const realValue = i * slope + intercept
      if (realValue < 0){
        return 0
      }
      else {
        return realValue
      }
    }), length);
    length += array.length;
  }
  return result;
}

// 모든 shape, pixelSpacing, orientationMatrix, orientation 이 동일한지 확인
const attrsToCompareVolumeMetaData = ['voxelLength', 'pixelSpacing', 'orientationMatrix', 'orientationString']

function dotProduct(v1, v2) {
  return v1.reduce((sum, val, i) => sum + val * v2[i], 0)
}

function magnitude(v) {
  return Math.sqrt(dotProduct(v, v))
}

function angleBetweenVectors(v1, v2) {
  let cosTheta = dotProduct(v1, v2) / (magnitude(v1) * magnitude(v2))
  return Math.acos(cosTheta) // Angle in radians
}

function getColumn(matrix, columnIndex) {
  return matrix.map(row => row[columnIndex])
}

function areMatricesSimilar(mat1, mat2, threshold = 0.01) { // Threshold in radians
  for (let i = 0; i < 3; i++) {
    const v1 = getColumn(mat1, i) // Extracting column vector for column-major order
    const v2 = getColumn(mat2, i)

    if (angleBetweenVectors(v1, v2) > threshold) {
      return false
    }
  }
  const offset1 = getColumn(mat1, 3) // Extracting column vector for column-major order
  const offset2 = getColumn(mat2, 3)
  const similarOffset = arraysEqual(offset1, offset2)
  return similarOffset ? true : false
}

export function extractVolumeMetaData(niftiMetaData) {
  const volumeMetaData = {}
  attrsToCompareVolumeMetaData.forEach(attr => {
    volumeMetaData[attr] = structuredClone(niftiMetaData[attr])
  })
  return volumeMetaData
}
export function compareVolumeMetaData(d1, d2) {
  return attrsToCompareVolumeMetaData.every(attr => {
    if (attr === 'orientationMatrix') {
      return areMatricesSimilar(d1?.[attr], d2?.[attr])
    }
    if (Array.isArray(d1?.[attr])) {
      return arraysEqual(d1?.[attr], d2?.[attr])
    }
    else {
      return objectsEqual(d1?.[attr], d2?.[attr])
    }
  })
}
