import React, {useState, useEffect} from "react";
import {Form, Tag} from 'antd';
import chroma from "chroma-js";
import {SketchPicker} from "react-color";
import { TagBase } from "./tagBase";

const popover = {
  position: 'absolute',
  zIndex: '2',
}
const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}

function FormColorTag({form, listName, name, segCmap}) {
  const [tagColor, setTagColor] = useState(form.getFieldValue(listName)[name[0]]?.[name[1]] ?? '#808080')
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setTagColor(form.getFieldValue(listName)[name[0]]?.[name[1]] ?? '#808080')
  }, [segCmap])
  const handleClickTag = () => {
    setShowPicker(true)
  }
  const handleClosePicker = () => {
    setShowPicker(false);
  }
  const handleChangePicker = (color, event) => {
    setTagColor(color.hex)
    // form.setFieldsValue({[namePath]: color.hex})
  }
  const handleChangeCompletePicker = (color, event) => {
    setTagColor(color.hex)
    // const rgb = chroma(color.hex).alpha(1).css().slice(4, -1)

    // https://stackoverflow.com/questions/63711080/how-to-set-value-dynamically-inside-form-list-using-setfieldsvalue-in-antd-4
    const fields = form.getFieldsValue(true)
    const {seg_cmap} = fields
    Object.assign(seg_cmap[name[0]], {[name[1]]: color.hex})
    form.setFieldsValue({seg_cmap})
    // set field value
  }
  return (
    <>
      <TagBase 
        style={{cursor: "pointer"}}
        onClick={handleClickTag}
        color={tagColor}
        children={tagColor}
      />
      {showPicker ?
        <div style={popover}>
        <div
          style={cover}
          onClick={handleClosePicker}
        />
        <SketchPicker
          styles={{default: {picker: {background: "#CCCCCC", color: "#222222"}}}}
          color={tagColor}
          // color={color}
          onChange={handleChangePicker}
          onChangeComplete={handleChangeCompletePicker}
        />
      </div>
        : null}
    </>
  )
}
export default FormColorTag;

