import React from 'react';
import {Form,Input,Button,Tabs,Typography,Select,Divider} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {update as updateUserInfo} from '../redux/modules/auth'
import {update as updateConfig} from '../redux/modules/config'

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const UserSettingForm = () => {
  const [settingsForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const userConfig = useSelector(state => state.config)
  const userInfo = useSelector(state => state.auth.user)
  
  const onChangePasswrodFinish = values => {
    dispatch(updateUserInfo({values}));
  };

  const onSettingsFinish = values => {
    dispatch(updateConfig({...values}))
  };

  const handleCancel = () => history.goBack()

  return (
    <div 
      style={{
        width: '800px',
        margin: 'auto',
        verticalAlign: 'middle',
        height : '600px',
      }}
    >
      <Tabs 
        type="card" 
        defaultActiveKey='settings'
      >
        <Tabs.TabPane tab='Settings' key='settings'>
          <Form
            {...formItemLayout}
            form={settingsForm}
            initialValues={{
              ...userConfig,
            }}
            onFinish={onSettingsFinish}
          >
            <Divider orientation="left" style={{margin : '0 0 16px 0'}}>User info</Divider>
            <Form.Item
              label={
                <span>
                  Username
                </span>
              }
            >
              <Typography.Text>
                <pre style={{margin : 0}} >{userInfo?.username}</pre>
              </Typography.Text> 
            </Form.Item>

            <Form.Item
              label="E-mail"
            >
              <Typography.Text>
                <pre style={{margin : 0}} >{userInfo?.email}</pre>
              </Typography.Text> 
            </Form.Item>
            <Divider orientation="left">Common</Divider>
            <Form.Item
              name="landing_url"
              label="Homepage"
              rules={[
                {
                  required: true,
                  message: 'Please select your homepage when login!',
                },
              ]}
            >
              <Select>
                <Option value={'/upload'}>Upload</Option>
                <Option value={'/patient'}>Patient List</Option>
                <Option value={'/dataset'}>Dataset List</Option>
                <Option value={'/pipeline'}>Pipeline List</Option>
                <Option value={'/analysis'}>Result List</Option>
                <Option value={'/modality'}>Imagetype List</Option>
                <Option value={'/notice'}>Notice</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="sidebar_open"
              label="Sidebar"
              rules={[
                {
                  required: true,
                  message: 'Please select your sidebar status!',
                },
              ]}
            >
              <Select>
                <Option value={true}>Open</Option>
                <Option value={false}>Close</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="per_page"
              label="Per page in list page"
              rules={[
                {
                  required: true,
                  message: 'Please select your per page in list page!',
                },
              ]}
            >
              <Select>
                <Option value={10}>10/page</Option>
                <Option value={20}>20/page</Option>
                <Option value={50}>50/page</Option>
                <Option value={100}>100/page</Option>
              </Select>
            </Form.Item>
            <Divider orientation="left">Patient</Divider>
            <Form.Item
              name="patient_detail_table_split"
              label="Table view mode"
              rules={[
                {
                  required: true,
                  message: 'Please select your image table view mode!',
                },
              ]}
            >
              <Select>
                <Option value={true}>NIfTI / DICOM</Option>
                <Option value={false}>NIfTI + DICOM</Option>
              </Select>
            </Form.Item>
            <Divider orientation="left">Analysis</Divider>
            <Form.Item
              name="analysis_detail_title_open"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'Please select your collapse status!',
                },
              ]}
            >
              <Select>
                <Option value={true}>Open</Option>
                <Option value={false}>Close</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="per_page_for_detail"
              label="Per page in detail page"
              rules={[
                {
                  required: true,
                  message: 'Please select your per page in detail page!',
                },
              ]}
            >
              <Select>
                <Option value={10}>10/page</Option>
                <Option value={20}>20/page</Option>
                <Option value={50}>50/page</Option>
                <Option value={100}>100/page</Option>
              </Select>
            </Form.Item>
            <Form.Item {...tailFormItemLayout} style={{marginTop : '20px'}}>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Change password' key='changePassword'>
          <Form
            {...formItemLayout}
            form={changePasswordForm}
            name="changePassword"
            onFinish={onChangePasswrodFinish}
            scrollToFirstError
          >
            <Form.Item
              name="currPassword"
              label="Current password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: /\d.*[A-Z]|[A-Z].*\d/,
                  message : 'Password must contain 1 capital letter and 1 number!'
                },
                {
                  min : 8,
                  max : 50,
                  message : 'Password must be between 8 and 50 characters!'
                }
              ]}
              hasFeedback
            >
              <Input.Password/>
            </Form.Item>
            <Form.Item
              name="password"
              label="New password"
              dependencies={['currPassword']}
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  pattern: /\d.*[A-Z]|[A-Z].*\d/,
                  message : 'Password must contain 1 capital letter and 1 number!'
                },
                {
                  min : 8,
                  max : 50,
                  message : 'Password must be between 8 and 50 characters!'
                }
              ]}
              hasFeedback
            >
              <Input.Password/>
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm new password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                ({getFieldValue}) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password/>
            </Form.Item>
            <Form.Item {...tailFormItemLayout} style={{marginTop : '20px'}}>
              <Button type="primary" htmlType="submit" className="login-form-button">
                OK
              </Button>
              {' '}
              <Button onClick={handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default UserSettingForm;
