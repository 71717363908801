import React, {useState, useEffect, createContext} from 'react';
import './App.less';
import {Route, Switch, useLocation, Redirect} from 'react-router-dom'
import Home from './components/home'
import Main from './components/main'
import Upload from './components/upload'
import PatientList from './components/patientList'
import Patient from './components/patient'
import About from './components/about'
import NotFound from './components/notfound'
import NormalLoginForm from './pages/login'
import LoginPending from './pages/loginPending'
import RegistrationForm from './pages/register'
import {Layout, Menu} from 'antd';
import Header from './containers/header'
import Footer from './containers/footer'
import SiderMenu from './containers/sider-menu'
import {useDispatch, useSelector} from "react-redux";
import {Privacy, Terms} from "./components/policy";
import DatasetList from "./components/datasetList"
import Dataset from "./components/dataset"
import PipelineList from "./components/pipelineList"
import Pipeline from "./components/pipeline"
import AnalysisList from "./components/analysisList"
import Analysis4 from "./components/analysis4"
import Analysis5 from "./components/analysis5"
import AnalysisCreate from './components/analysisCreate';
import ModalityList from "./components/modalityList"
import Modality from "./components/modality"
import {logoutEvent, refresh} from './redux/modules/auth'
import UserSettingForm from "./pages/user-setting";
import {useHistory} from "react-router-dom";
import PrivateRoute from './routes/private-route'
import MyEditor from "./components/mdEditorTest";
import TaskResultViewer from "./components/taskResultViewer";
import TaskResultViewerLong from "./components/taskResultViewerLong";
import {update as updateConfig, toggleSider} from "./redux/modules/config";
import Profile from "./components/profile";
import { MessageOutlined } from "@ant-design/icons";
import NoticeList from "./components/notice/noticeList";
import NoticeDetail from "./components/notice/notice";
import NoticeEditor from "./components/notice/noticeEditor";
import LongTaskDrawer from "./components/longTaskDrawer/longTaskDrawer";
import AlreadyLoginStatus from './pages/alreadyLoginPage';
// import CommentWindow from "./components/comment/comment";
// import DCMtest from "./components/lab/euihwan";
// import HwanPipeline from "./components/lab/hwan_pipeline";
import useSocket, {SocketContext} from './hooks/socket';
import './global.css'
import MessageDrawer from './components/messageDrawer';
import ModalityTest from './components/modalityTest';
import ManageReport from './components/report/manageReport';
import Report from './components/report/report';

const {Sider, Content} = Layout;

function App() {
  const {addHandler, removeHandler} = useSocket();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const collapsed = !useSelector(state => state.config.sidebar_open);
  const landingUrl = useSelector(state => state.config.landing_url);
  const user = useSelector(state => state.auth.user);
  const changedConfig = useSelector(state => state.config.changed)
  const siderWidth = collapsed ? 80 : 140

  // const auth = useSelector(state => state.auth);
  const [commentStatus, setCommentStatus] = useState(false);
  const commentWidth = commentStatus ? 385 : 0;

  useEffect(() => {
    dispatch(refresh());
  }, [])

  useEffect(() => {
    function syncLogout (event) {
      if (event.key === 'logout') {
        dispatch(logoutEvent());
        history.push('/login');
      }
    }
    window.addEventListener('storage', syncLogout);
    return () => window.removeEventListener('storage', syncLogout);
  });

  const toggleCollapsed = () => dispatch(toggleSider())

  useEffect(() => {
    if (changedConfig.status) {
      dispatch(updateConfig(changedConfig))
    }
  },[location])

  return (
    <div id="main-layout" style={{height: '100vh', width: '100%', overflow: 'auto'}}>
      <SocketContext.Provider value={{addHandler, removeHandler}}>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
          // trigger={null}
          onCollapse={toggleCollapsed}
          collapsible
          collapsed={collapsed}
          width={siderWidth}
        >
          <SiderMenu style={{ position: "relative" }} collapsed={collapsed} />
          <Profile
            style={{
              border: 2,
              position: "absolute",
              left: collapsed && !user ? 7 : 24,
              bottom: 60,
              cursor: 'pointer'
            }}
            name={user !== null ? user.username : "anonymous"}
            loggedIn={user !== null}
          />
        </Sider>

        <Layout
          style={{
            marginLeft: siderWidth,
            marginRight: commentWidth,
            transition: "margin-right 0.5s",
          }}
        >
          {/* TODO 추후 어울리도록 처리 필요 */}
          {/* {user !== null ? (
            <MessageOutlined
              style={{
                border: 2,
                position: "fixed",
                right: 12,
                top: 12,
                color: "white",
                fontSize: "20px",
                zIndex: 100,
              }}
              onClick={() => {
                setCommentStatus(!commentStatus);
              }}
            />
          ) : (
            <></>
          )}
          <CommentWindow commentWidth={commentWidth} /> */}

          {/*<Header> Header </Header>*/}
          <Content
            style={{
              margin: '8px 8px',
              padding: 8,
              minHeight: '100vh',
              display: 'flex'
            }}
            id="main-content"
          >
            <Switch>
              <Route exact path="/" children={<Redirect to={{pathname: user ? landingUrl : '/login'}}/>}/>
              <Route path="/register" children={user ? <AlreadyLoginStatus/> : <RegistrationForm/>}/>
              <Route path="/login" children={user ? <AlreadyLoginStatus/> : <NormalLoginForm/>}/>
              <Route path="/about" children={<About/>}/>
              <Route path="/policy/privacy" children={<Privacy/>}/>
              <Route path="/policy/terms" children={<Terms/>}/>
              <Route path="/login-pending" children={<LoginPending/>} />
              <Route path="/notice" exact={true} children={<NoticeList/>} />
              <Route path="/notice/detail" exact={true} children={<NoticeDetail/>} />
              <Route path="/report" exact={true} children={<Report/>} />
              
              <PrivateRoute path="/setting" children={<UserSettingForm/>} />
              <PrivateRoute path="/upload" children={<Upload/>} />

              <PrivateRoute path="/patient/detail" children={<Patient/>} />
              <PrivateRoute path="/patient" children={<PatientList/>} />

              <PrivateRoute path="/dataset/detail" children={<Dataset/>} />
              <PrivateRoute path="/dataset" children={<DatasetList/>} />
              
              <PrivateRoute path="/pipeline/manage_report" children={<ManageReport/>} />
              <PrivateRoute path="/pipeline/detail" children={<Pipeline/>} />
              <PrivateRoute path="/pipeline" children={<PipelineList/>} />

              <PrivateRoute path="/analysis/detail2" children={<Analysis5/>} />
              <PrivateRoute path="/analysis/detail" children={<Analysis4/>} />
              <PrivateRoute path="/analysis/create" children={<AnalysisCreate />} />
              <PrivateRoute path="/analysis" children={<AnalysisList/>} />

              <PrivateRoute path="/modality/compare_binding" children={<ModalityTest/>} />
              <PrivateRoute path="/modality/detail" children={<Modality/>} />
              <PrivateRoute path="/modality" children={<ModalityList/>}/>

              <PrivateRoute path="/notice/edit" children={<NoticeEditor/>} />

              <PrivateRoute path="/view-volume2" children={<TaskResultViewerLong/>} />
              <PrivateRoute path="/view-volume" children={<TaskResultViewer/>} />
              <Route component={NotFound}/> {/* 순서중요, 마지막에 둘 것 */}
            </Switch>
          </Content>
          <Footer />
        </Layout>
        <LongTaskDrawer />
        <MessageDrawer />
      </SocketContext.Provider>
    </div>
  );
}

export default App;
