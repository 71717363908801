import React, {Fragment, useEffect, useRef, useContext, useState} from "react";
import {Button, Form, Row, Col, Space, Table, Card, Descriptions, Switch, Tooltip, Modal, Collapse, Typography} from 'antd';
import './analysis4.css'
import './pipeline.css'
import ModelName from "./modelName";
import {InfoCircleOutlined, ClockCircleOutlined, SyncOutlined, CheckCircleTwoTone, CloseCircleTwoTone, WarningTwoTone} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation } from "react-router-dom";
import { TypographyDesc } from "./typographyDesc";
import { useGetImageTypeHooks } from "../hooks/useImageTypeHooks";
import ROIFields from "./ROIFields";
import { useSearchHighlight } from "../context/searchHighlightContext";
import CaseSearchBox, {caseSearchOptions} from "./caseSearchBox";
import { changePerPageForDetail } from "../redux/modules/config";
import { MODE, loadCase, closeNavigateModal, updateCaseState, updateRunState, updateDisplay, getQuickViewData } from "../redux/modules/analysis"
import {getUrl} from "../redux/modules/view";
import Split from 'react-split'
import { SocketContext } from "../hooks/socket";
import { EVT_ANALYSIS } from "../constants";
import { EllipsisTooltipWithParagraph } from "../context/searchHighlightContext";
import { toggleAnalysisTitle } from "../redux/modules/config";
import {TASK_DELIMITER, TASK_NAME_MODEL_PREDICTION, TASK_NAME_REPORT, selectedDecomposition} from "../lib/taskUtil";
import {LabelView} from "./labelControls";
import {TagBase} from "./tagBase";
import TrackROIFields from "./TrackROIFields";
import ReportConfig from "./report/reportConfig";

const {Paragraph} = Typography
const {Panel} = Collapse


export const nameStyle = {
  fontSize: 14,
  fontWeight: 'normal',
  fontStyle: 'italic',
  color: 'darkgray'
}

const nameStyleV = {
  ...nameStyle,
  marginBottom: 4
}
const nameStyleH = {
  ...nameStyle,
  marginRight: 8
}

export const resultValueStyle = {
  fontSize: 11,
  fontWeight: 'normal',
  color: 'darkgray'
}

export function TaskUpperBar({isModal=false}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const tasks = useSelector(state => state.analysis?.tasks) || []
  const display = useSelector(state => state.analysis?.display)

  const handleExpandAll = () => {
    const payload = {...display}
    payload.task.expandedRowKeys = tasks.map(task => task.id)
    dispatch(updateDisplay(payload))
    if (!isModal) {
      history.replace(location.pathname, payload)
    }
  }
  const handleCollapseAll = () => {
    const payload = {...display}
    payload.task.expandedRowKeys = []
    dispatch(updateDisplay(payload))
    if (!isModal) {
      history.replace(location.pathname, payload)
    }
  }
  return (
    <Row>
      <Col span={isModal ? 24 : 12}>
        <Descriptions title={`Task list (total: ${tasks.length})`} />
      </Col>
      <Col span={isModal ? 24 : 12}>
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "right", padding : '4px'}}
        >
          <Button onClick={handleExpandAll}>Expand All</Button>
          <Button onClick={handleCollapseAll}>Collapse All</Button>
        </Space>
      </Col>
    </Row>
  )
}

export function TaskTable({isModal= false}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loading = useSelector(state => state.analysis?.loading)
  const taskTemplates = useSelector(state => state.analysis?.taskTemplates) || []
  const models = useSelector(state =>state.analysis?.models) || []
  const imageTypes = useSelector(state => state.analysis?.imageTypes)
  const tasks = useSelector(state => state.analysis?.tasks) || []

  const display = useSelector(state => state.analysis?.display)

  const paginationInfo = display.case.pagination
  const searchInfo = display.case.search
  const detailPerPage = useSelector(state => state.config?.per_page_for_detail)

  const page = paginationInfo.page || 1;
  const per_page = paginationInfo.per_page || detailPerPage ||10
  const category = searchInfo.category
  const keyword = searchInfo.keyword
  const firstOrderTaskId = tasks?.find(task => task.order ===1)?.id
  const taskExpandedRowKeys = display.task.expandedRowKeys || []
  const selectedTaskId = display.task.selectedId || firstOrderTaskId

  const selectedTaskRow = useRef()

  useEffect(() => {
    if (selectedTaskRow.current) {
      selectedTaskRow.current.scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }, [tasks])

  const taskColumns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width: 'auto',
      align : 'center',
      render: (value, record) => {
        const renderData = value > 0 ? value : '-'
        return <div ref={record.id === selectedTaskId ? selectedTaskRow : null}> {renderData} </div>
      }
    },
    {
      title: 'Type',
      dataIndex: 'task_template_id',
      key: 'name',
      render : (template_id, record) => {
        const selectTemplate = taskTemplates.find(template => template.id === parseInt(template_id))
        if (selectTemplate?.name === TASK_NAME_MODEL_PREDICTION) {
          const modelName = record.config?.model
          return (
            <>
              <p style={{margin : 0}}>{selectTemplate?.name}</p>
              <ModelName name={modelName}/>
            </>
          )
        }
        return selectTemplate?.name
      }
    },
    {
      title : 'state',
      dataIndex : 'state',
      key : 'state',
      align : 'center',
      render : (text, record) => {
        return text === 'pending' ? <ClockCircleOutlined style={{fontSize:24}}/>
          : text === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
            : text === 'success' ? <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{fontSize:24}}/>
              : text === 'warning' ? <WarningTwoTone twoToneColor={'#f8ac14'} style={{fontSize:24}}/>
                : text === 'fail' ? <CloseCircleTwoTone twoToneColor={'#ff4d4f'} style={{fontSize:24}} onClick={() => {
                    if (record.message != null) {
                      Modal.warning({content:record.message})}
                  }}/>
                  : null
      }
    }
  ]

  const onRowClick =(record, index) => {
    return {
      onClick: () => {
        const payload = {...display}
        payload.task.selectedId = record.id
        dispatch(updateDisplay(payload))
        if (!isModal) {
          history.replace(location.pathname, payload)
        }
      }
    }
  }

  const onExpandTaskColumn = (expanded, record) => {
    let expandList = [...taskExpandedRowKeys]
    const id = record.id
    const isSameIdSelected = selectedTaskId === id
    const payload = {...display}

    if (expanded) {
      expandList.push(id)
      payload.task.expandedRowKeys = expandList
    }
    else if (!expanded && isSameIdSelected) {
      expandList = [...taskExpandedRowKeys].filter(id => id !== record.id)
      payload.task.expandedRowKeys = expandList
    }

    if (!isSameIdSelected) {
      payload.task.selectedId = id
      payload.case.pagination = {page, per_page}
      payload.case.search = {category, keyword}
    }
    dispatch(updateDisplay(payload))
    if (!isModal) {
      history.replace(location.pathname, payload)
    }
  }

  const expandedTaskDetailRowRender = record => {
    return (
      <TaskDetail
        task={record}
        taskTemplates={taskTemplates}
        models={models}
        imageTypes={imageTypes}
        selectedTaskId={selectedTaskId}
      />)
  }

  const rowExpandable = record => {
    const task_template_id = record.task_template_id
    const template = taskTemplates.find(template => template.id === task_template_id)
    return template.name !== 'preparation'
  }

  const rowClassName = (record, index) => {
    let className = []
    if (record.id === selectedTaskId) {
      className.push('selected-row')
    }
    if (display.volumeInfo.task_index === index && isModal) {
      className.push('loaded-row')
    }
    return className.join(' ')
  }

  return (
    <Table
      columns={taskColumns}
      dataSource={tasks}
      loading={loading}
      expandable={{
        expandedRowKeys : taskExpandedRowKeys,
        expandedRowRender : expandedTaskDetailRowRender,
        onExpand : onExpandTaskColumn,
        rowExpandable : rowExpandable
      }}
      rowKey='id'
      onRow={onRowClick}
      pagination={false}
      size='small'
      rowClassName={rowClassName}
    />
  )
}

export function CaseUpperBar({isModal=false}) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch()

  const loading = useSelector(state => state.analysis?.loading)
  const taskTemplates = useSelector(state => state.analysis?.taskTemplates) || []
  const tasks = useSelector(state => state.analysis?.tasks) || []
  const totalCase = useSelector(state => state.analysis?.case?.total) || 1
  const display = useSelector(state => state.analysis?.display)

  const paginationInfo = display.case.pagination
  const searchInfo = display.case.search
  const detailPerPage = useSelector(state => state.config?.per_page_for_detail)

  const per_page = paginationInfo.per_page || detailPerPage ||10
  const firstOrderTaskId = tasks?.find(task => task.order ===1)?.id
  const selectedTaskId = display.task.selectedId || firstOrderTaskId

  
  const caseDesc = () => {
    const task = tasks.find(task => task?.id === selectedTaskId)
    const template = taskTemplates.find(template => template?.id === parseInt(task?.task_template_id))
    if (task?.order && template?.name) {
      if (task?.order > 0) {
        return `[${task.order}] ${template.name}`
      }
      else {
        return `${template.name}`
      }
    }
    else {
      return ""
    }
  }

  const handleSearch = (keyword, category) => {
    const payload = {...display}
    payload.case.pagination = {page : 1, per_page}
    payload.case.search = {category, keyword}
    dispatch(updateDisplay(payload))
    if (!isModal) {
      history.replace(location.pathname, payload)
    }
  }

  return (
    <Row>
      <Col span={isModal ? 24 : 12}>
        <Descriptions title={`Case list (total: ${totalCase}) - ${caseDesc()}`} />
      </Col>
      <Col span={isModal ? 24 : 12}>
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "right", padding : '4px' }}
        >
          <CaseSearchBox
            defaultValue={searchInfo.keyword}
            style={{width:'100%', maxWidth:'600px', minWidth : '500px'}}
            loading={loading}
            onSearch={handleSearch}
          />
        </Space>
      </Col>
    </Row>
  )
}

export function CaseTable({isModal=false}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(state => state.analysis?.loading)
  const analysisId = useSelector(state => state.analysis?.analysis?.id)
  const tasks = useSelector(state => state.analysis?.tasks) || []
  const caseList = useSelector(state => state.analysis?.case?.list) || []
  const totalCase = useSelector(state => state.analysis?.case?.total) || 1
  const display = useSelector(state => state.analysis?.display)
  const taskTemplates = useSelector(state => state.analysis?.taskTemplates) || []

  const searchInfo = display.case.search
  const paginationInfo = display.case.pagination
  const detailPerPage = useSelector(state => state.config?.per_page_for_detail)

  const page = paginationInfo?.page || 1;
  const per_page = paginationInfo?.per_page || detailPerPage ||10
  const category = searchInfo?.category
  const keyword = searchInfo?.keyword
  const firstOrderTaskId = tasks?.find(task => task.order ===1)?.id
  const selectedTaskId = display.task.selectedId || firstOrderTaskId

  const mode = display.mode || MODE.SINGLE

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const lastQuickViewVolumeInfo = useSelector(state => state.analysis?.quickView?.lastVolumeInfo)

  const isLongitudinalMode = mode === MODE.MULTI

  useEffect(() => {
    if (selectedTaskId) {
      if (tasks.length !== 0) {
        dispatch(loadCase({id: selectedTaskId, page, per_page, ...searchInfo, longitudinal: isLongitudinalMode}))
      }
    }
  },[paginationInfo, mode, selectedTaskId, searchInfo])

  const {searchHighlight} = useSearchHighlight({paginationInfo: searchInfo, searchOptions: caseSearchOptions})
  const datasetColumns = [
    {
      title : 'Patient ID',
      dataIndex : 'pid',
      key : 'pid',
      render : text => searchHighlight(text, 'pid')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render : text => searchHighlight(text, 'name')
    },
    {
      title: 'Birth',
      dataIndex: 'birth',
      key: 'birth',
      render: text => <>{text.split(' ')[0]}</>,
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      width: 'auto',
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
      width: 'auto',
    },
    {
      title: 'Study ID',
      dataIndex: 'sid',
      key: 'sid',
      render : text => searchHighlight(text, 'sid')
    },
    {
      title: 'Study Date',
      dataIndex: 'date',
      key: 'date',
      render: text => <>{text.split(' ')[0]}</>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: 'auto',
      render: record => record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
        : record === 'success' ? <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{fontSize:24}}/>
          : record === 'warning' ? <WarningTwoTone twoToneColor={'#f8ac14'} style={{fontSize:24}}/>
            : record === 'fail' ? <CloseCircleTwoTone twoToneColor={'#ff4d4f'} style={{fontSize:24}}/>
              : <ClockCircleOutlined style={{fontSize:24}} />
    },
    {
      title: '',
      key: 'action',
      width: 200,
      render: (record_text, record, record_index) => {
        const btnLoad = <Button
            disabled={record.state !== 'success' && record.state !== 'warning'}
            onClick={e => handleLoadButton(e, record, record_index)}
          > Load </Button>
        const btnLog = <Button
          disabled={record.state !== 'fail' && record.state !== 'warning'}
          onClick={e => handleLogButton(e, record, record_index)}
        > Log </Button>

        const loadedTask = tasks.find(task => task.id === selectedTaskId)
        const loadedTemplate = taskTemplates.find(template => template.id === loadedTask.task_template_id)

        return (
          <Space>
            <Button
              disabled={
                (record.state !== 'success' && 
                record.state !== 'warning') ||
                loadedTemplate?.name === TASK_NAME_REPORT
              }
              onClick={e => handleQuickViewButton(e, record, record_index)}
              id="quick-view-btn"
            >
              Quick view
            </Button>
            {record.state !== 'fail ' ? btnLoad : null}
            {record.state !== 'success' ? btnLog : null}
          </Space>
        )
      },
    }
  ] // longitudinal mode 에서는 study 관련 컬럼 제거
  .filter(column => isLongitudinalMode ? !column.title.includes('Study') : true)

  const getVolumeInfo = (record, record_index) => {
    let sids = [record?.study_id]
    if (isLongitudinalMode) {
      sids = record.studies.map(s => s.study_id)
    }

    const task_index = tasks.findIndex(t => t.id === selectedTaskId)
    const loadTask = tasks[task_index]
    const order = loadTask.order

    let taskName = taskTemplates.find(t => t.id === loadTask.task_template_id).name
    if (taskName === TASK_NAME_MODEL_PREDICTION) {
      const modelInfo = loadTask.config.model
      taskName = `${taskName} ${modelInfo[0]}|[${modelInfo[1]}]`
    }
    const patientName = caseList[record_index].name
    const patientId = caseList[record_index].pid

    const caseInfo = caseList.filter(caseData => sids.includes(caseData.study_id))

    const volumeInfo = {
      run_id: record.run_id,
      pid: record.patient_id,
      sids,
      task_index,
      case_index: record_index,
      order,
      taskName,
      patientName,
      patientId,
      caseInfo,
      mode
    }
    return volumeInfo
  }

  const handleLogButton = (e, record, record_index) => {
    e.stopPropagation()
    return Modal.warning({content:record.message})
  }

  const handleLoadButton = (e, record, record_index) => {
    e.stopPropagation()
    const volumeInfo = getVolumeInfo(record, record_index)

    const payload = {...display}
    payload.volumeInfo = volumeInfo
    dispatch(updateDisplay(payload))

    return dispatch(getUrl(volumeInfo))
  }

  const handleQuickViewButton = (e, record, record_index) => {
    e.stopPropagation()
    if (record.state !== 'fail') {
      const volumeInfo = getVolumeInfo(record, record_index)
      const sids = volumeInfo.sids

      const loadTargetTask = tasks.find(task => task.run_id === record.run_id)

      const studyInfo = sids.map(sid => {
        const targetCase = caseList.find(caseInfo => caseInfo.study_id === sid)
        const inputs = loadTargetTask?.input?.map(input => {
          if (input.type !== 'map') {
            return undefined
          }
          if (input.length === 'n') {
            const selectedList = input?.selected
            const selectedIds = input?.selected_id
            const result = selectedList?.map((selected, index) => {
              const blobtypeId = selectedIds?.[index]
              if (!blobtypeId) {
                return undefined
              }
              return [blobtypeId, selected]
            }).filter(data => data)
            
            const multiLengthData = result?.map(([blobtypeId, selected]) => {
              const {order, alias} = selectedDecomposition(selected)
              const targetTask = tasks.find(task => task.order === (order ?? -1))
              const run_id = targetTask?.run_id
              return {
                id: analysisId,
                run_id,
                pid: record.patient_id,
                blobtype_id: blobtypeId,
                duplicate: !!alias,
                alias
              }
            })
            if (multiLengthData) {
              return multiLengthData
            }
            else {
              return undefined
            }
          }
          const blobtype_id = input.selected_id
          const selected = input.selected
          const {order, alias} = selectedDecomposition(selected)
          const targetTask = tasks.find(task => task.order === (order ?? -1))
          const run_id = targetTask?.run_id
          return {
            id: analysisId,
            run_id,
            pid: record.patient_id,
            blobtype_id,
            duplicate: !!alias,
            alias
          }
        }).flat().filter(data => data)
        
        const outputs = loadTargetTask?.output?.map(output => {
          if (output.type !== 'map') {
            return undefined
          }
          const blobtype_id = output.blobtype
          const run_id = record.run_id
          if (output.length === 'n') {
            const selectedList = output?.selected
            const selectedIds = output?.blobtype
            const duplicates = output?.duplicates
            const result = selectedList?.map((selected, index) => {
              const blobtypeId = selectedIds?.[index]
              if (!blobtypeId) {
                return undefined
              }
              const duplicate = duplicates?.[index]
              return [blobtypeId, selected, duplicate]
            }).filter(data => data)
            
            const multiLengthData = result?.map(([blobtypeId, selected, duplicate]) => {
              const {alias} = selectedDecomposition(selected)
              return {
                id: analysisId,
                run_id,
                pid: record.patient_id,
                blobtype_id: blobtypeId,
                duplicate,
                alias
              }
            })
            if (multiLengthData) {
              return multiLengthData
            }
            else {
              return undefined
            }
          }
          return {
            id: analysisId,
            run_id,
            pid: record.patient_id,
            blobtype_id,
            duplicate: output.duplicate,
            alias: output.alias
          }
        }).flat().filter(data => data)
        
        return {
          sid,
          date: targetCase.date.split(' ')[0],
          inputs,
          outputs
        }
      })
      return dispatch(getQuickViewData({studyInfo, volumeInfo}))
    }
    return Modal.warning({content:record.message})
  }

  const onPaginationChange = (page, per_page) => {
    if (per_page !== detailPerPage) {
      dispatch(changePerPageForDetail(per_page))
    }

    const payload = {...display}
    const id = selectedTaskId || tasks?.find(task => task.order === 1)?.id
    payload.task.selectedId = id
    payload.case.pagination = {page, per_page}
    payload.case.search = {category, keyword}
    dispatch(updateDisplay(payload))

    if (!isModal) {
      history.replace(location.pathname, payload)
    }
  }

  const rebuildCaseList = cases => {
    // 동일한 patient를 묶어줘야 함
    const patient_ids= [...new Set(cases.map(c => c.patient_id))] // TODO sort 필요?
    const newCases = []
    patient_ids.forEach(patient_id => {
      const firstCase = cases.find(p => p.patient_id === patient_id)
      const patient = {...firstCase, state: 'success'}
      patient.studies = cases.filter(p => p.patient_id === patient.patient_id)
      patient.study_ids = cases.filter(p => p.patient_id === patient.patient_id).map(s => s.study_id)

      // new state for patient [pending, running, success, warning, fail
      // pending -> running -> success or warning or fail
      // 우선순위 running > pending > fail > warning > success
      let patientState = 'success'
      patientState = patient.studies.some(c => c.state === 'warning') ? 'warning' :  patientState
      patientState = patient.studies.some(c => c.state === 'fail') ? 'fail' :  patientState
      patientState = patient.studies.some(c => c.state === 'pending') ? 'pending' :  patientState
      patientState = patient.studies.some(c => c.state === 'running') ? 'running' :  patientState
      patient.state = patientState

      newCases.push({...patient})
    })
    return newCases
  }

  const rowClassName = (record, index, expandedRecord=false) => {
    if (!isModal) return 
    let className = []
    if (expandedRecord || !isLongitudinalMode) {
      if (display?.volumeInfo?.sids?.includes(record.study_id)) {
        className.push('loaded-row')
      
      const selectedTaskIndex = tasks.findIndex(task => task.id ===display.task.selectedId)
      const loadedTaskIndex = display?.volumeInfo?.task_index
      if (selectedTaskIndex === loadedTaskIndex) {
        className.push('selected-row')
      }
      
      return className
      }
    }
    else if (isLongitudinalMode) {
      const isIncluded = display?.volumeInfo?.sids?.some(sid => record.study_ids?.includes(sid))
      if (isIncluded) {
        className.push('loaded-row')
      
      const selectedTaskIndex = tasks.findIndex(task => task.id ===display.task.selectedId)
      const loadedTaskIndex = display?.volumeInfo?.task_index
      if (selectedTaskIndex === loadedTaskIndex) {
        className.push('selected-row')
      }
      
      return className
      }
    }
    return
  }

  const expandedPatientRowRender = record => {
    const study_columns = [
      {
        title: 'Study ID',
        dataIndex: 'sid',
        key: 'sid',
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        key: 'study_desc',
        // ellipsis: true,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: text => <>{text.split(' ')[0]}</>,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: record => {
          return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
            :record === 'warning' ? <WarningTwoTone twoToneColor={'#f8ac14'} style={{fontSize:24}}/>
              : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
                : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
                  : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
        }
      },
      {
        title: '',
        key: 'action',
        render: (record_text, record, record_index) => (
          <div>
            {record.state === 'fail' ? <Button onClick={() => {
              Modal.warning({content:record.message})
            }}>Logs</Button> : null}
          </div>
        ),
      },
    ];
    return (
      <Table
        size="small"
        columns={study_columns}
        dataSource={record.studies}
        rowClassName={(record, index) => rowClassName(record, index, true)}
        pagination={false}
        rowKey='id'
      />
    );
  };

  const onExpand = (expanded, record) => {
    if (expanded) {
      const copiedExpandedRowKeys = [...expandedRowKeys, record.id]
      setExpandedRowKeys(copiedExpandedRowKeys)
    } else {
      const copiedExpandedRowKeys = [...expandedRowKeys].filter(key => key !== record.id)
      setExpandedRowKeys(copiedExpandedRowKeys)
    }
  }

  const pagination = {
    current: page,
    pageSize: per_page,
    total: totalCase,
    showSizeChanger: true,
    onChange: onPaginationChange,
  }

  const expandable = isLongitudinalMode && {
    expandedRowRender: expandedPatientRowRender,
    rowExpandable: record => record.hasOwnProperty('studies'),
    defaultExpandAllRows: false,
    expandRowByClick: true,
    expandedRowKeys,
    onExpand
  }

  useEffect(() => {
    const onLastDataFocus = VolumeInfo => {
      if (!VolumeInfo) return 
      
      const rowKey = VolumeInfo?.caseInfo?.[0]?.id
      if (rowKey) {
        const rowElement = document.querySelector(`tr[data-row-key="${rowKey}"]`);
        const quickViewBtnEl = rowElement?.querySelector('#quick-view-btn')
        quickViewBtnEl?.focus()
      }
    }
    
    onLastDataFocus(lastQuickViewVolumeInfo)
  },[lastQuickViewVolumeInfo])

  return (
    <Table
      columns={datasetColumns}
      loading={loading}
      dataSource={!isLongitudinalMode ? caseList : rebuildCaseList(caseList)}
      size='small'
      rowClassName={rowClassName}
      pagination={pagination}
      expandable={expandable}
      rowKey='id'
    />
  )
}

export function NavigateModal() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = location.state
  const pipeline = useSelector(state => state.analysis?.pipeline);
  const dataset = useSelector(state => state.analysis?.dataset);
  const open = useSelector(state => state.analysis?.open)
  const user = useSelector(state => state.auth.user);
  const viewList = useSelector(state => state.view?.list);
  const display = useSelector(state => state.analysis?.display)
  const mode = display?.mode || MODE.SINGLE

  useEffect(() => {
    if (history.action === 'POP') {
      dispatch(updateDisplay(locationState))
    }
  },[location])

  const onCancel = () => dispatch(closeNavigateModal())

  useEffect(()=> {
    if (history.action === 'PUSH') {
      onCancel()
    }
  },[viewList])

  const runModalSocketCB = payload => {
    const runId = payload.run_id
    if (runId) {
      dispatch(updateRunState(payload))
      if (payload.cases) { // case 정보도 있으면 함께 업데이트
        dispatch(updateCaseState(payload))
      }
    }
  }
  
  const {addHandler, removeHandler} = useContext(SocketContext)

  useEffect(() => {
    addHandler(EVT_ANALYSIS, runModalSocketCB)
    return () => removeHandler(EVT_ANALYSIS, runModalSocketCB)
  },[user])

  const titleCollapse = !useSelector(state => state.config.analysis_detail_title_open)
  const onCollapseChange = () => dispatch(toggleAnalysisTitle(!titleCollapse))

  const onToggleSwitch = checked => {
    const newMode = checked ? MODE.MULTI : MODE.SINGLE
    const payload = {...display}
    payload.mode = newMode
    dispatch(updateDisplay(payload))
  }

  const ModalTitle = () => {
    return (
      <div style={{width: "100%", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div>Navigate</div>
        {pipeline?.longitudinal?
          <Switch
            checkedChildren="longitudinal view"
            unCheckedChildren="cross-sectional view"
            onChange={onToggleSwitch}
            checked={mode === MODE.MULTI}
          /> : null
        }
      </div>
    )
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      closable={false}
      footer={null}
      width={'90%'}
      title={<ModalTitle />}
      style={{height: 800}}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "90%", width: '100%' }}>
        <Split
          sizes={[30, 80]}
          minSize={[400, 800]}
          gutterSize={5}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          cursor="col-resize"
          className={"split"}
        >
          <div className="overflow-scroll" style={{minWidth: '400px', height: titleCollapse ? '75vh' : '75vh'}}>
            <div style={{position:'relative'}}>
              <Information title={'Pipeline'} data={pipeline} collapse={titleCollapse} onCollapseChange={onCollapseChange}/>
              <br/>
              <TaskUpperBar isModal={true}/>
            </div>
            <TaskTable isModal={true}/>
          </div>
          <div className="overflow-scroll" style={{minWidth: '800px', height: titleCollapse ? '75vh' : '75vh'}}>
            <div style={{position:'relative'}}>
              <Information title={'Dataset'} data={dataset} collapse={titleCollapse} onCollapseChange={onCollapseChange}/>
              <br />
              <CaseUpperBar isModal={true}/>
            </div>
            <CaseTable isModal={true}/>
          </div>
        </Split>
      </div>
    </Modal>
  )
}


export function Information({title, data, collapse, onCollapseChange}) {
  const history = useHistory();
  const handleGo = () => history.push(`/${title}/detail`.toLowerCase(), {key: data?.parent_id})

  const disabled = data?.parent_id ? false : true

  return (
    <>
      <Collapse
        style={{
          backgroundColor: 'rgba(255,255,255,0.03)',
          borderRadius: '5px'
        }}
        bordered={false}
        activeKey={collapse ? 'expand' : null}
        onChange={onCollapseChange}
      >
        <Panel
          className={'middle-align'}
          header={
            collapse
            ? <h2 style={{margin : '0'}}>{title}</h2>
            : <div style={{ whiteSpace: 'nowrap', textOverflow : 'ellipsis', overflow : 'hidden' }}>
                <h2 style={{display :'inline-block', margin : '0 6px 0 0 '}}>{title}</h2>
                <Tooltip title={data?.name} placement="bottomLeft" zIndex={10000}>
                  <h3 style={{fontSize: 'large', display :'inline-block',margin : 0}}>[{data?.name}]</h3>
                </Tooltip>
              </div>
          } 
          key='expand'
        >
          <Card
            size='small'
            headStyle={{backgroundColor : '#18343B',}}
            title={
              <EllipsisTooltipWithParagraph
                title={<Paragraph ellipsis={{rows : 10,}} children={data?.name}/>}
                color={'#0D6E6D'}
                placement="bottomLeft"
                children={`name : ${data?.name}`}
              />
            }
            bodyStyle={{
              height : '8rem',
              overflowX: 'hidden',
              overflowY : 'auto',
              backgroundColor : '#0C2329',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {'desc : '}
            {data?.desc}
          </Card>
          <Space style={{display: 'flex', justifyContent: 'right', marginTop: '5px'}}>
            <Tooltip title={'Already removed'} open={!disabled ? false : undefined}>
              <Button onClick={handleGo} disabled={disabled}>
                {`Go to ${title}`}
              </Button>
            </Tooltip>
          </Space>
        </Panel>
      </Collapse>
    </>
  )
}


export const TaskDetail = ({task, taskTemplates, models, imageTypes, selectedTaskId=undefined}) => {
  const inputs = task.input || task.inputs
  const outputs = task.output || task.outputs
  const configs = task.config
  const template = taskTemplates.find(template=>template.id === parseInt(task.task_template_id))
  const {getTagColorFromType, getTagFromTypeId, getTypeFromTypeId, getTypeFromShort} = useGetImageTypeHooks(imageTypes)

  const isModelPrediction = template.name === TASK_NAME_MODEL_PREDICTION

  let selectedModel = null
  if (isModelPrediction) {
    selectedModel = models.find(model => {
      return model.name === task.config.model[0]
        && model.version === task.config.model[1]
    })
  }

  const getInputTags = input => {
    let shortNames = input.selected || []
    let shortIds = input.selected_id || []
    if (!input.length) {
      shortNames = [input.selected]
      shortIds = [input.selected_id]
    }
    return shortIds.map((id, index) => 
      <React.Fragment key={`${id}-${index}`}>
        {getTagFromTypeId(id, shortNames[index])}
      </React.Fragment>
      )
  }

  const getOutputTags = (output, btype) => {
    // 생각해야 할 것 blobtype, alias, children
    let shortNames = output?.alias || []
    let shortIds = output.blobtype || []
    if (!output.length) {
      shortNames = [output.selected]
      shortIds = [output.selected_id]
    }
    return shortIds.map((id, index) => getTagFromTypeId(`${id}-${index}`, shortNames[index]))
  }

  const inputTitle = (inputName, desc) => {
    return (
      <Space>
        {inputName}
        <Tooltip title={desc} color={'#0D6E6D'}>
          <Space><InfoCircleOutlined/></Space>
        </Tooltip>
      </Space>
    )
  }

  const configDetail = (templateConfig, configValue, imageType) => {
    switch (templateConfig.type) {
      case 'select-imagetype':
        return <TypographyDesc>{getTagFromTypeId(configValue)}</TypographyDesc>
      case 'expression-roi':
        return (
          <Row gutter={2}>
            {configValue?.map((expression, idx) => {
              const label = imageType.seg_cmap.find(item => item.value === expression.value)
              return <Col key={idx} span={24}>
                <TypographyDesc key={idx}
                  children={<LabelView name={label.name} color={label.color} value={label.value}/>}
                />
              </Col>
            })}
          </Row>
        )
      case 'expression':
        return (
          <Row style={{textAlign : 'center'}} gutter={2}>
            {configValue?.map(value => {
              const imagetype = getTypeFromTypeId(value.selected_id)
              const target = getTagFromTypeId(value.selected_id, value.target)
              let conditionValue = `${value.value}`
              if (imagetype.seg) {
                const label = imagetype.seg_cmap.find(cmap => cmap.value === value.value)
                if (label) {
                  conditionValue = <LabelView name={label.name} color={label.color} value={label.value}/>
                }
                else {
                  conditionValue = <LabelView name={'background'} color={'#000000'} value={0}/>
                }
              }

              const lopSpan = 3
              const targetSpan = 9
              const opSpan = 3
              const conditionValueSpan = 24 - lopSpan - targetSpan - opSpan

              return <>
                <Col span={lopSpan}><TypographyDesc children={value.lop}/></Col>
                <Col span={targetSpan}><TypographyDesc children={target}/></Col>
                <Col span={opSpan}><TypographyDesc children={value.op}/></Col>
                <Col span={conditionValueSpan}><TypographyDesc style={{whiteSpace: "nowrap"}} children={conditionValue}/></Col>
              </>
            })}
          </Row>
        )
      case 'boolean':
        return <Switch disabled defaultChecked={configValue} />
      case 'track-roi':
        return <TrackROIFields imageType={imageType} value={configValue} disabled={true} />
      case 'locate-roi':
        return <ROIFields imageType={imageType} value={configValue} disabled={true} />
      case 'report-configuration':
        return (
          <ReportConfig 
            value={configValue}
            readOnly={true}
            imageTypeList={imageTypes}
          />
        )
      default:
        const isArray = Array.isArray(configValue)
        let values = []
        if (isArray) {
          values = configValue
        }
        else {
          values = [configValue]
        }
        return <Space>{values.map((value, idx) => <TypographyDesc key={idx} style={resultValueStyle}>{value}</TypographyDesc>)}</Space>
    }
  }

  function ModelDetail({modelHint, task}) {
    const model = models.find(m => m.name === modelHint[0] && m.version === modelHint[1])
    const modelInfoComp = <div style={nameStyleV}>Model information</div>
    return (
      <>
        {modelInfoComp}
        <Descriptions title="" bordered column={1} size="small">
          <Descriptions.Item label="desc" style={{...nameStyle, whiteSpace: 'pre-line'}}>{model.desc}</Descriptions.Item>
        </Descriptions>
        <Card type="inner" title="Model Configuration" size="small" headStyle={nameStyle}>
          {model.config.map(modelConfig => {
            const name = modelConfig.name
            const modelConfigNameComp = modelConfig?.vertical ? <div style={nameStyleV}>{name}</div> : <span style={nameStyleH}>{name}</span>
            const configValue = task.config[name]
            const taskOutput = task.output || task.outputs
            const outputTypes = taskOutput.map(o => getTypeFromTypeId(o.blobtype)) // NOTE model.output 사용하면 안됨, snapshot 데이터 사용해야 함
            const imageType = outputTypes.find(t => t?.seg)
            // if (!imageType) { return; }
            return (
              <div style={{marginBottom: 8}}>
                {modelConfigNameComp}
                {configDetail(modelConfig, configValue, imageType)}
              </div>
            )
          })}
        </Card>
      </>
    )
  }

  return (
    <Form>
      <Space direction="vertical" style={{ width: "100%", justifyContent: "right" }}>
        {inputs?.length && 
          <Card
            title='Task input'
            size='small'
            className={task.id === selectedTaskId ? "selected-row" : null}
          >
            {inputs?.map((input, index)=>{
              const inputName = input.name
              let desc = template?.input?.[index]?.desc || selectedModel.input[index].desc
              if (Array.isArray(desc)) {
                desc = desc.join(' or ')
              }
              const inputNameComp = input?.length ? <div style={nameStyleV}>{inputName}</div> : <span style={nameStyleH}>{inputName}</span>
              return (
                <Form.Item key={index}>
                  {inputTitle(inputNameComp, desc)}
                  <TypographyDesc>
                    {getInputTags(input)}
                  </TypographyDesc>
                </Form.Item>
              )
            })}
          </Card>
        }
        <Card
          title='Task configuration'
          size='small'
          hidden={template.config?.length ? false : true}
          className={task.id === selectedTaskId ? "selected-row" : null}
        >
          {template.config?.map((templateConfig, configIdx) => {
            const name = templateConfig.name
            const configNameComp = templateConfig?.vertical ? <div style={nameStyleV}>{name}</div> : <span style={nameStyleH}>{name}</span>
            const configValue = configs[name]
            const target = inputs?.[0]?.selected
            const short = target?.includes(TASK_DELIMITER) ? target.split(TASK_DELIMITER)[0] : target
            const imgtype = getTypeFromShort(short)
            return (
              <div key={`${configIdx}`}>
                <Form.Item key={configIdx}>
                  {configNameComp}
                  {configDetail(templateConfig, configValue, imgtype)}
                </Form.Item>
                {name === 'model'
                  ? <ModelDetail modelHint={configValue} task={task}/>
                  : null}
              </div>
            )
          })}
        </Card>
        <Card
          title='Task output'
          size='small'
          className={task.id === selectedTaskId ? "selected-row" : null}
        >
          {outputs.map((output, index)=>{
            const name = output?.name || `result ${index + 1}`
            const outputNameComp = output?.length ? <div style={nameStyleV}>{name}</div> : <span style={nameStyleH}>{name}</span>
            if (output.type === 'map') {
              let tags = null
              let desc = null
              if (output.length) {
                // 생각해야 할 것 suppress, blobtype, alias, children
                desc = output?.desc || ''
                if (output?.children) {
                  tags = output?.children.map((c, idx) => {
                    const btype = getTypeFromTypeId(c?.blobtype)
                    const suffix = c?.alias ? ` (${c?.alias})` : ''
                    const tagName = btype.short + suffix
                    return <TagBase key={idx} color={getTagColorFromType(btype)} children={tagName}/>
                  })
                }
                else {
                  tags = output.blobtype?.map((btid, idx) => {
                    const btype = getTypeFromTypeId(btid)
                    return <TagBase key={idx} color={getTagColorFromType(btype)} children={btype.short}/>
                  })
                }
              }
              else {
                // 생각해야 할 것 suppress, blobtype, alias
                const btype = getTypeFromTypeId(output?.blobtype)
                desc = output?.desc || btype?.name
                const suffix = output?.alias ? ` (${output?.alias})` : ''
                const tagName = btype.short + suffix
                tags = [<TagBase key="1" color={getTagColorFromType(btype)} children={tagName}/>]
              }
              return (
                <Form.Item key={index}>
                  {inputTitle(outputNameComp, desc)}
                  <TypographyDesc>
                    {tags}
                  </TypographyDesc>
                </Form.Item>
              )
            }
            else {
              return (
                <Form.Item key={index}>
                  {inputTitle(outputNameComp, output?.desc)}
                </Form.Item>
              )
            }
          })}
        </Card>
      </Space>
    </Form>
  )
}