import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PREVIEW, SUMMARY_PADDING, SUMMARY_WIDTH, VALUE_OPERATION, REPORT_TYPE, getSettingFunction, getTrackImageTemplate, TRACK_IMAGE_INPUT_DATA, SORT, ALL_SEG_CMAP, ROW_INFO, getConfigValue, SETTING, TABLE_ROW_TYPE } from "./utils"
import { updatePreviewOpen, updateReportPreview } from "../../redux/modules/pipeline"
import { Rnd } from "react-rnd"
import { CloseOutlined } from '@ant-design/icons';
import { GraphTablePreview } from "./graph-and-table"
import { PREVIEW_OPTION, TABLE_TYPE_SETTING_NAMES, getGraphTableTemplate, graphTablePreviewSettings } from "./settings"
import { Space } from "antd"
import { TrackImagePreview } from "./trackImage"
import { TASK_DELIMITER } from "../../lib/taskUtil"

const TRACK_STATE = Object.freeze({
  NEW: 'new',
  STABLE: 'stable',
  INCREASE: 'increase',
  DECREASE: 'decrease',
})

const PreviewModal = ({page, candidates, longitudinal}) => {
  const dispatch = useDispatch()

  const open = useSelector(state => state?.pipeline?.preview_open)
  const updatePreview = useSelector(state => state?.pipeline?.preview_update)
  const imageTypeList = useSelector(state => state.blobTypes?.list || [])
  const [contentWidth, setContentWidth] = useState(SUMMARY_WIDTH)
  
  const MIN_SIZE = 400

  const onCancel = () => dispatch(updatePreviewOpen(!open))
  
  const [maxWidth, setMaxWidth] = useState(window.innerWidth - 20);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 20);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rndSize, setRndSize] = useState({
    width: MIN_SIZE * 2.2, // 초기 Rnd 컴포넌트의 폭
    height: MIN_SIZE * 2.5, // 초기 Rnd 컴포넌트의 높이
  });

  const [graphTableDummyData, setGraphTableDummyData] = useState([])
  const [trackImageDummyData, setTrackImageDummyData] = useState([])
  const [processedTrackImageDummyData, setProcessedTrackImageDummyData] = useState([])

  const preview = page?.[PREVIEW]
  const getPreviewValue = getSettingFunction(preview, graphTablePreviewSettings)
  const studyCount = longitudinal ? 
    (getPreviewValue(PREVIEW_OPTION.STUDY_COUNT) || 2) : 
    1
  
  useEffect(() => {
    if (updatePreview && open) {
      let newWidth = SUMMARY_WIDTH
      const STUDY_LIMIT = 5
      if (studyCount > STUDY_LIMIT && page?.type === REPORT_TYPE.GRAPH_N_TABLE ) {
        const exceedStudyCount = studyCount - STUDY_LIMIT
        newWidth = SUMMARY_WIDTH + (exceedStudyCount * 150)
      }
      else if (page?.type === REPORT_TYPE.TRACK_IMAGE) {
        const trackImageValue = page[REPORT_TYPE.TRACK_IMAGE]
        const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList)
        const getTrackImageValue = getSettingFunction(trackImageValue, trackImageTemplate)
        const maximumColumnCount = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.MAXIMUM_COLUMN_COUNT)
        newWidth = maximumColumnCount > 5 ? SUMMARY_WIDTH + 200 : SUMMARY_WIDTH
      }
      else {
        newWidth = SUMMARY_WIDTH
      }
      if (contentWidth !== newWidth) {
        setContentWidth(newWidth)
      }
      dispatch(updateReportPreview(false))
    }
  },[updatePreview, page, open, studyCount])

  const dummyDataInfo = useRef({studyCount, targetNames: [], dateList: [], template: []})

  useEffect(() => {
    if (page?.type === REPORT_TYPE.GRAPH_N_TABLE && open) {
      const graphTableRows = page?.[REPORT_TYPE.GRAPH_N_TABLE] || []
    
      const targetNames = []

      for (const row of graphTableRows) {
        const selected = row?.target?.selected
        if (selected) {
          targetNames.push(selected)
        }
        const expressions = row?.[ROW_INFO.FILTER_CONDITION]
        if (expressions?.length) {
          for (const exp of expressions) {
            if (exp.target) {
              targetNames.push(exp.target)
            }
          }
        }
      }
      
      const notExistTargetName = (
        dummyDataInfo.current.targetNames.length !== targetNames.length || 
        !targetNames.every(targetName => dummyDataInfo.current.targetNames.includes(targetName))
      )
      const notSameStudyCount = studyCount !== dummyDataInfo.current.studyCount
      
      if (open && (notExistTargetName || notSameStudyCount)) {
        const [dateList, template] = getDummyTemplate(graphTableRows, imageTypeList, studyCount)
        dummyDataInfo.current.studyCount = studyCount
        dummyDataInfo.current.targetNames = targetNames
        dummyDataInfo.current.dateList = dateList
        dummyDataInfo.current.template = template
      }
      const dateList = dummyDataInfo.current.dateList
      const template = dummyDataInfo.current.template
      const newViewDataList = getDummyDataList(graphTableRows, dateList, template, page)
      
      setGraphTableDummyData(newViewDataList)
    }
  },[updatePreview, page, open, studyCount])

  useEffect(() => {
    if (open && page.type === REPORT_TYPE.TRACK_IMAGE) {

      const dummyTrackDataList = getTrackImageDummyData(studyCount)
      setTrackImageDummyData(dummyTrackDataList)
    }
  },[studyCount, open])

  useEffect(() => {
    if (
      page?.[REPORT_TYPE.TRACK_IMAGE] && 
      trackImageDummyData?.length && 
      page.type === REPORT_TYPE.TRACK_IMAGE
    ) {
      const trackImageValue = page[REPORT_TYPE.TRACK_IMAGE]
      const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList)
      const getTrackImageValue = getSettingFunction(trackImageValue, trackImageTemplate)
      
      const sortCondition = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.SORT)
      const volumeThreshold = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.VOLUME_THRESHOLD)
      const maximum_row_count = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.MAXIMUM_ROW_COUNT)
      const maximumColumnCount = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.MAXIMUM_COLUMN_COUNT)

      const settingAppliedDataList = trackImageDummyData.map((trackData, ti) => {
        return {
          ...trackData,
          trackInfo: trackData.trackInfo.sort((a, b) => {
            const target = sortCondition[0]
            const order = sortCondition[1]
            if (order === SORT.ASC) {
              return a[target] - b[target]
            }
            return b[target] - a[target]
          }).filter(trackInfo => trackInfo.volume >= volumeThreshold)
          .filter((trackInfo, index) => {
            if (trackData !== trackImageDummyData.at(-1)) {
              return true
            }
            return index < maximum_row_count
          }),
        }
      }).filter(((trackData, index) => {
        const previewDataLength = trackImageDummyData.length
        const limitIndex = previewDataLength - maximumColumnCount
        return index > limitIndex - 1
      }))

      setProcessedTrackImageDummyData(settingAppliedDataList)
    }

  },[trackImageDummyData, page, candidates, imageTypeList])

  // 웹 브라우저 창 크기가 변경될 때 최대 크기 업데이트
  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(window.innerWidth - 20);
      setMaxHeight(window.innerHeight - 20);
    };

    window.addEventListener('resize', handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleResize = (e, direction, ref, delta, position) => {
    setRndSize({
      width: ref.style.width,
      height: ref.style.height,
    });
  };
  const scaleRatio = parseInt(rndSize.width) / contentWidth

  const handleDragStop = (e, d) => {
    setPosition({ x: d.x, y: d.y });
  }

  const getChildren = () => {
    switch (page?.type) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        return (
          <GraphTablePreview 
            page={page} 
            dataList={graphTableDummyData}
            contentWidth={contentWidth}
            candidates={candidates}
          />
        )
      case REPORT_TYPE.TRACK_IMAGE:
        return (
          <TrackImagePreview 
            page={page} 
            trackDataList={processedTrackImageDummyData}
          />
        )
      default:
        return (
          <>
            <div>{page?.title}</div>
            <div>{page?.type}</div>
          </>
        )
    }
  }
  
  return (
    <>
      {open && 
        <Rnd
          style={{
            border: '1px solid #ddd',
            backgroundColor: 'black',
            position: 'fixed',
            top: 0, 
            left: 0,
            zIndex: 10,
            width: rndSize.width,
            height: rndSize.height,
          }}
          default={{
            x: position.x,
            y: position.y,
            width: rndSize.width,
            height: rndSize.height,
          }}
          onResize={handleResize}
          onDragStop={handleDragStop}
          minWidth={MIN_SIZE}
          minHeight={MIN_SIZE}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          bounds="#main-layout"
          dragHandleClassName="drag-handle"
        >
          <div 
            style={{
              position: 'relative',
              margin: 0,
              overflowX: 'hidden',
              overflowY: 'auto',
              height: '100%',
            }}
          >
            <div 
              className="drag-handle" 
              style={{
                cursor: 'move', 
                height: rndSize.height, 
                paddingTop: 22,
              }}
            >
              <div 
                style={{
                  width: '100%',
                  background: '#0C2329', 
                  textAlign: 'center', 
                  cursor: 'move',
                  position: 'fixed', 
                  top: 0, 
                  left: 0, 
                  zIndex: 1,
                  height: 22
                }} 
              >
                <div style={{float: 'left', height: 0, overflow: 'visible', paddingLeft: 8}}>
                  {`ratio ${(parseInt(rndSize.width)/contentWidth * 100).toFixed(2)}%`}
                </div>
                <Space>
                  <h4 style={{display: 'inline'}}>Preview</h4>
                </Space>
                <div style={{float: 'right', height: 0, overflow: 'visible', paddingRight: 8}}>
                  <CloseOutlined onClick={onCancel} style={{cursor: 'pointer', marginLeft: 10}}/>
                </div>
              </div>
              <div 
                style={{ 
                  transform:`scale(${scaleRatio}, ${scaleRatio})`,
                  transformOrigin: 'left top',
                  textAlign: 'center',
                  width: contentWidth,
                  padding: SUMMARY_PADDING,
                  cursor: 'move',
                }}
              >
                {getChildren()}
              </div>
            </div>
          </div>
        </Rnd>
      }
    </>
  );
}

export default PreviewModal


const getDateList = count => {
  const today = new Date();
  const randomDateList = [];

  for (let i = 1; i < count; i++) {
    const previousMonth = new Date(today);
    previousMonth.setMonth(today.getMonth() - i - 1); 

    const randomDate = new Date(
      previousMonth.getFullYear(),
      previousMonth.getMonth(),
      Math.floor(Math.random() * 30) + 1
    );
    randomDateList.push(randomDate.toISOString().slice(0, 10));
  }

  randomDateList.push(today.toISOString().slice(0, 10));

  randomDateList.sort()
  return randomDateList
}


const calculateMedian = arr => {
  const sortedArr = [...arr].sort((a, b) => a - b);

  const length = sortedArr.length;

  // 배열의 길이가 홀수인 경우 중앙값은 정확히 중앙에 위치한 값입니다.
  if (length % 2 === 1) {
    const middleIndex = Math.floor(length / 2);
    return sortedArr[middleIndex];
  } else {
    // 배열의 길이가 짝수인 경우 중앙값은 중앙에 있는 두 값의 평균입니다.
    const middleIndex1 = length / 2 - 1;
    const middleIndex2 = length / 2;
    return (sortedArr[middleIndex1] + sortedArr[middleIndex2]) / 2;
  }
}

const calculateStandardDeviation = arr => {
  // 배열의 평균 계산
  const mean = arr.reduce((sum, value) => sum + value, 0) / arr.length;
  // 각 데이터 포인트와 평균 간의 차이의 제곱을 계산하여 합산
  const squaredDifferencesSum = arr.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0);
  // 분산 계산
  const variance = squaredDifferencesSum / arr.length;
  // 표준편차는 분산의 제곱근
  const standardDeviation = Math.sqrt(variance);

  return standardDeviation;
}

const getIntRandom = (min, max, minRatio) => {
  const rand = Math.random();
  if (rand < minRatio) return min;

  const range = max - min;
  const remainingProbability = (1 - minRatio) / range;
  const value = Math.floor((rand - minRatio) / remainingProbability) + min + 1;

  return value;
}

const getRandomInRange = (min, max) => {
  return min + (max - min) * Math.random();
}

const generateData = (length, min, max, minRatio) => {
  const data = [];
  const minCount = Math.floor(length * minRatio);
  const peak = max * 0.3;
  const stdDev = peak * 0.5;
  
  // min 값으로 초기화
  for (let i = 0; i < minCount; i++) {
    data.push(Math.floor(min * getRandomInRange(1, 4))); 
  }

  // 나머지 값은 정규 분포를 따르도록 설정
  for (let i = minCount; i < length; i++) {
    let normalValue = boxMullerTransform(peak, stdDev);
    data.push(normalValue < min ? min : Math.floor(normalValue));
  }

  // 배열을 섞어서 min 값들이 배열의 시작 부분에만 모이지 않도록 함
  shuffleArray(data);

  return data;
}

const boxMullerTransform = (mean, stdDev) => {
  let u = 0, v = 0;
  while (u === 0) u = Math.random(); // u와 v가 0이 되는 것을 방지
  while (v === 0) v = Math.random();
  let z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  return z * stdDev + mean;
}

const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const getTemplateData = (imageTypeNames, imageTypeList, studyCount) => {
  const randomDateList = getDateList(studyCount)
  const template =  randomDateList.map(date => {
    const dummyData = {date}
    for (const imagetypeName of imageTypeNames) {
      let min = 0
      let max = 4095 // MRI 12bit 최대값
      let minRatio = 0.2
      let array = []
      
      const x = 32
      const y = 32
      const z = 8
      
      const short = imagetypeName.includes(TASK_DELIMITER) ? 
        imagetypeName.split(TASK_DELIMITER)[0] :
        imagetypeName
      const imagetype = imageTypeList.find(type => type.short === short)
      if (!imagetype) {
        continue
      }
      if (imagetype.seg) {
        min = 0
        max = imagetype.seg_cmap.length
        const baseRatio = 0.95 - (max * 0.01)
        minRatio = baseRatio > 0.5 ? baseRatio : 0.5
        array = Array.from({ length: x * y * z }, () => getIntRandom(min, max, minRatio));
      }
      else {
        max = 4095
        min = Math.floor(getRandomInRange(1, Math.floor(max * 0.01)))
        minRatio = 0.4
        array = generateData(x * y * z, min, max, minRatio)
      }
      dummyData[imagetypeName] = {min, max, array, seg: imagetype.seg}
      if (imagetype.seg) {
        const cmapValues = imagetype.seg_cmap.map(cmap => cmap.value)
        const counts = cmapValues.map(value => {
          return {
            value,
            count: Math.floor(getRandomInRange(1, 10))
          }
        })
        
        dummyData[imagetypeName].counts = counts
      }
    }
    return dummyData
  })
  return [randomDateList, template]
}

const evaluateCondition = (expression, index) => {
  const array = expression.array
  const targetValue = array[index]
  switch (expression.op) {
    case '=':
      return targetValue === expression.value;
    case '>':
      return targetValue > expression.value;
    case '<':
      return targetValue < expression.value;
    case '>=':
      return targetValue >= expression.value;
    case '<=':
      return targetValue <= expression.value;
    default:
      return false;
  }
}

const evaluateConditions = (conditions, index) => {
  let currentGroupResult = evaluateCondition(conditions[0], index);
  let finalResult = currentGroupResult;

  for (let i = 1; i < conditions.length; i++) {
    const condition = conditions[i];

    if (condition.lop === 'and') {
      // 같은 그룹 내에서 'and' 연산 수행
      currentGroupResult = currentGroupResult && evaluateCondition(condition);
    } else if (condition.lop === 'or') {
      // 'or' 연산자를 만나면, 이전 그룹의 결과를 최종 결과에 'or' 연산하고 새 그룹 시작
      finalResult = finalResult || currentGroupResult;
      currentGroupResult = evaluateCondition(condition);
    }
  }

  // 마지막 그룹의 결과를 최종 결과에 반영
  return finalResult || currentGroupResult;
}

const applyFilterCondition = (selectedArray, expressions, targetTemplate) => {
  const newExpressions = expressions.map(exp => {
    const target = exp.target
    return {
      ...exp,
      array: targetTemplate[target].array
    }
  })
  let min
  let max

  const filteredArray = selectedArray.filter((value, index) => {
    const result = evaluateConditions(newExpressions, index)
    if (result) {
      if (min === undefined || value < min) {
        min = value
      }
      if (max === undefined || value > max) {
        max = value
      }
    }
    return result
  })
  return {min, max, array: filteredArray}
}

const getAppliedNeglectSmallValue = (array, threshold) => {
  return array.filter(value => {
    return value > threshold
  })
}

function createHistogramData(data, nbinsx, min, max) {
  const maxVal = max
  const minVal = min
  const binSize = (maxVal - minVal) / nbinsx;
  const bins = new Array(nbinsx).fill(0);
  const bin_centers = Array.from({ length: nbinsx }, (_, i) => minVal + binSize * (i + 0.5));

  data.forEach(val => {
    const index = Math.min(Math.floor((val - minVal) / binSize), nbinsx - 1);
    bins[index]++;
  });

  return { bin_centers, bins };
}

const getOperatedValue = (array, min, max, operation, counts, nbinsx) => {
  let value
  switch (operation) {
    case VALUE_OPERATION.VOLUME:
      value = array.length
      break
    case VALUE_OPERATION.COUNT:
      const values = [...new Set(array)]
      const sumCounts = counts
        .filter(item => values.includes(item.value))
        .reduce((total, item) => total + item.count, 0)
      value = sumCounts
      break
    case VALUE_OPERATION.MEAN:
      const sum = array.reduce((total, currentValue) => total + currentValue, 0);
      const average = sum / array.length;
      value = average;
      break
    case VALUE_OPERATION.MIN:
      value = min
      break
    case VALUE_OPERATION.MAX:
      value = max
      break
    case VALUE_OPERATION.MEDIAN:
      value = calculateMedian(array)
      break
    case VALUE_OPERATION.STD:
      value = calculateStandardDeviation(array)
      break
    case VALUE_OPERATION.HISTOGRAM:
      value = createHistogramData(array, nbinsx, min, max)
      break
    case VALUE_OPERATION.SPARKLINE:
      value = createHistogramData(array, 50, min, max)
      break
    default: 
      value = undefined
  }
  if (value === undefined) {
    return 0
  }
  if (typeof value === 'number') {
    return isNaN(value) ? 0 : value
  }
  return value
}

const getDummyTemplate = (graphTableRows, imageTypeList, studyCount) => {
  const usedImageTypeNames = []
  for (const row of graphTableRows) {
    const selected = row?.target?.selected
    if (selected) {
      usedImageTypeNames.push(selected)
    }
    const expressions = row?.[ROW_INFO.FILTER_CONDITION]
    if (expressions?.length) {
      for (const exp of expressions) {
        const target = exp.target
        if (target) {
          usedImageTypeNames.push(target)
        }
      }
    }
  }
  // date 내부에 base 데이터 제작
  const [dateList, template] = getTemplateData([...new Set(usedImageTypeNames)], imageTypeList, studyCount) 
  return [dateList, template]
}

const getDummyDataList = (graphTableRows, dateList, template, page) => {
  // const [dateList, template] = getDummyTemplate(graphTableRows, imageTypeList, studyCount)

  const dummyDataLists = dateList.map((date, index) => {
    const dummyData = {date}
    const targetTemplate = template[index]
    for (const row of graphTableRows) {
      const selected = row?.target?.selected
      const segValue = row?.target?.seg_value
      if (!targetTemplate[selected]) {
        continue
      }
      const selectedArray = targetTemplate[selected]?.array
      const seg = targetTemplate[selected]?.seg
      let filteredArray = selectedArray
      let min = targetTemplate[selected].min
      let max = targetTemplate[selected].max
      const value = row?.[ROW_INFO.VALUE]
      const operation = value?.operation
      if (!operation) {
        continue
      }

      const expressions = row?.[ROW_INFO.FILTER_CONDITION]
      if (expressions?.length) {
        if (expressions.some(exp => !exp.target || exp.value === undefined)) {
          continue // 값 표현 불가
        }
        const filteredData = applyFilterCondition(selectedArray, expressions, targetTemplate)
        filteredArray = filteredData.array
        min = filteredData.min
        max = filteredData.max
      }
      const applyNeglectSmallValues = row?.[ROW_INFO.NEGLECT_SMALL_VALUES]
      if (applyNeglectSmallValues && !seg) {
        const smallValuesThreshold = row?.[ROW_INFO.SMALL_VALUES_THRESHOLD] || 0.5
        const threshold = Math.floor(max * smallValuesThreshold * 0.01)
        min = threshold
        filteredArray = getAppliedNeglectSmallValue(filteredArray, threshold)
      }
      if (seg && segValue !== ALL_SEG_CMAP.value) {
        filteredArray = filteredArray.map(value => {
          if (value === segValue) {
            return segValue
          }
          return 0
        })
        max = segValue
        min = 0 
      }
      let counts
      let histogramBins
      if (targetTemplate[selected]?.counts) {
        counts = targetTemplate[selected]?.counts
      }
      if (operation === VALUE_OPERATION.HISTOGRAM || operation === VALUE_OPERATION.SPARKLINE) {
        const globalSetting = page?.[SETTING]
        const globalTableSettingValues = globalSetting?.[TABLE_ROW_TYPE.TABLE] || []
        const tableTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE)
    
        const tableRowTemplate = getGraphTableTemplate(TABLE_ROW_TYPE.TABLE, row)
        const getRowConfigValue = getConfigValue(globalTableSettingValues, tableTemplate, row.config, tableRowTemplate)
        histogramBins = getRowConfigValue(TABLE_TYPE_SETTING_NAMES.NUMBER_OF_BINS)
      }
      
      const operatedValue = getOperatedValue(filteredArray, min, max, operation, counts, histogramBins)
      
      dummyData[row.index] = operatedValue
    }
    return dummyData
  })
  return dummyDataLists
}

const getTrackImageDummyData = studyCount => {
  const randomDateList = getDateList(studyCount)

  const trackDummyData = randomDateList.map((date,i) => {
    const dummyTrackArray = Array.from({length: 50}).map((_, index) => {
      const ratio = 0.8
      const exist = Math.random() < ratio

      const getVolume = exist => {
        if (!exist) {
          return 0
        }
        const min = 1; // 최소값
        const max = 100 * (i + 1); // 최대값
        const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
        return randomNum
      }

      const volume = getVolume(exist)
      return {
        locationId: index,
        exist,
        volume,
        imageUrl: undefined
      }
    }).filter(trackInfo => trackInfo.exist)
    return {
      date,
      trackInfo: dummyTrackArray
    }
  }).map((trackData, index, array) => {
    if (index === 0) {
      return {
        ...trackData,
        trackInfo: trackData.trackInfo.map(trackInfo => {
          return {
            ...trackInfo,
            state: TRACK_STATE.NEW
          }
        })
      }
    }
    const previousTrackData = array[index - 1]
    const previousTrackInfo = previousTrackData.trackInfo
    const currentTrackInfo = trackData.trackInfo
    return {
      ...trackData,
      trackInfo: currentTrackInfo.map(trackInfo => {
        const currVolume = trackInfo.volume
        const prevVolume = previousTrackInfo?.find(preTrackInfo => 
          preTrackInfo.locationId === trackInfo.locationId)?.volume
        let state = TRACK_STATE.NEW
        if (prevVolume) {
          state = (currVolume / prevVolume) >= 1.65 ? 
            TRACK_STATE.INCREASE : 
          (currVolume / prevVolume) <= 0.35 ? 
            TRACK_STATE.DECREASE : 
            TRACK_STATE.STABLE
        }
        return {
          ...trackInfo,
          state
        }
      })
    }
  })

  const lastDateData = trackDummyData.at(-1)
  const lastDateTrackInfo = lastDateData.trackInfo
  const usedLocationIds = lastDateTrackInfo.map(trackInfo => trackInfo.locationId)
  
  const locations = [...new Set(usedLocationIds)].map(id => {
    const location = randomLocation().join(',')
    return {
      id,
      location: `${location}`
    }
  })
  
  const addedLocationDummyData = trackDummyData.map(trackData => {
    return {
      ...trackData,
      trackInfo: trackData.trackInfo.filter(trackInfo => {
        return usedLocationIds.includes(trackInfo.locationId)
      }).map(trackInfo => {
        return {
          ...trackInfo,
          location: locations.find(location => location.id === trackInfo.locationId)?.location
        }
      })
    }
  })

  
  
  return addedLocationDummyData
}

const randomLocation = () => {
  return Array.from({length: 3}).map(() => {
    const min = 0; // 최소값
    const max = 255; // 최대값
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum
  })
}