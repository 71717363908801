import { inputFormatter } from "../../lib/inputFormatter";
import { REPORT_TYPE, INPUT_TYPE, reportContext, FormDataList, capitalizeFirstLetter } from "./utils"
import { memo, useContext } from "react";
import { addPage, removePage, updateActive, updatePageProperty } from "./reportReducer";
import GraphAndTable from "./graph-and-table";
import TrackImage from "./trackImage";
import { PlusSquareOutlined } from '@ant-design/icons';
import { CloseOutlinedWithHover, LessPaddingTabs } from "../styledComponent";

const MemoizedTabs = memo(LessPaddingTabs)


export const pageTemplate = [
  {
    name: 'title',
    type: INPUT_TYPE.STRING,
    default: 'new page',
    vertical: false,
    maxLength: 30,
    showCount: true,
    formatter: inputFormatter,
    required: true,
  },
  {
    name: 'type',
    type: INPUT_TYPE.SELECT,
    vertical: false,
    required: true,
    options: [
      {
        name: capitalizeFirstLetter(REPORT_TYPE.GRAPH_N_TABLE),
        value: REPORT_TYPE.GRAPH_N_TABLE,
      },
      {
        name: capitalizeFirstLetter(REPORT_TYPE.TRACK_IMAGE),
        value: REPORT_TYPE.TRACK_IMAGE,
      }
    ],
    extra: [
      {
        name: REPORT_TYPE.GRAPH_N_TABLE, 
        type: INPUT_TYPE.GRAPH_N_TABLE, 
        vertical: true,
        default: [],
        triggerValue: REPORT_TYPE.GRAPH_N_TABLE,
        hideLabel: true
      },
      {
        name: REPORT_TYPE.TRACK_IMAGE,
        type: INPUT_TYPE.TRACK_IMAGE,
        vertical: true,
        default: {},
        triggerValue: REPORT_TYPE.TRACK_IMAGE,
        hideLabel: true
      }
    ]
  }
]

const Pages = ({pages, readOnly=false}) => {
  const {dispatchReport} = useContext(reportContext)

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      targetKey.target.blur()
      return dispatchReport(addPage())
    } else if (action === 'remove') {
      return dispatchReport(removePage(targetKey))
    }
    return null
  }

  const onActiveChange = key => dispatchReport(updateActive(key))

  const addIcon = <><PlusSquareOutlined style={{marginRight: 5}} />Add page</>

  const activePage = pages?.find(page => page.active)

  const items = pages?.map((page, pageIndex) => {
    const onUpdate = payload => dispatchReport(updatePageProperty(payload))
    return ({
      label: page.title,
      key: page.key,
      children: (
        <div style={{padding: '0 10px'}}>
          <FormDataList
            template={pageTemplate}  
            value={page}
            onUpdate={onUpdate}
            extraMargin={0}
            GraphAndTable={GraphAndTable}
            TrackImage={TrackImage}
            readOnly={readOnly}
          />
        </div>
      ),
      closeIcon: <CloseOutlinedWithHover style={{color: 'gray'}} />
    })
  })
  
  return (
    <>
      <MemoizedTabs
        type={!readOnly ? "editable-card" : 'card'}
        activeKey={activePage?.key}
        onChange={onActiveChange}
        onEdit={onEdit}
        addIcon={addIcon}
        items={items}
      />
    </>
  )
}

export default memo(Pages)