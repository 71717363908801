import React, {useEffect, useContext} from "react";
import {Button, Row, Col, Space, Switch} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import { MODE, get, clear, updateRunState, updateCaseState, updateDisplay, getDownloadTargets, updateAnalysisState } from "../redux/modules/analysis"
import {useHistory, useLocation } from "react-router-dom";
import {useTitle} from "../hooks/title";
import Split from 'react-split'
import './analysis4.css'
import './pipeline.css'
import { toggleAnalysisTitle } from "../redux/modules/config";
import { SocketContext } from "../hooks/socket";
import { EVT_ANALYSIS } from "../constants";
import { TaskUpperBar, TaskTable, CaseUpperBar, CaseTable, Information } from "./analysisComponents";
import DownloadResultPathSettingModal from "./downloadResultPathSettingModal";
import LoadingModal from "./loadingModal";
import AnalysisQuickViewModal from "./analysisQuickView";

function Analysis() {
  useTitle("Analysis");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.auth.user);
  const analysis = useSelector(state => state.analysis?.analysis);
  const dataset = useSelector(state => state.analysis?.dataset);
  const pipeline = useSelector(state => state.analysis?.pipeline);
  const display = useSelector(state => state.analysis?.display)
  const downloadTargets = useSelector(state => state.analysis?.downloadTargets)
  const detailPerPage = useSelector(state => state.config?.per_page_for_detail)
  const viewLoading = useSelector(state => state.view.loading)

  const locationState = location.state
  const mode = display?.mode || MODE.SINGLE

  const quickViewOpen = useSelector(state => state.analysis?.quickView?.open)

  const runSocketCB = payload => {
    const runId = payload.run_id
    const analysisId = payload.analysis_id
    if (runId) {
      dispatch(updateRunState(payload))
      if (payload.cases) { // case 정보도 있으면 함께 업데이트
        dispatch(updateCaseState(payload))
      }
    }
    if (analysisId) {
      dispatch(updateAnalysisState(payload))
    }
  }
  
  const {addHandler, removeHandler} = useContext(SocketContext)

  useEffect(()=>{
    addHandler(EVT_ANALYSIS, runSocketCB)
    return () => removeHandler(EVT_ANALYSIS, runSocketCB)
  },[user])

  useEffect(() => {
    if (location.state?.mode === undefined && pipeline !== undefined) {
      const state = {...locationState, ...display}
      history.replace(location.pathname, state)
      dispatch(updateDisplay(state))
    }
  }, [pipeline])

  // 최초 analysis db 조회
  useEffect(() => {
    if (location.state?.key !== undefined) {
      const longitudinal =
          location.state?.mode === MODE.MULTI
        ? true
        : location.state?.mode === MODE.SINGLE
        ? false
        : undefined
      const taskId = location.state?.task?.selectedId
      const pagination = location.state?.case?.pagination || {}
      const page = pagination?.page || 1
      const per_page = pagination?.per_page || detailPerPage || 10
      const search = location.state?.case?.search || {}
      const payload = {id: location.state.key, page, per_page, ...search, taskId, longitudinal}
      dispatch(get(payload))
    }
    else {
      history.replace('/analysis')
    }
    window.scrollTo(0, 0)
    return () => {
      if (history.location.pathname !== '/view-volume2' && history.location.pathname !== '/view-volume') { // VolumeImageViewer 에서 사용기 때문
        dispatch(clear())
      }
    }
  }, [])

  useEffect(() => {
    if (history.action === 'POP') {
      dispatch(updateDisplay(locationState))
    }
  },[location])

  const handleOK = () => history.push('/analysis');
  const handleDownload = () => dispatch(getDownloadTargets(locationState.key))
  
  const titleCollapse = !useSelector(state => state.config.analysis_detail_title_open)
  const onCollapseChange = () => dispatch(toggleAnalysisTitle(!titleCollapse))

  const onToggleSwitch = checked => {
    const newMode = checked ? MODE.MULTI : MODE.SINGLE
    const payload = {...display}
    payload.mode = newMode
    dispatch(updateDisplay(payload))
    history.replace(location.pathname, payload)
  }

  return (
    <div
      style={{
        width: '100%',
        position : 'relative',
      }}
    >
      <Row gutter={[8, 4]} align="middle">
        <Col span={6}>
          <h1>Analysis Result</h1>
        </Col>
        <Col span={12}></Col>
        <Col span={6} flex="1">
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "right" }}
            >
              {pipeline?.longitudinal ?
                <Switch
                  checkedChildren="longitudinal view"
                  unCheckedChildren="cross-sectional view"
                  onChange={onToggleSwitch}
                  checked={mode === MODE.MULTI}
                /> : null
              }
              {downloadTargets && 
                <DownloadResultPathSettingModal />
              }
              <Button onClick={handleDownload} disabled={!(analysis?.state === 'success' || analysis?.state === 'fail')}>Download</Button>
              <Button onClick={handleOK}>OK</Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Split
        sizes={[40, 80]}
        minSize={[400, 800]}
        expandToMin={true}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        cursor="col-resize"
        className={`split`}
      >
        <div className="overflow-scroll">
          <div style={{position:'sticky', top: '0', zIndex :'1000', backgroundColor:'#18343B'}}>
            <Information title={'Pipeline'} data={pipeline} collapse={titleCollapse} onCollapseChange={onCollapseChange}/>
            <br/>
            <TaskUpperBar />
          </div>
          <TaskTable />
        </div>
        <div className="overflow-scroll">
          <div style={{position:'sticky', top :'0', zIndex :'1000', backgroundColor:'#18343B'}}>
            <Information title={'Dataset'} data={dataset} collapse={titleCollapse} onCollapseChange={onCollapseChange}/>
            <br/>
            <CaseUpperBar />
          </div>
          <CaseTable mode={mode}/>
        </div>
      </Split>
      {/* View 버튼 클릭시 loading */}
      <LoadingModal loading={viewLoading}/>
      {quickViewOpen && <AnalysisQuickViewModal />}
    </div>
  );
}

export default Analysis;