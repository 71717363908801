// 참고 https://react-dropzone.js.org/#section-styling-dropzone

import React, {useState, useMemo, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import './fileUpload.css'
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import {Checkbox} from "antd";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#162b31',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%'
};

const acceptStyle = {
  borderColor: '#00e676'
};

function FileUpload({msg, dropped, anonymize, niftiOnly, onDrop, dispatchDicomDrop, anonymizeVisible=true, niftiOnlyVisible=false}) {
  const dropzoneRef = useRef();
  const [dragActive, setDragActive] = useState(false)

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    getFilesFromEvent : e => {
      return new Promise((resolve, reject) => {
        if (e.type ==='drop') {
          if (e.dataTransfer.items.length > 0) {
            dispatchDicomDrop({
              type: 'UPDATE_ONLY',
              payload: {
                loading : true,
                parseCountMax: 0,
                progress: 0
              }})
            try {
              getDroppedOrSelectedFiles(e).then(chosenFiles => {
                if (chosenFiles.length > 0) {
                  const fileList = chosenFiles.map(f => {
                    const file = f.fileObject
                    file.path = f.fullPath
                    return file
                  })
                  resolve(fileList)
                } else {
                  dispatchDicomDrop({
                    type: 'UPDATE_ONLY',
                    payload: {
                      loading : false,
                      parseCountMax: 0,
                      progress: 0
                  }})
                }
              })
            } catch (e) {
              dispatchDicomDrop({
                type: 'UPDATE_ONLY',
                payload: {
                  loading : false,
                  parseCountMax: 0,
                  progress: 0
                }})
            }
          }
        }
      })}
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(dragActive ? acceptStyle : {})
  }), [dragActive])

  const onChangeAnonymize = (e) => {
    e.stopPropagation()
    dispatchDicomDrop({
      type: 'UPDATE_ONLY',
      payload: { anonymize: e.target.checked },
    });
  };
  const onChangeNiftiOnly = (e) => {
    e.stopPropagation()
    dispatchDicomDrop({
      type: 'UPDATE_ONLY',
      payload: { niftiOnly: e.target.checked },
    });
  };

  return (
    <section
      className="container"
      style={dropped ? {margin: 'auto', height: '100%'} : {margin: '10% 10% auto', height: '50%'}}
      ref={dropzoneRef}
      onDragOver={() => setDragActive(true)}
      onDragLeave={() => setDragActive(false)}
      onDrop={() => setDragActive(false)}
    >
      <div
        style={dropped ?
          {float: 'right', marginLeft: 20} :
          {display: 'flex', justifyContent: 'flex-end', paddingBottom: 10}
        }
      >
        {anonymizeVisible &&
          <Checkbox checked={anonymize} onChange={onChangeAnonymize}>
            {/*Use Top Level Directory Name as Patient ID*/}
            Anonymized DICOM
          </Checkbox>
        }
        {niftiOnlyVisible &&
          <Checkbox checked={niftiOnly} onChange={onChangeNiftiOnly}>
            {/*Use Top Level Directory Name as Patient ID*/}
            NIfTI only
          </Checkbox>
        }
      </div>
      <div {...getRootProps({className: 'dropzone', style})}>
        <input {...getInputProps()} />
        <span style={ dropped ? null: {fontSize: 25}}>{msg ? msg : "Drop files or folders Here"}</span>
        {/*<p>Drag 'n' drop some files here, or click to select files</p>*/}
      </div>
    </section>
  );
}

export default FileUpload;
