import { DatePicker, Input, Modal, Typography, Table, Tabs } from "antd";
import styled from "styled-components";
import { CloseOutlined } from '@ant-design/icons';

export const TextAreaWithCount = styled(Input.TextArea)`
  ::after {
    color : rgba(255,255,255,0.33)
  }
  texarea {
    overflow : auto;
  }
  textarea::-webkit-scrollbar {
    background-color: rgba(12, 35, 41,0.5);
    width: 8px;
  }
  textarea::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
  }
  textarea::-webkit-scrollbar-thumb {
    background-color: #18343B;
  }
  textarea::-webkit-resizer {
    border-width : 20px;
    border-style : solid;
    border-color : transparent #18343B #18343B transparent;
    background-color : rgba(12, 35, 41, 1);
  }
`

export const PrefixWhiteDatePicker = styled(DatePicker)`
  span {
    color : rgba(255,255,255,0.3)
  }
`

export const TransparentBGModal = styled(Modal)`
  .ant-modal-content {
    background-color : rgba(0,0,0,0);
    box-shadow : none;
  }
`

// Typography.Text 의 original font-size = 80%
export const FontSizeFullText = styled(Typography.Text)` 
  code {
    font-size : 100%; 
    border : 0;
    margin : 0;
    padding : 0;
  }
`
// 배경 검은색으로 Report 제작시 사용
export const SummaryColorTable = styled(Table)`
  .ant-table-cell {
    background-color : rgb(0,0,0) !important;
  }

  .ant-table-cell-row-hover {
    background-color : rgb(0,0,0) !important;
  }
  .ant-table-thead {
    background-color : rgb(0,0,0) !important;
    border-bottom : 1px solid #666666 !important;
  }
`

export const CloseOutlinedWithHover = styled(CloseOutlined)`
  :hover {
    color : inherit !important;
  }
`

export const LessPaddingTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding: 8px 8px !important;
  }
`
