import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Space, Card, Button, Progress, Row, Col} from "antd";
import FileUpload from "./fileUpload2";
import UnresolvedList from "./unresolvedList";
import "react-block-ui/style.css";
import DatasetSelect from "./datasetSelect";
import { load, clear } from "../redux/modules/blobTypes";
import PatientStudyList from "./patientStudyList";
import { useDicomDrop } from "../hooks/dicomDrop";
import {useTitle} from "../hooks/title";
import { ThreeDots, RotatingLines } from  'react-loader-spinner'
import useUploadContext from "../context/uploadContext";
import {TransparentBGModal} from './styledComponent'
import ModalAskPatientID from "./modalAskPatientID";

function Upload() {
  useTitle('DYNAPEX | Upload')
  const dispatch = useDispatch();
  const uploadLoading = useSelector(state => state.longTask.upload.loading)

  const [stateDicomDrop, dispatchDicomDrop, onDrop] = useDicomDrop();
  const {
    loading,
    progress,
    parseCount,
    parseCountMax: parseLength,
    exception,
    dropped,
    anonymize,
    niftiOnly,
    resolveds,
    unresolveds,
    loaded,
    modalAskPatientID
  } = stateDicomDrop;

  useEffect(() => {
    dispatch(load({}));
    return () => dispatch(clear())
  }, []);

  const datasetCardRef = useRef();
  const resolvedsCardRef = useRef();
  const unresolvedsCardRef = useRef();

  const {
    datasetIdRef, 
    startUpload, 
  } = useUploadContext({
    resolveds,
    unresolveds,
    dispatchDicomDrop,
    datasetCardRef,
    resolvedsCardRef,
    unresolvedsCardRef
  })

  const handleCancel = () => {
    dispatchDicomDrop({type : 'INIT_THEN_UPDATE', payload : {}})
  }

  const handleUpload = () => {
    startUpload()
  };

  const LoadingUI = () => {
    if (uploadLoading) {
      return (
        <Space>
          <RotatingLines 
            strokeColor="#0D6E6D"
            strokeWidth="5"
            animationDuration="0.75"
            width="30"
            visible={true}
          />
        </Space>
      )
    }
    else if (loading && loaded && parseLength !== 0) {
      return (
        <div>
          {`Parsing files... ${parseCount}/${parseLength}`}
          <br />
          <br />
          <Progress
            type="circle"
            percent={progress}
            status={exception ? "exception" : ""}
          />
        </div>
      )
    }
    return (<Space direction="vertical" align="center">
              <ThreeDots 
                height="40" 
                width="40" 
                radius="9"
                color="#0D6E6D" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
              <h4>Accepting files ...</h4>
            </Space>
          ) 
  };

  function handleDatasetSelectChange(val, option) {
    datasetIdRef.current = val
  }

  return (
    <div style={{width: "100%"}}>
      <ModalAskPatientID
        data={modalAskPatientID}
      />
      <TransparentBGModal
        open={loading || uploadLoading}
        footer={null}
        closable={false}
        zIndex={2000}
        centered
        bodyStyle={{backgroundColor : 'rgba(0,0,0,0)'}}
      >
        <div style={{
          display: "flex",
          height : '100%',
          justifyContent: "center",
          alignItems: "center",
          textAlign : 'center'
        }}>
          <LoadingUI />
        </div>
      </TransparentBGModal>
      <Row gutter={[8, 4]} align="middle">
        <Col span={6}>
          <h1>Upload</h1>
        </Col>
        <Col span={12}>
          {dropped ? (
            <FileUpload
              msg="Drag & drop files or folders here for additional data"
              onDrop={onDrop}
              dropped={dropped}
              anonymize={anonymize}
              niftiOnlyVisible={true}
              niftiOnly={niftiOnly}
              dispatchDicomDrop={dispatchDicomDrop}
            />
          ) : null}
        </Col>
        <Col span={6} flex="1">
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "right" }}
            >
              {dropped ? (
                <Space>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button
                    type="primary"
                    className="login-form-button"
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                </Space>
              ) : null}
            </Space>
          </div>
        </Col>
      </Row>
      {dropped ? (
        <Space style={{ width: "100%" }} direction="vertical" size="middle">
          <Card ref={datasetCardRef}>
            <h3>Dataset</h3>
            <DatasetSelect
              onChange={handleDatasetSelectChange}
              dropdownMatchSelectWidth={true}
              defaultValue={"Create or select a dataset"}
              loading={loading}
            />
          </Card>
          <Card ref={resolvedsCardRef}>
            <PatientStudyList
              resolveds={resolveds}
              unresolveds={unresolveds}
              loading={loading}
              update={dispatchDicomDrop}
              binds={stateDicomDrop.binds}
            />
          </Card>
          {unresolveds.length > 0 ?
            <Card ref={unresolvedsCardRef}>
              <UnresolvedList
                resolveds={resolveds}
                unresolveds={unresolveds}
                loading={loading}
                update={dispatchDicomDrop}
                binds={stateDicomDrop.binds}
              />
            </Card>
            : null}
        </Space>
      ) : (
        <FileUpload
          onDrop={onDrop}
          dropped={dropped}
          anonymize={anonymize}
          niftiOnlyVisible={true}
          niftiOnly={niftiOnly}
          dispatchDicomDrop={dispatchDicomDrop} />
      )}
    </div>
  );
}

export default Upload;
