import {Switch, Row, Col, Divider, Checkbox, Form, InputNumber, Modal, Slider, Typography} from "antd";
import ImageFilterExpression from "./imageFilterExpression";
import React, {useRef, useState, useMemo, useEffect} from "react";
import {prepareProcessingPerUrl, update} from "./taskResultViewer";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import {useDispatch} from "react-redux";
import TaskImageIOSelect, {MODE} from "./taskImageIOSelect";
import {msgError, msgInfo} from "../redux/modules/message";
import {compareVolumeMetaData} from "../lib/cornerstoneUtils";
import {TASK_DELIMITER} from "../lib/taskUtil";

const commands = ["Statistics", "Histogram", "Scatter"]
const imageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
export const STATS_SELECTION = ['avg', 'std', 'min', 'max', 'median']


const CommandOptionModal = (
  {
    viewerState, viewerDispatch,
    taskResults, extraImages,
    imageTypeList,
    thisTask
  }
) => {
  const dispatch = useDispatch();
  const {
    // loading,
    // progress,
    processing,
    // acs,
    // dates,
    cmds,
    // inputs,
    // outputs,
    urls,
    // mapsOnly,
    // mapCount,
    // windowSize,
    // viewWidth,
    // allViewWidth,
    // showRightSider,
    // openPopOver,
    openModal,
    imageTypeSelected,
    // reset,
    // locate,
    // navData,
    // tools,
    stats,
    histogram,
    scatter,
    modalType,
  } = viewerState
  const commandIdx = commands.findIndex(t => t === modalType)

  const imgFilterExprRef = useRef()
  const [binCount, setBinCount] = useState(30)

  const getImageUrlsInResult = taskResult => {
    return [
      ...taskResult.input,
      ...taskResult.output,
      ...(extraImages || [])
    ].map(img => {
      if (img?.length) {
        return img.url.map((url, j) => {
          const imageType = imageTypeList?.find(bt => bt.id === img?.selected_id?.[j] || bt.id === img?.blobtype?.[j])
          return [{img: {url}, typeName: img.selected[j], imageType}]
        })
      }
      else {
        // selected_id for input & blobtype for output, blobtype_id for extraImages
        const imageType = imageTypeList?.find(bt => bt.id === img?.selected_id || bt.id === img?.blobtype || bt.id === img?.blobtype_id)

        // input 은 selected 사용하면 되고, extra image 는 key 가 있어서 그걸 쓴다. output 은 강제로 만들어야 할 듯
        const fromInput = !!taskResult.input.find(i => i === img)
        const name = fromInput && img?.selected || img?.key || `${imageType?.short}${TASK_DELIMITER}${thisTask.order}`
        return {img, typeName: name, imageType}
      }
    }).flat(Infinity)
  }

  const buildFilterMaskThenDispatchRequest = newReqObj => {
    // filter 수식 validation: target 없는 것 제거하고 첫번째 수식에 lop 가 있으면 제거
    const filterLen = newReqObj.filter.length
    newReqObj.filter = newReqObj.filter.filter(expr => expr?.target)
    if (newReqObj.filter.length > 0) {
      if (newReqObj.filter[0].hasOwnProperty('lop')) {
        delete newReqObj.filter[0].lop
      }
    }
    // 입력 수식 form 에 저장
    const formCurr = forms[commandIdx]
    formCurr.setFieldsValue({
      filterCondition: newReqObj.filter
    })
    const diff = filterLen - newReqObj.filter.length
    if (diff) {
      dispatch(msgInfo(`Invalid expression${diff > 1 ? 's in filter are' : ' in filter is'} removed`))
    }

    // filter 값이 빈 경우, 즉 filterMask 필요 없는 경우 바로 dispatch action 후 종료
    if (newReqObj.filter.length === 0) {
      if (commandIdx !== 2) {
        viewerDispatch(prepareProcessingPerUrl({mapsOnly: true}))
      }
      viewerDispatch(update({
        processing: !processing,
        openModal: false,
        ...(commandIdx === 0 && {stats: {...newReqObj, filterMasks: null, filterMaskMetaDatas: null}}),
        ...(commandIdx === 1 && {histogram: {...newReqObj, filterMasks: null, filterMaskMetaDatas: null}}),
        ...(commandIdx === 2 && {scatter: {...newReqObj}}),
      }))
    }
    else {
      // filter 값이 있는 경우, 즉 filterMask 필요한 경우
      viewerDispatch(update({processing: true, openModal: false,}))

      const filterMasks = []
      const filterMasksMetaData = []
      taskResults.forEach(taskResult => {
        // inputs, outputs, extraImages
        const allImages = getImageUrlsInResult(taskResult)

        // 선택된 맵 loadVolume + 필터 걸기 => build mask?
        const segList = []
        const promises = []
        newReqObj.filter.forEach(expr => {
          const found = allImages.find(img => expr.target === img.typeName)
          if (found) {
            segList.push(found.imageType.seg)
            const niftiImageId = imageId.fromURL(`nifti:${found.img.url}#x`)
            promises.push(cornerstoneNIFTIImageLoader.nifti.loadVolume(niftiImageId.url))
          }
        })
        Promise.all(promises).then(volumes => {
          const metaData0 = volumes[0].metaData
          for (let i = 1; i < volumes.length; ++i) {
            if (!compareVolumeMetaData(metaData0, volumes[i].metaData)) {
              viewerDispatch(update({processing: false}))
              dispatch(msgError('Unable to build filter mask: all images in the filter should be co-registered'))
              return
            }
          }

          const vols = volumes.map(v => ({
            slope: v.metaData.slope,
            intercept: v.metaData.intercept,
            maxPixelValue: v.metaData.maxPixelValue,
            ndarray: v.imageDataNDarray
          }))

          const myWorker = new Worker(new URL("../workers/voxelFilter.worker.js", import.meta.url));
          myWorker.postMessage([segList, vols, newReqObj.filter]);
          myWorker.onmessage = function (e) {
            const {filterMask} = e.data
            newReqObj.filterMask = filterMask
            newReqObj.filterMaskMetaData = metaData0
            newReqObj.filterMaskMetaData.dataType = {
              ...newReqObj.filterMaskMetaData.dataType,
              TypedArrayConstructor: undefined,
              OriginalTypedArrayConstructor: undefined
            }

            filterMasks.push(filterMask)
            filterMasksMetaData.push(metaData0)

            if (filterMasks.length === taskResults.length) {
              if (commandIdx !== 2) {
                viewerDispatch(prepareProcessingPerUrl({mapsOnly: true}))
              }
              viewerDispatch(update({
                // cmds: newCmds,
                ...(commandIdx === 0 && {stats: {...newReqObj, filterMasks: filterMasks, filterMaskMetaDatas: filterMasksMetaData}}),
                ...(commandIdx === 1 && {histogram: {...newReqObj, filterMasks: filterMasks, filterMaskMetaDatas: filterMasksMetaData}}),
                ...(commandIdx === 2 && {scatter: {...newReqObj}}),
              }))
            }
            myWorker.terminate()
          }
        })
      })
    }
  }

  const handleOkStats = req => {
    if (req.selection.length === 0) {
      dispatch(msgError('At least, 1 item must be selected for calculation'))
      return
    }
    buildFilterMaskThenDispatchRequest({...stats, ...req,})
  }
  const handleOkHistogram = req => {
    buildFilterMaskThenDispatchRequest({...histogram, ...req, binCount: binCount,})
  }
  const handleOkScatter = req => {
    // filterMask 도 만들어야 하고 (buildFilterMaskThenDispatchReq 에서 수행)
    // scatter 대상이 되는 map 들로부터 scatter trace 도 뽑아야 함
    const cmap = imageTypeList.find(bt => bt.id === req.catVar.blobtype_id).seg_cmap
    buildFilterMaskThenDispatchRequest({
      ...scatter,
      ...req,
      opacity: req.opacity * 0.01,
      legends: Object.assign({}, ...cmap.map(s => ({[s.value]: s.name})))
    })
  }

  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const forms = [form1, form2, form3]
  const handleOks = [handleOkStats, handleOkHistogram, handleOkScatter]
  const handleOk = () => {
    forms[commandIdx].submit()
  }

  const handleFinishForm = values => {
    handleOks[commandIdx]({
      ...values,
      execute: true,
      filter: imgFilterExprRef.current?.getExpression() || []
    })
  }

  const handleCancel = () => {
    cmds.states[commandIdx] = false
    viewerDispatch(update({
      openModal: false,
      cmds: {...cmds},
    }))
  }

  const [checkAll, setCheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);

  const onCheckAllChange = e => {
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    form1.setFieldValue('selection', e.target.checked ? STATS_SELECTION : [])
  };

  const onCheckChange = e => {
    const selection = form1.getFieldValue('selection')
    const count = e.target.checked ? selection.length + 1 : selection.length - 1
    setIndeterminate(count > 0 && count < STATS_SELECTION.length);
  }

  const MyCheckbox = (props) => <Checkbox onChange={onCheckChange} {...props}/>

  const CommonOptions = ({filter}) =>
    <>
      <Form.Item label="Neglect small values" name="neglect" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Small value threshold" name="neglectThreshold">
        <Slider
          min={0.0001} max={0.01} step={0.0001}
          tooltip={{formatter: value => `${(value * 100).toFixed(2)} %`}}
        />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        label="filter-condition"
        name="filterCondition">
        <ImageFilterExpression
          ref={imgFilterExprRef}
          imageTypeSelected={imageTypeSelected}
          imageTypeList={imageTypeList}
          value={[...filter]}
        />
      </Form.Item>
    </>

  const commonInitValues = {
    neglect: true,
    neglectThreshold: 0.005
  }
  const formStatistics = (
    <Form
      colon={false}
      form={form1}
      initialValues={{
        ...commonInitValues,
        selection: STATS_SELECTION,
        filterCondition: stats.filter
      }}
      onFinish={handleFinishForm}
    >
      <Form.Item
        labelCol={{span: 24}}
        label={
          <div style={{display: "flex", justifyContent: "space-between", width: "50vw"}}>
            Select item to calculate
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              Check all
            </Checkbox>
          </div>
        }
      >
        <Divider style={{margin: 0, marginBottom: 4}}/>
        <Form.Item name="selection" noStyle>
          <Checkbox.Group style={{width: '100%'}}>
            <Row gutter={[8, 4]}>
              <Col span={8}>
                <MyCheckbox value="avg"> Average </MyCheckbox>
              </Col>
              <Col span={8}>
                <MyCheckbox value="std"> Standard deviation </MyCheckbox>
              </Col>
              <Col span={8}>
                <MyCheckbox value="min"> Minimum </MyCheckbox>
              </Col>
            </Row>
            <Row gutter={[8, 4]}>
              <Col span={8}>
                <MyCheckbox value="max"> Maximum </MyCheckbox>
              </Col>
              <Col span={8}>
                <MyCheckbox value="median"> Median </MyCheckbox>
              </Col>
              <Col span={8}>
                {/*<MyCheckbox value="qnt"> Quantiles </MyCheckbox>*/}
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form.Item>
      <CommonOptions filter={stats.filter}/>
    </Form>
  )

  const formHistogram = (
    <Form
      labelCol={{span: 6}}
      colon={false}
      form={form2}
      initialValues={{
        ...commonInitValues,
        filterCondition: histogram.filter
      }}
      onFinish={handleFinishForm}
    >
      <Form.Item label="Number of bins" name="bins">
        <InputNumber defaultValue={binCount} onChange={setBinCount} min={10} max={100}/>
      </Form.Item>
      <CommonOptions filter={histogram.filter}/>
    </Form>
  )

  const imagesLoaded = [...imageTypeSelected.filter(t => t.selected)].map(t => {
    return {
      ...t,
      selected: false,
      disabled: false,
    }
  })

  const segImages = imagesLoaded.filter(t => imageTypeList?.find(bt => bt.id === t.blobtype_id)?.seg)
  const nonSegImages = imagesLoaded.filter(t => !imageTypeList?.find(bt => bt.id === t.blobtype_id)?.seg)
  const catVarDefault = segImages?.[0]
  const xVarDefault = nonSegImages?.[0]
  const yVarDefault = nonSegImages?.[1]

  const handleTaskImageIOSelectChange = name => (value, option) => {
    // const test = form3.getFieldsValue(true)
    // console.log(test)

    if (!!value?.length) {
      if (name === 'catVar') {
        const selectedValue = segImages?.find(it => it.key === value[0])
        form3.setFieldsValue({[name]: selectedValue})
      }
      else {
        const selectedValue = nonSegImages?.find(it => it.key === value[0])
        form3.setFieldsValue({[name]: selectedValue})
      }
    }
    else {
      form3.setFieldsValue({[name]: undefined})
    }
  }
  const formScatter = (
    <Form
      labelCol={{span: 6}}
      colon={false}
      form={form3}
      initialValues={{
        ...commonInitValues,
        filterCondition: scatter.filter,
        catVar: catVarDefault,
        xVar: xVarDefault,
        yVar: yVarDefault,
        showLegend: true,
        opacity: 30
      }}
      onFinish={handleFinishForm}
    >
      <Form.Item
        label={<span style={{alignSelf: "normal"}}><div>1st variable</div><div>(x-axis)</div></span>}
        name="xVar"
        rules={[{required: true, message: 'Please select image'}]}
      >
        <TaskImageIOSelect
          options={nonSegImages}
          onChange={handleTaskImageIOSelectChange("xVar")}
          style={{height: 44, paddingTop: 8}}
        />
      </Form.Item>
      <Form.Item
        label={<span style={{alignSelf: "normal"}}><div>2nd variable</div><div>(y-axis)</div></span>}
        name="yVar"
        rules={[{required: true, message: 'Please select image'}]}
      >
        <TaskImageIOSelect
          options={nonSegImages}
          onChange={handleTaskImageIOSelectChange("yVar")}
          style={{height: 44, paddingTop: 8}}
        />
      </Form.Item>
      <Form.Item
        label={<span style={{alignSelf: "normal"}}><div>Categorical variable</div><div>(segmentation)</div></span>}
        name="catVar"
        rules={[{required: true, message: 'Please select image'}]}
      >
        <TaskImageIOSelect
          options={segImages}
          onChange={handleTaskImageIOSelectChange("catVar")}
          style={{height: 44, paddingTop: 8}}
        />
      </Form.Item>
      <Form.Item label="Plot legend" name="showLegend" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="Opacity" name="opacity">
        <Slider
          style={{marginLeft: 0}}
          tooltip={{formatter: value => `${value} %`}}
        />
      </Form.Item>
      <CommonOptions filter={scatter.filter}/>
    </Form>
  )
  useEffect(() => {
    // urls 변경은 새 케이스 로드니까 모든 필드 초기화
    forms.forEach((f, i) => {
      f.resetFields()
    })
    // 왜 명시적으로 해줘야 리셋이 될까 ㅠ
    form3.setFieldsValue({
      catVar: catVarDefault,
      xVar: xVarDefault,
      yVar: yVarDefault,
    })
  }, [urls])

  useEffect(() => {
    if (forms[commandIdx]) {
      const conditions = forms[commandIdx].getFieldValue('filterCondition')
      const validConditions = conditions.filter(expr => {
        return expr.target && imageTypeSelected.find(imgType => {
          return imgType.selected && imgType.key === expr.target
        })
      })
      if (validConditions.length > 0) {
        if (validConditions[0].hasOwnProperty('lop')) {
          delete validConditions[0].lop
        }
      }
      // console.log(conditions, imageTypeSelected, validConditions)
      forms[commandIdx].setFieldValue('filterCondition', validConditions)
    }
  }, [urls])

  return (
    <Modal
      title={modalType + ' Options'}
      closable={false}
      open={openModal}
      onOk={handleOk}
      onCancel={handleCancel}
      forceRender={true}
      width={700}
    >
      {commandIdx === 0 ? formStatistics : null}
      {commandIdx === 1 ? formHistogram : null}
      {commandIdx === 2 ? formScatter : null}
    </Modal>
  )
}
export default CommandOptionModal;