import React, { useEffect } from 'react';
import {Form, Input, Button, Checkbox, Modal, Space} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import { login } from '../redux/modules/auth'
import {useTitle} from "../hooks/title";
import { useHistory } from 'react-router-dom';

const NormalLoginForm = () => {
  useTitle('DYNAPEX | Login')
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    const remberData = localStorage.getItem('remember')
    if (remberData) {
      let values = JSON.parse(atob(remberData))
      if (values.lastLogin) {
        const todayTimeStamp = new Date().getTime()
        const lastLoginDateTimeStamp = new Date(values.lastLogin).getTime()
        const mmPerSecond = 1000
        const secPerHour = 3600
        const hourPerDay = 24
        const storedPeriod = Math.floor((todayTimeStamp - lastLoginDateTimeStamp)/(mmPerSecond*secPerHour*hourPerDay))
        if (storedPeriod > 30) {
          values = {}
          localStorage.removeItem('remember');
        }
      }
      form.setFieldsValue(values)
    }
  },[])

  const onFinish = values => {
    if (values.remember) {
      const today = new Date();

      const year = today.getFullYear();
      const month = ('0' + (today.getMonth() + 1)).slice(-2);
      const day = ('0' + today.getDate()).slice(-2);
      const time = today.toTimeString().split(' ')[0]

      const dateString = year + '-' + month  + '-' + day + " " +time;
      const rememberData = {...values, lastLogin : dateString}
      const encodeData = btoa(JSON.stringify(rememberData))
      localStorage.setItem('remember', encodeData);
    }
    else {
      localStorage.removeItem('remember');
    }
    dispatch(login({values}));
  };

  function handleRegister () {
    history.push('/register')
  }

  function handleRemberMe(e) {
    if (e.target.checked) {
      return Modal.warning({
        title : 'Save login information to this PC',
        content : (
          <div>
            <p>Please note that personal information may be leaked in public places</p>
            <p>It is stored for a month from the last login</p>
          </div>),
      })
    }
  }

  return (
    <Form
      form={form}
      style={{
        width: '30%',
        margin: 'auto',
        verticalAlign: 'middle',
        textAlign: 'center',
      }}
      name="normal_login"
      className="login-form"
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username or E-mail"/>
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon"/>}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Space style={{width : '100%', justifyContent:'space-between'}}>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox onClick={e=>handleRemberMe(e)}>Remember me</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Space>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
        {' '}
        <Button onClick={handleRegister}>
         register
        </Button>
      </Form.Item>
    </Form>
  );
};

export default NormalLoginForm;
