import React, {useEffect, useState, forwardRef, useRef, useContext} from "react";
import {Button, Switch, Space, message, Typography} from 'antd';
import CornerstoneElement from './cornerstoneElement'
import {useDispatch, useSelector} from "react-redux";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import VolumeImageOverlayContext from "../context/volumeImageOverlayContext";
import SegmentationContext from "../context/segmentationContext";
import OverlayOption from "./overlayOption";
import {buildNiftiStack, calcMinMaxSlice, compareVolumeMetaData, computeAutoVoi, concatTypedArrays} from "../lib/cornerstoneUtils";
import Plot from "react-plotly.js";
import {msgError, msgWarn} from "../redux/modules/message";
import {MemoizedHistogramPlot, MemoizedScatterPlot} from "./Plots";
import {updateProgressByURL, updateProcessingByURL, update} from "./taskResultViewer";
import usePrevious from "../hooks/previous";
import myOrientationTool from "../lib/customTools/myOrientationTool";
import MyReferenceLinesTool from "../lib/customTools/myReferenceLinesTool"
// import JSONView from 'react-json-view'
import {arraysEqual} from "../lib/compare";
// import useIsMounted from "../hooks/isMounted";
import IJKContext, {IJKContextProvider, IJKValue} from "../context/ijkContext";
import {STATS_SELECTION} from "../components/commandOptionModal";
import {clearUrlCache} from "../redux/modules/view";

function VolumeImageViewer(props) {
  const dispatch = useDispatch()

  const {
    url,
    urls,
    acs,
    cmds,
    handleLoadEnd,
    tools,
    scrollSynchronizers,
    newImageSynchronizer,
    width,
    colormap,
    seg,
    seg_cmap,
    tag,
    locate,
    reset,
    autowwwc,
    showSider,
    viewerDispatch,
    allImageLoaded,
    stats,
    histogram,
    scatter,
    viewerState,
    volumeIndex
  } = props;
  // console.log('histogram:', histogram)
  let synchronizerWWWC = useRef(null);

  useEffect(() => {
    return () => {
      // memory leak 방지
      cleanupSegContext(url)
      cleanupOverlayContext(url)
      if (synchronizerWWWC.current) {
        synchronizerWWWC.current.destroy()
      }
      if (refX.current?.element) {
        scrollSynchronizers.current[0].remove(refX.current.element)
        newImageSynchronizer.current.remove(refX.current.element)
      }
      if (refY.current?.element) {
        scrollSynchronizers.current[1].remove(refY.current.element)
        newImageSynchronizer.current.remove(refY.current.element)
      }
      if (refZ.current?.element) {
        scrollSynchronizers.current[2].remove(refZ.current.element)
        newImageSynchronizer.current.remove(refZ.current.element)
      }
      if (scrollSynchronizers.current.length > 0) {
        scrollSynchronizers.current[0].destroy()
        scrollSynchronizers.current[1].destroy()
        scrollSynchronizers.current[2].destroy()
      }
      if (newImageSynchronizer.current) {
        newImageSynchronizer.current.destroy()
      }
      // cornerstone.imageCache.purgeCache();
      // cornerstoneNIFTIImageLoader.nifti.purge();

      flattened3DLabelCache.current = null

      filteredPixelData3DIndex.current = null;
      volumeZ.current = null

      if (requestPromise.current.state() === 'pending' && requestObj.current) {
        requestObj.current.abort()
        cancelled.current = true
        dispatch(clearUrlCache({url}))
      }
      requestObj.current = null
      requestPromise.current = null
    }
  }, []);

  const [filteredPixelData3D, setFilteredPixelData3D] = useState(null);
  // const filteredPixelData3D = useRef();
  // const [filteredPixelData3DIndex, setFilteredPixelData3DIndex] = useState(null);
  const filteredPixelData3DIndex = useRef();
  const [noImage, setNoImage] = useState(false);
  useEffect(() => {
    if (noImage) {
      removeCornerstoneTools()
    }
  }, [noImage])

  const volumeZ = useRef() // volume Z 맞나?

  const cancelled = useRef(false)
  const requestObj = useRef(null)
  const requestPromise = useRef(null)

  const directions = ['x', 'y', 'z']

  useEffect(() => {
    let alive = true
    const imageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
    cornerstoneNIFTIImageLoader.nifti.localMode = false;
    cornerstoneNIFTIImageLoader.nifti.configure({
      beforeSend: (request, url) => {
        requestObj.current = request
      }
    })

    cancelled.current = false
    const niftiUrl = imageId.fromURL(`nifti:${url}`)
    requestPromise.current = cornerstoneNIFTIImageLoader.nifti.loadVolume(niftiUrl.url)
    requestPromise.current.then(async (volume)  => {
      requestObj.current = null
      cornerstoneNIFTIImageLoader.nifti.configure({
        beforeSend: null
      })
      volumeZ.current = volume

      const newStacks = {x: {}, y: {}, z: {}}
      let promises = []
      for (const dir of directions) {
        const fileName = url.split('?')[0].split('/').at(-1)
        const niftiImageId = imageId.fromURL(`nifti:${url}#${dir}`)
        await cornerstone.loadAndCacheImage(niftiImageId.url).then(imageLoaded => {
          const newStack = buildNiftiStack(niftiImageId, imageLoaded, layerOption)
          if (newStack) {
            newStack.fileName = fileName
            newStack.dir = dir
            promises = promises.concat(newStack.imageIds.map(stackImageId => cornerstone.loadAndCacheImage(stackImageId)))
            newStacks[dir] = newStack
          }
        })
      }
      if (alive) {
        Promise.all(promises).then(values => {
          viewerDispatch(updateProgressByURL(url, {state: 'success'}))
        })
        flattened3DLabelCache.current = null // 여기서 날려놔야 나중에 다시 빌드
        setStacks(newStacks);
      }
    }).catch(e => {
      if (cancelled.current) { return }

      // cancel 된 게 아니라면 로딩에 실패한 것이니 이미지 경로가 잘못되었거나.. 이미지가 존재하지 않는 경우임
      viewerDispatch(updateProgressByURL(url, {state: 'failed'}))
      dispatch(msgError(`"Failed to load image" ${e.message}`))
      setNoImage(true)
    })
    return () => {
      alive = false
      cleanupSegContext(url)
      cleanupOverlayContext(url)
    }
  }, [url])


  const [statsCount, setStatsCount] = useState(null)
  const [statsValue, setStatsValue] = useState(null)
  const statsPrev = usePrevious(stats)
  const commonOptions = ['neglect', 'neglectThreshold']
  const compareStatsOptions = (prev, curr) => {
    // const test = objectsEqual(prev, curr) // 이런식으로 비교하면 filterMask 비교가 들어가서 엄청 느려짐
    const commonSame = commonOptions.every(o => prev[o] === curr[o])
    return commonSame && prev.selection === curr.selection && arraysEqual(prev.filter, curr.filter)
  }
  useEffect(() => {
    if (noImage === true || !volumeZ.current) {return}
    // 경우의 수
    // 1. 새로 만들어야 하는 경우 (cmds.states[1] && stats?.execute && compareStatsOptions)
    // 2. 이전에 만든 것을 다시 사용하는 경우 (cmds.states[1] && stats?.execute && !compareStatsOptions)
    // 3. filter 조건이 달라진 경우

    // long running task 로딩 처리
    if (cmds.states[0] && stats?.execute) { // 보여줘 (execute 없으면 아직 option 지정중)
      if (!compareStatsOptions(stats, statsPrev)) { // 새로 만들어 보여줌
        if (stats?.filterMasksMetaData?.[0] && !compareVolumeMetaData(volumeZ.current.metaData, stats?.filterMasksMetaData?.[0])) {
          viewerDispatch(updateProcessingByURL(url, {processing: false}))
          dispatch(msgWarn(`[${tag.props.value}] Unable to apply filter: image shape mismatch`))
          return
        }

        // prep stats
        const myWorker = new Worker(new URL("../workers/stats.worker.js", import.meta.url));
        myWorker.postMessage([
          seg,
          STATS_SELECTION,
          stats.selection,
          {
            filterMask: stats?.filterMasks?.[0],
            neglect: stats.neglect,
            neglectThreshold: stats.neglectThreshold},
          {
            slope: volumeZ.current.metaData.slope,
            intercept: volumeZ.current.metaData.intercept,
            maxPixelValue: volumeZ.current.metaData.maxPixelValue,
            isFloat: volumeZ.current.metaData.dataType.isDataInFloat,
            ndarray: volumeZ.current.imageDataNDarray,
          }
        ]);
        myWorker.onmessage = function (e) {
          const {count, values} = e.data
          setStatsCount(count)
          setStatsValue(values)
          viewerDispatch(updateProcessingByURL(url, {processing: false}))
          // myWorker.terminate()
        }
      }
      else { // 만들어 둔 것 보여줌
        viewerDispatch(updateProcessingByURL(url, {processing: false}))
      }
    }
  }, [cmds, stats])

  const compareHistogramOptions = (prev, curr) => {
    // const test = objectsEqual(prev, curr) // 이런식으로 비교하면 filterMask 비교가 들어가서 엄청 느려짐
    const commonSame = commonOptions.every(o => prev[o] === curr[o])
    return commonSame && prev.binCount === curr.binCount && arraysEqual(prev.filter, curr.filter)
  }
  const histogramPrev = usePrevious(histogram)
  useEffect(() => {
    if (noImage === true || !volumeZ.current) {return}
    // 경우의 수
    // 1. 새로 만들어야 하는 경우 (cmds.states[1] && histogram?.execute && compareHistogramOptions)
    // 2. 이전에 만든 것을 다시 사용하는 경우 (cmds.states[1] && histogram?.execute && !compareHistogramOptions)
    // 3. filter 조건이 달라진 경우

    // long running task 로딩 처리
    if (cmds.states[1] && histogram?.execute) { // 보여줘 (execute 없으면 아직 option 지정중)
      if (!compareHistogramOptions(histogram, histogramPrev)) { // 새로 만들어 보여줌
        if (histogram?.filterMasksMetaData?.[0] && !compareVolumeMetaData(volumeZ.current.metaData, histogram?.filterMasksMetaData?.[0])) {
          viewerDispatch(updateProcessingByURL(url, {processing: false}))
          dispatch(msgWarn(`[${tag.props.value}] Unable to apply filter: image shape mismatch`))
          return
        }

        // prep histogram
        const myWorker = new Worker(new URL("../workers/histo.worker.js", import.meta.url));
        myWorker.postMessage([
          seg,
          {filterMask: histogram?.filterMasks?.[0], neglect: histogram.neglect, neglectThreshold: histogram.neglectThreshold},
          {
            slope: volumeZ.current.metaData.slope,
            intercept: volumeZ.current.metaData.intercept,
            maxPixelValue: volumeZ.current.metaData.maxPixelValue,
            isFloat: volumeZ.current.metaData.dataType.isDataInFloat,
            ndarray: volumeZ.current.imageDataNDarray,
          }
        ]);
        myWorker.onmessage = function (e) {
          const {nzPixelData, nzPixelDataIdx} = e.data
          setFilteredPixelData3D(nzPixelData)
          filteredPixelData3DIndex.current = nzPixelDataIdx
          viewerDispatch(updateProcessingByURL(url, {processing: false}))
          myWorker.terminate()
        }
      }
      else { // 만들어 둔 것 보여줌
        viewerDispatch(updateProcessingByURL(url, {processing: false}))
      }
    }
  }, [cmds, histogram])

  const [stacks, setStacks] = useState({x: {}, y: {}, z: {}});
  const flattened3DLabelCache = useRef(null)
  // const [synchronizer, setSynchronizer] = useState(
  //   new cornerstoneTools.Synchronizer(cornerstone.EVENTS.NEW_IMAGE, cornerstoneTools.updateImageSynchronizer)
  // );


  const refX = useRef(null);
  const refY = useRef(null);
  const refZ = useRef(null);
  const refXPrev = usePrevious(refX.current)
  const refYPrev = usePrevious(refY.current)
  const refZPrev = usePrevious(refZ.current)
  useEffect(() => {
    const x = refX.current ? 1 : 0
    const y = refY.current ? 1 : 0
    const z = refZ.current ? 1 : 0
    const needSync = x + y + z >= 2
    if (noImage === false && needSync) {
      synchronizerWWWC.current = new cornerstoneTools.Synchronizer(
        cornerstone.EVENTS.IMAGE_RENDERED, cornerstoneTools.wwwcSynchronizer);
      if (refX.current?.element) {
        synchronizerWWWC.current.add(refX.current.element);
        scrollSynchronizers.current[0].add(refX.current.element)
        newImageSynchronizer.current.add(refX.current.element)
      }
      if (refY.current?.element) {
        synchronizerWWWC.current.add(refY.current.element)
        scrollSynchronizers.current[1].add(refY.current.element)
        newImageSynchronizer.current.add(refY.current.element)
      }
      if (refZ.current?.element) {
        synchronizerWWWC.current.add(refZ.current.element)
        scrollSynchronizers.current[2].add(refZ.current.element)
        newImageSynchronizer.current.add(refZ.current.element)
      }
    }
    addCornerstoneTools()

    // NOTE 이건 뭐였지? ㅠ
    // // overlays
    // if (overlays.length > 0) {
    //
    // }

    return () => {
      if (synchronizerWWWC.current) {
        synchronizerWWWC.current.destroy()
      }
      if (refX.current?.element) {
        scrollSynchronizers.current[0].remove(refX.current.element)
        newImageSynchronizer.current.remove(refX.current.element)
      }
      if (refY.current?.element) {
        scrollSynchronizers.current[1].remove(refY.current.element)
        newImageSynchronizer.current.remove(refY.current.element)
      }
      if (refZ.current?.element) {
        scrollSynchronizers.current[2].remove(refZ.current.element)
        newImageSynchronizer.current.remove(refZ.current.element)
      }
    }
  }, [urls])

  useEffect(() => {
    addCornerstoneTools()
  }, [tools])

  useEffect(() => {
    if (allImageLoaded) {
      if (seg && stacks.x?.imageIds) {
        handleChangeSegOverlay(false)(true)
      }
    }
  }, [allImageLoaded, stacks])

  useEffect(() => {
    if (autowwwc) {
      // 3장
      const eElements = cornerstone.getEnabledElements();
      const myElements = eElements.filter(e => e.image.imageId.includes(url))
      if (myElements.length > 0) {
        const pixels = myElements.map(e => e.image.getPixelData())
        const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(myElements[0].image.imageId)
        const {min, max, skewness} = calcMinMaxSlice(concatTypedArrays(pixels), niftiMetaData)
        myElements.forEach(e => {
          const viewport = cornerstone.getViewport(e.element);
          computeAutoVoi(niftiMetaData, viewport, e.image, min, max, skewness)
          cornerstone.displayImage(e.element, e.image, viewport);
        })
      }
      // stacks
      viewerDispatch(updateProcessingByURL(url, {processing: false}))
    }
  }, [autowwwc]);

  const refPlot = useRef();

  // 예제에서는 display_image가 완료된 이후에 ReferenceLines나 Crosshair를 붙이더라, 아래는 예제 주석
  // Doing some dark magic here to make sure we don't add our
  // synchronizer/tool until all canvases have rendered an image.
  // const renderCount = useRef(0);
  // const addTools = (element) => {
  //   synchronizer.add(element);
  //   renderCount.current = renderCount.current + 1
  //   cornerstoneTools.orientationMarkers.enable(element);
  // }
  // useEffect(() => {
  //   const testZ = refZ.current.element;
  //   const ee = cornerstone.getEnabledElement(testZ);
  //   if (renderCount >= 3) {
  //     // cornerstoneTools.addTool(cornerstoneTools.ReferenceLinesTool);
  //     // cornerstoneTools.setToolEnabled('ReferenceLines', {
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // const tool1 = cornerstoneTools.ReferenceLinesTool;
  //     // cornerstoneTools.addToolForElement(tool1);
  //     // cornerstoneTools.setToolEnabledForElement('ReferenceLines', {
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // cornerstoneTools.setToolActiveForElement
  //
  //     // cornerstoneTools.addTool(cornerstoneTools.CrosshairsTool);
  //     // cornerstoneTools.setToolActive('Crosshairs', {
  //     //   mouseButtonMask: 1,
  //     //   synchronizationContext: synchronizer,
  //     // });
  //     // const tool2 = cornerstoneTools.CrosshairsTool;
  //     // cornerstoneTools.addToolForElement(tool2);
  //     // cornerstoneTools.setToolEnabledForElement('ReferenceLines', {
  //     //   mouseButtonMask: 1,
  //     //   synchronizationContext: synchronizer,
  //     // })
  //   }
  // }, [renderCount])

  const layer_option_base = {
    name: 'layer-base',
    visible: true,
    opacity: 1.0,
    viewport: {
      colormap: 'gray',
    }
  }
  const layer_option_overlay = {
    name: 'layer-overlay',
    visible: true,
    opacity: 0.2,
    viewport: {
      colormap: 'jet',
    }
  }

  const [segOverlaid, setSegOverlaid] = useState(false);
  const {segmentations, toggleSegmentation, action:actionSeg, setAction:setActionSeg, cleanup:cleanupSegContext} = useContext(SegmentationContext);
  const [segOption, setSegOption] = useState({
    name: 'optionBase',
    visible: true,
    opacity: 1.0,
    renderOutline: true,
    renderFill: true,
    shouldRenderInactiveLabelmaps: true,
  });

  const createWorkerFlatten = (volume, ndarray) => {
    return new Promise((resolve, reject) => {
      const worker = new Worker(new URL("../workers/flattenNDarray.worker.js", import.meta.url));
      worker.postMessage([ndarray, volume.metaData.slope, volume.metaData.intercept,]);
      worker.onmessage = function (e) {
        const {flattened} = e.data
        resolve(flattened)
        worker.terminate()
      }
      worker.onerror = function(e) {
        // Rejects the promise using the error associated with the ErrorEvent
        reject(worker, e.error);
      };
    })
  }

  const handleChangeSegOverlay = manual => checked => {
    // url 변경 여부(== cache 유무), toggle 여부를 따져서 toggle 해줘야 함
    const segmentation = segmentations.find(item => item.url === url)
    if (segmentation && flattened3DLabelCache.current !== null) {
      // url도 있고 캐시도 존재하는 경우 (사용자가 클릭한 경우) 이미 켜져있으면 아무일도 하지 않음
      if (!(segOverlaid === checked)) {
        toggleSegmentation(manual, volumeIndex, url, segOption, flattened3DLabelCache.current.x.buffer, flattened3DLabelCache.current.y.buffer, flattened3DLabelCache.current.z.buffer, flattened3DLabelCache.current.metaData)
      }
    }
    else {
      viewerDispatch(update({processing: true}))
      // 왜 volumeZ 인지는 볼륨 XYZ 를 봐야할 듯?
      const promiseX = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray.transpose(1, 2, 0))
      const promiseY = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray.transpose(0, 2, 1))
      const promiseZ = createWorkerFlatten(volumeZ.current, volumeZ.current.imageDataNDarray)
      Promise.all([promiseX, promiseY, promiseZ]).then(results => {
        const [resultX, resultY, resultZ] = results
        flattened3DLabelCache.current = {x: resultX, y: resultY, z: resultZ, metaData: volumeZ.current.metaData}

        // 새로운 url 인 경우(첫 로딩이후 강제로 들어오거나, 이후 변경됨) 무조건 toggle (켜준다)
        segOption.colorLUT = seg_cmap
        toggleSegmentation(manual, volumeIndex, url, segOption, resultX.buffer, resultY.buffer, resultZ.buffer, volumeZ.current.metaData)
        viewerDispatch(update({processing: false}))
      }).catch((e) => console.debug(e))
    }
    setSegOverlaid(checked)
  }

  const [overlaid, setOverlaid] = useState(false);
  const {overlays, toggleOverlay, action, setAction, cleanup:cleanupOverlayContext} = useContext(VolumeImageOverlayContext);
  const [layerOption, setLayerOption] = useState({
    name: 'optionBase',
    visible: true,
    opacity: 1.0,
    viewport: {
      colormap: colormap,
      //   voi: { windowWidth: 30, windowCenter: 16 }
    }
  });

  const handleChangeOverlay = (checked) => {
    setOverlaid(checked)
    toggleOverlay(url, layerOption, stacks.x, stacks.y, stacks.z)
  }

  const removeCornerstoneTools = () => {
    if (synchronizerWWWC.current) {
      synchronizerWWWC.current.destroy()
    }
    if (refX.current?.element) {
      scrollSynchronizers.current[0].remove(refX.current.element)
      newImageSynchronizer.current.remove(refX.current.element)
    }
    if (refY.current?.element) {
      scrollSynchronizers.current[1].remove(refY.current.element)
      newImageSynchronizer.current.remove(refY.current.element)
    }
    if (refZ.current?.element) {
      scrollSynchronizers.current[2].remove(refZ.current.element)
      newImageSynchronizer.current.remove(refZ.current.element)
    }
    // const elems = [refX.current?.element, refY.current?.element, refZ.current?.element]
    // elems.forEach(elem => {
    //   if(elem && cornerstoneTools.isToolActiveForElement(elem, 'Crosshairs')) {
    //     cornerstoneTools.removeToolForElement(elem, 'Crosshairs')
    //   }
    // })
  }
  const addCornerstoneTools = () => {
    // add basic tools
    // 우클릭은 확대, 축소
    const zoomTool = cornerstoneTools.ZoomTool;
    cornerstoneTools.addTool(zoomTool);
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2});
    // 휠클릭은 panning
    const panTool = cornerstoneTools.PanTool;
    cornerstoneTools.addTool(panTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4});
    // 휠인데 mouseButtonMask 는 뭐지?
    const zoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
    cornerstoneTools.addTool(zoomMouseWheelTool);
    cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1});
    // 스택 - 마우스스크롤
    const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
    cornerstoneTools.addTool(StackScrollMouseWheelTool)
    cornerstoneTools.setToolActive('StackScrollMouseWheel', {
      configuration: {
        // loop: true
        allowSkipping: false
      }
    })
    // orientation 레이블
    cornerstoneTools.addTool(myOrientationTool, {configuration: { drawAllMarkers: true }});
    cornerstoneTools.setToolActive('myOrientation')
    // referencelines
    cornerstoneTools.addTool(MyReferenceLinesTool);

    // add tools depending on settings
    if (tools.referencelines) {
      cornerstoneTools.setToolEnabled('MyReferenceLines', {
        synchronizationContext: newImageSynchronizer.current,
      });
    }
    else {
      cornerstoneTools.setToolDisabled('MyReferenceLines', {
        synchronizationContext: newImageSynchronizer.current,
      });
    }

    if (tools.wwwc) {
      const wwwcTool = cornerstoneTools.WwwcTool;
      cornerstoneTools.addTool(wwwcTool);
      cornerstoneTools.setToolActive('Wwwc', {mouseButtonMask: 1});
    }
    else if (tools.dragprobe) {
      const dragProbeTool = cornerstoneTools.DragProbeTool;
      // cornerstoneTools.addTool(dragProbeTool, {defaultStrategy: 'minimal'});
      cornerstoneTools.addTool(dragProbeTool);
      cornerstoneTools.setToolActive('DragProbe', {mouseButtonMask: 1});
    }
    else if (tools.crosshair) {
      cornerstoneTools.addTool(cornerstoneTools.CrosshairsTool);
      cornerstoneTools.setToolActive('Crosshairs', {
        mouseButtonMask: 1,
        synchronizationContext: newImageSynchronizer.current,
      });
    }
  }

  return (
    <IJKContextProvider>
      <div
        style={{display: "flex", flexDirection: "row"}}
        className='loading-overlay-target'
        onContextMenu={(e) => e.preventDefault()}
      >
        <CornerstoneElement
          hidden={!acs[0]}
          volumeIndex={volumeIndex}
          direction="z"
          stack={stacks.z}
          ref={refZ}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations, action: actionSeg}}
          tag={tag}
          width={width}
          noImage={noImage}
          // height={props.height}
          locate={locate}
          reset={reset}
          viewerState={viewerState}
          seg={false}
        />
        <CornerstoneElement
          hidden={!acs[1]}
          volumeIndex={volumeIndex}
          direction="y"
          stack={stacks.y}
          ref={refY}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations, action: actionSeg}}
          tag={tag}
          width={width}
          noImage={noImage}
          locate={locate}
          reset={reset}
          viewerState={viewerState}
          seg={false}
        />
        <CornerstoneElement
          hidden={!acs[2]}
          volumeIndex={volumeIndex}
          direction="x"
          stack={stacks.x}
          ref={refX}
          url={url}
          urls={urls}
          overlay={{overlays:overlays, action: action, layerOption: layerOption}}
          segmentation={{segmentations: segmentations, action: actionSeg}}
          tag={tag}
          width={width}
          noImage={noImage}
          locate={locate}
          reset={reset}
          viewerState={viewerState}
          seg={false}
        />

        {filteredPixelData3D ?
          <MemoizedHistogramPlot
            ref={refPlot}
            data={filteredPixelData3D}
            size={width}
            hidden={!(cmds.states[1] && histogram?.execute)}
            nbinsx={histogram.binCount}
            // onAfterPlot={handlePlotEnd}
          /> : null}


        <div
          style={showSider ? {
            display: 'inline-flex',
            flexDirection: 'column',
            gap: 8,
            padding: 10
          } : {display: 'none'}}
          direction="vertical"
        >
          {seg ?
            <Switch
              style={{width: 'fit-content'}}
              checked={segOverlaid}
              onChange={handleChangeSegOverlay(true)}
              checkedChildren="Cancel segmentation overlay"
              unCheckedChildren="Overlay image as segmentation"
            />
            : null}

          <Switch
            style={{width: 'fit-content'}}
            defaultChecked={overlaid}
            onChange={handleChangeOverlay}
            checkedChildren="Cancel overlay"
            unCheckedChildren="Overlay image"
          />
          <OverlayOption option={layerOption} setOption={setLayerOption}
                         url={url} setAction={setAction} width={width} overlaid={overlaid}/>
          <IJKValue volumeZ={volumeZ.current} seg={segOverlaid} cmap={seg_cmap}/>
          <div>
            Image shape: [{volumeZ.current?.metaData.voxelLength?.join(', ')}]
          </div>
          {/*{volumeIndex}*/}
          <div style={{marginTop: 'auto'}}>
            {cmds.states[0] && stats?.execute && statsValue != null && statsValue.length > 0 ?
              <>
                <Typography.Title
                  level={5}
                  copyable={{
                    text: STATS_SELECTION.map((name, i) => `${name}: ${statsValue[i]?.toFixed(2)}`).join(', ')
                  }}>
                  Statistics
                </Typography.Title>
                {STATS_SELECTION.map((name, i) => {
                  if (statsValue[i] == null) {return undefined}
                  return <div>
                    <Typography.Text code>
                      {name}: {statsValue[i]?.toFixed(2)}
                    </Typography.Text>
                  </div>
                })}

                <Typography.Text style={{display: "block", marginTop: 8}} keyboard>{`${statsCount} voxel${statsCount > 1 ? 's' : null}`}</Typography.Text>
              </>
              : null
            }
          </div>
        </div>
      </div>
    </IJKContextProvider>
  );
}


export default VolumeImageViewer;

