import React, {useEffect, useState} from "react";
import {Switch, Tooltip, Table, Collapse, Card, Form, Input, Space} from "antd";
import {useSelector} from "react-redux";
import TaskInputSelect, {MODE} from "./taskInputSelect";
import {CloseCircleFilled, CloseCircleOutlined, InfoCircleOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {useGetImageTypeHooks} from "../hooks/useImageTypeHooks";
import {getImgTypeId} from "../lib/taskUtil"

const {Panel} = Collapse

// display: inline-block;
// margin-right: 4px;
// color: #ff4d4f;
// font-size: 14px;
// font-family: SimSun, sans-serif;
// line-height: 1;
// content: '*';
const requiredMarkStyle = {
  marginRight: 4,
  color: '#ff4d4f',
  fontSize: 14,
  fontFamily: 'SimSun, sans-serif',
  lineHeight: 1,
  alignSelf: 'center'
}

const errorStyle = {color: '#ff4d4f'}

export const TaskOutput = (props) => {
  const imageTypes = useSelector(state => state.blobTypes);
  const {getTagFromTypeId, getTypeFromTypeId} = useGetImageTypeHooks()

  // TODO task output은 어떻게 달지 흠..
  // TODO
  //  1. "generate map" 입력에 따라 타입 바뀌는 모습 (disabled)
  //  2. btype 이 fixed 된 경우도 있고 flexible 한 경우도 있음, 중요한 건 disabled 라는 것
  //  3. 사용자가 지정하는 경우도 있음 - output 타입 결정하게 해야 할 수도 << 이것 때문에 Form 으로 만드는 중 ㅠ
  //  4. leakage output 제거할 방법? 심지어 output 번호도 꼬이네 젠장..result 8 또는 result 11 로 등장하고 있음..
  //  5. report 타입 rendering 어떻게 할까? 육각형? - track 켰을때 track report 추가해야 하는가?
  //  6. 아래로 몰아야하냐 말아야 하나
  // 4랑 5는 하는 건 쉬운데, 정의해두고 적용하기가 어렵다
  const candidatesFromBlobTypes = (btypes) => {
    if (Array.isArray(btypes)) {
      let names = []
      for (const id of btypes) {
        for (const imgType of imageTypes.list) {
          if (imgType.id === id) {
            names.push(imgType.short)
          }
        }
      }
      return names
    }
    else {
      for (const imgType of imageTypes.list) {
        if (imgType.id === btypes) {
          return [imgType.short]
        }
      }
    }

    return ""
  }

  const buildOutputs = () => {
    if (props.outputs) {
      return props.outputs
    }
    else {
      // props.outputs가 undefined 이거나 null
      if (!props.model) { return []}

      const model = props.models.find(m => {
        return m.name === props.model[0] && m.version === props.model[1]
      })
      if (!model) { return []}

      let outputs = []
      for (const output of model.output) {
        outputs.push({
          name: output.name,
          type: output.type,
          candidates: candidatesFromBlobTypes(output.blobtype),
          desc: 'Descriptions need to be filled',
        })
      }
      return outputs
    }
  }

  const [outputs, setOutputs] = useState([]);

  const updateRow = rowUpdated => {
    const newOutputs = outputs.map(o => {
      if (o.key === rowUpdated.key) {
        return rowUpdated
      }
      else {
        return o
      }
    })
    setOutputs(newOutputs)
  }

  const handleTagAliasChange = record => event => {
    const restrictedChars = /[\\/:*?"()<>|%◀]+|\p{Extended_Pictographic}/gu;
    const sanitized = event.target.value.replace(restrictedChars, "");
    const trimmed = sanitized.trim()
    record.alias = trimmed  !== "" ? sanitized : trimmed
    updateRow(record)
  }

  const handleSuppressChange = record => event => {
    record.suppress = event
    updateRow(record)
  }

  const fillOutputImageTypeBySelect = outputs => {
    return outputs.map((o, oidx) => {
      o.key = oidx // key injection
      if (!o?.blobtype && o?.selected) {
        o.blobtype = o.hasOwnProperty('length') ?
          o.selected.map(sel => getImgTypeId(sel, imageTypes.list))
          : getImgTypeId(o.selected, imageTypes.list)
      }
      return o
    })
  }

  useEffect(() => {
    const newOutputs = fillOutputImageTypeBySelect(buildOutputs())

    // fill tag alias data if needed
    const mapOutputs = newOutputs.filter(o => o.type === 'map' && o?.blobtype)
    const btypeIds = mapOutputs.map(o => o.blobtype).flat(Infinity)
    const btypeSet = new Set(btypeIds)
    if (btypeSet.size === btypeIds.length) {
      newOutputs.forEach(o => {
        if (o.type === 'map') {
          delete o.duplicate
          delete o.children
        }
      })
    }
    else {
      const btypeCounts = {}
      btypeIds.forEach(item => {
        btypeCounts[item] = (btypeCounts[item] || 0) + 1
      })
      // NOTE 중복되는 output 이 존재하고 해당 output 이 배열에 담겨 있으면 강제로 풀어준다
      //  잠깐 중복되는 애만 따로 빼줘도 충분할 것 같은데?

      newOutputs.forEach(o => {
        if (o.type !== 'map') { return }

        if (Array.isArray(o?.blobtype)) {
          if (o.blobtype.length > 0) {
            const duplicateList = o.blobtype.map(bt => btypeCounts[bt] > 1)
            if (duplicateList.some(Boolean)) {
              o.duplicate = duplicateList
              const {desc, length, children: childrenPrev, ...remainder} = o
              o.children = o.blobtype.map((bt, btidx) => {
                const child = {
                  ...remainder,
                  key: `${o.key}-child-${btidx}`,
                  name: `${o.name} ${btidx + 1}`,
                  blobtype: bt,
                  selected: o.selected[btidx],
                  duplicate: btypeCounts[bt] > 1,
                  ...(btypeCounts[bt] > 1 && childrenPrev?.[btidx]?.alias ? {alias: childrenPrev?.[btidx]?.alias} : {})
                }
                return child
              })
              console.log(o.children, childrenPrev)
            }
          }
        }
        else {
          o.duplicate = o?.blobtype && btypeCounts[o.blobtype] > 1
        }
      })

      const duplicateInArray = mapOutputs.some(item => !item.hasOwnProperty('length') ? false : item.blobtype.some(bt => btypeCounts[bt] > 1))
      if (duplicateInArray) {

        // const unraveled = newOutputs.map(item => {
        //   if (item.hasOwnProperty('length')) {
        //     return item.blobtype.map((bt, btidx) => {
        //       const {length, ...remainder} = item
        //       const newItem = {
        //         ...remainder,
        //         blobtype: bt,
        //         selected: item.selected[btidx]
        //       }
        //       if (btypeCounts[newItem.blobtype] > 1) {
        //         newItem.duplicate = true
        //       }
        //       return newItem
        //     })
        //   }
        //   else {
        //     if (item?.blobtype && btypeCounts[item.blobtype] > 1) {
        //       item.duplicate = true
        //     }
        //     return item
        //   }
        // })
        // newOutputs = unraveled.flat().map((o, oidx) => {
        //   o.key = oidx
        //   return o
        // })
      }
      else {
        // Mark items with blobtype that have more than one occurrence as duplicate
      }
    }

    // fill suppress data if needed
    if (newOutputs.some(o => o.suppressible)) {
      newOutputs.forEach(o => {
        if (o?.suppressible && !o.hasOwnProperty('suppress')) {
          o.suppress = false
        }
      })
    }
    setOutputs(newOutputs)

  }, [props.outputs, props.model, props.task])

  const onChange = (output_index) => (val, option, ...t) => {
    // console.log(output_index, val, option, t)
    const newOutputs = [...outputs]
    const curOutput = newOutputs[output_index]

    if (curOutput.hasOwnProperty('length')) {
      curOutput.selected = val
    }
    else {
      if (val.length > 0) {
        curOutput.selected = val[0]
      }
      else {
        delete curOutput.selected
      }
    }

    setOutputs(newOutputs)
    props.onChange({...props.task, outputs: newOutputs})
  }


  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (text, record, index)=> {
        const name = text ? text: `result ${index + 1}`
        const isChild = typeof(record.key) === 'string' && record.key.includes('child')
        const childStyle = {marginLeft: isChild ? 15 : 0}
        const outputDesc ='desc' in record ? record.desc :
          record?.blobtype ? getTypeFromTypeId(record.blobtype)?.name : null
        if (outputDesc != null) {
          return <div style={childStyle}>
            {name} {` `}
            <Tooltip
              showArrow={true}
              title={outputDesc}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <InfoCircleOutlined style={{color: 'rgba(255, 255, 255, 0.5)'}}/>
            </Tooltip>
          </div>
        }
        else {
          return <div style={childStyle}> {name} </div>
        }
      },
    },
    {
      title: 'type',
      dataIndex: 'type',
      key: 'type',
      width: '100%',
      ellipsis: {showTitle: false},
      // shouldCellUpdate: (record, prevRecord) => {
      //   return record.type === prevRecord.type && record?.alias === prevRecord?.alias
      // },
      render: (type, record, index) => {
        // console.log(`${index} type column rendered`, record)
        if (type === 'voxel') {
          return 'voxel data'
        }
        if (type !== 'map') {
          return type
        }
        else {
          const btype = record.blobtype
          if (btype == null) {
            return 'N/A'
          }
          if (Array.isArray(btype)) {
            const myTooltip = content => {
              return <Tooltip title={content} getPopupContainer={trigger => trigger.parentNode.parentNode}>{content}</Tooltip>
            }
            return myTooltip(btype.map(bt => getTagFromTypeId(bt)))
          }
          else {
            const tag = getTagFromTypeId(btype)
            if (record?.duplicate) {
              const type = getTypeFromTypeId(btype)
              const aliasTag = record?.alias ? getTagFromTypeId(btype, `${type.short} (${record.alias})`) : null

              // NOTE alias 는 null 일 수 없다
              // TODO 잠깐 근데 array type 이면 alias 없을 수 있지 않나?
              if (aliasTag === null) {
                return (
                  <div style={errorStyle}>
                    <CloseCircleFilled style={{...errorStyle, marginRight: 8}}/>
                    <span>Please input tag alias</span>
                  </div>
                )
              }

              // NOTE alias 는 중복되면 안 됨
              const checkAlias = i => i.key !== record.key && i?.duplicate && i?.blobtype === record?.blobtype && i?.alias === record.alias
              const checks = outputs.map(o => {
                if (checkAlias(o)) {
                  return true
                }
                if (o?.children && o.children.some(c => checkAlias(c))) {
                  return true
                }
                return false
              })
              if (checks.some(Boolean)) {
                return (
                  <div style={errorStyle}>
                    <CloseCircleFilled style={{...errorStyle, marginRight: 8}}/>
                    <span>Tag alias should be unique</span>
                  </div>)
              }

              return (
                <Space>
                  {tag} <ArrowRightOutlined style={{marginLeft: -8}}/> <span>{aliasTag}</span>
                </Space>
              )
            }
            else {
              return tag
            }
          }
        }
      }
    },
  ]
  // alias 컬럼 추가
  if (outputs.filter(o => o.type === 'map' && o?.blobtype).some(o => o?.duplicate)) {
    const expand = {
      title: '',
      dataIndex: 'key',
      key: 'key',
      width: 30,
      render: (text, record, index)=> {
        return ""
      }
    }
    // columns.unshift(expand)
    columns.splice(1, 0, expand)

    const alias = {
      title: 'Tag alias',
      dataIndex: 'alias',
      key: 'alias',
      width: 140,
      render: (alias, record, index) => {
        if (record?.duplicate && !Array.isArray(record.duplicate)) {
          // form 으로 만들면 편집중에 ok를 할 수가 없음..
          // return <Form.Item
          //   name={`tagalias-${index}`}
          //   required
          //   hasFeedback
          //   rules={[
          //     {
          //       validator: (_, value) => {
          //         if (!value) {
          //           return Promise.reject(new Error("Should have some value"));
          //         }
          //         else {
          //           const names = outputs.filter(o => o.key !== record.key).map(o => o?.)
          //           if (myStrings.includes(value)) {
          //             return Promise.reject(new Error("Should have unique name"));
          //           }
          //           else {
          //             return Promise.resolve();
          //           }
          //         }
          //       }
          //     }
          //   ]}
          // >
          //   <Input
          //     size="small"
          //     maxLength={12}
          //     placeholder="Enter tag alias"
          //     onChange={handleTagAliasChange(record)}
          //     value={record?.alias}
          //   />
          // </Form.Item>
          return <div style={{display: 'inline-flex'}}>
            <div style={{...requiredMarkStyle, display: record?.suppress ? 'none' : 'block'} }>*</div>
            <Input
              size="small"
              maxLength={12}
              placeholder="Enter tag alias"
              onChange={handleTagAliasChange(record)}
              value={record?.alias}
              disabled={record?.suppress}
            />
          </div>
        }
        else {
          return null
        }
      }
    }
    columns.push(alias)
  }
  // suppress 컬럼 추가
  if (outputs.some(o => o.suppressible)) {
    const suppress = {
      title: 'suppress',
      dataIndex: 'suppress',
      key: 'suppress',
      width: 80,
      render: (suppress, record, index) => {
        if (record?.suppressible) {
          return <Switch
            checked={suppress}
            onChange={handleSuppressChange(record)}
          />
        }
        else {
          return null
        }
      }
    }
    columns.push(suppress)
  }

  // tree data 에서는 defaultExpandAllRows 쓰려면 약간의 트릭이 필요하다
  // https://stackoverflow.com/a/64618091

  // console.count('TaskOutput rendered!')
  // console.log(props.task.outputs?.[0], props.outputs?.[0], outputs)
  const seletedModel = props.models.find(m => m.name === props.model?.[0] && m.version === props.model?.[1])
  return (
    <>
      {outputs && outputs.length ? (
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Task output" key="1">
            <Table
              size="small"
              dataSource={outputs}
              columns={columns}
              pagination={false}
              locale={{ emptyText: 'N/A' }}
              expandable={{
                expandIconColumnIndex: 1,
                showExpandColumn: true,
                defaultExpandAllRows: true,
            }}
            />
          </Panel>
        </Collapse>
      ) : (<div>loading...</div>)
      }
    </>
  );
}
