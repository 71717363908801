export const LOAD = 'blob/LOAD';
export const LOAD_SUCCESS = 'blob/LOAD_SUCCESS';
export const LOAD_FAIL = 'blob/LOAD_FAIL';
export const ADD = 'blob/ADD';
export const ADD_SUCCESS = 'blob/ADD_SUCCESS';
export const ADD_FAIL = 'blob/ADD_FAIL';
export const GET = 'blob/GET';
export const GET_SUCCESS = 'blob/GET_SUCCESS';
export const GET_FAIL = 'blob/GET_FAIL';
export const UPDATE = 'blob/UPDATE';
export const UPDATE_SUCCESS = 'blob/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'blob/UPDATE_FAIL';
export const DEL = 'blob/DEL';
export const DEL_SUCCESS = 'blob/DEL_SUCCESS';
export const DEL_FAIL = 'blob/DEL_FAIL';

const initialState = {
  loaded: false,
  blobs: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        blobs: action.payload.blobs,
        total: action.payload.total,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case ADD:
      return {
        ...state,
        loading: true
      };
    case ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        blobs: action.payload
      };
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case GET:
      return {
        ...state,
        loading: true
      };
    case GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        blob: action.payload.blob,
        blobs: action.payload.blobs
      };
    }
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DEL:
      return {
        ...state,
        loading: true
      };
    case DEL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case DEL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function add(payload) {
  return {type: ADD, payload};
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload};
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload};
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function del(payload) {
  return {type: DEL, payload};
}

export function delSuccess(payload) {
  return {type: DEL_SUCCESS, payload};
}

export function delFail(payload) {
  return {type: DEL_FAIL, payload};
}
