import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTitle } from '../hooks/title';
import { ForRunAnalysisTable, mode } from './analysisRunModal';
import { load as loadDataset, clear as clearDataset } from '../redux/modules/dataset';
import { load as loadPipeline, clear as clearPipeline, } from "../redux/modules/pipeline"
import { load as loadImagetype  } from "../redux/modules/blobTypes"
import Split from 'react-split'
import './analysis4.css'
import './pipeline.css'
import { Row, Col, Space, Button } from 'antd';
import { msgError } from '../redux/modules/message';
import { add } from "../redux/modules/analysis";
import {useHistory} from "react-router-dom";

function AnalysisCreate() {
  useTitle("Analysis")
  const dispatch = useDispatch()
  const history = useHistory()
  const dataset_id = useRef(0)
  const pipeline_id = useRef(0)

  useEffect(()=>{
    dispatch(loadDataset({}))
    dispatch(loadPipeline({}))
    dispatch(loadImagetype({}))
    return () => {
      dispatch(clearDataset())
      dispatch(clearPipeline())
    }
  },[])

  const handleOk = () => {
    if (dataset_id.current === 0) {
      return dispatch(msgError('Please select a dataset first'))
    }
    if (pipeline_id.current === 0) {
      return dispatch(msgError('Please select a pipeline first'))
    }

    dispatch(add({dataset_id : dataset_id.current, pipeline_id : pipeline_id.current}))
  }

  const handleCancel = () => history.goBack()

  return (
    <div
      style={{
        width: '100%',
        position : 'relative',
      }}
    >
      <Row gutter={[8, 4]} align="middle">
        <Col span={6}>
          <h1>Analysis</h1>
        </Col>
        <Col span={12}></Col>
        <Col span={6} flex="1">
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "right" }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <Button onClick={handleOk}>OK</Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Split
        sizes={[80, 60]}
        minSize={[780, 500]}
        expandToMin={true}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
        cursor="col-resize"
        className={`split`}
      >
        <div>
          <h3>Pipeline</h3>
          <ForRunAnalysisTable 
            tableTarget={mode.PIPELINE}
            dataset_id={dataset_id}
            pipeline_id={pipeline_id}
          />
        </div>
        <div>
          <h3>Dataset</h3>
          <ForRunAnalysisTable 
            tableTarget={mode.DATASET}
            dataset_id={dataset_id}
            pipeline_id={pipeline_id}
          />
        </div>
      </Split>
    </div>
  )
}

export default AnalysisCreate