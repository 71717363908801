export const patientIdRule = [
  {
    required: true,
    whitespace : true,
    type : 'string',
    message: 'Please input your patient ID!',
  },
  {
    // https://dicom.innolitics.com/ciods/rt-plan/patient/00101002/00100020
    // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html
    // LO (최대 64글자)
    max: 64,
    message : 'Cannot exceed 64 letters'
  },
  {
    pattern : new RegExp(/^[A-Za-z0-9][A-Za-z0-9_.-]+$/),
    message : 'Start with a letter or number and Allowed characters are letter, number, -(hypen), .(periods), _(underscores)',
  },
]

export const patientNameRule = [
  {
    required: true,
    whitespace : true,
    type : 'string',
    message: 'Please input your patient ID!',
  },
  {
    // https://dicom.innolitics.com/ciods/rt-plan/patient/00100010
    // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html#sect_6.2.1
    max: 64,
    message : 'Cannot exceed 64 letters'
  },
  {
    pattern : new RegExp(/^(?!.*[\\]).*$/gu),
    message : `The following special characters cannot be used. \\`
  },
]

export const studyIdRule = [
  {
    // https://dicom.innolitics.com/ciods/12-lead-ecg/general-study/00200010
    // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html
    max: 16,
    message : 'Cannot exceed 16 letters'
  },
  {
    pattern: new RegExp(/^[a-zA-Z0-9_ ]+$/),
    message : 'The only special character can use _(underscore).'
  }
]

export const studyDescRule = [
  {
    // https://dicom.innolitics.com/ciods/12-lead-ecg/general-study/00081030
    // https://dicom.nema.org/medical/dicom/current/output/chtml/part05/sect_6.2.html
    max: 64,
    message : 'Cannot exceed 64 letters'
  }
]