import React from 'react';
import {useSelector} from 'react-redux'
import {Button} from 'antd'

const LoginPending = () => {
  const name =  useSelector(state => {
    if (state.auth.user !== null) {
      return state.auth.user.name;
    }
    else
      return 'anonymous'
  });

  return (
    <div>
      <br/>
      {name}
      <br />
      <br />
      비활성화된 계정입니다.
      <br />
      이메일을 확인하여 활성화 할 수 있습니다.
      <br />
      메일을 다시 보내시겠습니까?
      <br />
      <br />
      <Button> 다시 보내기 </Button>
    </div>
  );
};

export default LoginPending;
