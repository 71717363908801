import React, { useEffect } from "react";
import { Row, Col, Button, Space, Form, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import purify from "dompurify";
import {getDetail, clear} from "../../redux/modules/notice";
import {useTitle} from '../../hooks/title'
import "react-quill/dist/quill.snow.css";
import { TypographyDesc } from "../typographyDesc";

const { Text } = Typography;

function NoticeDetail() {
  useTitle('Notice')
  const history = useHistory();
  const dispatch = useDispatch()
  const location = useLocation();
  const key = location.state?.key;
  const [form] = Form.useForm();

  const article = useSelector(state=>state.notice?.article)
  const loading = useSelector(state=>state.notice?.loading)

  useEffect(()=>{
    if (article === undefined && key !== undefined) {
      dispatch(getDetail({key}))
    }
    return () => dispatch(clear())
  },[])

  
  const handleList = () => history.goBack()

  return (
    <div
      style={{
        maxWidth: '70%',
        minWidth: "70%",
        margin: "20px auto",
      }}
    >
      <Row gutter={[8, 4]} align="middle">
        <Col span={6}>
          <h1>Notice</h1>
        </Col>
        <Col span={12}></Col>
        <Col span={6} flex='1'>
          <div>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "right" }}
            >
              <Button
                onClick={handleList}
              >
                List
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
      <br/>
      <Form
        form={form}
        labelCol={{span : 2}}
        loading={loading}
      >
        <Form.Item name='Title' label='Title'>
          <TypographyDesc>{article?.title}</TypographyDesc>
        </Form.Item>
        <Form.Item name='Author' label='Author'>
          <TypographyDesc>{article?.user}</TypographyDesc>
        </Form.Item>
        <Form.Item name='Date' label='Date'>
          <TypographyDesc>{article?.created}</TypographyDesc>
        </Form.Item>
        <Form.Item name='Content' label='Content'>
          <Text>
            <div className="ql-snow">
              <pre
                className="ql-editor"
                style={{
                  maxHeight: 'max-content' ,
                  overflowY: "auto",
                  padding : '0px 12px',
                  margin : 0
                }}
                dangerouslySetInnerHTML={{ __html: purify.sanitize(article?.content) }}
              />
            </div>
          </Text>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NoticeDetail;
