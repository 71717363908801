import React, {forwardRef} from "react";
import Plot from "react-plotly.js";

const baseAxisStyle = {
  // type: 'log',
  // title: {
  //   standoff: 25
  // },
  autorange: true,
  gridcolor: "rgba(0,0,0,0.2)",
  tickfont: {
    color: "rgba(200,200,200,0.5)",
  },
  zerolinecolor: '#969696',
}

const baseMarginStyle = {
  t: 20, l: 50, b: 35, r: 20,
  pad: 5,
  autoexpand: true,
}

const baseLayout = {
  dragmode: "select",
  paper_bgcolor: "rgba(0,0,0,0)",
  // plot_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: '#2c576275',
  xaxis: {
    ...baseAxisStyle
  },
  yaxis: {
    ...baseAxisStyle
  },
  margin: {
    ...baseMarginStyle
  },
}

const ScatterPlot = forwardRef((props, ref) => {
  // console.log(props.showLegend)
  // const [hover, setHover] = useState(false)
  // const [datarevision, setDataRevision] = useState(0)
  // const reRender = () => {
  //   console.log(`hover ${hover} datarevision ${datarevision}`)
  //   setHover(!hover)
  //   setDataRevision(datarevision + 1)
  // }
  return (
    <div style={props?.hidden ? {display: 'none'} : {border: "1px solid #6c757d",}}>
      {props.data
        ?
        <Plot
          style={{display: "block"}}
          ref={ref}
          data={props.data} // type: 'scattergl' in scatter.worker.js
          layout={{
            ...baseLayout,
            // datarevision: datarevision,
            hovermode: props?.hover === false ? false : 'closest',
            dragmode: props?.hover === false ? false : 'zoom',
            width: props.size,
            height: props.size,
            showlegend: props.showLegend,
            // showlegend: false,
            legend: {
              bgcolor: '#D2D2D2',
              xanchor: 'right',
              x: 1,
              yanchor: 'top',
              y: 1,
            },
          }}
          // onSelected={handlePlotSelected}
          // onSelecting={handlePlotSelecting}
          config={{displaylogo: false}}
          // config={{
          //   displaylogo: false,
          //   displayModeBar: true,
          //   modeBarButtonsToAdd: [{
          //     name: 'Toggle Hover Syncronisation',
          //     // icon: Plot.default.icon.movie,
          //     click: reRender,
          //     // attr: 'hover__',
          //     val: 'hover',
          //     toggle: true
          //   }]
          // }}
          onAfterPlot={props.onAfterPlot}
          // onAfterPlot={handleEndPlot}
        />
        : null
      }
    </div>
  )
})

const HistogramPlot = forwardRef((props, ref) => {
  // return <TestPlot data={props.data} ref={ref} />;
  // const plotSelected = useRef();
  const handlePlotSelected = (e) => {
    // console.log('selected', e)
    // plotSelected.current = e.points
  }
  const handleEndPlot = () => {
    // console.log('hahahah')
  }
  // console.log('histogramPlot', props?.hidden)
  return (
    <div style={props?.hidden ? {display: 'none'} : {border: "1px solid #6c757d"}}>
      {props.data
        ?
        <Plot
          style={{display: "block"}}
          ref={ref}
          data={[
            {
              x: props.data,
              type: 'histogram',
              nbinsx: props.nbinsx,
              opacity: 0.6,
              marker: {
                line: {
                  color: "rbga(255, 255, 255, 1)",
                  width: 2,
                },
              },
            }
          ]}
          layout={{
            ...baseLayout,
            width: props.size,
            height: props.size,
            selectdirection: 'h',
          }}
          onSelected={handlePlotSelected}
          // onSelecting={handlePlotSelecting}
          config={{displaylogo: false}}
          onAfterPlot={props.onAfterPlot}
          // onAfterPlot={handleEndPlot}
        />
        : null
      }
    </div>
  )
});

const commonOptions = ['hidden', 'size', 'data']
function compareHistogram(prev, next) {
  // console.log('compareHistogramPlot', prev.hidden, next.hidden)
  const commonSame = commonOptions.every(o => prev[o] === next[o])
  return commonSame && prev.nbinsx === next.nbinsx
}
function compareScatter(prev, next) {
  const commonSame = commonOptions.every(o => prev[o] === next[o])
  return commonSame && prev.showLegend === next.showLegend && prev.hover === next.hover
}
export const MemoizedHistogramPlot = React.memo(HistogramPlot, compareHistogram);
export const MemoizedScatterPlot = React.memo(ScatterPlot, compareScatter);
