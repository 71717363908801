import React from 'react';
import { Link } from 'react-router-dom'
import { Divider, Space, Typography } from 'antd';

const Footer = () => {
  return (
    <>
      <Divider style={{margin: 0}}/>
      <div style={{padding: '20px', display: 'flex', justifyContent: "center", flexDirection: 'column', alignItems: 'center'}}>
        <Space size='large'>
          <Link to='/policy/terms'>
            <Typography.Title level={5} style={{fontWeight: 'normal', width: 150}}>
              Terms of Service
            </Typography.Title>
          </Link>
          <Link to='/policy/privacy'>
            <Typography.Title level={5} style={{fontWeight: 'normal', width: 150}}>
              Privacy Policy
            </Typography.Title>
          </Link>
        </Space>
        <div>
          <Typography.Text>
            Copyrights © DYNAPEX LLC. All rights reserved.
          </Typography.Text>
        </div>
      </div>
    </>
  );
};

export default Footer;
