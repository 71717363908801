import React, {useEffect, useState, useRef, useLayoutEffect, useReducer, useContext} from "react";
import {Divider, Modal, Slider, Button, Popover, Row, Col, Tooltip} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import VolumeImageViewerLong from "./volumeImageViewerLong";
import _ from 'lodash'
import {VolumeImageOverlayContextProvider} from "../context/volumeImageOverlayContext";
import MultiSegContext, {MultiSegContextProvider} from "../context/multiSegmentationContext";
import LoadingOverlay from 'react-loading-overlay';
import ViewerToolbar, {MODE} from "./viewerToolbar";
import H5Viewer from "./h5Viewer";
import moment from "moment";
import VisNetwork from "./visNetwork";
import {useTitle} from "../hooks/title";
import {getUrl} from "../redux/modules/view";
import TaskImageIOSelect, {MODE as TaskImageIOSelectMODE } from "./taskImageIOSelect";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import VolumeImageViewer from "./volumeImageViewer";
import { TagBase } from "./tagBase";
import {useHotkeys} from "react-hotkeys-hook";
import CommandOptionModal from "./commandOptionModal";
import {MemoizedScatterPlot} from "./Plots";
import {msgError} from "../redux/modules/message";
import {buildImageTypeList} from "../lib/taskUtil";
import {TASK_DELIMITER} from "../lib/taskUtil";
import DicomReportViewer from "./dicomReportViewer";

const segmentationModule = cornerstoneTools.getModule('segmentation');
const imageId = cornerstoneNIFTIImageLoader.nifti.ImageId;

LoadingOverlay.propTypes = undefined;

const initialState = {
  loading: false,
  progress: 0,
  processing: false,
  acs: [true, false, false],
  // longitudinal? [true, false, false] : [true, true, true]
  // cmds: [false, false, false],
  cmds: {
    shows: [false, false, true],
    states: [false, false, false]
  },

  inputs: [],
  outputs: [],
  urls: [],

  mapsOnly: true,
  mapCount: 0,

  windowSize: {},
  viewWidth: 0,
  allViewWidth: 0,
  showRightSider: false,

  openPopOver: false,
  openModal: false,
  openTrackModal: false,
  modalType: '',

  imageTypeSelected: [],

  reset: {},
  autowwwc: false,
  locate: {},
  navData: {},
  tools: {
    wwwc: false,
    dragprobe: false,
    crosshair: true,
    referencelines: true,
    clear: function() {
      this.wwwc = false
      this.dragprobe = false
      this.crosshair = false
      this.referencelines = false
    }
  },

  stats: {
    execute: false,
    nonzero: true,
    filter: []
  },
  histogram: {
    execute: false,
    binCount: 0,
    filter: []
  },
  scatter: {
    execute: false,
    opacity: 0,
    filter: []
  },
  segConfig: {
    visible: true,
    fillAlpha: segmentationModule.configuration.fillAlpha,
    outlineAlpha: segmentationModule.configuration.outlineAlpha,
  },

  dates: [],
  datesState: [],
  extras: [], // extra 도 study 여럿이니까 이구조가 필요하다
}

const initState = (args) => {
  return {
    ...JSON.parse(JSON.stringify(initialState)),
    // stringify 에서 함수는 날아가기때문에 ㅠ
    tools: {
      wwwc: false,
      dragprobe: false,
      crosshair: true,
      referencelines: true,
      clear: function() {
        this.wwwc = false
        this.dragprobe = false
        this.crosshair = false
        this.referencelines = false
      }
    },
    ...args
  }
}

const ACTION_UPDATE = 'UPDATE'
export const update = (payload) => {
  return { type : ACTION_UPDATE, payload }
}

const ACTION_UPDATE_PROGRESS_BY_URL = 'UPDATE_PROGRESS_BY_URL'
export const updateProgressByURL = (url, payload) => {
  return { type : ACTION_UPDATE_PROGRESS_BY_URL, url, payload }
}

const ACTION_PREPARE_PROCESSING = 'PREPARE_PROCESSING'
export const prepareProcessing = () => {
  return { type : ACTION_PREPARE_PROCESSING }
}

const ACTION_UPDATE_PROCESSING_BY_URL = 'UPDATE_PROCESSING_BY_URL'
export const updateProcessingByURL = (url, payload) => {
  return { type : ACTION_UPDATE_PROCESSING_BY_URL, url, payload }
}

const ACTION_INIT_THEN_UPDATE = 'INIT_THEN_UPDATE'
export const initThenUpdate = (payload) => {
  return { type : ACTION_INIT_THEN_UPDATE, payload }
}

const reducer = (state, action) => {
  // console.count(action.type)
  // console.log(action.payload)
  switch(action.type) {
    case ACTION_PREPARE_PROCESSING:
      const newState = {
        ...state,
        processing: true
      }
      newState.urls.forEach(item => {
        // hdf5 데이터에서도 histogram 만들려고 해서 ㅠ
        if (action.payload?.mapsOnly) {
          item.type === 'map' ? item.processing = true : item.processing = false
        }
        else {
          item.processing = true
        }
      })
      return newState
    case ACTION_UPDATE_PROCESSING_BY_URL: {
      const idx = state.urls.findIndex(item => item.url === action.url)
      const url = state.urls[idx]
      state.urls[idx] = {...url, ...action.payload}
      if (state.urls.every(i => !i.processing)) {
        // 모든 url 이 processing false 일 때 전체 processing 도 false
        return {
          ...state,
          processing: false,
          // updateProcessingByURL 쓰는 애들 (autowwwc, stats, histogram) 전부 초기화
          autowwwc: false,
          stats: {
            ...state.stats,
            execute: false
          },
          histogram: {
            ...state.histogram,
            execute: false
          },
        }
      }
      else {
        return state
      }
    }
    case ACTION_UPDATE_PROGRESS_BY_URL: {
      const idx = state.urls.findIndex(item => item.url === action.url)
      const url = state.urls[idx]
      state.urls[idx] = {...url, ...action.payload}

      const progressSum = state.urls.reduce((prev, cur) => prev + cur.progress, 0)
      const newProgress = Math.floor(progressSum/state.urls.length)
      const loadEnd = state.urls.every(i => i.state === 'success' || i.state === 'failed')
      if (newProgress > state.progress) {
        return {
          ...state,
          progress: newProgress,
          loading: !loadEnd
        }
      }
      if (loadEnd) {
        return {
          ...state,
          loading: false
        }
      }

      // for update without re-rendering
      return state
    }
    case ACTION_UPDATE:
      return {
        ...state,
        ...action.payload
      };
    case 'REPLACE_ONLY': {
      const newState = {...state}
      for(const k of Object.keys(action.payload)) {
        newState[k] = action.payload[k]
      }
      return newState
    }
    case ACTION_INIT_THEN_UPDATE:
      return initState(action.payload)
    default:
      return state;
  }
}

function TaskResultViewerLong() {
  return (
    <VolumeImageOverlayContextProvider>
      <MultiSegContextProvider>
        <TaskResultViewerLongInner/>
      </MultiSegContextProvider>
    </VolumeImageOverlayContextProvider>
  )
}

function TaskResultViewerLongInner() {
  useTitle('DYNAPEX | Result View')
  const [state, viewerDispatch] = useReducer(reducer, {}, initState);

  const {
    loading,
    progress,
    processing,
    acs,
    cmds,
    inputs,
    outputs,
    urls,
    mapsOnly,
    mapCount,
    windowSize,
    viewWidth,
    allViewWidth,
    showRightSider,
    openPopOver,
    openModal,
    modalType,
    imageTypeSelected,
    reset,
    autowwwc,
    locate,
    navData,
    tools,
    stats,
    histogram,
    scatter,
    segConfig,
    dates,
    datesState,
    extras,
    openTrackModal,
  } = state

  const history = useHistory();
  const dispatch = useDispatch();
  const loadTarget = useSelector(state => state.view.target, _.isEqual);
  const taskResults = useSelector(state => state.view.list, _.isEqual);
  const extraReq = useSelector(state => state.view.extra, _.isEqual);
  const trackData = useSelector(state => state.view.track, _.isEqual);
  const sidebarOpen = useSelector(state => state.config.sidebar_open);
  // const imageTypeList = useSelector(state => state.analysis.imageTypes);

  const {setAction:setActionSeg} = useContext(MultiSegContext);
  useHotkeys('s', () => {
    // TODO how to throttle
    const newSegConfig = {
      ...segConfig,
      visible: !segConfig.visible
    }
    if (segConfig.visible) {
      newSegConfig.fillAlpha = segmentationModule.configuration.fillAlpha
      newSegConfig.outlineAlpha = segmentationModule.configuration.outlineAlpha
      segmentationModule.configuration.fillAlpha = 0
      segmentationModule.configuration.outlineAlpha = 0
    }
    else {
      if (newSegConfig.fillAlpha === 0 && newSegConfig.outlineAlpha === 0) {
        newSegConfig.fillAlpha = 0.2
        newSegConfig.outlineAlpha = 0.7
      }
      segmentationModule.configuration.fillAlpha = newSegConfig.fillAlpha
      segmentationModule.configuration.outlineAlpha = newSegConfig.outlineAlpha
    }
    viewerDispatch(update({segConfig: newSegConfig}))
    setActionSeg({type: 'changeOption'})
  }, {enableOnFormTags: ["INPUT"]})

  // x, y, z direction 별로 3개 mouse scroll synchronizer 추가
  const scrollSynchronizers = useRef([
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
  ]);
  const newImageSynchronizer = useRef(
    new cornerstoneTools.Synchronizer(
      cornerstone.EVENTS.NEW_IMAGE, cornerstoneTools.updateImageSynchronizer)
  );
  useEffect(() => {
    const removeHistoryListener = history.listen((location, action) => {
      window.scrollTo(0, 0)
    })
    resizeWindow()
    window.addEventListener("resize", resizeWindow);

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
      window.removeEventListener("resize", resizeWindow)
      removeHistoryListener()
    }
  }, []);

  const location = useLocation()
  const {run_id, pid, sids, task_index, case_index} = location.state.volumeInfo
  useEffect(() => {
    if (history.action === 'POP') {
      if ([run_id, pid, sids, task_index, case_index].every(value => value !== undefined)) {
        dispatch(getUrl({...location.state.volumeInfo, action: 'replace'}))
      }
    }
  },[location.state.volumeInfo])

  useEffect(() => {
    if (!taskResults) { return }

    const inputs = []
    const outputs = []
    const extras = []
    let lastSegVolumeIndex = -1
    taskResults.forEach(res => {
      let volumeIndex = 0;
      const processItems = (items, btIdName, mapsOnly) => {
        const res = mapsOnly ? items.filter(i => i.type === 'map') : items
        return res.map(i => {
          const ids = i.hasOwnProperty('length') ? i[btIdName] : [i[btIdName]];
          if (!i.hasOwnProperty('volumeIndex')) {
            // extra load 시에 index 보존을 위해 없을때만 추가
            i.volumeIndex = i.hasOwnProperty('length') ? i.url.map(() => volumeIndex++) : volumeIndex++;
          }
          ids.forEach((id, iidx) => {
            if (analysisImageTypes?.find(bt => bt.id === id)?.seg) {
              lastSegVolumeIndex = i.hasOwnProperty('length') ? i.volumeIndex[iidx] : i.volumeIndex
            }
          })
        })
      }

      processItems(res.input, 'selected_id', true)
      inputs.push(res.input)

      const resOutput = res.output.filter(o => !o?.suppress)
      processItems(resOutput, 'blobtype', true)
      outputs.push(resOutput)
      if (res?.extra) {
        processItems(res.extra, 'blobtype_id', false)
        extras.push(res.extra)
      }
    })

    const transpose = matrix => matrix[0].map((col, i) => matrix.map(row => row[i]))

    // 뒤 flat은 emtpy url 날리려면 꼭 필요
    const allUrls = [
      ...inputs.flat(Infinity).map(i => [i.url].flat().map(j => ({url: j, type: i.type}))).flat(Infinity),
      ...outputs.flat(Infinity).map(i => [i.url].flat().map(j => ({url: j, type: i.type}))).flat(Infinity),
      ...extras.flat(Infinity).map(e => ({url: e.url, type: 'map'})).flat(Infinity)
    ]

    const dates = taskResults.map(r => moment(r.date).format('YYYY-MM-DD'))

    const urlsNew = allUrls.map(item => {
      const urlOld = urls.find(urlOld => item.url === urlOld.url)
      return urlOld ? urlOld : {...item, progress: 0, state: 'loading'}
    })
    const urlsLoaded = urlsNew.filter(item => item.state === 'success')
    viewerDispatch(initThenUpdate({
      loading: urlsNew.length === urlsLoaded.length ? false : true,
      progress: (urlsLoaded.length / urlsNew.length) * 100,
      inputs: transpose(inputs),
      outputs: transpose(outputs),
      extras: extras.length > 0 ? transpose(extras) : [],
      urls: urlsNew,
      dates: dates,
      datesState: Array(dates.length).fill(true),
      mapsOnly: outputs.flat(Infinity).every(o => o.type === 'map'),
      mapCount: outputs.flat(Infinity).filter(o => o.type === 'map').length,
      segMaxIdx: lastSegVolumeIndex,
      imageTypeSelected: buildImageTypeList(analysis, task_index, extraReq)
    }))
    // TODO datas 잊지 말고 업데이트~~
    // dates={}
  }, [taskResults, location.state.volumeInfo])

  useEffect(() => {
    cornerstone.events.addEventListener(cornerstone.EVENTS.IMAGE_LOAD_PROGRESS, handleCornerstoneImageLoadProgress);
    return () => {
      cornerstone.events.removeEventListener(cornerstone.EVENTS.IMAGE_LOAD_PROGRESS, handleCornerstoneImageLoadProgress)
    }
  },[urls])

  const [scatterPlotsData, setScatterPlotsData] = useState(null);
  useEffect(() => {
    let alive = true
    if (cmds.states[2] && scatter?.execute) {
      const newScatterPlotsData = []
      taskResults.forEach(taskResult => {
        const allImages = [
          ...taskResult.input,
          ...taskResult.output,
          ...(taskResult?.extra || [])
        ].map(img => {
          if (img?.length) {
            return img.url.map((url, j) => {
              return [{img: {url}, typeName: img.selected[j]}]
            })
          }
          else {
            // selected_id for input & blobtype for output, blobtype_id for extraImages
            const imageType = analysisImageTypes?.find(bt => bt.id === img?.selected_id || bt.id === img?.blobtype || bt.id === img?.blobtype_id)

            // input 은 selected 사용하면 되고, extra image 는 key 가 있어서 그걸 쓴다. output 은 강제로 만들어야 할 듯
            const fromInput = !!taskResult.input.find(i => i === img)
            const name = (fromInput && img?.selected) || img?.key || `${imageType?.short}${TASK_DELIMITER}${thisTask.order}`
            return {img, typeName: name}
          }
        }).flat(Infinity)

        const promises = []
        const vars = [scatter.xVar, scatter.yVar, scatter.catVar]
        vars.forEach(myvar => {
          const found = allImages.find(img => myvar.key === img.typeName)
          if (found) {
            const niftiImageId = imageId.fromURL(`nifti:${found.img.url}#x`)
            // const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(niftiImageId.url)
            promises.push(cornerstoneNIFTIImageLoader.nifti.loadVolume(niftiImageId.url))
          }
          else {
            viewerDispatch(update({processing: false}))
            dispatch(msgError(`Unable to load image [${myvar.key}]`))
          }
        })
        Promise.all(promises).then(volumes => {
          const vols = volumes.map(v => ({
            data: v.imageDataNDarray,
            slope: v.metaData.slope,
            intercept: v.metaData.intercept,
            maxPixelValue: v.metaData.maxPixelValue,
          }))
          const myWorker = new Worker(new URL("../workers/scatter.worker.js", import.meta.url));
          // myWorker.postMessage([data1, data2, seg, payload]);
          myWorker.postMessage([vols[0], vols[1], vols[2], scatter]);
          myWorker.onmessage = function (e) {
            const {traces, filteredIndex} = e.data
            newScatterPlotsData.push({
              data: Object.values(traces),
              // filteredIndexes: filteredIndex
            })
            myWorker.terminate()
            if (alive && newScatterPlotsData.length === taskResults.length) {
              setScatterPlotsData(newScatterPlotsData) // TODO 얘도 useReducer 안으로 보내야지 않나?
              viewerDispatch(update({processing: false}))
            }
          }
        })
      })
    }
    else {
      if (alive) {
        setScatterPlotsData(null)
      }
    }
    return () => {
      alive = false
    }
  }, [cmds, scatter])

  useEffect(() => {
    // 날짜 선택에 따라 width 결정하되 아래사항도 고려해야함
    // - 그외 controllers such as overlay opacity, colormapd, etc
    // - commands (cross-sectional 에서만)
    // - right-sidebar
    // 그래서 결국.. (vw - 'sidebar size') / (number of views + 1) // 1 for controllers, views = acs + stats
    const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const sidebar = sidebarOpen ? 140 : 80
    const marginAndPad = (8+8) * 2 + 6

    // const views = acs.concat(cmds)
    // const nviews = views.reduce((prev, cur) => prev + (cur ? 1 : 0), showRightSider ? 1 : 0)
    const nviews = datesState.reduce((prev, cur) => prev + (cur ? 1 : 0), showRightSider ? 1 : 0) || 1

    viewerDispatch(update({
      allViewWidth: vw - sidebar - marginAndPad,
      viewWidth: Math.ceil((vw - sidebar - marginAndPad) / (1.1 * nviews)) - 2
    }))
  }, [urls, acs, datesState, sidebarOpen, showRightSider, windowSize])

  const resizeWindow = () => {
    viewerDispatch(update({
      windowSize: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight}
    }))
  }

  const handleCornerstoneImageLoadProgress = (event) => {
    const urlItem = urls.find(item => item.url === event.detail.url)
    if (urlItem && event.detail.percentComplete !== undefined) {
      viewerDispatch(updateProgressByURL(urlItem.url, {...urlItem, progress: event.detail.percentComplete}))
    }
  }

  const handleLoadEnd = (url, msg) => {
    urls.forEach(i => {
      if (i.url === url) {
        i.state = msg
      }
    })
    if (urls.every(i => i.state === 'success' || i.state === 'failed')) {
      viewerDispatch(update({loading: false}))
      window.scrollTo(0, 0)
    }
  }

  useLayoutEffect(() => {
    if (loading || processing) {
      window.scrollTo(0, 0)
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [loading, processing]);

  const [volumeThreshold, setVolumeThreshold] = useState(5)
  const handleOk = doLocate => {
    viewerDispatch(update({
      openTrackModal: false,
      locate: {
        ...locate,
        doLocate,
      }
    }))
  }

  const handleImageTypeSelectChange = (typeList) => {
    const newTypes = [...imageTypeSelected]
    newTypes.forEach(t => t.selected = false)
    typeList.forEach(key => {
      const foundType = newTypes.find(t => t.key === key)
      foundType.selected = true
    })

    viewerDispatch(update({imageTypeSelected: newTypes}))
  }

  const analysis = useSelector(state => state.analysis);
  const analysisTasks = analysis?.tasks
  const analysisImageTypes = analysis?.imageTypes
  const thisTask = analysisTasks[task_index]

  const handleLoadExtra = () => {
    dispatch(getUrl({
      stay: true,
      run_id,
      pid,
      sids,
      task_index,
      case_index,
      extra: imageTypeSelected.filter(t => !t.disabled && t.selected)
    }))
  }
  const viewerStyle = {
    position: "fixed",
    zIndex: 2,
    top: 10,
    background: 'rgba(0,0,0,0.1)',
    // width: (viewWidth + 2) * 3
    width: allViewWidth
  }

  const VolumeImageViewerBase = (url, imageType, tagElement, studyId, studyIdx, volumeIndex) => {
    return (
      <VolumeImageViewerLong
        key={url.split('?')[0]}
        url={url}
        urls={urls}
        acs={acs}
        cmds={cmds}
        handleLoadEnd={handleLoadEnd}
        tools={tools}
        scrollSynchronizers={scrollSynchronizers}
        newImageSynchronizer={newImageSynchronizer}
        width={viewWidth}
        colormap={imageType?.cmap_id}
        seg={imageType?.seg}
        seg_cmap={imageType?.seg_cmap}
        tag={tagElement}
        locate={locate}
        reset={reset}
        autowwwc={autowwwc}
        showSider={showRightSider}
        viewerDispatch={viewerDispatch}
        allImageLoaded={!loading}

        studyId={studyId}
        hidden={!datesState[studyIdx]}
        viewerState={state}
        volumeIndex={volumeIndex}
      />
    )
  }

  // TODO RadioGroup으로 해놓은거 Reset(Home)같은 경우는 Button식으로 해야함
  return (
    // <div style={{float:'left'}}>
    <div onContextMenu={(e) => e.preventDefault()}>
      <div id="viewer-toolbar" style={viewerStyle}>
        <ViewerToolbar
          viewerState={state}
          viewerDispatch={viewerDispatch}
          mode={MODE.MULTI}
          loadTarget={loadTarget}
          // handleDate={handleDate}
          lesionTrack={{
            visible: Boolean(trackData),
            handler: () => {
              viewerDispatch(update({
                openTrackModal: true,
                locate: {
                  ...locate,
                  doLocate: false,
                }
              }))
            }
          }}
        />
      </div>
      <br/>
      <br/>
      <LoadingOverlay
        active={loading || processing}
        spinner
        text={processing ? 'Preparing data...' : `Loading images... ${progress}%`}
        styles={{
          wrapper: {
            overflow: 'hidden'
          }
        }}
      >
        {taskResults?.[0]?.input?.length === 0 ? null :
          <div style={{lineHeight: '30px'}}>
            Input {taskResults?.[0]?.input?.length > 1 ? "images" : "image"}
          </div>
        }
        <div
            style={{display: "flex", flexDirection: "column"}}
        >
          {inputs?.map((studyInput, iidx) => {
            return <div
                key={`${iidx}`}
              style={{display: "flex", flexDirection: "row"}}
            >
              {studyInput.map((item, sidx) => {
                if (item.type === 'map') {
                  let combinations = [{
                    imageTypeId: item?.selected_id,
                    imageTypeName: item?.selected,
                    url: item.url,
                    vi: item.volumeIndex
                  }]

                  if (item.hasOwnProperty('length')) {
                    // input 자체가 배열인 경우(e.g. voxel data collection) item.url 은 배열
                    combinations = item.url.map((url, url_index) => {
                      return {
                        imageTypeId: item?.selected_id[url_index],
                        imageTypeName: item?.selected,
                        url: url,
                        vi: item.volumeIndex[url_index]
                      }
                    })
                  }
                  return (
                    <div
                      style={{display: "flex", flexDirection: "column"}}
                    >
                      {
                        combinations.map((entry, entry_index) => {
                          const imageType = analysisImageTypes?.find(bt => bt.id === entry.imageTypeId)
                          const tagElement = <TagBase
                            style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
                            color={imageType?.tag_color || '#777777'} children={entry.imageTypeName}/>
                          return VolumeImageViewerBase(entry.url, imageType, tagElement, item.study_id, sidx, entry.vi)
                        })
                      }
                    </div>
                  )
                }
                else {
                  return (
                    <div style={!datesState[sidx] ? {display: 'none'} : {border: "solid 1px"}}>
                      <H5Viewer
                        key={`input-${iidx}`}
                        url={item.url}
                        width={viewWidth}
                        parentLoading={loading}
                        handleLoadEnd={handleLoadEnd}
                        viewerDispatch={viewerDispatch}
                      />
                    </div>
                  )
                }
              })}
            </div>
          })}
        </div>
        {
          extras.length ?
            <div style={{lineHeight: '30px'}}>
              Extra loaded image
            </div>
            : null
        }
        <div
          style={{display: "flex", flexDirection: "column"}}
        >
          {extras?.map((studyExtra, eidx) => {
            return <div
              key={`${eidx}`}
              style={{display: "flex", flexDirection: "row"}}
            >
              {studyExtra.map((item, sidx) => {
                const imageType = analysisImageTypes?.find(bt => bt.id === item.blobtype_id)
                const tagElement = <TagBase
                  style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
                  color={item.tag_color} children={item.key}/>
                return VolumeImageViewerBase(item.url, imageType, tagElement, item.study_id, sidx, item.volumeIndex)
              })}
            </div>
          })}
        </div>
        <div style={{lineHeight: '30px'}}>
          Output {mapsOnly ? mapCount > 1 ? "images" : "image" : "data"}
        </div>
        <div
          style={{display: "flex", flexDirection: "column"}}
        >
          {outputs?.map((studyOutput, oidx) => {
            return <div
              key={`${oidx}`}
              style={{display: "flex", flexDirection: "row"}}
            >
              {studyOutput.map((item, sidx) => {
                if (item.type === 'map') {
                  let combinations = [{imageTypeId: item?.blobtype, url: item.url, vi: item.volumeIndex}]
                  if (item.hasOwnProperty('length')) {
                    combinations = item.url.map((url, url_index) => {
                      return {imageTypeId: item?.blobtype[url_index], url: url, vi: item.volumeIndex[url_index]}
                    })
                  }
                  return (
                    <div
                      style={{display: "flex", flexDirection: "column"}}
                    >
                      {
                        combinations.map((entry, entry_index) => {
                          const imageType = analysisImageTypes?.find(bt => bt.id === entry.imageTypeId)
                          const tagElement = <TagBase
                            style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
                            color={imageType?.tag_color || '#777777'} children={`${imageType?.short}${TASK_DELIMITER}${thisTask.order}`}/>
                          return VolumeImageViewerBase(entry.url, imageType, tagElement, item.study_id, sidx, entry.vi)
                        })
                      }
                    </div>
                  )
                }
                else if (item.type === 'report') {
                  return (
                    <div style={!datesState[sidx] ? {display: 'none'} : {width:viewWidth + 4, border: "solid 1px"}}>
                      <DicomReportViewer
                        // key={index}
                        key={`output-${sidx}`}
                        name={item.name}
                        url={item.url}
                        width={viewWidth + 2}
                        height={viewWidth * 1.2}
                        parentLoading={loading}
                        handleLoadEnd={handleLoadEnd}
                        viewerDispatch={viewerDispatch}
                        reset={reset}
                      />
                    </div>
                  )
                }
                else {
                  return (
                    <div style={!datesState[sidx] ? {display: 'none'} : {border: "solid 1px"}}>
                      <H5Viewer
                        key={`output-${sidx}`}
                        url={item.url}
                        width={viewWidth}
                        parentLoading={loading}
                        handleLoadEnd={handleLoadEnd}
                        viewerDispatch={viewerDispatch}
                      />
                    </div>
                  )
                }
              })}
            </div>
          })}
        </div>
        <div
          style={{display: "flex", flexDirection: "row"}}
        >
          {scatterPlotsData ? scatterPlotsData.map(scatterPlotData => {
            return <MemoizedScatterPlot
              data={scatterPlotData.data}
              size={viewWidth}
              hidden={!cmds.states[2]}
              showLegend={scatter.showLegend}
              hover={false}
              // onAfterPlot={handlePlotEnd}
            />
          }) : null}
        </div>
        <CommandOptionModal
          viewerState={state}
          viewerDispatch={viewerDispatch}
          taskResults={taskResults}
          imageTypeList={analysisImageTypes}
          thisTask={thisTask}
        />
        <Modal
          open={openTrackModal}
          width={1000}
          centered
          footer={[
            <Button
              key="locate"
              type="primary"
              onClick={() => handleOk(true)}
            >
              Locate
            </Button>,
            <Button key="submit" onClick={() => handleOk(false)}>
              Close
            </Button>,
          ]}
        >
          <Slider
            style={{width: 200}}
            onChange={(v) => {setVolumeThreshold(v)}}
            defaultValue={5}
          />
          <VisNetwork
            dates={taskResults?.map(r => {
              return moment(r.date).format('YYYY-MM-DD')
            })}
            targetUrl={trackData}
            volThreshold={volumeThreshold}
            viewerState={state}
            viewerDispatch={viewerDispatch}
          />
        </Modal>
        <Popover
          trigger="click"
          onOpenChange={(open) => {
            if (!open) {
              const newTypes = imageTypeSelected.map(t => {
                if (!t.disabled && t.selected && !t.loaded) {
                  t.selected = false
                }
                return t
              })
              viewerDispatch(update({
                openPopOver: false,
                imageTypeSelected: newTypes
              }))
            }
          }}
          open={openPopOver}
          placement="topRight"
          title="Select images to view"
          content={
            <div>
              <Row gutter={[8, 4]} align="middle" style={{paddingBottom: 15}}>
                <Col>
                  <TaskImageIOSelect
                    options={imageTypeSelected}
                    onChange={handleImageTypeSelectChange}
                    value={imageTypeSelected.filter(t => t.selected).map(t => t.key)}
                    placement="topRight"
                    optionGroup={true}
                    mode={TaskImageIOSelectMODE.MULTI}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 4]} align="middle">
                <Col style={{marginLeft: 'auto'}}>
                  <Button type="primary" onClick={() => {
                    handleLoadExtra()
                    viewerDispatch(update({openPopOver: false}))
                  }}>Load</Button>
                </Col>
              </Row>
            </div>
          }
          getPopupContainer={trigger => trigger}
        >
          <Tooltip
            title="Add image view"
            placement="topRight"
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            open={openPopOver ? false : undefined}
            getPopupContainer={trigger => trigger}
          >
            <Button
              type="primary"
              shape="circle"
              icon={openPopOver ? <CloseOutlined /> : <PlusOutlined />}
              size="large"
              style={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                zIndex: 10
              }}
              onClick={() => {
                viewerDispatch(update({openPopOver: !state.openPopOver}))
              }}
            />
          </Tooltip>
        </Popover>
      </LoadingOverlay>
    </div>
  );
}

export default TaskResultViewerLong;

