import React, {useState, useEffect, useRef, useMemo} from "react";
import {
  Popconfirm,
  Row,
  Col,
  Button,
  Table,
  Select,
  Form,
  Input,
  Space,
  Modal,
  Result,
  Typography,
  Switch,
  Cascader
} from 'antd';
import { QuestionCircleOutlined, MenuOutlined} from '@ant-design/icons';
import ImageTypeCheck from './imageTypeCheck'
import {useDispatch, useSelector} from "react-redux";
import { get, add, update, clear, checkName, getPreset, duplicate } from "../redux/modules/pipeline"
import { load as loadImageTypes } from "../redux/modules/blobTypes"
import {useHistory, useLocation} from "react-router-dom";
import {TaskConfig} from './taskConfigs'
import {TaskInput} from './taskInput'
import {TaskOutput} from './taskOutput'
import {useTitle} from "../hooks/title";
import TaskInputSelect from "./taskInputSelect";
import { useDuplicateCheck } from "../context/duplicateCheckContext";
import { TextAreaWithCount } from "./styledComponent";
import { getTextByte } from "../lib/getTextByte";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import './pipeline.css'
import ModelName from "./modelName";
import LoadingModal from "./loadingModal";
import { inputFormatter } from "../lib/inputFormatter"
import {useHotkeys} from "react-hotkeys-hook";

import {
  TASK_DELIMITER,
  TASK_NAME_COREG_LONGITUDINAL,
  TASK_NAME_MODEL_PREDICTION,
  TASK_NAME_TRACK_ROI_CHANGES,
  isValidTask,
  changeTaskType,
  setFormFieldsByTask,
  getNewTasksFromDB,
  getNewTasksOnImageTypeSelChanged,
  getNewTasksOnTaskInputSelChanged,
  getNewTasksOnMove,
  getNewTasksOnLongitudinalChanged,
  getNewTasksOnNew,
  getNewTasksOnChange,
  fillTaskInputCandidate,
  getAllTaskOutputNames,
  getAllUsedTaskInputNames,
  getForcedOutputNames,
  validCheckOptionalConfig,
  editTaskOutputByConfig,
  decideTaskOutputImgtype
} from "../lib/taskUtil";

const {Option} = Select;
const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
      padding : '10px'
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

export const Pipeline = () => {
  useTitle("Pipeline");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const descValue = Form.useWatch('desc', form);
  const tableRef = useRef()

  const enumMode = Object.freeze({CREATE: 0, EDIT: 1, DUPLICATE: 2})
  const [mode, setMode] = useState(enumMode.CREATE);
  const imageTypeList = useSelector((state) => state.blobTypes?.list) || [];
  const imageTypeListLoaded = useSelector((state) => state.blobTypes.loaded);
  const imageTypeListLoading = useSelector((state) => state.blobTypes.loading);
  const defaultCheckedList = ['t1ce', 'flair'];
  const [imageTypeSelected, setImageTypeSelected] = useState([]);
  const pipelineLoaded = useSelector(state => state.pipeline?.loaded);
  const pipelineLoading = useSelector(state => state.pipeline?.loading);
  const pipeline = useSelector(state => state.pipeline?.pipeline);
  const loading = useSelector(state => state.pipeline.loading)
  const modalities = useSelector(state => state.pipeline?.modalities);
  const templatesRedux = useSelector(state => state.pipeline?.task_templates) || []
  const templates = templatesRedux.sort((a, b) => (a.name > b.name) ? 1 : -1)
  const coregLongitudinalTemplate = templates.find(template => template.name === TASK_NAME_COREG_LONGITUDINAL)

  const modes = useSelector(state => state.pipeline?.modes) || []
  const models = useSelector(state => state.pipeline?.models) || []
  const tasksRedux = useSelector(state => state.pipeline?.tasks) || {}  // 데이터가 Object형식으로 들어옴
  const sidebarOpen = useSelector(state => state.config.sidebar_open);

  const checkLoading = useSelector(state => state.pipeline?.check?.loading)
  const checkedName = useSelector(state => state.pipeline?.check?.name)
  const checkedNameError = useSelector(state => state.pipeline?.check?.nameError)
  
  // 첫 load 시 longitudinalWatched가 undefined로 되어 있어 pipeline?.longitudinal을 넣어줌
  // pipeline?.longitudinal 값이 undefined이면 false로 넣어줌
  const longitudinalWatched = Form.useWatch('longitudinal', form) ?? pipeline?.longitudinal ?? false;

  // 편집중인 task list
  const [tasks, setTasks] = useState([]);

  // 편집중(?)인 task
  const [editing, setEditing] = useState(false);
  const [task, setTask] = useState({});
  const [prevTask, setPrevTask] = useState({});

  useHotkeys('esc', () => {
    if (editing) {
      handleCancelTaskEdit()
    }
  })

  const isEditingLongitudinalCoregTask = longitudinalWatched &&
    task?.task_template_id === templates.find(tmpl => tmpl.name === TASK_NAME_COREG_LONGITUDINAL)?.id

  const taskEditArgs = {
    templates,
    models,
    imagetypes: imageTypeList,
    typenames: imageTypeSelected,
    longitudinal: longitudinalWatched
  }

  // 최초 analysis db 조회
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(loadImageTypes({}));
    if (location.state?.key > 0) {
      setMode(enumMode.EDIT);
      dispatch(get({id: location.state.key}))
    }
    else {
      if (location.state?.index !== undefined) {
        dispatch(getPreset({id: -1, index: location.state.index}))
      }
      else {
        dispatch(get({id: -1}))
      }
    }
    return ()=> {
      if (history.location.pathname !== location.pathname) {
        dispatch(clear())
      }
    }
  }, [])

  useEffect(() => {
    if (!!pipeline) {
      form.setFieldsValue({
        id: pipeline.id,
        name: pipeline.name,
        desc: pipeline.desc,
        longitudinal: pipeline.longitudinal
      });
      if (pipeline.id) {
        setMode(enumMode.EDIT)
      }
      else {
        setMode(enumMode.CREATE)
      }
    }
    if (!!modalities) {
      setImageTypeSelected(modalities);
    }
    else {
      setImageTypeSelected(defaultCheckedList);
    }
  }, [pipeline, modalities])

  useEffect(() => {
    if (!pipelineLoading && pipelineLoaded && !imageTypeListLoading && imageTypeListLoaded) {
      setTasks(getNewTasksFromDB(tasksRedux, {...taskEditArgs, typenames: modalities || []}))
    }
  }, [pipelineLoading, pipelineLoaded, imageTypeListLoading, imageTypeListLoaded])

  const handleImageTypeCheck = (typenames) => {
    setImageTypeSelected(typenames);
    setTasks(getNewTasksOnImageTypeSelChanged(tasks, {...taskEditArgs, typenames}))
  }

  const handleAddTask = () => {
    setTasks(getNewTasksOnNew ({tasks, taskEditArgs}));
  };

  const handleDeleteTask = record => {
    setTasks(getNewTasksOnMove(tasks, record.index, null, taskEditArgs))
  };

  const handleCancel = () => {
    // dispatch(delete());
    history.goBack();
  };

  const finish = (values, valid, modalities, currMode=mode) => {
    const payload = {values, tasks, valid, modalities}
    if (currMode === enumMode.CREATE) {
      return dispatch(add(payload))
    }
    else if (currMode === enumMode.DUPLICATE) {
      return dispatch(duplicate(payload))
    }
    else {
      return dispatch(update(payload));
    }
  }

  const onFinish = (values, currMode=mode) => {
    validCheckOptionalConfig(tasks, templates)

    // 입력 불완전한 상태에서 오류뿜으며 Done 버튼 못누르게 하기
    const cumulatedOutputNames = getAllTaskOutputNames(tasks, taskEditArgs)

    const typenamesUsed = getAllUsedTaskInputNames(tasks)
    const typenamesRequired = imageTypeSelected.filter(imgtype => typenamesUsed.includes(imgtype))
    const typenamesForced = getForcedOutputNames(tasks, templates, imageTypeList)
    const usedImageTypes = [...new Set([
      ...cumulatedOutputNames.map(name => name.split(TASK_DELIMITER)[0]),
      ...typenamesForced // suppress 된 것들은 쓰이진 않지만 분석에 넘겨줘야 정보를 쓸 수 있다
    ])]

    const modalities = usedImageTypes.map(short => {
      const type = imageTypeList.find(imgtype=> imgtype.short === short)
      return {
        name: short,
        blobtype_id: type.id,
        selected: imageTypeSelected.includes(short),
        required: typenamesRequired.includes(short),
        forced: typenamesForced.includes(short),
        preset: type.preset
      }
    }).sort((a, b) => (a.blobtype_id > b.blobtype_id) ? 1 : -1)

    const newTasks = tasks.map(task => ({
      ...task,
      valid : isValidTask(task, templates.find(tmpl => tmpl.id === task.task_template_id), models)
    }))
    const valid = !(tasks.length === 0 || newTasks.some(task=>!task.valid))
    if (!valid && currMode !== enumMode.DUPLICATE) {
      return showInvalidModal(values, valid, modalities, newTasks)
    }

    return finish(values, valid, modalities, currMode)
  }

  const showInvalidModal = (values, valid, modalities, tasks) => {
    const invalidTasks = tasks.filter(task => !task.valid)
    return Modal.confirm({
      icon : undefined,
      content : (
        <Result
          status='warning'
          style={{padding : '0'}}
          title={(
            <Typography.Title level={3} style={{textAlign:'center'}} type="warning">
              {"Incomplete Pipeline"}
            </Typography.Title>
          )}
          subTitle={(
            <>
              {tasks.length === 0
                ? (<br/>)
                : (
                  <>
                    <Typography.Paragraph style={{textAlign:'center'}} type="warning">
                      Following tasks are not fully defined
                    </Typography.Paragraph>
                    {invalidTasks.map((task,index) => {
                      const showCount = 5
                      const templateInfo = templates.find(template => template.id === task.task_template_id)
                      if (index < showCount) {
                        return (
                          <Typography.Paragraph>
                            {`[${task.order}] ${templateInfo?.name}`}
                          </Typography.Paragraph>
                        )
                      }
                      else if (index === showCount) {
                        return (
                          <Typography.Paragraph>
                            ... {invalidTasks.length > showCount ? invalidTasks.length-showCount + ' more':null}
                          </Typography.Paragraph>
                        )
                      }
                      else {
                        return null
                      }
                    })}
                  </>
                )
              }
              <Typography.Paragraph style={{marginBottom : '10px'}} type="warning">
                Do you want to proceed anyway?
              </Typography.Paragraph>
            </>
          )}
        />
      ),
      onOk() {
        finish(values, valid, modalities)
      },
      onCancel() {
        const invalidKeys = tasks.filter(task => !task.valid).map(task => task.index)
        invalidKeys.forEach((key,index)=> {
          const invalidRow = tableRef.current.querySelector(`[data-row-key="${key}"]`)
          invalidRow?.classList.add('blink')
          setTimeout(()=>invalidRow?.classList.remove("blink"),2000)
          if (index === 0 && invalidRow) {
            invalidRow.scrollIntoView()
          }
        })
      }
    })
  }

  const onSelectTaskInputTypeChange = (task_index) => (val, option, ...t) => {
    setTasks(getNewTasksOnTaskInputSelChanged(task_index, val, tasks, taskEditArgs))
  }

  const handleChangeLongitudinal = checked => {
    const trackTemplate = templates.find(tmpl => tmpl.name === TASK_NAME_TRACK_ROI_CHANGES)
    const trackTaskIndex = tasks.findIndex(t => trackTemplate.id === t.task_template_id)
    if (!checked && trackTaskIndex >= 0) {
      Modal.confirm({
        width: 'fit-content',
        icon: undefined,
        content: (
          <Result
            status='warning'
            style={{padding: '0'}}
            title={(
              <Typography.Title level={3} style={{textAlign: 'center'}} type="warning">
                Task Deletion
              </Typography.Title>
            )}
            subTitle={(
              <>
                <Typography.Paragraph style={{textAlign: 'center', marginBottom: 0}} type="warning">
                  {`TASK ${trackTaskIndex + 1} (${TASK_NAME_TRACK_ROI_CHANGES}) is available only in longitudinal pipeline.`}
                </Typography.Paragraph>
                <Typography.Paragraph style={{textAlign: 'center'}} type="warning">
                  It will be deleted as converting to cross-sectional pipeline.
                </Typography.Paragraph>
                <Typography.Paragraph style={{marginBottom: '10px'}} type="warning">
                  Do you want to proceed anyway?
                </Typography.Paragraph>
              </>
            )}
          />
        ),
        onOk() {
          setTasks(getNewTasksOnLongitudinalChanged(checked, tasks, taskEditArgs))
        },
        onCancel() {
          form.setFieldsValue({longitudinal: true})
        }
      })
    }
    else {
      setTasks(getNewTasksOnLongitudinalChanged(checked, tasks, taskEditArgs))
    }
  }

  const columns = [
    {
      title : "",
      width : '65px',
      align : 'center',
      render : (text, record, index)=> {
        if (longitudinalWatched && index === 0) {
          return undefined
        }
        else {
          return <DragHandle />
        }
      }
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      width : '65px',
      align : 'center',
    },
    {
      title: 'Type',
      dataIndex: 'task_template_id',
      key: 'name',
      render: (template_id, record) => {
        const selectTemplate = templates.find(el => el.id === template_id);
        if (selectTemplate?.name === TASK_NAME_MODEL_PREDICTION) {
          return (
            <>
              <p style={{margin : 0}}>{selectTemplate?.name}</p>
              <ModelName name={record.config?.model}/>
            </>
          )
        }
        else {
          return selectTemplate?.name;
        }
      },
    },
    {
      title: 'Input data',
      dataIndex: 'inputs',
      width: "40%",
      key: 'input-data',
      render: (text, record, index) => {
        const selected = record.inputs?.[0].selected
        const placeholder = record.inputs?.[0] ? `Select ${record.inputs?.[0].type} data` : ""
        const inputSelect = (
          <TaskInputSelect
            style={{width : '140px'}}
            onChange={onSelectTaskInputTypeChange(index)}
            dropdownMatchSelectWidth={false}
            placeholder={placeholder}
            value={selected}
            tabIndex={editing?-1:0}
            taskInput={record.inputs?.[0]}
          />
        )
        return (
          <div className={selected ? null : 'drag-invisible'}>
            {record.inputs === null ? "N/A" : inputSelect}
          </div>)
      }
    },
    {
      title: 'Action',
      key: 'action',
      align : 'center',
      width: "100px",
      className : 'drag-invisible',
      render: (_, record, index) => (
        <Row gutter={[0, 4]} style={{width : '100px'}}>
          <Col span={24}>
            <Button block onClick={() => handleEditTask(record)} tabIndex={editing ? -1 : 0} >Edit</Button>
          </Col>
          {record.task_template_id !== coregLongitudinalTemplate.id && 
            <Col flex={24}>
              <Popconfirm
                title="Are you sure?"
                icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
                onConfirm={() => handleDeleteTask(record)}
              >
                <Button block tabIndex={editing?-1:0} >Remove</Button>
              </Popconfirm>
            </Col>
          }
        </Row>
      ),
    },
  ];

  const onFinishTaskEdit = values => {
    // task form에서 값 가져와서 세팅..
    const { task_type_id, ...remainder } = values;

    const newTask = {...task};
    newTask.task_template_id = task_type_id[task_type_id.length - 1];
    newTask.config = remainder;

    const newTasks = getNewTasksOnChange(tasks, newTask, false, taskEditArgs)
    setTasks(newTasks)
    setEditing(false);
  }

  const handleCancelTaskEdit = () => {
    setTasks(getNewTasksOnChange(tasks, {...prevTask}, false, taskEditArgs))
    setEditing(false);
  }

  const { onFieldsChange, lastMatchValidator} = useDuplicateCheck({
    loading : checkLoading,
    form : form,
    fields : [{
      fieldName : 'name',
      action : checkName,
      result : checkedName,
      error : checkedNameError
    }]
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    document.body.style.cursor = 'default';
    if (oldIndex === newIndex) { return }

    setTasks(getNewTasksOnMove(tasks, oldIndex, newIndex, taskEditArgs));
  };

  const DraggableContainer = useMemo(() => {
    return (props) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortStart={() => (document.body.style.cursor = 'grabbing')}
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  }, [tasks])

  const DraggableBodyRow = useMemo(() => {
    return ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = tasks.findIndex(task => task.index === restProps['data-row-key']);
      const isValid = tasks[index] ? isValidTask(tasks[index], templates.find(tmpl => tmpl.id === tasks[index].task_template_id), models) : undefined
      const validClassName = isValid ? null : 'invalid-task'
      const editingClassName = (editing && restProps['data-row-key'] === task.index) ? 'ant-table-row-selected' : ''
      const applyClass = [validClassName, editingClassName].join(" ")
      return <SortableItem
        collection={longitudinalWatched && index === 0 ? 1 : 0}
        disabled={longitudinalWatched && index === 0 ? true : false}
        index={index}
        className={applyClass}
        {...restProps}
      />;
    };
  }, [tasks, editing])

  const onTaskFieldsChange = (changedFields, allFields) => {
    // console.log('onTaskFieldChange: changedFields', changedFields, 'allFields', allFields)
    const changedFieldNames = changedFields.map(f => f.name.length > 1 ? f.name.join('>') : f.name[0])

    // STEP 1 Task Type (template) 가 바뀐 경우
    let fidx = changedFieldNames.findIndex(name => name.includes('task_type_id'))
    if (fidx >= 0) {
      return onCascaderTaskTypeChange(changedFields[fidx].value)
    }
    // STEP 2 model 선택이 바뀐 경우
    fidx = changedFieldNames.findIndex(name => name === 'model')
    if (fidx >= 0) {
      return onSelectModelChange('model', changedFields[fidx].value)
    }
    // STEP 3 그 외 나머지 ㄷㄷ
    const fieldValues = form2.getFieldsValue(true)
    const {task_type_id, ...remainder} = fieldValues;
    task.config = remainder;
    const [res, newTask] = editTaskOutputByConfig(changedFields, task, taskEditArgs)
    if (res) {
      // console.log('onChange TaskFields: before ', task.outputs?.[0], ' after ', newTask.outputs?.[0])
      setTask({...newTask, outputs: [...newTask.outputs]})
    }
  }

  const handleEditTask = (task) => {
    setEditing(true);

    const oldTask = JSON.parse(JSON.stringify(task))
    setPrevTask(oldTask);

    setFormFieldsByTask(form2, task, taskEditArgs)
    const template = templates.find(el => el.id === task.task_template_id)
    decideTaskOutputImgtype(task, template, imageTypeList, models, longitudinalWatched)
    setTask(task);
  }

  function onCascaderTaskTypeChange(val, option) {
    // console.log(val, option);
    const newTask = changeTaskType(task, val[val.length-1], templates);
    fillTaskInputCandidate(newTask, {...taskEditArgs, tasks})
    setFormFieldsByTask(form2, newTask, taskEditArgs)
    const template = templates.find(el => el.id === newTask.task_template_id)
    decideTaskOutputImgtype(newTask, template, imageTypeList, models, longitudinalWatched)
    setTask(newTask);
  }

  const onChangeTaskInput = (newTask) => {
    const template = templates.find(el => el.id === newTask.task_template_id)
    decideTaskOutputImgtype(newTask, template, imageTypeList, models, longitudinalWatched)
    // console.log('onChange TaskInput: before ', task.outputs?.[0], ' after ', newTask.outputs?.[0])
    setTask(newTask);
  }
  const onChangeTaskOutput = (newTask) => {
    const template = templates.find(el => el.id === newTask.task_template_id)
    decideTaskOutputImgtype(newTask, template, imageTypeList, models, longitudinalWatched)
    // console.log('onChange TaskOutput before ', task.outputs?.[0], ' after ', newTask.outputs?.[0])
    setTask(newTask);
  }

  const onSelectModelChange = (name, val, option) => {
    const fieldValues = form2.getFieldsValue(true)
    Object.keys(fieldValues).forEach(key => {
      if (key === 'model' || key === 'task_type_id') {
        form2.setFieldsValue({[key]: fieldValues[key]})
      }
      else {
        form2.setFieldsValue({[key]: undefined})
      }
    })

    const newTask = {...task}
    newTask.config = {...task.config, [name]: val} // "model": ['model-name', 'version'] 형태로 저장
    if (val) {
      const model = models.find(m => m.name === val[0] && m.version === val[1])
      newTask.inputs = JSON.parse(JSON.stringify(model.input))
      newTask.outputs = JSON.parse(JSON.stringify(model.output))
    }
    else {
      newTask.inputs = null
      newTask.outputs = null
    }
    fillTaskInputCandidate(newTask, {...taskEditArgs, tasks})
    setTask(newTask)
  }

  const onDuplicate = () => {
    const pipelineInfo = {...form.getFieldsValue(true)}
    delete pipelineInfo.id
    onFinish(form.getFieldsValue(true), enumMode.DUPLICATE)
  }

  const buildCascaderOptions = () => {
    const templatesFilter = templates.filter(x => isEditingLongitudinalCoregTask ? true : !x.hidden)
    const tasksDynapex = templatesFilter.filter(t => t.manufacturer == null)
    const tasksThirdParty = templatesFilter.filter(t => t.manufacturer != null)

    const manufacturers = new Set(tasksThirdParty.map(t => t.manufacturer))
    let options = tasksDynapex.map(t => ({label: t.name, value: t.id}))
    if (!longitudinalWatched) {
      options = options.filter(o => o.label !== TASK_NAME_TRACK_ROI_CHANGES)
    }
    // 이제 여기에 3rd party 이름을 먼저 추가하고 각각 그안에 task를 달아준다
    const thirdPartyOptions = [...manufacturers].map(m => {
      return {
        label: m,
        value: m,
        children: tasksThirdParty.filter(t => t.manufacturer === m).sort((a, b) => a.id - b.id).map(t => ({label: t.name, value: t.id}))
      }
    })
    return options.concat(thirdPartyOptions)
  }

  // const buildCascaderValue = () => {
  //   const curTaskTemplate = templates.find(t => t.id === task.task_template_id)
  //   return curTaskTemplate.manufacturer ? [curTaskTemplate.manufacturer, task.task_template_id] : [task.task_template_id]
  // }

  // console.count('Pipeline rendered!')
  // console.log(task.outputs?.[0])
  return (
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        // minWidth: '50%',
        width: '100%',
      }}
    >
      <LoadingModal
        loading={loading}
      />
      <Form
        form={form}
        name="pipeline"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        // https://stackoverflow.com/questions/51549115/best-way-to-disabled-div-onclick-in-react
        style={{width: sidebarOpen ? '48%':'49%', padding: 10, pointerEvents: editing?"none":"auto", opacity: editing?0.5:1.0}}
        labelCol={{flex: '115px'}}
        labelWrap
        colon={false}
      >
        <Row gutter={[8, 4]} align="middle">
          <Col span={12}>
            <h1>Pipeline</h1>
          </Col>
          <Col span={12} flex='1'>
            <div>
              <Form.Item>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right" }}
                >
                  {
                    pipeline?.id
                    ? <Button onClick={onDuplicate} tabIndex={editing?-1:0} children={'Duplicate'}/>
                    : null
                  }
                  <Button onClick={handleCancel} tabIndex={editing?-1:0}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" tabIndex={editing?-1:0}>
                    OK
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <br />
        <Form.Item name="id" style={{display: 'none'}}>
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              whitespace : true,
              type : 'string',
              message: 'Please input your pipeline name!',
            },
            {
              max: 256,
              message : 'Cannot exceed 256 letters'
            },
            {
              pattern : new RegExp(/^(?!.*[\\]).*$/gu),
              message : `The following special characters cannot be used. \\`
            },
            lastMatchValidator('name')
          ]}
          hasFeedback
        >
          <Input
            placeholder="My Pipeline"
            tabIndex={editing?-1:0}
            maxLength={256}
            showCount={inputFormatter}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="desc"
          rules={[
            {
              message: 'Please describe your pipeline!',
            },
            () => ({
              validator(_, value) {
                if (!value || getTextByte(value) <= 65535) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Cannot exceed 65535 letters'));
              },
            }),
          ]}
        >
          <TextAreaWithCount 
            placeholder="Pipeline description" 
            tabIndex={editing?-1:0}
            rows={4}
            maxLength={65535}
            showCount={{
              formatter: ({ count, maxLength})=> `${getTextByte(descValue || "")}/${maxLength}`
            }}
          />
        </Form.Item>
        <Form.Item
          label="Longitudinal"
          name="longitudinal"
          valuePropName="checked"
        >
          <Switch onChange={handleChangeLongitudinal}/>
        </Form.Item>
        <Form.Item
          label='Input image type'
          name="Image type selection"
          rules={[
            {
              message: 'Please select image types that will be used in this pipeline!',
            },
          ]}
        >
          <ImageTypeCheck
            options={modes === undefined ? [] : modes }
            savedList={imageTypeSelected}
            handleCheckChange={handleImageTypeCheck}
            editing={editing}
          />
        </Form.Item>
        <br />
        <Row gutter={[8, 4]} align="middle">
          <Col span={12}>
              <h3>Task list</h3>
            </Col>
            <Col span={12} flex='1'>
              <div>
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "right" }}
                >
                  <Button type="primary" onClick={handleAddTask} tabIndex={editing?-1:0}>New task</Button>
                </Space>
              </div>
          </Col>
        </Row>
        <Table
          ref={tableRef}
          loading={loading || pipelineLoading}
          columns={columns}
          dataSource={tasks}
          pagination={false}
          size="small"
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Form>

      {editing ?
      <Form
        form={form2}
        // requiredMark='optional'
        name="task"
        onFinish={onFinishTaskEdit}
        onFieldsChange={onTaskFieldsChange}
        style={{width: sidebarOpen ? '47%':'48%', padding: 10, position: 'fixed', right: 10,
                height : '100vh', overflowY : 'auto', backgroundColor : '#18343B', fontSize: 0,
        }}
        layout="horizontal"
        colon={false}
        labelWrap={true}
        labelAlign="left"
        labelCol={{span: 6}}
        wrapperCol={{span: 12}}
      >
        <Form.Item
          label="Task type"
          name="task_type_id"
          style={{marginBottom: 8}}
        >
          <Cascader
            style={{ width: '100%' }}
            popupClassName="ModelCascader"
            disabled={isEditingLongitudinalCoregTask}
            // onChange={onCascaderTaskTypeChange}
            dropdownMatchSelectWidth={false}
            getPopupContainer={trigger => trigger.parentNode}
            showSearch={{
              filter: (input, option) => option.map(o => o.label).join(' ').toLowerCase().includes(input.toLowerCase())
            }}
            options={buildCascaderOptions()}
            allowClear={false}
          />
        </Form.Item>
        <Space direction="vertical" style={{ width: "100%", justifyContent: "right" }}>
          <TaskInput
            inputs={task.inputs}
            outputs={task.outputs}
            models={models}
            model={task.config?.model}
            template={templates.find(el => el.id === task.task_template_id)}
            task={task}
            onChange={onChangeTaskInput}
          />
          <TaskConfig
            template_config={templates.find(el => el.id === task.task_template_id)?.config}
            task={task}
            longitudinal={longitudinalWatched}
            setFieldsValue={form2.setFieldsValue}
            // onSelectModelChange={onSelectModelChange}
            models={models}
            imageTypeSelected={imageTypeList.filter(t => imageTypeSelected.includes(t.short))}
          />
          <TaskOutput
            outputs={task.outputs}
            models={models}
            model={task.config?.model}
            template={templates.find(el => el.id === task.task_template_id)}
            task={task}
            onChange={onChangeTaskOutput}
          />
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                OK
              </Button>
              <Button onClick={handleCancelTaskEdit}>
                Cancel
              </Button>
            </Space>
          </Form.Item>
        </Space>
      </Form>
        : null}
    </div>
  );
}

export default Pipeline;
