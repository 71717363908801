export const GET_URL = "view/GET_URL";
export const GET_URL_SUCCESS = "view/GET_URL_SUCCESS";
export const GET_URL_FAIL = "view/GET_URL_FAIL";
export const CLEAR_URL_CACHE = "view/CLEAR_URL_CACHE";
export const CLEAR_URL_CACHE_SUCCESS = "view/CLEAR_URL_CACHE_SUCCESS";
export const CLEAR_URL_CACHE_FAIL = "view/CLEAR_URL_CACHE_FAIL";
export const GET_SERIES_URL = "view/GET_SERIES_URL";
export const GET_SERIES_URL_SUCCESS = "view/GET_SERIES_URL_SUCCESS";
export const GET_SERIES_URL_FAIL = "view/GET_SERIES_URL_FAIL";
export const GET_NIFTI_URL = "view/GET_NIFTI_URL";
export const GET_NIFTI_URL_SUCCESS = "view/GET_NIFTI_URL_SUCCESS";
export const GET_NIFTI_URL_FAIL = "view/GET_NIFTI_URL_FAIL";
export const CLEAR_URL = 'view/CLEAR_URL'

const initialState = {
  loaded: false,
  target: null,
  list: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_URL:
      return {
        ...state,
        target: action.payload,
        loading: true
      };
    case GET_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload.list,
        track: action.payload.track,
        extra: action.payload.extra
      };
    case GET_URL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: [],
        error: action.error,
      };
    case CLEAR_URL_CACHE:
    case CLEAR_URL_CACHE_SUCCESS:
    case CLEAR_URL_CACHE_FAIL:
      return state
    case GET_SERIES_URL:
      return {
        ...state,
        loading: true,
      };
    case GET_SERIES_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload,
      };
    case GET_SERIES_URL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: [],
        error: action.error,
      };
    case GET_NIFTI_URL:
      return {
        ...state,
        loading: true,
      };
    case GET_NIFTI_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: action.payload,
      };
    case GET_NIFTI_URL_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        list: [],
        error: action.error,
      };
    case CLEAR_URL:
      return {
        ...state,
        list: []
      }
    default:
      return state;
  }
}

export function getUrl(payload) {
  return { type: GET_URL, payload };
}
export function getUrlSuccess(payload) {
  return { type: GET_URL_SUCCESS, payload };
}
export function getUrlFail(payload) {
  return { type: GET_URL_FAIL, payload };
}
export function getSeriesUrl(payload) {
  return { type: GET_SERIES_URL, payload };
}
export function getSeriesUrlSuccess(payload) {
  return { type: GET_SERIES_URL_SUCCESS, payload };
}
export function getSeriesUrlFail(payload) {
  return { type: GET_SERIES_URL_FAIL, payload };
}
export function getNiftiUrl(payload) {
  return { type: GET_NIFTI_URL, payload };
}
export function getNiftiUrlSuccess(payload) {
  return { type: GET_NIFTI_URL_SUCCESS, payload };
}
export function getNiftiUrlFail(payload) {
  return { type: GET_NIFTI_URL_FAIL, payload };
}
export function clearUrl(){
  return { type : CLEAR_URL}
}
export function clearUrlCache(payload) {
  return { type: CLEAR_URL_CACHE, payload };
}
export function clearUrlCacheSuccess(payload) {
  return { type: CLEAR_URL_CACHE_SUCCESS, payload };
}
export function clearUrlCacheFail(payload) {
  return { type: CLEAR_URL_CACHE_FAIL, payload };
}
