import React, {useReducer, useEffect, useState, useRef, useLayoutEffect, useContext} from "react";
import {Form, InputNumber, Select, Tooltip, Row, Col, Space, Popover, Button, Divider, Modal} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import VolumeImageViewer from "./volumeImageViewer";
import _ from 'lodash'
import {VolumeImageOverlayContextProvider} from "../context/volumeImageOverlayContext";
import SegmentationContext, {SegmentationContextProvider} from "../context/segmentationContext";
import LoadingOverlay from 'react-loading-overlay';
import ViewerToolbar, {MODE} from "./viewerToolbar";
import H5Viewer from "./h5Viewer";
import {useTitle} from "../hooks/title";
import axios from "axios";
import {PlusOutlined, CloseOutlined} from "@ant-design/icons";
import TaskImageIOSelect, {MODE as TaskImageIOSelectMODE } from "./taskImageIOSelect";
import {CLEAR_URL, getUrl} from "../redux/modules/view";
import ImageFilterExpression from "./imageFilterExpression";
import CommandOptionModal from "./commandOptionModal";
import {MultiSegContextProvider} from "../context/multiSegmentationContext";
import {useHotkeys} from "react-hotkeys-hook";
import { TagBase } from "./tagBase";
import {IJKContextProvider} from "../context/ijkContext";
import {MemoizedScatterPlot} from "./Plots";
import {msgError} from "../redux/modules/message";
import {buildImageTypeList} from "../lib/taskUtil";
import {TASK_DELIMITER} from "../lib/taskUtil";
import CornerstoneElementPlain from "./cornerstoneElementPlain";
import DicomReportViewer from "./dicomReportViewer";

const segmentationModule = cornerstoneTools.getModule('segmentation');
const imageId = cornerstoneNIFTIImageLoader.nifti.ImageId;

LoadingOverlay.propTypes = undefined;

const initialState = {
  loading: false,
  progress: 0,
  processing: false,
  acs: [true, true, true],
  // longitudinal? [true, false, false] : [true, true, true]
  // cmds: [false, false, false],
  cmds: {
    shows: [true, true, true],
    states: [false, false, false]
  },

  inputs: [],
  outputs: [],
  urls: [],

  mapsOnly: true,
  mapCount: 0,

  windowSize: {},
  viewWidth: 0,
  allViewWidth: 0,
  showRightSider: true,

  openPopOver: false,
  openModal: false,
  modalType: '',

  imageTypeSelected: [],

  reset: {},
  autowwwc: false,
  locate: {},
  navData: {},
  tools: {
    wwwc: false,
    dragprobe: false,
    crosshair: true,
    referencelines: true,
    clear: function() {
      this.wwwc = false
      this.dragprobe = false
      this.crosshair = false
      this.referencelines = false
    }
  },

  stats: {
    execute: false,
    nonzero: true,
    filter: []
  },
  histogram: {
    execute: false,
    binCount: 0,
    filter: []
  },
  scatter: {
    execute: false,
    opacity: 0,
    filter: []
  },
  segConfig: {
    visible: true,
    fillAlpha: segmentationModule.configuration.fillAlpha,
    outlineAlpha: segmentationModule.configuration.outlineAlpha,
  }
}

const initState = (args) => {
  return {
    ...JSON.parse(JSON.stringify(initialState)),
    // stringify 에서 함수는 날아가기때문에 ㅠ
    tools: {
      wwwc: false,
      dragprobe: false,
      crosshair: true,
      referencelines: true,
      clear: function() {
        this.wwwc = false
        this.dragprobe = false
        this.crosshair = false
        this.referencelines = false
      }
    },
    ...args
  }
}

const ACTION_UPDATE = 'UPDATE'
export const update = (payload) => {
  return { type : ACTION_UPDATE, payload }
}

const ACTION_UPDATE_PROGRESS_BY_URL = 'UPDATE_PROGRESS_BY_URL'
export const updateProgressByURL = (url, payload) => {
  return { type : ACTION_UPDATE_PROGRESS_BY_URL, url, payload }
}

const ACTION_PREPARE_PROCESSING = 'PREPARE_PROCESSING'
export const prepareProcessingPerUrl = (payload) => {
  return { type : ACTION_PREPARE_PROCESSING, payload }
}

const ACTION_UPDATE_PROCESSING_BY_URL = 'UPDATE_PROCESSING_BY_URL'
export const updateProcessingByURL = (url, payload) => {
  return { type : ACTION_UPDATE_PROCESSING_BY_URL, url, payload }
}

const ACTION_INIT_THEN_UPDATE = 'INIT_THEN_UPDATE'
export const initThenUpdate = (payload) => {
  return { type : ACTION_INIT_THEN_UPDATE, payload }
}

const reducer = (state, action) => {
  // console.count(action.type)
  // console.log(action.payload)
  switch(action.type) {
    case ACTION_PREPARE_PROCESSING:
      const newState = {
        ...state,
        processing: true
      }
      newState.urls.forEach(item => {
        // hdf5 데이터에서도 histogram 만들려고 해서 ㅠ
        if (action.payload?.mapsOnly) {
          item.type === 'map' ? item.processing = true : item.processing = false
        }
        else {
          item.processing = true
        }
      })
      return newState
    case ACTION_UPDATE_PROCESSING_BY_URL: {
      const idx = state.urls.findIndex(item => item.url === action.url)
      const url = state.urls[idx]
      state.urls[idx] = {...url, ...action.payload}
      if (state.urls.every(i => !i.processing)) {
        // 모든 url 이 processing false 일 때 전체 processing 도 false
        return {
          ...state,
          processing: false,
          // updateProcessingByURL 쓰는 애들 (autowwwc, stats, histogram) 전부 초기화
          autowwwc: false,
          stats: {
            ...state.stats,
            execute: false
          },
          histogram: {
            ...state.histogram,
            execute: false
          },
        }
      }
      else {
        return state
      }
    }
    case ACTION_UPDATE_PROGRESS_BY_URL: {
      state.urls = [...state.urls].map(item => {
        if (item.url === action.url) {
          return {...item, ...action.payload}
        }
        else {
          return item
        }
      })

      const progressSum = state.urls.reduce((prev, cur) => prev + cur.progress, 0)
      const newProgress = Math.floor(progressSum/state.urls.length)
      const loadEnd = state.urls.every(i => i.state === 'success' || i.state === 'failed')
      if (newProgress > state.progress) {
        return {
          ...state,
          progress: newProgress,
          loading: !loadEnd
        }
      }
      if (loadEnd) {
        return {
          ...state,
          loading: false
        }
      }

      // for update without re-rendering
      return state
    }
    case ACTION_UPDATE:
      return {
        ...state,
        ...action.payload
      };
    case 'REPLACE_ONLY': {
      const newState = {...state}
      for(const k of Object.keys(action.payload)) {
        newState[k] = action.payload[k]
      }
      return newState
    }
    case ACTION_INIT_THEN_UPDATE:
      return initState(action.payload)
    default:
      return state;
  }
}

function TaskResultViewer() {
  return (
    <VolumeImageOverlayContextProvider>
      <SegmentationContextProvider>
        <TaskResultViewerInner/>
      </SegmentationContextProvider>
    </VolumeImageOverlayContextProvider>
  )
}

function TaskResultViewerInner() {
  useTitle('DYNAPEX | Result View')
  const [state, viewerDispatch] = useReducer(reducer, {}, initState);

  const {
    loading,
    progress,
    processing,
    acs,
    cmds,
    inputs,
    outputs,
    extras,
    urls,
    mapsOnly,
    mapCount,
    windowSize,
    viewWidth,
    allViewWidth,
    showRightSider,
    openPopOver,
    // openModal,
    // modalType,
    imageTypeSelected,
    reset,
    autowwwc,
    locate,
    navData,
    tools,
    stats,
    histogram,
    scatter,
    segConfig,
  } = state

  const history = useHistory();
  const dispatch = useDispatch();
  const loadTarget = useSelector(state => state.view.target, _.isEqual);
  const taskResult = useSelector(state => state.view.list[0], _.isEqual);
  const navDataUrl = taskResult?.nav
  const sidebarOpen = useSelector(state => state.config.sidebar_open);
  // const imageTypeList = useSelector(state => state.analysis.imageTypes);


  const {setAction:setActionSeg} = useContext(SegmentationContext);
  useHotkeys('shift+1', () => {
    segmentationModule.configuration.renderOutline = !segmentationModule.configuration.renderOutline
    setActionSeg({type: 'changeOption'})
  }, {enableOnFormTags: ["INPUT"]})
  useHotkeys('s', () => {
    // TODO how to throttle
    const newSegConfig = {
      ...segConfig,
      visible: !segConfig.visible
    }
    if (segConfig.visible) {
      newSegConfig.fillAlpha = segmentationModule.configuration.fillAlpha
      newSegConfig.outlineAlpha = segmentationModule.configuration.outlineAlpha
      segmentationModule.configuration.fillAlpha = 0
      segmentationModule.configuration.outlineAlpha = 0
    }
    else {
      if (newSegConfig.fillAlpha === 0 && newSegConfig.outlineAlpha === 0) {
        newSegConfig.fillAlpha = 0.2
        newSegConfig.outlineAlpha = 0.7
      }
      segmentationModule.configuration.fillAlpha = newSegConfig.fillAlpha
      segmentationModule.configuration.outlineAlpha = newSegConfig.outlineAlpha
    }
    viewerDispatch(update({segConfig: newSegConfig}))
    setActionSeg({type: 'changeOption'})
  }, {enableOnFormTags: ["INPUT"]})

  // x, y, z direction 별로 3개 mouse scroll synchronizer 추가
  const scrollSynchronizers = useRef([
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
    new cornerstoneTools.Synchronizer(
      cornerstoneTools.EVENTS.STACK_SCROLL, cornerstoneTools.stackImagePositionSynchronizer),
  ]);
  const newImageSynchronizer = useRef(
    new cornerstoneTools.Synchronizer(
    cornerstone.EVENTS.NEW_IMAGE, cornerstoneTools.updateImageSynchronizer)
  );
  useEffect(() => {
    const removeHistoryListener = history.listen((location, action) => {
      window.scrollTo(0, 0)
    })

    resizeWindow()
    window.addEventListener("resize", resizeWindow);

    // 초기값 from cornerstoneTools - defaultConfiguration.js
    segmentationModule.configuration.fillAlpha = 0.2
    segmentationModule.configuration.outlineAlpha = 0.7

    return () => {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
      window.removeEventListener("resize", resizeWindow)
      removeHistoryListener()
    }
  }, []);

  const location = useLocation();
  const {run_id, pid, sids, task_index, case_index} = location.state.volumeInfo
  useEffect(() => {
    if (history.action === 'POP') {
      if ([run_id, pid, sids, task_index, case_index].every(value => value !== undefined)) {
        dispatch(getUrl({...location.state.volumeInfo, action: 'replace'}))
      }
    }
  },[location.state.volumeInfo])

  useEffect(() => {
    if (!taskResult) { return }

    let lastSegVolumeIndex = -1
    let volumeIndex = 0;
    const processItems = (items, btIdName, mapsOnly) => {
      const res = mapsOnly ? items.filter(i => i.type === 'map') : items
      return items.map(i => {
        const ids = i.hasOwnProperty('length') ? i[btIdName] : [i[btIdName]];
        if (!i.hasOwnProperty('volumeIndex')) {
          // extra load 시에 index 보존을 위해 없을때만 추가
          i.volumeIndex = i.hasOwnProperty('length') ? i.url.map(() => volumeIndex++) : volumeIndex++;
        }
        ids.forEach((id, iidx) => {
          if (analysisImageTypes?.find(bt => bt.id === id)?.seg) {
            lastSegVolumeIndex = i.hasOwnProperty('length') ? i.volumeIndex[iidx] : i.volumeIndex
          }
        })
      })
    }

    const outputs = taskResult.output.filter(o => !o?.suppress)
    processItems(taskResult.input, 'selected_id', true)
    processItems(outputs, 'blobtype', true)
    const extraImages = taskResult?.extra
    if (extraImages) {
      processItems(extraImages, 'blobtype_id', false)
    }

    const inputUrls = taskResult.input.map(i => [i.url].flat().map((j, jidx) => ({url: j, type: i.type, btype_id: i.selected_id[jidx]}))).flat()
    const outputUrls = outputs.map(i => [i.url].flat().map((j, jidx) => ({url: j, type: i.type, btype_id: i.blobtype?.[jidx]}))).flat()
    const extraUrls = (extraImages?.map(item => ({url: item.url, type: 'map', blobtype_id: item.blobtype_id})).flat() || [])
    const allUrls = [...inputUrls, ...outputUrls, ...extraUrls]
    const urlsNew = allUrls.map(item => {
      const urlOld = urls.find(urlOld => item.url === urlOld.url)
      return urlOld ? urlOld : {...item, progress: 0, state: 'loading'}
    })
    const urlsLoaded = urlsNew.filter(item => item.state === 'success')
    viewerDispatch(initThenUpdate({
      loading: urlsNew.length === urlsLoaded.length ? false : true,
      progress: (urlsLoaded.length / urlsNew.length) * 100,
      inputs: taskResult.input,
      outputs: outputs,
      extras: extraImages,
      urls: urlsNew,
      mapsOnly: outputs.every(o => o.type === 'map'),
      mapCount: outputs.filter(o => o.type === 'map').length,
      segMaxIdx: lastSegVolumeIndex ,
      imageTypeSelected: buildImageTypeList(analysis, task_index, extraImages)
    }))
  }, [taskResult, location.state.volumeInfo])

  useEffect(() => {
    cornerstone.events.addEventListener(cornerstone.EVENTS.IMAGE_LOAD_PROGRESS, handleCornerstoneImageLoadProgress);
    return () => {
      cornerstone.events.removeEventListener(cornerstone.EVENTS.IMAGE_LOAD_PROGRESS, handleCornerstoneImageLoadProgress)
    }
  },[urls])

  const [scatterPlotData, setScatterPlotData] = useState(null);
  useEffect(() => {
    let alive = true
    if (cmds.states[2] && scatter?.execute) {
      const allImages = [
        ...taskResult.input,
        ...taskResult.output,
        ...(taskResult?.extra || [])
      ].map(img => {
        if (img?.length) {
          return img.url.map((url, j) => {
            return [{img: {url}, typeName: img.selected[j]}]
          })
        }
        else {
          // selected_id for input & blobtype for output, blobtype_id for extraImages
          const imageType = analysisImageTypes?.find(bt => bt.id === img?.selected_id || bt.id === img?.blobtype || bt.id === img?.blobtype_id)

          // input 은 selected 사용하면 되고, extra image 는 key 가 있어서 그걸 쓴다. output 은 강제로 만들어야 할 듯
          const fromInput = !!taskResult.input.find(i => i === img)
          const name = (fromInput && img?.selected) || img?.key || `${imageType?.short}${TASK_DELIMITER}${thisTask.order}`
          return {img, typeName: name}
        }
      }).flat(Infinity)

      const promises = []
      const vars = [scatter.xVar, scatter.yVar, scatter.catVar]
      vars.forEach(myvar => {
        const found = allImages.find(img => myvar.key === img.typeName)
        if (found) {
          const niftiImageId = imageId.fromURL(`nifti:${found.img.url}#x`)
          // const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(niftiImageId.url)
          promises.push(cornerstoneNIFTIImageLoader.nifti.loadVolume(niftiImageId.url))
        }
        else {
          viewerDispatch(update({processing: false}))
          dispatch(msgError(`Unable to load image [${myvar.key}]`))
        }
      })
      Promise.all(promises).then(volumes => {
        const vols = volumes.map(v => ({
          data: v.imageDataNDarray,
          slope: v.metaData.slope,
          intercept: v.metaData.intercept,
          maxPixelValue: v.metaData.maxPixelValue,
        }))
        const myWorker = new Worker(new URL("../workers/scatter.worker.js", import.meta.url));
        // myWorker.postMessage([data1, data2, seg, payload]);
        myWorker.postMessage([vols[0], vols[1], vols[2], scatter]);
        myWorker.onmessage = function (e) {
          const {traces, filteredIndex} = e.data
          if (alive) {
            setScatterPlotData({
              data: Object.values(traces),
              // filteredIndexes: filteredIndex
            })
            viewerDispatch(update({processing: false}))
          }
          myWorker.terminate()
        }
      })
    }
    else {
      if (alive) {
        setScatterPlotData(null)
      }
    }
    return () => {
      alive = false
    }
  }, [cmds, scatter])

  useEffect(() => {
    // ACS 선택에 따라 width 결정하되 아래사항도 고려해야함
    // - 그외 controllers such as overlay opacity, colormapd, etc
    // - commands (cross-sectional 에서만)
    // - right-sidebar
    // 그래서 결국.. (vw - 'sidebar size') / (number of views + 1) // 1 for controllers, views = acs + stats
    const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const sidebar = sidebarOpen ? 140 : 80
    const marginAndPad = (8+8) * 2

    const views = acs.concat(cmds.states.slice(1, 2))
    const nviews = views.reduce((prev, cur) => prev + (cur ? 1 : 0), showRightSider ? 1 : 0)

    viewerDispatch(update({
      allViewWidth: vw - sidebar - marginAndPad,
      viewWidth: Math.ceil((vw - sidebar - marginAndPad) / nviews) - 2
    }))
  }, [urls, acs, cmds, sidebarOpen, showRightSider, windowSize])

  const resizeWindow = () => {
    viewerDispatch(update({
      windowSize: {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight}
    }))
  }

  const handleCornerstoneImageLoadProgress = (event) => {
    const urlItem = urls.find(item => item.url === event.detail.url)
    if (urlItem && event.detail.percentComplete !== undefined) {
      viewerDispatch(updateProgressByURL(urlItem.url, {...urlItem, progress: event.detail.percentComplete}))
    }
  }

  const handleLoadEnd = (url, msg) => {
    urls.forEach(i => {
      if (i.url === url) {
        i.state = msg
      }
    })
    if (urls.every(i => i.state === 'success' || i.state === 'failed')) {
      viewerDispatch(update({loading: false}))
      window.scrollTo(0, 0)
    }
  }

  useLayoutEffect(() => {
    if (loading || processing) {
      window.scrollTo(0, 0)
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [loading, processing]);

  const handleImageTypeSelectChange = (typeList) => {
    const newTypes = [...imageTypeSelected]
    newTypes.forEach(t => t.selected = false)
    typeList.forEach(key => {
      const foundType = newTypes.find(t => t.key === key)
      foundType.selected = true
    })

    viewerDispatch(update({imageTypeSelected: newTypes}))
  }

  const analysis = useSelector(state => state.analysis);
  const analysisTasks = analysis?.tasks
  const analysisImageTypes = analysis?.imageTypes
  const thisTask = analysisTasks[task_index]

  const handleLoadExtra = () => {
    dispatch(getUrl({
      stay: true,
      run_id,
      pid,
      sids,
      task_index,
      case_index,
      extra: imageTypeSelected.filter(t => !t.disabled && t.selected)
    }))
  }

  useEffect(() => {
    if (navDataUrl) {
      axios.get(navDataUrl).then(res => {
        viewerDispatch(update({
          navData: {
            ...state.navData,
            coordinates: res.data
          }
        }))
      })
    }
  }, [navDataUrl])

  const viewerStyle = {
    position: "fixed",
    zIndex: 2,
    top: 10,
    background: 'rgba(0,0,0,0.1)',
    // width: (viewWidth + 2) * 3
    width: allViewWidth
  }

  const VolumeImageViewerBase = (url, imageType, tagElement, volumeIndex) => {
    return (
      <VolumeImageViewer
        key={url.split('?')[0] + volumeIndex}
        url={url}
        urls={urls}
        acs={acs}
        cmds={cmds}
        handleLoadEnd={handleLoadEnd}
        tools={tools}
        scrollSynchronizers={scrollSynchronizers}
        newImageSynchronizer={newImageSynchronizer}
        width={viewWidth}
        colormap={imageType?.cmap_id}
        seg={imageType?.seg}
        seg_cmap={imageType?.seg_cmap}
        tag={tagElement}
        locate={locate}
        reset={reset}
        autowwwc={autowwwc}
        showSider={showRightSider}
        viewerDispatch={viewerDispatch}
        allImageLoaded={!loading}

        stats={stats}
        histogram={histogram}
        scatter={scatter}
        viewerState={state}
        volumeIndex={volumeIndex}
      />
    )
  }

  const acsNum = acs.reduce((acc, curr) => acc + (curr? 1 : 0), 0)

  return (
    // <div style={{float:'left'}}>
    <div onContextMenu={(e) => e.preventDefault()}>
      <div id="viewer-toolbar" style={viewerStyle}>
        <ViewerToolbar
          viewerState={state}
          viewerDispatch={viewerDispatch}
          mode={MODE.SINGLE}
          loadTarget={loadTarget}
          lesionNav={{
            visible: Boolean(navDataUrl),
            coordinates: navData?.coordinates,
            handler: (coord) => {
              viewerDispatch(update({
                locate: {
                  ...locate,
                  doLocate: true,
                  coordinates: coord
                }
              }))
            }
          }}
          rightSider={{
            visible: showRightSider,
            handler: () => {
              viewerDispatch(update({showRightSider: !state.showRightSider}))
            }
          }}
        />
      </div>
      <br/>
      <br/>
      <LoadingOverlay
        active={loading || processing}
        spinner
        text={processing ? 'Preparing data...' : `Loading images... ${progress}%`}
        styles={{
          wrapper: {
            overflow: 'hidden'
          }
        }}
      >
        {inputs.length === 0 ? null :
          <div style={{lineHeight: '30px'}}>
            Input images
          </div>
        }
        {inputs?.map((item, index) => {
          if (item.type === 'map') {
            let combinations = [{
              imageTypeId: item?.selected_id,
              imageTypeName: item?.selected,
              url: item.url,
              vi: item.volumeIndex
            }]
            if (item.hasOwnProperty('length')) {
              combinations = item.url.map((url, url_index) => {
                return {
                  imageTypeId: item?.selected_id[url_index],
                  imageTypeName: item?.selected[url_index],
                  url,
                  vi: item.volumeIndex[url_index]
                }
              })
            }
            return combinations.map((entry, entry_index) => {
              const imageType = analysisImageTypes?.find(bt => bt.id === entry.imageTypeId)
              if (imageType == null) { return null }
              const tagElement = <TagBase
                style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
                color={imageType?.tag_color || '#777777'} children={entry.imageTypeName}/>
              return VolumeImageViewerBase(entry.url, imageType, tagElement, entry.vi)
            })
          }
          else {
            return (
              <div style={{width:viewWidth * acsNum + 6, border: "solid 1px"}}>
                <H5Viewer
                  key={index}
                  url={item.url}
                  width={viewWidth * acsNum + 2}
                  parentLoading={loading}
                  handleLoadEnd={handleLoadEnd}
                  viewerDispatch={viewerDispatch}
                />
              </div>
            )
          }
        }).flat()}
        {extras?.length ?
          <div style={{lineHeight: '30px'}}>
            Extra loaded images
          </div>
          : null
        }
        {extras?.map((item, index) => {
          const imageType = analysisImageTypes?.find(bt => bt.id === item.blobtype_id)
          const tagElement = <TagBase
            style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
            color={item.tag_color} children={item.key}/>
          return VolumeImageViewerBase(item.url, imageType, tagElement, item.volumeIndex)
        })
        }
        <div style={{lineHeight: '30px'}}>
          Output {mapsOnly ? mapCount > 1 ? "images": "image" : "data"}
        </div>
        {outputs?.map((item, index) => {
          if (item.type === 'map') {
            let combinations = [{imageTypeId: item?.blobtype, url: item.url, vi: item.volumeIndex}]
            if (item.hasOwnProperty('length')) {
              combinations = item.url.map((url, url_index) => {
                return {imageTypeId: item?.blobtype[url_index], url, vi: item.volumeIndex[url_index]}
              })
            }
            return combinations.map((entry, entry_index) => {
              const imageType = analysisImageTypes?.find(bt => bt.id === entry.imageTypeId)
              if (imageType == null) { return null }
              const tagElement = <TagBase
                style={{marginTop: 5, marginLeft: 0, marginRight: 0}}
                color={imageType?.tag_color || '#777777'} children={`${imageType?.short}${TASK_DELIMITER}${thisTask.order}`}/>
              return VolumeImageViewerBase(entry.url, imageType, tagElement, entry.vi)
            })
          }
          else if (item.type === 'report') {
            return (
              <div style={{width:viewWidth * acsNum + 4, border: "solid 1px"}}>
                <DicomReportViewer
                  // key={index}
                  key={index}
                  name={item.name}
                  url={item.url}
                  width={viewWidth * acsNum + 2}
                  height={viewWidth * 1.2}
                  parentLoading={loading}
                  handleLoadEnd={handleLoadEnd}
                  viewerDispatch={viewerDispatch}
                  reset={reset}
                />
              </div>
            )
          }
          else {
            return (
              <div style={{width:viewWidth * acsNum + 6, border: "solid 1px"}}>
                <H5Viewer
                  key={index}
                  url={item.url}
                  width={viewWidth * acsNum + 2}
                  parentLoading={loading}
                  handleLoadEnd={handleLoadEnd}
                  viewerDispatch={viewerDispatch}
                />
              </div>
            )
          }
        }).flat()}
        <div
          style={{display: "flex", flexDirection: "row"}}
        >
          {scatterPlotData ?
            <MemoizedScatterPlot
              data={scatterPlotData.data}
              size={viewWidth}
              hidden={!cmds.states[2]}
              showLegend={scatter.showLegend}
              // onAfterPlot={handlePlotEnd}
            /> : null}
        </div>
        <CommandOptionModal
          viewerState={state}
          viewerDispatch={viewerDispatch}
          taskResults={[taskResult]}
          extraImages={extras}
          imageTypeList={analysisImageTypes}
          thisTask={thisTask}
        />
        <Popover
          trigger="click"
          onOpenChange={(open) => {
            if (!open) {
              const newTypes = imageTypeSelected.map(t => {
                if (!t.disabled && t.selected && !t.loaded) {
                  t.selected = false
                }
                return t
              })
              viewerDispatch(update({
                openPopOver: false,
                imageTypeSelected: newTypes
              }))
            }
          }}
          open={openPopOver}
          placement="topRight"
          title="Select images to view"
          content={
            <div>
              <Row gutter={[8, 4]} align="middle" style={{paddingBottom: 15}}>
                <Col>
                  <TaskImageIOSelect
                    options={imageTypeSelected}
                    onChange={handleImageTypeSelectChange}
                    value={imageTypeSelected.filter(t => t.selected).map(t => t.key)}
                    placement="topRight"
                    optionGroup={true}
                    mode={TaskImageIOSelectMODE.MULTI}
                    // row_index={{study : recordStudy, blob : recordBlob}}
                  />
                </Col>
              </Row>
              <Row gutter={[8, 4]} align="middle">
                <Col style={{marginLeft: 'auto'}}>
                  <Button type="primary" onClick={() => {
                    handleLoadExtra()
                    viewerDispatch(update({openPopOver: false}))
                  }}>Load</Button>
                </Col>
              </Row>
            </div>
          }
          getPopupContainer={trigger => trigger}
        >
          <Tooltip
            title="Add image view"
            placement="topRight"
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
            open={openPopOver ? false : undefined}
            getPopupContainer={trigger => trigger}
          >
            <Button
              type="primary"
              shape="circle"
              icon={openPopOver ? <CloseOutlined /> : <PlusOutlined />}
              size="large"
              style={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                zIndex: 10
              }}
              onClick={() => {
                viewerDispatch(update({openPopOver: !state.openPopOver}))
              }}
            />
          </Tooltip>
        </Popover>
      </LoadingOverlay>
    </div>
  );
}

export default TaskResultViewer;

