import React, {useEffect, useState} from "react";
import {Tooltip, Button, Col, InputNumber, Popconfirm, Row, Select, Space, Switch, Typography} from "antd";
import TaskInputSelect, {MODE} from "./taskInputSelect";
import {InfoCircleOutlined, MinusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {LabelSelect} from "./labelControls";
import {TypographyDesc} from "./typographyDesc";
import {resultValueStyle} from "./analysisComponents";

const {Option} = Select;

/* 실행여부 + 선택된 ROI 값의 조합
  "track-roi" : {
    execute: true   // 실행 여부
    roiSelected: 2 // 선택된 ROI 값
    thresholdPercent: 65
    thresholdMinimum: 0
  }
 */
const DEFAULT_THRESHOLD_PERCENT = 65;
const DEFAULT_THRESHOLD_MINIMUM = 0;

const TrackROIFields = ({imageType, value, onChange, disabled}) => {
  // { track: true/false, roi: 1 }
  const [expression, setExpression] = useState(value);
  useEffect(() => {
    if (value == null) {
      setExpression({
        execute: true,
        thresholdPercent: DEFAULT_THRESHOLD_PERCENT,
        thresholdMinimum: DEFAULT_THRESHOLD_MINIMUM
      })
    }
    else {
      setExpression(value);
    }
  }, [value])

  const onChangeLabelSelect = (selected, option) => {
    const newExpression = {
      ...expression,
      roiSelected: selected
    }
    setExpression(newExpression)
    onChange(newExpression)
  }

  const onChangeThresholdPercent = (val) => {
    const newExpression = {
      ...expression,
      thresholdPercent: val ?? DEFAULT_THRESHOLD_PERCENT
    }
    setExpression(newExpression)
    onChange(newExpression)
  }

  const onChangeThresholdMin = (val) => {
    const newExpression = {
      ...expression,
      thresholdMinimum: val ?? DEFAULT_THRESHOLD_MINIMUM
    }
    setExpression(newExpression)
    onChange(newExpression)
  }

  return (
    <>
      <Space>
        <LabelSelect
          options={imageType?.seg_cmap}
          defaultValue={expression?.roiSelected}
          value={expression?.roiSelected}
          onChange={onChangeLabelSelect}
          disabled={disabled || !expression?.execute}
        />
      </Space>
      <Row gutter={[8, 4]} align="middle" style={{marginTop: 10}}>
        <Col span={1}/>
        <Col span={11}>
          {disabled ? <TypographyDesc style={resultValueStyle}>Volume change threshold (ratio)</TypographyDesc>
            : <>
              Volume change threshold (ratio)
              <span
                style={{marginInlineStart: 4, cursor: 'help'}}
              >
            <Tooltip
              title="Both thresholds must be met for the volume change to be detected.">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
            </>
          }
        </Col>
        <Col span={1}/>
        <Col style={{whiteSpace: 'pre-wrap'}}>
          <InputNumber
            size="small"
            addonAfter='%'
            style={{width: 112}}
            disabled={disabled || !expression?.execute}
            min={0}
            defaultValue={expression?.thresholdPercent || 65}
            onChange={onChangeThresholdPercent}
          />
        </Col>
      </Row>
      <Row gutter={[8, 4]} align="middle" style={{marginTop: 10}}>
        <Col span={1}/>
        <Col span={11}>
          {disabled ? <TypographyDesc style={resultValueStyle}>Volume change threshold (absolute value)</TypographyDesc>
            : <>
              Volume change threshold (absolute value)
              <span
                style={{marginInlineStart: 4, cursor: 'help'}}
              >
            <Tooltip
              title="Both thresholds must be met for the volume change to be detected.">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
            </>
          }
        </Col>
        <Col span={1}/>
        <Col style={{whiteSpace: 'pre-wrap'}}>
          <InputNumber
            size="small"
            addonAfter={<>mm<sup>3</sup></>}
            style={{width: 130}}
            disabled={disabled || !expression?.execute}
            min={0}
            defaultValue={expression?.thresholdMinimum || 0}
            onChange={onChangeThresholdMin}
          />
        </Col>
      </Row>
    </>
  );
}
export default TrackROIFields;