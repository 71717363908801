import React, {useState, useEffect, useReducer} from "react";
import {Modal, Button, Table, Divider, Form, Input} from 'antd';
import { ClockCircleOutlined, SyncOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import { get} from "../redux/modules/analysis"
import { message } from 'antd'
import {useHistory, useLocation, useParams} from "react-router-dom";
import {getUrl} from "../redux/modules/view";
import {useTitle} from "../hooks/title";
import useSocket from "../hooks/socket"

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
      const payload = action.payload
      if (payload.type === 'run') {
        // console.log(state)
        // TODO mode === MODE.SINGLE 에 따라서 다르게 처리해줄 것
        const newList = state.list.map(item => {
          if (item.run_id === payload.id) {
            item.state = payload.state // 일단 run state 변경
            if (payload.cases) { // case 정보도 있으면 함께 업데이트
              item.cases = item.cases.map(ic => {
                payload.cases.forEach(pitem => {
                  if (ic.patient_id === pitem.pid && ic.study_id === pitem.sid) {
                    ic.message = pitem.msg
                    ic.state = pitem.state
                  }
                })
                return ic
              })
            }
          }
          return item
        })
        // console.log(newList)
        // console.log("reducer: runs - update - dispached!")
        return { list: newList };
      }
      return state
    case "update_all":
      // console.log("reducer: runs - update_all - dispached!")
      return { list: action.payload };
    default:
      throw new Error("Unsupported action type:", action.type);
  }
}

export const MODE = Object.freeze({SINGLE: 0, MULTI: 1})
function Analysis() {
  useTitle("Analysis");
  const [patients, setPatients] = useState([]);
  const [runs, dispatch] = useReducer(reducer, {list:[]});
  let params = useParams();
  const socketCallback = (payload) => {
    dispatch({type: 'update', payload: payload})
  }
  useSocket('/progress', socketCallback);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const analysis = useSelector(state => state.analysis);
  const dataset = useSelector(state => state.analysis.dataset);
  const patientsInStore = useSelector(state => state.analysis.patients);
  const pipeline = useSelector(state => state.analysis.pipeline);
  const runsInStore = useSelector(state => state.analysis.runs);
  const taskTemplatesInStore = useSelector(state => state.analysis.task_templates);

  const [task_templates, setTaskTemplates] = useState([]);

  const [mode, setMode] = useState()

  // cases는 patient_id, study_id만 가지고 있음
  const buildCase = (run_id, cases, patients) => {
    if (mode === MODE.SINGLE) {
      return cases.map(item => {
        const patient = patients.find(p => p.dbid === item.patient_id)
        const study = patient.study_ids.find(s => s.id === item.study_id)
        return {
          key: study.id,
          id: patient.id,
          patient_id: patient.dbid,
          pname: patient.name,
          sex: patient.sex,
          age: patient.age,
          birth: patient.birth,
          study_id: study.id,
          study_desc: study.desc,
          date: study.date,
          message: item.message,
          state: item.state,
          run_id: run_id
        }
      })
    }
    else {
      return patients.map(patient => {
        const patient_cases = cases.filter(c => c.patient_id === patient.dbid)
        let patient_state = 'fail'
        if (patient_cases.some(c => c.state === 'pending')) {
          patient_state = 'pending'
        }
        else if (patient_cases.every(c => c.state === 'success')) {
          patient_state = 'success'
        }
        return {
          key: patient.dbid,
          id: patient.id,
          patient_id: patient.dbid,
          pname: patient.name,
          sex: patient.sex,
          age: patient.age,
          birth: patient.birth,
          state: patient_state,
          run_id: run_id,
          studies: patient_cases.map(a_case => {
            const study = patient.study_ids.find(s => s.id === a_case.study_id)
            return {
              study_id: study.id,
              study_desc: study.desc,
              date: study.date,
              message: a_case.message,
              state: a_case.state,
            }
          })
        }
      })
    }
  }

  useEffect(() => {
    if (dataset !== undefined) {
      form.setFieldsValue({
        id: dataset.id,
        name: dataset.name,
        desc: dataset.desc
      });
      if (mode === undefined) {
        setMode(pipeline?.longitudinal ? MODE.MULTI : MODE.SINGLE)
      }
    }
    if (pipeline !== undefined) {
      form2.setFieldsValue({
        id: pipeline.id,
        name: pipeline.name,
        desc: pipeline.desc
      });
    }
    if (patientsInStore !== undefined && runsInStore !== undefined) {
      // runs - patient - series 순의 계층구조
      // runs.list - record.cases - record.studies
      // patient + study = case 개념인데,
      // TODO 나중에 Analysis 설정에 따라 한 환자의 각 scan이 독립적으로 여겨질 수 있음

      let patients = Object.entries(patientsInStore).map(([key, value], index) => {
        return {
          key: index,
          dbid: value.dbid,
          id: value.id,
          name: value.name,
          sex: value.sex,
          age: value.age,
          birth: value.birth,
          study_ids: value.study_ids
        };
      });

      const runs = Object.entries(runsInStore).map(([key, value], index) => {
        return {
          key: value.id,
          config: value.config,
          state: value.state,
          task_id: value.task_id,
          task_name: value.task_name,
          task_order: value.task_order,
          task_template_id: value.task_template_id,
          run_id: value.id,
          cases: buildCase(value.id, value.cases, patients)
        };
      });
      const sortedRuns = runs.sort((a, b) => (a.task_order > b.task_order) ? 1 : -1)
      dispatch({type: 'update_all', payload: sortedRuns})
      // console.log("runs dispached!")

    }
    if (taskTemplatesInStore !== undefined) {
      setTaskTemplates(taskTemplatesInStore);
    }

    setLoading(false);
  }, [dataset, patientsInStore, pipeline, runsInStore, mode])

  // 최초 analysis db 조회
  useEffect(() => {
    setLoading(true);
    if (location.state !== undefined && location.state?.key !== undefined) {
      dispatchRedux(get({id: location.state.key}))
    }
  }, [])

  const handleVerifyPatient = (record) => {
    history.push('/verify', {pid: record.dbid, study_ids: record.study_ids});
  }

  const handleViewPatient = record => {
    // patient를 보여준다? 모든 task 결과를?
  };
  const handleViewTask = record => {
    // setViewingTask(true);
    // setTask(record);
    form3.setFieldsValue({
      name: record.task_name,
      bet: true,
      "objective function": 'nmi',
      "interpolation": "4th Degree B-Spline"
    })
  };
  const handleCancel = () => {
    // dispatchRedux(delete());
    history.goBack();
  };

  const expandedPatientRowRender = (record) => {
    const study_columns = [
      {
        title: 'Study ID',
        dataIndex: 'study_id',
        key: 'study_id',
      },
      {
        title: 'Description',
        dataIndex: 'study_desc',
        key: 'study_desc',
        // ellipsis: true,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: text => <>{text.split(' ')[0]}</>,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: record => {
          return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
            : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
              : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
                : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
        }
      },
      {
        title: '',
        key: 'action',
        render: (record_text, record, record_index) => (
          <div>
            {record.state === 'fail' ? <Button onClick={() => {
              Modal.warning({content:record.message})
            }}>Logs</Button> : null}
          </div>
        ),
      },
    ];
    return (
      <Table
        size="small"
        columns={study_columns}
        dataSource={record.studies}
        pagination={false}
      />
    );
  };
  const expandedTaskRowRender = (record, task_index) => {
    const handleView = (record, case_index) => {
      let sids = [record?.study_id]
      if (record.hasOwnProperty('studies') && mode === MODE.MULTI) {
        sids = record.studies.map(s => s.study_id)
      }
      dispatchRedux(getUrl({
        run_id: record.run_id,
        pid: record.patient_id,
        sids: sids,
        task_index: task_index,
        case_index: case_index
      }))


      // history.push('view-volume', {run_id:record.run_id, pid: record.patient_id, study_ids: [record.study_id]})
      // history.push('/verify', {pid: record.dbid, study_ids: record.study_ids});
      // history.push('view-volume')
    }

    const patient_columns2 = [
      {
        title: 'Patient Name',
        dataIndex: 'pname',
        key: 'pname',
      },
      {
        title: 'Birth',
        dataIndex: 'birth',
        key: 'birth',
        render: text => <>{text.split(' ')[0]}</>,
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Sex',
        dataIndex: 'sex',
        key: 'sex',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: record => {
          return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
            : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
              : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
                : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
        }
      },
      {
        title: '',
        key: 'action',
        render: (record_text, record, record_index) => (
          <div>
            {record.state === 'success' ? <Button onClick={() => handleView(record, record_index)}>View</Button> : null}
            {record.state === 'fail' ? <Button onClick={() => {
              Modal.warning({content:record.message})
            }}>Logs</Button> : null}
          </div>
        ),
      },
    ];
    const patient_columns = [
      {
        title: 'Patient Name',
        dataIndex: 'pname',
        key: 'pname',
      },
      {
        title: 'Birth',
        dataIndex: 'birth',
        key: 'birth',
        render: text => <>{text.split(' ')[0]}</>,
      },
      {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Sex',
        dataIndex: 'sex',
        key: 'sex',
      },
      {
        title: 'Study ID',
        dataIndex: 'study_id',
        key: 'study_id',
      },
      {
        title: 'Study Date',
        dataIndex: 'date',
        key: 'date',
        render: text => <>{text?.split(' ')[0]}</>,
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: record => {
          return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
            : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
              : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
                : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
        }
      },
      {
        title: '',
        key: 'action',
        render: (record_text, record, record_index) => (
          <div>
            {record.state === 'success' ? <Button onClick={() => handleView(record, record_index)}>View</Button> : null}
            {record.state === 'fail' ? <Button onClick={() => {
              Modal.warning({content:record.message})
            }}>Logs</Button> : null}
          </div>
        ),
      },
    ];
    return (
      <Table
        size="middle"
        columns={mode === mode.SINGLE ? patient_columns : patient_columns2}
        dataSource={record.hasOwnProperty('studies') ? record.studies : record.cases}
        pagination={false}
        expandable={{
          expandedRowRender:expandedPatientRowRender,
          rowExpandable: record => record.hasOwnProperty('studies'),
          defaultExpandAllRows: true,
          expandRowByClick: true,
        }}
        // defaultExpandAllRows={true}
        // rowKey={record => record.key}
        rowkey="key"
      />
    );
  };
  const task_columns = [
    {
      title: 'Order',
      dataIndex: 'task_order',
      key: 'task_order',
      render: value => value > 0 ? value : '-'
    },
    {
      title: 'Type',
      dataIndex: 'task_template_id',
      key: 'name',
      render: (record) => {
        // console.log(record);
        const idx = task_templates.findIndex(el => el.id === record);
        return task_templates[idx].name;
      }
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: record => {
        return record === 'running' ? <SyncOutlined style={{fontSize:24}} spin/>
          : record === 'success' ? <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:24}}/>
          : record === 'fail' ? <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize:24}}/>
          : <ClockCircleOutlined style={{fontSize:24}} />
          // : <LoadingOutlined style={{fontSize:24}} spin/>
      }
    },
  ];

  const onFinishViewingTask = values => {
    // setViewingTask(false);
  }

  const [caseSelKeys, setCaseSelKeys] = useState([]);
  const caseRowSelection = {
    selectedRowKeys: caseSelKeys,
    type: 'radio',
    // preserveSelectedRowKeys: false, // 4.4에서...추가됨 ㅠ
    onChange: (selectedRowKeys, selectedRows) => {
      setCaseSelKeys(selectedRowKeys);
      // console.log(selectedRowKeys, selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [taskSelKeys, setTaskSelKeys] = useState([]);
  const taskRowSelection = {
    selectedRowKeys: taskSelKeys,
    type: 'radio',
    // preserveSelectedRowKeys: false, // 4.4에서...추가됨 ㅠ
    onChange: (selectedRowKeys, selectedRows) => {
      setTaskSelKeys(selectedRowKeys);
      // console.log(selectedRowKeys, selectedRows)
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleCheck = () => {
    // console.log(taskSelKeys)
    // console.log(caseSelKeys)
    if (taskSelKeys.length === 0) {
      message.info("please select a task to check")
      return
    }
    if (caseSelKeys.length === 0) {
      message.info("please select a patient to check")
      return
    }
    history.push('view-volume', {task:taskSelKeys[0], caseSelKeys})
    // history.push('view-volume')
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      {/*<div style={{display:'flex', flexDirection:'row'}}>*/}
      <div style={{display:'flex', justifyContent: 'center'}}>
      {/*<div style={{width: '100%', }}>*/}
        <Form
          form={form2}
          name="pipeline"
          className="login-form"
          // style={{width: '50%', padding: 10}}
          // style={{width: '65%', margin: '0 auto'}}
        >
          <h1>Analysis results</h1>
          <Button onClick={() => mode === MODE.SINGLE ? setMode(MODE.MULTI) : setMode(MODE.SINGLE)}>
            {mode === MODE.SINGLE ? "cross-sectional view" : "longitudinal view"}
          </Button>
          <Divider/>
          <h3>Pipeline</h3>
          <br/>
          <Form.Item name="id" style={{display: 'none'}}>
            <Input type="hidden"/>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
          >
            <Input placeholder="My Analysis"/>
          </Form.Item>
          <Form.Item
            label="Description"
            name="desc"
          >
            <Input.TextArea placeholder="Analysis description"/>
          </Form.Item>
          <h3>Tasks</h3>
          <Table
            expandable={{
              // expandedRowKeys: runs.list.map(item => item.key),
              expandedRowRender: expandedTaskRowRender,
              // rowExpandable: record => record.state !== 'pending',
              rowExpandable: record => record.task_name !== 'preparation',
              defaultExpandAllRows: true,
              expandRowByClick: true,
            }}
            loading={loading}
            columns={task_columns}
            dataSource={runs.list}
            rowSelection={null}
            rowKey="key"
            pagination={false}
          />
        </Form>
      </div>
    </div>
  );
}

export default Analysis;
