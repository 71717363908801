import { all, fork, takeEvery} from "redux-saga/effects";
import * as actions from '../../redux/modules/message'
import { message } from "antd";

message.config({maxCount: 5})
let count = 0

function* addMessageSaga({payload}) {
  const {type , content, cb} = payload
  count +=1
  const key = count
  if (cb) {
    yield message[type]({
      key,
      content,
      onClick : () => message.destroy(key),
    }).then(()=>{cb()})
  } else {
    yield message[type]({
      key,
      content,
      onClick : () => message.destroy(key),
    })
  }
}

function* watchMessageAction() {
  yield takeEvery(actions.SUCCESS, addMessageSaga)
  yield takeEvery(actions.INFO, addMessageSaga)
  yield takeEvery(actions.WARN, addMessageSaga)
  yield takeEvery(actions.ERROR, addMessageSaga)
  yield takeEvery(actions.SUCCESS_AND_CB, addMessageSaga)
  yield takeEvery(actions.INFO_AND_CB, addMessageSaga)
  yield takeEvery(actions.ERROR_AND_CB, addMessageSaga)
}

export default function* messageSaga() {
  yield all([
    fork(watchMessageAction)
  ])
}