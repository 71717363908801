import {all, call, cancelled, fork, getContext, put, takeLatest, takeLeading} from "redux-saga/effects"; 
import * as actions from "../../redux/modules/patient";
import axios from "axios";
import {
  loadSuccess, loadFail, getFail, getSuccess,updateSuccess, updateFail,
  delPatientsSuccess, delPatientsFail, getStudiesSuccess, getStudiesFail,
  delAsk, mergePatientSuccess, mergePatientFail, mergeAsk,
  addSuccess, addFail, getDownloadTargetsSuccess, getDownloadTargetsFail
} from "../../redux/modules/patient";
import {getCookie} from "../../lib/cookie";
import { msgError, msgSuccess } from "../../redux/modules/message";
import { upload } from "../../redux/modules/longTask";

function loadApi(page, per_page, category, keyword) {
  axios.defaults.headers.post['X-CSRF-Token'] = getCookie('csrf_access_token')
  return axios.post('/api/listPatient', {pagination:{page, per_page}, search: {category, keyword}},
  {withCredentials: true});
}

function getApi(id, cancelToken) {
  axios.defaults.headers.post['X-CSRF-Token'] = getCookie('csrf_access_token')
  return axios.post('/api/getPatient', {id}, {cancelToken},
  {withCredentials: true});
}

function updateApi({patientInfo, studies, approve=false}) {
  return axios.post('/api/updatePatient', {patientInfo, studies, approve},
  {withCredentials: true});
}

function delPatientsApi({patients, page, per_page, category, keyword, approve=false}) {
  return axios.post('/api/delPatients', {patients, pagination: {page, per_page}, search: {category, keyword}, approve},
  {withCredentials: true});
}

function getStudiesApi(id) {
  return axios.post('/api/getStudies', {id},{withCredentials: true});
}

function mergePatientApi({destination, patients, approve, page, per_page, category, keyword}) {
  return axios.post('/api/mergePatient', {
    destination, patients, approve, 
    pagination: {page, per_page}, 
    search: {category, keyword}
  }, {withCredentials: true})
}

function addApi({patientInfo, studies}) {
  return axios.post('/api/addPatient', {patientInfo, studies}, {withCredentials: true});
}

function getDownloadTargetsApi({patients}) {
  return axios.post('/api/getDownloadTargetPatient', {patients},{withCredentials: true});
}

function* loadSaga({payload}) {
  try {
    const { page, per_page, category, keyword } = payload;
    const res = yield loadApi(page, per_page, category, keyword)
    yield put(loadSuccess(res.data));
  } catch (error) {
    yield put(loadFail());
    yield put(msgError(error))
  }
}

function* getSaga({payload}) {
  const cancelSource = axios.CancelToken.source()
  try {
    const { id } = payload;
    const res = yield call(getApi, id, cancelSource.token);
    yield put(getSuccess(res.data));
  } catch (error) {
    yield put(getFail());
    yield put(msgError(error))
  }
  finally {
    if (yield cancelled()) {
      yield call(cancelSource.cancel)
    }
  }
}

function* updateSaga({payload}) {
  const {patientInfo, studies, approve=false} = payload
  try {
    const reducedStudies = studies => {
      return studies.map(s => ({
        ...s,
        series: s.seriesList.map(sr => ({
          ...sr,
          files: [sr?.files?.[0]],
        }))
      }))
    }
    const res = yield updateApi({patientInfo, studies: reducedStudies(studies), approve});
    
    const data = res.data
    
    if (data.affected_list) {
      // dataset에 영향이 있는 데이터를 수정하거나 삭제하는 경우
      yield put(delAsk(data))
    }
    else {
      // for new data upload
      const filteredStudies = studies.filter(s => !s.deleted)
      const uploadTargetStudies = []
      filteredStudies.forEach(s => {
        const seriesList = s.seriesList.filter(sr => sr.new && !sr.deleted)
        const blobs = s.blobs.filter(b => b.new && !b.deleted)
        s.seriesList = seriesList
        s.blobs = blobs
        if (seriesList.length > 0 || blobs.length > 0) {
          uploadTargetStudies.push(s)
        }
      })
      
      if (uploadTargetStudies.length === 0) {
        yield put(updateSuccess({}));
        const history = yield getContext('history');
        history.goBack();
      }
      else {
        const createKeyMapObj = data.obj_key
        uploadTargetStudies.forEach(s => {
          if (!s.id) {
            s.id = createKeyMapObj[s.key]
          }
          s.seriesList.forEach(sr => {
            if (!sr.id) {
              sr.id = createKeyMapObj[sr.key]
            }
          })
          s.blobs.forEach(b => {
            if (!b.id) {
              b.id = createKeyMapObj[b.key]
            }
          })
          s.series = s.seriesList
        })
        const patients = [{...patientInfo, studies: uploadTargetStudies}]
        
        yield put(upload({patients, withPatientUpdate: true}))
      }
      yield put(msgSuccess(`Patient '${patientInfo.pid}' updated successfully!`))
    }
  } catch (error) {
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function* delPatientsSaga({payload}) {
  try {
    const {patients, page, per_page, category, keyword, approve} = payload
    const res = yield delPatientsApi({patients, page, per_page, category, keyword, approve});
    const data = res.data
    if (data.target_data) {
      // dataset의 include 포함된 것을 삭제하는 경우
      yield put(delAsk(data))
    } 
    else {
      yield put(delPatientsSuccess(data))
    }
    
  } catch (error) {
    yield put(delPatientsFail());
    yield put(msgError(error))
  }
}

function* getStudiesSaga({payload}) {
  try {
    const { id } = payload;
    const res = yield getStudiesApi(id)
    yield put(getStudiesSuccess(res.data));
  } catch (error) {
    yield put(getStudiesFail());
    yield put(msgError(error))
  }
}

function* mergePatientSaga({payload}) {
  try {
    const {destination, patients} = payload
    const res = yield mergePatientApi(payload)
    if (res.data.affected_list) {
      yield put(mergeAsk(res.data))
    }
    else {
      yield put(mergePatientSuccess(res.data))
      const destPatient = patients.find(p => p.key === destination)
      yield put(msgSuccess(`Success merge, Please Check ${destPatient.pid} - ${destPatient.name}`))
    }
  } catch (error) {
    yield put(mergePatientFail())
    yield put(msgError(error))
  }
}

function* addSaga({payload}) {
  try {
    const res = yield addApi(payload);
    yield put(addSuccess(res.data))
    const history = yield getContext('history');
    history.goBack();
  } catch (error) {
    yield put(addFail())
    yield put(msgError(error))
  }
}

function* getDownloadTargetsSaga({payload}) {
  try {
    const {patients, inDetail=false} = payload
    if (!inDetail) {
      const res = yield getDownloadTargetsApi({patients});
      if (res.data.download_counts > 50) {
        yield put(msgError(`Data download is available for up to 50 (current: ${res.data.download_counts})`))
        yield put(getDownloadTargetsFail());
      }
      else {
        yield put(getDownloadTargetsSuccess(res.data));
      }
    }
    else {
      const convertData = patients.map(patient => ({
        ...patient,
        studies: patient.studies.map(study => ({
          ...study,
          series_list: study.seriesList
        }))
      }))
      yield put(getDownloadTargetsSuccess({patients: convertData}));
    }
  } catch (error) {
    yield put(getDownloadTargetsFail());
    yield put(msgError(error))
  }
}

function* watchLoad() {
  yield takeLeading(actions.LOAD, loadSaga);
}

function* watchGet() {
  yield takeLatest(actions.GET, getSaga);
  yield takeLatest(actions.GET_STUDIES, getStudiesSaga)
}

function* watchAdd() {
  yield takeLeading(actions.ADD, addSaga)
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}


function* watchDel() {
  yield takeLeading(actions.DEL_PATIENTS, delPatientsSaga)
}

function* watchMerge() {
  yield takeLeading(actions.MERGE_PATIENT, mergePatientSaga)
}

function* watchDwonload() {
  yield takeLeading(actions.GET_DOWNLOAD_TARGETS, getDownloadTargetsSaga)
}

export default function* patientSaga() {
  yield all([
    fork(watchLoad),
    fork(watchGet),
    fork(watchUpdate),
    fork(watchDel),
    fork(watchMerge),
    fork(watchAdd),
    fork(watchDwonload),
  ]);
}
