import React from "react";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneTools from "cornerstone-tools";
import * as cornerstoneNIFTIImageLoader from 'cornerstone-nifti-image-loader'
import {calcMinMaxSlice, computeAutoVoi, formatWindowLevelString} from "../lib/cornerstoneUtils";
import myOrientationTool from "../lib/customTools/myOrientationTool";
import {Button, Tooltip} from 'antd';
import {faHouse, faWandMagic} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const scrollToIndex = cornerstoneTools.importInternal('util/scrollToIndex');

function areStringArraysEqual(arr1, arr2) {
  if (arr1 === arr2) return true; // Identity
  if (!arr1 || !arr2) return false; // One is undef/null
  if (arr1.length !== arr2.length) return false; // Diff length

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

const bottomLeftStyle = {
  bottom: "5px",
  left: "5px",
  position: "absolute",
  color: "white"
};

const bottomRightStyle = {
  bottom: "5px",
  right: "5px",
  position: "absolute",
  color: "white"
};

class CornerstoneElementPlain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 512,
      height: 512,
      stack: props.stack,
      viewport: cornerstone.getDefaultViewport(null, undefined),
      viewportInitial: null,
      imageId: props.stack.imageIds[props.stack.currentImageIdIndex],
      imageLen: props.stack.imageIds.length,
      imageIdx: props.stack.currentImageIdIndex
    };

    this.onImageRendered = this.onImageRendered.bind(this);
    this.onNewImage = this.onNewImage.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.addCornerstoneTools = this.addCornerstoneTools.bind(this)
  }


  render() {
    return (
      <div onContextMenu={(e) => e.preventDefault()}>
        <div style={{
          top: "20px",
          left: "24px", // margin (5) + imageScrollBar (17)
          position: "absolute",
        }}>
          <Tooltip title="Reset">
            <Button
              icon={<FontAwesomeIcon icon={faHouse}/>}
              onClick={() => {
                cornerstone.loadAndCacheImage(this.state.imageId).then(image => {
                  cornerstone.displayImage(this.element, image, {
                    ...this.state.viewportInitial,
                    translation: {...this.state.viewportInitial.translation},
                    voi: {...this.state.viewportInitial.voi}
                  });
                })
              }}/>
          </Tooltip>
        </div>
        <div style={{textAlign: 'center'}}>{this.state.imageIdx + 1} / {this.state.imageLen}</div>
        <div style={{
          top: "20px",
          right: "24px", // margin (5) + imageScrollBar (17)
          position: "absolute",
        }}>
          <Tooltip title="Auto-contrast">
            <Button icon={<FontAwesomeIcon icon={faWandMagic}/>} onClick={() => {
              const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(this.state.imageId)
              if (niftiMetaData) {
                cornerstoneNIFTIImageLoader.nifti.loadImage(this.state.imageId).then(image => {
                  const {min, max, skewness} = calcMinMaxSlice(image.getPixelData(), niftiMetaData)
                  cornerstone.loadAndCacheImage(this.state.imageId).then(image => {
                    const viewport = cornerstone.getViewport(this.element);
                    computeAutoVoi(niftiMetaData, viewport, image, min, max, skewness)
                    cornerstone.displayImage(this.element, image, viewport);
                  })
                })
              }
              else {
                cornerstone.loadAndCacheImage(this.state.imageId).then(image => {
                  const viewport = cornerstone.getViewport(this.element);
                  computeAutoVoi(undefined, viewport, image)
                  cornerstone.displayImage(this.element, image, viewport);
                })
              }
            }}/>
          </Tooltip>
        </div>
        <br/>
        <div
          className="viewportElement"
          style={{
            width: this.state.width,
            height: this.state.height,
            position: "relative",
            color: "white"
          }}
          ref={input => {
            this.element = input;
          }}
        >
          <canvas className="cornerstone-canvas"/>
          <div style={bottomLeftStyle}>Zoom: {this.state.viewport.scale.toFixed(2)}</div>
          <div style={bottomRightStyle}>
            WW/WC: {formatWindowLevelString(this.state.viewport.voi.windowWidth)} /{" "}
            {formatWindowLevelString(this.state.viewport.voi.windowCenter)}
          </div>
        </div>
      </div>
    );
  }

  onWindowResize() {
    cornerstone.resize(this.element);
  }

  onImageRendered() {
    const viewport = cornerstone.getViewport(this.element);

    this.setState({
      viewport
    });

  }

  onNewImage(e) {
    // console.log(e.detail.image, e.detail.oldImage)
    const enabledElement = cornerstone.getEnabledElement(this.element);
    const stackToolData = cornerstoneTools.getToolState(this.element, "stack");
    if (stackToolData) {
      const stackData = stackToolData.data[0];
      this.setState({
        imageId: enabledElement.image.imageId,
        imageLen: stackData.imageIds.length,
        imageIdx: stackData.currentImageIdIndex
      });

      const image = e.detail.image
      const viewport = cornerstone.getDefaultViewportForImage(this.element, image);
      if (image.color === false && this.props.colormap) {
        viewport.colormap = this.props.colormap
        if (viewport.colormap !== 'gray') {
          image.render = undefined
        }
      }

      if (e.detail.image.rows !== e.detail.oldImage.rows || e.detail.image.columns !== e.detail.oldImage.columns ) {
        // 이전 이미지와 크기가 다르다면
        cornerstone.displayImage(this.element, image, viewport); // 여기서 자꾸 부르면 render 무한 호출 ㄷㄷ
      }
      this.setState({
        ...this.state,
        viewportInitial: {
          ...viewport,
          translation: {x: 0, y: 0}
        }
      })
    }
  }

  addCornerstoneTools(report) {
    // remove all tools
    const toolNames = ['Wwwc', 'Zoom', 'Pan', 'ZoomMouseWheel', 'StackScrollMouseWheel', 'myOrientation']
    toolNames.forEach(tn => cornerstoneTools.removeToolForElement(this.element, tn))

    // add basic tools
    // 좌클릭은 WW/WC
    const wwwcTool = cornerstoneTools.WwwcTool;
    cornerstoneTools.addTool(wwwcTool);
    cornerstoneTools.setToolActive('Wwwc', {mouseButtonMask: 1});
    // 우클릭은 확대, 축소
    const zoomTool = cornerstoneTools.ZoomTool;
    cornerstoneTools.addTool(zoomTool);
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2});
    // 휠클릭은 panning
    const panTool = cornerstoneTools.PanTool;
    cornerstoneTools.addTool(panTool);
    cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 4});
    // 휠인데 mouseButtonMask 는 뭐지?
    const zoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
    cornerstoneTools.addTool(zoomMouseWheelTool);
    cornerstoneTools.setToolActive('ZoomMouseWheel', { mouseButtonMask: 1});
    // 스택 - 마우스스크롤
    const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool;
    cornerstoneTools.addTool(StackScrollMouseWheelTool)
    cornerstoneTools.setToolActive('StackScrollMouseWheel', {
      configuration: {
        // loop: true
        allowSkipping: false
      }
    })

    if (!report) {
      // orientation 레이블
      cornerstoneTools.addTool(myOrientationTool, {configuration: { drawAllMarkers: true }});
      cornerstoneTools.setToolActive('myOrientation')
    }
  }

  componentDidMount() {
    const element = this.element;

    // Enable the DOM Element for use with Cornerstone
    cornerstone.enable(element);

    // Load the first image in the stack
    cornerstone.loadAndCacheImage(this.state.imageId).then(image => {
      const elements = cornerstone.getEnabledElements()
      if (elements.every(e => e.element !== element)) {
        return ;
      }
      cornerstone.resize(element); // modal 안에서 쓰이니까 마운트 후 1회 호출해준다, 이게 왜 해결책이지

      const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(image.imageId)
      const viewport = cornerstone.getDefaultViewportForImage(element, image);
      if (image.color === false && this.props.colormap) {
        viewport.colormap = this.props.colormap
        if (viewport.colormap !== 'gray') {
          image.render = undefined
        }
      }
      // const viewport = cornerstone.getViewport(this.element);
      computeAutoVoi(niftiMetaData, viewport, image)

      // Display the first image
      cornerstone.displayImage(element, image, viewport);

      const promises = Promise.all(this.props.stack.imageIds.map(imgid => cornerstone.loadAndCacheImage(imgid)))
      promises.then(images => {
        images.forEach(image => {
          if (image.color === false && this.props.colormap && viewport.colormap !== 'gray') {
            image.render = undefined
          }
        })
        const rowsSet = new Set(images.map(img => img.rows))
        const columnsSet = new Set(images.map(img => img.columns))
        const report = image.color && ((rowsSet.size > 1 || columnsSet.size > 1) || (image.rows > 1024 || image.columns > 1024))

        this.addCornerstoneTools(report)

        // Add the stack tool state to the enabled element
        const newStack = {
          ...this.props.stack,
          currentImageIdIndex: report ? 0 : this.state.stack.currentImageIdIndex,
        };
        cornerstoneTools.addStackStateManager(element, ["stack"]);
        cornerstoneTools.addToolState(element, "stack", newStack);

        this.setState({
          ...this.state,
          stack: newStack,
          width: report ? 1024 : 512,
          height: report ? 1024: 512,
          viewportInitial: {
            ...viewport,
            scale: report ? this.element.clientWidth === 1024 ? viewport.scale : viewport.scale * 2 : this.element.clientWidth === 512 ? viewport.scale : viewport.scale * 0.5,
            translation: {x: 0, y: 0}
          },
        });
        cornerstone.displayImage(element, images[newStack.currentImageIdIndex], viewport)
        cornerstone.resize(element)
      })
    })

    element.addEventListener("cornerstoneimagerendered", this.onImageRendered);
    element.addEventListener("cornerstonenewimage", this.onNewImage);
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    const element = this.element;
    window.removeEventListener("resize", this.onWindowResize);
    element.removeEventListener("cornerstonenewimage", this.onNewImage);
    element.removeEventListener("cornerstoneimagerendered", this.onImageRendered);
    cornerstone.disable(element);
  }


  async componentDidUpdate(prevProps, prevState) {
    // const stackData = cornerstoneTools.getToolState(this.element, "stack");
    // const stack = stackData.data[0];

    let {
      imageIds: stack,
      currentImageIdIndex: imageIndex,
    } = this.props.stack;

    const {
      imageIds: prevStack,
      currentImageIdIndex: prevImageIndex,
    } = prevProps.stack;

    const hasStackChanged = !areStringArraysEqual(prevStack, stack);
    const hasImageIndexChanged =
      imageIndex != null && imageIndex !== prevImageIndex;
    const colormapChanged = prevProps.colormap !== this.props.colormap

    if (hasStackChanged) {
      // update stack toolstate
      cornerstoneTools.clearToolState(this.element, 'stack');

      try {
        cornerstoneTools.stopClip(this.element);

        const imageId = stack[imageIndex || 0];
        const image = await cornerstone.loadAndCacheImage(imageId);

        let newStackImages = stack

        // nifti 인 경우 stack imageIds를 생성해줌
        const multiFrameModule = cornerstone.metaData.get('multiFrameModule', image.imageId)
        if (multiFrameModule !== undefined) {
          const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
          const imageIdObject = ImageId.fromURL(image.imageId);
          const numberOfSlices = multiFrameModule.numberOfFrames;
          newStackImages = Array.from(Array(numberOfSlices), (_, i) => `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i}`)
        }

        // 1. 컬러인가?
        // 2. rows와 columns 크기가 모두 동일한가?
        // 3. rows 나 columns 크기가 1024보다 큰 부분이 있나?
        const images = await Promise.all(newStackImages.map(imgid => cornerstone.loadAndCacheImage(imgid)))
        const rowsSet = new Set(images.map(img => img.rows))
        const columnsSet = new Set(images.map(img => img.columns))
        const report = image.color && ((rowsSet.size > 1 || columnsSet.size > 1) || (image.rows > 1024 || image.columns > 1024))

        this.addCornerstoneTools(report)

        let newStackRenderIndex = report ? 0 : Math.floor(newStackImages.length / 2)
        const newStack = {
          imageIds: newStackImages,
          currentImageIdIndex: newStackRenderIndex,
        }
        cornerstoneTools.addToolState(this.element, 'stack', newStack);
        const midImage = await cornerstone.loadAndCacheImage(newStackImages[newStackRenderIndex]);
        const niftiMetaData = cornerstoneNIFTIImageLoader.nifti.metaDataManager.get(midImage.imageId)
        const viewport = cornerstone.getDefaultViewportForImage(this.element, image);
        if (image.color === false && this.props.colormap) {
          viewport.colormap = this.props.colormap
          if (viewport.colormap !== 'gray') {
            image.render = undefined
          }
        }
        computeAutoVoi(niftiMetaData, viewport, image)
        cornerstone.displayImage(this.element, midImage, viewport);
        images.forEach(image => {
          if (image.color === false && this.props.colormap && viewport.colormap !== 'gray') {
            image.render = undefined
          }
        })

        this.setState({
          ...this.state,
          stack: newStack,
          width: report ? 1024 : 512,
          height: report ? 1024: 512,
          viewportInitial: {
            ...viewport,
            scale: report ? this.element.clientWidth === 1024 ? viewport.scale : viewport.scale * 2 : this.element.clientWidth === 512 ? viewport.scale : viewport.scale * 0.5,
            translation: {x: 0, y: 0}
          },
        });
        cornerstone.resize(this.element);
      } catch (err) {
        // :wave:
        // What if user kills component before `displayImage`?
      }
    } else if (!hasStackChanged && hasImageIndexChanged) {
      scrollToIndex(this.element, imageIndex);
    } else if (colormapChanged) {
      const viewport = cornerstone.getViewport(this.element);
      viewport.colormap = this.props.colormap // 바뀐 컬러맵 적용

      var enabledElement = cornerstone.getEnabledElement(this.element);
      if (enabledElement && enabledElement.image) {
        const image = enabledElement.image;
        if (image.color === false && this.props.colormap && viewport.colormap !== 'gray') {
          image.render = undefined // colormap 값이 있을 때 이를 적용하기 위해 render 설정 날려주기
        }
      }
    }

    //
    //
    // cornerstoneTools.clearToolState(this.element, "stack");
    // cornerstoneTools.addToolState(this.element, "stack", stack);
    // const imageId = stack.imageIds[stack.currentImageIdIndex];
    // scrollToIndex(this.element, stack.currentImageIdIndex);
    //
    // cornerstoneTools.stopClip(this.element);
    // const image = await cornerstone.loadAndCacheImage(imageId);
    //
    // cornerstone.displayImage(this.element, image);
    // cornerstone.reset(this.element);
  }

}
export default CornerstoneElementPlain;
