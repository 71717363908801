
import {useState, useEffect} from 'react';
import {Checkbox, Modal, Select, Table, Space} from 'antd';
const ModalAskPatientID = props => {
  const {visible, source, onOk, onCancel} = props.data
  const [mappings, setMappings] = useState()
  useEffect(() => {
    if (source) {
      setMappings(source)
    }
  }, [source])

  const handleSelectChange = record => (value, option) => {
    const newMappings = [...mappings]
    const newRecord = newMappings.find(mapping => mapping === record)
    newRecord.selected = value
    setMappings(newMappings)
  }
  return (
    <Modal
      title='Please select new patient ID foreach study'
      open={visible}
      onOk={() => onOk(mappings)}
      onCancel={onCancel}
      centered
      width='fit-content'
      destroyOnClose={true}
    >
      <Space direction="vertical" style={{ width: "100%", justifyContent: "right" }}>
        <Table
          size="small"
          columns={[
            {
              title: 'Current Patient ID',
              dataIndex: 'study',
              key: 'pid',
              render: (record) => record.pid,
              align: 'center'
            },
            {
              title: '',
              render: () => '\u27f6',
              align: 'center'
            },
            {
              title: 'New Patient ID',
              key: 'newPid',
              render: (text, record, index) => {
                return <Select
                  style={{ width: '100%'}}
                  placeholder="Select here"
                  dropdownMatchSelectWidth={false}
                  value={record?.selected}
                  options={record.optionsFull.map(dir => ({label: dir, value: dir}))}
                  onChange={handleSelectChange(record)}
                />
              }
            },
            {
              title: 'Study ID',
              dataIndex: 'study',
              key: 'studyId',
              render: (record) => record.sid
            },
            {
              title: 'Study Date',
              dataIndex: 'study',
              key: 'studyDate',
              render: (record) => record.date
            },
            {
              title: 'File path',
              dataIndex: 'study',
              key: 'studyPath',
              render: (record) => record.series[0].files[0].file.path
            },
          ]}
          dataSource={mappings}
        />
      </Space>
    </Modal>
  )
}

export default ModalAskPatientID;