import axios from "axios";
import { all, fork, getContext, put, takeLeading } from "redux-saga/effects";
import * as actions from '../../redux/modules/config'
import {updateSuccess, updateFail} from '../../redux/modules/config'


function updateApi(payload) {
  return axios.post('/api/updateConfig', payload, {withCredentials: true})
}

function* updateSaga({payload}) {
  try {
    const fromUserSettingPage = !payload.status
    
    if (payload.status) {
      delete payload.status
    }
    const res = yield updateApi(payload)
    const data = res.data
    
    yield put(updateSuccess(data))
    
    if (fromUserSettingPage) {
      const history = yield getContext('history');
      history.push(data.landing_url)
    }
  }
  catch (error) {
    yield put(updateFail())
  }
}

function* watchSave() {
  yield takeLeading(actions.UPDATE, updateSaga)
}

export default function* configSaga() {
  yield all([
    fork(watchSave)
  ])
}