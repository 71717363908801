import { Input, Button, Form } from "antd"
import { memo, useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePreviewOpen, updateReportPreview, updateReportTemplateModalOpen, clearLoadedReportTemplate } from "../../redux/modules/pipeline";
import { initialState, reportReducer, updateName, loadTemplate, updateReport } from "./reportReducer";
import { getConvertedReportTemplate, reportContext, taskPropsContext } from "./utils";
import Pages from "./pages";
import PreviewModal from "./previewModal";
import ReportTemplateModal from "./reportTemplateModal";

const MemoizedInput = memo(Input)
const MemoizedPreviewModal = memo(PreviewModal)

const ReportConfig = ({name, value, candidates, setFieldsValue, inManageReportPage=false, loadedTemplate, readOnly, imageTypeList: propImageTypeList, longitudinal=true}) => {
  const dispatch = useDispatch()
  const storeImageTypeList = useSelector(state => state.blobTypes?.list);
  const imageTypeList = propImageTypeList || storeImageTypeList;
  const [report, dispatchReport] = useReducer(
    reportReducer,
    {
      ...initialState,
      name: inManageReportPage ? undefined : initialState.name
    }
  );
  
  useEffect(() => {
    if (value) {
      dispatchReport(loadTemplate(value))
    }
    return () => dispatch(updatePreviewOpen(false))
  }, [])

  useEffect(() => {
    if (
      value && inManageReportPage && 
      (
        value.name !== report.name ||
        value.desc !== report.desc
      )
    ) {
      dispatchReport(updateReport({
        name: value?.name,
        desc: value?.desc
      })) 
    }
  },[value])

  useEffect(() => {
    if (loadedTemplate) {
      let payload
      if (inManageReportPage) {
        const template = getConvertedReportTemplate(loadedTemplate, candidates, imageTypeList)
        payload = {
          ...template,
          desc: loadedTemplate?.desc
        }
      }
      else {
        payload = getConvertedReportTemplate(loadedTemplate, candidates, imageTypeList)
      }
      dispatchReport(updateReport(payload)) 
    }
    return () => dispatch(clearLoadedReportTemplate())
  },[loadedTemplate])
  
  const onUpdate = useCallback(() => dispatch(updateReportPreview(true)), [])
  
  const {pages} = report

  const updateReportName = e => {
    const payload = e.target.value
    dispatchReport(updateName(payload))
  }

  const onTaskConfigUpdate = report => {
    setFieldsValue && setFieldsValue({[name]: report});
  }

  useEffect(() => {
    onTaskConfigUpdate(report)
    onUpdate()
  },[report])
  
  return (
    <div style={{position: 'relative'}} className="page">
      {!inManageReportPage &&
        <Form.Item
          label={'report name'}
          required={true}
          labelCol={{span: 6}}
          wrapperCol={{span: 18}}
        >
          <MemoizedInput
            value={report.name}
            onChange={updateReportName}
            disabled={readOnly}
            addonAfter={!readOnly && <PresetAddon/>}
          />
        </Form.Item>
      }
      <reportContext.Provider value={{dispatchReport}}>
        <taskPropsContext.Provider value={{candidates, imageTypeList, longitudinal}}>
          <Pages pages={pages} readOnly={readOnly}/>
        </taskPropsContext.Provider>
      </reportContext.Provider>
      <MemoizedPreviewModal 
        page={pages?.find(page => page?.active)}
        candidates={candidates}
        longitudinal={longitudinal}
      />
    </div>
  )
}

export default ReportConfig

const PresetAddon = () => {
  const dispatch = useDispatch()
  const openReportTemplateModal = useSelector(state => state.pipeline?.reportTemplate?.open)

  const onOpenLoadModal = () => dispatch(updateReportTemplateModalOpen(true))
  
  return (
    <>
      <Button onClick={onOpenLoadModal}>Load</Button>
      {openReportTemplateModal && 
        <ReportTemplateModal hideAction={true} />
      }
    </>
  )
}