export const GET_LIST = "notice/GET_LIST"
export const GET_LIST_SUCCESS = 'notice/GET_LIST_SUCCESS'
export const GET_LIST_FAIL = 'notice/GET_LIST_FAIL'
export const GET_DETAIL = 'notice/GET_DETAIL'
export const GET_DETAIL_SUCCESS = 'notice/GET_DETAIL_SUCCESS'
export const GET_DETAIL_FAIL = 'notice/GET_DETAIL_FAIL'
export const DEL = 'notice/DEL'
export const DEL_SUCCESS = 'notice/DEL_SUCCESS'
export const DEL_FAIL = 'notice/DEL_FAIL'
export const PUB_EDIT = 'notice/PUBLISH'
export const PUB_EDIT_SUCCESS = 'notice/PUBLISH_SUCCESS'
export const PUB_EDIT_FAIL = 'notice/PUBLISH_FAIL'
export const CLEAR = "notice/CLEAR"



const initialState = {
  loading : false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_LIST_SUCCESS:
      return {
        ...state,
        loading : false,
        list : action.payload.list,
        article : undefined,
        total : action.payload.total
      }
    case GET_LIST_FAIL:
      return {
        ...state,
        loading : false
      }
    case GET_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_DETAIL_SUCCESS:
      return {
        ...state,
        loading : false,
        article : action.payload,
      }
    case GET_DETAIL_FAIL:
      return {
        ...state,
        loading : false
      }
    case DEL:
      return {
        ...state,
        loading: true
      }
    case DEL_SUCCESS:
      return {
        ...state,
        loading : false,
        article : undefined,
        list : action.payload.list,
        total : action.payload.total
      }
    case DEL_FAIL:
      return {
        ...state,
        loading : false,
      }
    case PUB_EDIT:
      return {
        ...state,
        loading : true
      }
    case PUB_EDIT_SUCCESS:
      return {
        ...state,
        loading : false,
        article : action.payload
      }
    case PUB_EDIT_FAIL:
      return {
        ...state,
        loading : false
      }
    case CLEAR:
      return initialState

    default:
      return state;
  }
}

export function getList(payload) {
  return {type : GET_LIST, payload}
}

export function getListSuccess(payload) {
  return {type : GET_LIST_SUCCESS, payload}
}

export function getListFail(payload) {
  return {type : GET_LIST_FAIL, payload}
}

export function getDetail(payload) {
  return {type : GET_DETAIL, payload}
}

export function getDetailSuccess(payload) {
  return {type : GET_DETAIL_SUCCESS, payload}
}

export function getDetailFail(payload) {
  return {type : GET_DETAIL_FAIL, payload}
}

export function del(payload) {
  return {type : DEL, payload}
}

export function delSuccess(payload) {
  return {type : DEL_SUCCESS, payload}
}

export function delFail(payload) {
  return {type : DEL_FAIL, payload}
}

export function pubEdit(payload) {
  return {type : PUB_EDIT, payload}
}

export function pubEditSuccess(payload) {
  return {type : PUB_EDIT_SUCCESS, payload}
}

export function pubEditFail(payload) {
  return {type : PUB_EDIT_FAIL, payload}
}

export function clear(payload) {
  return {type : CLEAR, payload}
}