import {delay, take, select, all, fork, put, takeEvery} from "redux-saga/effects";
import {channel} from "redux-saga";
import * as actions from "../../redux/modules/scatter";
import {generate} from "../../redux/modules/scatter";

// function getPresignedUrlApi(run_id, pid, sid) {
//   const csrf_access_token = getCookie('csrf_access_token')
//   axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
//   return axios.post('/api/verify/presigned',
//     {'pid': pid, 'sid': sid, 'run_id': run_id},
//     {withCredentials: true});
// }
//
// function* getPresignedUrlSaga({ payload }) {
//   try {
//     const { run_id, pid, sid } = payload;
//     const { data } = yield call(getPresignedUrlApi, run_id, pid, sid);
//     yield put(getUrlSuccess(data));
//     const history = yield getContext('history');
//     history.push('view-volume')
//   } catch (error) {
//     yield put(getUrlFail());
//   }
// }

export const getData1 = (state) => state.scatter.data1
export const getData2 = (state) => state.scatter.data2
export const getSegmenation = (state) => state.scatter.segmentation

function* afterCalcSaga(traces, filteredIndex) {
  yield put(generate({
    // data: Object.values(traces),
    data: Object.values(traces),
    filteredIndexes: filteredIndex
  }))
}

function* handleBuildDone(chan) {
  while (true) {
    yield delay(100)
    const {traces, filteredIndex}= yield take(chan)
    yield put(generate({
      data: Object.values(traces),
      filteredIndexes: filteredIndex
    }))
  }
}

function* requestSaga({payload}) {
  yield all([
    take(actions.COLLECT_DATA),
    take(actions.COLLECT_SEG)
  ]);
  let data1 = yield select(getData1);
  let data2 = yield select(getData2);
  let seg = yield select(getSegmenation);
  if (data2 === null) {
    yield take(actions.COLLECT_DATA)
    data2 = yield select(getData2);
  }

  // const filtered = new Uint16Array(seg.data.size);
  // const filteredIndex =  new Uint32Array(seg.data.size);
  const chan = yield channel()
  yield fork(handleBuildDone, chan)
  const myWorker = new Worker(new URL("../../workers/scatter.worker.js", import.meta.url));
  // myWorker.postMessage([data1, data2, seg, filtered, filteredIndex]);
  myWorker.postMessage([data1, data2, seg, payload]);
  myWorker.onmessage = function (e) {
    // const {traces, filteredIndex} = e.data;
    chan.put(e.data)
    myWorker.terminate()
    //
    // afterCalcSaga(traces, filteredIndex)

    // result.textContent = e.data;
  }
}
function* watchGenerate() {
  yield takeEvery(actions.REQUEST, requestSaga);
}

export default function* verifySaga() {
  yield all([
    fork(watchGenerate),
  ]);
}