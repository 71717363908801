import React, { useEffect, useState } from "react";
import { Button, Table, Space, Tag, Popconfirm, Drawer } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { updateMsgList, toggleOpen } from "../redux/modules/message";
import { useTableSearchContext } from "../context/searchInResultContext";
import { changePerPage } from "../redux/modules/config";
import { useSearchHighlight } from "../context/searchHighlightContext";
import CreatedRender from "./createdRender";

function MessageDrawer() {
  const dispatch = useDispatch()
  const msgList = useSelector(state=>state.message.list)
  const msgOpen = useSelector(state=>state.message.open)
  const per_page = useSelector(state => state.config.per_page) || 10
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [sortedInfo, setSortedInfo] = useState({});
  const [data, setData] = useState([])
  const {getColumnSearchProps} = useTableSearchContext()
  const {searchHighlight} = useSearchHighlight({})

  useEffect(() => {
    const newList = msgList.map((msg, index) => {
      return {
        ...msg,
        key : index
      }
    })
    setData(newList)
    setSelectedRowKeys([])
  },[msgList])

  const onTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const onPaginationChange = (page, pageSize) => {
    setSelectedRowKeys([])
    if (pageSize !== per_page) {
      dispatch(changePerPage(pageSize))
    }
  }

  const rowSelection = {
    selectedRowKeys : selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const showSorterTooltip = key => {
    const status = sortedInfo.order
    const tooltipProps = {
      title : 'Click to sort ascending',
      mouseEnterDelay: 0.5,
      mouseLeaveDelay: 0,
    }
    if (sortedInfo.columnKey !== key) {
      return tooltipProps
    }
    if (status === 'ascend') {
      tooltipProps.title = 'Click to sort descending'
    }
    if (status === 'descend'){
      tooltipProps.title = 'Click to cancel sorting'
    }
    return tooltipProps
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '150px',
      align : 'center',
      filters: Array.from(new Set(msgList.map(msg => msg.type))).map(type => {
        return {text : type, value : type}
      }),
      onFilter: (value, record) => record.type.includes(value),
      sorter: (a, b) => a.type.length - b.type.length,
      sortOrder: sortedInfo.columnKey === 'type' ? sortedInfo.order : null,
      ellipsis: true,
      showSorterTooltip : showSorterTooltip('type'),
      render : record => {
        return <Tag color={record}>{record}</Tag>
      }
    },
    {
      ...getColumnSearchProps('content'),
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      ellipsis: {showTitle : false},
      // render: text => searchHighlight(text, 'content')
    },
    {
      title: 'created',
      dataIndex: 'addedTime',
      key: 'addedTime',
      align : 'center',
      sorter: (a, b) => new Date(a.addedTime).getTime() - new Date(b.addedTime).getTime(),
      sortOrder: sortedInfo.columnKey === 'addedTime' ? sortedInfo.order : null,
      ellipsis: true,
      showSorterTooltip : showSorterTooltip('addedTime'),
      width : '220px',
      render : date => <CreatedRender date={date} full={true}/>
    }
  ];

  const onClose = () => dispatch(toggleOpen())
  const onRemove = () => {
    const filteredData = data.filter(msg => !selectedRowKeys.includes(msg.key))
    dispatch(updateMsgList(filteredData))
  }

  return (
    <Drawer
      title={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Message</h3>
          <Popconfirm
            title={"Are you sure?"}
            icon={<QuestionCircleOutlined style={{color : 'red'}}/>}
            onConfirm={onRemove}
          >
            <Button
            type='primary'
            disabled={selectedRowKeys.length === 0}
            >Remove</Button>
          </Popconfirm>
        </Space>
      }
      placement="right"
      onClose={onClose}
      open={msgOpen}
      closable={false}
      size='large'
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <Table
          pagination={{
            pageSize: per_page,
            total: msgList.length,
            showSizeChanger: true,
            onChange : onPaginationChange
          }}
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          rowKey={'key'}
          onChange={onTableChange}
        />
      </div>
    </Drawer>
  );
}

export default MessageDrawer;

