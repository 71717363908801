import React, {useEffect} from 'react'
import {Route, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {useCookies} from 'react-cookie'
import {getCookie} from "../lib/cookie";

// https://stackoverflow.com/questions/52447828/is-there-a-way-to-modify-the-page-title-with-react-router-v4
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.auth.user);
  const [tokens, setTokens ] = useCookies();
  // useEffect(() => {
  //   const token = getCookie('csrf_access_token');
  //
  // });
  return (
    <Route
      {...rest}
      render={({ location }) =>
        'csrf_access_token' in tokens ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;