export const LOAD = 'patient/LOAD';
export const LOAD_SUCCESS = 'patient/LOAD_SUCCESS';
export const LOAD_FAIL = 'patient/LOAD_FAIL';
export const GET = 'patient/GET';
export const GET_SUCCESS = 'patient/GET_SUCCESS';
export const GET_FAIL = 'patient/GET_FAIL';
export const UPDATE = 'patient/UPDATE';
export const UPDATE_SUCCESS = 'patient/UPDATE_SUCCESS';
export const UPDATE_FAIL = 'patient/UPDATE_FAIL';
export const DEL_PATIENTS = 'patient/DEL_PATIENTS'
export const DEL_PATIENTS_SUCCESS = 'patient/DEL_PATIENTS_SUCCESS'
export const DEL_PATIENTS_FAIL = 'patient/DEL_PATIENTS_FAIL'
export const DEL_PATIENT_FILE = 'patient/DEL_PATIENT_FILE'
export const DEL_PATIENT_FILE_SUCCESS = 'patient/DEL_PATIENT_FILE_SUCCESS'
export const DEL_PATIENT_FILE_FAIL = 'patient/DEL_PATIENT_FILE_FAIL'
export const CLEAR = 'patient/CLEAR'
export const GET_STUDIES = 'patient/GET_STUDIES'
export const GET_STUDIES_SUCCESS = 'patient/GET_STUDIES_SUCCESS'
export const GET_STUDIES_FAIL = 'patient/GET_STUDIES_FAIL'
export const DEL_ASK = 'patient/DEL_ASK'
export const DEL_ASK_OK = 'patient/DEL_ASK_OK'
export const DEL_ASK_CANCEL = 'patient/DEL_ASK_CANCEL'
export const UPDATE_FINISHED_DATA = 'patient/UPDATE_FINISHED_DATA'
export const OPEN_MERGE_MODAL = 'patient/OPEN_MERGE_MODAL'
export const CLOSE_MERGE_MODAL = 'patient/CLOSE_MERGE_MODAL'
export const MERGE_PATIENT = 'patient/MERGE_PATIENT'
export const MERGE_PATIENT_SUCCESS = 'patient/MERGE_PATIENT_SUCCESS'
export const MERGE_PATIENT_FAIL = 'patient/MERGE_PATIENT_FAIL'
export const MERGE_ASK = 'patient/MERGE_ASK'
export const MERGE_ASK_OK = 'patient/MERGE_ASK_OK'
export const MERGE_ASK_CANCEL = 'patient/MERGE_ASK_CANCEL'
export const ADD = 'patient/ADD'
export const ADD_SUCCESS = 'patient/ADD_SUCCESS'
export const ADD_FAIL = 'patient/ADD_FAIL'
export const GET_DOWNLOAD_TARGETS = 'patient/GET_DOWNLOAD_TARGETS'
export const GET_DOWNLOAD_TARGETS_SUCCESS = 'patient/GET_DOWNLOAD_TARGETS_SUCCESS'
export const GET_DOWNLOAD_TARGETS_FAIL = 'patient/GET_DOWNLOAD_TARGETS_FAIL'
export const CLEAR_DOWNLOAD_TARGETS = 'patient/CLEAR_DOWNLOAD_TARGETS'

const initialState = {
  loaded: false,
  loading : false,
  patient : undefined,
  patients: [],
  studies : [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        patients: action.payload.list,
        total: action.payload.total,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case GET:
      return {
        ...state,
        loading: true,
      };
    case GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        patient: action.payload.patient,
        studies: action.payload.studies.map(s => ({
          ...s,
          seriesList: s.series_list,
          series_list: undefined
        }))
      };
    }
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case UPDATE:
      return {
        ...state,
        loading: true
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    
    case DEL_PATIENTS:
      return {
        ...state,
        loading : true,
      }
    case DEL_PATIENTS_SUCCESS:
      return {
        ...state,
        loading : false,
        loaded: true,
        patients : action.payload.list,
        deleted: action.payload.deleted
      }
    case DEL_PATIENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case DEL_PATIENT_FILE:
      return {
        ...state,
        loading : true,
      }
    case DEL_PATIENT_FILE_SUCCESS:
      return {
        ...state,
        loading : false,
        loaded: true,
        patient : action.payload.patient,
        studies : action.payload.studies
      }
    case DEL_PATIENT_FILE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case CLEAR:
      return initialState
    case GET_STUDIES:
      return {
        ...state,
        loading : true,
      }
    case GET_STUDIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        studies: action.payload.studies.map(s => ({
          ...s,
          seriesList: s.series_list,
          series_list: undefined
        }))
      };
    }
    case GET_STUDIES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case DEL_ASK:
      return {
        ...state,
        loading : false,
        delAsk : {
          affectedList : action.payload.affected_list,
          targetData : action.payload.target_data,
          modalVisible : true,
        }
      }
    case DEL_ASK_OK:
      return {
        ...state,
        delAsk : undefined
      }
    case DEL_ASK_CANCEL:
      return {
        ...state,
        loading : false,
        delAsk : undefined,
      }
    case UPDATE_FINISHED_DATA:
      {
        const finishedData = action.payload
        const uploadFinished = state?.uploadFinished || {dicom: [], nifti: []}
        if (finishedData.series_id) {
          uploadFinished.dicom = [...uploadFinished.dicom, finishedData]
        }
        else {
          uploadFinished.nifti = [...uploadFinished.nifti, finishedData]
        }

        return {
          ...state,
          uploadFinished
        }  
      }
    case OPEN_MERGE_MODAL:
      return {
        ...state,
        openMerge: true
      }
    case CLOSE_MERGE_MODAL:
      return {
        ...state,
        openMerge: undefined
      }
    case MERGE_PATIENT:
      return {
        ...state,
        loading: true,
        openMerge: undefined
      }
    case MERGE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        patients: action.payload.list,
        total: action.payload.total,
        openMerge: undefined,
        merged: action.payload.merged
      }
    case MERGE_PATIENT_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      }
    case MERGE_ASK:
      return {
        ...state,
        loading : false,
        mergeAsk : {
          affectedList : action.payload.affected_list,
          request: action.payload.request,
          modalVisible : true,
        }
      }
    case MERGE_ASK_OK:
      return {
        ...state,
        mergeAsk : undefined
      }
    case MERGE_ASK_CANCEL:
      return {
        ...state,
        loading : false,
        mergeAsk : undefined,
      }
    case ADD:
      return {
        ...state,
        loading: true
      }
    case ADD_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case ADD_FAIL:
      return {
        ...state,
        loading: false
      }
    case GET_DOWNLOAD_TARGETS:
      return {
        ...state,
        loading: true
      }
    case GET_DOWNLOAD_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadTargets: action.payload 
      }
    case GET_DOWNLOAD_TARGETS_FAIL:
      return {
        ...state,
        loading: false
      }
    case CLEAR_DOWNLOAD_TARGETS:
      return {
        ...state,
        downloadTargets: undefined
      }
    default:
      return state;
  }
}

export function load(payload) {
  return {type: LOAD, payload};
}

export function loadSuccess(payload) {
  return {type: LOAD_SUCCESS, payload};
}

export function loadFail(payload) {
  return {type: LOAD_FAIL, payload};
}

export function get(payload) {
  return {type: GET, payload};
}

export function getSuccess(payload) {
  return {type: GET_SUCCESS, payload};
}

export function getFail(payload) {
  return {type: GET_FAIL, payload};
}

export function update(payload) {
  return {type: UPDATE, payload};
}

export function updateSuccess(payload) {
  return {type: UPDATE_SUCCESS, payload};
}

export function updateFail(payload) {
  return {type: UPDATE_FAIL, payload};
}

export function delPatients(payload) {
  return {type : DEL_PATIENTS, payload}
}

export function delPatientsSuccess(payload) {
  return {type : DEL_PATIENTS_SUCCESS, payload}
}

export function delPatientsFail(payload) {
  return {type : DEL_PATIENTS_FAIL, payload}
}

export function delPatientFile(payload) {
  return {type : DEL_PATIENT_FILE, payload}
}

export function delPatientFileSuccess(payload) {
  return {type : DEL_PATIENT_FILE_SUCCESS, payload}
}

export function delPatientFileFail(payload) {
  return {type : DEL_PATIENT_FILE_FAIL, payload}
}

export function clear(payload) {
  return {type : CLEAR, payload}
}

export function getStudies(payload) {
  return {type : GET_STUDIES, payload}
}

export function getStudiesSuccess(payload) {
  return {type : GET_STUDIES_SUCCESS, payload}
}

export function getStudiesFail(payload) {
  return {type : GET_STUDIES_FAIL, payload}
}

export function delAsk(payload) {
  return {type : DEL_ASK, payload}
}

export function delAskOk(payload) {
  return {type:DEL_ASK_OK, payload}
}

export function delAskCancel(payload) {
  return {type : DEL_ASK_CANCEL, payload}
}

export function updateFinishedData(payload) {
  return {type: UPDATE_FINISHED_DATA, payload}
}

export function openMergeModal() {
  return {type: OPEN_MERGE_MODAL}
}

export function closeMergeModal() {
  return {type: CLOSE_MERGE_MODAL}
}

export function mergePatient(payload) {
  return {type: MERGE_PATIENT, payload}
}

export function mergePatientSuccess(payload) {
  return {type: MERGE_PATIENT_SUCCESS, payload}
}

export function mergePatientFail(payload) {
  return {type: MERGE_PATIENT_FAIL, payload}
}

export function mergeAsk(payload) {
  return {type: MERGE_ASK, payload}
}

export function mergeAskOk(payload) {
  return {type: MERGE_ASK_OK, payload}
}

export function mergeAskCancel(payload) {
  return {type: MERGE_ASK_CANCEL, payload}
}

export function add(payload) {
  return {type: ADD, payload}
}

export function addSuccess(payload) {
  return {type: ADD_SUCCESS, payload}
}

export function addFail(payload) {
  return {type: ADD_FAIL, payload}
}

export function getDownloadTargets(payload) {
  return {type: GET_DOWNLOAD_TARGETS, payload}
}

export function getDownloadTargetsSuccess(payload) {
  return {type: GET_DOWNLOAD_TARGETS_SUCCESS, payload}
}

export function getDownloadTargetsFail(payload) {
  return {type: GET_DOWNLOAD_TARGETS_FAIL, payload}
}

export function clearDownloadTargets(payload) {
  return {type: CLEAR_DOWNLOAD_TARGETS, payload}
}
