export const SUCCESS = 'message/SUCCESS'
export const INFO = 'message/INFO'
export const WARN = 'message/WARN'
export const ERROR = 'message/ERROR'
export const SUCCESS_AND_CB = 'message/SUCCESS_AND_CB'
export const INFO_AND_CB = 'message/INFO_AND_CB'
export const ERROR_AND_CB = 'message/ERROR_AND_CB'
export const UPDATE_MSG_LIST = 'message/UPDATE_MSG_LIST'
export const TOGGLE_OPEN = 'message/TOGGLE_OPEN'
export const CLEAR = 'message/CLEAR'

export function buildErrorMessage(content) {
  if (typeof(content) === 'object' && (content?.response?.data?.msg || content?.message)) {
    return 'Error: ' + (content.response?.data?.msg || content.message)
  } 
  return content
}

function buildMessage(type, content) {
  const date = new Date().toISOString() // save utc time
  const formattedDate = date.replace('T',' ').replace('Z','')
  return {
    type : type,
    content : content,
    addedTime : formattedDate,
  }
}

const initialState = {
  list : [],
  open: false
}

export default function reducer(state=initialState, action={}) {
  switch (action.type) {
    case SUCCESS:
    case INFO:
    case WARN:
    case ERROR:
    case SUCCESS_AND_CB:
    case INFO_AND_CB:
    case ERROR_AND_CB:
      const newList = [...state.list]
      const msgForm = buildMessage(action.payload.type, action.payload.content)
      newList.unshift(msgForm)
      return {
        ...state,
        list : newList
      }
    case UPDATE_MSG_LIST:
      return {
        ...state,
        list : action.payload
      }
    case TOGGLE_OPEN:
      return {
        ...state,
        open: !state.open
      }
    case CLEAR:
      return initialState
    default:
      return state
  }
}

export function msgSuccess(content) {
  return {type : SUCCESS, payload : { type : 'success', content : buildErrorMessage(content)}}
}

export function msgInfo(content) {
  return {type : INFO, payload : { type : 'info', content : buildErrorMessage(content)}}
}

export function msgWarn(content) {
  return {type : WARN, payload : { type : 'warn', content : buildErrorMessage(content)}}
}

export function msgError(content) {
  return {type : ERROR, payload : { type : 'error', content : buildErrorMessage(content)}}
}

export function msgSuccessAndCb({content,cb}) {
  return {type : SUCCESS_AND_CB, payload : { type : 'success', content : buildErrorMessage(content), cb : cb}}
}

export function msgInfoAndCb({content,cb}) {
  return {type : INFO_AND_CB, payload : { type : 'info', content : buildErrorMessage(content), cb : cb}}
}

export function msgErrorAndCb({content,cb}) {
  return {type : ERROR_AND_CB, payload : { type : 'error', content : buildErrorMessage(content), cb : cb}}
}

export function updateMsgList(payload) {
  return {type : UPDATE_MSG_LIST, payload}
}

export function toggleOpen() {
  return {type : TOGGLE_OPEN}
}

export function clear() {
  return {type: CLEAR}
}