import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useGetImageTypeHooks } from "./useImageTypeHooks";
import { getSeriesUrl, getNiftiUrl, clearUrl } from "../redux/modules/view";
import { msgError } from "../redux/modules/message";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneNIFTIImageLoader from "cornerstone-nifti-image-loader";
import CornerstoneElementPlain from "../components/cornerstoneElementPlain";
import { Button, Modal, Space } from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import * as cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import {buildDicomStack} from "../lib/UtilDicomParse";

const initialState = {
  count : 0,
  totalCount : 0,
  loading : false
}

const init = (args) => {
  return {
    ...initialState,
    ...args
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading : true,
        totalCount : action.payload
      }
    case 'LOAD_SUCCESS':
      return {
        ...state,
        loading : false,
        count : action.payload,
        totalCount : action.payload,
      }
    case 'UPDATE':
      return {
        ...state,
        count : action.payload
      }
    case 'CLEAR':
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const useImageViewHooks = ({TYPE_NAME}) => {
  const dispatch = useDispatch()
  const urls = useSelector(state => state.view.list); 
  const patient = useSelector(state => state.patient.patient)
  const [stack, setStack] = useState({ imageIds: [], currentImageIdIndex: 0 });
  const {getTypeFromTypeId} = useGetImageTypeHooks()
  const targetView = useRef("");
  const [showImageViewModal, setShowImageViewModal] = useState(false);
  const [modalColormap, setModalColormap] = useState("gray");
  const [modalLoading, setModalLoading] = useState(false);
  const [viewState, viewDispatch] = useReducer(reducer, {}, init);

  useEffect(() => {
    if (urls.length === 0) {
      setModalLoading(false)
      if (targetView.current !== "") {
        dispatch(msgError(`There is no image (${targetView.current.info})`))
      }
      return ;
    } else {
      if (targetView.current.type === "series") {
        seriesView(urls);
      } else if (targetView.current.type === "nifti") {
        niftiView(urls);
      }
    }
  }, [urls]);

  const handleOk = () => {
    setShowImageViewModal(false);
    targetView.current = "";
    dispatch(clearUrl())
    viewDispatch({type: 'CLEAR'})
  };

  const handleUploadedSeriesView = record => {
    setModalLoading(true);

    const currImageType = getTypeFromTypeId(record.blobtype_id)
    setModalColormap(currImageType.cmap_id)
    targetView.current = {
      type : "series",
      info : `Series Number : ${record.sn}, Protocol : ${record.protocol}`
    }
    dispatch(getSeriesUrl({
      pid: patient.id,
      sid: record.sid,
      sn: record.id,
    }))
  }
  const handleUploadedBlobView = record => {
    setModalLoading(true);

    const currImageType = getTypeFromTypeId(record.blobtype_id)
    setModalColormap(currImageType.cmap_id)
    targetView.current = {
      type: "nifti",
      info: `filename : ${record.fname.split('/').pop()}`
    }
    dispatch(getNiftiUrl({path: record.s3key}));
  }

  async function seriesView(urls) {
    try {
      viewDispatch({type: 'LOAD', payload: urls.length})
  
      let count = 0
      let updated = new Date().getTime()
  
      async function getImageId(url) {
        const image = await cornerstone.loadAndCacheImage(`wadouri:${url}`)
  
        count +=1
        const now = new Date().getTime()
  
        if (now - updated > 50 && count !== viewState.count) {
          viewDispatch({type: 'UPDATE', payload: count})
          updated = new Date().getTime()
        }
  
        // 'x00200013' dicom 정보 => Image Number
        // TODO 나중에 다른 Dicom header 들에 대한 정보들도 확인해서 해당 dicom 정보가 항상 있는건지 확인해야 한다.
        return {
          imageId: image.imageId,
          slideNumber: image.data.string("x00200013"),
          frame: image.data.intString("x00280008"),
        }
      }

      const stack = await buildDicomStack(urls, getImageId)
      setStack(stack);
      setShowImageViewModal(true);
      setModalLoading(false)
      viewDispatch({type: 'LOAD_SUCCESS', payload: urls.length})
    } catch (error) {
      const desc = 'Unable to read'
      dispatch(msgError(desc))
      handleOk()
    }
  }

  async function niftiView(urls) {
    try {
      const niftiPath = `nifti:${urls[0]}`;
      const image = await cornerstone.loadAndCacheImage(niftiPath);
      const multiFrameModule = cornerstone.metaData.get(
        "multiFrameModule",
        image.imageId
      );
      const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
      const imageIdObject = ImageId.fromURL(image.imageId);
      const numberOfSlices = multiFrameModule.numberOfFrames;
      const imageIds = Array.from(
        Array(numberOfSlices),
        (_, i) =>
          `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i}`
      );
      const stack = {
        imageIds: imageIds,
        currentImageIdIndex: Math.floor(numberOfSlices / 2) || 0,
      };
      setStack(stack);
      setShowImageViewModal(true);
      setModalLoading(false)
    } catch (error) {
      const desc = 'Unable to read'
      dispatch(msgError(desc))
      handleOk()
    }
  }

  const handleNewSeriesView = record => {
    const imageIds = record.files.map(f => cornerstoneWADOImageLoader.wadouri.fileManager.add(f.file));
    const stack = { imageIds: imageIds, currentImageIdIndex: Math.floor(imageIds.length/2) }; // 1장인 경우 imageIds[1]로 오류 발생으로 올림(Round)가 아닌 내림(Floor)로 수정진행
    setStack(stack);

    const currImageType = getTypeFromTypeId(record.type)
    currImageType ? setModalColormap(currImageType.cmap_id) : setModalColormap("gray")
    setShowImageViewModal(true);
  }

  const handleNewblobiView = async record => {
    
    cornerstoneNIFTIImageLoader.nifti.localMode = false;
    
    const tmpURL = URL.createObjectURL(record.file);
    const niftiPath = `nifti:${tmpURL}`;

    const image = await cornerstone.loadAndCacheImage(niftiPath);
    const multiFrameModule = cornerstone.metaData.get('multiFrameModule', image.imageId)
    if (multiFrameModule !== undefined) {
      const ImageId = cornerstoneNIFTIImageLoader.nifti.ImageId;
      const imageIdObject = ImageId.fromURL(image.imageId);
      const numberOfSlices = multiFrameModule.numberOfFrames;
      const imageIds = Array.from(Array(numberOfSlices), (_, i) => `nifti:${imageIdObject.filePath}#${imageIdObject.slice.dimension}-${i}`)
      const stack = {
        imageIds: imageIds,
        currentImageIdIndex: Math.floor(numberOfSlices / 2) || 0,
      };
      setStack(stack);
      const currImageType = getTypeFromTypeId(record.type)
      currImageType ? setModalColormap(currImageType.cmap_id) : setModalColormap("gray")
      setShowImageViewModal(true);
      window.URL.revokeObjectURL(tmpURL);
    }
    else {
      dispatch(msgError('Unable to load image'))
    }
  }

  const onViewClick = record => {
    if (record.id) {
      if (record.type === TYPE_NAME.DICOM) {
        return handleUploadedSeriesView(record)
      }
      else if (record.type === TYPE_NAME.NIFTI) {
        return handleUploadedBlobView(record)
      }
    }
    else {
      if (record.type === TYPE_NAME.DICOM) {
        return handleNewSeriesView(record)
      }
      else if (record.type === TYPE_NAME.NIFTI) {
        return handleNewblobiView(record)
      }
    }
  }

  const ViewBtn = ({record}) => {
    const recordType = record.type === TYPE_NAME.DICOM ? 'dicom' : 'nifti'
    const uploadFinishedData = useSelector(state => state.patient?.uploadFinished?.[recordType]?.filter(d => d.pid === patient.id))?.map(d => d.id) || []

    const [viewPossible, setViewPossible] = useState(false)
    
    useEffect(() => {
      setViewPossible(checkViewPossible())
    },[uploadFinishedData])
    
    const checkViewPossible = () => {
      if (record.upload_finished) return true
      if (record.new) return true
      const possible = uploadFinishedData?.includes(record.id)
      if (possible) {
        record.upload_finished = true
        return true
      }
      return false
    }

    return (
      <Space>
        {(viewPossible || record.upload_finished || record.new) ?
          <Button onClick={() => onViewClick(record)}> View </Button>
            : <LoadingOutlined />}
      </Space>
    )
  }

  const ImageViewModal = () => {
    return (
      <Modal // Dicom Viewer Modal
        closable={false}
        open={showImageViewModal}
        onOk={handleOk}
        cancelButtonProps={{ style: { display: "none" } }}
        centered
        width='fit-content'
      >
        <CornerstoneElementPlain
          stack={stack}
          colormap={modalColormap}
        />
      </Modal>
    )
  }
  
  return {
    ViewBtn,
    modalLoading,
    viewState,
    ImageViewModal
  }
}