import { useCallback, useContext, useEffect, useState } from "react"
import { FormDataList, SETTING, SUMMARY_PADDING, SUMMARY_WIDTH, TRACK_IMAGE_TYPE, REPORT_TYPE, 
  getSettingFunction, reportContext, taskPropsContext, TRACK_IMAGE_INPUT_DATA, IMAGE_INFO, 
  getTrackImageTemplate, formatToLocalString } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import { updatePageTrackImageProperty } from "./reportReducer"
import { Button, Col, Image, Row, Space } from "antd"
import { updatePreviewOpen } from "../../redux/modules/pipeline"
import { TRACK_GRAPH_SETTING, TRACK_IMAGE_SETTING, TRACK_TITLE_SETTING, 
  TrackImageSettingBtn, getFontStyle, trackGraphSettings, trackImageSettings, 
  trackTitleSettings } from "./settings"
import Plot from "react-plotly.js"
import MRI_IMAGE from './brain-mri-blurred.png'

const LEFT_MARGIN = 50
const RIGHT_MARGIN = 50
const PADDING = 10
const STAND_OFF = 10

const TrackImage = ({defaultValue, readOnly, page}) => {
  const dispatch = useDispatch()
  
  const [copiedPage, setCopiedPage] = useState(page)

  useEffect(() => {
    setCopiedPage(page)
  },[page.key])

  const {dispatchReport} = useContext(reportContext)
  const {candidates, imageTypeList} = useContext(taskPropsContext)
  const open = useSelector(state => state?.pipeline?.preview_open)
  const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList, readOnly, defaultValue)

  const onUpdate = payload => dispatchReport(updatePageTrackImageProperty(payload))
  
  const togglePreview = () => dispatch(updatePreviewOpen(!open))

  const updateValue = useCallback((newPage=page) => {
    setCopiedPage(newPage)
  },[page])

  return (
    <div style={{position: 'relative'}}>
      {!readOnly && 
        <div style={{height: 35}}>
          <Space style={{float: 'right'}}>
            <div>
              <Button onClick={togglePreview}>
                Preview
              </Button>
              <TrackImageSettingBtn page={copiedPage} updateValue={updateValue}/>
            </div>
          </Space>
        </div>
      }
      <FormDataList 
        template={trackImageTemplate}
        value={defaultValue}
        extraMargin={0}
        onUpdate={onUpdate}
        readOnly={readOnly}
      />
    </div>
  )
}

export default TrackImage


export const TrackImagePreview = ({page, trackDataList=[], capture=false}) => {
  const {candidates} = useContext(taskPropsContext)
  const imageTypeList = useSelector(state => state.blobTypes?.list) || [];
  const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList)
  
  const trackImageValue = page[REPORT_TYPE.TRACK_IMAGE]
  
  const getTrackImageValue = getSettingFunction(trackImageValue, trackImageTemplate)
  
  const maximumColumnCount = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.MAXIMUM_COLUMN_COUNT)

  const lastDateData = trackDataList.at(-1)

  const settingValue = page?.[SETTING]
  const getSettingValue = getSettingFunction(settingValue, trackTitleSettings)
  const titleInfo = getSettingValue(TRACK_IMAGE_TYPE.TITLE)
  
  const getTitleInfoValue = getSettingFunction(titleInfo, trackTitleSettings)

  const viewTitle = getTitleInfoValue(TRACK_TITLE_SETTING.VIEW_TITLE)
  const title = getTitleInfoValue(TRACK_TITLE_SETTING.TITLE)
  const styleValues = getTitleInfoValue(TRACK_TITLE_SETTING.TITLE_STYLE)
  const fontStyle = getFontStyle(styleValues, TRACK_TITLE_SETTING.TITLE_STYLE, trackTitleSettings)

  const graphInfo = getSettingValue(TRACK_IMAGE_TYPE.GRAPH)
  const getGraphSettingValue = getSettingFunction(graphInfo, trackGraphSettings)
  const showGraph = getGraphSettingValue(TRACK_GRAPH_SETTING.SHOW)
  
  const summaryWidth = maximumColumnCount > 5 ? (SUMMARY_WIDTH + 200) : SUMMARY_WIDTH
  
  const totalWidth = capture
    ? summaryWidth - (LEFT_MARGIN + STAND_OFF + PADDING + RIGHT_MARGIN)
    : summaryWidth - (LEFT_MARGIN + STAND_OFF + PADDING + RIGHT_MARGIN + SUMMARY_PADDING * 2)

  const width = totalWidth / maximumColumnCount
  const height = width

  const graphFontStyle = getFontStyle(styleValues, TRACK_GRAPH_SETTING.FONT_STYLE, trackGraphSettings)
  const dateFontSize = graphFontStyle.fontSize
  const dateFontColor = graphFontStyle.color

  const imageInfo = getSettingValue(TRACK_IMAGE_TYPE.IMAGE)
  const getImageSettingValue = getSettingFunction(imageInfo, trackImageSettings)
  const numbering = getImageSettingValue(TRACK_IMAGE_SETTING.NUMBERING)
  
  const displayDateIndividually = getGraphSettingValue(TRACK_GRAPH_SETTING.DISPLAY_DATE_INDIVIDUALLY)

  
  return (
    <>
      {viewTitle && (
        <div style={{...fontStyle}}>{title}</div>
      )}
      {lastDateData?.trackInfo.map((trackInfo, index) => {
        const {locationId} = trackInfo
        const sameLocationList = trackDataList.map((trackData, ti) => {
          return {
            date: trackData.date,
            trackInfo: trackData?.trackInfo?.filter(trackInfo => trackInfo.locationId === locationId)
          }
        })
      
        const dateList = sameLocationList.map(d => d.date)
        const blankDataList = Array.from({length: maximumColumnCount - dateList.length})
        const imageInfoList = [
          ...blankDataList.map(d => null), 
          ...sameLocationList
        ]

        const showDate = displayDateIndividually ? true : index === 0
        return (
          <div>
            {!showGraph && showDate &&
              <Row style={{marginLeft: LEFT_MARGIN + STAND_OFF + PADDING, marginRight: RIGHT_MARGIN, marginBottom: 20}}>
                {imageInfoList.map(trackData => {
                  const date = trackData?.date
                  return (
                    <Col style={{width}}>
                      <div style={{fontSize: dateFontSize, color: dateFontColor}}>{date}</div>
                    </Col>
                  )
                })}
              </Row>
            }
            <div style={{position: 'relative'}}>
              {numbering &&
                <div 
                  style={{
                    position: 'absolute',
                    overflow: 'visible', 
                    float: 'left', 
                    padding: `${height/10}px 30px`,
                    height: '50px', // Adjust this value as needed
                    left: 80,
                    width: 0,
                    bottom: 0,
                    fontSize: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1,
                  }}
                >
                  {index + 1}
                </div>
              }
              <TrackDataDisplay 
                page={page}
                trackInfo={trackInfo}
                trackDataList={trackDataList}
                maximumColumnCount={maximumColumnCount}
                width={width}
                height={height}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

const TrackDataDisplay = ({page, trackInfo, trackDataList, maximumColumnCount, width, height}) => {
  const settingValue = page?.[SETTING]
  const getSettingValue = getSettingFunction(settingValue, trackTitleSettings)
  const graphInfo = getSettingValue(TRACK_IMAGE_TYPE.GRAPH)
  
  const getGraphSettingValue = getSettingFunction(graphInfo, trackGraphSettings)

  const showGraph = getGraphSettingValue(TRACK_GRAPH_SETTING.SHOW)
  const lineColor = getGraphSettingValue(TRACK_GRAPH_SETTING.LINE_COLOR)
  const yAxisStartAtZero = getGraphSettingValue(TRACK_GRAPH_SETTING.Y_AXIS_START_AT_ZERO)
  const styleValues = getGraphSettingValue(TRACK_GRAPH_SETTING.FONT_STYLE)
  const fontStyle = getFontStyle(styleValues, TRACK_GRAPH_SETTING.FONT_STYLE, trackGraphSettings)
  const fontSize = fontStyle.fontSize
  const fontColor = fontStyle.color

  const yAxisLogScale = getGraphSettingValue(TRACK_GRAPH_SETTING.Y_AXIS_LOG_SCALE)
  

  const trackImageValue = page[REPORT_TYPE.TRACK_IMAGE]
  const {candidates} = useContext(taskPropsContext)
  const imageTypeList = useSelector(state => state.blobTypes?.list) || [];
  
  const trackImageTemplate = getTrackImageTemplate(candidates, imageTypeList)
  const getTrackImageValue = getSettingFunction(trackImageValue, trackImageTemplate)
  const baseImage = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.BASE_IMAGE)
  const track = getTrackImageValue(TRACK_IMAGE_INPUT_DATA.TRACK)

  const {locationId} = trackInfo
  const sameLocationList = trackDataList.map((trackData, ti) => {
    return {
      date: trackData.date,
      trackInfo: trackData?.trackInfo?.filter(trackInfo => trackInfo.locationId === locationId)
    }
  })

  const dateList = sameLocationList.map(d => d.date)
  const blankDataList = Array.from({length: maximumColumnCount - dateList.length})

  const xLabels = [
    ...blankDataList.map(d => ' '),
    ...dateList
  ]
  const xValues = xLabels.map((d, i) => i)

  const locationValues = sameLocationList?.map(d => d?.trackInfo?.reduce((acc, cur) => acc + cur.volume, 0)).map((value, index, array) => {
    const lastZeroIndex = array.lastIndexOf(0);
    if (index <= lastZeroIndex) {
      return null
    }
    return value
  })

  const yValues = [
    ...blankDataList.map(d => null), 
    ...locationValues
  ]

  const data = [
    {
      x: xValues,
      y: yValues,
      type: 'scatter',
      marker: {
        color: lineColor
      },
      name: 'volume'
    }
  ]

  const layout = {
    autosize: true,
    plot_bgcolor: 'black',
    paper_bgcolor: 'black',
    margin: {
      l: LEFT_MARGIN,
      r: RIGHT_MARGIN,
      t: 50,
      b: 10,
      pad: PADDING,
    },
    xaxis: {
      automargin: true,
      title: null,
      tickvals: xValues,
      ticktext: xLabels,
      showticklabels: true,
      zeroline: false,
      tickfont: {
        color: fontColor,
        size: fontSize
      },
      range: [-0.5, xLabels.length - 0.5],
    },
    yaxis: {
      automargin: true,
      title: {
        text: 'volume',
        side: 'left',
        font: {
          size: fontSize,
          color: fontColor
        },
        standoff: STAND_OFF,
      },
      showticklabels: true,
      zeroline: false,
      tickfont: {
        color: fontColor,
        size: fontSize
      },
      rangemode: yAxisStartAtZero && 'tozero', // y 축을 0부터 시작하게 설정
      type: yAxisLogScale && 'log'
    },
    shapes: [
      {
        type: 'rect',
        xref: 'paper',
        yref: 'paper',
        x0: 0,
        y0: 0,
        x1: 1,
        y1: 1,
        line: {
          color: 'white',
          width: 1,
        },
        fillcolor: 'rgba(0,0,0,0)'
      }
    ],
  }

  const imageInfoList = [
    ...blankDataList.map(d => null), 
    ...sameLocationList
  ]
  
  return (
    <>
      {showGraph &&
        <Plot 
          data={data}
          layout={layout}
          config={{staticPlot: true,}}
          style={{ 
            height: height * 0.7 > 400 ? 
              400 : 
              height * 0.7 < 300 ? 
                300 : 
                height * 0.7,
          }}
        />
      }
      <Row 
        style={{
          marginLeft: LEFT_MARGIN + STAND_OFF + PADDING, 
          marginRight: RIGHT_MARGIN, 
          marginBottom: 20,
          boxShadow: !showGraph && '0px 0px 0px 1px rgba(255,255,255,0.5)',
        }}
      >
        {imageInfoList.map((trackData, index, array) => {
          const columnHeight = height  * (trackData?.trackInfo?.length || 1)
          return (
            <Col style={{width, height: columnHeight}}>
              {trackData?.trackInfo?.map(trackInfo => {
                const isNullValue = yValues[index] === null
                if (isNullValue) return null
                return (
                  <div style={{width, height}}>
                    <ImageView 
                      page={page}
                      trackInfo={trackInfo}
                      baseImage={baseImage}
                      track={track}
                      width={width}
                      height={height}
                      imageUrl={trackInfo.imageUrl}
                    />
                  </div>
                )
              })}
            </Col>
          )
        })}
      </Row>
    </>
  )
}


const ImageView = ({page, trackInfo, baseImage, track, width, height, imageUrl=MRI_IMAGE}) => {
  const settingValue = page?.[SETTING]
  const getSettingValue = getSettingFunction(settingValue, trackTitleSettings)
  const imageInfo = getSettingValue(TRACK_IMAGE_TYPE.IMAGE)
  
  const getImageSettingValue = getSettingFunction(imageInfo, trackImageSettings)

  const imageInformation = getImageSettingValue(TRACK_IMAGE_SETTING.DISPLAY_INFORMATION)
  const styleValues = getImageSettingValue(TRACK_IMAGE_SETTING.FONT_STYLE)
  const fontStyle = getFontStyle(styleValues, TRACK_IMAGE_SETTING.FONT_STYLE, trackImageSettings)

  const state = trackInfo.state
  const colorizeState = getImageSettingValue(TRACK_IMAGE_SETTING.COLORIZE_STATE)

  const getStateColor = state => {
    switch(state) {
      case TRACK_IMAGE_SETTING.NEW:
        return getImageSettingValue(TRACK_IMAGE_SETTING.NEW)
      case TRACK_IMAGE_SETTING.INCREASE:
        return getImageSettingValue(TRACK_IMAGE_SETTING.INCREASE)
      case TRACK_IMAGE_SETTING.STABLE:
        return getImageSettingValue(TRACK_IMAGE_SETTING.STABLE)
      case TRACK_IMAGE_SETTING.DECREASE:
        return getImageSettingValue(TRACK_IMAGE_SETTING.DECREASE)
      default:
        return null
    }
  }
  const stateColor = colorizeState && getStateColor(state)

  return (
    <div style={{position: 'relative'}}>
      <div 
        style={{
          overflow: 'visible', 
          padding: 10,
          height: 0, 
          zIndex: 1, 
          float: 'left', 
          position: 'absolute', 
          top: 0, 
          ...fontStyle,
          textAlign: 'left',
        }}
      >
        {imageInformation?.includes(IMAGE_INFO.LOCATION) && 
          <div>{`Location: (${trackInfo.location})`}</div>
        }
        {imageInformation?.includes(IMAGE_INFO.VOLUME) && 
          <div>{`Volume: ${formatToLocalString(trackInfo.volume)}`}</div>
        }
        {imageInformation?.includes(IMAGE_INFO.STATE) && 
          <div>
            <span style={{marginRight: 5}}>State:</span>
            <span style={{color: stateColor}}>{state}</span>
          </div>
        }
      </div>
      <div style={{width, height, }}>
        <div 
          style={{
            display: 'flex', 
            alignItems: 'center', 
            height, 
            justifyContent: 'center'
          }}
        >
          <div style={{position: 'relative', width}}>
            <Image 
              src={imageUrl}
              preview={false}
              style={{pointerEvents: false, width, height: 'auto'}}
              draggable={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}