import React, {useEffect, useState} from "react";
import {Button, Col, InputNumber, Popconfirm, Row, Select, Space, Switch} from "antd";
import TaskInputSelect, {MODE} from "./taskInputSelect";
import {MinusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {LabelSelect} from "./labelControls";

const {Option} = Select;

/* 실행여부 + 선택된 ROI 값의 조합
  "locate-roi" : {
    execute: true   // 실행 여부
    roiSelected: 2 // 선택된 ROI 값
  }
 */
const ROIFields = ({imageType, name, value, setFieldsValue, disabled}) => {
  // { track: true/false, roi: 1 }
  const [expression, setExpression] = useState(value);
  useEffect(() => {
    setExpression(value);
  }, [value])

  return (
    <Space>
      <Switch
        defaultChecked={expression?.execute}
        checked={expression?.execute}
        onChange={checked => {
          const newExpression = {
            ...expression,
            execute: checked
          }
          setExpression(newExpression)
          setFieldsValue({[name]: newExpression})
        }}
        disabled={disabled}
      />
      <LabelSelect
        options={imageType?.seg_cmap}
        defaultValue={expression?.roiSelected}
        value={expression?.roiSelected}
        onChange={(selected, option) => {
          const newExpression = {
            ...expression,
            roiSelected: selected
          }
          setExpression(newExpression)
          setFieldsValue({[name]: newExpression})
        }}
        disabled={disabled || !expression?.execute}
      />
    </Space>
  );
}
export default ROIFields;