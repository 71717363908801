import { Button, Input, Space } from "antd";
import { useRef, useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";

export const usePatientSearchInResultContext = () => {
  const searchInput = useRef(null);
  const [filterInfo, setFilterInfo] = useState({})
  const [sorterInfo, setSorterInfo] = useState({})
  const filterFnRef = useRef({})

  const initializeFilterSorter = () => {
    setFilterInfo({})
    setSorterInfo({})
    Object.values(filterFnRef.current).forEach((value=>{
      if (value.selectedKeys.length > 0) {
        value.clearFilters()
      }
    }))
  }

  const sortOrder = key => {
    const splitKey = key.split('/')
    splitKey.pop()
    const classification = splitKey.join('/')
    return sorterInfo[classification]?.columnKey === key 
      ? sorterInfo[classification].order 
      : null
  }

  const getSortKey = key => {
    const splitKey = key.split('/')
    splitKey.pop()
    const sortKey = splitKey.join('/')
    return sortKey
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    const patientSortKey = getSortKey(sorter.columnKey)
    const sorterInfoCopy = {...sorterInfo}
    sorterInfoCopy[patientSortKey] = sorter  
    setSorterInfo(sorterInfoCopy)
    setFilterInfo({...filterInfo, ...filters})
  }

  const showSorterTooltip = key => {
    const parentKey = key.split('/').slice(0,-1).join('/')
    const status = sorterInfo[parentKey]?.order
    const targetKey = sorterInfo[parentKey]?.columnKey
    const tooltipProps = {
      title : 'Click to sort ascending',
      mouseEnterDelay: 0.5,
      mouseLeaveDelay: 0,
    }
    if (key !== targetKey) {
      return tooltipProps
    }

    if (status === 'ascend') {
      tooltipProps.title = 'Click to sort descending'
    }
    if (status === 'descend'){
      tooltipProps.title = 'Click to cancel sorting'
    }
    return tooltipProps
  }

  const getColumnSearchProps = colInfo => ({
    ...colInfo,
    filterResetToDefaultFilteredValue : true,
    filteredValue : filterInfo[colInfo.key] || null,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      filterFnRef.current[colInfo.key] = {
        selectedKeys : selectedKeys,
        clearFilters : clearFilters, 
      }
      return (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${colInfo.title}`}
            value={selectedKeys[0]}
            onChange={e => {
              e.stopPropagation()
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onPressEnter={e => {
              e.stopPropagation()
              const searchHistoryCopy = {...filterInfo}
              searchHistoryCopy[colInfo.key] = selectedKeys
              setFilterInfo(searchHistoryCopy)
              confirm()
            }}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={e => {
                e.stopPropagation()
                confirm()
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={e => {
                e.stopPropagation()
                clearFilters && clearFilters() && setSelectedKeys([])
                const searchHistoryCopy = {...filterInfo}
                delete searchHistoryCopy[colInfo.key]
                setFilterInfo(searchHistoryCopy)
                confirm()
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={e => {
                e.stopPropagation()
                const searchHistoryCopy = {...filterInfo}
                searchHistoryCopy[colInfo.key] = selectedKeys
                setFilterInfo(searchHistoryCopy)
                confirm({
                  closeDropdown: false,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      )
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => (
      record[colInfo.dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    showSorterTooltip: colInfo.sorter ? showSorterTooltip(colInfo.key) : undefined,
    render: (text, record) =>
      <div data-index={colInfo?.dataIndex}>
        {filterInfo[colInfo.key] ? (
          <Highlighter
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[filterInfo[colInfo.key][0]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        )}
      </div>
  });
  return {
    getColumnSearchProps, 
    initializeFilterSorter, 
    filterInfo, 
    sorterInfo, 
    sortOrder, 
    onTableChange,
    showSorterTooltip
  }
}



