import React from "react";
import {Tag} from 'antd';
import chroma from "chroma-js";
import { CloseOutlined } from '@ant-design/icons';

export const TagBase = ({color='128,128,128', style, onClick, closable, onClose, icon, children}) => {
  // color -> string, rgb, hex
  const isRGB = getColorType(color) === 'rgb' ? true : false
  const bgColor = isRGB ? chroma(color.split(',')) : color
  const textColor = getTagTextColor(color)
  return <Tag 
          color={bgColor} 
          style={{...style, color: textColor}} 
          onClick={onClick}
          closable={closable}
          closeIcon={<CloseOutlined style={{color: textColor}}/>}
          onClose={onClose}
          icon={icon}
          children={children}
        />
}

export const getTagTextColor = bgColor => {
  return isLightColor(bgColor) ? '#000' : '#fff'
}

const isLightColor = bgColor => {
  let r, g, b
  const isRGB = getColorType(bgColor) === 'rgb' ? true : false
  if (isRGB) {
    [r, g, b] = bgColor.split(',');
  }
  else {
    [r, g, b] = hexToRgb(bgColor)
  }
  // https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma > 128;
}

const hexToRgb = hex => {
  // 16진수 색상 코드에서 r, g, b 값을 추출하는 방법
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
}

const getColorType = color => {
  return color.split(',').length === 3 ? 'rgb' : 'hex'
}

function isLightColor2(bgColor) { //기존  labelControl의 함수
  let r, g, b
  const isRGB = getColorType(bgColor) === 'rgb' ? true : false
  if (isRGB) {
    [r, g, b] = bgColor.split(',');
  }
  else {
    [r, g, b] = hexToRgb(bgColor)
  }
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return L > 0.179
}