const getEvaluateResult = (hint, operand) => {
  const lop = hint.lop
  const op = hint.op
  const caseSensitive = hint?.case_sensitive ?? false
  const value = caseSensitive ? hint.value : hint.value.toLowerCase()

  let expressionCurr = false
  if (op === 'include' && operand !== null) {
    expressionCurr = operand.includes(value)
  } else if (op === 'exclude' && operand !== null) {
    expressionCurr = !operand.includes(value)
  } else if (op === 'exact match' && operand !== null) {
    expressionCurr = operand === value
  }

  return {lop, expressionCurr}
}

const evaluateAndGroup = andGroup => {
  return andGroup.every(data => data)
}

const evaluateExpressions = expressions => {
  const orGroups = [];
  let andGroup = [];

  expressions.forEach(data => {
    const { lop, expressionCurr } = data
    if (lop ===undefined || lop === 'and') {
      andGroup.push(expressionCurr);
    }
    else { // 'or'
      orGroups.push(evaluateAndGroup(andGroup))
      andGroup = [expressionCurr]
    }
  })

  if (andGroup.length > 0) {
    orGroups.push(evaluateAndGroup(andGroup))
  }

  return orGroups.some(result => result);
}


const FUNC = Object.freeze({FIND:'find', FILTER: 'filter'})

export const findDicomImageType = (series, study, imageTypeList, single=true) => {
  const result = imageTypeList[single ? FUNC.FIND : FUNC.FILTER](t => {
    if (t.dicom_hints?.length > 0) {
      const expressions = []
      let expression = false
      for (const hint of t.dicom_hints) {
        if (!hint.value) {
          expression = false
          continue
        }
        const target = hint.target
        const caseSensitive = hint?.case_sensitive ?? false
        let operand = null
        if (target === 'Protocol name' && series.protocol !== undefined) {
          operand = caseSensitive ? series.protocol : series.protocol.toLowerCase()
        } else if (target === 'Series description' && series.desc !== undefined) {
          operand = caseSensitive  ? series.desc : series.desc.toLowerCase()
        } else if (target === 'Study description' && study.desc !== undefined){
          operand = caseSensitive  ? study.desc : study.desc.toLowerCase()
        }
        expressions.push(getEvaluateResult(hint, operand))
      }
      expression = evaluateExpressions(expressions)
      return expression
    } else {
      return false
    }
  })
  return single ? result?.id : result.map(item => item.id)
}

/**
 * image_hints 와 file_name 을 가지고 type detection 하기
 * TODO file_name 뿐만 아니라 file_path도 이용할 수 있도록
 * @param blob
 * @returns {*}
 */
export const findNiftiImageType = (blob, imageTypeList, single=true) => {
  const result = imageTypeList[single ? FUNC.FIND : FUNC.FILTER](t => {
    if (t.image_hints?.length > 0) {
      const expressions = []
      let expression = false
      for (const hint of t.image_hints) {
        if (!hint.value) {
          expression = false
          continue
        }
        const target = hint.target
        const caseSensitive = hint?.case_sensitive ?? false
        let operand = null
        if (target === 'File name' && blob.name !== undefined) {
          operand = caseSensitive ? blob.name : blob.name.toLowerCase()
        } 
        expressions.push(getEvaluateResult(hint, operand))
      }
      expression = evaluateExpressions(expressions)
      return expression
    } else {
      return false
    }
  })
  return single ? result?.id : result.map(item => item.id)
}