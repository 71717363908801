import { all, fork, takeEvery, call, put, takeLeading, debounce } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from '../../redux/modules/auth'
import {loginSuccess, loginFail, refreshFail} from "../../redux/modules/auth";
import {registerSuccess, registerFail, updateSuccess, updateFail, refreshSuccess, 
  checkUserNameSuccess, checkUserNameFail, checkEmailSuccess, checkEmailFail } from "../../redux/modules/auth";
import {logoutFail} from "../../redux/modules/auth";
import {logoutSuccess} from "../../redux/modules/auth";
import {getCookie} from "../../lib/cookie";
import {getContext} from "@redux-saga/core/effects";
import { msgSuccess, msgError } from '../../redux/modules/message';
import { setUserConfig } from '../../redux/modules/config';


function updateApi({values}) {
  return axios.post('/api/updateUserInfo', {values});
}

function checkUserNameApi(username) {
  return axios.post('/api/check-username', {username})
}

function checkEmailApi(email, id) {
  return axios.post('/api/check-email', {email, id})
}

function* updateSaga({payload}) {
  try {
    const { values } = payload;
    const res = yield updateApi({values})
    const data = res.data
    const {userInfo, config} = splitUserInfoConfig(data)
    yield put(updateSuccess(userInfo));
    yield put(setUserConfig({...config}))
    yield put(msgSuccess('Updated successfully!'))

    const history = yield getContext('history');
    const path = config.landing_url || '/upload'
    history.push(path)
  } catch (error) {
    console.error('Update error. ', error);
    yield put(updateFail());
    yield put(msgError(error))
  }
}

function loginApi(loginData) {
  return axios.post('/api/login', loginData, {
    withCredentials: true, // For transition cookie
  });
}

function splitUserInfoConfig(data) {
  const userInfo = Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes('config')));
  const config = Object.fromEntries(Object.entries(data).filter(([key]) => key.includes('config')))?.config
  return {userInfo, config}
}

function* loginSaga({ payload }) {
  try {
    const { values: loginData } = payload;
    const { data } = yield call(loginApi, loginData);
    const {userInfo, config} = splitUserInfoConfig(data)
    
    const csrf_access_token = getCookie('csrf_access_token')
    axios.defaults.headers.get['X-CSRF-Token'] = csrf_access_token;
    axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
    axios.defaults.headers.delete['X-CSRF-Token'] = csrf_access_token;

    yield put(refreshSuccess(userInfo));
    yield put(setUserConfig({...config}))

    yield put(msgSuccess('Welcome, ' + data.username))

    // TODO 1 탭 여러개 열었을 때 session이 공유되는지? inMemoryToken이 다른 탭에서 접근가능한가..?
    // TODO 2 브라우저를 끄고 켰을 때 refresh 가 잘 갈까?
    // TODO 3 middleware로 silent refresh 구현

    // active user
    const history = yield getContext('history');
    if (data.confirmed) {
      const path = config.landing_url || '/upload'
      history.push(path)
    }
    // inactive user
    else {
      history.push('/login-pending')
    }
  } catch (error) {
    yield put(loginFail());
    yield put(msgError(error))
  }
}

function logoutApi(csrf_access_token) {
  return axios.delete('/api/logout', {
    headers: {
      'X-CSRF-Token': csrf_access_token
    },
    withCredentials: true,
  });
}

function logout2Api(csrf_refresh_token) {
  return axios.delete('/token/refresh/logout', {
    headers: {
      'X-CSRF-Token': csrf_refresh_token
    },
    withCredentials: true,
  });
}

function* logoutSaga() {
  try {
    const csrf_access_token = getCookie('csrf_access_token')
    const csrf_refresh_token = getCookie('csrf_refresh_token')
    yield call(logoutApi, csrf_access_token)
    yield call(logout2Api, csrf_refresh_token)

    const history = yield getContext('history');
    history.entries = []
    history.index = -1
    history.replace('/login')

    yield put(logoutSuccess());
    yield put(msgSuccess('Logout done successfully!'))

    // to support logging out from all windows
    localStorage.setItem('logout', Date.now());


  } catch (error) {
    console.error('Logout error. ', error.response.data.msg);
    yield put(logoutFail());
    yield put(msgError(error))
  }
}

function loadUserApi(userId) {
}

function* loadSaga({ payload }) {
  const data = yield call(loadUserApi);
  if (data != null) {
    yield put(loginSuccess(data));
  }
}

function refreshApi(csrf_token) {
  return axios.post('/token/refresh',{}, {
    headers: {
      'X-CSRF-Token': csrf_token
    },
    withCredentials: true, // For transition cookie
  });
}

function* refreshSaga() {
  const csrf_access_token_before = getCookie('csrf_access_token')
  const csrf_refresh_token_before = getCookie('csrf_refresh_token')
  if (csrf_refresh_token_before) {
    try {
      const { data } = yield call(refreshApi, csrf_refresh_token_before);
      const {userInfo, config} = splitUserInfoConfig(data)

      // message.success('Welcome, ' + data.username);
      const csrf_access_token = getCookie('csrf_access_token')
      axios.defaults.headers.get['X-CSRF-Token'] = csrf_access_token;
      axios.defaults.headers.post['X-CSRF-Token'] = csrf_access_token;
      axios.defaults.headers.delete['X-CSRF-Token'] = csrf_access_token;

      yield put(refreshSuccess(userInfo));
      yield put(setUserConfig({...config}))

      // // active user
      const history = yield getContext('history');
      if (data.confirmed) {
        if (history.location.pathname === '/login') {
          history.push(config.landing_url)  
        }
      }
      // inactive user
      else {
        history.push('/login-pending')
      }
    } catch (error) {
      yield put(refreshFail());
      yield put(msgError(error))
      const history = yield getContext('history');
      history.push('/login');
    }
  }
}

function signUpApi(signUpData) {
  return axios.post('/api/register', signUpData, {headers: {'Content-Type':'application/json'}});
}

function* registerSaga({ payload }) {
  const history = yield getContext('history');
  try {
    const signUpData = payload;
    const { data } = yield call(signUpApi, signUpData);
    yield put(registerSuccess(data));
    yield put(msgSuccess('Welcome, ' + data.username))
    history.push('/login-pending')
  } catch (error) {
    console.error(error);
    yield put(registerFail());
    yield put(msgError(error))
  }
}

function* checkUserNameSaga({payload }) {
  const {value} = payload
  try {
    const res = yield checkUserNameApi(value)
    const checkedUsername = res.data
    yield put(checkUserNameSuccess(checkedUsername))
  } catch(error) {
    yield put(checkUserNameFail(error.response.data.msg[0]))
  }
}

function* checkEmailSaga({payload }) {
  const {value, id} = payload
  try {
    const res = yield checkEmailApi(value, id)
    const checkedEmail = res.data
    yield put(checkEmailSuccess(checkedEmail))
  } catch(error) {
    yield put(checkEmailFail(error.response.data.msg[0]))
  }
}

function* watchUpdate() {
  yield takeLeading(actions.UPDATE, updateSaga);
}

function* watchLogin() {
  yield takeLeading(actions.LOGIN, loginSaga);
  yield takeLeading(actions.LOGOUT, logoutSaga);
}

function* watchRefresh() {
  yield takeEvery(actions.REFRESH, refreshSaga);
}

function* watchLoadUser() {
  yield takeLeading(actions.LOAD, loadSaga);
}

function* watchSignUp() {
  yield takeLeading(actions.REGISTER, registerSaga);
}

function* watchCheck() {
  yield debounce(500, actions.CHECK_USERNAME, checkUserNameSaga)
  yield debounce(500, actions.CHECK_EMAIL, checkEmailSaga)
}

export default function* authSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLoadUser),
    fork(watchSignUp),
    fork(watchUpdate),
    fork(watchRefresh),
    fork(watchCheck),
  ]);
}
