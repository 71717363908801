import chroma from "chroma-js"
import { useSelector } from "react-redux"
import { TagBase } from "../components/tagBase"

export const useGetImageTypeHooks = imageTypes => {
  const imageTypeListInStore = useSelector(state => state.blobTypes.list)
  const imageTypeList = imageTypes || imageTypeListInStore || []

  const getTypeFromTypeId = id => imageTypeList.find(type => type.id === id)
  const getShortFromTypeId = id => getTypeFromTypeId(id)?.short
  const getTypeFromShort = shortname => imageTypeList.find(type => type.short === shortname)
  const getTypeIdFromShort = shortname => getTypeFromShort(shortname)?.id
  const getTagColorFromType = type => type?.tag_color ? chroma(type.tag_color.split(',')).hex() :'gray'
  const getTypeFromDisplay = display => imageTypeList.find(type => type.display === display)
  
  const getTagFromTypeId = (id, name) => {
    const type = getTypeFromTypeId(id)
    const color = getTagColorFromType(type)
    const shortName = name ? name : type?.short
    return <TagBase key={id} id={id} color={color} children={shortName}/>
  }

  const getTagFromShort = shortName => {
    const type = imageTypeList.find(type => type.short === shortName)
    return getTagFromTypeId(type.id, shortName)
  }

  const getDisplayFromTypeId = id => getTypeFromTypeId(id).display
  
  return {
    imageTypeList,
    getTypeFromTypeId, 
    getShortFromTypeId, 
    getTypeIdFromShort, 
    getTypeFromShort, 
    getTagFromTypeId,
    getTagFromShort,
    getTagColorFromType,
    getDisplayFromTypeId,
    getTypeFromDisplay
  }
}