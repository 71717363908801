import React, {useEffect, useState} from "react";
import {io} from 'socket.io-client';
import {Input} from 'antd'
import {useTitle} from "../hooks/title";

// const chatt = []
// socket.on('my response',({name,message})=>{
//     console.log('socket on: ', chatt, name, message)
//     // setChat([...chat,{name,message}])
//     chatt.push({name, message})
// })

function Home() {
    useTitle('DYNAPEX | Home')
    // const socket =  io.connect('http://localhost:5000')
    // const socket =  io.connect('http://192.168.31.21:3000')

    // return <h2>Home</h2>;

    const [state, setState] = useState({message:'', name:''})
    // const [chat,setChat] =useState(chatt)
    const [chat,setChat] = useState([])
    const [socket, setSocket] = useState(null)

    // const eventName = 'my event'
    //
    // useEffect(() => {
    //     setChat(chatt)
    // })

    const handleMessageArrived = ({name,message})=>{
        // setChat([...chat,{name,message}])
        // const newChat = [...chat, {name, message}]
        // console.log('socket on: ', name, message, 'chat', chat, 'newChat', newChat)
        // setChat(newChat)
        setChat([...chat, {name, message}])
        // console.log('socket on: ', name, message, 'chat', chat)
    }

    useEffect(()=>{
      // const socket =  io('http://localhost:5000/progress')
      const socket =  io('/progress')
      socket.on('connect', function() {
        // console.log('check 2: connected? ', socket.connected);
      });

      socket.on('my response', handleMessageArrived);
      // socket.on('my progress response', (msg) => {console.log(msg)});
      setSocket(socket)
      return () => {
        // socket.off('my response', eventHandler);
        // socket.off('my response', handleMessageArrived);
        socket.disconnect()
        // console.log('socket disconnect')
      }
    }, [])

    const onTextChange = e =>{
        setState({...state,[e.target.name]: e.target.value})
    }

    const onMessageSubmit =(e)=>{
        e.preventDefault()
        const {name, message} =state
        // console.log('socket emit: ', name, message)
        socket.emit('join', {id: name});
        socket.emit('my event',{room:name, message})
        setState({message : '',name})
    }

    const renderChat =()=>{
        // console.log('renderChat', chat)
        return chat.map(({name, message},index)=>(
          <div key={index}>
              <h3>{name}:<span>{message}</span></h3>
          </div>
        ))
    }

    return (
      <>
        <div className='card'>

          <form onSubmit={onMessageSubmit}>
            <h1>Message</h1>
            <div className="name-field">
              <Input
                name ="name"
                onChange={e=> onTextChange(e)}
                value={state.name}
                label="Name"/>
            </div>
            <div >
              <Input
                name ="message"
                onChange={e=> onTextChange(e)}
                value={state.message}
                id="outlined-multiline-static"
                variant="outlined"
                label="Message"/>
            </div>
            <button>Send Message</button>
          </form>
          <div className="render-chat">
            <h1>Chat log</h1>
            {renderChat()}
          </div>
        </div>
      </>

    );
}

export default Home;