import React, {useEffect, useState} from "react";
import {Select, Tooltip} from 'antd';
import {useSelector} from "react-redux";
import chroma from "chroma-js";
import { TagBase } from "./tagBase";
import { TASK_DELIMITER } from "../lib/taskUtil";
import { CloseCircleTwoTone } from '@ant-design/icons';

const { Option } = Select;

export const MODE = Object.freeze({SINGLE: 0, MULTI: 1})
function TaskInputSelect({onChange, dropdownMatchSelectWidth, placeholder, value, tabIndex, taskInput, ...props}) {
  const storeBlobTypeList = useSelector(state => state.blobTypes?.list);
  const imagetypeList = props?.imageTypeList || storeBlobTypeList
  let selectMode = props.hasOwnProperty('mode') ?
    props.mode : taskInput?.hasOwnProperty("length") ?
      MODE.MULTI : MODE.SINGLE

  const onPreventMouseDown = (event) => {
    if (selectMode === MODE.MULTI) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const handleChange = (value, option) => {
    setSelected(value)
    if (selectMode === MODE.SINGLE) {
      if (option.length > 0) {
        setOpen(false)
      }
      // onChange?.(value[0], option[0])
    }
    // else {
    //   onChange?.(value, option)
    // }

    onChange?.(value, option)
  }

  useEffect(() => {
    // if (value) {
      if (selectMode === MODE.SINGLE) {
        if (value) {
          setSelected([value])
        }
        else {
          setSelected([])
        }
      }
      else {
        setSelected(value || [])
      }
    // }
  }, [value])

  // console.count('TaskInputSelect rendered!') // useWatch 때문에 2번 불리는 군..
  // console.log(taskInput, props, selected) // useWatch 때문에 2번 불리는 군..
  const getOptions = () => {
    if (props?.disabled && props.inReport) {
      return (
        <>
          <Option
            key={selected[0]}
            value={selected[0]}
            option_index={0}
            select_index={props?.select_index}
          >
            <TagBase color={imagetypeList.find(type => {
              const typeName = selected?.[0]
              const short = typeName?.includes(TASK_DELIMITER) ? 
                typeName.split(TASK_DELIMITER)[0] : 
                typeName
              return type.short === short
            })?.tag_color}>
              {selected?.[0]}
            </TagBase>
          </Option>
        </>
      )
    }
    return taskInput?.candidates && imagetypeList ? taskInput.candidates.map((item, oidx) => {
      const itemDisabled = taskInput?.candidates_disabled?.[oidx]
      // item : "flair", "flair <- Task 01" 형태의 string
      let imageTypeName = item
      const delimiter = ' \u25c0 TASK'
      if (item.includes(delimiter)) {
        imageTypeName = item.split(delimiter)[0]
      }
      const imageTypes = imagetypeList?.filter(t => t.short === imageTypeName)
      let imageType = null
      if (imageTypes.length === 1) {
        imageType = imageTypes[0]
      }
      else if (imageTypeName === 'result' && imageTypes.length === 0){
        imageType = {tag_color: '64,64,64'}
      }
      const testDisabled = selectMode === MODE.SINGLE && selected?.length > 0
        ? !selected?.includes(item)
        : !!itemDisabled
      if (imageType) {
        return <Option
          key={item}
          value={item}
          option_index={oidx}
          select_index={props?.select_index}
          disabled={testDisabled}
        >
          <TagBase color={chroma(imageType.tag_color.split(',')).hex()}>
            {item}
          </TagBase>
        </Option>
      }
      else
        return null
    }): null
  }
  const options = getOptions()
  const noCandidate = props?.inReport && 
    value !== undefined &&
    taskInput?.candidates?.find(candidate => candidate === value) ? 
      true : undefined

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    if (noCandidate && label?.props?.color === undefined) {
      const short = label.includes(TASK_DELIMITER) ?
        label.split(TASK_DELIMITER)[0] :
        label
      const imagetype = imagetypeList.find(type => type.short === short)
      return (
        <TagBase
          color={imagetype ? imagetype.tag_color : '64,64,64'}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
          style={{
            marginRight: 3,
          }}
        >
          <Tooltip
            title="imagetype not found"
            getPopupContainer={trigger => trigger.parentNode}
            placement="left"
          >
            <CloseCircleTwoTone twoToneColor="#ff4d4f" style={{fontSize: 12, marginRight: 8}}/>
            {label}
          </Tooltip>
        </TagBase>
      );
    }
    return (
      <TagBase
        color={label?.props?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {value}
      </TagBase>
    );
  };

  return (
    <Select
      showSearch={selectMode === MODE.MULTI || (selectMode === MODE.SINGLE && selected?.length === 0)}
      style={{ width: '100%'}}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      mode="multiple"
      tagRender={tagRender}
      onChange={handleChange}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      placeholder={placeholder}
      tabIndex={tabIndex}
      value={selected}
      select_index={props?.select_index}
      getPopupContainer={trigger => trigger.parentNode}
      disabled={props?.disabled}
    >
      {options}
    </Select>
  );
}
export default TaskInputSelect;

