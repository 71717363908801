import {duplicate} from "../redux/modules/pipeline";
import {CloseCircleFilled} from "@ant-design/icons";
import React from "react";
import { IMAGETYPE_INPUT_DATA, NONE, PAGES, TABLE_ROW_TYPE, REPORT_TYPE } from "../components/report/utils";

export const TASK_DELIMITER = ' \u25c0 TASK ' // ' ◀ TASK '
export const TASK_NAME_COREG_LONGITUDINAL = 'co-registration (longitudinal)'
export const TASK_NAME_MODEL_PREDICTION = 'model prediction'
const TASK_NAME_NORMALIZE= 'intensity normalization'
const TASK_NAME_NORMALIZE_NAWM= 'intensity normalization (NAWM)'
const TASK_NAME_MORPHOLOGICAL_OPS = 'morphological operations'
export const TASK_NAME_TRACK_ROI_CHANGES = 'track ROI change'
export const TASK_NAME_GENERATE_MASK = 'generate mask'
const TASK_NAME_ARITHMETIC= 'voxel-wise arithmetic'
export const TASK_NAME_REPORT = 'report'
const TASK_CONFIG_NAME_REPORT = 'report'
export const TASK_CONFIG_NAME_PAGES_IN_REPORT = 'pages'

const CFGNAME_CHANGE_OUTPUT_IMGTYPE = 'change output image type'
export const CFGNAME_BINARY_OUTPUT_IMGTYPE ='output as binary map'
const CFGNAME_FILTER_CONDITION = 'filter-condition'
const CFGNAME_ROI_FOR_NON_NAWM = 'ROI for non-NAWM'
export const CFGNAME_TRACK_ROI_CHANGES = 'target ROI'

const TASK_CONFIG_EXPRESSION_TYPE = 'expression'

export const TASK_CONFIG_EXPORT_LABELS = 'labels'

const POS_TASK_OUTPUT_TYPE_FIX = [
  {type: 'model', taskName: TASK_NAME_MODEL_PREDICTION, cfgName: 'model'},
  {type: 'track', taskName: TASK_NAME_TRACK_ROI_CHANGES},
  {type: 'id',    taskName: TASK_NAME_NORMALIZE, cfgName: CFGNAME_CHANGE_OUTPUT_IMGTYPE},
  {type: 'id',    taskName: TASK_NAME_NORMALIZE_NAWM, cfgName: CFGNAME_CHANGE_OUTPUT_IMGTYPE},
  {type: 'id',    taskName: TASK_NAME_ARITHMETIC, cfgName: CFGNAME_CHANGE_OUTPUT_IMGTYPE},
  {type: 'seg',   taskName: TASK_NAME_MORPHOLOGICAL_OPS, cfgName: CFGNAME_BINARY_OUTPUT_IMGTYPE},
  {type: 'seg',   taskName: TASK_NAME_GENERATE_MASK, cfgName: CFGNAME_BINARY_OUTPUT_IMGTYPE},
]

// TASK_INPUT_POS - task input 들어가는 자리니까 candidate 로 걸러줌
// antd Form NamePath 같이 task 안에서 propNames 순으로 접근
const TASK_INPUT_POS = [
  {propNames: ['inputs'], key: 'selected'},
  {propNames: ['config', 'filter-condition'], key: 'target'},
  {propNames: ['config', 'ROI for NAWM'], key: 'target'},
  {propNames: ['config', 'ROI for non-NAWM'], key: 'target'},
  {propNames: ['config', TASK_CONFIG_NAME_REPORT], key: 'pages'},
]
const TASK_INPUT_POS_IN_CONFIG = [
  {propNames: ['config', 'filter-condition'], key: 'target'},
  {propNames: ['config', 'ROI for NAWM'], key: 'target'},
  {propNames: ['config', 'ROI for non-NAWM'], key: 'target'},
  {propNames: ['config', TASK_CONFIG_NAME_REPORT], key: 'pages'},
]
const NAMEPATH_INPUT_IMGTYPE= [
  {propNames: ['config', CFGNAME_CHANGE_OUTPUT_IMGTYPE]},
]
const EXPRESSION_VALIDATE_POS_IN_CONFIG= [
  {name: 'filter-condition', fieldName: 'target'},
  {name: 'filter-condition', fieldName: 'value'},
  {name: 'ROI for NAWM', fieldName: 'target'},
  {name: 'ROI for NAWM', fieldName: 'value'},
  {name: 'ROI for non-NAWM', fieldName: 'target', optional: true},
  {name: 'ROI for non-NAWM', fieldName: 'value', optional: true},
  {name: 'target-ROI', fieldName: 'value'}, // morphological ops 에서 ROI 지정한 경우
]

// const VALIDATE_TYPE1 = 'taskIO'
// const VALIDATE_TYPE2 = 'ROI'
const VALIDATE_POS_IN_OPTIONAL_CONFIG = [
  {taskName: TASK_NAME_ARITHMETIC, cfgName: CFGNAME_FILTER_CONDITION, key: 'target'},
  {taskName: TASK_NAME_ARITHMETIC, cfgName: CFGNAME_FILTER_CONDITION, key: 'value'},
  {taskName: TASK_NAME_NORMALIZE_NAWM, cfgName: CFGNAME_ROI_FOR_NON_NAWM, key: 'target'},
  {taskName: TASK_NAME_NORMALIZE_NAWM, cfgName: CFGNAME_ROI_FOR_NON_NAWM, key: 'value'}
]

export const NULL_INPUT_TASK_TEMPLATES = [
  TASK_NAME_REPORT
]

// input.blobtype 경우의 수
// 1. 타입 미지정인 경우: 모든 타입 가능
// 2. segmentation 타입으로 지정 (morphological operation)
// 3. 타입 1개 지정 (model prediction)
// 4. 타입 N개 지정 (model prediction)
export const filterImageTypeNames = (input, names, imagetypes) => {
  // 모든 타입: case 1
  if (input.blobtype === undefined && !input.segmentation) {
    return names
  }

  const types = names.map(name => getImgtypeByName(name, imagetypes))

  // segmentation 타입만: case 2
  if (input.segmentation) {
    return names.filter((name, idx) => types?.[idx]?.seg)
  }

  if (!(Array.isArray(input.blobtype))) {
    // 타입 1개: case 3
    return names.filter((name, idx) => types[idx].id === input.blobtype)
  }
  else {
    // 타입 여러개: case 4
    return input.blobtype.map(blobtype_id => {
      return names.filter((name, idx) => types[idx].id === blobtype_id)
    }).flat(Infinity)
  }
}

export const isValidTask = (task, task_template, models) => {
  // report task는 inputs 값이 null 임
  if (task_template?.name === TASK_NAME_REPORT) {
    const report = task.config[TASK_CONFIG_NAME_REPORT]
    const name = report?.name
    if (!name) return false
    if (report[TASK_CONFIG_NAME_PAGES_IN_REPORT].length === 0) return false
    return validChangeReportConfig(report[PAGES], task.candidates)
  }
  // model prediction은 inputs 초기값이 null 임
  if (!task.inputs) {
    return false
  }

  // 모든 required 입력이 설정되었는지 확인
  for (const input of task.inputs) {
    if (!input.required) {
      continue
    }
    if (!input.selected) {
      return false
    }
    if (Array.isArray(input.selected) && input.selected.length === 0) {
      return false
    }
  }

  // 모든 required config 에 값이 존재하는지 확인
  for (const config of task_template.config) {
    if (config.required) {
      const configVal = task.config[config.name]
      if (Array.isArray(configVal)) {
        if (configVal.length === 0) {
          return false
        }
      }
      else {
        if (configVal == null) {
          return false
        }
      }
    }
  }

  // config 가 nested object 인 경우 추가 검증
  if ('locate-ROI' in task.config) {
    const roi = task.config['locate-ROI']
    if (roi && roi.execute && !roi?.roiSelected) {
      return false
    }
  }
  if (CFGNAME_TRACK_ROI_CHANGES in task.config) {
    const track = task.config[CFGNAME_TRACK_ROI_CHANGES]
    if (track && track.execute && !track?.roiSelected) {
      return false
    }
  }
  if (CFGNAME_CHANGE_OUTPUT_IMGTYPE in task.config) {
    const imgtype_id = task.config[CFGNAME_CHANGE_OUTPUT_IMGTYPE]
    if (!imgtype_id) {
      return false
    }
  }
  for (const config of EXPRESSION_VALIDATE_POS_IN_CONFIG) {
    // 해당 필드가 required 인지 확인, 모델 사용 task 는 model config 에서 찾아야 함
    if (task_template?.name === TASK_NAME_MODEL_PREDICTION) {
      const modelName = task.config?.model
      if (!modelName) {
        return false
      }
      const model = models.find(m => m.name === modelName[0] && m.version === modelName[1])
      const model_filter_config = model.config.find(cf => cf.name === config.name)
      if (!model_filter_config?.required) {
        continue
      }
    }
    else {
      if (!(config.name in task.config)) {
        continue
      }
      const template_config = task_template.config.find(cf => cf.name === config.name)
      if (!template_config?.required) {
        continue
      }
    }

    const expressions = task.config[config.name]

    // optional 이 아닌데 filter-condition 이 없는 경우 invalid 리턴
    if (!config.optional && (expressions == null || expressions.length === 0)) {
      return false
    }

    for (const expression of expressions) {
      const fieldValue = expression[config.fieldName]
      if (fieldValue != null) {
        continue
      }
      else {
        return false
      }
    }
  }

  // NOTE alias in output 확인
  if (!task.outputs) {
    return false
  }

  const btypeCounts = {}
  task.outputs.map(o => o.blobtype).flat(Infinity).forEach(item => {
    btypeCounts[item] = (btypeCounts[item] || 0) + 1
  })

  // NOTE 1. alias 는 null 일 수 없다
  for (const output of task.outputs) {
    if (output?.suppress) {
      continue
    }
    // NOTE 0. duplicate 필드가 잘 채워져 있나? btype 이 겹치는 데 duplicate: false 인지 확인
    if (!output?.duplicate && output?.blobtype && btypeCounts[output.blobtype] > 1) {
      return false
    }
    if (output?.duplicate) {
      if (Array.isArray(output.blobtype)) {
        const aliasChecks = output.children.map(c => {
          return c?.duplicate ? c?.alias ? false : true : false
        })
        if (aliasChecks.some(Boolean)) {
          return false
        }
      }
      else {
        if (!output?.alias) {
          return false
        }
      }
    }
  }

  // NOTE 2. alias 는 중복되면 안 됨
  const aliasList = task.outputs.map(output => {
    // 배열이면 selected, blobtype, duplicate, children 이 같은 길이
    if (output?.duplicate) {
      if (Array.isArray(output.blobtype)) {
        // duplicate + 배열이면 children 에 alias 값 존재
        return output.children.map(c => {
          return `${c.blobtype} ${c?.alias}` // undefined or empty string("") or 입력값
        })
      }
      else {
        // duplicate + single 이면 output 에 alias 값 존재
        return `${output.blobtype} ${output?.alias}` // undefined or empty string("") or 입력값
      }
    }
    return undefined
  }).flat(Infinity).filter(i => Boolean(i)) // undefined, empty string 제거
  const aliasSet = new Set(aliasList)
  if (aliasSet.size !== aliasList.length) {
    return false
  }

  return true
}

export const getForcedOutputNames = (tasks, templates, imagetypes) => {
  // foced 는 강제된 타입, 자세한 건 decideTaskOutputImgtype 주석 참고
  const imgtSeg = imagetypes.find(t => t.display === 'seg')
  const imgtype_ids = tasks.map(task => {
    // case 4
    const template = templates.find(el => el.id === task.task_template_id);
    if (template.output && template.output.some(o => o?.blobtype)) {
      return task.outputs.filter(o => o?.blobtype).map(o => o.blobtype)
    }

    // case 1
    const fixedTypes = task?.outputs?.filter(o=> o?.fixed && o?.blobtype)
    if (fixedTypes && fixedTypes.length > 0) {
      return fixedTypes.map(o => o.blobtype)
    }

    // case 2
    if (CFGNAME_CHANGE_OUTPUT_IMGTYPE in task.config) {
      const imgtype_id = task.config[CFGNAME_CHANGE_OUTPUT_IMGTYPE]
      if (imgtype_id) {
        return imgtype_id
      }
    }

    // case 3
    if (CFGNAME_BINARY_OUTPUT_IMGTYPE in task.config) {
      if (task.config[CFGNAME_BINARY_OUTPUT_IMGTYPE]) {
        return imgtSeg.id
      }
    }

    return null
  }).flat(Infinity).filter(id => id != null)

  return imgtype_ids.map(id => imagetypes.find(t => t.id === id)?.short)
}

const getTaskOutputNames = (task, taskEditArgs) => {
  const {templates, models, imagetypes, longitudinal} = taskEditArgs

  const generates = {map:[], voxel:[], model:[], report:[]}
  const template = templates.find(el => el.id === task.task_template_id);

  if (!isValidTask(task, template, models)) {
    return generates
  }

  // NOTE isValidTask 지난뒤니까 output 생기는 경우만 잘 챙기면 됨
  //  output 타입이 결정되면 output.blobtype 에 입력
  decideTaskOutputImgtype(task, template, imagetypes, models, longitudinal)

  // output blobtype 이 지정되어야만 만들수 있도록 수정
  for (const output of task.outputs) {
    switch (output.type) {
      case "map":
        generates.map = [...generates.map, ...generateOutputNames(task, output, imagetypes)]
        break
      case "voxel":
        generates.voxel.push(output.name + `${TASK_DELIMITER}${task.order}`)
        break
      case "model":
        generates.model.push(output.name + `${TASK_DELIMITER}${task.order}`)
        break
      case "report":
        generates.report.push(output.name + `${TASK_DELIMITER}${task.order}`)
        break
      default:
        console.error('unhandled output type')
    }
  }

  return generates
}

const generateOutputNames = (task, output, imagetypes) => {
  if (output?.blobtype == null || output?.suppress) { return [] }

  // 생각해봐야 할 것
  // 1. single or multiple
  // 2. btype + task order => 기본 output name
  // 3. duplicate & alias
  const isListType = Array.isArray(output.blobtype)
  const btypeIds = isListType ? output.blobtype : [output.blobtype]
  return btypeIds.map((btypeId, idx) => {
    const name = imagetypes.find(t => t.id === btypeId).short
    const outputName = name + `${TASK_DELIMITER}${task.order}`

    if (isListType) {
      if (output?.duplicate?.[idx]) {
        return outputName + ` (${output.children[idx].alias})`
      }
      else {
        return outputName
      }
    }
    else {
      return output?.duplicate ? outputName + ` (${output?.alias})` : outputName
    }
  })
}

const validateTaskInputsInConfig = (task, taskEditArgs) => {
  const {imagetypes, typenames} = taskEditArgs

  TASK_INPUT_POS_IN_CONFIG.forEach(pos => {
    // reduce 하면 inputs 또는 config['filter-condition'] 같은 애들이 나옴
    const prop = pos.propNames.reduce((acc, cur) => acc[cur], task)
    if (prop && pos.key === PAGES) {
      // report 구조체에 대해 validation
      const editValueFunc = (target, key='selected') => {
        if (!task.candidates.includes(target?.[key])) {
          return undefined
        }
        
        return target
      }
      const extra = [
        {propName: 'filter-condition', key: 'target'},
      ]
      editReportConfigTarget(prop?.[pos.key], editValueFunc, extra)
    }
    else {
      prop?.forEach(item => {
        // 불가능한 입력 제거
        item[pos.key] = Array.isArray(item[pos.key]) ?
          item[pos.key].map(name => task.candidates.includes(name))
          : task.candidates.includes(item[pos.key]) ? item[pos.key] : null
      })
    }
  })

  const typeIds = typenames.map(name => imagetypes.find(type => type.short === name)?.id).filter(id => id)
  NAMEPATH_INPUT_IMGTYPE.forEach(pos => {
    // reduce 하면 inputs 또는 config['filter-condition'] 같은 애들이 나옴
    let lastObj = null
    let lastName = null
    const prop = pos.propNames.reduce((acc, cur) => {
      lastObj = acc
      lastName = cur
      return acc[cur]
    }, task)
    if (lastName in lastObj && !typeIds.includes(prop)) {
      lastObj[lastName] = null
    }
  })
}
const buildInputCandidates = (task, output_cumulated, imageTypeList) => {
  const {maps, voxels, models, reports} = output_cumulated

  // NOTE model prediction filter 에서 사용
  //  model prediction 에 필요한 입력이 있고 거기에 candidate가 또 따로 있는 상황
  //  input 에 모든 candidate가 담을 수 없는 경우)
  //  + 추가로 input 이 여러개일 때 방해(?) 받지 않고 순수하게 task 입력을 모아둔다 for validateConfigByInputCandidates
  task.candidates = maps

  if (task.inputs == null) { return; }

  for (const input of task.inputs) {
    switch (input.type) {
      case "map":
        input.candidates = filterImageTypeNames(input, maps, imageTypeList)
        // 불가능한 입력 제거
        if (input.selected) {
          if (Array.isArray(input.selected)) {
            input.selected = input.selected.filter(item => input.candidates.includes(item))
          }
          else {
            if (!input.candidates.includes(input.selected)) {
              // candidate에 존재하지 않는 selection은 제거
              delete input.selected
            }
          }
        }
        break
      case "voxel":
        input.candidates = voxels;
        // 불가능한 입력 제거
        if (input.selected) {
          if (!input.candidates.includes(input.selected)) {
            // candidate에 존재하지 않는 selection은 제거
            delete input.selected
          }
        }
        break
      case "model":
        // TODO 모델 생성 task 가 생기면 여기서 추가해줘야겠지
        input.candidates = models;
        break
      case "report":
        // TODO report 생성 task 가 생기면 여기서 추가해줘야겠지
        input.candidates = reports;
        break
      default:
        input.candidates = filterImageTypeNames(maps, input);
        break
    }
  }
}

export const decideTaskOutputImgtype = (task, template, imagetypes, models, longitudinal) => {
  // NOTE 여기 수정할 때 항상 getForcedOutputNames 수정하는 걸 고려해야 함
  //    pipeline_blobtype forced 정해야 하기 때문에.. 그리고 그걸 확인하는 시점이 항상 분석하다 에러를 만나는 상태라서 ㅠ
  // task 에 따라서 output image type 이 강제로 고정되는 경우 (input 선택 관계없음)
  // 1. model prediction 또는 generate mask 같은 곳에서 output blobtype 을 고정한 경우
  // 2. intensity normalization - 'chang output image type' - 현재는 selected 중에 선택, 이거 그냥 모든 image type 으로 할까?
  //    selected 바뀌면 걸러줘야함
  // 3. generate mask - 'output as binary map' true 이면 seg type 강제됨
  //   : 조건이라는게 task input, selected 로 걸러야 할 수도 있고 coreg 처럼 위에서 선택한 거 빼고 선택해야 할 수도 있다
  // 4. diffusion 이나 perfusion 처럼 output 에 blobtype이 고정된 경우: task template 에 blobtype 이 박혀 있는 경우

  // STEP 1 output blobtype 이 어떤 이유로든 고정된 경우 (위 주석 1~3 에 해당)
  //  e.g. model prediction, generate mask, intensity normalization 등
  //  model prediction 은 model.output 에 있고 generate mask 는 옵션에 따라 seg 또는 입력과 같은 타입으로 바뀜
  //  intensity normalization 같은 경우는 task config 에서 지정
  for (const pos of POS_TASK_OUTPUT_TYPE_FIX) {
    if (template.name !== pos.taskName) {
      continue
    }
    switch(pos.type) {
      case 'track':
        template.output.forEach((o, oi) => {
          if (o?.blobtype) {
            task.outputs[oi].blobtype = o.blobtype
            task.outputs[oi].fixed = true
          }
        })
        break
      case 'model':
        const modelName = task.config[pos.cfgName]
        if (modelName) {
          const model = models.find(m => m.name === modelName[0] && m.version === modelName[1])
          // NOTE model prediction 중 output 이 여러개인 대표적인 예, DCE GAN
          model.output.forEach((o, oi) => {
            task.outputs[oi].blobtype = o?.blobtype
            task.outputs[oi].fixed = true
          })
        }
        break
      case 'id':
        const id = task.config[pos.cfgName]
        if (id) {
          task.outputs[0].blobtype = id
          task.outputs[0].fixed = true
        }
        else {
          delete task.outputs[0].blobtype
          delete task.outputs[0].fixed
        }
        break
      case 'seg':
        const exec = task.config[pos.cfgName]
        const imgtSeg = imagetypes.find(t => t.display === 'seg')
        task.outputs[0].blobtype = exec ? imgtSeg.id
          : task.inputs[0].selected ? getImgtypeByName(task.inputs[0].selected, imagetypes).id : undefined
        task.outputs[0].fixed = task.outputs[0].blobtype ? true : false
        break
      default:
        console.error('unhandled position type')
    }
    return true
  }

  // STEP 2 task template output 에 blobtype 이 박혀있는 경우 (시작 주석 case 4 에 해당)
  if (template.output.some(o => o?.blobtype)) {
    const allHaveBtype = task.outputs.every(o => o.type === 'map' ? o?.blobtype : true)
    if (allHaveBtype) {
      return true
    }
  }

  // 여기 도달하고도 output 타입 미지정이면 generate_map 인 경우이므로
  // 따라서 task input 선택에 따라 output image type 결정
  /**
   * 생각해야 할 조건.. 으 복잡
   *  1. type === 'map' or 'voxel' or 'model'
   *  2. selected 존재 유무
   *  3. array or single (task.input, task.output 자체는 항상 array)
   *  4. generate_map: addInput 이지만 여기서 추가해두고 output 에서 처리하지말자 (output 중에서는 blobtype attr 있는 것만?)
   *    4-1. task config 에서 binary type 지정된 경우
   *    4-2. task config 에서 output type 지정된 경우
   *    4-3. selected 존재시 type ◀ TASK 1 같은 형태 string 처리 + selected_id
   */
  for (const output of task.outputs) {
    // output null 이면 pass (도달 불가)
    // map 타입만 지정가능, voxel 이나 model 은 pass
    if (output == null || output.type !== "map") {
      continue
    }

    const targetName = output.name === 'result' ? 'target' : output.name
    const target = task.inputs.find(ip => ip.name === targetName)
    if (target?.selected && output?.blobtype && !Array.isArray(output.blobtype)) {
      const selected_btype = getImgTypeId(target.selected, imagetypes)
      if (output.blobtype === selected_btype) {
        continue
      }
    }

    // case 1. input 은 'target', output 은 'result'
    if (output.name === 'result') {
      output.blobtype = Array.isArray(target.selected) ?
        target.selected.map(s => getImgTypeId(s, imagetypes))
        : getImgTypeId(target.selected, imagetypes)
    }
    else {
      if (target) {
        // case 2. input, output 이름이 같을 때
        const selected = target.selected
        output.blobtype = Array.isArray(target.selected) ?
          target.selected.map(s => getImgTypeId(s, imagetypes))
          : getImgTypeId(target.selected, imagetypes)
      }
      else {
        // case 3. input, output 이름이 다를 때
        //  실제로 도달하면 안됨, 여기 온다는 건 model prediction 같이
        //  input1 + input2 => result 같은 형태라는 의미
        console.error('unable to decide output image type')
      }
    }
  }
}

const cumulateTaskOutput = (task, output_cumulated, taskEditArgs) => {
  const {maps, voxels, models, reports} = output_cumulated
  const {map, voxel, model, report} = getTaskOutputNames(task, taskEditArgs)
  if (map.length > 0) {
    output_cumulated.maps = maps.concat(map)
  }
  if (voxel.length > 0) {
    output_cumulated.voxels = voxels.concat(voxel)
  }
  if (model.length > 0) {
    output_cumulated.models = models.concat(model)
  }
  if (report.length > 0) {
    output_cumulated.reports = reports.concat(report)
  }
}

export const fillTaskInputCandidate = (editingTask, taskEditArgs) => {
  // fillAllTasksInputCandidate 와 가장 큰 차이점은 현재 편집중인 task 의 input candidate 만 바꾼다는 것
  // 왜냐하면, task 입력 설정에서 task 편집중에 바로 필요하기 때문
  const {templates, models, imagetypes, typenames, tasks} = taskEditArgs
  const output_cumulated = {
    maps: [...typenames],
    voxels: [],
    models: [...models],
    reports: []
  }

  for (const task of tasks) {
    if (task.index === editingTask.index) {
      break
    }
    cumulateTaskOutput(task, output_cumulated, taskEditArgs)
  }
  buildInputCandidates(editingTask, output_cumulated, imagetypes)
  validateTaskInputsInConfig(editingTask, taskEditArgs)
}

export const fillAllTasksInputCandidate = (tasks, taskEditArgs) => {
  const {templates, models, imagetypes, typenames} = taskEditArgs
  const output_cumulated = {
    maps: [...typenames],
    voxels: [],
    models: [...models],
    reports: []
  }

  const newTasks = [...tasks];
  for (const task of newTasks) {
    buildInputCandidates(task, output_cumulated, imagetypes)
    validateTaskInputsInConfig(task, taskEditArgs)
    cumulateTaskOutput(task, output_cumulated, taskEditArgs)
  }
  return newTasks;
}

export const getAllTaskOutputNames = (tasks, taskEditArgs) => {
  const {templates, models, imagetypes, typenames} = taskEditArgs

  const output_cumulated = {
    maps: [...typenames],
    voxels: [],
    models: [...models],
    reports: []
  }

  for (const task of tasks) {
    buildInputCandidates(task, output_cumulated, imagetypes)
    cumulateTaskOutput(task, output_cumulated, taskEditArgs)
  }

  return output_cumulated['maps']
}


export const getNewTask = ({template_id, tasks, templates}) => {
  let template = templates.find(el => el.id === template_id);
  if (!template) {
    const templatesFiltered = templates.filter(t => t.manufacturer == null && !t.hidden)
    template = templatesFiltered[0]
  }
  const config = {}
  template.config.forEach(item => {
    const name = item.name
    const defaultValue = item.default
    config[name] = defaultValue
  })
  const newTask = {
    order: tasks.length + 1,
    config: config,
    index : tasks.length,
    task_template_id: template.id,
    inputs: JSON.parse(JSON.stringify(template.input)), // deep copy 중요!
    outputs: JSON.parse(JSON.stringify(template.output)), // deep copy 중요!
  };

  return newTask;
}

export const setFormFieldsByTask = (form, task, taskEditArgs) => {
  const {templates, typenames} = taskEditArgs
  form.resetFields()

  const curTaskTemplate = templates.find(t => t.id === task.task_template_id)
  const taskTypeId = curTaskTemplate.manufacturer ? [curTaskTemplate.manufacturer, task.task_template_id] : [task.task_template_id]
  form.setFieldsValue({task_type_id: taskTypeId})

  const task_template = templates.find(el => el.id === task.task_template_id);
  task_template?.config.forEach((item, item_index) => {
    const name = item.name;
    let val = item.default;
    if (task.config.hasOwnProperty(name)) {
      // 저장된 값이 있으면 덮어쓰기
      val = task.config[name];
      form.setFieldsValue({[name]: val})
    }
    else {
      // 저장된 값이 없으면
      switch (item.type) {
        case 'select-imagetype':
          let finalVal = val
          let selected = task?.inputs?.[0]?.selected // selected 는 "t1ce ◀ TASK 1" 형태의 문자열
          if (!finalVal && selected) {
            if (selected.includes(TASK_DELIMITER)) {
              selected = selected.split(TASK_DELIMITER)[0]
            }
            finalVal = typenames.find(t => t.short === selected)?.id
          }
          form.setFieldsValue({[name]: finalVal})
          break
        case 'expression-roi':
          val = val ?? []
          form.setFieldsValue({[name]: val})
          break
        case 'expression':
          val = val ?? []
          form.setFieldsValue({[name]: val})
          break
        default:
          form.setFieldsValue({[name]: val})
      }
    }
  })

  // NOTE model prediction 인 경우, task.config 에 filter 값 존재하므로 특수처리
  if (task_template?.name === TASK_NAME_MODEL_PREDICTION) {
    Object.keys(task.config).forEach(key => {
      form.setFieldsValue({[key]: task.config[key]})
    })
  }
}

export const changeTaskType = (task, new_template_id, templates) => {
  let new_template = templates.find(el => el.id === new_template_id);
  if (!new_template) {
    new_template = templates[0]
  }
  const newTask = {...task};
  newTask.task_template_id = new_template.id;
  newTask.config = new_template.config.filter(conf => 'default' in conf).reduce((acc, item) => {
    acc[item.name] = item.default
    return acc
  }, {}) // task config 는 빈 object, {} 로 시작, task_template 가 []
  newTask.inputs = JSON.parse(JSON.stringify(new_template.input)); // deep copy 중요!
  newTask.outputs = JSON.parse(JSON.stringify(new_template.output)); // deep copy 중요!
  return newTask;
}

// NOTE 아직까지는 output.blobtype 은 array 일 수 없다, input.blobtype 은 array 가능
//  - blobtype << input & output
//  - blobtype_id << extraImages
// const imageType = analysisImageTypes?.find(bt => bt.id === img?.blobtype || bt.id === img?.blobtype_id)
// const foundType = analysisImageTypes.find(t => t.id === output.blobtype)

export const setInputCandidateDisabled = (inputs) => {
  // // NOTE task output 에 어떤 image type 이 1개 이상 존재할 수 없음 (alias 때문에 이제 더이상 그렇지 않음)
  // //  가령 coreg task 에서 moving 을 t1으로 잡으면 others 에서 t1 및 파생 t1 들을 선택할 수 없도록 강제해야 함
  // //  candidates_disabled 에 값 설정, 활용은 TaskInputSelect 에서 한다
  // if (inputs) {
  //   const oname_taken = new Set();
  //   for (const inp of inputs) {
  //     const output_map = inp?.generate_map
  //     if (output_map && inp.hasOwnProperty('selected')) {
  //       if (Array.isArray(inp.selected)) {
  //         inp.selected.forEach(i => oname_taken.add(i.split(TASK_DELIMITER)[0]))
  //       }
  //       else {
  //         oname_taken.add(inp.selected.split(TASK_DELIMITER)[0]);
  //       }
  //     }
  //   }
  //   for (const inp of inputs) {
  //     if (inp?.generate_map) {
  //       inp.candidates_disabled = []
  //       inp.candidates.forEach(candidate => {
  //         if (inp?.selected?.includes(candidate)) {
  //           inp.candidates_disabled.push(false)
  //         }
  //         else {
  //           const candidate_name = candidate.split(TASK_DELIMITER)[0]
  //           if (oname_taken !== candidate && oname_taken.has(candidate_name)) {
  //             inp.candidates_disabled.push(true)
  //           } else {
  //             inp.candidates_disabled.push(false)
  //           }
  //         }
  //       })
  //     }
  //   }
  // }
}

const editImageTypeSelectedOnShift = target => {
  // 'pure' (task delimiter 가 없는) 이면 그대로 리턴
  if (!target.includes(TASK_DELIMITER)) {
    return target
  }
  else {
    const name_split = target.split(TASK_DELIMITER)
    const match = target.match(new RegExp(TASK_DELIMITER + "(\\d+)"))
    const matchAlias = name_split[1].match(/\(([^)]+)\)/);
    const alias = matchAlias ? matchAlias[1] : null;
    const suffix = alias ? ` (${alias})` : ''
    return name_split[0] + (match[1] === '1' ? '' : `${TASK_DELIMITER}${parseInt(match[1]) - 1}`) + suffix
  }
}

const editImageTypeSelectedOnPrepend = (target, t1ceShort) => {
  // 'pure' (task delimiter 가 없는)
  if (!target.includes(TASK_DELIMITER)) {
    // t1ce 가 사용된 경우
    if (target.includes(t1ceShort)) {
      return target + `${TASK_DELIMITER}1`
    }
    else {
      // t1ce 사용하지 않은 경우면 그대로 리턴
      return target
    }
  }
  else {
    const name_split = target.split(TASK_DELIMITER)
    const match = target.match(new RegExp(TASK_DELIMITER + "(\\d+)"))
    const matchAlias = name_split[1].match(/\(([^)]+)\)/);
    const alias = matchAlias ? matchAlias[1] : null;
    const suffix = alias ? ` (${alias})` : ''
    return name_split[0] + `${TASK_DELIMITER}${parseInt(match[1]) + 1}` + suffix
  }
}

const editImageTypeSelectedOnMove = (target, diffmap) => {
  if (target.includes(TASK_DELIMITER)) {
    const nameSplit = target.split(TASK_DELIMITER)
    const match = target.match(new RegExp(TASK_DELIMITER + "(\\d+)"))
    const matchAlias = nameSplit[1].match(/\(([^)]+)\)/);
    const alias = matchAlias ? matchAlias[1] : null;
    const suffix = alias ? ` (${alias})` : ''
    return nameSplit[0] + `${TASK_DELIMITER}${diffmap[match[1]]}` + suffix
  }
  else {
    return target
  }
}

export const getAllUsedTaskInputNames = tasks => {
  return [...new Set(tasks.map(task => {
    return TASK_INPUT_POS.map(pos => {
      const prop = pos.propNames.reduce((acc, cur) => acc[cur], task)
      if (prop && pos.key === PAGES)  {
        const inputNames = []
        const getValueFunc = (target, key='selected') => {
          if (target) {
            inputNames.push(target?.[key])
          }
          return target
        }
        const extra = [
          {propName: 'filter-condition', key: 'target'},
        ]
        editReportConfigTarget(prop?.[pos.key], getValueFunc, extra)
        return inputNames
      }
      return prop?.map(item => item[pos.key])
    })
  }).flat(Infinity).filter(item => item))]
}

const editTaskInputOrder = (task, editFunc, ...args) => {
  TASK_INPUT_POS.forEach(pos => {
    const prop = pos.propNames.reduce((acc, cur) => acc[cur], task)
    if (prop && pos.key === 'pages') {
      const editValueFunc = (target, key='selected') => {
        const newSelected = editFunc(target?.[key], ...args)
        return {
          ...target,
          [key]: newSelected
        }
      }
      const extra = [
        {propName: 'filter-condition', key: 'target'},
      ]
      editReportConfigTarget(prop?.[pos.key], editValueFunc, extra)
    }
    else {
      prop?.filter(item => item[pos.key]).forEach(item => {
        // prop 이 task.inputs 일 때, item[pos.key], 즉 input.selected 는 Array 일 수 있다
        item[pos.key] = Array.isArray(item[pos.key]) ?
          item[pos.key].map(sel => editFunc(sel, ...args))
          : editFunc(item[pos.key], ...args)
      })
    }
  })
}

export const getMatchingOutput = (task, input) => {
  if (task.outputs.length === 1) {
    return task.outputs[0]
  }
  else {
    // find matching output which has same name with the current input
    return task.outputs.find(o => o.name === input.name)
  }
}

// editTaskOutputByConfig, A 하고 decideTaskOutputImagtype, B 는 다른데.. 정리를 해보자
// A 는 일단 현재는 B 를 포함하고, 추가로 필드값 변경에 따라 outputs 배열을 추가, 삭제, 수정함
// B 는 output blobtype 이 고정된(fixed) 경우 이를 적용하고 그렇지 않은 경우 blobtype 값을 채움
// A 는 newTask 를 반환하고 B 는 inplace 로 변경

export const editTaskOutputByConfig = (changedFields, task, taskEditArgs) => {
  // STEP 1. output blobtype 입력
  const {templates, models, imagetypes, longitudinal} = taskEditArgs
  const template = templates.find(el => el.id === task.task_template_id)

  const newTask = {...task, outputs: [...task.outputs]}  // 항상 새로 만들기
  decideTaskOutputImgtype(newTask, template, imagetypes, models, longitudinal)

  // STEP 2. config 변화에 따라 output 수가 바뀌는 상황 e.g. Use Leakage Correction in CMN
  let leakCorr = false
  let leakCorrValue = null
  changedFields.forEach(f => {
    if (f.name.some(n => n === 'Use leakage correction')) {
      leakCorr = true
      leakCorrValue = f.value
    }
  })
  if (leakCorr) {
    // console.log(`Use leakage correction changed ${leakCorrValue}`)
    const btypeLeak = imagetypes.find(bt => bt.display === 'leak')
    if (leakCorrValue) {
      const btypeMTT = imagetypes.find(bt => bt.display === 'mtt')
      const idx = newTask.outputs.findIndex(o => o.blobtype === btypeMTT.id) + 1
      const newOutputLeak = {type: 'map', blobtype: btypeLeak.id, fixed: true, suppressible: true, suppress: false}
      newTask.outputs.splice(idx, 0, newOutputLeak)
    }
    else {
      const idx = newTask.outputs.findIndex(o => o.blobtype === btypeLeak.id)
      newTask.outputs.splice(idx, 1)
    }
  }
  return [true, newTask]
}


export const rebuildTasksOnShift = (tasks) => {
  // coreg-longitudinal 제거로 순서 당겨지는 경우
  return tasks.slice(1, tasks.length).map(t => {
    t.index = t.index - 1 // 한 칸씩 당기기
    t.order = t.order - 1 // 한 칸씩 당기기

    editTaskInputOrder(t, editImageTypeSelectedOnShift)
    return t
  })
}


export const rebuildTasksOnPrepend = (tasks, t1ceShort) => {
  // 순서변경 및 t1ce 가 사용된 경우를 찾아서 변경해줌
  return tasks.map(t => {
    // 한 칸씩 뒤로 밀기
    t.index = t.index + 1
    t.order = t.order + 1

    editTaskInputOrder(t, editImageTypeSelectedOnPrepend, t1ceShort)
    return t
  })
}

export const getNewTasksFromDB = (tasks, taskEditArgs) => {
  const newTasks = Object.entries(tasks).map(([key, value], index) => {
    return {
      id: value.id,
      order: value.order,
      index,
      config: value.config,
      inputs: value.input,
      outputs: value.output,
      task_template_id: value.task_template_id,
    };
  }).sort((a, b) => (a.order > b.order) ? 1 : -1)

  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export const getNewTasksOnImageTypeSelChanged = (tasks, taskEditArgs) => {
  const newTasks = [...tasks]
  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export const getNewTasksOnTaskInputSelChanged = (task_index, val, tasks, taskEditArgs) => {
  const newTasks = [...tasks]

  const cur_task = newTasks[task_index];
  const firstInput = cur_task.inputs[0]
  if (firstInput.hasOwnProperty('length')) {
    firstInput.selected = val
    cur_task.outputs[0].selected = val;
  }
  else {
    if (val.length > 0) {
      firstInput.selected = val[0]
      if (firstInput?.generate_map) {
        cur_task.outputs[0].selected = val[0];
      }
    }
    else {
      delete firstInput.selected
      if (firstInput?.generate_map) {
        delete cur_task.outputs[0].selected
        delete cur_task.outputs[0].blobtype
      }
    }
  }

  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export const getNewTasksOnNew = ({template_id, tasks, taskEditArgs}) => {
  const {templates} = taskEditArgs
  const newTasks = [...tasks, getNewTask({tasks, templates})];
  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export const getNewTasksOnChange = (tasks, task, taskEditing, taskEditArgs) => {
  const newTasks = [...tasks];
  newTasks.splice(task.order - 1, 1, task);
  if (!taskEditing) {
    fillAllTasksInputCandidate(newTasks, taskEditArgs);
  }
  return newTasks
}

export const getNewTasksOnMove = (tasks, oldIndex, newIndex, taskEditArgs) => {
  const tasksCopy = tasks.map(t => {
    t.orderOld = t.order
    return t
  })

  const task2move = tasksCopy[oldIndex]
  tasksCopy.splice(oldIndex, 1)
  // newIndex 가 falsy 이면 지우는 것임
  if (newIndex != null) {
    tasksCopy.splice(newIndex, 0, task2move)
  }

  const mapOrderDiff = {}
  const newTasks = tasksCopy.map((task, index) => {
    const newTask = {
      ...task,
      index,
      order : index + 1,
    }
    delete newTask.orderOld

    mapOrderDiff[task.orderOld] = newTask.order

    return newTask
  })

  newTasks.forEach(task => editTaskInputOrder(task, editImageTypeSelectedOnMove, mapOrderDiff))

  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export const getNewTasksOnLongitudinalChanged = (checked, tasks, taskEditArgs) => {
  const {templates, imagetypes, typenames} = taskEditArgs

  let newTasks = null
  if (checked) {
    // new task of coreg-longitudinal
    const coreg_template = templates.find(t => t.name === TASK_NAME_COREG_LONGITUDINAL)
    const coregTask =
      getNewTask({template_id:coreg_template.id, tasks, templates})
    coregTask.index = 0
    coregTask.order = 1

    // t1ce 가 선택되어 있는 경우에만 추가할 수 있음
    const inputImageTypes = imagetypes.filter(t => typenames.includes(t.short))
    const t1ceType = imagetypes.find(t => t.display === 't1ce')
    if (inputImageTypes.find(t => t.display === 't1ce')) {
      coregTask.inputs[0].selected = t1ceType.short
    }

    newTasks = [coregTask, ...rebuildTasksOnPrepend(tasks, t1ceType.short)]
  } else {
    // remove coreg task
    newTasks = rebuildTasksOnShift(tasks)

    // remove track task if exists
    const trackTemplate = templates.find(tmpl => tmpl.name === TASK_NAME_TRACK_ROI_CHANGES)
    const trackTaskIndex = newTasks.findIndex(t => trackTemplate.id === t.task_template_id)
    if (trackTaskIndex >= 0) {
      return getNewTasksOnMove(newTasks, trackTaskIndex, null, taskEditArgs)
    }
  }

  fillAllTasksInputCandidate(newTasks, taskEditArgs);
  return newTasks
}

export function getImgTypeId(selected, blobtypes) {
  if (!selected) {
    return selected
  }

  let imageTypeName = selected
  if (selected.includes(TASK_DELIMITER)) {
    imageTypeName = selected.split(TASK_DELIMITER)[0]
  }
  return blobtypes.find(t => t.short === imageTypeName)?.id
}

const getImgtypeByName = (name, imagetypes) => {
  const short = name.includes(TASK_DELIMITER) ? name.split(TASK_DELIMITER)[0] : name
  return imagetypes.find(t => t.short === short)
}

export const injectImgtypeID2TaskJSON = (tasks, blobtypes) => {
  tasks.forEach(task => {
    if (task.inputs) {
      task.inputs.forEach(input => {
        const sel = input?.selected
        if (sel) {
          input.selected_id = Array.isArray(sel) ?
            sel.map(s => getImgTypeId(s, blobtypes))
            : getImgTypeId(sel, blobtypes)
        }
        delete input.candidates_disabled
        delete input.candidates
        delete input.desc
      })
    }

    TASK_INPUT_POS_IN_CONFIG.forEach(pos => {
      // reduce 하면 inputs 또는 config['filter-condition'] 같은 애들이 나옴
      const prop = pos.propNames.reduce((acc, cur) => acc[cur], task)
      if (prop && pos.key === PAGES) {
        const editValueFunc = (target, key='selected') => {
          return {
            ...target,
            selected_id: getImgTypeId(target?.[key], blobtypes)
          }
        }
        const extra = [
          {propName: 'filter-condition', key: 'target'},
        ]
        editReportConfigTarget(prop?.[pos.key], editValueFunc, extra)

      }
      else {
        // item 이 input 또는 condition or expression, pos.key 가 'target'
        prop?.forEach(item => {
          item.selected_id = Array.isArray(item[pos.key]) ?
            item[pos.key].map(name => getImgTypeId(name, blobtypes))
            : getImgTypeId(item[pos.key], blobtypes)
        })
      }
    })

    // NAMEPATH_INPUT_IMGTYPE 는 이미 id 여서 건드릴 게 없다
    // NAMEPATH_INPUT_IMGTYPE.forEach(pos => {})

    // task.outputs 에 blobtype 은 애초에 ID 로 입력
  })
}

const addMapEntry = (map, key, taskId, imgtype, selected, disabled, loaded) => {
  map.set(key, {
    key: key,
    task_id: taskId, // pure input 이면 undefined 가능
    blobtype_id: imgtype.id,
    tag_color: imgtype.tag_color,
    selected: selected,
    disabled: disabled,
    loaded: loaded || disabled
  })
}

const addInput = (analysis, task, input, map, disabled = true, selected= true, loaded = true) => {
  /**
   * 생각해야 할 조건.. 으 복잡
   *  1. type === 'map'
   *  2. selected 존재 유무
   *  3. array or single (task.input, task.output 자체는 항상 array)
   *  4. generate_map: addInput 이지만 여기서 추가해두고 output 에서 처리하지말자 (output 중에서는 blobtype attr 있는 것만?)
   *    4-1. task config 에서 binary type 지정된 경우
   *    4-2. task config 에서 output type 지정된 경우
   *    4-3. selected 존재시 type ◀ TASK 1 같은 형태 string 처리 + selected_id
   *
   * @type {unknown[]}
   */

  const analysisTasks = analysis?.tasks
  const analysisImageTypes = analysis?.imageTypes

  // case 1. type === 'map' + case 2. selected 존재 유무
  if (input.type === 'map' && input.selected) {
    // case 3. array or single (task.input, task.output 자체는 항상 array)
    let selections = []
    let selections_id = []
    if (Array.isArray(input.selected)) {
      selections = input.selected
      selections_id = input.selected_id
    }
    else {
      selections = [input.selected]
      selections_id = [input.selected_id]
    }
    selections.forEach((sel, si) => {
      let imgTask = undefined
      if (sel.includes(TASK_DELIMITER)) {
        const match = sel.match(new RegExp(TASK_DELIMITER + "(\\d+)"))
        const task_order = match[1]
        imgTask = analysisTasks[task_order - 1]
      }

      const inputImgtype = analysisImageTypes.find(t => t.id === selections_id[si])
      if (inputImgtype && !map.has(sel)) {
        addMapEntry(map, sel, imgTask?.id, inputImgtype, selected, disabled, loaded)
      }
    })
  }
}


const addOutput = (analysis, task, output, map, selected = true, disabled = true, loaded = true) => {
  // const analysisTasks = analysis?.tasks
  const analysisImageTypes = analysis?.imageTypes

  if (output.type === 'map' && output?.blobtype && !output?.suppress) {
    if (Array.isArray(output.blobtype)) {
      output.blobtype.forEach((obt, i) => {
        const foundType = analysisImageTypes.find(t => t.id === obt)
        let suffix = ''
        if (output?.children && output.children[i].duplicate) {
          suffix = ` (${output.children[i].alias})`
        }
        const output_name = foundType.short + `${TASK_DELIMITER}${task.order}` + suffix
        if (!map.has(output_name)) {
          addMapEntry(map, output_name, task.id, foundType, selected, disabled, loaded)
        }
      })
    }
    else {
      const foundType = analysisImageTypes.find(t => t.id === output.blobtype)
      const suffix = output?.duplicate ? output?.alias ? ` (${output?.alias})` : '' : ''
      const output_name = foundType.short + `${TASK_DELIMITER}${task.order}` + suffix
      if (!map.has(output_name)) {
        addMapEntry(map, output_name, task.id, foundType, selected, disabled, loaded)
      }
    }
  }
}

export const buildImageTypeList = (analysis, taskIndex, extra) => {
  if (!analysis?.tasks || !analysis?.imageTypes) {
    return []
  }

  const tasks = analysis?.tasks
  const thisTask = tasks[taskIndex]

  const allTaskImages = new Map()
  const taskTemplates = analysis.taskTemplates
  const taskTemplateId = thisTask.task_template_id
  let taskInput = thisTask.input
  if (!taskInput) {
    const taskTemplate = taskTemplates.find(t => t.id === taskTemplateId)
    if (NULL_INPUT_TASK_TEMPLATES.includes(taskTemplate?.name)) {
      taskInput = []
    }
  }
  // 현재 task 이미지들은 모두 추가 + disabled 처리
  taskInput.forEach(input => {
    addInput(analysis, thisTask, input, allTaskImages)
  })
  thisTask.output.forEach(output => {
    addOutput(analysis, thisTask, output, allTaskImages)
  })

  // extraImages 일단 추가 + selected
  if (extra) {
    extra.forEach(ex => {
      if (!allTaskImages.has(ex.key)) {
        ex.selected = true
        ex.loaded = true
        allTaskImages.set(ex.key, ex)
      }
    })
  }

  tasks.forEach((task, ti) => {
    if (ti !== taskIndex && task.state !== 'fail') {
      // inputs
      task.input?.forEach(input => {
        addInput(analysis, task, input, allTaskImages, false, false, false)
      })

      // outputs
      task.output?.forEach(output => {
        addOutput(analysis, task, output, allTaskImages, false, false, false)
      })
    }
  })

  return [...allTaskImages.values()]
}

export const validCheckOptionalConfig = (tasks, templates) => {
  const taskNameMapper = {}
  templates.forEach(template => {
    taskNameMapper[template.id] = template.name
  })

  tasks.forEach(task => {
    const taskName = taskNameMapper[task.task_template_id]
    VALIDATE_POS_IN_OPTIONAL_CONFIG.forEach(pos => {
      if (taskName === pos.taskName) {
        task.config[pos.cfgName] = task.config[pos.cfgName].filter(data => data[pos.key] != null)
        const firstExpression = task.config[pos.cfgName][0]
        if (firstExpression?.lop) {
          delete firstExpression.lop
        }
      }
    })
  })
}

export const validChangeReportConfig = (pages, candidates) => {
  if (!pages?.length) {
    return false
  }
  for (const page of pages) {
    if (!page?.title) {
      return false
    }
    switch (page.type) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        const dataSource = page[page.type] || []
        if (dataSource.length === 0) {
          return false
        }
        for (const row of dataSource) {
          const target = row?.target
          const value = row?.value
          const type = row?.type
          if (type === TABLE_ROW_TYPE.TEXT) {
            continue
          }
          if (target?.selected === NONE && !target?.selected_id) {
            continue
          }
          if (!target || !value) {
            return false
          }
          if (candidates && !candidates.includes(target?.selected)) {
            return false
          }
          for (const pos of EXPRESSION_VALIDATE_POS_IN_CONFIG) {
            const expressions = row?.[pos.name]
            if (expressions) {
              for (const exp of expressions) {
                if (exp?.[pos.fieldName] === undefined) {
                  return false
                }
              }
            }
          }
        }
        break
      case REPORT_TYPE.TRACK_IMAGE:
        const trackImageConfig = page[page.type]
        for (const configName of Object.values(IMAGETYPE_INPUT_DATA)) {
          const target = trackImageConfig?.[configName]
          if (!target?.selected) {
            return false
          }
          if (candidates && !candidates.includes(target?.selected)) {
            return false
          }
        }
        break
      default:
        // unhandled report type
        return false
    }
  }
  
  return true
}

const editReportConfigTarget = (pages, func, extra=[]) => {
  pages.forEach(page => {
    switch (page.type) {
      case REPORT_TYPE.GRAPH_N_TABLE:
        const dataSource = page[page.type] || []
        for (const row of dataSource) {
          if (row.target && row.target?.selected !== NONE) {
            row.target = func(row.target)
          }
        }
        if (extra.length === 0) {
          break
        }
        for (const extraItem of extra) {
          const {propName, key} = extraItem
          for (const row of dataSource) {
            if (!row.hasOwnProperty(propName)) {
              continue
            }
            if (Array.isArray(row[propName])) {
              for (const [index, item, ] of row[propName].entries()) {
                row[propName][index] = func(item, key)
              }
              row[propName] = row[propName]?.filter(item => item?.[key] !== undefined)
            }
            else {
              row[propName][key] = func(row?.[propName], key)
            }
          }
        }
        break
      case REPORT_TYPE.TRACK_IMAGE:
        const trackImageConfig = page[page.type]
        for (const configName of Object.values(IMAGETYPE_INPUT_DATA)) {
          const target = trackImageConfig?.[configName]
          if (target?.selected) {
            trackImageConfig[configName] = func(target)
          }
        }
        break
      default:
        break
    }
  })
}

export const selectedDecomposition = selected => {
  const shortMatch = selected.match(/^(\w+)/);
  const orderMatch = selected.match(/TASK (\d+)/);
  const aliasMatch = selected.match(/\(([^)]+)\)/);

  return {
      short: shortMatch ? shortMatch[1] : undefined,
      order: orderMatch ? parseInt(orderMatch[1], 10) : undefined,
      alias: aliasMatch ? aliasMatch[1] : undefined,
  };
}